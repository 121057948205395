import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";

import { firestoreConnect } from "react-redux-firebase";
import Paper from "@material-ui/core/Paper";
import { stringTranslate } from "languages/OMTranslate";
import { WrappedVirtualizedTable } from "../common/WrappedVirtualizedTable";
import {
  setSelectedContact,
  toggleContactModal
} from "store/actions/customersPage";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    height: "100%",
    width: "100%",
    order: 3,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      minHeight: "200px"
    }
  }
});

class ContactsList extends Component {
  render() {
    const {
      filteredContacts,
      setSelectedContact,
      toggleContactModal,
      classes
    } = this.props;

    return (
      <Paper className={classes.root}>
        {filteredContacts && filteredContacts.length > 0 ? (
          <WrappedVirtualizedTable
            rowCount={filteredContacts.length}
            rowGetter={({ index }) => filteredContacts[index]}
            onRowClick={event => {
              setSelectedContact(event.rowData);
              toggleContactModal();
            }}
            columns={[
              {
                width: 200,
                flexGrow: 1.1,
                label: stringTranslate("customers", "Contact Name"),
                dataKey: "name"
              },
              {
                width: 200,
                flexGrow: 1.1,
                label: stringTranslate("customers", "Contact Phone"),
                dataKey: "phone"
              },
              {
                width: 200,
                flexGrow: 1.1,
                label: stringTranslate("customers", "Contact Email"),
                dataKey: "email"
              }
            ]}
          />
        ) : (
          <Typography variant="body1" className="m-12">
            {stringTranslate("general", "No elements")}
          </Typography>
        )}
      </Paper>
    );
  }
}

ContactsList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer,
  contacts: state.firestore.ordered.contacts
});

const mapDispatchToProps = dispatch => ({
  setSelectedContact: contact => dispatch(setSelectedContact(contact)),
  toggleContactModal: () => dispatch(toggleContactModal())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(ContactsList);
