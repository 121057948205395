import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import { TextField, IconButton } from "@material-ui/core";

import { stringTranslate } from "languages/OMTranslate";

const styles = theme => ({
  content: {
    display: "flex",
    alignItems: "center"
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  },
  icon: {
    marginLeft: "10px"
  }
});

/**
 * Componente per gestire l'opzione di una check, dropdown o radio
 */
const OptionField = ({
  classes,
  option,
  onChange,
  onDelete,
  errors,
  index
}) => (
  <div className={classes.content}>
    <TextField
      error={errors[`options.${option.name}`] !== undefined}
      label={stringTranslate("modules", "option")}
      className={classes.textField}
      helperText={errors[`options.${option.name}`]}
      value={option.label}
      onChange={e => onChange(e.target.value)}
      InputProps={{
        className: classes.inputText
      }}
      InputLabelProps={{
        className: classes.inputTextLabel
      }}
      placeholder={`${stringTranslate("modules", "fieldOption")} ${index + 1}`}
      margin="normal"
      required
      fullWidth
    />
    <IconButton className={classes.icon} onClick={onDelete}>
      <RemoveIcon />
    </IconButton>
  </div>
);

OptionField.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  option: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

OptionField.defaultProps = {
  errors: {}
};

export default withStyles(styles, { withTheme: true })(OptionField);
