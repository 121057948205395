import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";

import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Snackbar
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Modules from "./module/Modules";
import ModuleForm from "./module/ModuleForm";
import FormBuilder from "./builder/FormBuilder";

import { getFirestoreCollection } from "../../../firestoreAccount";
import { stringTranslate } from "languages/OMTranslate";

const styles = theme => ({
  container: {
    order: 1,
    flex: 1
  },
  contentLoader: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dialogCloseButton: {
    position: "absolute",
    top: theme.spacing.unit,
    right: theme.spacing.unit
  }
});

/**
 * Pannello principale che gestisce la visualizzazione del componente con tabella
 * dei moduli compilati e del form builder
 * Qui viene gestita la dialog per visualizzare anteprima form e dettaglio di un modello
 */
class ModulePanel extends React.Component {
  state = {
    dialogModule: {
      open: false,
      fetching: false,
      props: undefined,
      preview: true
    },
    snackbar: {
      open: false,
      message: ""
    }
  };

  toggleShowDialogModule = (
    props = { customForm: undefined, module: undefined },
    preview
  ) => {
    this.setState(state => ({
      dialogModule: {
        ...state.dialogModule,
        open: !state.dialogModule.open,
        props,
        preview: preview === undefined ? state.dialogModule.preview : preview
      }
    }));
  };

  showSnackbar = message => {
    this.setState({ snackbar: { open: true, message } });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState(state => ({
      snackbar: { ...state.snackbar, open: false }
    }));
  };

  onShowPreview = (customForm, fields) => {
    this.toggleShowDialogModule(
      {
        customForm: {
          id: "dummy",
          ...customForm,
          fields
        }
      },
      true
    );
  };

  onShowModule = async (customForm, module) => {
    this.toggleShowDialogModule(
      {
        customForm,
        module
      },
      false
    );
    this.setState(state => ({
      dialogModule: {
        ...state.dialogModule,
        fetching: true
      }
    }));
    // Vegono letti i dati del task e clienti collegati
    const taskRes = await this.fetchTaskAsync(module.taskId);
    if (taskRes.error) {
      this.toggleShowDialogModule();
      this.showSnackbar(stringTranslate("modules", "errorShowModule"));
      return;
    }
    const partyRes = await this.fetchPartyAsync(module.partyId);
    if (partyRes.error) {
      this.toggleShowDialogModule();
      this.showSnackbar(stringTranslate("modules", "errorShowModule"));
      return;
    }
    this.setState(state => ({
      dialogModule: {
        ...state.dialogModule,
        fetching: false,
        props: {
          ...state.dialogModule.props,
          task: taskRes.task,
          party: partyRes.party
        }
      }
    }));
  };

  /**
   * Salva nel database la copia di un custom form
   * @param {Object} customForm oggetto custom form
   */
  onCopyCustomForm = async customForm => {
    const { firestore } = this.props;
    if (customForm === undefined) {
      return;
    }
    try {
      const customFormNoId = { ...customForm };
      delete customFormNoId.id;
      await firestore
        .collection(`${getFirestoreCollection()}/customForms`)
        .add({
          ...customFormNoId,
          name: `${stringTranslate("modules", "copyOf")} ${customForm.name}`,
          createdAt: new Date()
        });
    } catch (e) {
      console.log("error copy custom form", e);
      this.showSnackbar(stringTranslate("modules", "errorCopyCustomFrom"));
      return;
    }
    this.showSnackbar(stringTranslate("modules", "customFormCopied"));
  };

  /**
   * Legge i dati del task indicato
   * @param {String} taskId id del task
   * @return {Object} oggetto con i seguenti parametri:
   *  - error: booleano indicate se presente un errore
   *  - party: oggetto con i dati del task. Undefined in caso di errore o id non specificato
   */
  fetchTaskAsync = async taskId => {
    const { firestore } = this.props;
    if (taskId === undefined || taskId === "") {
      return { error: false };
    }
    try {
      const task = await firestore
        .collection(`${getFirestoreCollection()}/tasks`)
        .doc(taskId)
        .get();
      return { error: false, task: task.data() };
    } catch (e) {
      console.log("fetch task", e);
      return { error: true };
    }
  };

  /**
   * Legge i dati del cliente indicato
   * @param {String} partyId id del cliente
   * @return {Object} oggetto con i seguenti parametri:
   *  - error: booleano indicate se presente un errore
   *  - party: oggetto con i dati del cliente. Undefined in caso di errore o id non specificato
   */
  fetchPartyAsync = async partyId => {
    const { firestore } = this.props;
    if (partyId === undefined || partyId === "") {
      return { error: false };
    }
    try {
      const party = await firestore
        .collection(`${getFirestoreCollection()}/parties`)
        .doc(partyId)
        .get();
      return { error: false, party: party.data() };
    } catch (e) {
      console.log("fetch party", e);
      return { error: true };
    }
  };

  renderModules = (customForm, modules) => {
    const { onBack, onOpenBuilder } = this.props;
    if (!customForm) {
      return undefined;
    }
    return (
      <Modules
        customForm={customForm}
        onBack={onBack}
        onEditForm={onOpenBuilder}
        onCopy={this.onCopyCustomForm}
        modules={modules}
        onModuleClick={this.onShowModule}
      />
    );
  };

  renderBuilder = (customForm, modules = []) => {
    const { onBack, onCloseBuilder } = this.props;
    return (
      <FormBuilder
        customForm={customForm}
        onBack={
          customForm === undefined || customForm === null
            ? onBack
            : onCloseBuilder
        }
        used={modules.length > 0}
        onShowPreview={this.onShowPreview}
      />
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <div className={classes.contentLoader}>
        <CircularProgress color="secondary" />
      </div>
    );
  };

  render() {
    const { dialogModule, snackbar } = this.state;
    const { classes, customForm, modules, showBuilder } = this.props;
    // Di default viene visualizzato il loading
    let contentToRender = this.renderLoading();
    if (customForm && !isLoaded(modules)) {
      contentToRender = this.renderLoading();
    }
    if (customForm && isLoaded(modules)) {
      // Se viene selezionato un form e sono in editing visualizzo builder form
      // altirmenti la tabella dei modelli
      contentToRender = showBuilder
        ? this.renderBuilder(customForm, modules)
        : this.renderModules(customForm, modules);
    }
    if (!customForm) {
      contentToRender = this.renderBuilder(customForm, []);
    }
    return (
      <div className={classes.container}>
        {contentToRender}
        <Dialog
          onClose={() => this.toggleShowDialogModule()}
          aria-labelledby="module-dialog"
          open={dialogModule.open}
          fullWidth
          scroll="paper"
        >
          <DialogTitle id="module-dialog" disableTypography>
            <Typography variant="h3">
              {`${dialogModule.props &&
                dialogModule.props.customForm &&
                dialogModule.props.customForm.name}${
                dialogModule.preview
                  ? ` (${stringTranslate("modules", "preview")})`
                  : ""
              }`}
            </Typography>
          </DialogTitle>
          <IconButton
            className={classes.dialogCloseButton}
            onClick={() => this.toggleShowDialogModule()}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            {dialogModule.fetching ? (
              this.renderLoading()
            ) : (
              <ModuleForm
                customForms={customForm ? [customForm] : []}
                selectedCustomForm={
                  dialogModule.props && dialogModule.props.customForm
                }
                module={dialogModule.props && dialogModule.props.module}
                task={dialogModule.props && dialogModule.props.task}
                party={dialogModule.props && dialogModule.props.party}
                isPreview={dialogModule.preview}
                onDeleted={() => this.toggleShowDialogModule()}
              />
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          message={snackbar.message}
        />
      </div>
    );
  }
}

ModulePanel.propTypes = {
  classes: PropTypes.object.isRequired, // Provided by withStyle
  customForm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.shape({
      seconds: PropTypes.number.isRequired
    }).isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        position: PropTypes.number.isRequired,
        help: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string
          })
        )
      })
    )
  }),
  modules: PropTypes.arrayOf(PropTypes.shape()), // I campi sono dinamici, dipendono dal custom form
  onBack: PropTypes.func.isRequired,
  onOpenBuilder: PropTypes.func.isRequired,
  onCloseBuilder: PropTypes.func.isRequired,
  showBuilder: PropTypes.bool.isRequired
};

ModulePanel.defaultProps = {
  customForm: undefined,
  modules: undefined
};

const mapStateToProps = state => ({
  modules: state.firestore.ordered.modules
});

const mapDispatchToProps = dispatch => ({});

export default compose(
  firestoreConnect(props => {
    return props.customForm
      ? [
          {
            collection: `${getFirestoreCollection()}/modules`,
            storeAs: "modules",
            where: [["customFormId", "==", props.customForm.id]]
          }
        ]
      : [];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(ModulePanel);
