import React, { Component } from "react";
import {
  withStyles,
  Button,
  Grid,
  FormHelperText,
  IconButton,
  Typography,
  TextField,
  Tooltip
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { CardElement, injectStripe } from "react-stripe-elements";
import MainActivitySectionHeader from "../mainActivity/MainActivitySectionHeader";
import { connect } from "react-redux";
import { AttachMoney } from "@material-ui/icons";
import { stringTranslate } from "languages/OMTranslate";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import LoadingModal from "main/common/LoadingModal";
import firebase from "firestoreAccount";
import OMDeleteConfirmationAlert from "main/content/common/OMDeleteConfirmationAlert";
import OMConfirmationPopup from "main/content/common/OMConfirmationPopup";
import $ from "jquery";

const styles = theme => ({
  root: {
    order: 2,
    display: "flex",
    flexFlow: "column nowrap",
    padding: theme.spacing.unit * 3,
    maxWidth: theme.measures.formMaxWidth,
    flex: "1 1 auto",
    width: "100%",
    height: "calc(100vh - 48px)",
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      width: "100%",
      height: "auto"
    }
  },
  card: {
    padding: "10px 0",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)"
  },
  container: {
    marginTop: theme.spacing.unit * 3
  }
});

class AccountPaymentsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: "",
      onSaving: false,
      onSaved: false,
      deleteConfirmOpen: false,
      missingPaymentDialogOpen: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      $("#hContainer").height(window.innerHeight - 48);
    }
    $("#hContainer").height(window.innerHeight - 48);
  }

  resize = () => this.forceUpdate();

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    if (this.props.account && this.props.account.missingPaymentMethod) {
      this.setState({ missingPaymentDialogOpen: true });
    }
  }

  deleteCardOnStripe = () => {
    const { firestore } = this.props;
    firestore
      .update(
        {
          collection: "accounts",
          doc: localStorage.getItem("accountId")
        },
        { updatingStripeData: true }
      )
      .then(docRef => {
        const { account } = this.props;
        const serverFunction = firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("deleteCreditCardOnStripe");

        serverFunction({
          sourceId: account.stripeData.sourceId,
          accountId: localStorage.getItem("accountId")
        })
          .then(result => {
            console.log("deleteCardOnStripe_result", result);
          })
          .catch(error => {
            console.log("error", error);
          });
      });
  };

  updateCardOnStripe = sourceId => {
    const { account } = this.props;
    const serverFunction = firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("updateCreditCardOnStripe");

    serverFunction({
      sourceId,
      description: account.name,
      email: account.email,
      accountId: localStorage.getItem("accountId")
    })
      .then(result => {
        console.log("updateCreditCardOnStripe_result", result);
      })
      .catch(error => {
        console.log(
          "errore durante l'aggiunta della carta di credito lato server",
          error
        );
      });
  };

  handleSubmit = event => {
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault();
    const { firestore } = this.props;
    firestore
      .update(
        {
          collection: "accounts",
          doc: localStorage.getItem("accountId")
        },
        { updatingStripeData: true }
      )
      .then(docRef => {
        this.props.stripe
          .createSource({
            type: "card",
            owner: {
              name: "Jenny Rosen"
            }
          })
          .then(response => {
            if (response.error) {
              this.setState(
                {
                  errorMsg: response.error.message
                },
                () =>
                  firestore.update(
                    {
                      collection: "accounts",
                      doc: localStorage.getItem("accountId")
                    },
                    { updatingStripeData: false }
                  )
              );
            } else if (response.source) {
              this.updateCardOnStripe(response.source.id);
            }
          });
      });
  };

  formatCreditCard = card => {
    return (
      card.brand +
      " ****-" +
      card.last4 +
      " (" +
      card.exp_month +
      "/" +
      card.exp_year +
      ")"
    );
  };

  render() {
    const { classes, theme, account } = this.props;
    const {
      errorMsg,
      deleteConfirmOpen,
      missingPaymentDialogOpen
    } = this.state;
    let paymentDetail;
    if (account && account.stripeData && account.stripeData.card) {
      const card = account.stripeData.card;
      paymentDetail = this.formatCreditCard(card);
    }

    const missingPaymentCloseHandler = () => {
      this.setState({ missingPaymentDialogOpen: false });
    };

    return (
      <div className={classes.root}>
        <MainActivitySectionHeader
          text={stringTranslate("account", "paymentMethod")}
          icon={AttachMoney}
        />
        {account && (!account.stripeData || !account.stripeData.card) && (
          <form onSubmit={this.handleSubmit}>
            <Grid
              container
              spacing={24}
              alignItems="flex-end"
              className={classes.container}
            >
              <Grid item xs={12} sm={6}>
                <label>
                  <Typography variant="caption">
                    {stringTranslate("account", "paymentMethod")}
                  </Typography>
                  <div className={classes.card}>
                    <CardElement
                      id="card-element"
                      onReady={el => el.focus()}
                      style={{
                        base: {
                          color: theme.typography.color,
                          fontFamily: theme.typography.fontFamily,
                          fontSize: theme.typography.fontSize
                        }
                      }}
                    />
                  </div>
                </label>
                {(Boolean(errorMsg) || Boolean(account.stripeError)) && (
                  <FormHelperText
                    id="card-errors"
                    error={Boolean(errorMsg) || Boolean(account.stripeError)}
                  >
                    {errorMsg
                      ? errorMsg
                      : account.stripeError
                      ? account.stripeError
                      : ""}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  aria-label={stringTranslate("general", "Save")}
                >
                  {stringTranslate("general", "Save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
        {account && account.stripeData && account.stripeData.card && (
          <Grid
            container
            spacing={24}
            alignItems="flex-end"
            className={classes.container}
          >
            <Grid item xs={10} sm={6}>
              <TextField
                fullWidth
                type="text"
                label={stringTranslate("account", "paymentMethod")}
                value={paymentDetail}
                InputProps={{ readOnly: true }}
                error={Boolean(account.stripeError)}
                helperText={account.stripeError}
              />
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                title={
                  stringTranslate("general", "Delete") +
                  " " +
                  stringTranslate("account", "paymentMethod").toLowerCase()
                }
              >
                <IconButton
                  onClick={() => this.setState({ deleteConfirmOpen: true })}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <OMDeleteConfirmationAlert
          isOpen={deleteConfirmOpen}
          deleteHandler={() => {
            this.setState({ deleteConfirmOpen: false });
            this.deleteCardOnStripe();
          }}
          cancelHandler={() => this.setState({ deleteConfirmOpen: false })}
        />
        <OMConfirmationPopup
          isOpen={missingPaymentDialogOpen}
          confirmHandler={missingPaymentCloseHandler}
          closeHandler={missingPaymentCloseHandler}
          title={stringTranslate("account", "missingPaymentMethodDialogTitle")}
          text={stringTranslate("account", "missingPaymentMethodDialogBody")}
          confirmActionText="OK"
          showCancelButton={false}
        />
        <LoadingModal
          isOpen={account && account.updatingStripeData}
          text={stringTranslate("account", "updatingStripeData")}
        />
      </div>
    );
  }
}

export default injectStripe(
  compose(
    firestoreConnect(props => [
      {
        collection: "accounts",
        doc: localStorage.getItem("accountId"),
        storeAs: "account"
      }
    ]),
    connect(state => ({
      account: state.firestore.data.account
    })),
    withStyles(styles, { withTheme: true })
  )(AccountPaymentsDetail)
);
