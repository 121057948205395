import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Select, MenuItem, ListItemIcon, Typography } from "@material-ui/core";
import TextIcon from "@material-ui/icons/ShortText";
import AreaIcon from "@material-ui/icons/Subject";
import RadioIcon from "@material-ui/icons/RadioButtonChecked";
import CheckIcon from "@material-ui/icons/CheckBox";
import DropdownIcon from "@material-ui/icons/LineWeight";
import DateIcon from "@material-ui/icons/Today";
import HourIcon from "@material-ui/icons/AccessTime";

import { stringTranslate } from "languages/OMTranslate";

import InputSelect from "./InputSelect";
import { FieldTypes } from "../ModulesConfig";

function getPropertyByType(type) {
  const traslatedTexts = stringTranslate("modules", "fieldTypes");
  switch (type) {
    case FieldTypes.text:
      return [TextIcon, traslatedTexts.text];
    case FieldTypes.area:
      return [AreaIcon, traslatedTexts.area];
    case FieldTypes.radio:
      return [RadioIcon, traslatedTexts.radio];
    case FieldTypes.check:
      return [CheckIcon, traslatedTexts.check];
    case FieldTypes.dropdown:
      return [DropdownIcon, traslatedTexts.dropdown];
    case FieldTypes.date:
      return [DateIcon, traslatedTexts.date];
    case FieldTypes.time:
      return [HourIcon, traslatedTexts.time];
    default:
      return [];
  }
}

const styles = () => ({
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const SelectType = ({ classes, selectedType, types, onChange }) => (
  <Select
    value={selectedType}
    onChange={e => {
      const { value } = e.target;
      if (value !== selectedType) {
        onChange(e.target.value);
      }
    }}
    input={<InputSelect />}
  >
    {types.map(type => {
      const [Icon, text] = getPropertyByType(type);
      return (
        <MenuItem key={type} value={type}>
          <div className={classes.content}>
            <ListItemIcon className={classes.icon}>
              <Icon />
            </ListItemIcon>
            <Typography>{text}</Typography>
          </div>
        </MenuItem>
      );
    })}
  </Select>
);

SelectType.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  types: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  selectedType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

SelectType.defaultProps = {
  selectedType: "TEXT"
};

export default withStyles(styles)(SelectType);
