import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  actionBar: {
    display: 'flex',
    flex: 1,
    padding: theme.spacing.unit * 3,
    flexWrap: 'wrap'
  }
});

const ActionBar = ({ children, classes }) => (
  <div className={classes.actionBar}>{children}</div>
);

ActionBar.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  children: PropTypes.node
};

ActionBar.defaultProps = {
  selected: false
};

export default withStyles(styles, { withTheme: true })(ActionBar);
