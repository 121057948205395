import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";

import { firestoreConnect } from "react-redux-firebase";
import Paper from "@material-ui/core/Paper";
import { stringTranslate } from "languages/OMTranslate";
import { WrappedVirtualizedTable } from "../common/WrappedVirtualizedTable";
import { setSelectedNote, toggleNoteModal } from "store/actions/customersPage";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    height: "100%",
    width: "100%",
    order: 3,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      minHeight: "200px"
    }
  }
});

class NotesList extends Component {
  render() {
    const {
      filteredNotes,
      setSelectedNote,
      toggleNoteModal,
      classes
    } = this.props;

    return (
      <Paper className={classes.root}>
        {filteredNotes && filteredNotes.length > 0 ? (
          <WrappedVirtualizedTable
            rowCount={filteredNotes.length}
            rowGetter={({ index }) => {
              return filteredNotes[index];
            }}
            onRowClick={event => {
              setSelectedNote(event.rowData);
              toggleNoteModal();
            }}
            columns={[
              {
                width: 140,
                flexGrow: 0.0,
                label: stringTranslate("customers", "Note Date"),
                dataKey: "dateFormatted"
              },
              {
                width: 300,
                flexGrow: 1.1,
                label: stringTranslate("customers", "Note Description"),
                dataKey: "description"
              }
            ]}
          />
        ) : (
          <Typography variant="body1" className="ml-12">
            {stringTranslate("general", "No elements")}
          </Typography>
        )}
      </Paper>
    );
  }
}

NotesList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer,
  notes: state.firestore.ordered.notes
});

const mapDispatchToProps = dispatch => ({
  setSelectedNote: note => dispatch(setSelectedNote(note)),
  toggleNoteModal: () => dispatch(toggleNoteModal())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(NotesList);
