import React from "react";
import PropTypes from "prop-types";
import FormGroup from "@material-ui/core/FormGroup";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import CreatableSelect from "react-select/lib/Creatable";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import NoteIcon from "@material-ui/icons/Note";
import ModulesIcon from "@material-ui/icons/Description";
import { stringTranslate } from "languages/OMTranslate";
import OMReactSelect from "../../../common/OMReactSelect";
import OMPhoneInput from "main/content/common/OMPhoneInput";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "@lodash";
import classNames from "classnames";
import checkAccountPermission from "main/common/account/accountEditionConfig";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import NumberFormat from "react-number-format";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialSelect from "@material-ui/core/Select";

const styles = theme => ({
  container: {
    display: "flex",
    flexFlow: "column nowrap"
  },
  detailContainer: {
    flexFlow: "column nowrap",
    padding: "8px 12px"
  },
  dialogFormGroup: {
    alignItems: "flex-start",
    marginBottom: 2 * theme.spacing.unit
  },
  textField: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    width: 150
  },
  littleTextField: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    width: 80
  },
  orderTextField: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    width: "90px"
  },
  expansionPanelExpanded: {
    margin: "6px 0"
  },
  expansionPanelSummaryExpanded: {
    padding: "0 12px"
  },
  inputText: {
    fontSize: theme.text.medium
  },
  phoneDiv: {
    marginLeft: theme.spacing.unit * 2
  },
  datePicker: {
    width: 150
  },
  descrFormGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing.unit
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing.unit,
    right: theme.spacing.unit
  },
  buttonsDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: theme.spacing.unit * 2
  },
  otherButtonsDiv: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: theme.spacing.unit * 2
  },
  buttonIcon: {
    marginRight: "10px",
    fontSize: "20px"
  },
  spacer: {
    flex: 1,
    width: "100%"
  },
  inputLabel: {
    fontSize: "18px"
  }
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      suffix=" min."
    />
  );
}

const TaskForm = ({
  task,
  poiOptions,
  partyOptions,
  contactNameOptions,
  isLoading,
  contactPhoneReadonly,
  errors,
  warnings,
  canSubmit,
  resourcesOptions,
  detailDataPanelOpen,
  taskId,
  onToggleDetailPanel,
  onClose,
  onSubmit,
  onDelete,
  onManageModules,
  onManageAttachments,
  onDescriptionChange,
  onAutocompleteBaseChange,
  onAutocompletePartyChange,
  onAutocompletePartyCreate,
  onAutocompleteContactChange,
  onAutocompleteContactCreate,
  onChange,
  onDateChange,
  onDurationChange,
  onPhoneChange,
  searchPois,
  classes,
  userCountry,
  account,
  notifyOnArriveChange,
  notifyBeforeChange,
  onManageNotes,
  onCodeChange,
  lastNote,
  handleNoteTextChange,
  executionOrders
}) => {
  const {
    description,
    partyId,
    poiId,
    resourceId,
    contactName,
    contactPhone,
    beginning,
    ending,
    duration,
    notifyOnArrive,
    notifyBefore,
    code,
    executionOrder
  } = task;
  return (
    <React.Fragment>
      <DialogTitle id="simple-dialog-title" disableTypography>
        <Typography variant="h3">{stringTranslate("tasks", "task")}</Typography>
      </DialogTitle>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <form
          onSubmit={onSubmit}
          className={classes.container}
          noValidate
          autoComplete="off"
        >
          <FormGroup className={classes.descrFormGroup}>
            <TextField
              id="code"
              label={stringTranslate("tasks", "code")}
              value={code}
              onChange={onCodeChange}
              margin="dense"
              required
              style={{ width: "15%" }}
              inputProps={{ maxLength: 6 }}
            />
            <TextField
              id="description"
              label={stringTranslate("tasks", "description")}
              value={description}
              autoFocus
              onChange={onDescriptionChange}
              margin="dense"
              required
              style={{ width: "80%" }}
            />
          </FormGroup>
          <ExpansionPanel
            expanded={detailDataPanelOpen}
            classes={{ expanded: classes.expansionPanelExpanded }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={onToggleDetailPanel}
              classes={{
                expanded: classes.expansionPanelSummaryExpanded
              }}
            >
              <Typography className={classes.heading} color="primary">
                {stringTranslate("general", "otherInfo")}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.detailContainer}>
              <FormGroup className={classes.dialogFormGroup}>
                <OMReactSelect
                  value={resourceId}
                  onChange={onAutocompleteBaseChange("resourceId")}
                  options={resourcesOptions}
                  label={stringTranslate("tasks", "resource")}
                  componentType={Select}
                  isClearable
                />
              </FormGroup>
              {checkAccountPermission(account, "taskList", "where") && (
                <FormGroup className={classes.dialogFormGroup}>
                  <OMReactSelect
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={onAutocompletePartyChange("partyId")}
                    onCreateOption={onAutocompletePartyCreate("partyId")}
                    options={partyOptions}
                    value={partyId}
                    componentType={CreatableSelect}
                    label={stringTranslate("tasks", "company")}
                  />
                </FormGroup>
              )}
              {checkAccountPermission(account, "taskList", "where") && (
                <FormGroup className={classes.dialogFormGroup}>
                  <OMReactSelect
                    loadOptions={searchPois}
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={onAutocompleteBaseChange("poiId")}
                    defaultOptions={poiOptions}
                    value={poiId}
                    componentType={AsyncSelect}
                    label={stringTranslate("tasks", "address")}
                  />
                </FormGroup>
              )}
              {checkAccountPermission(account, "taskList", "where") && (
                <FormGroup row className={classes.dialogFormGroup}>
                  <OMReactSelect
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={onAutocompleteContactChange("contactName")}
                    onCreateOption={onAutocompleteContactCreate("contactName")}
                    options={contactNameOptions}
                    value={contactName}
                    componentType={CreatableSelect}
                    label={stringTranslate("tasks", "contact")}
                    width={200}
                  />
                  <OMPhoneInput
                    id="contactPhone"
                    label={stringTranslate("tasks", "phone")}
                    value={contactPhone}
                    changeValueHandler={onPhoneChange}
                    containerClassName={classes.phoneDiv}
                    country={userCountry}
                    disabled={contactPhoneReadonly}
                  />
                  <div style={{ marginLeft: "16px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={notifyOnArrive}
                          onChange={notifyOnArriveChange}
                          disabled={!contactName}
                        />
                      }
                      label={stringTranslate("tasks", "notify")}
                    />
                  </div>
                  <TextField
                    id="nofityBefore"
                    label={stringTranslate("tasks", "before")}
                    className={classes.littleTextField}
                    style={notifyOnArrive ? {} : { display: "none" }}
                    value={notifyBefore}
                    onChange={event => notifyBeforeChange(event.target.value)}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputText
                    }}
                    margin="none"
                  />
                </FormGroup>
              )}
              <FormGroup row className={classes.dialogFormGroup}>
                <div className="flex flex-col">
                  <InputLabel
                    className={classNames("form-input-label", {
                      error: Boolean(errors.beginning)
                    })}
                  >
                    {stringTranslate("tasks", "beginning")}
                  </InputLabel>
                  <DatePicker
                    selected={beginning}
                    onChange={onDateChange("beginning")}
                    showTimeSelect
                    className={classNames("date-picker-input", {
                      error: Boolean(errors.beginning)
                    })}
                    placeholderText={stringTranslate("tasks", "beginning")}
                    dateFormat={stringTranslate("general", "fsnDateTimeFormat")}
                    isClearable={true}
                    popperPlacement="top-start"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption={stringTranslate("general", "timeCaption")}
                  />
                  {Boolean(errors.beginning) ? (
                    <FormHelperText error={true}>
                      {errors.beginning}
                    </FormHelperText>
                  ) : Boolean(warnings) && Boolean(warnings.beginning) ? (
                    <FormHelperText style={{ color: "#ffa000" }}>
                      {warnings.beginning}
                    </FormHelperText>
                  ) : null}
                </div>
                <TextField
                  id="duration"
                  label={stringTranslate("tasks", "duration")}
                  className={classes.textField}
                  value={duration}
                  onChange={onDurationChange("duration")}
                  InputProps={{
                    className: classes.inputText
                  }}
                  InputLabelProps={{
                    className: classes.inputText
                  }}
                  margin="none"
                />
                <div className="flex flex-col">
                  <InputLabel
                    className={classNames("form-input-label", {
                      error: Boolean(errors.ending)
                    })}
                  >
                    {stringTranslate("tasks", "ending")}
                  </InputLabel>
                  <DatePicker
                    selected={ending}
                    onChange={onDateChange("ending")}
                    showTimeSelect
                    className={classNames("date-picker-input", {
                      error: Boolean(errors.ending)
                    })}
                    placeholderText={stringTranslate("tasks", "ending")}
                    dateFormat={stringTranslate("general", "fsnDateTimeFormat")}
                    isClearable={true}
                    popperPlacement="top-start"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption={stringTranslate("general", "timeCaption")}
                  />
                  {Boolean(errors.ending) ? (
                    <FormHelperText error={true}>
                      {errors.ending}
                    </FormHelperText>
                  ) : Boolean(warnings) && Boolean(warnings.ending) ? (
                    <FormHelperText style={{ color: "#ffa000" }}>
                      {warnings.ending}
                    </FormHelperText>
                  ) : null}
                </div>
                <FormControl
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                    minWidth: "120px"
                  }}
                >
                  <InputLabel htmlFor="execution-order">
                    {stringTranslate("tasks", "executionOrder")}
                  </InputLabel>
                  <MaterialSelect
                    value={executionOrder}
                    onChange={event => onChange("executionOrder", event)}
                    inputProps={{
                      name: "executionOrder",
                      id: "execution-order"
                    }}
                  >
                    {executionOrders.map(ord => {
                      return (
                        <MenuItem key={ord} value={ord}>
                          {ord}
                        </MenuItem>
                      );
                    })}
                  </MaterialSelect>
                </FormControl>
              </FormGroup>
              <FormGroup row className={classes.dialogFormGroup}>
                <TextField
                  id="task-note"
                  label="Nota"
                  multiline
                  rows="2"
                  rowsMax="2"
                  value={lastNote}
                  onChange={handleNoteTextChange}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  style={{
                    width: "100%",
                    marginLeft: "0px",
                    marginRight: "0px"
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: { root: classes.inputLabel }
                  }}
                />
              </FormGroup>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <div className={classes.buttonsDiv}>
              <Button
                type="submit"
                aria-label="Check"
                variant="contained"
                color="secondary"
                className="mr-12"
                disabled={!Boolean(canSubmit) || !_.isEmpty(errors)}
              >
                {stringTranslate("general", "Save")}
              </Button>
              {taskId && (
                <Button
                  onClick={onDelete}
                  aria-label="Clear"
                  variant="contained"
                  color="primary"
                  className="mr-12"
                >
                  {stringTranslate("general", "Delete")}
                </Button>
              )}
            </div>
            {taskId ? (
              <div className={classes.otherButtonsDiv}>
                <div className={classes.spacer} />
                <Button
                  onClick={onManageNotes}
                  className="ml-12"
                  variant="outlined"
                >
                  <NoteIcon className={classes.buttonIcon} />
                  {stringTranslate("tasks", "notes")}
                </Button>
                {checkAccountPermission(account, "toolbar", "Modules") && (
                  <Button
                    onClick={onManageModules}
                    className="ml-12"
                    variant="outlined"
                  >
                    <ModulesIcon className={classes.buttonIcon} />
                    {stringTranslate("tasks", "modules")}
                  </Button>
                )}
                <Button
                  onClick={onManageAttachments}
                  className="ml-12"
                  variant="outlined"
                >
                  <AttachFileIcon className={classes.buttonIcon} />
                  {stringTranslate("tasks", "attachments")}
                </Button>
              </div>
            ) : null}
          </div>
        </form>
      </DialogContent>
    </React.Fragment>
  );
};

TaskForm.propTypes = {
  taskId: PropTypes.string,
  task: PropTypes.shape().isRequired,
  poiOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  partyOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  contactNameOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  contactPhoneReadonly: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    beginning: PropTypes.string,
    ending: PropTypes.string
  }).isRequired,
  canSubmit: PropTypes.bool.isRequired,
  resourcesOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  detailDataPanelOpen: PropTypes.bool.isRequired,
  onToggleDetailPanel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onManageModules: PropTypes.func.isRequired,
  onManageAttachments: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onAutocompleteBaseChange: PropTypes.func.isRequired,
  onAutocompletePartyChange: PropTypes.func.isRequired,
  onAutocompletePartyCreate: PropTypes.func.isRequired,
  onAutocompleteContactChange: PropTypes.func.isRequired,
  onAutocompleteContactCreate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onDurationChange: PropTypes.func.isRequired,
  onPhoneChange: PropTypes.func.isRequired,
  searchPois: PropTypes.func.isRequired,
  userCountry: PropTypes.string,
  account: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired // Oggetto creato da withStyles
};

TaskForm.defaultProps = {
  taskId: undefined
};

export default withStyles(styles)(TaskForm);
