import React, { Component } from "react";
import { connect } from "react-redux";
import { FuseScrollbars } from "@fuse";
import {
  withStyles,
  List,
  Popover,
  MenuItem,
  IconButton,
  Typography
} from "@material-ui/core";
import ChatRoomListItem from "./ChatRoomListItem";
import PropTypes from "prop-types";
import { stringTranslate } from "languages/OMTranslate";
import { compose } from "redux";
import { Forum, MoreVert } from "@material-ui/icons";
import MainActivitySectionHeader from "../MainActivitySectionHeader";
import OMSearchAddBar from "../../common/OMSearchAddBar";
import { Link } from "react-router-dom";

const styles = theme => ({
  scrollbar: {
    maxHeight: "calc(100vh - 185px)",
    flex: 1
  },
  listItemText: {
    root: {
      paddingRight: 0
    },
    primary: {
      fontSize: theme.text.medium
    }
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  }
});

class ChatList extends Component {
  state = {
    search: "",
    moreButton: null,
    show: "list"
  };

  setSearch = event => {
    this.setState({ search: event.target.value });
  };

  clearSearch = () => {
    this.setState({ search: "" });
  };

  showOptions = event => this.setState({ moreButton: event.currentTarget });

  closeOptions = () => this.setState({ moreButton: null });

  render() {
    const { classes, chatRooms, resources, user, language } = this.props;

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={classes.titleContainer}>
          <MainActivitySectionHeader
            icon={Forum}
            text={stringTranslate("chat", "chat")}
          />
          <IconButton onClick={this.showOptions}>
            <MoreVert />
          </IconButton>
          <Popover
            open={Boolean(this.state.moreButton)}
            anchorEl={this.state.moreButton}
            onClose={this.closeOptions}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            classes={{
              paper: "py-8"
            }}
          >
            <React.Fragment>
              <MenuItem
                component={Link}
                to="#"
                onClick={this.props.newBroadcast}
              >
                <Typography variant="body1" className={classes.listItemText}>
                  {stringTranslate("chat", "broadcast")}
                </Typography>
              </MenuItem>
            </React.Fragment>
          </Popover>
        </div>
        <OMSearchAddBar
          searchText={this.state.search}
          textChangeHandler={this.setSearch}
          textClearHandler={this.clearSearch}
        />
        <FuseScrollbars className={classes.scrollbar}>
          <List>
            <React.Fragment>
              {chatRooms.map(room => {
                const resource = resources ? resources[room.resourceId] : null;
                if (
                  user &&
                  room.users.indexOf(user.uid) >= 0 &&
                  resource &&
                  resource.name
                    .toLowerCase()
                    .includes(this.state.search.toLowerCase())
                ) {
                  return (
                    <ChatRoomListItem
                      key={room.id}
                      selectedRoom={room}
                      selectChatHandler={this.props.selectChatHandler}
                      language={language}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </React.Fragment>
          </List>
        </FuseScrollbars>
      </div>
    );
  }
}

ChatList.propTypes = {
  classes: PropTypes.object.isRequired,
  chatRooms: PropTypes.array,
  resources: PropTypes.object
};

const mapStateToProps = state => ({
  user: state.auth.user,
  resources: state.firestore.data.resources,
  language: state.auth.user.data.userLanguage
});

export default compose(connect(mapStateToProps))(
  withStyles(styles, { withTheme: true })(ChatList)
);
