import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  label: {
    color: theme.palette.primary.normal,
    fontWeight: 400,
    fontSize: "12px",
    marginBottom: "10px"
  },
  labelBold: {
    color: theme.palette.primary.normal,
    fontWeight: 700,
    fontSize: "14px"
  }
});

const LabelWithText = ({ classes, label, text }) => {
  return (
    <Typography className={classes.label}>
      {`${label}: `}
      <span className={classes.labelBold}>{text}</span>
    </Typography>
  );
};

LabelWithText.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(LabelWithText);
