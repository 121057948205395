import * as resourceConstants from "./resourceConstants";

export function sortResources(resources) {
  return resources.sort((resA, resB) => resA.name.localeCompare(resB.name));
}

export function splitResourcesByLocalization(resources) {
  const resObj = {};
  resObj.withPosition = resources.filter(
    res => res.lastPosition && res.lastPosition.lat_long
  );
  resObj.withoutPosition = resources.filter(
    res => !res.lastPosition || !res.lastPosition.lat_long
  );
  return resObj;
}

export function isResourceOffline(resource) {
  return (
    resource &&
    resource.device &&
    resource.device.deviceStatus &&
    resource.device.deviceStatus.connection ===
      resourceConstants.PHONE_STATUS_OFFLINE
  );
}

export function filterResourcesByString(resources, pattern) {
  const searchText = pattern.toLowerCase();
  return resources.filter(res => {
    let found = false;
    if (res.name && res.name.toLowerCase().includes(searchText)) {
      return true;
    }

    if (Array.isArray(res.tags)) {
      for (const el of res.tags) {
        if (el.toLowerCase().includes(searchText)) {
          found = true;
          break;
        }
      }
    }

    return found;
  });
}
