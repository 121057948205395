import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate } from "languages/OMTranslate";
import firebase, {
  getFirestoreCollection
} from "../../../../../firestoreAccount";
import * as taskConstants from "../../../../common/tasks/taskConstants";
import { getEndedExecutionsStatuses } from "main/common/tasks/taskHelper";
import TaskForm from "./TaskForm";
import TaskAttachments from "./TaskAttachments";
import TaskModules from "./TaskModules";
import OMDeleteConfirmationAlert from "../../../common/OMDeleteConfirmationAlert";
import TaskNotes from "./TaskNotes";
import { passValueToForm } from "store/actions/mainActivityPage";

const MODE_TASK_DETAIL = "detail";
const MODE_ATTACHMENTS = "attachments";
const MODE_MODULES = "modules";
const MODE_NOTES = "notes";

const styles = () => ({
  dialogPaper: {
    maxWidth: "700px"
  }
});

class TaskDetail extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribe = null;
    this.state = {
      task: this.initNewTask(),
      isLoading: false,
      contactPhoneReadonly: true,
      partyOptions: null,
      poiOptions: null,
      contactNameOptions: null,
      errors: {},
      warnings: {},
      deleteConfirmationOpen: false,
      mode: MODE_TASK_DETAIL,
      lastNote: null,
      noteText: "",
      loadedTask: null
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.taskId === undefined &&
      props.taskId === null &&
      state.mode !== MODE_TASK_DETAIL
    ) {
      return {
        mode: MODE_TASK_DETAIL
      };
    }
    return null;
  }

  isEmpty = obj => {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  };

  getLastNote = taskId => {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.unsubscribe = firebase
      .firestore()
      .collection(`${getFirestoreCollection()}/tasks/${taskId}/notes`)
      .orderBy("creationDate", "desc")
      .limit(1)
      .onSnapshot(querySnapshot => {
        if (!querySnapshot.empty) {
          this.setState({
            lastNote: querySnapshot.docs[0],
            noteText: querySnapshot.docs[0].data().note
          });
        } else {
          this.setState({ lastNote: null, noteText: "" });
        }
      });
  };

  handleNoteTextChange = event => {
    this.setState({ noteText: event.target.value });
  };

  componentDidMount() {
    if (this.props.taskId) {
      this.getLastNote(this.props.taskId);
    }
  }

  componentDidUpdate(prevProps) {
    const { partyOptions, poiOptions, contactNameOptions } = this.state;
    const {
      allPartiesOrd,
      freePoisOrd,
      allContactsOrd,
      taskId,
      allParties,
      allPois,
      allResources,
      allContacts
    } = this.props;

    if (!partyOptions && allPartiesOrd) {
      let partyOptionsTemp = [];
      if (allPartiesOrd) {
        partyOptionsTemp = allPartiesOrd.map(el => ({
          value: el.id,
          label: el.name
        }));
      }
      this.setState({
        partyOptions: partyOptionsTemp
      });
    }

    if (!poiOptions && freePoisOrd) {
      this.setState({
        poiOptions: [
          {
            options: freePoisOrd.map(el => ({
              value: el.id,
              label: el.address
            })),
            label: stringTranslate("tasks", "savedAddresses")
          }
        ]
      });
    }

    if (!contactNameOptions && allContactsOrd) {
      const filterContacts = allContactsOrd.filter(contact => {
        if (contact.id && contact.name) return true;
        else return false;
      });
      this.setState({
        contactNameOptions: filterContacts.map(el => ({
          value: el.id,
          label: el.name
        }))
      });
    }

    // Sono stati scritti in redux dei dati per popolare la form del task
    if (
      this.isEmpty(prevProps.valuesForForm) &&
      !this.isEmpty(this.props.valuesForForm)
    ) {
      if (this.props.valuesForForm.poiId) {
        this.setState({
          task: { ...this.state.task, poiId: this.props.valuesForForm.poiId }
        });
      }
      if (this.props.valuesForForm.partyId) {
        this.setState({
          task: {
            ...this.state.task,
            partyId: this.props.valuesForForm.partyId
          }
        });
      }
      // "Consuma" il dato salvato in redux per non renderlo più utilizzabile
      this.props.passValueToForm({});
      return;
    }

    if (prevProps.taskId !== taskId) {
      this.getLastNote(taskId);

      if (taskId) {
        firebase
          .firestore()
          .collection(getFirestoreCollection() + "/tasks")
          .doc(taskId)
          .get()
          .then(doc => {
            const data = doc.data();
            this.setState({ loadedTask: data });

            const task = data;
            const partyId = task.partyId
              ? {
                  value: task.partyId,
                  label: allParties[task.partyId]
                    ? allParties[task.partyId].name
                    : ""
                }
              : null;
            const poiId = task.poiId
              ? {
                  value: task.poiId,
                  label: allPois[task.poiId] ? allPois[task.poiId].address : ""
                }
              : null;
            const resourceId = task.resourceId
              ? {
                  value: task.resourceId,
                  label: allResources[task.resourceId]
                    ? allResources[task.resourceId].name
                    : ""
                }
              : null;
            const contactName = task.contactId
              ? {
                  value: task.contactId,
                  label: allContacts[task.contactId]
                    ? allContacts[task.contactId].name
                    : ""
                }
              : null;
            const contactPhone =
              task.contactId && allContacts[task.contactId]
                ? allContacts[task.contactId].phone
                : "";

            this.setState({
              task: {
                description: task.description,
                code: task.code ? task.code : "",
                executionStatus: task.executionStatus,
                transmissionStatus: task.transmissionStatus,
                duration: this.getDurationToShow(task),
                // duration: task.duration ? task.duration : 0,
                beginning: task.beginning
                  ? new Date(task.beginning.seconds * 1000)
                  : null,
                ending: task.ending
                  ? new Date(task.ending.seconds * 1000)
                  : null,
                partyId,
                poiId,
                contactName,
                contactPhone,
                resourceId,
                notifyOnArrive: task.notifyOnArrive
                  ? task.notifyOnArrive
                  : false,
                notifyBefore: task.notifyBefore ? task.notifyBefore : 0,
                executionOrder: task.executionOrder ? task.executionOrder : ""
              },
              errors: {},
              warnings: {},
              contactPhoneReadonly: contactPhone !== ""
            });
            if (partyId) {
              this.getPoiOptions(partyId.value);
              this.getContactOptions(partyId.value);
            }
          });
      } else {
        this.setState({
          task: this.initNewTask(),
          // canSubmit: false,
          errors: {},
          warnings: {}
        });
      }
    }
  }

  randomCode = length => {
    var result = "";
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz";
    for (var i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  };

  initNewTask = () => {
    return {
      description: "",
      code: this.randomCode(6),
      partyId: null,
      poiId: null,
      resourceId: null,
      contactName: null,
      beginning: null,
      ending: null,
      contactPhone: "",
      duration: "",
      executionStatus: taskConstants.STATUS_TOEXECUTE,
      transmissionStatus: taskConstants.TRANSMISSION_SENT,
      notifyOnArrive: false,
      notifyBefore: 30,
      executionOrder: ""
    };
  };

  canSaveTask = () => {
    const { task } = this.state;
    return Boolean(
      task.description &&
        task.code &&
        !getEndedExecutionsStatuses().includes(task.executionStatus)
    );
  };

  closeDeleteConfirmAlert = () => {
    this.setState({ deleteConfirmationOpen: false });
  };

  openDeleteConfirmAlert = () => {
    this.setState({ deleteConfirmationOpen: true });
  };

  handleClose = () => {
    const { onClose, selectedValue } = this.props;
    this.setState({ mode: MODE_TASK_DETAIL });

    if (this.unsubscribe) {
      this.unsubscribe();
    }

    this.setState({ task: this.initNewTask() });

    onClose(selectedValue);
  };

  toggleDetailPanel = () => {
    const { detailDataPanelToggler } = this.props;
    detailDataPanelToggler();
  };

  handleChange = (name, event) => {
    const { task } = this.state;
    this.setState({ task: { ...task, [name]: event.target.value } });
  };

  handlePhoneChange = phoneNumber => {
    const { task } = this.state;
    this.setState({ task: { ...task, contactPhone: phoneNumber } });
  };

  handleDescriptionChange = event => {
    const { task } = this.state;
    this.setState({
      task: { ...task, description: event.target.value }
    });
  };

  handleDurationChange = name => event => {
    const { task, errors } = this.state;
    if (errors.beginning || errors.ending) {
      return;
    }

    let newDuration = parseInt(event.target.value, 10);
    if (isNaN(newDuration)) {
      newDuration = 0;
    }
    const taskToSet = {
      ...task,
      duration: newDuration
    };

    if (task.beginning) {
      taskToSet.ending = new Date(
        task.beginning.getTime() + newDuration * 60 * 1000 // millisecondi
      );
    } else if (task.ending) {
      taskToSet.beginning = new Date(
        task.ending.getTime() - newDuration * 60 * 1000 // millisecondi
      );
    }

    this.setState({ task: taskToSet });
  };

  handleDateChange = name => date => {
    const { task, errors } = this.state;
    const newJsDate = date;
    const taskToSet = {
      ...task,
      [name]: newJsDate
    };
    if (newJsDate === null) {
      delete errors.beginning;
      delete errors.ending;
      this.setState({
        task: taskToSet,
        errors
      });
      return;
    }

    if (name === "beginning") {
      if (task.ending) {
        if (newJsDate.getTime() > task.ending.getTime()) {
          // taskToSet.beginning = task.ending;
          taskToSet.duration = 0;
          this.setState({
            errors: {
              ...errors,
              beginning: stringTranslate("tasks", "dateSequenceError"),
              ending: stringTranslate("tasks", "dateSequenceError")
            },
            task: taskToSet
          });
          return;
        }

        taskToSet.duration = Math.round(
          (task.ending.getTime() - newJsDate.getTime()) / 1000 / 60 // nuova durata in minuti
        );
      } else if (task.duration) {
        taskToSet.ending = new Date(
          newJsDate.getTime() + task.duration * 60 * 1000 // millisecondi
        );
      } else {
        // ho solo il beginning settato
        taskToSet.duration = 30; // setto 30 minuti di default
        taskToSet.ending = new Date(
          newJsDate.getTime() + 30 * 60 * 1000 // millisecondi
        );
      }
    }

    if (name === "ending") {
      if (task.beginning) {
        if (newJsDate.getTime() < task.beginning.getTime()) {
          // taskToSet.ending = task.beginning;
          taskToSet.duration = 0;
          this.setState({
            errors: {
              ...errors,
              beginning: stringTranslate("tasks", "dateSequenceError"),
              ending: stringTranslate("tasks", "dateSequenceError")
            },
            task: taskToSet
          });
          return;
        }

        taskToSet.duration = Math.round(
          (newJsDate.getTime() - task.beginning.getTime()) / 1000 / 60 // nuova durata in minuti
        );
      } else if (task.duration) {
        taskToSet.beginning = new Date(
          newJsDate.getTime() - task.duration * 60 * 1000 // millisecondi
        );
      } else {
        // ho solo l'ending settato
        taskToSet.duration = 30; // setto 30 minuti di default
        taskToSet.beginning = new Date(
          newJsDate.getTime() - 30 * 60 * 1000 // millisecondi
        );
      }
    }

    delete errors.beginning;
    delete errors.ending;
    this.setState({
      task: taskToSet,
      errors,
      warnings: this.checkOtherTaskTime(
        task.resourceId ? task.resourceId.value : null,
        taskToSet.beginning,
        taskToSet.ending
      )
    });
  };

  checkOtherTaskTime = (resourceId, start, end) => {
    let warnings = {};

    if (!resourceId || !start || !end) return warnings;

    if (resourceId && this.props.tasks) {
      for (var taskId in this.props.tasks) {
        if (this.props.tasks.hasOwnProperty(taskId)) {
          const t = this.state.loadedTask;
          if (
            t.executionStatus === "COMPLETED" ||
            t.executionStatus === "ABORTED"
          ) {
            continue;
          }

          if (
            t.resourceId &&
            t.resourceId === resourceId &&
            t.beginning &&
            t.ending
          ) {
            const compareStart = t.beginning.seconds * 1000;
            const compareEnd = t.ending.seconds * 1000;

            if (
              start.getTime() <= compareEnd &&
              end.getTime() >= compareStart
            ) {
              warnings["beginning"] = stringTranslate(
                "tasks",
                "warningSameTime"
              );
              warnings["ending"] = stringTranslate("tasks", "warningSameTime");
              return warnings;
            }
          }
        }
      }
    }
    return warnings;
  };

  handleAutocompleteChange = name => (object, additionalTaskFields = {}) => {
    const { task } = this.state;
    this.setState({
      task: {
        ...task,
        ...additionalTaskFields,
        [name]: object
      },
      warnings:
        name === "resourceId"
          ? object
            ? this.checkOtherTaskTime(object.value, task.beginning, task.ending)
            : {}
          : {}
    });
  };

  handleAutocompletePoiCreate = name => inputValue => {
    const { task, poiOptions } = this.state;
    this.setState({ isLoading: true });

    const { firestore } = this.props;
    const { partyId } = task;
    const newObj = {
      address: inputValue,
      partyId: partyId ? partyId.value : ""
    };

    firestore
      .add({ collection: `${getFirestoreCollection()}/pois` }, newObj)
      .then(docRef => {
        const newOption = {
          label: newObj.address,
          value: docRef.id
        };
        this.setState({
          isLoading: false,
          poiOptions: [...poiOptions, newOption],
          task: { ...task, poiId: newOption }
        });
      });
  };

  handleAutocompleteContactCreate = name => inputValue => {
    this.setState({ isLoading: true });
    const { contactNameOptions, task } = this.state;

    const { firestore } = this.props;
    const { partyId } = task;
    const newObj = {
      name: inputValue,
      phone: "",
      partyId: partyId ? partyId.value : ""
    };

    firestore
      .add({ collection: `${getFirestoreCollection()}/contacts` }, newObj)
      .then(docRef => {
        const newOption = {
          label: newObj.name,
          value: docRef.id
        };
        this.setState({
          isLoading: false,
          contactNameOptions: [...contactNameOptions, newOption],
          contactPhoneReadonly: false,
          task: { ...task, contactName: newOption }
        });
      });
  };

  handleAutocompletePartyCreate = name => inputValue => {
    this.setState({ isLoading: true });
    const { partyOptions, task } = this.state;
    const { firestore } = this.props;
    const newObj = {
      name: inputValue,
      isActive: true,
      type: "CUSTOMER"
    };

    firestore
      .add({ collection: `${getFirestoreCollection()}/parties` }, newObj)
      .then(docRef => {
        const newOption = {
          label: newObj.name,
          value: docRef.id
        };

        this.setState({
          isLoading: false,
          partyOptions: [...partyOptions, newOption],
          task: { ...task, partyId: newOption }
        });
      });
  };

  handleAutocompletePartyChange = name => object => {
    const { task } = this.state;
    // settato precedentemente, se diversi pulisco contatto e poi

    let toClearPoiContact = false;
    if (task.partyId) {
      if (!object || object.value !== task.partyId.value) {
        toClearPoiContact = true;
      }
    } else {
      toClearPoiContact = true;
    }
    if (toClearPoiContact) {
      this.handleAutocompleteChange(name)(object, {
        contactName: null,
        poiId: null,
        contactPhone: ""
      });
    } else {
      this.handleAutocompleteChange(name)(object);
    }
    const partyId = object ? object.value : "";
    this.getPoiOptions(partyId);
    this.getContactOptions(partyId);
  };

  handleAutocompleteContactChange = name => object => {
    const { task } = this.state;
    const { allContacts } = this.props;

    if (object) {
      this.setState({
        task: {
          ...task,
          contactName: object,
          contactPhone: allContacts[object.value].phone
            ? allContacts[object.value].phone
            : ""
        },
        contactPhoneReadonly: false
      });
    } else {
      this.setState({
        task: {
          ...task,
          contactName: object,
          contactPhone: ""
        },
        contactPhoneReadonly: true
      });
    }
  };

  handleCodeChange = event => {
    const { task } = this.state;
    this.setState({ task: { ...task, code: event.target.value } });
  };

  getPoiOptions = partyId => {
    const queryConfig = {
      collection: `${getFirestoreCollection()}/pois`,
      where: ["partyId", "==", partyId],
      orderBy: "address"
    };
    this.retrieveDataForOptions(queryConfig, "address", poiOptions => {
      this.setState({
        poiOptions: [
          {
            options: poiOptions,
            label: stringTranslate("tasks", "savedAddresses")
          }
        ]
      });
    });
  };

  getContactOptions = partyId => {
    const queryConfig = {
      collection: `${getFirestoreCollection()}/contacts`,
      where: ["partyId", "==", partyId],
      orderBy: "name"
    };
    this.retrieveDataForOptions(queryConfig, "name", contactNameOptions => {
      this.setState({ contactNameOptions });
    });
  };

  searchPois = (inputValue, callback) => {
    const { poiOptions } = this.state;
    const savedPoiOptions = poiOptions[0].options;
    const optionsArray = [
      {
        options: savedPoiOptions.filter(opt =>
          opt.label.toLowerCase().includes(inputValue.toLowerCase())
        ),
        label: stringTranslate("tasks", "savedAddresses")
      }
    ];
    const service = new window.google.maps.places.AutocompleteService();

    const displaySuggestions = (predictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        callback(optionsArray);
      }

      const predictionsFormatted = [];
      if (predictions) {
        predictions.forEach(prediction => {
          predictionsFormatted.push({
            label: prediction.description,
            value: prediction.place_id,
            isGooglePlace: true
          });
        });
      }

      optionsArray.push({
        options: predictionsFormatted,
        label: stringTranslate("tasks", "googleSearch")
      });
      callback(optionsArray);
    };

    service.getPlacePredictions({ input: inputValue }, displaySuggestions);
  };

  retrieveDataForOptions = (queryConfig, labelField, callback) => {
    const { firestore } = this.props;
    firestore.get(queryConfig).then(snapshot => {
      const options = [];
      snapshot.forEach(e => {
        options.push({ value: e.id, label: e.data()[labelField] });
      });
      callback(options);
    });
  };

  onSubmit = async e => {
    e.preventDefault();
    const { task, lastNote, noteText } = this.state;
    const { firestore, user } = this.props;
    let taskId = this.props.taskId;

    const taskToSave = this.getTaskToSaveFromState();
    delete taskToSave.duration;

    // SAVE POI
    if (task.poiId && task.poiId.isGooglePlace) {
      const poiId = await this.saveNewPoi(task.poiId);
      taskToSave.poiId = poiId;
    }

    // SAVE CONTACT
    if (taskToSave.contactId) {
      await firestore.update(
        {
          collection: `${getFirestoreCollection()}/contacts`,
          doc: taskToSave.contactId
        },
        { phone: task.contactPhone }
      );
    }

    // SAVE TASK
    if (taskId) {
      await firestore.update(
        {
          collection: `${getFirestoreCollection()}/tasks`,
          doc: taskId
        },
        taskToSave
      );
    } else {
      taskToSave.creationDatetime = new Date();
      taskId = await firestore
        .add({ collection: `${getFirestoreCollection()}/tasks` }, taskToSave)
        .then(docRef => docRef.id);
    }

    // SAVE NOTES
    if (!lastNote && noteText) {
      // Deve essere creata una nota nuova
      await firebase
        .firestore()
        .collection(`${getFirestoreCollection()}/tasks/${taskId}/notes`)
        .add({
          creationDate: firebase.firestore.Timestamp.fromMillis(Date.now()),
          note: noteText,
          user: {
            type: "WEB",
            id: user.uid,
            name: user.data.displayName
          }
        });
    } else if (lastNote) {
      if (noteText) {
        // La nota va aggiornata
        await lastNote.ref.update({ note: noteText });
      } else {
        // La nota va cancellata
        await lastNote.ref.delete();
      }
    }

    if (taskToSave.executionOrder && taskToSave.resourceId) {
      await this.reorderTasks(
        taskId,
        taskToSave.resourceId,
        taskToSave.executionOrder
      );
    }

    this.setState(
      { task: this.initNewTask(), errors: {}, warnings: {} },
      this.handleClose
    );
  };

  reorderTasks = (taskId, resourceId, order) => {
    let promises = [];
    if (this.props.allTasks) {
      this.props.allTasks.forEach(t => {
        if (t.id === taskId) {
          return;
        }

        if (!t.resourceId || t.resourceId !== resourceId || !t.executionOrder) {
          return;
        }

        if (t.executionOrder >= order) {
          promises.push(
            firebase
              .firestore()
              .collection(`${getFirestoreCollection()}/tasks`)
              .doc(t.id)
              .update({ executionOrder: t.executionOrder + 1 })
          );
        }
      });
    }
    return Promise.all(promises, () => {});
  };

  onDelete = e => {
    e.preventDefault();

    const { firestore, taskId } = this.props;
    const callback = () => {
      this.setState({
        task: this.initNewTask(),
        errors: {},
        warnings: {}
      });
      this.closeDeleteConfirmAlert();
      this.handleClose();
    };

    if (taskId) {
      firestore
        .delete({
          collection: `${getFirestoreCollection()}/tasks`,
          doc: taskId
        })
        .then(callback);
    } else {
      callback();
    }
  };

  saveNewPoi = place => {
    if (place.coords) {
      return new Promise((resolve, reject) => {
        const { firestore } = this.props;
        const { task } = this.state;
        const { partyId } = task;
        const partyIdToSave = partyId ? partyId.value : "";

        firestore
          .collection(getFirestoreCollection() + "/pois")
          .add({
            name: "",
            address: place.label,
            lat_long: new firestore.GeoPoint(
              place.coords.lat,
              place.coords.lng
            ),
            partyId: partyIdToSave,
            isMainAddress: false
          })
          .then(poiRef => {
            this.getPoiOptions(partyIdToSave);
            resolve(poiRef.id);
          })
          .catch(error => {
            reject(`errore_firestore ${error}`);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        this.getGooglePlaceLatLng(place.value)
          .then(result => {
            const { firestore } = this.props;
            const { task } = this.state;
            const { partyId } = task;
            const partyIdToSave = partyId ? partyId.value : "";

            const poiToSave = {
              name: "",
              address: result.formatted_address,
              lat_long: new firestore.GeoPoint(
                result.geometry.location.lat(),
                result.geometry.location.lng()
              ),
              partyId: partyIdToSave,
              isMainAddress: false
            };

            firestore
              .add(
                { collection: `${getFirestoreCollection()}/pois` },
                poiToSave
              )
              .then(poiRef => {
                this.getPoiOptions(partyIdToSave);
                resolve(poiRef.id);
              })
              .catch(error => {
                reject(`errore_firestore ${error}`);
              });
          })
          .catch(error => {
            reject(`errore_geocode ${error}`);
          });
      });
    }
  };

  getGooglePlaceLatLng = placeId => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ placeId }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          resolve(results[0]);
        } else {
          reject(status);
        }
      });
    });
  };

  getTaskToSaveFromState = () => {
    const { task } = this.state;
    const taskToSave = {
      description: task.description,
      code: task.code,
      executionStatus: task.executionStatus,
      transmissionStatus: task.transmissionStatus,
      duration: task.duration,
      beginning: task.beginning ? task.beginning : null,
      ending: task.ending ? task.ending : null,
      partyId: task.partyId ? task.partyId.value : "",
      poiId: task.poiId ? task.poiId.value : "",
      contactId: task.contactName ? task.contactName.value : "",
      resourceId: task.resourceId ? task.resourceId.value : "",
      notifyOnArrive: task.notifyOnArrive ? task.notifyOnArrive : false,
      notifyBefore: task.notifyBefore ? task.notifyBefore : 0,
      executionOrder: task.executionOrder ? task.executionOrder : ""
    };
    return this.setDerivedDateFields(taskToSave);
  };

  setDerivedDateFields = taskToSave => {
    const processedTask = taskToSave;
    if (!taskToSave.beginning && taskToSave.ending && taskToSave.duration) {
      const endingTimestamp = taskToSave.ending.getTime();
      const durationMillis = taskToSave.duration * 60 * 1000;
      processedTask.beginning = new Date(endingTimestamp - durationMillis);
    }
    if (!taskToSave.ending && taskToSave.beginning && taskToSave.duration) {
      const beginningTimestamp = taskToSave.beginning.getTime();
      const durationMillis = taskToSave.duration * 60 * 1000;
      processedTask.ending = new Date(beginningTimestamp + durationMillis);
    }
    return processedTask;
  };

  getDurationToShow = task => {
    if (task.beginning && task.ending) {
      return Math.round((task.ending.seconds - task.beginning.seconds) / 60); // in minuti
    }
    return "";
  };

  notifyOnArriveChange = () => {
    const { task } = this.state;
    this.setState({
      task: { ...task, notifyOnArrive: !task.notifyOnArrive }
    });
  };

  notifyBeforeChange = value => {
    const { task } = this.state;
    this.setState({
      task: { ...task, notifyBefore: value }
    });
  };

  getResourceOrders = () => {
    if (this.state.task.resourceId) {
      const tasks = this.props.allTasks;
      var result = [];
      tasks.forEach(t => {
        if (t.resourceId === this.state.task.resourceId.value) {
          if (t.executionOrder) {
            result.push(t.executionOrder);
          }
        }
      });
      result.sort((a, b) => a - b);

      if (result.length) {
        result.push(result[result.length - 1] + 1);
        return result;
      } else {
        return [1];
      }
    } else {
      return [1];
    }
  };

  contentToRender = () => {
    const { taskId, user } = this.props;
    const { task, mode } = this.state;
    const {
      poiOptions,
      partyOptions,
      contactNameOptions,
      isLoading,
      contactPhoneReadonly,
      errors,
      warnings
    } = this.state;

    let userCountry;
    if (user) {
      userCountry = user.countryCode;
    } else {
      userCountry = "IT";
    }

    const { resourcesOptions, detailDataPanelOpen } = this.props;
    if (mode === MODE_ATTACHMENTS && taskId !== undefined && taskId !== null) {
      return (
        <TaskAttachments
          taskId={taskId}
          taskDescription={task.description}
          onBack={() => {
            this.setState({ mode: MODE_TASK_DETAIL });
          }}
        />
      );
    }
    if (mode === MODE_MODULES && taskId !== undefined && taskId !== null) {
      return (
        <TaskModules
          task={{ ...task, id: taskId }}
          // party={
          //   task.partyId && allPartiesOrd.find(p => p.id === task.partyId.value)
          // }
          onBack={() => {
            this.setState({ mode: MODE_TASK_DETAIL });
          }}
        />
      );
    }

    if (mode === MODE_NOTES && taskId !== undefined && taskId !== null) {
      return (
        <TaskNotes
          taskId={taskId}
          user={this.props.user}
          onBack={() => {
            this.setState({ mode: MODE_TASK_DETAIL });
          }}
        />
      );
    }

    const orders = this.getResourceOrders();

    return (
      <TaskForm
        task={task}
        poiOptions={poiOptions}
        partyOptions={partyOptions}
        contactNameOptions={contactNameOptions}
        isLoading={isLoading}
        contactPhoneReadonly={contactPhoneReadonly}
        errors={errors}
        warnings={warnings}
        canSubmit={this.canSaveTask()}
        resourcesOptions={resourcesOptions}
        detailDataPanelOpen={detailDataPanelOpen}
        taskId={taskId}
        onToggleDetailPanel={this.toggleDetailPanel}
        onClose={this.handleClose}
        onSubmit={this.onSubmit}
        onDelete={this.openDeleteConfirmAlert}
        onManageModules={() => this.setState({ mode: MODE_MODULES })}
        onManageAttachments={() => this.setState({ mode: MODE_ATTACHMENTS })}
        onDescriptionChange={this.handleDescriptionChange}
        onAutocompleteBaseChange={this.handleAutocompleteChange}
        onAutocompletePartyChange={this.handleAutocompletePartyChange}
        onAutocompletePartyCreate={this.handleAutocompletePartyCreate}
        onAutocompleteContactChange={this.handleAutocompleteContactChange}
        onAutocompleteContactCreate={this.handleAutocompleteContactCreate}
        onChange={this.handleChange}
        onDateChange={this.handleDateChange}
        onDurationChange={this.handleDurationChange}
        onPhoneChange={this.handlePhoneChange}
        searchPois={this.searchPois}
        userCountry={userCountry}
        account={this.props.account}
        notifyOnArriveChange={this.notifyOnArriveChange}
        notifyBeforeChange={this.notifyBeforeChange}
        onManageNotes={() => this.setState({ mode: MODE_NOTES })}
        code={this.state.task.code}
        onCodeChange={this.handleCodeChange}
        lastNote={this.state.noteText}
        handleNoteTextChange={this.handleNoteTextChange}
        executionOrders={orders}
      />
    );
  };

  render() {
    const { classes, open } = this.props;
    const { deleteConfirmationOpen } = this.state;
    return (
      <React.Fragment>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          fullWidth
          scroll="paper"
          classes={{ paper: classes.dialogPaper }}
        >
          {this.contentToRender()}
        </Dialog>
        <OMDeleteConfirmationAlert
          isOpen={deleteConfirmationOpen}
          deleteHandler={this.onDelete}
          cancelHandler={this.closeDeleteConfirmAlert}
        />
      </React.Fragment>
    );
  }
}

TaskDetail.propTypes = {
  classes: PropTypes.shape().isRequired,
  firestore: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  taskId: PropTypes.string,
  selectedValue: PropTypes.string,
  allPartiesOrd: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  freePoisOrd: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired
    })
  ),
  allContactsOrd: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  tasks: PropTypes.shape({}),
  allParties: PropTypes.shape(),
  allPois: PropTypes.shape(),
  allResources: PropTypes.shape(),
  allContacts: PropTypes.shape(),
  resourcesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  detailDataPanelOpen: PropTypes.bool.isRequired,
  detailDataPanelToggler: PropTypes.func.isRequired,
  account: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired
};

TaskDetail.defaultProps = {
  taskId: undefined,
  selectedValue: undefined,
  allPartiesOrd: undefined,
  freePoisOrd: undefined,
  allContactsOrd: undefined,
  tasks: undefined,
  allParties: undefined,
  allPois: undefined,
  allResources: undefined,
  allContacts: undefined,
  resourcesOptions: []
};

export default compose(
  firestoreConnect(),
  connect(
    state => {
      const partyOptions = [];
      let resourcesOptions = [];
      const poiOptions = [];
      const contactNameOptions = [];
      if (state.firestore.ordered.resources) {
        resourcesOptions = state.firestore.ordered.resources.map(el => ({
          value: el.id,
          label: el.name
        }));
      }

      return {
        allParties: state.firestore.data.parties,
        allPartiesOrd: state.firestore.ordered.parties,

        allResources: state.firestore.data.resources,

        allContacts: state.firestore.data.contacts,
        allContactsOrd: state.firestore.ordered.contacts,

        allPois: state.firestore.data.pois,
        allPoisOrd: state.firestore.ordered.pois,

        freePois: state.firestore.data.freePois,
        freePoisOrd: state.firestore.ordered.freePois,
        freeContacts: state.firestore.data.freeContacts,
        tasks: state.firestore.data.tasks,
        partyOptions,
        resourcesOptions,
        poiOptions,
        contactNameOptions,
        user: state.auth.user,
        account: state.firestore.data.account,
        valuesForForm: state.mainActivityPage.task.valuesForForm
      };
    },
    dispatch => ({
      passValueToForm: values => dispatch(passValueToForm(values))
    })
  ),
  withStyles(styles)
)(TaskDetail);
