export const tasks = {
  //filtri lista
  filterToYesterday: "Fino a ieri",
  filterToday: "Oggi",
  filterFromTomorrow: "Da domani",
  date: "Data",

  //colonne lista
  description: "Descrizione",
  resource: "Risorsa",
  when: "Quando",
  where: "Dove",
  distance: "Distanza",
  status: "Stato",
  read: "Letto",
  showTaskDetail: "Vedi dettaglio task",
  toAssignTask: "Task da assegnare",
  loading: "Aggiornamento attività, attendere...",

  //executionstatus
  TOEXECUTE: "Da eseguire",
  COMPLETED: "Completato",
  SUSPENDED: "Sospeso",
  ABORTED: "Annullato",
  REJECTED: "Rifiutato",

  "OG-EXECUTING": "In esecuzione",
  "OG-ENROUTE": "In viaggio",
  "OG-ARRIVED": "Giunto a destinazione",

  //transmissionStatus
  SENT: "Inviato",
  RECEIVED: "Ricevuto",
  READ: "Letto",

  //form
  task: "Attivita'",
  company: "Cliente",
  address: "Indirizzo",
  contact: "Contatto",
  phone: "Telefono",
  duration: "Durata (min.)",
  beginning: "Inizio",
  ending: "Fine",
  savedAddresses: "Indirizzi salvati",
  googleSearch: "Indirizzi da google",
  dateSequenceError: "Inizio dev'essere precendente a Fine",
  notify: "Notifica",
  before: "all'arrivo",
  code: "Codice",
  warningSameTime: "C'è un altro task a quest'ora",
  executionOrder: "Ordine",

  // Allegati
  attachments: "Allegati",
  taskAttachments: "Allegati Attività",
  errorFetchAttachments: "Errore durante la lettura degli allegati",
  noAttachments: "Questa attività non ha nessun allegato",
  invalidFile: "Formato file non valido",
  invalidFileSize: "Dimensione del file superiore a 10MB",
  attachmentCreated: "Allegato caricato",
  attachmentDeleted: "Allegato cancellato",
  errorCreateAttachment: "Errore durante la creazione",
  errorUploadFile: "Errore durante il caricamento",
  errorDeleteAttachment: "Errore durante la cancellazione dell'allegato",
  errorLoadPdf: "Errore durante il caricamento del pdf",
  errorLoadImage: "Errore durante il caricamento dell'immagine",
  scannedCode: "Codice scansionato: ",

  // Moduli
  modules: "Moduli",
  taskModules: "Moduli Attività",
  noModules: "Nessun modulo trovato",
  noCustomForms: "Non è stato ancora creato un form",

  // Note
  notes: "Note",
  noNotes: "Questa attività non ha nessuna nota"
};
