import React from "react";
import { compose, withHandlers, lifecycle } from "recompose";
import { connect } from "react-redux";
import { GoogleMap } from "react-google-maps";
import ResourceMapMarkerComponentClass from "./ResourceMapMarkerComponentClass";
import TaskMapMarkerComponent from "./TaskMapMarkerComponent";
import PartiesMapMarkerGroup from "./PartiesMapMarkerGroup";
import SearchMapMarkerComponent from "./SearchMapMarkerComponent";
import getBoundsZoomLevel from "./getBoundsZoomLevel";
import {
  setVisibleMarkers,
  setVisibleResources,
  setSearchedResources,
  setVisibleTasks,
  setVisibleParties,
  setGeolocatedPoi,
  showAllResources
} from "store/actions/mainActivityPage";
import { TrafficLayer } from "react-google-maps";
import { stringTranslate } from "languages/OMTranslate";
import { firestoreConnect } from "react-redux-firebase";
import mapEnvironment from "main/common/mapEnvironment";

const {
  SearchBox
} = require("react-google-maps/lib/components/places/SearchBox");
const _ = require("lodash");

const defaultMapCenter = {
  lat: 50,
  lng: 22.4
};
const defaultZoom = 4;

const MapComponentInner = compose(
  lifecycle({
    // shouldComponentUpdate: function(nextProps, nextState) {
    //   var nextPropsObj = nextProps;
    //   var thisPropsObj = this.props;
    //   var udiff = compareObj(nextPropsObj, thisPropsObj);
    //   if (udiff.tasks) delete udiff["tasks"];
    //   if (udiff.allTasks) delete udiff["allTasks"];
    //   if (udiff.firebase) delete udiff["firebase"];
    //   console.log(udiff);
    //   if (isEmpty(udiff)) {
    //     console.log("salto");
    //     return false;
    //   }
    //   return true;
    // },
    componentWillMount() {
      const refs = {};

      this.setState({
        bounds: null,
        forceBound: null,
        center: defaultMapCenter,
        zoom: defaultZoom,
        forceCenter: true,
        onZoomChange: zoomValue => {
          this.setState({ zoom: zoomValue.zoom });
        },
        markers: [],
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter()
          });
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        changeCenterAndZoom: (center, zoom) => {
          this.setState({
            center,
            zoom
          });
        },
        deleteSearchMarker: index => {
          this.setState({ markers: [] });
        },

        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          if (
            typeof window.google === "object" &&
            typeof window.google.maps === "object"
          ) {
            const bounds = new window.google.maps.LatLngBounds();
            places.forEach(place => {
              if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            const nextMarkers = places.map(place => ({
              position: place.geometry.location,
              formatted_address: place.formatted_address
            }));
            const nextCenter = _.get(
              nextMarkers,
              "0.position",
              this.state.center
            );
            this.setState({
              center: nextCenter,
              markers: nextMarkers
            });
          }
        }
      });
    },

    componentDidMount() {
      this.props.setShowAllResources(true);
    },

    componentDidUpdate(prevProps, refs) {
      if (this.props.showTraffic) {
      }

      if (
        this.props.resources &&
        prevProps.resources !== this.props.resources
      ) {
        this.props.setVisibleResources(this.props.resources);
        this.props.setSearchedResources(this.props.resources);
      }
      if (!this.props.visibleTasks) {
        this.props.setVisibleTasks(this.props.tasks);
      }
      if (!this.props.visibleParties) {
        this.props.setVisibleParties(this.props.parties);
      }

      if (
        prevProps.selectedResource !== this.props.selectedResource &&
        this.props.selectedResource
      ) {
        const resource = this.props.indexedResources[
          this.props.selectedResource
        ];
        if (resource.lastPosition && resource.lastPosition.lat_long) {
          this.setState({
            center: {
              lat: resource.lastPosition.lat_long.latitude,
              lng: resource.lastPosition.lat_long.longitude
            },
            zoom: 8
          });
        }
      }

      if (this.props.dimensions.height === -1) return;

      if (
        (prevProps.selectedResource && !this.props.selectedResource) ||
        this.props.showAllResources ||
        prevProps.showAllResources ||
        (typeof prevProps.resources === "undefined" && this.props.resources) ||
        (this.state.forceCenter && this.props.resources)
      ) {
        if (
          typeof window.google === "object" &&
          typeof window.google.maps === "object" &&
          this.props.resources
        ) {
          var bounds = new window.google.maps.LatLngBounds();
          var isToSet = false;
          this.props.resources.forEach(resource => {
            if (resource.lastPosition && resource.lastPosition.lat_long) {
              isToSet = true;
              bounds.extend({
                lat: resource.lastPosition.lat_long.latitude,
                lng: resource.lastPosition.lat_long.longitude
              });
            }
          });
          if (isToSet) {
            this.setState({
              center: bounds.getCenter(),
              zoom: isNaN(getBoundsZoomLevel(bounds, this.props.dimensions))
                ? 4
                : getBoundsZoomLevel(bounds, this.props.dimensions),
              forceCenter: false
            });
            if (
              JSON.stringify(this.props.resources) !==
              JSON.stringify(this.props.visibleResources)
            ) {
              this.props.setVisibleResources(this.props.resources);
            }
            if (
              JSON.stringify(this.props.resources) !==
              JSON.stringify(this.props.searchedResources)
            ) {
              this.props.setSearchedResources(this.props.resources);
            }
          }
        }
        if (this.props.showAllResources) this.props.setShowAllResources(false);
      }
    }
  }),

  withHandlers(() => {
    const refs = {
      map: undefined
    };

    return {
      onMapMounted: props => ref => {
        refs.map = ref;
        props.setShowAllResources(true);
      },

      onZoomChanged: ({ onZoomChange }) => () => {
        onZoomChange({ zoom: refs.map.getZoom() });
      },
      onBoundsChanged: props => () => {
        var filterResources = [];

        if (props.resources && props.resources.length > 0) {
          filterResources = props.resources.filter(function(resource) {
            if (!props.mapVisibility) return true;
            if (!resource.lastPosition) return true;
            if (!resource.lastPosition.lat_long) return true;
            if (props.showAllResources) return true;
            return refs.map.getBounds().contains({
              lat: resource.lastPosition.lat_long.latitude,
              lng: resource.lastPosition.lat_long.longitude
            });
          });
        }
        if (
          JSON.stringify(props.visibleResources) !==
          JSON.stringify(filterResources)
        )
          props.setVisibleResources(filterResources);
      }
    };
  })
)((props, state) => {
  const {
    visibleResources,
    visibleParties,
    selectedResource,
    searchedResources,
    allCustomer
  } = props;

  let componentResources = [];
  if (selectedResource) {
    componentResources.push({
      id: selectedResource,
      ...props.indexedResources[selectedResource]
    });
  } else {
    if (searchedResources) {
      componentResources = searchedResources;
    } else if (
      visibleResources &&
      visibleResources.length > 0 &&
      !props.mapVisibility
    ) {
      componentResources = visibleResources;
    } else {
      componentResources = props.resources;
    }
  }

  let componentTasks = [];
  if (props.tasks) {
    // taskListFiltered contiene gli id di tutti i task mostrati in lista
    if (props.taskListFiltered && props.taskListFiltered.length > 0) {
      // Se nella lista sono presenti tutti i task (stessa cardinalità quindi)
      if (props.taskListFiltered.length === props.tasks.length) {
        componentTasks = props.tasks;
      } else {
        componentTasks = props.tasks.filter(
          task => props.taskListFiltered.indexOf(task.id) > -1
        );
      }
    } else {
      // La lista task è vuota, non devo vedere marker
      componentTasks = [];
    }

    if (selectedResource) {
      componentTasks = componentTasks.filter(
        task => task.resourceId === selectedResource
      );
    } else if (searchedResources) {
      componentTasks = componentTasks.filter(task => {
        let found = false;
        searchedResources.forEach(resource => {
          if (resource.id === task.resourceId) {
            found = true;
          }
        });
        return found;
      });
    }
  }

  let componentParties = [];
  if (visibleParties && visibleParties.length > 0) {
    componentParties = visibleParties;
  } else {
    componentParties = props.parties;
  }

  if (props.selectedParties && props.selectedParties.length > 0) {
    componentParties = componentParties.filter(party => {
      if (props.selectedParties.indexOf(party.id) > -1) {
        return true;
      }
      return false;
    });
  }

  return (
    <GoogleMap
      center={props.center}
      zoom={props.zoom}
      ref={props.onMapMounted}
      onBoundsChanged={props.onBoundsChanged}
      onZoomChanged={() => props.onZoomChanged()}
      markers={props.markers}
      defaultOptions={{
        mapTypeControl: false,
        streetViewControl: true
      }}
    >
      {props.showTraffic && <TrafficLayer autoUpdate />}

      <SearchBox
        ref={props.onSearchBoxMounted}
        bounds={props.bounds}
        controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={props.onPlacesChanged}
      >
        <input
          type="text"
          placeholder={stringTranslate("mainactivity", "Search on Google Maps")}
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `30px`,
            marginTop: `10px`,
            marginLeft: `10px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            outline: `none`,
            textOverflow: `ellipses`,
            fontSize: "14px"
          }}
        />
      </SearchBox>

      {props.markers.map((marker, index) => {
        return (
          <SearchMapMarkerComponent
            key={index}
            position={marker.position}
            formatted_address={marker.formatted_address}
            deleteMarker={props.deleteSearchMarker}
          />
        );
      })}

      {componentResources && props.showResources
        ? componentResources.map(resource => {
            if (resource.lastPosition && resource.lastPosition.lat_long)
              return (
                <ResourceMapMarkerComponentClass
                  key={resource.id}
                  resource={resource}
                  allTasks={props.tasks}
                  geolocatedPoi={props.geolocatedPoi}
                  setGeolocatedPoi={props.setGeolocatedPoi}
                  isSelectedResource={resource.id === selectedResource}
                  changeCenterAndZoom={props.changeCenterAndZoom}
                  currentZoom={props.zoom}
                  goToReport={props.goToReport}
                />
              );
          })
        : ""}
      {componentTasks && props.showTasks
        ? componentTasks.map(task => {
            if (task.poiId) {
              return (
                <TaskMapMarkerComponent
                  key={task.id}
                  task={task}
                  allPois={props.allPois}
                  allCustomer={allCustomer}
                />
              );
            }
          })
        : ""}
      {componentParties && props.showParties
        ? componentParties.map(party => {
            return (
              <PartiesMapMarkerGroup
                key={party.id}
                party={party}
                allPois={props.allPois}
                allAddress={props.allAddress}
                poiCategories={props.poiCategories}
                selectedCategories={props.selectedCategories}
              />
            );
          })
        : ""}
    </GoogleMap>
  );
});

const mapStateToProps = state => ({
  visibleMarkers: state.mainActivityPage.activityMap.visibleMarkers,
  visibleResources: state.mainActivityPage.activityMap.visibleResources,
  visibleTasks: state.mainActivityPage.activityMap.visibleTasks,
  visibleParties: state.mainActivityPage.activityMap.visibleParties,
  allPois: state.firestore.data.pois,
  allCustomer: state.firestore.data.parties,
  geolocatedPoi: state.mainActivityPage.geolocatedPoi,
  indexedResources: state.firestore.data.resources,
  allAddress: state.firestore.ordered.pois,
  selectedResource: state.mainActivityPage.resourceList.selectedResource,
  searchedResources: state.mainActivityPage.resourceList.searchedResources,
  showAllResources: state.mainActivityPage.activityMap.showAllResources,
  mapVisibility: state.mainActivityPage.panelVisibility.mapVisibility,
  poiCategories: state.firestore.data.poiCategories,
  user: state.auth.user,
  taskListFiltered: state.mainActivityPage.task.taskListFiltered
});

const mapDispatchToProps = dispatch => ({
  setVisibleMarkers: markers => dispatch(setVisibleMarkers(markers)),
  setVisibleResources: resources => dispatch(setVisibleResources(resources)),
  setSearchedResources: resources => dispatch(setSearchedResources(resources)),
  setVisibleTasks: tasks => dispatch(setVisibleTasks(tasks)),
  setVisibleParties: parties => dispatch(setVisibleParties(parties)),
  setGeolocatedPoi: geolocatedPoi => dispatch(setGeolocatedPoi(geolocatedPoi)),
  setShowAllResources: isToShow => dispatch(showAllResources(isToShow))
});

const MapComponent = compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(mapEnvironment(MapComponentInner));
export default MapComponent;
