export default {
  "1": "5%", // executionOrder
  "2": "8%", // code
  "3": "18%", // description
  "3L": "46%", // description large
  "4": "15%", // resourceName
  "5": "10%", // when
  "6": "20%", // where
  "7": "8%", // distance
  "8": "8%", // status
  "9": "8%" // transmissionStatus + arrowRight
};
