export default {
  dateFormat: "MM/DD/YYYY",
  resource: "Resource",
  date: "Date",
  start: "Start",
  end: "End",
  title: "Modify clock in/out",
  save: "Save",
  emptyTable: "No data found",
  search: "Search",
  selectedRows: "selected",
  approve: "Approve selected"
};
