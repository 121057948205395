export const login = {
  "Login to your account": "Login con il tuo account",
  Email: "Email",
  Password: "Password",
  Login: "Login",
  "Don't have an account?": "Non hai ancora un account?",
  "Create an account": "Crea un nuovo account",
  "Min character length is 4": "Lunghezza minima 4 caratteri",
  "Min character length is 3": "Lunghezza minima 3 caratteri",

  "auth/email-already-in-use": "Email gia' in uso",
  "auth/invalid-email": "Indirizzo email non valido",
  "auth/operation-not-allowed": "Operazione non permessa",
  "auth/user-not-found": "Utente non trovato",
  "auth/user-disabled": "Utente disabilitato",
  "auth/weak-password": "Occorre definire una password di almeno 8 caratteri",
  "auth/wrong-password": "Password errata",
  "auth/expired-action-code": "Codice di verifica non valido",
  "auth/invalid-action-code": "Codice di verifica non valido",
  "Do you need help?": "Hai bisogno di aiuto?",
  "Contact us": "Contattaci",
  "Lost Password?": "Password persa?",
  "Get a new one": "Richiedine una nuova",
  "Reset password": "Richiesta nuova password",
  "Send request": "Invia richiesta",
  "Error in reset password request. User unknown.":
    "Errore durante l'inoltro della richiesta. Utente non conosciuto."
};
