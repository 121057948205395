import React from "react";
import { Chip, withStyles } from "@material-ui/core";

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit,
    boxShadow: theme.shadows[3]
  },
  chipContainer: {
    display: "inline"
  }
});

function ToAssignTaskChipSimple(props) {
  const {
    task,
    classes,
    connectDragSource,
    chipClickHandler,
    chipDeleteHandler
  } = props;

  return connectDragSource(
    <div className={classes.chipContainer}>
      <Chip
        label={task.description}
        key={task.id}
        color="secondary"
        // className={classNames(classes.chip, {
        //   [classes.chipDragging]: isDragging
        // })}
        className={classes.chip}
        onDelete={() => chipDeleteHandler(task.id)}
        onClick={event => chipClickHandler(event, task.id)}
        //   variant="outlined"
      />
    </div>
  );
}

export default withStyles(styles)(ToAssignTaskChipSimple);
