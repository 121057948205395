const firebaseConfig = {
  development: {
    apiKey: "AIzaSyBILt6niLNTruTJPzh0QrK2mF6mEtd30I8",
    authDomain: "overmover-dev-227915.firebaseapp.com",
    databaseURL: "https://overmover-dev-227915.firebaseio.com",
    projectId: "overmover-dev-227915",
    storageBucket: "overmover-dev-227915.appspot.com",
    messagingSenderId: "267699102169"
    // apiKey: "AIzaSyAhpzkAI9XIIu8ucxKhwuZQv8GdTgQUbfo",
    // authDomain: "overmover-pro.firebaseapp.com",
    // databaseURL: "https://overmover-pro.firebaseio.com",
    // projectId: "overmover-pro",
    // storageBucket: "overmover-pro.appspot.com",
    // messagingSenderId: "11931073092"
  },
  production: {
    apiKey: "AIzaSyAhpzkAI9XIIu8ucxKhwuZQv8GdTgQUbfo",
    authDomain: "overmover-pro.firebaseapp.com",
    databaseURL: "https://overmover-pro.firebaseio.com",
    projectId: "overmover-pro",
    storageBucket: "overmover-pro.appspot.com",
    messagingSenderId: "11931073092"
  }
};

const config = firebaseConfig[process.env.REACT_APP_FIREBASE_ENV];

export default config;
