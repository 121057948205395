export default {
  step1:
    "Benvenuto nel tour guidato di OverMover! Il tour richiede solo pochi minuti.",
  step2:
    "Queste icone ti permettono di cambiare pannello nella pagina principale",
  step3: "Questa icona permette di mostrare il pannello della mappa",
  step4:
    "Il pannello della mappa mostra la posizione e lo stato (libero/occupato) delle tue risorse",
  step5: "Puoi cliccare sul segnaposto di una risorsa per vedere più dettagli",
  step6:
    "L'icona del calendario permette di mostrare/nascondere il pannello del calendario",
  step7:
    "Il pannello del calendario mostra la pianificazione dei task nel tempo per ogni risorsa",
  step8: "E ti permette di organizzare il lavoro di tutti i dipendenti.",
  step9:
    "Il pannello dei task mostra quali attività sono assegnate alle risorse",
  step10: "E mostra i dettagli dei task e il loro stato di esecuzione.",
  step11: "L'icona della chat permette di mostrare il pannello delle chat...",
  step12: "Qui puoi mandare messaggio alle risorse.",
  step13:
    "Nel pannello delle chat puoi scambiare messaggi e immagini con le risorse sul campo.",
  step14:
    "Il tuo account contiene alcuni dati di esempio per aiutarti a vedere come funziona OverMover.",
  step15:
    "I dati di esempio possono essere rimossi quando inizierai ad invitare le tue risorse. Per rimuoverli vai nel menu Impostazioni -> Cancella dati dimostrativi",
  step16: "Congratulazioni! Hai completato il tour guidato!"
};
