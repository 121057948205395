import * as Actions from "../actions";
var language = window.navigator.userLanguage || window.navigator.language;

const initialState = {
  userChecked: false,
  role: "guest",
  data: {
    firstName: "John",
    lastName: "Doe",
    displayName: "John Doe",
    photoURL: "assets/images/avatars/Velazquez.jpg",
    email: "johndoe@withinpixels.com",
    userLanguage: language,
    shortcuts: ["calendar", "mail", "contacts", "todo"]
  }
};

const user = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_USER_CHECKED: {
      return {
        ...state,
        userChecked: true
      };
    }
    case Actions.SET_USER_DATA: {
      return {
        ...initialState,
        ...action.payload,
        userChecked: true
      };
    }
    case Actions.REMOVE_USER_DATA: {
      return {
        ...initialState
      };
    }
    case Actions.USER_LOGGED_OUT: {
      return initialState;
    }
    case Actions.UPDATE_REDUX_USER: {
      return {
        ...state,
        ...action.user
      };
    }
    default: {
      return state;
    }
  }
};

export default user;
