import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate } from "languages/OMTranslate";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  root: { padding: "24px" },
  header: { display: "flex", flex: "flex-row" },
  title: { flex: "1 1 auto", border: "1px none red", order: "1" },
  actions: { flex: "0 0 auto", border: "1px none red", order: "2" },
  addbutton: {
    fontSize: "44px",
    color: theme.palette.secondary.main
  }
});

class UserAddFormPage extends Component {
  render() {
    const { classes, users } = this.props;
    return (
      <div
        className={classes.root}
        ref={root => {
          this.root = root;
        }}
      >
        <div className={classes.header}>
          <div className={classes.title}>
            <Typography variant="h3">
              {stringTranslate("users", "Add User")}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

UserAddFormPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  users: state.firestore.data.users
});

const mapDispatchToProps = dispatch => ({});

export default compose(
  firestoreConnect([
    {
      collection: "users",
      where: ["accountId", "==", localStorage.getItem("accountId")],
      storeAs: "users"
    }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(UserAddFormPage);
