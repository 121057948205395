import React from "react";
import PropTypes from "prop-types";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Snackbar,
  Button,
  Typography
} from "@material-ui/core";

import PreviewIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";

import ActionBar from "../components/ActionBar";
import BackButton from "../components/BackButton";
import ResponsiveButton from "../components/ResponsiveButton";
import Fields from "./Fields";
import OMDeleteConfirmationAlert from "../../common/OMDeleteConfirmationAlert";

import {
  FieldTypes,
  generateDefaultField,
  generateBaseOption
} from "../ModulesConfig";
import { fieldCompare } from "../Common";
import { stringTranslate } from "languages/OMTranslate";

import { getFirestoreCollection } from "../../../../firestoreAccount";

/**
 * Resetta le uteriori informazioni dei campi che vengono salvati nello stato
 * @param {Array} fields lista di campi
 */
const resetStateFields = fields =>
  fields.map(field => ({
    field,
    errors: {},
    isNew: false,
    newOptionNames: []
  }));

const defaultState = customForm => ({
  customForm: {
    prop: customForm ? customForm : { name: "" },
    errors: {}
  },
  // Necessario almeno un campo compilato
  fields: resetStateFields(
    customForm ? customForm.fields : [generateDefaultField(FieldTypes.text)]
  ),
  snackbar: {
    open: false,
    message: "",
    action: undefined
  },
  dialogConfirm: {
    open: false,
    callback: undefined
  }
});

/**
 * Verifica che i valori del custom form e i valori dei campi siano
 * validi
 * @param {Object} customForm oggetto custom form (deve essere quello nello stato)
 * @param {Array<Object>} fields lista di oggetti campi (deve essere quello nello stato)
 * @returns {Array} lista con 3 valori:
 * posizione 0: lista di campi processati in cui sono stati segnalati gli errori
 * posizione 1: oggetto custom form processato in cui sono stati segnati gli errori
 * posizione 2: boolean che indica se ci sono stati degli errori
 */
const validateCustomFormAndFields = (customForm, fields) => {
  let errorFound = false;
  let processedCustomForm = customForm;
  if (customForm.prop.name === "") {
    errorFound = true;
    processedCustomForm = {
      ...customForm,
      errors: {
        name: stringTranslate("modules", "errorCustomFormNameRequired")
      }
    };
  }
  // Verifica campi
  const processedFields = fields.map(({ field, ...others }) => {
    let errors = {};
    if (field.label === "") {
      errorFound = true;
      errors = {
        ...errors,
        label: stringTranslate("modules", "fieldLabelRequired")
      };
    }
    if (field.options !== undefined) {
      field.options.forEach(option => {
        if (option.label === "") {
          errorFound = true;
          errors = {
            ...errors,
            [`options.${option.name}`]: stringTranslate(
              "modules",
              "fieldOptionLabelRequired"
            )
          };
        }
      });
    }
    return { ...others, field, errors };
  });
  return [processedFields, processedCustomForm, errorFound];
};

const styles = theme => ({
  contentButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: 1,
    width: "100%"
  },
  button: {
    marginLeft: "20px",
    "@media(max-width: 400px)": {
      marginLeft: "10px"
    }
  },
  contentCustomFormFields: {
    padding: theme.spacing.unit * 3,
    paddingTop: 0
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  },
  gridCell: {
    display: "flex"
  },
  title: {
    display: "flex",
    alignItems: "center"
  }
});

class FormBuilder extends React.Component {
  state = defaultState(this.props.customForm);

  componentDidUpdate(prevProps) {
    if (
      this.props.customForm === undefined &&
      prevProps.customForm === undefined
    ) {
      return;
    }
    if (
      (this.props.customForm !== undefined &&
        prevProps.customForm === undefined) ||
      (this.props.customForm === undefined &&
        prevProps.customForm !== undefined) ||
      this.props.customForm.id !== prevProps.customForm.id
    ) {
      this.setState(defaultState(this.props.customForm));
    }
  }

  showSnackbar = (message, action = undefined) => {
    this.setState({ snackbar: { open: true, message, action } });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState(state => ({
      snackbar: { ...state.snackbar, open: false }
    }));
  };

  toggleShowDialogConfirm = (callback = () => {}) => {
    this.setState(state => ({
      dialogConfirm: {
        open: !state.dialogConfirm.open,
        callback
      }
    }));
  };

  /**
   * Sposta un campo aggiornando la posizione
   * Si presuppone che la posizione si cambiata e i controllo
   * sugli indici siano già stati fatti
   * @param {Number} previousIndex posizione precedente dell'elemento
   * @param {Number} nextIndex posizione in cui l'elemento è stato posizionato
   */
  onFieldMove = (previousIndex, nextIndex) => {
    const { fields } = this.state;
    const shift = previousIndex > nextIndex ? 1 : -1;
    const items = fields.map(({ field, ...others }, i) => {
      // Campo che è stato spostato cambia la posizione
      if (i === previousIndex) {
        return {
          field: { ...field, position: nextIndex },
          ...others
        };
      }
      // Viene modificata la posizione degli altri campi
      if (i >= nextIndex && i < previousIndex && shift === 1) {
        return {
          field: { ...field, position: field.position + shift },
          ...others
        };
      }
      if (i <= nextIndex && i > previousIndex && shift === -1) {
        return {
          field: { ...field, position: field.position + shift },
          ...others
        };
      }
      return {
        field,
        ...others
      };
    });
    this.setState({ fields: items });
  };

  /**
   * Aggiunge un nuovo campo nella posizione successiva al campo
   * di cui è stato premuto il pulsante aggiungi
   * Viene creato un campo di tipo testo vuoto
   * @param {Object} field oggetto campo
   */
  onFieldAdd = ({ position }) => {
    const { fields } = this.state;
    // Copia e incrementa la posizione ai campi successi alla posizione
    const items = Array.from(fields).map(({ field, ...other }) => {
      if (field.position > position) {
        return { field: { ...field, position: field.position + 1 }, ...other };
      }
      return { field, ...other };
    });
    // Aggiunge un nuovo campo
    items.push({
      field: {
        ...generateDefaultField(FieldTypes.text),
        position: position + 1
      },
      error: {},
      isNew: true,
      newOptionNames: []
    });
    this.setState({ fields: items });
  };

  /**
   * Rimuove un campo
   * @param {Object} field oggetto campo
   */
  onFieldDelete = ({ position }) => {
    const { fields } = this.state;
    if (fields.length === 1) {
      return;
    }
    // Copia e rimuove campo con la specifica posizione
    const items = Array.from(fields).filter(
      ({ field }) => field.position !== position
    );
    this.setState({
      // Decrementa la posizione dei campi successivi
      fields: items.map(({ field, ...other }) => {
        if (field.position > position) {
          return {
            field: { ...field, position: field.position - 1 },
            ...other
          };
        }
        return { field, ...other };
      })
    });
    this.showSnackbar(stringTranslate("modules", "fieldRemoved"), () => {
      // Viene ripristinato lo stato precedente di campi
      this.setState({
        fields
      });
      this.closeSnackbar();
    });
  };

  /**
   * Gestisce il cambiamento dei tipo di un campo.
   * Non è possibile modificare il campo se:
   *  - il campo non è nuovo (non presente nel db)
   *  - il custom form è utilizzato
   * @param {Object} field oggetto campo
   * @param {String} newType tipo di campo
   */
  onFieldTypeChange = ({ name }, newType) => {
    const { used } = this.props;
    this.setState(state => ({
      fields: state.fields.map(({ field, errors, isNew, newOptionNames }) => {
        if (field.name !== name) {
          return { field, errors, isNew, newOptionNames };
        }
        if (!isNew && used) {
          this.showSnackbar(
            stringTranslate("modules", "errorCannotChangeTypeFieldInUse")
          );
          return { field, errors, isNew, newOptionNames };
        }
        const newField = generateDefaultField(newType);
        return {
          field: {
            ...newField,
            label: field.label,
            help: field.help,
            required: field.required,
            position: field.position
          },
          errors: {},
          isNew: true, // al salvataggio viene modificato il nome
          newOptionNames:
            newField.options && newField.options.length > 0
              ? [newField.options[0].name]
              : []
        };
      })
    }));
  };

  /**
   * Gestisce il cambiamento del valore di una proprietà del campo
   * @param {Object} field oggetto campo
   * @param {String} fieldProp nome della proprietà del campo
   */
  onFieldPropChange = ({ name }, fieldProp) => value => {
    this.setState(state => ({
      fields: state.fields.map(({ field, errors, ...other }) => {
        if (field.name !== name) {
          return { field, errors, ...other };
        }
        return {
          field: {
            ...field,
            [fieldProp]: value
          },
          errors: {},
          ...other
        };
      })
    }));
  };

  /**
   * Gestisce il cambiamento del valore di una opzione di un campo
   * @param {Object} field oggetto campo
   * @param {String} optionName nome dell'opzione
   */
  onFieldPropOptionChange = ({ name }, optionName) => value => {
    this.setState(state => ({
      fields: state.fields.map(({ field, errors, ...other }) => {
        if (field.name !== name) {
          return { field, errors, ...other };
        }
        return {
          field: {
            ...field,
            options: field.options.map(option => {
              if (option.name !== optionName) {
                return option;
              }
              return {
                ...option,
                label: value
              };
            })
          },
          errors: {},
          ...other
        };
      })
    }));
  };

  /**
   * Gestisce l'inserimento di una opzione di un campo
   * Aggiunge l'opzione appena creata nella lista degli id di opzioni nuove
   * @param {Object} field oggetto campo
   */
  onFieldPropOptionAdd = ({ name }) => {
    this.setState(state => ({
      fields: state.fields.map(({ field, newOptionNames, ...other }) => {
        if (field.name !== name) {
          return { field, newOptionNames, ...other };
        }
        const newOption = generateBaseOption();
        return {
          field: {
            ...field,
            options: [...field.options, newOption]
          },
          newOptionNames: [...newOptionNames, newOption.name],
          ...other
        };
      })
    }));
  };

  /**
   * Gestisce la cancellazione di una opzione di un campo
   * Rimuove l'opzione appena creata dalla lista di id di opzioni nuove
   * @param {Object} field oggetto campo
   * @param {String} optionName nome dell'opzione
   */
  onFieldPropOptionDelete = ({ name }, optionName) => {
    const { fields } = this.state;
    const fieldToRemove = fields.find(({ field }) => field.name === name);
    if (fieldToRemove && fieldToRemove.field.options.length === 1) {
      return;
    }
    this.setState(state => ({
      fields: state.fields.map(({ field, newOptionNames, ...other }) => {
        if (field.name !== name || field.options.length === 1) {
          return { field, newOptionNames, ...other };
        }
        return {
          field: {
            ...field,
            options: field.options.filter(option => option.name !== optionName)
          },
          newOptionNames: newOptionNames.filter(name => name !== optionName),
          ...other
        };
      })
    }));
    this.showSnackbar(stringTranslate("modules", "fieldRemoved"), () => {
      // Viene ripristinato lo stato precedente di campi
      this.setState({
        fields
      });
      this.closeSnackbar();
    });
  };

  /**
   * Metodo richiamato al click del pulsante salva del custom form
   * Viene eseguita la verifica di tutti i campi.
   * Se tutto ok imposta SOLO per ogni nuova proprietà il nome e viene
   * eseguito il salvataggio a db del custom form e relativi campi
   */
  onSave = async () => {
    const { fields, customForm } = this.state;
    const [
      processedFields,
      processedCustomForm,
      errorFound
    ] = validateCustomFormAndFields(customForm, fields);
    if (errorFound) {
      this.setState({
        fields: processedFields,
        customForm: processedCustomForm
      });
      this.showSnackbar(stringTranslate("modules", "errorInvalidFields"));
      return;
    }
    await this.saveCustomFormAsync(
      processedCustomForm.prop,
      processedFields.map(({ field, isNew, newOptionNames }) => ({
        ...field,
        // Se è una nuova proprietà viene impostato il valore del nome (deve essere univoco)
        name: isNew
          ? `${field.label
              .substring(0, Math.min(15, field.label.length))
              .replace(" ", "_")
              .toLowerCase()}_${field.position}_${Date.now()}`
          : field.name,
        // Per ogni nuova opzione viene impostato il valore del nome (deve essere univoco)
        options:
          field.options && field.options.length > 0
            ? field.options.map((option, i) => {
                // Verifica se opzione è nuova
                const isOptionNew =
                  (newOptionNames &&
                    newOptionNames.find(
                      newOptName => newOptName === option.name
                    )) !== undefined;
                // Se nuova reimposta il valore del campo nome (univoco)
                return {
                  ...option,
                  name: isOptionNew
                    ? `${option.label
                        .substring(0, Math.min(15, option.label.length))
                        .replace(" ", "_")
                        .toLowerCase()}_${i}_${field.position}_${Date.now()}`
                    : option.name
                };
              })
            : []
      }))
    );
  };

  /**
   * Metodo richiamato quando viene eseguito il click su pulsante cancella
   * del custom form
   */
  onDelete = () => {
    const { used } = this.props;
    const { customForm } = this.state;
    if (used) {
      this.showSnackbar(
        stringTranslate("modules", "errorCannotDeleteCustomFormInUse")
      );
      return;
    }
    this.toggleShowDialogConfirm(() =>
      this.deleteCustomFormAsync(customForm.prop, used)
    );
  };

  /**
   * Metodo richiamata quando viene eseguito il click sul pulsante anteprima
   * Viene veficato che tutti i campi siano validi e richiamato il metodo
   * passato nelle prop per visualizzare la preview
   */
  onShowPreview = () => {
    const { fields, customForm } = this.state;
    const { onShowPreview } = this.props;
    const [
      processedFields,
      processedCustomForm,
      errorFound
    ] = validateCustomFormAndFields(customForm, fields);
    if (errorFound) {
      this.setState({
        fields: processedFields,
        customForm: processedCustomForm
      });
      this.showSnackbar(stringTranslate("modules", "errorInvalidFields"));
      return;
    }
    onShowPreview(
      {
        ...processedCustomForm.prop
      },
      processedFields.map(({ field }) => field)
    );
  };

  /**
   * Salva in firestore il custom form
   * Se il salvataggio avviene con successo vengono resettati i campi dello stato
   * (campi come: errors, isNew etc..)
   * @param {Object} customForm oggetto con le informazioni del form
   * @param {Array} fields lista di campi che compongono il custom form
   */
  saveCustomFormAsync = async (customForm, fields) => {
    const { firestore } = this.props;
    let savedCustomForm = customForm;
    try {
      if (customForm.id !== undefined && customForm.id !== "") {
        await firestore
          .collection(`${getFirestoreCollection()}/customForms`)
          .doc(customForm.id)
          .update({
            name: customForm.name,
            fields
          });
      } else {
        savedCustomForm = await firestore
          .collection(`${getFirestoreCollection()}/customForms`)
          .add({
            name: customForm.name,
            createdAt: new Date(),
            fields
          });
      }
    } catch (e) {
      console.log("error save custom form", e);
      this.showSnackbar(stringTranslate("modules", "errorSaveCustomFrom"));
      return;
    }
    this.setState({
      customForm: {
        prop: {
          id: savedCustomForm.id,
          ...customForm
        },
        errors: {}
      },
      fields: resetStateFields(fields)
    });
    this.showSnackbar(stringTranslate("modules", "customFormSaved"));
  };

  /**
   * Cancella il custom form da db se non in uso.
   * Se la cancellazione avviene con successo viene chiuso il builder
   * @param {Object} customForm oggetto custom form
   * @param {Boolean} used se true indica se custom form in uso
   */
  deleteCustomFormAsync = async (customForm, used) => {
    const { firestore, onBack } = this.props;
    if (used) {
      this.showSnackbar(
        stringTranslate("modules", "errorCannotDeleteCustomFormInUse")
      );
      return;
    }
    try {
      await firestore
        .collection(`${getFirestoreCollection()}/customForms`)
        .doc(customForm.id)
        .delete();
    } catch (e) {
      console.log("error delete custom form", e);
      this.showSnackbar(stringTranslate("modules", "errorDeleteCustomFrom"));
      return;
    }
    onBack();
  };

  /**
   * Renderizza il pulsante indietro
   * Viene visualizzato solo se in modifica
   */
  renderBackButton = () => {
    const { customForm, onBack } = this.props;
    const isEditing = customForm !== undefined && customForm !== null;
    if (isEditing) {
      return <BackButton onClick={onBack} />;
    }
    return undefined;
  };

  render() {
    const { customForm, fields, snackbar, dialogConfirm } = this.state;
    const { classes, used } = this.props;
    return (
      <div>
        <ActionBar>
          {this.renderBackButton()}
          <Typography className={classes.title} variant="h3">
            {customForm.prop && customForm.prop.id
              ? stringTranslate("modules", "modifyModule")
              : stringTranslate("modules", "newModule")}
          </Typography>
          <div className={classes.contentButtons}>
            <ResponsiveButton
              className={classes.button}
              variant="outlined"
              onClick={this.onShowPreview}
              text={stringTranslate("modules", "preview")}
              icon={PreviewIcon}
              fullWithIcon={false}
            />
            {!used && customForm.prop && customForm.prop.id && (
              <ResponsiveButton
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={this.onDelete}
                text={stringTranslate("modules", "delete")}
                icon={DeleteIcon}
                fullWithIcon={false}
              />
            )}
            <ResponsiveButton
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={this.onSave}
              text={stringTranslate("modules", "save")}
              icon={CheckIcon}
              fullWithIcon={false}
            />
          </div>
        </ActionBar>
        <div className={classes.contentCustomFormFields}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TextField
                label={stringTranslate("modules", "customFormName")}
                error={customForm.errors["name"] !== undefined}
                helperText={customForm.errors["name"]}
                value={customForm.prop.name}
                onChange={e => {
                  const { value } = e.target;
                  this.setState(state => ({
                    customForm: {
                      ...state.customForm,
                      prop: {
                        ...state.customForm.prop,
                        name: value
                      },
                      errors: {}
                    }
                  }));
                }}
                InputProps={{
                  className: classes.inputText
                }}
                InputLabelProps={{
                  className: classes.inputTextLabel
                }}
                margin="normal"
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        {fields && (
          <Fields
            fields={fields.sort((f1, f2) => fieldCompare(f1.field, f2.field))}
            onAdd={this.onFieldAdd}
            onDelete={this.onFieldDelete}
            onMove={this.onFieldMove}
            onFieldTypeChange={this.onFieldTypeChange}
            onFieldPropChange={this.onFieldPropChange}
            onFieldPropOptionDelete={this.onFieldPropOptionDelete}
            onFieldPropOptionChange={this.onFieldPropOptionChange}
            onFieldPropOptionAdd={this.onFieldPropOptionAdd}
          />
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          message={snackbar.message}
          action={
            snackbar.action && [
              <Button
                key="undo"
                color="secondary"
                size="small"
                onClick={snackbar.action}
              >
                {stringTranslate("modules", "undo")}
              </Button>
            ]
          }
        />
        <OMDeleteConfirmationAlert
          isOpen={dialogConfirm.open}
          deleteHandler={() => {
            this.toggleShowDialogConfirm();
            dialogConfirm.callback();
          }}
          cancelHandler={() => this.toggleShowDialogConfirm()}
        />
      </div>
    );
  }
}

FormBuilder.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  customForm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.shape({
      seconds: PropTypes.number.isRequired
    }).isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        position: PropTypes.number.isRequired,
        help: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string
          })
        )
      })
    )
  }),
  onBack: PropTypes.func.isRequired,
  onShowPreview: PropTypes.func.isRequired,
  used: PropTypes.bool
};

FormBuilder.defaultProps = {
  customForm: undefined,
  used: false
};

export default compose(
  firestoreConnect(),
  withStyles(styles, { withTheme: true })
)(FormBuilder);
