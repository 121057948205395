import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import { stringTranslate } from "languages/OMTranslate";
import CheckboxWithOptions from "./CheckboxWithOptions";
import FilterCheckbox from "./FilterCheckbox";
import MultipleSelect from "../../common/MultipleSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = theme => ({
  filterContainer: {
    position: "absolute",
    top: "10px",
    right: "102px",
    height: "30px",
    backgroundColor: theme.palette.primary.white,
    borderRadius: "3px",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 6px",
    zIndex: "1",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      right: "auto",
      top: "auto",
      left: "10px",
      bottom: "10px"
    }
  },
  resourceCheckbox: {
    marginRight: "6px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  taskFilterOptionsContainer: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "8px"
  },
  customerFilterOptionsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    padding: "8px"
  },
  close: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "8px",
    paddingTop: "4px"
  }
});

const MapFilter = ({
  classes,
  showResources,
  showCustomers,
  showTasks,
  handleChange,
  toggleCustomerFilterOptions,
  customerFilterAnchor,
  poiCategories,
  selectedCategories,
  parties,
  selectedParties,
  handleMultipleSelect
}) => {
  const hasPartyFilter =
    selectedParties.length > 0 || selectedCategories.length > 0;

  return (
    <div className={classes.filterContainer}>
      <FormGroup row style={{ height: "30px" }}>
        <div className={classes.resourceCheckbox}>
          <FilterCheckbox
            checked={showResources}
            onChange={event => handleChange("showResources", event)}
            label={stringTranslate("mainactivity", "Resources")}
          />
        </div>

        <CheckboxWithOptions
          indeterminate={hasPartyFilter}
          checked={showCustomers}
          handleChange={event => handleChange("showCustomers", event)}
          openOptions={toggleCustomerFilterOptions}
          label={stringTranslate("mainactivity", "Customers")}
          anchor={customerFilterAnchor}
        >
          <React.Fragment>
            <div className={classes.close}>
              <div
                onClick={toggleCustomerFilterOptions}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  icon={["fas", "times"]}
                  style={{ color: "#808080" }}
                />
              </div>
            </div>
            <div className={classes.customerFilterOptionsContainer}>
              <MultipleSelect
                inputLabel={stringTranslate("mainactivity", "selectCustomers")}
                selected={selectedParties}
                onChange={event =>
                  handleMultipleSelect(event, "selectedParties")
                }
                id="select-multiple-customer"
                values={parties}
              />

              <MultipleSelect
                inputLabel={stringTranslate("mainactivity", "selectCategories")}
                selected={selectedCategories}
                onChange={event =>
                  handleMultipleSelect(event, "selectedCategories")
                }
                id="select-multiple-category"
                values={poiCategories}
                style={{ marginTop: "12px" }}
              />
            </div>
          </React.Fragment>
        </CheckboxWithOptions>
        <div className={classes.resourceCheckbox}>
          <FilterCheckbox
            checked={showTasks}
            onChange={event => handleChange("showTasks", event)}
            label={stringTranslate("mainactivity", "Tasks")}
          />
        </div>
      </FormGroup>
    </div>
  );
};
export default withStyles(styles, { withTheme: true })(MapFilter);
