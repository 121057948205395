import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import OMSearchAddBar from "main/content/common/OMSearchAddBar";
import CustomFormList from "./customForm/CustomFormList";

const styles = theme => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    height: "100%"
  }
});

class CustomFormsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ""
    };
  }

  /**
   * Applica il filtro di ricerca alla lista di custom form
   * @param {Array} customForms lista du custom form
   */
  applyFilter = customForms => {
    const { searchText } = this.state;
    if (customForms === undefined || customForms.length === 0) {
      return [];
    }

    let filteredForms = [...customForms];

    if (searchText !== "") {
      filteredForms = customForms.filter(
        form => form.name.toLowerCase().search(searchText.toLowerCase()) > -1
      );
    }

    filteredForms.sort(function(f1, f2) {
      if (f1.createdAt.seconds < f2.createdAt.seconds) {
        return 1;
      }
      if (f1.createdAt.seconds > f2.createdAt.seconds) {
        return -1;
      }
      return 0;
    });
    return filteredForms;
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  setSearchText = event => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };
  clearText = () => {
    this.setState({ searchText: "" });
  };

  render() {
    const {
      classes,
      customForms,
      selectedCustomForm,
      onSelectCustomForm,
      onAdd
    } = this.props;
    const { searchText } = this.state;
    const filteredForms = this.applyFilter(customForms);

    return (
      <div
        className={classes.root}
        ref={root => {
          this.root = root;
        }}
      >
        <OMSearchAddBar
          searchText={searchText}
          textChangeHandler={this.setSearchText}
          textClearHandler={this.clearText}
          addClickHandler={onAdd}
          withAdd
        />
        <CustomFormList
          customForms={filteredForms}
          selectedCustomForm={selectedCustomForm}
          onSelectCustomForm={onSelectCustomForm}
        />
      </div>
    );
  }
}

CustomFormsPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  customForms: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired
      }).isRequired
    })
  ),
  selectedCustomForm: PropTypes.shape(),
  onSelectCustomForm: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired
};

CustomFormsPanel.defaultProps = {
  customForms: [],
  selectedCustomForm: undefined
};

export default withStyles(styles, { withTheme: true })(CustomFormsPanel);
