export const reports = {
  Calculate: "Calcola",
  Date: "Data",
  From: "Dalle",
  To: "Alle",
  Resource: "Risorsa",
  Time: "Orario",
  fromDate: "Dalla data",
  toDate: "Alla data",

  Speed: "Velocita'",

  "Routes Report": "Report Percorsi",
  "Resource Report": "Report Risorse",
  "No results title": "Nessun risultato trovato",
  "No results message":
    "Nessun risultato trovato con i parametri di ricerca indicati",
  showColumns: "Mostra colonne",
  viewReport: "Vedi report",

  //Report risorse
  resourceSubtotal: "Subtotale risorsa",
  partySubtotal: "Subtotale cliente",
  resource: "Risorsa",
  total: "Totale",
  firstPositionHour: "Ora inizio attività",
  lastPositionHour: "Ora fine attività",
  deltaPositionsHour: "Ore impegno",
  taskExecutionTime: "Ore lavorate",
  handledTasks: "Task gestiti",
  completedTasks: "Task completati",
  showChart: "Mostra grafico",
  showTable: "Mostra tabella",

  //Report timbrature
  validTime: "Valide",
  modifiedTime: "Da verificare",
  TimeTracking: "Timbrature giornaliere",
  timeTrackingFormTitle: "Modifica timbratura",
  dateStart: "Dal",
  dateEnd: "Al",
  momentFormat: "DD/MM/YYYY",
  emptyReport: "Nessun risultato",
  "Show positions": "Mostra posizioni",
  "Show route": "Mostra Percorso",
  "Tasks Report": "Report Attivita'",
  "From Date": "Dalla data",
  "To Date": "Alla data",
  timeEnd: "Orario di fine",
  save: "Salva",
  days: "Giorni",
  totalTime: "Totale ore: ",
  Departure: "Partenza",
  Arrival: "Arrivo",
  "Total route": "Totale percorso",
  groupBy: "Raggruppa per",

  period: "Periodo",
  today: "Oggi",
  yesterday: "Ieri",
  sevenDays: "Fino a 7 giorni fa",
  thirtyDays: "Fino a 30 giorni fa",
  customDays: "Scegli date"
};
