import "babel-polyfill";
import "typeface-muli";
import React from "react";
import ReactDOM from "react-dom";
import history from "./history";
import "./react-table-defaults";
import "./styles/index.css";
import JssProvider from "react-jss/lib/JssProvider";
import { create } from "jss";
import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";
import { unregister } from "./registerServiceWorker";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { routes } from "./fuse-configs/fuseRoutesConfig";
import { FuseLayout, FuseTheme, FuseAuthorization } from "@fuse";
import OMToolbar from "./main/common/toolbar/OMToolbar";
import jssExtend from "jss-extend";
import store from "store";
import { Auth } from "auth";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { SnackbarProvider } from "notistack";
import { setDateFsnGlobalLocale } from "languages/OMTranslate";
import OMErrorBoundary from "main/common/OMErrorBoundary";
import OMAuthVerify from "main/common/OMAuthVerify";
import { Offline, Online } from "react-detect-offline";
import OfflinePage from "main/content/pages/errors/NotAvaiable/OfflinePage";

library.add(fas);

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()]
});

jss.options.insertionPoint = document.getElementById("jss-insertion-point");
const generateClassName = createGenerateClassName();
setDateFsnGlobalLocale(store.getState().auth.user.data.userLanguage);
const pollingNetworkObj = { interval: 15 * 1000, enabled: false };

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <DragDropContextProvider backend={HTML5Backend}>
      <SnackbarProvider
        maxSnack={3}
        hideIconVariant={true}
        preventDuplicate={true}
      >
        <Provider store={store}>
          <Auth>
            <Router history={history}>
              <FuseAuthorization routes={routes}>
                <FuseTheme>
                  <OMErrorBoundary>
                    <OMAuthVerify>
                      <Online polling={pollingNetworkObj}>
                        <FuseLayout routes={routes} toolbar={<OMToolbar />} />
                      </Online>
                      <Offline
                        polling={pollingNetworkObj}
                        onChange={online => {
                          if (online) window.location.reload(true);
                        }}
                      >
                        <OfflinePage />
                      </Offline>
                    </OMAuthVerify>
                  </OMErrorBoundary>
                </FuseTheme>
              </FuseAuthorization>
            </Router>
          </Auth>
        </Provider>
      </SnackbarProvider>
    </DragDropContextProvider>
  </JssProvider>,
  document.getElementById("root")
);

// registerServiceWorker();
unregister();
