import { authRoles } from "auth";
import MainActivityPage from "./MainActivityPage";

export const MainActivityConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.staff, //['admin','staff']
  routes: [
    {
      path: "/mainActivity",
      component: MainActivityPage
    }
  ]
};
