import * as Actions from "../../actions/mainActivityPage/index";

const initialState = {
  selectedResource: null,
  updateList: false,
  searchedResources: []
};

const resourceList = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_RESOURCE:
      return {
        ...state,
        selectedResource: action.resourceId,
        updateList: true
      };
    case Actions.SET_SEARCHED_RESOURCES:
      return { ...state, searchedResources: action.resources };
    case Actions.SET_AS_UPDATED: {
      return {
        ...state,
        updateList: false
      };
    }
    case Actions.RESET_ACTION: {
      return initialState;
    }
    default:
      return state;
  }
};

export default resourceList;
