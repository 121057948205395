import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const styles = theme => ({
  formControlLabel: {
    marginLeft: "0px",
    marginRight: "0px"
  },
  checkbox: {
    padding: "3px",
    fontSize: "20px"
  },
  label: {
    fontSize: theme.text.small,
    fontWeight: "300"
  }
});

const FilterCheckbox = ({ checked, onChange, label, classes }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
          checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
          checked={checked}
          onChange={onChange}
          value={label}
          color="primary"
          classes={{ root: classes.checkbox }}
        />
      }
      label={label}
      classes={{
        root: classes.formControlLabel,
        label: classes.label
      }}
    />
  );
};
export default withStyles(styles, { withTheme: true })(FilterCheckbox);
