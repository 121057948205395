import React from "react";
import { withStyles } from "@material-ui/core";
import AccountButtonMenu from "./AccountButtonMenu";
import AccountDataForm from "./AccountDataForm";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

const styles = theme => ({
  root: {
    position: "relative",
    display: "flex",
    flexFlow: "row nowrap",
    height: "calc(100vh - " + theme.measures.navbarHeight + ")",
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      flexFlow: "column nowrap"
    }
  }
});

const AccountPage = ({ classes }) => {
  return (
    <div className={classes.root} id="hContainer">
      <AccountButtonMenu />
      <AccountDataForm />
    </div>
  );
};

export default compose(
  firestoreConnect(props => [
    {
      collection: "accounts",
      doc: localStorage.getItem("accountId"),
      storeAs: "account"
    }
  ]),
  withStyles(styles, { withTheme: true })
)(AccountPage);
