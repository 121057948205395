import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { stringTranslate } from 'languages/OMTranslate';

const styles = theme => ({
  label: {
    fontSize: '11px',
    marginBottom: '3px'
  },
  input: {
    width: '100%',
    color: theme.palette.primary.dark,
    '& < div': {
      display: 'block'
    }
  }
});

const DateField = ({ classes, field, value, onChange, error }) => {
  let date = null;
  if (Object.prototype.toString.call(value) === '[object Date]') {
    date = value;
  } else {
    date =
      value && value.seconds && value.seconds > 0
        ? new Date(value.seconds * 1000)
        : null;
  }
  return (
    <FormControl
      className={classNames(
        classes.formControl,
        'form-control-date-picker-full'
      )}
      error={error !== undefined && error !== ''}
      required={field.required}
      component="fieldset"
      fullWidth
      margin="normal"
    >
      <FormLabel component="legend" className={classes.label}>
        {field.label}
      </FormLabel>
      <DatePicker
        selected={date}
        onChange={date => onChange(field)(date)}
        className={classNames('date-picker-input', classes.input, {
          error: error !== undefined && error !== ''
        })}
        placeholderText={field.help}
        dateFormat={stringTranslate('general', 'fsnDateFormat')}
        isClearable={true}
        popperPlacement="bottom-start"
      />
      {error !== undefined && error !== '' && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

DateField.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    position: PropTypes.number.isRequired,
    help: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string
      })
    )
  }).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      seconds: PropTypes.number.isRequired
    }),
    PropTypes.instanceOf(Date)
  ]),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

DateField.defaultProps = {
  value: {
    seconds: 0
  },
  error: undefined
};

export default withStyles(styles, { withTheme: true })(DateField);
