import { isResourceOffline } from "main/common/resources/resourceHelper";

export function getResourceMarkerIcon(resource) {
  var color = "grey";
  var direction = getDirection(null);
  if (resource.activity && resource.activity === "FREE") {
    color = "blue";
    if (resource.status !== "STILL")
      direction = getDirection(resource.lastPosition.heading);
  }
  if (resource.activity && resource.activity === "ENGAGED") {
    color = "red";
    if (resource.status !== "STILL")
      direction = getDirection(resource.lastPosition.heading);
  }
  if (
    isResourceOffline(resource) ||
    (resource.status && resource.status === "PRIVACY")
  ) {
    direction = getDirection(null);
    color = "grey";
  }
  const baseUrl = getBaseUrl();
  return baseUrl + color + getBaseName() + direction + getExtension();
}

export function getTaskMarkerIcon() {
  const directory = "tasks";
  const baseUrl = getBaseUrl();
  return baseUrl + directory + getBaseName() + "task" + getExtension();
}
export function getSearchMarkerIcon(task) {
  const directory = "search";
  const baseUrl = getBaseUrl();
  return baseUrl + directory + getBaseName() + "search" + getExtension();
}

export function getPartiesMarkerIcon(color) {
  const directory = "pois";
  const baseUrl = getBaseUrl();

  if (color) {
    return baseUrl + directory + "/poi-" + color + getExtension();
  } else {
    return baseUrl + directory + getBaseName() + "poi" + getExtension();
  }
}

export function getDirectionMarkerIcon(position) {
  const directory = "directions";
  const direction = getDirection(position.heading);
  const baseUrl = getBaseUrl();

  return baseUrl + directory + getBaseName() + direction + getExtension();
}

function getExtension() {
  return ".svg";
}
function getBaseName() {
  return "/map-marker-";
}

function getBaseUrl() {
  var iconurl = window.location.protocol + "//" + window.location.hostname;
  if (
    window.location.hostname === "10.1.2.44" ||
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    iconurl = iconurl + ":" + window.location.port;
  }
  iconurl = iconurl + "/assets/mapIcons/";
  return iconurl;
}

function getDirection(heading) {
  if (!heading && heading !== 0) return "still";
  let direction = "n";
  if (heading >= 338 || heading < 23) direction = "n";
  if (heading >= 23 && heading < 68) direction = "ne";
  if (heading >= 68 && heading < 113) direction = "e";
  if (heading >= 113 && heading < 158) direction = "se";
  if (heading >= 158 && heading < 203) direction = "s";
  if (heading >= 203 && heading < 248) direction = "sw";
  if (heading >= 248 && heading < 293) direction = "w";
  if (heading >= 293 && heading < 338) direction = "nw";

  return direction;
}
