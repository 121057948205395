import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";

import { firestoreConnect } from "react-redux-firebase";
import Modal from "@material-ui/core/Modal";
import {
  setSelectedContact,
  toggleContactModal
} from "store/actions/customersPage";
import OMTabSearchAddBar from "../common/OMTabSearchAddBar";
import ContactFormModal from "./ContactFormModal";
import ContactsList from "./ContactsList";

const styles = theme => ({
  root: {
    padding: theme.measures.panelPadding,
    width: "100%"
  }
});

class ContactsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ""
    };
  }
  setSearchText = event => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };

  render() {
    const {
      toggleContactModal,
      setSelectedContact,
      showContactsModal,
      selectedCustomer,
      selectedContact,
      contacts
    } = this.props;
    const { searchText } = this.state;

    var filteredContacts = null;
    if (contacts) {
      filteredContacts = contacts.filter(contact => {
        var thisParty = false;
        if (contact.partyId === selectedCustomer.id) thisParty = true;
        else thisParty = false;
        var found = false;
        if (thisParty) {
          if (
            (contact.name &&
              contact.name.toLowerCase().search(searchText.toLowerCase()) >
                -1) ||
            (contact.email &&
              contact.email.toLowerCase().search(searchText.toLowerCase()) >
                -1) ||
            (contact.phone &&
              contact.phone.toLowerCase().search(searchText.toLowerCase()) > -1)
          ) {
            found = true;
          }
        }
        return found;
      });
    }

    if (filteredContacts) {
      filteredContacts.sort(function(x, y) {
        if (!x.name) return -1;
        if (!y.name) return 1;
        if (x.name.toLowerCase() < y.name.toLowerCase()) {
          return -1;
        }
        if (x.name.toLowerCase() > y.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }

    return (
      <React.Fragment>
        <OMTabSearchAddBar
          textChangeHandler={this.setSearchText}
          textClearHandler={() => {
            this.setState({ searchText: "" });
          }}
          addClickHandler={() => {
            setSelectedContact(null);
            toggleContactModal();
          }}
          searchText={searchText}
          withAdd={!Boolean(this.props.hideAdd)}
        />
        <ContactsList filteredContacts={filteredContacts} />

        <Modal
          open={showContactsModal}
          onBackdropClick={() => toggleContactModal()}
        >
          <ContactFormModal selectedContact={selectedContact} />
        </Modal>
      </React.Fragment>
    );
  }
}

ContactsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  showContactsModal: state.customersPage.contactsTab.showContactsModal,
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer,
  selectedContact: state.customersPage.selectedContact.selectedContact,
  contacts: state.firestore.ordered.contacts
});

const mapDispatchToProps = dispatch => ({
  setSelectedContact: contact => dispatch(setSelectedContact(contact)),
  toggleContactModal: () => dispatch(toggleContactModal())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(ContactsTab);
