import React from "react";
import { Popper, Paper, Fade, withStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CirclePicker } from "react-color";

const styles = theme => ({
  round: {
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    cursor: "pointer"
  },
  popper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px"
  },
  close: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "8px",
    paddingTop: "4px"
  }
});

export const colorNames = {
  "#d50000": "red",
  "#ff80ab": "pink",
  "#d500f9": "fucsia",
  "#7e57c2": "purple",
  "#283593": "deep-blue",
  "#2962ff": "blue",
  "#4fc3f7": "light-blue",
  "#00838f": "cyan",
  "#1de9b6": "teal",
  "#00c853": "green",
  "#689f38": "light-green",
  "#ffea00": "yellow",
  "#ff8f00": "orange",
  "#d84315": "deep-orange",
  "#4e342e": "brown"
};

export const colors = [
  "#d50000", // red
  "#ff80ab", // pink
  "#d500f9", // fucsia
  "#7e57c2", // purple
  "#283593", // deep blue
  "#2962ff", // blue
  "#4fc3f7", // light blue
  "#00838f", // cyan
  "#1de9b6", // teal
  "#00c853", // green
  "#689f38", // light green
  "#ffea00", // yellow
  "#ff8f00", // orange
  "#d84315", // deep orange
  "#4e342e" // brown
];

class ColorPicker extends React.Component {
  state = { selector: null };

  openColorPicker = event => {
    this.setState({ selector: event.currentTarget });
  };

  onColorChange = color => {
    this.setState({ selector: null });
    if (this.props.onChange) {
      this.props.onChange(color);
    }
  };

  render() {
    const { selector } = this.state;
    const { classes, color } = this.props;
    const selectedColor = color ? color : "#f44336";

    return (
      <React.Fragment>
        <div
          onClick={this.openColorPicker}
          className={classes.round}
          style={{
            backgroundColor: selectedColor
          }}
        />
        <Popper
          id={"colorPickerPopper"}
          open={Boolean(selector)}
          anchorEl={selector}
          style={{ zIndex: 1400 }}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <div className={classes.close}>
                  <div
                    onClick={event => this.setState({ selector: null })}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon
                      icon={["fas", "times"]}
                      style={{ color: "#808080" }}
                    />
                  </div>
                </div>
                <div className={classes.popper}>
                  <CirclePicker
                    width="210px"
                    colors={colors}
                    onChangeComplete={(color, event) =>
                      this.onColorChange(color)
                    }
                  />
                </div>
              </Paper>
            </Fade>
          )}
        </Popper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ColorPicker);
