import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

import moment from "moment";
import "moment/min/locales";

import { stringTranslate, getMomementLocale } from "languages/OMTranslate";

import { FieldTypes, DefaulFieldTypes } from "../ModulesConfig";

/**
 * Processa il valore di un campo del modulo che verrà visualizzato nella tabella
 * @param {Any} data valore del campo
 * @param {Object} field definizione del campo
 */
function preprocessData(data, field) {
  if (data === undefined || data === null) {
    return "-";
  }
  if (
    field.type === DefaulFieldTypes.createdAt ||
    field.type === FieldTypes.date
  ) {
    moment.locale(getMomementLocale());
    return moment
      .unix(data.seconds)
      .format(stringTranslate("general", "momentDateFormat"));
  }
  if (field.type === FieldTypes.time) {
    moment.locale(getMomementLocale());
    return moment
      .unix(data.seconds)
      .format(stringTranslate("general", "momentTimeFormat"));
  }
  if (field.type === FieldTypes.radio || field.type === FieldTypes.dropdown) {
    const selectedOpt = field.options.find(option => option.name === data);
    return selectedOpt ? selectedOpt.label : "-";
  }
  if (field.type === FieldTypes.check) {
    const selectedOpts = field.options.filter(option =>
      data.find(d => d === option.name)
    );
    return selectedOpts ? selectedOpts.map(opt => opt.label).join(", ") : "-";
  }
  return data;
}

/**
 * Genera i dati e le colonne per la visualizzazione nella tabella
 * @param {Array} modules lista di moduli
 * @param {Array} fields lista di campi che definiti per il custom form
 */
function generateDataAndColumns(modules, fields) {
  const columns = fields.map(field => field.label);
  const data = modules.map(module =>
    fields.map(field => preprocessData(module[field.name], field))
  );
  return [data, columns];
}

const styles = () => ({});

const ModulesTable = ({ fields, modules, onModuleClick }) => {
  const [data, columns] = generateDataAndColumns(modules, fields);
  return (
    <MUIDataTable
      data={data}
      columns={columns}
      options={{
        filterType: "dropdown",
        responsive: "scroll",
        selectableRows: false,
        pagination: false,
        download: false,
        print: false,
        fixedHeader: false,
        elevation: 0,
        textLabels: stringTranslate("modules", "tableTextLabels"),
        onRowClick: (rowData, { dataIndex }) => {
          onModuleClick(modules[dataIndex]);
        }
      }}
    />
  );
};

ModulesTable.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
      placeholder: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string
        })
      )
    }).isRequired
  ).isRequired,
  modules: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  onModuleClick: PropTypes.func.isRequired
};

ModulesTable.defaultProps = {
  modules: []
};

export default withStyles(styles)(ModulesTable);
