export const SAVE_NOTIFICATIONS = "[NOTIFICATION] SAVE_NOTIFICATIONS";
export const EMPTY_NOTIFICATIONS = "[NOTIFICATION] EMPTY_NOTIFICATIONS";

export function saveNotifications(notifications) {
  return {
    type: SAVE_NOTIFICATIONS,
    notifications
  };
}

export function emptyNotifications() {
  return {
    type: EMPTY_NOTIFICATIONS
  };
}
