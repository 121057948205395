import * as Actions from "../../actions/customersPage/index";

const initialState = {
  selectedContact: false
};

const selectedContact = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SELECTED_CONTACT: {
      return {
        ...state,
        selectedContact: action.contact
      };
    }
    case Actions.RESET_ACTION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default selectedContact;
