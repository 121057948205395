/**
 * Funzione di ordinamento dei campi per la loro posizione
 * @param {Object} field1 oggetto campo 1
 * @param {Object} field2 oggetto campo 2
 */
export const fieldCompare = (field1, field2) => {
  if (field1.position > field2.position) {
    return 1;
  }
  if (field1.position < field2.position) {
    return -1;
  }
  return 0;
};
