import { authRoles } from "auth";
import CustomersPage from "./CustomersPage";
import ImportCustomers from "./ImportCustomers";

export const CustomersConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.staff, //['admin','staff']
  routes: [
    {
      path: "/customers",
      component: CustomersPage
    },
    {
      path: "/importCustomers",
      component: ImportCustomers
    }
  ]
};
