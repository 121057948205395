import { authRoles } from "auth";
import UsersPage from "./UsersPage";
import UserAddFormPage from "./UserAddFormPage";
import UserModifyFormPage from "./UserModifyFormPage";

export const UsersConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.admin, //['admin']
  routes: [
    {
      path: "/users",
      component: UsersPage
    },
    {
      path: "/userAdd",
      component: UserAddFormPage
    },
    {
      path: "/userModify",
      component: UserModifyFormPage
    }
  ]
};
