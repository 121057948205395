import { authRoles } from "auth";
import AccountPage from "./AccountPage";
import AccountSubscriptionPage from "./AccountSubscriptionPage";
import AccountPaymentsPage from "./AccountPaymentsPage";

export const AccountConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.admin, //['admin','staff']
  routes: [
    {
      path: "/account",
      component: AccountPage
    },
    {
      path: "/subscription",
      component: AccountSubscriptionPage
    },
    {
      path: "/payments",
      component: AccountPaymentsPage
    }
  ]
};
