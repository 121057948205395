import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Badge,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Hidden,
  CircularProgress
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ToAssignTaskBar from "./ToAssignTaskBar";
import moment from "moment";
import "moment/min/locales";
import { getEndedExecutionsStatuses } from "main/common/tasks/taskHelper";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate, getMomementLocale } from "languages/OMTranslate";
import { getTaskColor } from "../scheduler/getTaskColor";
import checkAccountPermission from "main/common/account/accountEditionConfig";
import {
  toggleTaskForm,
  shareTaskListFiltered
} from "store/actions/mainActivityPage";
import TaskCard from "./TaskCard";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import firebase, { getFirestoreCollection } from "../../../../firestoreAccount";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import TaskListItem from "./TaskListItem";
import LoadingModal from "../../../common/LoadingModal";
import TableHeader from "./TableHeader";
import TaskDetail from "./detail/TaskDetail";

function desc(a, b, orderBy) {
  if (!b[orderBy] && !a[orderBy]) {
    return 0;
  }
  if (!b[orderBy] && a[orderBy]) {
    return -1;
  }
  if (b[orderBy] && !a[orderBy]) {
    return 1;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function compareLocation(a, b) {
  if (b["partyName"] + b["poiAddress"] < a["partyName"] + a["poiAddress"]) {
    return -1;
  }
  if (b["partyName"] + b["poiAddress"] > a["partyName"] + a["poiAddress"]) {
    return 1;
  }
  return 0;
}

function compareOrder(a, b, type) {
  if (
    (!a["executionOrder"] || a["executionOrder"] === "") &&
    b["executionOrder"] &&
    b["executionOrder"] !== ""
  ) {
    return type === "desc" ? 1 : -1;
  }

  if (
    (!b["executionOrder"] || b["executionOrder"] === "") &&
    a["executionOrder"] &&
    a["executionOrder"] !== ""
  ) {
    return type === "desc" ? -1 : 1;
  }

  if (a["executionOrder"] < b["executionOrder"]) {
    return 1;
  } else if (a["executionOrder"] > b["executionOrder"]) {
    return -1;
  } else {
    return 0;
  }
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy === "where") {
    return order === "desc"
      ? (a, b) => compareLocation(a, b)
      : (a, b) => -compareLocation(a, b);
  }

  if (orderBy === "executionOrder") {
    return order === "desc"
      ? (a, b) => compareOrder(a, b, "desc")
      : (a, b) => -compareOrder(a, b, "asc");
  }

  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
  tableWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  toAssignTaskPanel: {
    backgroundColor: theme.palette.primary.background
  },
  toAssignTaskPanelSummary: {
    paddingLeft: 10,
    fontSize: theme.typography.fontSize
  },
  toAssignTaskPanelSummaryContent: {
    "& > :last-child": {
      paddingRight: 10
    }
  },
  taskCardsContainer: {
    overflowY: "auto",
    flex: "1 1 auto"
  }
});

class MainActivityTaskList extends React.Component {
  state = {
    allTasks: [],
    filteredTasks: [],
    order: "asc",
    orderBy: "description",
    page: 0,
    rowsPerPage: 5,
    filterToYesterday: false,
    filterToday: true,
    filterFromTomorrow: false,
    filterExecutionStatus: [],
    freeSearch: "",
    filterDate: null,
    showFilters: true,
    loading: false
  };

  componentDidMount() {
    firebase
      .firestore()
      .collection(getFirestoreCollection() + "/tasks")
      .onSnapshot(querySnapshot => {
        let allTasks = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            allTasks.push({
              id: doc.id,
              ...doc.data(),
              code: doc.data().code ? doc.data().code : "",
              timeStamp: this.getTimestamp(doc.data())
            });
          });
        }
        this.setState({ allTasks }, () => {
          this.populateRelatedRecords();
          this.filterTasks();
        });
      });
  }

  populateRelatedRecords = () => {
    const { resources, contacts, pois, parties } = this.props;
    const tasks = this.state.allTasks;
    const populatedTasks = [];
    tasks.forEach(task => {
      populatedTasks.push({
        ...task,
        resource: resources[task.resourceId],
        resourceName:
          resources && resources[task.resourceId] != null
            ? resources[task.resourceId].name
            : "",
        poi: pois && pois[task.poiId],
        poiAddress:
          pois && pois[task.poiId] != null ? pois[task.poiId].address : "",
        partyName:
          parties && parties[task.partyId] ? parties[task.partyId].name : "",
        contact: contacts && contacts[task.contactId],
        contactName:
          contacts && contacts[task.contactId] != null
            ? contacts[task.contactId].name
            : "",
        contactPhone:
          contacts && contacts[task.contactId] != null
            ? contacts[task.contactId].phone
            : ""
      });
    });
    this.setState({ allTasks: populatedTasks }, () => this.orderTasks());
  };

  // FILTRI
  handleFilterDate = date => {
    if (date !== null) {
      this.setState(
        {
          filterDate: date,
          filterToYesterday: false,
          filterToday: false,
          filterFromTomorrow: false
        },
        () => this.filterTasks()
      );
    } else {
      this.setState({ filterDate: date }, () => this.filterTasks());
    }
  };

  handleFreeSearch = event => {
    this.setState({ freeSearch: event.target.value }, () => this.filterTasks());
  };

  checkboxHandler = (property, checked) => {
    this.setState(
      {
        [property]: checked
      },
      () => this.filterTasks()
    );
  };

  handleStatusChange = event => {
    this.setState(
      {
        filterExecutionStatus: event.target.value
      },
      () => this.filterTasks()
    );
  };

  filterTasks = () => {
    if (!this.state.allTasks) return;

    // Prende tutti i valori dei filtri dallo stato e li applica
    const {
      filterToYesterday,
      filterToday,
      filterFromTomorrow,
      filterExecutionStatus,
      freeSearch,
      filterDate
    } = this.state;

    const filteredTaskIds = [];

    const today = new Date();
    const yesterday = new Date();
    const tomorrow = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    tomorrow.setDate(tomorrow.getDate() + 1);
    today.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);
    tomorrow.setHours(0, 0, 0, 0);

    this.state.allTasks.forEach(task => {
      let resourceFilter = true;
      let dateFilter = true;
      let freeSearchFilter = true;
      let statusFilter = true;

      let yesterdayFilter = false;
      let todayFilter = false;
      let tomorrowFilter = false;
      let hasFilter = false;

      const taskDate = new Date(task.timeStamp * 1000);
      taskDate.setHours(0, 0, 0, 0);

      // FILTRO PER STATO
      if (filterExecutionStatus.length > 0) {
        if (!(filterExecutionStatus.indexOf(task.executionStatus) > -1)) {
          statusFilter = false;
        }
      }

      // FILTRO PER DATA
      if (!task.timeStamp) {
        dateFilter = true;
      } else if (filterDate) {
        const fd = new Date(filterDate);
        fd.setHours(0, 0, 0, 0);
        dateFilter = fd.getTime() === taskDate.getTime();
      } else {
        if (filterToYesterday) {
          hasFilter = true;
          yesterdayFilter = taskDate.getTime() <= yesterday.getTime();
        }
        if (filterToday) {
          hasFilter = true;
          todayFilter = taskDate.getTime() === today.getTime();
        }
        if (filterFromTomorrow) {
          hasFilter = true;
          tomorrowFilter = taskDate.getTime() >= tomorrow.getTime();
        }
        dateFilter = hasFilter
          ? yesterdayFilter || todayFilter || tomorrowFilter
          : true;
      }

      // FILTRO PER RISORSE
      if (this.props.filterResource) {
        resourceFilter = task.resourceId === this.props.filterResource;
      } else if (this.props.searchedResources) {
        let found = false;
        let found2 = false;

        this.props.searchedResources.forEach(resource => {
          if (resource.id === task.resourceId) {
            found = true;
          }
        });

        if (this.props.visibleResources) {
          this.props.visibleResources.forEach(resource => {
            if (resource.id === task.resourceId) {
              found2 = true;
            }
          });
        } else {
          found2 = true;
        }

        resourceFilter = found && found2;
      }

      // FILTRO RICERCA LIBERA
      if (freeSearch) {
        const lowerCase = freeSearch.toLowerCase();

        freeSearchFilter =
          (task.code && task.code.toLowerCase().includes(lowerCase)) ||
          (task.description &&
            task.description.toLowerCase().includes(lowerCase)) ||
          (task.poiAddress &&
            task.poiAddress.toLowerCase().includes(lowerCase)) ||
          (task.partyName &&
            task.partyName.toLowerCase().includes(lowerCase)) ||
          (task.resourceName &&
            task.resourceName.toLowerCase().includes(lowerCase));
      }

      if (dateFilter && resourceFilter && statusFilter && freeSearchFilter) {
        filteredTaskIds.push(task.id);
      }
    });
    this.setState({ filteredTasks: filteredTaskIds });
    this.props.shareTaskListFiltered(filteredTaskIds);
  };
  //_______FINE FILTRI_________//

  orderTasks = () => {
    const ordered = stableSort(
      this.state.allTasks,
      getSorting(this.state.order, this.state.orderBy)
    );
    this.setState({ allTasks: ordered });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy }, () => this.orderTasks());
  };

  handleAddClick = () => {
    this.props.toggleTaskForm({
      taskFormOpen: true,
      detailDataPanelOpen: false,
      managedTaskId: null
    });
  };

  toggleDetailPanel = () => {
    this.props.toggleTaskForm({
      detailDataPanelOpen: !this.props.detailDataPanelOpen
    });
  };

  handleCloseTaskForm = () => {
    this.props.toggleTaskForm({
      taskFormOpen: false,
      managedTaskId: null
    });
  };

  handleClickDetail = (event, id) => {
    this.props.toggleTaskForm({
      managedTaskId: id,
      taskFormOpen: true,
      detailDataPanelOpen: true
    });
  };

  toggleFilter = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  getWhenDescription = task => {
    if (!task.timeStamp) {
      return "";
    }

    moment.locale(getMomementLocale());
    const dateString = task.timeStamp
      ? moment.unix(task.timeStamp).format("L")
      : "";

    const hourString = task.timeStamp
      ? moment.unix(task.timeStamp).format("HH:mm")
      : "";

    let duration = "";
    if (task.beginning && task.ending) {
      duration = moment
        .duration(task.ending.seconds - task.beginning.seconds, "seconds")
        .humanize();
    }

    return {
      date: dateString,
      hour: hourString,
      duration: duration
    };
  };

  getWhenDescrForTable = task => {
    const descrObj = this.getWhenDescription(task);
    if (!descrObj) {
      return "";
    }

    return (
      <div>
        {descrObj.date}
        <br /> {descrObj.hour} ({descrObj.duration})
      </div>
    );
  };

  getWhenDescrForCard = task => {
    const descrObj = this.getWhenDescription(task);
    if (!descrObj) {
      return "";
    }

    return (
      <span>
        {descrObj.date}&nbsp;&nbsp;&nbsp;{descrObj.hour}&nbsp;(
        {descrObj.duration})
      </span>
    );
  };

  getExecutionStatus = task => {
    return (
      <Typography variant="caption" style={{ color: getTaskColor(task) }}>
        {stringTranslate("tasks", task.executionStatus)}
      </Typography>
    );
  };

  getWhereDescription = task => {
    if (!task.partyName && !task.poiAddress) {
      return "";
    }

    return (
      <React.Fragment>
        {task.partyName}
        <br />
        {task.poiAddress}
      </React.Fragment>
    );
  };

  getDistanceDescription = task => {
    if (!task.distance) {
      return "";
    }

    const distanceString = this.getDistanceValue(task) + " km";
    const etaString = moment.duration(task.eta, "seconds").humanize();

    return (
      <div>
        {distanceString}
        <br />
        {etaString}
      </div>
    );
  };

  getDelay = task => {
    //non segnalo ritardo se è uno stato "concluso"
    if (getEndedExecutionsStatuses().includes(task.executionStatus)) {
      return "";
    }

    const startingTimestamp = task.timeStamp;
    if (!startingTimestamp) {
      return "";
    }

    let eta = parseInt(task.eta);
    if (isNaN(eta)) {
      eta = 0;
    }

    const nowTimestamp = moment().unix();
    //maggiore di 30 minuti
    if (nowTimestamp + eta > startingTimestamp + 30 * 60) {
      return ["delay", "strong"];
      //tra 15 e 30 minuti
    } else if (nowTimestamp + eta > startingTimestamp + 15 * 60) {
      return ["delay", "small"];
    } else {
      return "";
    }
  };

  getDistanceValue = task => {
    let meterDistance = parseInt(task.distance);
    if (isNaN(meterDistance)) {
      meterDistance = 0;
    }
    if (meterDistance >= 1000) {
      return Math.round(meterDistance / 100) / 10;
    } else {
      return Math.round(meterDistance * 1e3) / 1e6;
    }
  };

  getTimestamp = task => {
    if (!task.beginning && !task.ending) {
      return null;
    }

    return task.beginning ? task.beginning.seconds : task.ending.seconds;
  };

  randomCode = length => {
    var result = "";
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz";
    for (var i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  };

  updateTaskOrder = (task, newOrder) => {
    return firebase
      .firestore()
      .collection(getFirestoreCollection() + "/tasks")
      .doc(task.id)
      .update({ executionOrder: newOrder });
  };

  onDragEnd = async result => {
    const { destination, source, draggableId } = result;
    // Drop fuori dall'area dove è possibile droppare un elemento
    if (!destination) {
      return;
    }
    // Drop nella stessa posizione di partenza
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    this.setState({ loading: true });
    const promises = [];

    let tasks = this.state.allTasks;
    const removed = tasks.splice(source.index, 1);
    tasks.splice(destination.index, 0, removed[0]);
    const resourceId = removed[0].resourceId;
    let i = 0;

    if (this.state.order === "asc") {
      tasks.forEach(t => {
        if (t.resourceId === resourceId && t.executionOrder) {
          if (i === 0 || i > t.executionOrder) {
            i = t.executionOrder;
          }
        }
      });
      tasks.forEach(t => {
        if (t.resourceId === resourceId && t.executionOrder) {
          promises.push(this.updateTaskOrder(t, i));
          i++;
        }
      });
    } else {
      tasks.forEach(t => {
        if (t.resourceId === resourceId && t.executionOrder) {
          if (i === 0 || i < t.executionOrder) {
            i = t.executionOrder;
          }
        }
      });
      tasks.forEach(t => {
        if (t.resourceId === resourceId && t.executionOrder) {
          promises.push(this.updateTaskOrder(t, i));
          i--;
        }
      });
    }

    Promise.all(promises).then(result => {
      this.setState({ loading: false });
    });
  };

  render() {
    const { classes, resources } = this.props;
    const data = this.state.allTasks;

    const toAssignTaskCount = this.props.toAssignTasks
      ? this.props.toAssignTasks.length
      : 0;
    const isToShowToAssingTaskBar = toAssignTaskCount !== 0;

    if (!data || !resources) {
      return <CircularProgress />;
    }

    return (
      <React.Fragment>
        <Hidden xsDown>
          <ToAssignTaskBar clickHandler={this.handleClickDetail} />
        </Hidden>
        <Hidden smUp>
          {isToShowToAssingTaskBar && (
            <ExpansionPanel className={classes.toAssignTaskPanel}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.toAssignTaskPanelSummary}
                classes={{
                  root: classes.toAssignTaskPanelSummary,
                  content: classes.toAssignTaskPanelSummaryContent
                }}
              >
                <Badge color="secondary" badgeContent={toAssignTaskCount}>
                  {stringTranslate("tasks", "toAssignTask")}
                </Badge>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <ToAssignTaskBar clickHandler={this.handleClickDetail} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
        </Hidden>
        <EnhancedTableToolbar
          checkboxChangeHandler={this.checkboxHandler}
          filterToYesterday={this.state.filterToYesterday}
          filterToday={this.state.filterToday}
          filterFromTomorrow={this.state.filterFromTomorrow}
          resources={resources}
          filterExecutionStatus={this.state.filterExecutionStatus}
          statusChangeHandler={this.handleStatusChange}
          addClickHandler={this.handleAddClick}
          freeSearch={this.state.freeSearch}
          handleFreeSearch={this.handleFreeSearch}
          filterDate={this.state.filterDate}
          handleFilterDate={this.handleFilterDate}
          toggleFilter={this.toggleFilter}
          showFilters={this.state.showFilters}
        />

        <TaskDetail
          open={this.props.taskFormOpen}
          onClose={this.handleCloseTaskForm}
          taskId={this.props.managedTaskId}
          detailDataPanelOpen={this.props.detailDataPanelOpen}
          detailDataPanelToggler={this.toggleDetailPanel}
          allTasks={this.state.allTasks}
        />

        <Hidden xsDown>
          <div className={classes.tableWrapper}>
            <TableHeader
              order={this.state.order}
              orderBy={this.state.orderBy}
              createSortHandler={this.handleRequestSort}
              account={this.props.account}
            />
            <LoadingModal
              text={stringTranslate("tasks", "loading")}
              isOpen={this.state.loading}
            />
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="task-list-table">
                {(provided, snapshot) => (
                  <div
                    style={{
                      flex: 1,
                      overflowY: "scroll"
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {data.map((n, index) => {
                      const found = this.state.filteredTasks.find(id => {
                        return id === n.id;
                      });
                      if (found === undefined) {
                        return null;
                      }

                      return (
                        <TaskListItem
                          key={n.id}
                          task={n}
                          index={index}
                          account={this.props.account}
                          handleClick={event =>
                            this.handleClickDetail(event, n.id)
                          }
                          getWhenDescription={this.getWhenDescription}
                          getDistance={this.getDistanceValue}
                          getDelay={this.getDelay}
                          orderBy={this.state.orderBy}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Hidden>
        <Hidden smUp>
          {data.map(task => (
            <TaskCard
              key={task.id}
              task={task}
              delayClasses={this.getDelay(task)}
              handleClickDetail={this.handleClickDetail}
              when={this.getWhenDescrForCard(task)}
              showWhere={checkAccountPermission(
                this.props.account,
                "taskList",
                "where"
              )}
              where={this.getWhereDescription(task)}
              distance={this.getDistanceDescription(task)}
            />
          ))}
        </Hidden>
      </React.Fragment>
    );
  }
}

MainActivityTaskList.propTypes = {
  classes: PropTypes.object.isRequired,
  firestore: PropTypes.object.isRequired,
  resources: PropTypes.object,
  contacts: PropTypes.object,
  pois: PropTypes.object,
  parties: PropTypes.object
};

export default compose(
  firestoreConnect(),
  connect(
    state => ({
      filterResource: state.mainActivityPage.resourceList.selectedResource,
      searchedResources: state.mainActivityPage.resourceList.searchedResources,
      visibleResources: state.mainActivityPage.activityMap.visibleResources,
      toAssignTasks: state.firestore.ordered.toAssignTasks,
      parties: state.firestore.data.parties,
      resources: state.firestore.data.resources,
      pois: state.firestore.data.pois,
      contacts: state.firestore.data.contacts,
      user: state.auth.user,
      account: state.firestore.data.account,

      taskFormOpen: state.mainActivityPage.task.taskFormOpen,
      detailDataPanelOpen: state.mainActivityPage.task.detailDataPanelOpen,
      managedTaskId: state.mainActivityPage.task.managedTaskId
    }),
    dispatch => ({
      toggleTaskForm: options => dispatch(toggleTaskForm(options)),
      shareTaskListFiltered: filtered =>
        dispatch(shareTaskListFiltered(filtered))
    })
  ),
  withStyles(styles)
)(MainActivityTaskList);
