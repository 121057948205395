import React from "react";
import { Redirect } from "react-router-dom";
import { FuseUtils } from "@fuse/index";
import { MainActivityConfig } from "main/content/mainActivity/MainActivityConfig";
import { UsersConfig } from "main/content/users/UsersConfig";
import { AccountConfig } from "main/content/account/AccountConfig";
import { ResourcesConfig } from "main/content/resources/ResourcesConfig";
import { CustomersConfig } from "main/content/customers/CustomersConfig";
import { ReportsConfig } from "main/content/reports/ReportsConfig";
import { ModulesConfig } from "main/content/modules/ModulesConfig";
import { LoginConfig } from "main/content/login/LoginConfig";
import { RegisterConfig } from "main/content/register/RegisterConfig";
import { LogoutConfig } from "main/content/logout/LogoutConfig";
import { pagesConfigs } from "main/content/pages/pagesConfigs";
import TimeTrackingConfig from "main/content/timeTracking/TimeTrackingConfig";

const routeConfigs = [
  ...pagesConfigs,
  LoginConfig,
  RegisterConfig,
  LogoutConfig,
  UsersConfig,
  ResourcesConfig,
  ModulesConfig,
  CustomersConfig,
  MainActivityConfig,
  ReportsConfig,
  AccountConfig,
  TimeTrackingConfig
];

export const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/mainActivity" />
  },
  {
    component: () => <Redirect to="/pages/errors/error-404" />
  }
];
