import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import Forum from "@material-ui/icons/Forum";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import CreditCard from "@material-ui/icons/CreditCard";
import { Typography, Button, Tooltip } from "@material-ui/core";
import { stringTranslate } from "languages/OMTranslate";
import moment from "moment";

const styles = theme => ({
  notificationButton: {
    textTransform: "none",
    height: "50px",
    maxHeight: "50px",
    minHeight: "50px",
    minWidth: "100%",
    paddingTop: "0px",
    paddingBottom: "0px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "5px",
    marginBottom: "5px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  iconContainer: {
    marginRight: 10
  },
  textContainer: {
    display: "flex",
    flex: 4,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  timeContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  noticeText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "200px"
  }
});

class NotificationsListItem extends Component {
  constructor(props) {
    super(props);
    this.interval = null;
    this.state = {
      fromNow: moment.unix(this.props.date.seconds).fromNow(true)
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.updateTime(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateTime = () => {
    this.setState({
      fromNow: moment.unix(this.props.date.seconds).fromNow(true)
    });
  };

  getIcon = type => {
    switch (type) {
      case "CHAT":
        return <Forum color="primary" />;
      case "TASK":
        return <AssignmentTurnedIn color="primary" />;
      case "PAYMENTS":
        return <CreditCard color="primary" />;
      default:
        return null;
    }
  };

  getBody = () => {
    if (this.props.type === "PAYMENTS") {
      return (
        stringTranslate("account", "missingPaymentMethodNotificationBody1") +
        this.props.body +
        stringTranslate("account", "missingPaymentMethodNotificationBody2")
      );
    }
    return this.props.body;
  };

  getTitle = () => {
    if (this.props.type === "PAYMENTS") {
      return stringTranslate("account", "missingPaymentMethodDialogTitle");
    }
    return this.props.title;
  };

  render() {
    const { classes } = this.props;
    const icon = this.getIcon(this.props.type);
    const body = this.getBody();
    const title = this.getTitle();

    return (
      <Button
        centerRipple={true}
        className={classes.notificationButton}
        onClick={() => this.props.onClick(this.props)}
      >
        <div className={classes.iconContainer}>{icon}</div>
        <div className={classes.textContainer}>
          <Typography variant="body1">{title}</Typography>
          <Tooltip title={body}>
            <Typography className={classes.noticeText} variant="body1">
              {body}
            </Typography>
          </Tooltip>
        </div>
        <div className={classes.timeContainer}>
          <Typography variant="caption">{this.state.fromNow}</Typography>
        </div>
      </Button>
    );
  }
}

export default withStyles(styles, { withTheme: true })(NotificationsListItem);
