export const modules = {
  // Table
  edit: "Modifica Form",
  task: "Attività",
  party: "Cliente",
  createdAt: "Creato il",
  // Builder
  newModule: "Nuovo modulo",
  modifyModule: "Modifica modulo",
  customFormName: "Nome del form",
  fieldLabel: "Nome campo",
  fieldHelp: "Testo aiuto compilazione (Opzionale)",
  fieldRequired: "Obbligatorio",
  fieldOption: "Opzione",
  addFieldOption: "Aggiungi opzione",
  add: "Aggiungi",
  save: "Salva",
  copy: "Copia",
  preview: "Anteprima",
  delete: "Cancella",
  undo: "Annulla",
  fieldLabelRequired: "Inserisci il nome del campo",
  fieldOptionLabelRequired: "Inserisci il nome dell'opzione",
  // Labels tabella
  tableTextLabels: {
    body: {
      noMatch: "Nessun modulo trovato",
      toolTip: "Ordina"
    },
    toolbar: {
      search: "Cerca",
      viewColumns: "Visualizza colonne",
      filterTable: "Filtra tabella"
    },
    filter: {
      all: "Tutti",
      title: "FILTRI",
      reset: "RIPRISTINA"
    },
    viewColumns: {
      title: "Visualizza colonne",
      titleAria: "Visualizza/Nascondi colonne tabella"
    }
  },
  fieldTypes: {
    text: "Testo",
    area: "Area di testo",
    radio: "Scelta singola",
    check: "Scelta multipla",
    dropdown: "Scelta singola - Lista",
    date: "Data",
    time: "Ora"
  },
  customFormSaved: "Form salvato",
  customFormCopied: "Form copiato",
  moduleSaved: "Modulo salvato",
  errorSaveCustomFrom: "Errore durante il salvataggio del form",
  errorDeleteCustomFrom: "Errore durante la cancellazione del form",
  errorSaveModule: "Errore durante il salvataggio del modulo",
  errorDeleteModule: "Errore durante la cancellazione del modulo",
  errorInvalidFields: "Ci sono uno o più campi non validi",
  errorCustomFormNameRequired: "Inserisci il nome del form",
  errorCannotChangeTypeFieldInUse:
    "Non è possibile modificare il tipo di un campo in uso",
  errorCannotDeleteCustomFormInUse: "Non è possibile cancellare un form in uso",
  // Module
  errorShowModule: "Impossibile visualizzare questo modulo",
  errorCopyCustomFrom: "Errore durante la copia del form",
  copyOf: "Copia di",
  fieldRemoved: "Campo rimosso"
};
