import React from "react";
import OMSearchAddBar from "./OMSearchAddBar";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  appbar: {
    order: 2,
    backgroundColor: theme.palette.primary.white
  },
  toolbar: {
    maxWidth: "30%",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      maxWidth: "100%"
    }
  }
});

const OMTabSearchAddBar = props => <OMSearchAddBar {...props} withAdd />;

export default withStyles(styles, { withTheme: true })(OMTabSearchAddBar);
