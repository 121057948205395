import firebase, { getFirestoreCollection } from "../../../firestoreAccount";
export const ASSIGN_TASK = "[TASK] ASSIGN_TASK";

export const assignTaskToResource = (taskId, resourceId) => {
  const db = firebase.firestore();
  const collection = getFirestoreCollection() + "/tasks";

  db.collection(collection)
    .doc(taskId)
    .update({ resourceId });

  return {
    type: ASSIGN_TASK,
    taskId,
    resourceId
  };
};

export const SET_DND_CONFIG = "[TASK] SET_DND_CONFIG";

export const setSchedulerDndConfig = config => {
  return {
    type: SET_DND_CONFIG,
    dndConfig: config
  };
};
