export const resources = {
  Resource: "Risorsa",
  Name: "Nome",
  Phone: "Telefono",
  Phone_validation_error: "Inserire un numero di telefono valido",
  Categories: "Categorie",
  "Send invitation": "Manda invito",
  Invite: "Invita",
  Registration: "Registrazione",
  Not_localized: "Risorse senza posizione",
  InviteMsg:
    "E' stata aggiunta una nuova risorsa. Vuoi mandarle un'invito per unirsi ad OverMover installando la WorkerApp sul suo smartphone?",
  InviteSuccess: "Risorsa invitata con successo",

  //registrazione
  INVITED: "Invitata",
  NEW: "Da invitare",
  VERIFIED: "Verificata",
  TOSENDINVITE: "Invito in corso",

  //attività
  FREE: "Libero",
  ENGAGED: "Occupato",

  //stato
  STILL: "Fermo",
  MOVING: "In movimento",
  PRIVACY: "Non tracciato",
  OFFLINE: "Offline"
};
