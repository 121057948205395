export const toolbar = {
  "Change Language": "Cambia lingua",
  Logout: "Esci",
  Home: "Home",
  Resources: "Risorse",
  Customers: "Clienti",
  Documents: "Documenti",
  Reports: "Report",
  Modules: "Moduli",
  Settings: "Impostazioni",
  Users: "Utenti",
  POIs: "POI",
  Account: "Account",
  resourceRoutes: "Percorsi",
  "Help request": "Richesta assistenza",
  Message: "Messaggio",
  onSending: "Invio del messaggio all' helpdesk Overmover...",
  onSent: "Il tuo messaggio è registrato correttamente nel nostro helpdesk.",
  emptyNotifications: "Nessuna notifica",
  notifications: "Notifiche",
  "Change language": "Cambia lingua",
  help: "Richiedi assistenza",
  startTour: "Tour guidato",
  "Do you need assistance? Send us a quick message and we will answer immediately.":
    "Hai bisogno di assistenza? mandaci un messaggio e ti contatteremo immediatamente.",
  Menu: "Menu",
  clearAll: "Segna come lette",
  VerifyTimeTracking: "Timbrature da verificare",
  TimeTracking: "Timbrature",
  TaskReport: "Attivita'",
  customerList: "Elenco clienti",
  importCustomers: "Importa da file"
};
