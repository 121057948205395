import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { FormControlLabel, Switch } from "@material-ui/core";

import { stringTranslate } from "languages/OMTranslate";

const styles = () => ({
  content: {
    marginTop: "16px",
    marginBottom: "8px"
  }
});

/**
 * Input di testo della lebel di un campo del custom form
 * In questo campo non sono gestiti gli errori in quanto valore
 * ha già un default
 */
const RequireCheckBox = ({ classes, field, onChange }) => (
  <div className={classes.content}>
    <FormControlLabel
      control={
        <Switch
          checked={field.required}
          onChange={e => onChange(field, "required")(e.target.checked)}
          color="secondary"
        />
      }
      label={stringTranslate("modules", "fieldRequired")}
    />
  </div>
);

RequireCheckBox.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

RequireCheckBox.defaultProps = {
  errors: {}
};

export default withStyles(styles, { withTheme: true })(RequireCheckBox);
