import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate } from "languages/OMTranslate";
import classNames from "classnames";
import { Email, CheckCircle, Block, HelpOutline } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { CardContent } from "@material-ui/core";

const styles = theme => ({
  root: {},
  icon: { fontSize: "18px" }
});

class UserCardContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, user } = this.props;
    return (
      <CardContent className="pl-12 pr-12">
        <Typography className="flex flex-row" variant="caption">
          <Email className={classNames(classes.icon, "mr-12")} />
          {user.data.email}
        </Typography>
        {user.status === "ENABLED" && (
          <Typography className="flex flex-row capitalize" variant="caption">
            <CheckCircle className={classNames(classes.icon, "mr-12")} />
            {stringTranslate("general", user.status)}
          </Typography>
        )}
        {user.status === "DISABLED" && (
          <Typography className="flex flex-row capitalize" variant="caption">
            <Block className={classNames(classes.icon, "mr-12")} />
            {stringTranslate("general", user.status)}
          </Typography>
        )}
        {user.status === "WAITING_VALIDATION" && (
          <Typography className="flex flex-row capitalize" variant="caption">
            <HelpOutline className={classNames(classes.icon, "mr-12")} />
            <span>{stringTranslate("general", user.status)}</span>
          </Typography>
        )}
      </CardContent>
    );
  }
}

UserCardContent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(UserCardContent);
