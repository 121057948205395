import * as Actions from "../../actions/mainActivityPage/index";

const initialState = {
  taskId: null,
  resourceId: null,
  dndConfig: null
};

const taskAssign = function(state = initialState, action) {
  switch (action.type) {
    case Actions.ASSIGN_TASK:
      return { ...state, taskId: action.taskId, resourceId: action.resourceId };
    case Actions.SET_DND_CONFIG:
      return { ...state, dndConfig: action.dndConfig };
    default:
      return state;
  }
};

export default taskAssign;
