export const ADD_ROOM = "[CHAT] ADD_ROOM";
export const EMPTY_ROOM_LIST = "[CHAT] EMPTY_ROOM_LIST";
export const SET_ACTIVE_CHAT = "[CHAT] SET_ACTIVE_CHAT";
export const SET_USED_CHAT = "[CHAT] SET_USED_CHAT";

export function addRoom(rooms) {
  return {
    type: ADD_ROOM,
    rooms
  };
}

export function emptyRoomList() {
  return {
    type: EMPTY_ROOM_LIST
  };
}

export function setActiveChat(room) {
  return {
    type: SET_ACTIVE_CHAT,
    room
  };
}

export function usedChat(value) {
  return {
    type: SET_USED_CHAT,
    value: value
  };
}
