import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { MenuItem, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    borderBottom: "1px none " + theme.palette.primary.panelBorder,
    display: "flex"
  },
  listItemText: {
    paddingRight: 0,
    flex: "1 1 auto",
    fontSize: theme.text.medium
  }
});

class HamburgerMenuItem extends Component {
  render() {
    const { classes, children } = this.props;

    return (
      <MenuItem
        component={Link}
        to={this.props.to}
        onClick={this.props.onClick}
        className={classes.root}
      >
        {this.props.label ? (
          <Typography variant="body1" className={classes.listItemText}>
            {this.props.label}
          </Typography>
        ) : (
          ""
        )}
        {children ? children : ""}
      </MenuItem>
    );
  }
}
export default withStyles(styles, { withTheme: true })(HamburgerMenuItem);
