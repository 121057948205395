import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { Document, Page, pdfjs } from "react-pdf";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

import { stringTranslate } from "languages/OMTranslate";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;
Modal.setAppElement("#root");

const modalContentActionsHeight = "50px";

const styles = theme => ({
  container: {
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    flex: 1,
    height: "100%"
  },
  contentView: {
    height: `calc(100vh - ${modalContentActionsHeight})`,
    width: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  message: {
    fontSize: theme.text.large,
    color: "#fff",
    lineHeight: "1.75"
  },
  modalOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0, 0.85)",
    zIndex: 2000
  },
  modalContent: {
    position: "relative",
    height: "100vh",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 0,
    background: "none",
    border: "none",
    padding: 0,
    color: "#000",
    overflow: "none"
  },
  modalContentActions: {
    height: modalContentActionsHeight,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px"
  },
  contentPdf: {
    height: `calc(100vh - ${modalContentActionsHeight})`,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    overflow: "auto"
  },
  contentPdfPages: {
    width: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  spacer: {
    width: "100%"
  }
});

class AttachmentViewer extends React.Component {
  state = {
    numPages: null
  };

  handleOnDocumentLoadSuccess = document => {
    const { numPages } = document;
    this.setState({
      numPages
    });
  };

  /**
   * Ritorna un component per visualizzare uno spinner
   * Nota sono stati utilizzati gli stessi stili del component LightBox
   * per rendere la schermata più uniforme
   */
  getLoadingView = () => {
    const { classes } = this.props;
    return (
      <div className={classes.contentView}>
        <div className="ril-loading-circle ril__loadingCircle ril__loadingContainer__icon">
          {[...new Array(12)].map((_, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="ril-loading-circle-point ril__loadingCirclePoint"
            />
          ))}
        </div>
      </div>
    );
  };

  /**
   * Ritorna un component per visualizzare un messaggio
   * @param {String} message messaggio
   */
  getMessageView = message => {
    const { classes } = this.props;
    return (
      <div
        className={classes.contentView}
        onClick={e => e.stopPropagation()}
        role="presentation"
      >
        <Typography className={classes.message}>{message}</Typography>
      </div>
    );
  };

  contentToRender = () => {
    const { numPages } = this.state;
    const { classes, attachment, onClose } = this.props;

    if (
      attachment.type === "IMAGE" ||
      attachment.type === "SIGNATURE" ||
      attachment.type === "BARCODE"
    ) {
      return (
        <Lightbox
          mainSrc={attachment.url}
          onCloseRequest={onClose}
          imageLoadErrorMessage={this.getMessageView(
            stringTranslate("tasks", "errorLoadImage")
          )}
        />
      );
    }
    return (
      <Modal
        isOpen
        onRequestClose={onClose}
        className={classes.modalContent}
        overlayClassName={classes.modalOverlay}
      >
        <div onClick={onClose} role="presentation">
          <div className={classes.modalContentActions}>
            <div className={classes.spacer} />
            <button
              type="button"
              onClick={onClose}
              aria-label="Close lightbox"
              // Stesse classi del pulsante chiusi presente nel component LightBox
              className="ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton"
            />
          </div>
          <div className={classes.contentPdf}>
            <Document
              file={attachment.url}
              onLoadSuccess={this.handleOnDocumentLoadSuccess}
              loading={this.getLoadingView()}
              error={this.getMessageView(
                stringTranslate("tasks", "errorLoadPdf")
              )}
            >
              <div
                onClick={e => e.stopPropagation()}
                className={classes.contentPdfPages}
                role="presentation"
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    height={window.innerHeight}
                  />
                ))}
              </div>
            </Document>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    return this.contentToRender();
  }
}

AttachmentViewer.propTypes = {
  attachment: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    createdAt: PropTypes.shape({
      seconds: PropTypes.number.isRequired
    }).isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired // Oggetto creato da withStyles
};

export default withStyles(styles)(AttachmentViewer);
