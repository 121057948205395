import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from "@material-ui/core";

const styles = theme => ({
  formControl: {},
  controlLabel: {
    fontSize: "11px",
    color: theme.palette.primary.dark
  },
  label: {
    fontSize: "11px"
  }
});

/**
 * Nota: il valore di questo tipo di campo è una lista di valori
 * in quanto possono esserci una seleziona multipla
 */

const Check = ({ classes, field, value, onChange, error }) => (
  <FormControl
    className={classes.formControl}
    error={error !== undefined && error !== ""}
    required={field.required}
    component="fieldset"
    fullWidth
    margin="normal"
  >
    <FormLabel component="legend" className={classes.label}>
      {field.label}
    </FormLabel>
    <FormGroup>
      {field.options.map(option => (
        <FormControlLabel
          key={option.name}
          className={classes.controlLabel}
          control={
            <Checkbox
              checked={value.find(v => v === option.name) !== undefined}
              onChange={e =>
                onChange(field)(
                  // Se viene selezionato viene aggiunta l'opzione alla lista atrimenti viene rimossa
                  // vengono rimossi i possibili vecchi valori (nel caso in cui si stata selezionata un'opzione successivamente rimossa)
                  e.target.checked
                    ? [
                        ...value.filter(
                          v =>
                            field.options.find(o => o.name === v) !== undefined
                        ),
                        option.name
                      ]
                    : value.filter(v => v !== option.name)
                )
              }
              value={option.name}
            />
          }
          label={option.label}
        />
      ))}
    </FormGroup>
    <FormHelperText>{`${field.help}${
      error && error !== "" ? `${field.help ? " - " : ""}${error}` : ""
    }`}</FormHelperText>
  </FormControl>
);

Check.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    position: PropTypes.number.isRequired,
    help: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string
      })
    )
  }).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

Check.defaultProps = {
  value: [],
  error: undefined
};

export default withStyles(styles, { withTheme: true })(Check);
