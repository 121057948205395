import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import Notifications from "@material-ui/icons/Notifications";
import firebase, { getFirestoreCollection } from "firestoreAccount";
import { Button, Popover, Typography, Tooltip } from "@material-ui/core";
import { FuseAnimate } from "@fuse";
import NotificationsList from "./NotificationsList";
import { connect } from "react-redux";
import { compose } from "redux";
import { setActiveChat, chatToggle } from "store/actions/mainActivityPage";
import { stringTranslate } from "languages/OMTranslate";
import NotificationsCounter from "./NotificationsCounter";
import notificationClickHandler from "./notificationClickHandler";
import { withRouter } from "react-router-dom";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    maxHeight: "300px",
    overflowY: "auto",
    width: "300px"
  }
});

class NotificationsToolbarButton extends Component {
  constructor(props) {
    super(props);
    this.state = { notificationsMenu: null };
  }

  notificationsMenuClick = event => {
    this.setState({ notificationsMenu: event.currentTarget });
  };

  notificationsMenuClose = () => {
    this.setState({ notificationsMenu: null });
  };

  handleClick = event => {
    notificationClickHandler({
      notificationId: event.id,
      type: event.type,
      sourceId: event.sourceId,
      ...this.props
    });
    this.notificationsMenuClose();
  };

  clearAllNotification = () => {
    const collection = getFirestoreCollection() + "/notifications";
    firebase
      .firestore()
      .collection(collection)
      .where("status", "==", "NEW")
      .get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          doc.ref.update({ status: "READ" });
        });
        this.notificationsMenuClose();
      })
      .catch(error => console.log(error));
  };

  renderEmptyMessage = () => {
    return (
      <div
        style={{
          marginRight: 15,
          marginTop: 10,
          marginLeft: 15,
          marginBottom: 10
        }}
      >
        <Typography variant="body1">
          {stringTranslate("toolbar", "emptyNotifications")}
        </Typography>
      </div>
    );
  };

  render() {
    const { notificationsMenu } = this.state;

    return (
      <React.Fragment>
        <FuseAnimate delay={300}>
          <Tooltip title={stringTranslate("toolbar", "notifications")}>
            <Button className="h-50" onClick={this.notificationsMenuClick}>
              <NotificationsCounter
                endQueryCallback={this.setNotifications}
                snapshotCallback={this.showNotification}
              >
                <Notifications style={{ color: "#33475b" }} />
              </NotificationsCounter>
            </Button>
          </Tooltip>
        </FuseAnimate>
        <Popover
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={this.notificationsMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          classes={{
            paper: "py-8"
          }}
        >
          <NotificationsList
            notifications={this.props.notifications}
            language={this.props.language}
            onClick={this.handleClick}
            clearAll={this.clearAllNotification}
          />
        </Popover>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  chatRooms: state.mainActivityPage.chat.chatRooms,
  chatVisibility: state.mainActivityPage.panelVisibility.chatVisibility,
  language: state.auth.user.data.userLanguage,
  notifications: state.mainActivityPage.notifications.notifications
});

const mapDispatchToProps = dispatch => ({
  setActiveChat: room => dispatch(setActiveChat(room)),
  chatToggle: () => dispatch(chatToggle())
});

export default withRouter(
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(withStyles(styles, { withTheme: true })(NotificationsToolbarButton))
);
