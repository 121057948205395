import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import firebase, { getFirestoreCollection } from "../../../firestoreAccount";
import { stringTranslate } from "languages/OMTranslate";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { firestoreConnect } from "react-redux-firebase";
import {
  withStyles,
  CircularProgress,
  InputLabel,
  Button,
  TextField,
  Modal,
  DialogTitle,
  Typography,
  IconButton
} from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import OMReactSelect from "main/content/common/OMReactSelect";
import Select from "react-select";
import DatePicker from "react-datepicker";
import CloseIcon from "@material-ui/icons/Close";
import AccessTime from "@material-ui/icons/AccessTime";

const styles = theme => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignSelf: "stretch",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: theme.panels.backgroundColor
  },
  spinner: {
    display: "flex",
    flex: 1,
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center"
  },
  tableContainer: {
    display: "flex",
    alignSelf: "stretch",
    flex: 1
  },
  formContainer: {
    backgroundColor: theme.panels.backgroundColor,
    width: "600px",
    height: "400px",
    position: "absolute",
    left: "50%",
    top: "50%",
    marginLeft: "-200px",
    marginTop: "-300px",
    borderRadius: "4px"
  },
  form: {
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "24px"
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.panels.backgroundColor
  },
  titleRow: {
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "row",
    padding: theme.measures.panelPadding
  },
  title: {
    display: "flex",
    flexDirection: "row",
    flex: "0 0 auto",
    borderBottom: theme.panels.border
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  },
  confirmButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const columns = [
  {
    name: "resource",
    label: stringTranslate("timeTracking", "resource"),
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "date",
    label: stringTranslate("timeTracking", "date"),
    options: {
      filter: true,
      sort: false
    }
  },
  {
    name: "start",
    label: stringTranslate("timeTracking", "start"),
    options: {
      filter: true,
      sort: false
    }
  },
  {
    name: "end",
    label: stringTranslate("timeTracking", "end"),
    options: {
      filter: true,
      sort: false
    }
  }
];

class TimeTrackingPage extends Component {
  state = {
    data: [],
    loading: false,
    openForm: false,
    selectedRow: null,
    // Campi della form
    resourceId: null,
    resourceName: null,
    date: null,
    startHour: null,
    endHour: null
  };

  muiDataTableOptions = () => {
    return {
      pagination: false,
      download: false,
      print: false,
      search: true,
      filter: false,
      viewColumns: false,
      selectableRows: false,
      responsive: "scroll",
      elevation: 0,
      onRowClick: (rowData, rowMeta) => {
        const selected = this.state.data[rowMeta.dataIndex];

        this.setState({
          openForm: true,
          selectedRow: selected[4],
          resourceId: selected[5],
          resourceName: selected[0],
          date: selected[6],
          startHour: selected[2],
          endHour: selected[3]
        });
      },
      textLabels: {
        body: {
          noMatch: stringTranslate("timeTracking", "emptyTable")
        },
        toolbar: {
          search: stringTranslate("timeTracking", "search")
        },
        selectedRows: {
          text: stringTranslate("timeTracking", "selectedRows"),
          delete: stringTranslate("timeTracking", "approve")
        }
      }
    };
  };

  componentDidMount() {
    firebase
      .firestore()
      .collection(getFirestoreCollection() + "/time_tracking")
      .where("status", "==", "WAITING")
      .orderBy("start", "asc")
      .onSnapshot(querySnapshot => {
        this.setState({ loading: true });
        var data = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            if (doc.data().resourceId in this.props.resources) {
              var resource = this.props.resources[doc.data().resourceId];
              var date = moment(doc.data().start.seconds * 1000).format(
                stringTranslate("timeTracking", "dateFormat")
              );
              var startTime = moment(doc.data().start.seconds * 1000).format(
                "HH:mm"
              );
              var endTime = doc.data().end
                ? moment(doc.data().end.seconds * 1000).format("HH:mm")
                : "";

              data.push([
                resource.name,
                date,
                startTime,
                endTime,
                doc.id,
                doc.data().resourceId,
                new Date(doc.data().start.seconds * 1000),
                doc.data().end
                  ? new Date(doc.data().end.seconds * 1000)
                  : new Date()
              ]);
            }
          });
        }
        this.setState({ data, loading: false });
      });
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          paper: {
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: "10px",
            width: "100%",
            height: "100%"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            fontSize: this.props.theme.text.small,
            padding: "4px 24px 4px 12px"
          }
        },
        MUIDataTableHeadCell: {
          root: {
            fontSize: this.props.theme.text.medium,
            padding: "4px 24px 4px 12px"
          }
        }
      }
    });

  setResource = object => {
    if (!object || !object.value || !object.label) {
      this.setState({ resourceId: null, resourceName: null });
    } else {
      this.setState({ resourceId: object.value, resourceName: object.label });
    }
  };

  splitTime = time => {
    var parts = time.split(":");
    return { h: parts[0], m: parts[1] };
  };

  save = () => {
    const start = new Date(this.state.date);
    var time = this.splitTime(this.state.startHour);
    start.setHours(time.h, time.m, 0);

    const end = new Date(this.state.date);
    time = this.splitTime(this.state.endHour);
    end.setHours(time.h, time.m, 0);

    firebase
      .firestore()
      .collection(getFirestoreCollection() + "/time_tracking")
      .doc(this.state.selectedRow)
      .update({
        resourceId: this.state.resourceId,
        start,
        end,
        status: "APPROVED"
      })
      .then(result => {
        this.setState({ openForm: false });
      })
      .catch(error => {
        console.log(error);
        this.setState({ openForm: false });
      });
  };

  render() {
    const { classes, resourceArray } = this.props;
    let resourcesOptions = [];
    if (resourceArray) {
      resourceArray.forEach(el => {
        resourcesOptions.push({
          value: el.id,
          label: el.name
        });
      });
    }

    return (
      <div className={classes.root}>
        <div className={classes.titleRow}>
          <div className={classes.title}>
            <AccessTime color="primary" />
            <Typography variant="h3" className="px-8 py-4" color="primary">
              {stringTranslate("toolbar", "VerifyTimeTracking")}
            </Typography>
          </div>
        </div>
        {this.loading ? (
          <div className={classes.spinner}>
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <React.Fragment>
            <div className={classes.tableContainer}>
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  data={this.state.data}
                  columns={columns}
                  options={this.muiDataTableOptions()}
                />
              </MuiThemeProvider>
            </div>
            <Modal
              open={this.state.openForm}
              onClose={() => this.setState({ openForm: false })}
            >
              <div className={classes.formContainer}>
                <div className={classes.form}>
                  <div className={classes.dialogTitle}>
                    <DialogTitle
                      id="simple-dialog-title"
                      disableTypography
                      style={{ paddingLeft: "0px" }}
                    >
                      <Typography variant="h3">
                        {stringTranslate("timeTracking", "title")}
                      </Typography>
                    </DialogTitle>
                    <IconButton
                      className={classes.closeButton}
                      onClick={() => this.setState({ openForm: false })}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={8}>
                      <OMReactSelect
                        value={
                          this.state.resourceId
                            ? {
                                value: this.state.resourceId,
                                label: this.state.resourceName
                              }
                            : null
                        }
                        onChange={this.setResource}
                        options={resourcesOptions}
                        label={stringTranslate("timeTracking", "resource")}
                        componentType={Select}
                        isClearable
                        InputProps={{
                          className: classes.inputText
                        }}
                        InputLabelProps={{
                          className: classes.inputTextLabel
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={4}>
                      <div className="flex flex-col overflow-hidden">
                        <InputLabel required className="form-input-label">
                          {stringTranslate("timeTracking", "date")}
                        </InputLabel>
                        <DatePicker
                          selected={this.state.date}
                          onChange={date => this.setState({ date })}
                          className="date-picker-input"
                          placeholderText={stringTranslate(
                            "timeTracking",
                            "date"
                          )}
                          dateFormat={stringTranslate(
                            "general",
                            "fsnDateFormat"
                          )}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={4}>
                      <div className="flex flex-col overflow-hidden">
                        <TextField
                          id="timeStart"
                          label={stringTranslate("timeTracking", "start")}
                          type="time"
                          defaultValue={this.state.startHour}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ step: 60 }}
                          onChange={event =>
                            this.setState({ startHour: event.target.value })
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className="flex flex-col overflow-hidden">
                        <TextField
                          id="timeEnd"
                          label={stringTranslate("timeTracking", "end")}
                          type="time"
                          defaultValue={this.state.endHour}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ step: 60 }}
                          onChange={event =>
                            this.setState({ endHour: event.target.value })
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div
                  style={{ bottom: "24px", left: "24px", position: "absolute" }}
                >
                  <Grid container spacing={24}>
                    <Grid item xs={12} sm={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="mt-12"
                        onClick={this.save}
                      >
                        {stringTranslate("timeTracking", "save")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Modal>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  resources: state.firestore.data.resources,
  resourceArray: state.firestore.ordered.resources
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => {
    return [
      {
        collection: "/resources",
        where: ["accountId", "==", localStorage.getItem("accountId")],
        storeAs: "resources",
        orderBy: "name"
      }
    ];
  }),
  withStyles(styles, { withTheme: true })
)(TimeTrackingPage);
