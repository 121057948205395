import React, { Component } from "react";
import NotificationsListItem from "./NotificationsListItem";
import { Typography } from "@material-ui/core";
import { stringTranslate } from "languages/OMTranslate";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  listContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    maxHeight: "300px",
    overflowY: "auto",
    width: "300px",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      width: "100%",
      minWidth: "300px"
    }
  },
  button: {
    fontSize: "12px"
  }
});

class NotificationsList extends Component {
  renderEmptyMessage = () => {
    return (
      <div
        style={{
          marginRight: 15,
          marginTop: 10,
          marginLeft: 15,
          marginBottom: 10
        }}
      >
        <Typography variant="body1">
          {stringTranslate("toolbar", "emptyNotifications")}
        </Typography>
      </div>
    );
  };

  render() {
    const { classes, notifications, language, onClick, clearAll } = this.props;

    return (
      <div className={classes.listContainer}>
        <Button className={classes.button} size="small" onClick={clearAll}>
          <Typography variant="caption">
            {stringTranslate("toolbar", "clearAll")}
          </Typography>
        </Button>
        {notifications && notifications.length >= 0
          ? notifications.map(notice => {
              return (
                <NotificationsListItem
                  {...notice}
                  key={notice.id}
                  onClick={onClick}
                  language={language}
                />
              );
            })
          : this.renderEmptyMessage()}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(NotificationsList);
