import * as Actions from "../../actions/customersPage/index";

const initialState = {
  selectedCustomer: false
};

const selectedCustomer = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SELECTED_CUSTOMER: {
      return {
        ...state,
        selectedCustomer: action.customer
      };
    }
    case Actions.RESET_ACTION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default selectedCustomer;
