import React, { Component } from "react";
import Formsy from "formsy-react";
import { TextFieldFormsy } from "@fuse";
import { withStyles, Button, InputAdornment, Icon } from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as Actions from "auth/store/actions";
import { Link, withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { stringTranslate } from "../../../../languages/OMTranslate";
import Grid from "@material-ui/core/Grid";
import firebase from "firebase/app";
import { FuseAnimate } from "@fuse";
import { Typography } from "@material-ui/core";
import classNames from "classnames";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

class LostPasswordTab extends Component {
  state = {
    canSubmit: false,
    showOk: false,
    showError: false
  };

  form = React.createRef();

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  onSubmit = model => {
    firebase.auth().useDeviceLanguage();
    firebase
      .auth()
      .sendPasswordResetEmail(model.username)
      .then(() => {
        this.setState({ showOk: true });
      })
      .catch(error => {
        this.setState({ showError: true });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user.role !== "guest") {
      const pathname =
        this.props.location.state && this.props.location.state.redirectUrl
          ? this.props.location.state.redirectUrl
          : "/";
      this.props.history.push({
        pathname
      });
    }
    return null;
  }

  resetForm = () => {
    this.setState({
      showOk: false,
      showError: false
    });
  };

  render() {
    const { classes } = this.props;
    const { canSubmit } = this.state;

    if (this.state.showOk) {
      return (
        <FuseAnimate animation="transition.slideUpIn" delay={300}>
          <Typography variant="body1" className={classNames("max-w-512 my-32")}>
            {stringTranslate(
              "general",
              "An email has been sent to your email address. Follow the instructions to complete the password recovery procedure"
            )}
          </Typography>
        </FuseAnimate>
      );
    }

    if (this.state.showError) {
      return (
        <FuseAnimate animation="transition.slideUpIn" delay={300}>
          <React.Fragment>
            <Typography
              variant="body1"
              className={classNames("max-w-512 my-32")}
            >
              {stringTranslate(
                "login",
                "Error in reset password request. User unknown."
              )}
            </Typography>
            <Link to="#" onClick={this.resetForm}>
              {stringTranslate("general", "Retry")}
            </Link>
          </React.Fragment>
        </FuseAnimate>
      );
    }

    return (
      <div className={classes.root}>
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          ref={form => (this.form = form)}
          className="flex flex-col justify-center w-full"
        >
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <TextFieldFormsy
                className="w-full"
                type="text"
                name="username"
                label={stringTranslate("login", "Email")}
                validations="isEmail"
                validationErrors={{
                  isEmail: stringTranslate(
                    "general",
                    "Please enter a valid email"
                  )
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className="text-20" color="action">
                        email
                      </Icon>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                aria-label={stringTranslate("login", "Send request")}
                disabled={!canSubmit}
                value="firebase"
                className="normal-case"
              >
                {stringTranslate("login", "Send request")}
              </Button>
            </Grid>
          </Grid>
        </Formsy>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLoginWithFireBase: Actions.submitLoginWithFireBase
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    login: auth.login,
    user: auth.user
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(LostPasswordTab)
  )
);
