import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import { withStyles } from "@material-ui/core/styles";

import { Button, IconButton, Card, CardContent, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import SelectType from "../components/SelectType";
import Handle from "../components/Handle";

import TextForm from "./forms/TextForm";
import AreForm from "./forms/AreaForm";
import DateForm from "./forms/DateForm";
import TimeForm from "./forms/TimeForm";
import ChecksForm from "./forms/ChecksForm";
import RadiosForm from "./forms/RadiosForm";
import DropdownsForm from "./forms/DropdownsForm";

import { FieldTypes } from "../ModulesConfig";

import { stringTranslate } from "languages/OMTranslate";

const getFormToRender = (field, errors, props) => {
  const {
    onFieldPropChange,
    onFieldPropOptionDelete,
    onFieldPropOptionChange,
    onFieldPropOptionAdd
  } = props;
  switch (field.type) {
    case FieldTypes.text:
      return (
        <TextForm field={field} errors={errors} onChange={onFieldPropChange} />
      );
    case FieldTypes.area:
      return (
        <AreForm field={field} errors={errors} onChange={onFieldPropChange} />
      );
    case FieldTypes.radio:
      return (
        <RadiosForm
          field={field}
          errors={errors}
          onChange={onFieldPropChange}
          onDeleteOption={onFieldPropOptionDelete}
          onChangeOption={onFieldPropOptionChange}
          onAddOption={onFieldPropOptionAdd}
        />
      );
    case FieldTypes.check:
      return (
        <ChecksForm
          field={field}
          errors={errors}
          onChange={onFieldPropChange}
          onDeleteOption={onFieldPropOptionDelete}
          onChangeOption={onFieldPropOptionChange}
          onAddOption={onFieldPropOptionAdd}
        />
      );
    case FieldTypes.dropdown:
      return (
        <DropdownsForm
          field={field}
          errors={errors}
          onChange={onFieldPropChange}
          onDeleteOption={onFieldPropOptionDelete}
          onChangeOption={onFieldPropOptionChange}
          onAddOption={onFieldPropOptionAdd}
        />
      );
    case FieldTypes.date:
      return (
        <DateForm field={field} errors={errors} onChange={onFieldPropChange} />
      );
    case FieldTypes.time:
      return (
        <TimeForm field={field} errors={errors} onChange={onFieldPropChange} />
      );
    default:
      return undefined;
  }
};

const styles = theme => ({
  container: {
    paddingBottom: "20px"
  },
  card: {},
  content: {
    display: "flex",
    padding: 0,
    "&:last-child": {
      padding: 0
    }
  },
  contentForm: {
    display: "flex",
    flex: 1
  },
  contentInputs: {
    padding: "0 20px",
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2
    }
  },
  contentTypes: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: 20,
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1
    }
  },
  contentActions: {
    justifyContent: "flex-end",
    display: "flex",
    paddingBottom: "10px",
    paddingRight: "10px",
    order: 3,
    [theme.breakpoints.down("sm")]: {
      order: 3
    }
  },
  buttonAction: {
    marginRight: "20px"
  },
  buttonIconText: {
    fontSize: "20px",
    marginRight: "10px"
  }
});

const Field = ({
  classes,
  field,
  errors,
  index,
  onAdd,
  onDelete,
  onFieldTypeChange,
  ...other
}) => (
  <Draggable draggableId={field.name} index={index}>
    {(provided, snapshot) => (
      <div
        {...provided.draggableProps}
        ref={provided.innerRef}
        className={classes.container}
      >
        <Card
          className={classes.card}
          // isDragging={snapshot.isDragging}
        >
          <CardContent className={classes.content}>
            <Handle {...provided.dragHandleProps} />
            <div className={classes.contentForm}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={6}
                  className={classes.contentInputs}
                >
                  {getFormToRender(field, errors, other)}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={6}
                  className={classes.contentTypes}
                >
                  <SelectType
                    types={Object.values(FieldTypes)}
                    selectedType={field.type}
                    onChange={type => onFieldTypeChange(field, type)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.contentActions}
                >
                  <Button
                    className={classes.buttonAction}
                    onClick={() => onAdd(field)}
                    color="secondary"
                  >
                    <AddIcon className={classes.buttonIconText} />
                    {stringTranslate("modules", "add")}
                  </Button>
                  <IconButton onClick={() => onDelete(field)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </div>
    )}
  </Draggable>
);

Field.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    position: PropTypes.number.isRequired,
    help: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string
      })
    )
  }).isRequired,
  errors: PropTypes.shape({}),
  onFieldTypeChange: PropTypes.func.isRequired,
  onFieldPropChange: PropTypes.func.isRequired,
  onFieldPropOptionDelete: PropTypes.func.isRequired,
  onFieldPropOptionChange: PropTypes.func.isRequired,
  onFieldPropOptionAdd: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

Field.defaultProps = {
  errors: {}
};

export default withStyles(styles, { withTheme: true })(Field);
