import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteDemoData } from "main/common/account/accountHelper";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

import {
  Button,
  Icon,
  ListItemIcon,
  Popover,
  MenuItem,
  Typography,
  Tooltip,
  Hidden
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { FuseAnimate } from "@fuse";
import * as authActions from "auth/store/actions";
import * as fuseActions from "store/actions/fuse";
import {
  getCurrentLanguageIcon,
  stringTranslate,
  getLanguageIcon,
  getLanguageLabel,
  getMomementLocale
} from "languages/OMTranslate";
import moment from "moment";
import NotificationsToolbarButton from "./NotificationsToolbarButton";
import HelpdeskModal from "./HelpdeskModal";
import LoadingModal from "../LoadingModal";
import HamburgerMenu from "./HamburgerMenu";
import OMConfirmationPopup from "main/content/common/OMConfirmationPopup";
import checkAccountPermission from "main/common/account/accountEditionConfig";
import { Person } from "@material-ui/icons";
import ShowAllResourcesButton from "../../content/common/ShowAllResourcesButton";
import * as mainActions from "store/actions/mainActivityPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "48px",
    backgroundColor: theme.palette.primary.background
  },
  logo: {
    order: 1,
    display: "flex",
    flex: "0 0 auto"
  },
  rightmenu: {
    order: 3,
    display: "flex",
    flex: "0 0 auto"
  },
  hamburgermenu: {
    order: 4,
    display: "flex",
    flex: "0 0 auto"
  },
  centermenu: {
    order: 2,
    display: "flex",
    flex: "1 1 auto",
    textAlign: "center"
  },

  seperator: {
    width: 1,
    height: 52,
    backgroundColor: theme.palette.divider
  },
  languageFlag: {
    width: "24px"
  },
  logoIcon: {
    height: 30
  },
  listItemText: {
    root: {
      paddingRight: 0
    },
    primary: {
      fontSize: theme.text.medium
    }
  }
});

class OMToolbar extends Component {
  state = {
    userMenu: null,
    languageMenu: null,
    settingsMenu: null,
    reportMenu: null,
    customerMenu: null,
    openDemoDataDeleteModal: false
  };

  userMenuClick = event => {
    this.setState({ userMenu: event.currentTarget });
  };

  languageMenuClick = event => {
    this.setState({ languageMenu: event.currentTarget });
  };

  settingsMenuClick = event => {
    this.setState({ settingsMenu: event.currentTarget });
  };

  reportMenuClick = event => {
    this.setState({ reportMenu: event.currentTarget });
  };

  customerMenuClick = event => {
    if (this.state.customerMenu === null) {
      this.setState({ customerMenu: event.currentTarget });
    } else {
      this.setState({ customerMenu: null });
    }
  };

  userMenuClose = () => {
    this.setState({ userMenu: null });
  };

  settingsMenuClose = () => {
    this.setState({ settingsMenu: null });
  };

  languageMenuClose = () => {
    this.setState({ languageMenu: null });
  };

  reportMenuClose = () => {
    this.setState({ reportMenu: null });
  };

  openDeleteDemoDataPopup = () => {
    this.props.openDeleteDemoDataPopup();
  };

  closeDeleteDemoDataPopup = () => {
    this.props.closeDeleteDemoDataPopup();
  };

  executeDemoDataDeletion = () => {
    this.closeDeleteDemoDataPopup();
    this.setState({ openDemoDataDeleteModal: true }, () =>
      deleteDemoData(() => this.setState({ openDemoDataDeleteModal: false }))
    );
  };

  render() {
    const {
      classes,
      user,
      logout,
      changeUserLanguage,
      account,
      openDeleteDemoDataDialog
    } = this.props;

    const {
      userMenu,
      languageMenu,
      settingsMenu,
      reportMenu,
      openDemoDataDeleteModal
    } = this.state;

    moment.locale(getMomementLocale());

    return (
      <React.Fragment>
        <div className={classNames(classes.root, "flex flex-row")}>
          <div className={classNames(classes.logo, "px-12")}>
            <Hidden smUp>
              <div className="flex px-6 py-6" style={{ flexDirection: "row" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer"
                  }}
                  onClick={this.props.userListToggle}
                >
                  <Person color="primary" className="mt-4 ml-4" />
                  <Typography
                    variant="h3"
                    className="px-8 py-8"
                    color="primary"
                  >
                    {stringTranslate("toolbar", "Resources")}
                  </Typography>
                  <FontAwesomeIcon
                    icon={
                      this.props.userListVisibility
                        ? ["fas", "caret-up"]
                        : ["fas", "caret-down"]
                    }
                    color="#9591a0"
                    className="mr-4"
                  />
                </div>
                <ShowAllResourcesButton />
              </div>
            </Hidden>
            <Hidden xsDown>
              <MenuItem component={Link} to="/mainActivity">
                <img
                  className={classNames(classes.logoIcon, "logo-icon")}
                  src="assets/images/logos/OverMover.svg"
                  alt="logo"
                />
              </MenuItem>
            </Hidden>
          </div>
          <div className={classNames(classes.centermenu)} />
          <Hidden smDown>
            <div className={classNames(classes.rightmenu)}>
              <div className="m-auto flex flex-row">
                {checkAccountPermission(account, "toolbar", "Home") && (
                  <MenuItem component={Link} to="/mainActivity">
                    <Typography
                      variant="body1"
                      className={classNames(classes.listItemText)}
                    >
                      {stringTranslate("toolbar", "Home")}
                    </Typography>
                  </MenuItem>
                )}
                {checkAccountPermission(account, "toolbar", "Resources") && (
                  <MenuItem component={Link} to="/resources">
                    <Typography
                      variant="body1"
                      className={classNames(classes.listItemText)}
                    >
                      {stringTranslate("toolbar", "Resources")}
                    </Typography>
                  </MenuItem>
                )}
                {checkAccountPermission(account, "toolbar", "Customers") && (
                  <React.Fragment>
                    <MenuItem
                      component={Link}
                      to="#"
                      onClick={this.customerMenuClick}
                    >
                      <Typography
                        variant="body1"
                        className={classNames(classes.listItemText)}
                      >
                        {stringTranslate("toolbar", "Customers")}
                      </Typography>
                    </MenuItem>
                    <Popover
                      open={Boolean(this.state.customerMenu)}
                      anchorEl={this.state.customerMenu}
                      onClose={this.customerMenuClick}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                      }}
                      classes={{
                        paper: "py-8"
                      }}
                    >
                      <React.Fragment>
                        <MenuItem
                          component={Link}
                          to="/customers"
                          onClick={this.customerMenuClick}
                        >
                          <Typography
                            variant="body1"
                            className={classNames(classes.listItemText)}
                          >
                            {stringTranslate("toolbar", "customerList")}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          component={Link}
                          to="/importCustomers"
                          onClick={this.customerMenuClick}
                        >
                          <Typography
                            variant="body1"
                            className={classNames(classes.listItemText)}
                          >
                            {stringTranslate("toolbar", "importCustomers")}
                          </Typography>
                        </MenuItem>
                      </React.Fragment>
                    </Popover>
                  </React.Fragment>
                )}
                {checkAccountPermission(account, "toolbar", "Reports") && (
                  <React.Fragment>
                    <MenuItem
                      component={Link}
                      to="#"
                      onClick={this.reportMenuClick}
                    >
                      <Typography
                        variant="body1"
                        className={classNames(classes.listItemText)}
                      >
                        {stringTranslate("toolbar", "Reports")}
                      </Typography>
                    </MenuItem>
                    <Popover
                      open={Boolean(reportMenu)}
                      anchorEl={reportMenu}
                      onClose={this.reportMenuClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                      }}
                      classes={{
                        paper: "py-8"
                      }}
                    >
                      <React.Fragment>
                        <MenuItem
                          component={Link}
                          to="/reports/routes"
                          onClick={this.reportMenuClose}
                        >
                          <Typography
                            variant="body1"
                            className={classNames(classes.listItemText)}
                          >
                            {stringTranslate("toolbar", "resourceRoutes")}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          component={Link}
                          to="/reports/resources"
                          onClick={this.reportMenuClose}
                        >
                          <Typography
                            variant="body1"
                            className={classNames(classes.listItemText)}
                          >
                            {stringTranslate("toolbar", "Resources")}
                          </Typography>
                        </MenuItem>
                        {checkAccountPermission(
                          account,
                          "timeTracking",
                          "TimeTracking"
                        ) && (
                          <MenuItem component={Link} to="/reports/timeTracking">
                            <Typography
                              variant="body1"
                              className={classNames(classes.listItemText)}
                            >
                              {stringTranslate("toolbar", "TimeTracking")}
                            </Typography>
                          </MenuItem>
                        )}
                        {checkAccountPermission(
                          account,
                          "toolbar",
                          "TaskReport"
                        ) && (
                          <MenuItem component={Link} to="/reports/tasks">
                            <Typography
                              variant="body1"
                              className={classNames(classes.listItemText)}
                            >
                              {stringTranslate("toolbar", "TaskReport")}
                            </Typography>
                          </MenuItem>
                        )}
                      </React.Fragment>
                    </Popover>
                  </React.Fragment>
                )}
                {checkAccountPermission(account, "toolbar", "Modules") && (
                  <MenuItem component={Link} to="/modules">
                    <Typography
                      variant="body1"
                      className={classNames(classes.listItemText)}
                    >
                      {stringTranslate("toolbar", "Modules")}
                    </Typography>
                  </MenuItem>
                )}
                {checkAccountPermission(account, "toolbar", "Settings") &&
                user.role === "admin" ? (
                  <React.Fragment>
                    <MenuItem
                      component={Link}
                      to="#"
                      onClick={this.settingsMenuClick}
                    >
                      <Typography
                        variant="body1"
                        className={classNames(classes.listItemText)}
                      >
                        {stringTranslate("toolbar", "Settings")}
                      </Typography>
                    </MenuItem>
                    <Popover
                      open={Boolean(settingsMenu)}
                      anchorEl={settingsMenu}
                      onClose={this.settingsMenuClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                      }}
                      classes={{
                        paper: "py-8"
                      }}
                    >
                      <React.Fragment>
                        <MenuItem
                          component={Link}
                          to="/users"
                          onClick={this.settingsMenuClose}
                        >
                          <Typography
                            variant="body1"
                            className={classNames(classes.listItemText)}
                          >
                            {stringTranslate("toolbar", "Users")}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          component={Link}
                          to="/account"
                          onClick={this.settingsMenuClose}
                        >
                          <Typography
                            variant="body1"
                            className={classNames(classes.listItemText)}
                          >
                            {stringTranslate("toolbar", "Account")}
                          </Typography>
                        </MenuItem>
                        {checkAccountPermission(
                          account,
                          "timeTracking",
                          "TimeTracking"
                        ) ? (
                          <MenuItem
                            component={Link}
                            to="/timeTracking"
                            onClick={this.settingsMenuClose}
                          >
                            <Typography
                              variant="body1"
                              className={classNames(classes.listItemText)}
                            >
                              {stringTranslate("toolbar", "VerifyTimeTracking")}
                            </Typography>
                          </MenuItem>
                        ) : null}
                        {account && !account.demoDataDeleted && (
                          <MenuItem
                            component={Link}
                            to="#"
                            onClick={this.openDeleteDemoDataPopup}
                          >
                            <Typography
                              variant="body1"
                              className={classes.listItemText}
                            >
                              {stringTranslate(
                                "general",
                                "demoDataDeletionTitle"
                              )}
                            </Typography>
                          </MenuItem>
                        )}
                      </React.Fragment>
                    </Popover>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>
              <NotificationsToolbarButton />
              <HelpdeskModal />

              <FuseAnimate delay={300}>
                <Tooltip title={stringTranslate("toolbar", "Change language")}>
                  <Button className="h-50" onClick={this.languageMenuClick}>
                    <div className="hidden md:flex flex-col ml-12 items-start">
                      <img
                        className={classNames(classes.languageFlag)}
                        src={getCurrentLanguageIcon()}
                        alt={stringTranslate("toolbar", "Change Language")}
                      />
                    </div>

                    <Icon
                      className="text-16 ml-12 hidden sm:flex"
                      variant="action"
                    >
                      keyboard_arrow_down
                    </Icon>
                  </Button>
                </Tooltip>
              </FuseAnimate>
              <Popover
                open={Boolean(languageMenu)}
                anchorEl={languageMenu}
                onClose={this.languageMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
                classes={{
                  paper: "py-8"
                }}
              >
                <React.Fragment>
                  <MenuItem
                    component={Link}
                    to="#"
                    onClick={() => {
                      changeUserLanguage("it-IT");
                      this.languageMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <img
                        alt="it-IT"
                        className={classNames(classes.languageFlag)}
                        src={getLanguageIcon("it-IT")}
                      />
                    </ListItemIcon>

                    <Typography
                      variant="body1"
                      className={classNames(classes.listItemText)}
                    >
                      {getLanguageLabel("it-IT")}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="#"
                    onClick={() => {
                      changeUserLanguage("en-US");
                      this.languageMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <img
                        alt="en-US"
                        className={classNames(classes.languageFlag)}
                        src={getLanguageIcon("en-US")}
                      />
                    </ListItemIcon>
                    <Typography
                      variant="body1"
                      className={classNames(classes.listItemText)}
                    >
                      {getLanguageLabel("en-US")}
                    </Typography>
                  </MenuItem>
                </React.Fragment>
              </Popover>

              <div className={classNames(classes.seperator)} />
              <FuseAnimate delay={300}>
                <Button className="h-50" onClick={this.userMenuClick}>
                  <div className="hidden md:flex flex-col ml-12 items-start">
                    <Typography variant="body1">
                      {user.data.displayName}
                    </Typography>
                    <Typography variant="caption" className="capitalize">
                      {stringTranslate("general", user.role)}
                    </Typography>
                  </div>

                  <Icon
                    className="text-16 ml-12 hidden sm:flex"
                    variant="action"
                  >
                    keyboard_arrow_down
                  </Icon>
                </Button>
              </FuseAnimate>
              <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={this.userMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center"
                }}
                classes={{
                  paper: "py-8"
                }}
              >
                <React.Fragment>
                  <MenuItem
                    onClick={() => {
                      logout();
                      this.userMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <Icon>exit_to_app</Icon>
                    </ListItemIcon>
                    <Typography
                      variant="body1"
                      className={classNames(classes.listItemText)}
                    >
                      {stringTranslate("toolbar", "Logout")}
                    </Typography>
                  </MenuItem>
                </React.Fragment>
              </Popover>
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classNames(classes.hamburgermenu)}>
              <HamburgerMenu
                logout={logout}
                changeUserLanguage={changeUserLanguage}
              />
            </div>
          </Hidden>
          <OMConfirmationPopup
            isOpen={openDeleteDemoDataDialog}
            confirmHandler={this.executeDemoDataDeletion}
            closeHandler={this.closeDeleteDemoDataPopup}
            title={stringTranslate("general", "demoDataDeletionTitle")}
            text={stringTranslate("general", "demoDataDeletionText")}
            confirmActionText={stringTranslate("general", "Delete")}
          />
          <LoadingModal
            isOpen={openDemoDataDeleteModal}
            text={stringTranslate("general", "demoDataDeleting")}
          />
        </div>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: authActions.logoutUser,
      changeUserLanguage: authActions.changeUserLanguage,
      openDeleteDemoDataPopup: fuseActions.openDeleteDemoDataDialog,
      closeDeleteDemoDataPopup: fuseActions.closeDeleteDemoDataDialog,
      userListToggle: mainActions.userListToggle
    },
    dispatch
  );
}

function mapStateToProps({ auth, firestore, fuse, mainActivityPage }) {
  return {
    user: auth.user,
    account: firestore.data.account,
    openDeleteDemoDataDialog: fuse.dialog.openDeleteDemoDataDialog,
    userListVisibility: mainActivityPage.panelVisibility.userListVisibility
  };
}

export default compose(
  firestoreConnect(props => {
    const collection = [
      {
        collection: "accounts/",
        doc: localStorage.getItem("accountId"),
        storeAs: "account"
      }
    ];
    return collection;
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(OMToolbar);
