import React from "react";
import { withStyles } from "@material-ui/core/styles";

const style = theme => ({
  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "48px",
    minHeight: "48px",
    borderBottomWidth: "1px",
    borderBottomColor: "#e0e0e0",
    backgroundColor: "#FFFFFF",
    color: "#000000DE",
    fontSize: theme.text.small,
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "#ededed",
      borderLeftColor: "#ededed"
    }
  }
});

const TableRow = props => {
  return (
    <div className={props.classes.tableRow}>
      {props.data.map((element, index) => {
        return (
          <div
            key={index}
            style={{
              width: props.columns[index].width,
              paddingLeft: "12px",
              paddingRight: "12px"
            }}
          >
            {element}
          </div>
        );
      })}
    </div>
  );
};
export default withStyles(style, { withTheme: true })(TableRow);
