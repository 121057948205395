export const mainactivity = {
  "Search on Google Maps": "Cerca su Google Maps",
  Resources: "Risorse",
  Customers: "Clienti",
  Tasks: "Attivita'",
  Busy: "Occupato",
  Engaged: "Occupato",
  Free: "Libero",
  Still: "Fermo",
  Moving: "In movimento",
  Offline: "Non collegato",
  Privacy: "Privacy",
  "N/A": "N.D.",
  Day: "Giorno",
  Week: "Settimana",
  Month: "Mese",
  Scheduler: "Calendario",
  Chat: "Chat",
  Map: "Mappa",
  at: "a",
  taskDescription: "Task corrente",
  addTask: "Aggiungi attività",
  loadingAccount: "Creazione account in corso...",
  "Show Filter": "Mostra filtro",
  "Show Traffic": "Mostra situazione traffico",
  "Zoom in": "Ingrandisci",
  Route: "Percorso",
  Remove: "Rimuovi",
  selectCategories: "Seleziona categorie",
  selectCustomers: "Seleziona clienti"
};
