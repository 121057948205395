export const SET_VISIBLE_MARKERS = "[ACTIVITYMAP] SET_VISIBLE_MARKERS";
export const SET_VISIBLE_RESOURCES = "[ACTIVITYMAP] SET_VISIBLE_RESOURCES";

export function setVisibleMarkers(markers) {
  return {
    type: SET_VISIBLE_MARKERS,
    markers
  };
}

export function setVisibleResources(resources) {
  return {
    type: SET_VISIBLE_RESOURCES,
    resources
  };
}

export const SET_VISIBLE_TASKS = "[ACTIVITYMAP] SET_VISIBLE_TASKS";

export function setVisibleTasks(tasks) {
  return {
    type: SET_VISIBLE_TASKS,
    tasks
  };
}

export const SET_VISIBLE_PARTIES = "[ACTIVITYMAP] SET_VISIBLE_PARTIES";

export function setVisibleParties(tasks) {
  return {
    type: SET_VISIBLE_PARTIES,
    tasks
  };
}

export const SET_GEOLOCATED_POI = "[ACTIVITYMAP] SET_GEOLOCATED_POI";

export function setGeolocatedPoi(geolocatedPoi) {
  return {
    type: SET_GEOLOCATED_POI,
    geolocatedPoi
  };
}

export const SHOW_ALL_RESOURCES = "[ACTIVITYMAP] SHOW_ALL_RESOURCES";

export function showAllResources(isToShow) {
  return {
    type: SHOW_ALL_RESOURCES,
    value: isToShow
  };
}
