import * as Actions from "../../actions/mainActivityPage/index";

const initialState = {
  visibleMarkers: [],
  visibleTasks: [],
  visibleResources: [],
  geolocatedPoi: [],
  showAllResources: false
};

const activityMap = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_ALL_RESOURCES: {
      return {
        ...state,
        showAllResources: action.value
      };
    }
    case Actions.SET_VISIBLE_MARKERS: {
      return {
        ...state,
        visibleMarkers: action.markers
      };
    }
    case Actions.SET_VISIBLE_RESOURCES: {
      return {
        ...state,
        visibleResources: action.resources
      };
    }
    case Actions.SET_VISIBLE_TASKS: {
      return {
        ...state,
        visibleTasks: action.tasks
      };
    }
    case Actions.SET_VISIBLE_PARTIES: {
      return {
        ...state,
        visibleParties: action.parties
      };
    }
    case Actions.SET_GEOLOCATED_POI: {
      return {
        ...state,
        geolocatedPoi: action.geolocatedPoi
      };
    }
    case Actions.RESET_ACTION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default activityMap;
