import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import firebase from "../../../firestoreAccount";
import ResourceListContainer from "./ResourceListContainer";
import ResourceFormContainer from "./ResourceFormContainer";
import Joyride, { ACTIONS, EVENTS, STATUS, LIFECYCLE } from "react-joyride";
import steps from "./guidedTourSteps";
import { stringTranslate } from "../../../languages/OMTranslate";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setResource } from "../../../store/actions/resources";

const styles = theme => ({
  hContainer: {
    position: "relative",
    display: "flex",
    flexFlow: "row nowrap",
    height: "calc(100vh - 48px)",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      flexFlow: "column nowrap"
    }
  },
  vContainer: {
    backgroundColor: theme.palette.primary.white,
    order: 3,
    flex: "1 1 auto",
    width: "50%",
    display: "flex",
    flexFlow: "column nowrap",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      width: "100%"
    }
  }
});

class ResourcesPage extends Component {
  state = {
    runTour: false,
    stepIndex: 0
  };

  componentDidMount() {
    if (this.props.user && this.props.user.showGuidedTour) {
      if (this.props.resources) {
        this.props.setResource(this.props.resources[0].id);
      }
      this.setState({ runTour: true });
    } else {
      this.props.setResource(null);
    }
  }

  tooltipCallback = data => {
    const { action, index, status, type, lifecycle } = data;
    if (index === 2 && type === "step:after" && action === ACTIONS.NEXT) {
      this.props.history.push({ pathname: "/" });
      window.location.reload();
    }

    if (
      action === ACTIONS.CLOSE &&
      lifecycle === LIFECYCLE.COMPLETE &&
      type === EVENTS.STEP_AFTER
    ) {
      this.setState({ runTour: false });
      firebase
        .firestore()
        .collection("users")
        .doc(this.props.user.uid)
        .update({ showGuidedTour: false, stepIndex: 13 });
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ runTour: false });
    }
  };

  render() {
    const { classes, isToShowResourceForm } = this.props;
    return (
      <React.Fragment>
        <div className="main-container">
          <div className={classes.hContainer}>
            <Joyride
              showProgress={true}
              run={this.state.runTour}
              stepIndex={this.state.stepIndex}
              steps={steps}
              continuous={true}
              callback={this.tooltipCallback}
              styles={{
                options: {
                  primaryColor: "#ff7b59"
                }
              }}
              locale={{
                back: stringTranslate("tour", "back"),
                close: stringTranslate("tour", "close"),
                last: stringTranslate("tour", "next"),
                next: stringTranslate("tour", "next"),
                skip: stringTranslate("tour", "skip")
              }}
            />
            <Hidden xsDown={isToShowResourceForm}>
              <ResourceListContainer />
            </Hidden>
            <Hidden xsDown={!isToShowResourceForm}>
              <div className={classes.vContainer}>
                <ResourceFormContainer />
              </div>
            </Hidden>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ResourcesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  setResource: id => dispatch(setResource(id))
});

export default withRouter(
  compose(
    firestoreConnect(props => [
      {
        collection: "/resources",
        where: ["accountId", "==", localStorage.getItem("accountId")],
        storeAs: "resources",
        orderBy: "name"
      }
    ]),
    connect(
      state => ({
        account: state.firestore.data.account,
        user: state.auth.user,
        resources: state.firestore.ordered.resources,
        isToShowResourceForm: state.resources.resourceList.isToShowResourceForm
      }),
      mapDispatchToProps
    ),
    withStyles(styles, { withTheme: true })
  )(ResourcesPage)
);
