import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio as RadioBase,
  FormHelperText
} from "@material-ui/core";

const styles = theme => ({
  formControl: {},
  controlLabel: {
    fontSize: "11px",
    color: theme.palette.primary.dark
  },
  label: {
    fontSize: "11px"
  }
});

const Radio = ({ classes, field, value, onChange, error }) => (
  <FormControl
    className={classes.formControl}
    required={field.required}
    component="fieldset"
    fullWidth
    margin="normal"
    error={error !== undefined && error !== ""}
  >
    <FormLabel component="legend" className={classes.label}>
      {field.label}
    </FormLabel>
    <RadioGroup value={value} onChange={e => onChange(field)(e.target.value)}>
      {field.options.map(option => (
        <FormControlLabel
          key={option.name}
          className={classes.controlLabel}
          value={option.name}
          control={<RadioBase />}
          label={option.label}
        />
      ))}
    </RadioGroup>
    <FormHelperText>{`${field.help}${
      error && error !== "" ? `${field.help ? " - " : ""}${error}` : ""
    }`}</FormHelperText>
  </FormControl>
);

Radio.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    position: PropTypes.number.isRequired,
    help: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string
      })
    )
  }).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

Radio.defaultProps = {
  value: "",
  error: undefined
};

export default withStyles(styles, { withTheme: true })(Radio);
