import { FuseLoadable } from "@fuse";

export const NotAvaiablePageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false
        },
        toolbar: {
          display: false
        },
        footer: {
          display: false
        },
        leftSidePanel: {
          display: false
        },
        rightSidePanel: {
          display: false
        }
      }
    }
  },
  routes: [
    {
      path: "/pages/errors/not-avaiable",
      component: FuseLoadable({
        loader: () => import("./NotAvaiablePage")
      })
    },
    {
      path: "/pages/errors/not-fit-min-size",
      component: FuseLoadable({
        loader: () => import("./NotFitMinSize")
      })
    }
  ]
};
