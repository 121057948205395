import React, { Component } from "react";
import Formsy from "formsy-react";
import { TextFieldFormsy } from "@fuse";
import { withStyles, Button, InputAdornment, Icon } from "@material-ui/core";
import { bindActionCreators } from "redux";
import * as Actions from "auth/store/actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { stringTranslate } from "../../../../languages/OMTranslate";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    width: "100%"
  }
});

class FirebaseLoginTab extends Component {
  state = {
    canSubmit: false
  };

  form = React.createRef();

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  onSubmit = model => {
    this.props.submitLoginWithFireBase(model);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.login.error &&
      (this.props.login.error.username || this.props.login.error.password)
    ) {
      this.form.updateInputsWithError({
        ...this.props.login.error
      });

      this.props.login.error = null;
      this.disableButton();
    }

    if (this.props.user.role !== "guest") {
      const pathname =
        this.props.location.state && this.props.location.state.redirectUrl
          ? this.props.location.state.redirectUrl
          : "/";
      this.props.history.push({
        pathname
      });
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    const { canSubmit } = this.state;

    return (
      <div className={classes.root}>
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          ref={form => (this.form = form)}
          className="flex flex-col justify-center w-full"
        >
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <TextFieldFormsy
                className="w-full"
                type="text"
                name="username"
                label={stringTranslate("login", "Email")}
                validations={{
                  minLength: 4
                }}
                validationErrors={{
                  minLength: stringTranslate(
                    "login",
                    "Min character length is 4"
                  )
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className="text-20" color="action">
                        email
                      </Icon>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormsy
                className="w-full"
                type="password"
                name="password"
                label={stringTranslate("login", "Password")}
                validations={{
                  minLength: 4
                }}
                validationErrors={{
                  minLength: stringTranslate(
                    "login",
                    "Min character length is 4"
                  )
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className="text-20" color="action">
                        vpn_key
                      </Icon>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                aria-label={stringTranslate("login", "Login")}
                disabled={!canSubmit}
                value="firebase"
                className="normal-case"
              >
                {stringTranslate("login", "Login")}
              </Button>
            </Grid>
          </Grid>
        </Formsy>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLoginWithFireBase: Actions.submitLoginWithFireBase
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    login: auth.login,
    user: auth.user
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(FirebaseLoginTab)
  )
);
