import moment from "moment";
import "moment/locale/it";
import { getMomementLocale } from "languages/OMTranslate";

export function timestampToDateTime(time) {
  if (!time) return "";
  var t = new Date(time.seconds * 1000);
  var formatted = t.toLocaleDateString() + " " + t.toLocaleTimeString();
  return formatted;
}

export function timestampToDbDateTime(time) {
  if (!time) return "";
  return moment.unix(time.seconds).format("YYYY-MM-DD HH:mm:ss");
}

export function formatDate(time) {
  if (!time) {
    return "";
  }

  moment.locale(getMomementLocale());
  return moment.unix(time.seconds).format("L");
}
