import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import React from "react";
import { withStyles, InputLabel } from "@material-ui/core";
import _ from "@lodash";
import { getCountryCallingCode } from "libphonenumber-js";
import classNames from "classnames";

const styles = theme => ({
  input: {
    height: "33px !important",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
    "&:hover": {
      borderBottom: "2px solid " + theme.palette.primary.darker
    },
    "&:focus": {
      borderBottom: "2px solid " + theme.palette.primary.darker + " !important"
    },
    flexBasis: "auto !important",
    backgroundColor: "white"
  },
  label: {
    fontSize: "calc(" + theme.text.medium + " * 0.75)",
    // color: theme.palette.primary.dark,
    color: "rgba(0, 0, 0, 0.54)",
    height: 16
  }
});

const OMPhoneInput = props => {
  const importedProps = _.pick(props, [
    "autoComplete",
    "disabled",
    "displayInitialValueAsLocalNumber",
    "country",
    "countries",
    "labels",
    "flagsPath",
    "flags",
    "flagComponent",
    "international",
    "internationalIcon",
    "showCountrySelect",
    "countrySelectTabIndex",
    "countryOptions",
    "style",
    "className",
    "inputClassName",
    "countrySelectComponent",
    "inputComponent",
    "ext",
    "error",
    "locale",
    "metadata",
    "onCountryChange",
    "disablePhoneInput"
  ]);

  let { value, country } = props;
  if (!value && country) {
    value = "+" + getCountryCallingCode(country);
  }

  const {
    classes,
    placeholder,
    errorMessage,
    label,
    required,
    containerClassName
  } = props;

  return (
    <div className={classNames("flex flex-col", containerClassName)}>
      <InputLabel className={classes.label} required={required}>
        {label}
      </InputLabel>
      <PhoneInput
        {...importedProps}
        placeholder={placeholder}
        inputClassName={classes.input}
        onChange={phone => props.changeValueHandler(phone)}
        value={value}
        error={errorMessage}
      />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(OMPhoneInput);
