import { combineReducers } from "redux";
import panelVisibility from "./panelVisibility.reducer";
import activityMap from "./activityMap.reducer";
import resourceList from "./resourceList.reducer";
import taskAssign from "./taskAssign.reducer";
import chat from "./chat.reducer";
import notifications from "./notifications.reducer";
import task from "./task.reducer";

const mainActivityPage = combineReducers({
  panelVisibility,
  activityMap,
  resourceList,
  taskAssign,
  chat,
  notifications,
  task
});

export default mainActivityPage;
