import * as Actions from "../../actions/mainActivityPage/index";

const initialState = { notifications: [] };

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SAVE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications
      };
    case Actions.EMPTY_NOTIFICATIONS:
      return {
        ...state,
        notifications: []
      };
    default: {
      return state;
    }
  }
};

export default notificationsReducer;
