import React from "react";
import { withStyles } from "@material-ui/core";
import AccountButtonMenu from "./AccountButtonMenu";
import AccountPaymentsDetail from "./AccountPaymentsDetail";
import { compose } from "redux";
import { StripeProvider } from "react-stripe-elements";
import { Elements } from "react-stripe-elements";

const styles = theme => ({
  root: {
    position: "relative",
    display: "flex",
    flexFlow: "row nowrap",
    height: "calc(100vh - " + theme.measures.navbarHeight + ")",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      flexFlow: "column nowrap"
    }
  }
});

const AccountPaymentsPage = ({ classes }) => {
  const stripePublicKey =
    process.env.REACT_APP_FIREBASE_ENV === "production"
      ? "pk_live_RykOCB1e0pfoVsGp7e28423L00qE9rgOiT"
      : "pk_test_lRMpOpXqv9drRw5IfBLb179Q00eInrVGBa";

  return (
    <StripeProvider apiKey={stripePublicKey}>
      <div className={classes.root} id="hContainer">
        <AccountButtonMenu />
        <Elements>
          <AccountPaymentsDetail />
        </Elements>
      </div>
    </StripeProvider>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(
  AccountPaymentsPage
);
