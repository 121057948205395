import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Badge } from "@material-ui/core";
import moment from "moment";
import "moment/min/locales";
import { Draggable } from "react-beautiful-dnd";
import TextCell from "./TextCell";
import IconCell from "./IconCell";
import checkAccountPermission from "main/common/account/accountEditionConfig";
import { stringTranslate } from "languages/OMTranslate";
import { getTaskColor } from "../scheduler/getTaskColor";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import * as taskConstants from "../../../common/tasks/taskConstants";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";

const styles = theme => ({
  tableRow: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    height: "48px",
    minHeight: "48px",
    borderBottomWidth: "1px",
    borderBottomColor: "#e0e0e0",
    borderLeftColor: "#FFFFFF",
    borderLeftWidth: 5,
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#ededed",
      borderLeftColor: "#ededed"
    },
    "&.delay": {
      "&.strong": {
        borderLeftColor: theme.palette.delay.strong
      },
      "&.small": {
        borderLeftColor: theme.palette.delay.small
      }
    }
  },
  badge: {
    backgroundColor: "#ff7b59",
    color: "#fff",
    width: "20px",
    height: "20px",
    top: "-9px",
    right: "-9px"
  }
});

class TaskListItem extends React.Component {
  getTableRowClassName = task => {
    return classNames(this.props.classes.tableRow, this.props.getDelay(task));
  };

  getTransmissionStatusIcon = task => {
    switch (task.transmissionStatus) {
      case taskConstants.TRANSMISSION_SENT:
        return <DoneIcon />;
      case taskConstants.TRANSMISSION_RECEIVED:
        return <DoneAllIcon />;
      case taskConstants.TRANSMISSION_READ:
        return <DoneAllIcon color="secondary" />;
      default:
        return <span />;
    }
  };

  invalid = value => {
    return value === null || value === undefined;
  };

  render() {
    const { task, index, classes } = this.props;
    const isWhereVisible = checkAccountPermission(
      this.props.account,
      "taskList",
      "where"
    );
    const when = this.props.getWhenDescription(task);

    const distance = this.invalid(task.distance)
      ? ""
      : this.props.getDistance(task);
    const eta = this.invalid(task.eta)
      ? ""
      : moment.duration(task.eta, "seconds").humanize();

    return (
      <Draggable
        key={task.id}
        draggableId={task.id}
        index={index}
        isDragDisabled={this.props.orderBy !== "executionOrder"}
      >
        {(provided, snapshot) => (
          <div
            {...provided.draggableProps}
            ref={provided.innerRef}
            className={this.getTableRowClassName(task)}
            onClick={this.props.handleClick}
          >
            <IconCell column="1" dragHandleProps={provided.dragHandleProps}>
              <Badge
                classes={{ badge: classes.badge }}
                badgeContent={task.executionOrder ? task.executionOrder : ""}
                invisible={!task.executionOrder}
              >
                <span />
              </Badge>
            </IconCell>
            <TextCell content={task.code} column="2" />
            <TextCell
              content={task.description}
              tooltip={task.description}
              column={isWhereVisible ? "3" : "3L"}
            />
            <TextCell content={task.resourceName} column="4" />
            <TextCell
              content={when ? when.date : ""}
              secondary={when ? when.hour + " (" + when.duration + ")" : ""}
              column="5"
            />
            {isWhereVisible ? (
              <TextCell
                content={task.partyName}
                secondary={task.poiAddress}
                column="6"
              />
            ) : null}
            {isWhereVisible ? (
              <TextCell
                content={distance}
                secondary={eta}
                column="7"
                style={{ textAlign: "right" }}
              />
            ) : null}
            <TextCell
              column="8"
              content={stringTranslate("tasks", task.executionStatus)}
              textColor={getTaskColor(task)}
            />
            <IconCell column="9">
              <div
                style={{
                  display: "flex",
                  alignSelf: "stretch",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                {this.getTransmissionStatusIcon(task)}
                <KeyboardArrowRight />
              </div>
            </IconCell>
          </div>
        )}
      </Draggable>
    );
  }
}
export default withStyles(styles, { withTheme: true })(TaskListItem);
