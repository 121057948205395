import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import { stringTranslate } from "languages/OMTranslate";
import firebase, {
  getFirestoreCollection
} from "../../../../../firestoreAccount";
import * as fileUtils from "./attachments/fileUtils";

import ButtonUpload from "./components/ButtonUpload";
import OMDeleteConfirmationAlert from "../../../common/OMDeleteConfirmationAlert";
import Attachments from "./attachments/Attachments";
import AttachmentViewer from "./attachments/AttachmentViewer";

const styles = theme => ({
  container: {
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    flex: 1,
    height: "100%"
  },
  backButton: {
    position: "absolute",
    top: theme.spacing.unit,
    left: theme.spacing.unit
  },
  dialogTitle: {
    paddingBottom: "10px"
  },
  dialogContent: {
    padding: 0
  },
  title: {
    paddingLeft: "50px",
    marginBottom: "10px"
  },
  subTitle: {
    paddingLeft: "50px",
    color: theme.palette.primary.normal,
    fontWeight: 700,
    fontSize: "14px"
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "5px 20px 20px"
  },
  contentView: {
    minHeight: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  message: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark,
    lineHeight: "1.75"
  },
  shadow: {
    boxShadow: "0px 2px 2px 0px rgba(0,0,0, 0.1)"
  }
});

class TaskAttachments extends React.Component {
  state = {
    loading: true,
    attachments: [],
    error: undefined,
    fileUpload: {
      running: false,
      progress: 0,
      success: false
    },
    snackbar: {
      open: false,
      message: ""
    },
    dialogDelete: {
      open: false,
      attachment: undefined
    },
    attachmentToView: undefined
  };

  uploadTask = undefined;

  unsubscribeAttachments = undefined;

  componentDidMount() {
    const { taskId } = this.props;
    if (taskId === null || taskId === undefined) {
      return;
    }
    this.unsubscribeAttachments = firebase
      .firestore()
      .collection(`${getFirestoreCollection()}/tasks/${taskId}/attachments`)
      .orderBy("createdAt", "desc")
      .onSnapshot(
        result => {
          this.setState({
            loading: false,
            attachments:
              result !== undefined && result !== null ? result.docs : [],
            error: undefined
          });
        },
        e => {
          console.log("error subscribe resource", e);
          this.setState({
            loading: false,
            attachments: [],
            error: stringTranslate("tasks", "errorFetchAttachments")
          });
        }
      );
  }

  componentWillUnmount() {
    if (this.unsubscribeAttachments) {
      this.unsubscribeAttachments();
    }
    if (this.uploadTask) {
      this.uploadTask();
    }
  }

  handleToogleShowDialogDelete = (attachment = undefined) => {
    this.setState(state => ({
      dialogDelete: {
        open: !state.dialogDelete.open,
        attachment
      }
    }));
  };

  handleShowSnackbar = message => {
    this.setState({ snackbar: { open: true, message } });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState(state => ({
      snackbar: { ...state.snackbar, open: false }
    }));
  };

  /**
   * Metodo richiamato quando viene cliccato un allegato
   * @param {DocumentSnapshot} attachment oggetto document snapshot dell'allegato
   */
  handleOnAttachmentClick = attachment => {
    this.setState({ attachmentToView: attachment.data() });
  };

  /**
   * Metodo richiamato quando viene cliccato sul pulsante cancella di un allegato
   * @param {DocumentSnapshot} attachment oggetto document snapshot dell'allegato
   */
  handleOnAttachmentDeleteClick = attachment => {
    this.handleToogleShowDialogDelete(attachment);
  };

  /**
   * Metodo richiamato quando viene selezionato un file per l'upload.
   * Dopo aver verificato che il tipo di file sia supportato
   * viene eseguito l'upload del file
   * @param {Object} event evento selezione file
   */
  handleOnFileSelect = event => {
    if (event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    if (!fileUtils.validFileType(file.type)) {
      this.handleShowSnackbar(stringTranslate("tasks", "invalidFile"));
      return;
    }
    if (!fileUtils.validSize(file.size)) {
      this.handleShowSnackbar(stringTranslate("tasks", "invalidFileSize"));
      return;
    }
    this.uploadFile(file);
  };

  /**
   * Esegue l'upload di un file come allegato. Dopo che il file
   * è stato caricato crea il relativo oggetto allegato
   * @param {File} file oggetto file da caricare
   */
  uploadFile = file => {
    const { taskId, user } = this.props;
    this.uploadTask = fileUtils.uploadFile(file, "taskAttachments")(
      snapshot => {
        const progress = (
          (snapshot.bytesTransferred / snapshot.totalBytes) *
          100
        ).toFixed(0);
        this.setState({
          fileUpload: {
            running: true,
            progress: parseInt(progress, 10),
            success: false
          }
        });
      },
      error => {
        console.log(error);
        this.handleShowSnackbar(stringTranslate("tasks", "errorUploadFile"));
        this.setState({
          fileUpload: {
            running: false,
            progress: 0,
            success: false
          }
        });
      },
      async downloadUrl => {
        if (this.uploadTask === undefined) {
          return;
        }
        this.setState(state => ({
          fileUpload: {
            ...state.fileUpload,
            running: true,
            success: true
          }
        }));
        await this.createAttachmentAsync(file, downloadUrl, taskId, user);
        setTimeout(() => {
          this.setState({
            fileUpload: {
              progress: 0,
              running: false,
              success: false
            }
          });
        }, 1000);
      }
    );
  };

  /**
   * Crea l'oggetto allegato
   * @param {File} file file dell'allegato
   * @param {String} downloadUrl url di download del file
   * @param {String} taskId id del task
   * @param {Object} user oggetto con le informazioni dell'utente
   */
  createAttachmentAsync = async (file, downloadUrl, taskId, user) => {
    try {
      await firebase
        .firestore()
        .collection(`${getFirestoreCollection()}/tasks/${taskId}/attachments`)
        .add({
          createdAt: new Date(),
          name: file.name,
          url: downloadUrl,
          ...fileUtils.processFileSize(file.size),
          type: fileUtils.fileType(file.type),
          user: {
            _id: user.uid,
            avatar: "",
            name: user.data.displayName,
            type: "USER"
          }
        });
      // this.handleShowSnackbar(stringTranslate('tasks', 'attachmentCreated'));
    } catch (e) {
      console.log("error create attachment", e);
      this.handleShowSnackbar(
        stringTranslate("tasks", "errorCreateAttachment")
      );
    }
  };

  /**
   * Esegue la cancellazione di un allegato e il relativo file
   * @param {DocumentSnapshot} attachment oggetto document snapshot dell'allegato
   */
  deleteAttachmentAsync = async attachment => {
    if (attachment === undefined || attachment === null) {
      return;
    }
    try {
      attachment.ref.delete();
    } catch (e) {
      console.log("error delete attachment", e);
      this.handleShowSnackbar(
        stringTranslate("tasks", "errorDeleteAttachment")
      );
      return;
    }
    this.handleShowSnackbar(stringTranslate("tasks", "attachmentDeleted"));
    try {
      await fileUtils.deleteFileAsync(attachment.data().url);
    } catch (e) {
      console.log("error delete attachment file", e);
      // Nel caso di cancellazione del file non viene interrotto
      // l'utente dalla navigazione
    }
  };

  /**
   * Ritorna una component per visualizzare un messaggio
   * @param {String} message messaggio
   */
  getMessageView = message => {
    const { classes } = this.props;
    return (
      <div className={classes.contentView}>
        <Typography className={classes.message}>{message}</Typography>
      </div>
    );
  };

  contentToRender = () => {
    const { loading, error, attachments } = this.state;
    const { classes } = this.props;
    if (loading) {
      return (
        <div className={classes.contentView}>
          <CircularProgress color="secondary" />
        </div>
      );
    }
    if (error) {
      return this.getMessageView(error);
    }
    if (attachments.length === 0) {
      return this.getMessageView(stringTranslate("tasks", "noAttachments"));
    }
    return (
      <Attachments
        attachments={attachments}
        onItemClick={this.handleOnAttachmentClick}
        onItemDelete={this.handleOnAttachmentDeleteClick}
      />
    );
  };

  render() {
    const { taskDescription, onBack, classes } = this.props;
    const { snackbar, fileUpload, dialogDelete, attachmentToView } = this.state;
    return (
      <React.Fragment>
        <IconButton className={classes.backButton} onClick={onBack}>
          <BackIcon />
        </IconButton>
        <DialogTitle
          id="simple-dialog-title"
          disableTypography
          className={classes.dialogTitle}
        >
          <div>
            <Typography variant="h3" className={classes.title}>
              {stringTranslate("tasks", "taskAttachments")}
            </Typography>
            <Typography variant="h4" className={classes.subTitle}>
              {taskDescription}
            </Typography>
          </div>
        </DialogTitle>
        <div className={`${classes.toolbar} ${classes.shadow}`}>
          <ButtonUpload
            running={fileUpload.running}
            progress={fileUpload.progress}
            success={fileUpload.success}
            onFileSelected={this.handleOnFileSelect}
            fileTypes={["image/*", ".pdf"]}
          >
            {stringTranslate("general", "Upload")}
          </ButtonUpload>
        </div>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.container}>{this.contentToRender()}</div>
        </DialogContent>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={this.handleCloseSnackbar}
          message={snackbar.message}
        />
        <OMDeleteConfirmationAlert
          isOpen={dialogDelete.open}
          deleteHandler={() => {
            this.handleToogleShowDialogDelete();
            this.deleteAttachmentAsync(dialogDelete.attachment);
          }}
          cancelHandler={() => this.handleToogleShowDialogDelete()}
        />
        {attachmentToView && (
          <AttachmentViewer
            attachment={attachmentToView}
            onClose={() => this.setState({ attachmentToView: undefined })}
          />
        )}
      </React.Fragment>
    );
  }
}

TaskAttachments.propTypes = {
  taskId: PropTypes.string.isRequired,
  taskDescription: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired // Oggetto creato da withStyles
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(TaskAttachments);
