export const account = {
  name: "Name",
  phoneNumber: "Phone number",
  address: "Address",
  UUID: "Vat",
  email: "Email",

  subscriptionType: "Actual plan",
  billingDate: "Next invoice on",
  estimatedInvoice: "Estimated invoice amount",

  paymentMethod: "Payment method",
  updatingStripeData: "Updating payment method...",
  missingPaymentMethodDialogTitle: "Missing payment method",
  missingPaymentMethodDialogBody:
    "You must provide a valid payment method to continue to use OverMover.",
  missingPaymentMethodNotificationBody1:
    "Your account subscription will expire in ",
  missingPaymentMethodNotificationBody2:
    " days. Please insert a valid payment method",

  Account: "Account",
  Subscription: "Subscription",
  Payments: "Payments"
};
