import handleIndexEE from "./guidedTour/handleIndexEE";
import handleIndexPE from "./guidedTour/handleIndexPE";
import handleIndexSE from "./guidedTour/handleIndexSE";

import smallScreenHandleIndexEE from "./guidedTour/smallScreenHandleIndexEE";
import smallScreenHandleIndexPE from "./guidedTour/smallScreenHandleIndexPE";
import smallScreenHandleIndexSE from "./guidedTour/smallScreenHandleIndexSE";

export const handleIndex = ({
  stepIndex,
  edition,
  chatToggle,
  chatVisibility,
  chatRooms,
  setActiveChat,
  mapToggle,
  mapVisibility,
  taskListToggle,
  taskListVisibility,
  calendarToggle,
  calendarVisibility,
  userListToggle,
  userListVisibility,
  resources
}) => {
  if (edition === "EE") {
    handleIndexEE(
      stepIndex,
      chatToggle,
      chatVisibility,
      chatRooms,
      setActiveChat,
      mapToggle,
      mapVisibility,
      taskListToggle,
      taskListVisibility,
      calendarToggle,
      calendarVisibility,
      userListToggle,
      userListVisibility,
      resources
    );
  } else if (edition === "PE") {
    handleIndexPE(
      stepIndex,
      chatToggle,
      chatVisibility,
      chatRooms,
      setActiveChat,
      mapToggle,
      mapVisibility,
      taskListToggle,
      taskListVisibility,
      calendarToggle,
      calendarVisibility,
      userListToggle,
      userListVisibility,
      resources
    );
  } else {
    handleIndexSE(
      stepIndex,
      chatToggle,
      chatVisibility,
      chatRooms,
      setActiveChat,
      mapToggle,
      mapVisibility,
      taskListToggle,
      taskListVisibility,
      calendarToggle,
      calendarVisibility,
      userListToggle,
      userListVisibility,
      resources
    );
  }
};

export const smallScreenHandleIndex = ({
  stepIndex,
  edition,
  chatToggle,
  chatVisibility,
  chatRooms,
  setActiveChat,
  mapToggle,
  mapVisibility,
  taskListToggle,
  taskListVisibility,
  calendarToggle,
  calendarVisibility,
  userListToggle,
  userListVisibility,
  hideAllPanels,
  resources
}) => {
  if (edition === "EE") {
    smallScreenHandleIndexEE(
      stepIndex,
      chatToggle,
      chatVisibility,
      chatRooms,
      setActiveChat,
      mapToggle,
      mapVisibility,
      taskListToggle,
      taskListVisibility,
      calendarToggle,
      calendarVisibility,
      userListToggle,
      userListVisibility,
      hideAllPanels,
      resources
    );
  } else if (edition === "PE") {
    smallScreenHandleIndexPE(
      stepIndex,
      chatToggle,
      chatVisibility,
      chatRooms,
      setActiveChat,
      mapToggle,
      mapVisibility,
      taskListToggle,
      taskListVisibility,
      calendarToggle,
      calendarVisibility,
      userListToggle,
      userListVisibility,
      hideAllPanels,
      resources
    );
  } else {
    smallScreenHandleIndexSE(
      stepIndex,
      chatToggle,
      chatVisibility,
      chatRooms,
      setActiveChat,
      mapToggle,
      mapVisibility,
      taskListToggle,
      taskListVisibility,
      calendarToggle,
      calendarVisibility,
      userListToggle,
      userListVisibility,
      hideAllPanels,
      resources
    );
  }
};
