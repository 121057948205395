import bigScreenStepsSE from "./guidedTour/bigScreenStepsSE";
import bigScreenStepsPE from "./guidedTour/bigScreenStepsPE";
import bigScreenStepsEE from "./guidedTour/bigScreenStepsEE";

import smallScreenStepsSE from "./guidedTour/smallScreenStepsSE";
import smallScreenStepsPE from "./guidedTour/smallScreenStepsPE";
import smallScreenStepsEE from "./guidedTour/smallScreenStepsEE";

export const bigScreenSteps = {
  SE: bigScreenStepsSE,
  PE: bigScreenStepsPE,
  EE: bigScreenStepsEE
};

export const smallScreenSteps = {
  SE: smallScreenStepsSE,
  PE: smallScreenStepsPE,
  EE: smallScreenStepsEE
};
