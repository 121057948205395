import React, { Component } from "react";
import Formsy from "formsy-react";
import { TextFieldFormsy } from "@fuse";
import { CheckboxFormsy } from "@fuse";
import classNames from "classnames";

import { withStyles, Button, InputAdornment, Icon } from "@material-ui/core";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as Actions from "auth/store/actions";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { stringTranslate, getPolicyLink } from "languages/OMTranslate";
import Modal from "@material-ui/core/Modal";
import PrivacyText from "../PrivacyText";
import PolicyText from "../PolicyText";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    width: "100%"
  },
  textContainer: {
    display: "flex",
    flexDirection: "column"
  },
  modalRoot: {
    position: "absolute",
    width: "calc(100vw * 0.8)",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    height: "calc(100vh * 0.6)",
    top: "15vh",
    left: "10vw"
  }
});

class FirebaseRegisterTab extends Component {
  state = {
    dialogOpen: false,
    policyOpen: false,
    canSubmit: false
  };

  form = React.createRef();

  privacyClick = event => {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  };
  policyClick = event => {
    this.setState({ policyOpen: !this.state.policyOpen });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  onSubmit = model => {
    const query = new URLSearchParams(this.props.location.search);
    model.requestedPlan = query.get("requestedPlan");
    this.props.registerWithFirebase(model);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.register.error &&
      (this.props.register.error.username ||
        this.props.register.error.password ||
        this.props.register.error.email)
    ) {
      this.form.updateInputsWithError({
        ...this.props.register.error
      });

      this.props.register.error = null;
      this.disableButton();
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    const { canSubmit } = this.state;

    return (
      <div className={classes.root}>
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          ref={form => (this.form = form)}
          className="flex flex-col justify-center w-full"
        >
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <TextFieldFormsy
                className="w-full"
                type="text"
                name="firstName"
                label={stringTranslate("register", "First Name")}
                validations={{
                  minLength: 3
                }}
                validationErrors={{
                  minLength: stringTranslate(
                    "login",
                    "Min character length is 3"
                  )
                }}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldFormsy
                className="w-full"
                type="text"
                name="lastName"
                label={stringTranslate("register", "Last Name")}
                validations={{
                  minLength: 3
                }}
                validationErrors={{
                  minLength: stringTranslate(
                    "login",
                    "Min character length is 3"
                  )
                }}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormsy
                className="w-full"
                type="text"
                name="company"
                label={stringTranslate("register", "Company")}
                validations={{
                  minLength: 3
                }}
                validationErrors={{
                  minLength: stringTranslate(
                    "login",
                    "Min character length is 3"
                  )
                }}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormsy
                className="w-full"
                type="text"
                name="email"
                value={this.props.fixedEmail ? this.props.fixedEmail : ""}
                disabled={Boolean(this.props.fixedEmail)}
                label={stringTranslate("register", "Email")}
                validations="isEmail"
                validationErrors={{
                  isEmail: stringTranslate(
                    "general",
                    "Please enter a valid email"
                  )
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className="text-20" color="action">
                        email
                      </Icon>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormsy
                className="w-full"
                type="password"
                name="password"
                label={stringTranslate("register", "Password")}
                validations="equalsField:password-confirm"
                validationErrors={{
                  equalsField: stringTranslate(
                    "register",
                    "Passwords do not match"
                  )
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className="text-20" color="action">
                        vpn_key
                      </Icon>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldFormsy
                className="w-full"
                type="password"
                name="password-confirm"
                label={stringTranslate("register", "Confirm password")}
                validations="equalsField:password"
                validationErrors={{
                  equalsField: stringTranslate(
                    "register",
                    "Passwords do not match"
                  )
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className="text-20" color="action">
                        vpn_key
                      </Icon>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxFormsy
                className="my-16"
                name="accept"
                label={
                  <React.Fragment>
                    {stringTranslate("register", "acceptCondition") + " "}
                    <a href={getPolicyLink()} target="_blank">
                      {stringTranslate("register", "policyLink")}
                    </a>
                    {" " +
                      stringTranslate("register", "acceptCondition2") +
                      " "}
                    <Link to="#" onClick={this.privacyClick}>
                      {stringTranslate("register", "policyLink2")}
                    </Link>
                  </React.Fragment>
                }
                validations="equals:true"
                validationError={stringTranslate("register", "acceptError")}
                required
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className="mx-auto normal-case"
                aria-label={stringTranslate("register", "Register")}
                disabled={!canSubmit}
              >
                {stringTranslate("register", "Register")}
              </Button>
            </Grid>
          </Grid>
        </Formsy>
        <Modal open={this.state.dialogOpen} onBackdropClick={this.privacyClick}>
          <div className={classNames(classes.modalRoot)}>
            <PrivacyText onBackdropClick={this.privacyClick} />
          </div>
        </Modal>
        <Modal open={this.state.policyOpen} onBackdropClick={this.policyClick}>
          <div className={classNames(classes.modalRoot)}>
            <PolicyText onBackdropClick={this.policyClick} />
          </div>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerWithFirebase: Actions.registerWithFirebase
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    register: auth.register,
    user: auth.user
  };
}

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(FirebaseRegisterTab)
  )
);
