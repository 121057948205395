import React from "react";
import { Typography } from "@material-ui/core";

const MainActivitySectionHeader = ({ icon, text, children }) => {
  const Icon = icon;
  return (
    <div className="flex px-6 py-6">
      <Icon color="primary" className="mt-4 ml-4" />
      <Typography variant="h3" className="px-8 py-8" color="primary">
        {text}
      </Typography>
      {children ? children : null}
    </div>
  );
};

export default MainActivitySectionHeader;
