import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { getFirestoreCollection } from "../../../../firestoreAccount";

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit
  },
  container: {
    backgroundColor: theme.palette.primary.background
  }
});

class ToAssignTaskBar extends Component {
  handleDelete = taskId => {
    const { firestore } = this.props;
    firestore.delete({
      collection: getFirestoreCollection() + "/tasks",
      doc: taskId
    });
  };

  handleOpenForm = (event, taskId) => {
    this.props.clickHandler(event, taskId);
  };

  render() {
    const tasks = this.props.toAssignTasks;
    const { classes, dndSchedulerConfig } = this.props;
    let DnDTaskItem = dndSchedulerConfig.taskDndSource.getDragSource();

    return (
      <Paper elevation={0} className={classes.container}>
        {tasks && tasks.length > 0 && (
          <React.Fragment>
            {tasks.map(task => (
              <DnDTaskItem
                key={task.id}
                task={task}
                newEvent={dndSchedulerConfig.newEvent}
                schedulerData={dndSchedulerConfig.schedulerData}
                chipClickHandler={this.handleOpenForm}
                chipDeleteHandler={this.handleDelete}
              />
            ))}
          </React.Fragment>
        )}
      </Paper>
    );
  }
}

export default compose(
  firestoreConnect(),
  connect(state => ({
    toAssignTasks: state.firestore.ordered.toAssignTasks,
    parties: state.firestore.data.parties,
    resources: state.firestore.data.resources,
    pois: state.firestore.data.pois,
    contacts: state.firestore.data.contacts,
    dndSchedulerConfig: state.mainActivityPage.taskAssign.dndConfig
  })),
  withStyles(styles)
)(ToAssignTaskBar);
