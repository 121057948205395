import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import firebase, { getFirestoreCollection } from "../../../firestoreAccount";
import { stringTranslate } from "languages/OMTranslate";
import classNames from "classnames";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import MapIcon from "@material-ui/icons/Map";
import {
  setSelectedCustomer,
  setSelectedPoi,
  togglePoiModal,
  setOrphanPoiId,
  setOrphanPoiAddress
} from "store/actions/customersPage";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PoiFormModal from "./PoiFormModal";
import { Button, IconButton, Typography, Hidden } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { TextFieldFormsy } from "@fuse";
import Formsy from "formsy-react";

const styles = theme => ({
  container: {
    padding: theme.spacing.unit * 3,
    width: "100%",
    maxWidth: theme.measures.formMaxWidth,
    // marginTop: theme.spacing.unit * 1.5,
    // marginBottom: theme.spacing.unit * 3,
    // marginLeft: theme.spacing.unit * 1.5,
    order: 1,
    flex: "0 0 auto"
  },
  textField: {
    width: "100%"
  },
  buttonProgress: {
    color: theme.palette.secondary.main
  },
  addressContainer: {
    display: "flex"
  },
  addressText: {
    flex: "1 1 auto"
  },
  addressIcon: {
    flex: "0 0 auto"
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  },
  leftArrow: {
    marginRight: "1.6rem"
  },
  dialogPaper: {
    maxWidth: "700px"
  }
});

class CustomerForm extends Component {
  state = {
    error: false,
    dialogOpen: false,
    onSaving: false,
    onSaved: false,
    canSubmit: false,
    poiCategories: []
  };

  componentDidMount() {
    firebase
      .firestore()
      .collection(getFirestoreCollection() + "/poiCategories")
      .onSnapshot(querySnapshot => {
        let poiCategories = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            poiCategories.push({
              id: doc.id,
              name: doc.data().name,
              color: doc.data().color
            });
          });
        }
        this.setState({ poiCategories });
      });
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  handleChange = name => event => {
    const { selectedCustomer, setSelectedCustomer } = this.props;
    var tmp = Object.assign({}, selectedCustomer);
    tmp[name] = event.target.value;
    setSelectedCustomer(tmp);
  };

  handleCloseDialog() {
    this.setState({
      dialogOpen: false
    });
  }
  handleOpenDialog() {
    this.setState({
      dialogOpen: true
    });
  }

  handleType = name => event => {
    const { selectedCustomer, setSelectedCustomer } = this.props;
    var tmp = Object.assign({}, selectedCustomer);

    if (event.target.checked) tmp.type = "SUPPLIER";
    else tmp.type = "CUSTOMER";
    setSelectedCustomer(tmp);
  };

  handleSubmit() {
    const { firestore } = this.props;
    const {
      selectedCustomer,
      orphanPoiId,
      setOrphanPoiId,
      setOrphanPoiAddress
    } = this.props;

    if (selectedCustomer) {
      if (selectedCustomer.id) {
        this.setState({
          onSaving: true
        });
        firestore
          .collection(getFirestoreCollection() + "/parties")
          .doc(selectedCustomer.id)
          .update(selectedCustomer)
          .then(() => {
            this.setState({
              onSaving: false,
              onSaved: true
            });
            setTimeout(
              function() {
                this.setState({ onSaved: false });
              }.bind(this),
              3000
            );
          });
      } else {
        if (selectedCustomer.name) {
          this.setState({
            onSaving: true
          });
          var company = firestore
            .collection(getFirestoreCollection() + "/parties")
            .doc();
          selectedCustomer.isActive = true;
          selectedCustomer.id = company.id;
          company.set(selectedCustomer).then(() => {
            if (orphanPoiId) {
              var orphanPoi = firestore
                .collection(getFirestoreCollection() + "/pois")
                .doc(orphanPoiId);

              orphanPoi.update({ partyId: selectedCustomer.id });
              setOrphanPoiId(null);
              setOrphanPoiAddress(null);
            }
            this.setState({
              onSaving: false,
              onSaved: true
            });
            setTimeout(
              function() {
                this.setState({ onSaved: false });
              }.bind(this),
              3000
            );
          });
        } else {
          this.setState({
            error: true
          });
        }
      }
    }
  }

  handleDelete() {
    const { firestore, selectedCustomer, setSelectedCustomer } = this.props;

    if (selectedCustomer.id) {
      var tmp = Object.assign({}, selectedCustomer);
      tmp.isActive = false;
      firestore
        .collection(getFirestoreCollection() + "/parties")
        .doc(selectedCustomer.id)
        .update(tmp)
        .then(() => {
          this.handleCloseDialog();
          setSelectedCustomer(null);
        });
    }
  }

  render() {
    const {
      classes,
      selectedCustomer,
      orphanPoiAddress,
      setSelectedPoi,
      togglePoiModal,
      mainAddress,
      setPoiTab,
      selectedPoi,
      showPoiModal
    } = this.props;
    const { onSaving, onSaved, canSubmit } = this.state;

    return (
      <React.Fragment>
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          ref={form => (this.form = form)}
          className={classes.container}
        >
          <Grid container spacing={24}>
            <Grid item xs={12} className="flex items-center">
              <Hidden smUp>
                <IconButton
                  className={classes.leftArrow}
                  onClick={() => {
                    this.props.handleNullNewCustomer();
                    this.props.setSelectedCustomer(null);
                  }}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Hidden>
              <Typography variant="h3">
                {selectedCustomer && selectedCustomer.id
                  ? stringTranslate("customers", "Modify Company")
                  : stringTranslate("customers", "New Company")}
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <TextFieldFormsy
                id="customerName"
                name="customerName"
                label={stringTranslate("customers", "Company Name")}
                className={classes.textField}
                value={
                  selectedCustomer && selectedCustomer.name
                    ? selectedCustomer.name
                    : ""
                }
                onChange={this.handleChange("name")}
                margin="normal"
                required
                InputProps={{
                  className: classes.inputText
                }}
                InputLabelProps={{
                  className: classes.inputTextLabel
                }}
              />
            </Grid>
            <Grid item sm={4} xs={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      selectedCustomer && selectedCustomer.type === "SUPPLIER"
                        ? true
                        : false
                    }
                    onChange={this.handleType()}
                    value="1"
                    color="default"
                  />
                }
                label={
                  selectedCustomer && selectedCustomer.type === "SUPPLIER"
                    ? stringTranslate("customers", "Supplier")
                    : stringTranslate("customers", "Customer")
                }
                classes={{ label: classes.inputText }}
              />
            </Grid>
            <Grid item sm={6} xs={8}>
              <TextFieldFormsy
                id="customerUUID"
                name="customerUUID"
                label={stringTranslate("customers", "VAT")}
                className={classes.textField}
                value={
                  selectedCustomer && selectedCustomer.UUID
                    ? selectedCustomer.UUID
                    : ""
                }
                InputProps={{
                  className: classes.inputText
                }}
                InputLabelProps={{
                  className: classes.inputTextLabel
                }}
                onChange={this.handleChange("UUID")}
                margin="normal"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextFieldFormsy
                id="customerPhone"
                name="customerPhone"
                label={stringTranslate("customers", "Phone")}
                className={classes.textField}
                value={
                  selectedCustomer && selectedCustomer.phone
                    ? selectedCustomer.phone
                    : ""
                }
                InputProps={{
                  className: classes.inputText
                }}
                InputLabelProps={{
                  className: classes.inputTextLabel
                }}
                onChange={this.handleChange("phone")}
                margin="normal"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextFieldFormsy
                id="customerEmail"
                name="customerEmail"
                label={stringTranslate("customers", "Email")}
                className={classes.textField}
                type="email"
                value={
                  selectedCustomer && selectedCustomer.email
                    ? selectedCustomer.email
                    : ""
                }
                InputProps={{
                  className: classes.inputText
                }}
                InputLabelProps={{
                  className: classes.inputTextLabel
                }}
                onChange={this.handleChange("email")}
                margin="normal"
                validations="isEmail"
                validationError={stringTranslate("general", "Not Valid Email")}
              />
            </Grid>
            {true ? (
              <Grid item sm={6} xs={12} className={classes.addressContainer}>
                <TextFieldFormsy
                  id="customerAddress"
                  name="customerAddress"
                  label={stringTranslate("customers", "Address")}
                  className={classNames(classes.textField, classes.addressText)}
                  value={
                    selectedCustomer &&
                    mainAddress &&
                    mainAddress.id &&
                    mainAddress.partyId === selectedCustomer.id
                      ? mainAddress.address
                      : orphanPoiAddress
                      ? orphanPoiAddress
                      : ""
                  }
                  InputProps={{
                    className: classes.inputText,
                    readOnly: true
                  }}
                  InputLabelProps={{
                    className: classes.inputTextLabel
                  }}
                  onChange={this.handleChange("address")}
                  margin="normal"
                />
                <IconButton
                  className={classNames(
                    classes.iconButton,
                    classes.addressIcon
                  )}
                  aria-label="Search"
                  onClick={() => {
                    if (
                      selectedCustomer &&
                      mainAddress &&
                      mainAddress.id &&
                      mainAddress.partyId === selectedCustomer.id
                    )
                      setSelectedPoi(mainAddress);
                    else {
                      setSelectedPoi({ isMainAddress: true });
                    }
                    setPoiTab(null, 1);
                    togglePoiModal();
                  }}
                >
                  <MapIcon />
                </IconButton>
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                className="mr-12"
                disabled={onSaving || !canSubmit}
                onClick={() => this.handleSubmit()}
              >
                {stringTranslate("general", "Save")}
              </Button>
              {selectedCustomer && selectedCustomer.id ? (
                <Button
                  variant="contained"
                  color="primary"
                  className="mr-12"
                  onClick={() => this.handleOpenDialog()}
                >
                  {stringTranslate("general", "Delete")}
                </Button>
              ) : (
                ""
              )}
              {onSaving && stringTranslate("general", "onSaving")}
              {onSaved && stringTranslate("general", "onSaved")}
            </Grid>
          </Grid>
          <Dialog
            open={this.state.dialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {stringTranslate("general", "Delete confirm title")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {stringTranslate("general", "Delete confirm content")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleCloseDialog()} color="primary">
                {stringTranslate("general", "Cancel")}
              </Button>
              <Button
                onClick={() => this.handleDelete()}
                color="secondary"
                autoFocus
              >
                {stringTranslate("general", "Delete")}
              </Button>
            </DialogActions>
          </Dialog>
        </Formsy>

        <Dialog
          onClose={() => togglePoiModal()}
          aria-labelledby="simple-dialog-title"
          open={showPoiModal}
          fullWidth
          scroll="paper"
          classes={{ paper: classes.dialogPaper }}
        >
          <PoiFormModal
            selectedPoi={selectedPoi}
            poiCategories={this.state.poiCategories}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

CustomerForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  showPoiModal: state.customersPage.contactsTab.showPoiModal,
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer,
  selectedPoi: state.customersPage.selectedPoi.selectedPoi,
  orphanPoiAddress: state.customersPage.selectedPoi.orphanPoiAddress,
  orphanPoiId: state.customersPage.selectedPoi.orphanPoiId
});

const mapDispatchToProps = dispatch => ({
  setSelectedCustomer: customer => dispatch(setSelectedCustomer(customer)),
  setSelectedPoi: contact => dispatch(setSelectedPoi(contact)),
  setOrphanPoiId: poiId => dispatch(setOrphanPoiId(poiId)),
  setOrphanPoiAddress: address => dispatch(setOrphanPoiAddress(address)),
  togglePoiModal: () => dispatch(togglePoiModal())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(CustomerForm);
