import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate } from "languages/OMTranslate";
import { TextFieldFormsy, SelectFormsy } from "@fuse";
import { Email, HelpOutline, Cancel } from "@material-ui/icons";
import classNames from "classnames";
import { IconButton, Button, MenuItem, Typography } from "@material-ui/core";
import { Card, CardContent, CardHeader, CardActions } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Formsy from "formsy-react";

const styles = theme => ({
  textField: {
    fontSize: theme.text.medium,
    margin: 0,
    marginTop: "10px"
  },
  textFieldEmail: {
    fontSize: theme.text.smaill,
    margin: 0
  },
  selectField: {
    fontSize: theme.text.small,
    marginTop: "6px"
  },
  menuItemField: {
    fontSize: theme.text.small,
    padding: "6px"
  },
  icon: { fontSize: "18px" }
});

class UserForm extends Component {
  constructor(props) {
    super(props);
    if (props.user && props.user.id) {
      this.state = {
        canSubmit: false,
        dialogOpen: false,
        modifingUserUid: props.user.id,
        modifingUserFirstName: props.user.data.firstName,
        modifingUserLastName: props.user.data.lastName,
        modifingUserName: props.user.data.displayName,
        modifingUserRole: props.user.role,
        modifingUserEmail: props.user.data.email,
        modifingUserStatus: props.user.status
      };
    } else {
      this.state = {
        canSubmit: false,
        dialogOpen: false,
        modifingUserUid: 0,
        modifingUserFirstName: "",
        modifingUserLastName: "",
        modifingUserName: "",
        modifingUserRole: "staff",
        modifingUserStatus: "WAITING_VALIDATION",
        modifingUserEmail: ""
      };
    }
  }

  disableButton = () => this.setState({ canSubmit: false });

  enableButton = () => this.setState({ canSubmit: true });

  handleChange = name => event =>
    this.setState({
      [name]: event.target.value
    });

  handleDelete() {
    const { firestore } = this.props;
    const { modifingUserUid } = this.state;
    if (modifingUserUid) {
      firestore
        .collection("users")
        .doc(modifingUserUid)
        .delete()
        .then(() => {
          this.props.setModifingUser();
          this.props.closeNewUser();
        });
    }
  }

  submitModify(user) {
    const { firestore } = this.props;
    const {
      modifingUserUid,
      modifingUserFirstName,
      modifingUserLastName,
      modifingUserName,
      modifingUserRole,
      modifingUserEmail,
      modifingUserStatus
    } = this.state;

    if (modifingUserUid) {
      firestore
        .collection("users")
        .doc(modifingUserUid)
        .update({
          role: modifingUserRole,
          data: {
            ...user.data,
            firstName: modifingUserFirstName,
            lastName: modifingUserLastName,
            displayName: modifingUserFirstName + " " + modifingUserLastName,
            email: modifingUserEmail
          }
        })
        .then(() => {
          this.props.setModifingUser();
          this.props.closeNewUser();
        });
    } else {
      var newUser = firestore.collection("users").doc();
      newUser
        .set({
          role: modifingUserRole,
          accountId: localStorage.getItem("accountId"),
          status: modifingUserStatus,
          from: "firebase",
          uid: newUser.id,
          data: {
            email: modifingUserEmail,
            displayName: modifingUserName
          }
        })
        .then(() => {
          this.props.setModifingUser();
          this.props.closeNewUser();
        });
    }
  }

  closeForm() {
    this.props.setModifingUser();
    this.props.closeNewUser();
  }

  render() {
    const { classes, user } = this.props;
    const {
      modifingUserUid,
      modifingUserFirstName,
      modifingUserLastName,
      modifingUserRole,
      modifingUserEmail,
      modifingUserStatus,
      canSubmit
    } = this.state;
    return (
      <Card className="w-256 mb-16 sm:mb-12 mr-16 sm:mr-12 max-h-full flex flex-col min-w-200 ">
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          ref={form => (this.form = form)}
          className={classes.container}
        >
          <CardHeader
            className="pl-12 pr-12"
            classes={{
              action: "-mx-12 ",
              content: "mr-12"
            }}
            title={
              <React.Fragment>
                <TextFieldFormsy
                  id="firstName"
                  name="firstName"
                  required
                  value={modifingUserFirstName}
                  autoFocus={true}
                  fullWidth={true}
                  onChange={this.handleChange("modifingUserFirstName")}
                  validations={{
                    minLength: 3
                  }}
                  label={stringTranslate("register", "First Name")}
                  validationErrors={{
                    minLength: stringTranslate(
                      "login",
                      "Min character length is 3"
                    )
                  }}
                  InputProps={{ classes: { input: classes.textField } }}
                />
                <TextFieldFormsy
                  id="lastName"
                  name="lastName"
                  required
                  value={modifingUserLastName}
                  autoFocus={true}
                  fullWidth={true}
                  onChange={this.handleChange("modifingUserLastName")}
                  validations={{
                    minLength: 3
                  }}
                  label={stringTranslate("register", "Last Name")}
                  validationErrors={{
                    minLength: stringTranslate(
                      "login",
                      "Min character length is 3"
                    )
                  }}
                  InputProps={{ classes: { input: classes.textField } }}
                />
              </React.Fragment>
            }
            subheader={
              <SelectFormsy
                name="userRole"
                value={modifingUserRole}
                onChange={this.handleChange("modifingUserRole")}
                label={stringTranslate("register", "role")}
                inputProps={{
                  id: "userRole"
                }}
                className="mt-12"
                classes={{
                  selectMenu: classes.selectField
                }}
              >
                <MenuItem value={"admin"} className={classes.menuItemField}>
                  {stringTranslate("general", "admin")}
                </MenuItem>
                <MenuItem value={"staff"} className={classes.menuItemField}>
                  {stringTranslate("general", "staff")}
                </MenuItem>
              </SelectFormsy>
            }
            action={
              <IconButton
                aria-label={stringTranslate("general", "Cancel")}
                component={Link}
                to="#"
                onClick={() => this.closeForm()}
              >
                <Cancel classes={{ root: "text-24" }} />
              </IconButton>
            }
            subheaderTypographyProps={{
              style: { textTransform: "capitalize" }
            }}
          />
          <CardContent className="pl-12 pr-12">
            <Typography className="flex flex-row capitalize " variant="caption">
              <Email className={classNames(classes.icon, "mr-12 mt-24")} />
              <TextFieldFormsy
                name="userEmail"
                id="userEmail"
                required
                label={stringTranslate("register", "Email")}
                validations="isEmail"
                validationError={stringTranslate("general", "Not Valid Email")}
                value={modifingUserEmail}
                onChange={this.handleChange("modifingUserEmail")}
                InputProps={{
                  classes: { input: classes.textField },
                  readOnly: modifingUserUid ? true : false
                }}
              />
            </Typography>

            <Typography
              className="flex flex-row capitalize mt-12"
              variant="caption"
            >
              <HelpOutline className={classNames(classes.icon, "mr-12")} />
              <span>{stringTranslate("general", modifingUserStatus)}</span>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="secondary"
              className="mr-12"
              onClick={() => this.submitModify(user)}
              disabled={!canSubmit}
            >
              {stringTranslate("general", "Save")}
            </Button>
            {modifingUserUid ? (
              <Button
                variant="contained"
                color="primary"
                className="mr-12"
                onClick={() => this.setState({ dialogOpen: true })}
              >
                {stringTranslate("general", "Delete")}
              </Button>
            ) : (
              ""
            )}
          </CardActions>
        </Formsy>
        <Dialog
          open={this.state.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {stringTranslate("general", "Delete confirm title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {stringTranslate("general", "Delete confirm content")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogOpen: false })}
              color="primary"
            >
              {stringTranslate("general", "Cancel")}
            </Button>
            <Button
              onClick={() => this.handleDelete()}
              color="secondary"
              autoFocus
            >
              {stringTranslate("general", "Delete")}
            </Button>
          </DialogActions>
        </Dialog>{" "}
      </Card>
    );
  }
}

UserForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default compose(
  firestoreConnect(),
  withStyles(styles, { withTheme: true })
)(UserForm);
