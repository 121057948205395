import { stringTranslate } from "../../../../languages/OMTranslate";
export default [
  {
    target: "#guidedTour-step1",
    content: stringTranslate("smallScreenTour", "step1"),
    disableBeacon: true,
    placement: "center"
  },
  {
    target: "#guidedTour-step2",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step2"),
    placement: "top"
  },
  {
    target: "#guidedTour-step4",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step3"),
    placement: "top"
  },
  {
    target: "#guidedTour-step7",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step4"),
    placement: "auto"
  },
  {
    target: "#guidedTour-step7",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step5"),
    placement: "auto"
  },
  {
    target: "#guidedTour-step3",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step6"),
    placement: "top"
  },
  {
    target: "#guidedTour-step11",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step7"),
    placement: "auto"
  },
  {
    target: "#guidedTour-step11",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step8"),
    placement: "auto"
  },
  {
    target: "#guidedTour-step6",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step9"),
    placement: "top"
  },
  {
    target: "#guidedTour-step9",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step10"),
    placement: "auto"
  },
  {
    target: "#guidedTour-step5",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step11"),
    placement: "top"
  },
  {
    target: "#guidedTour-step13",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step12"),
    placement: "auto"
  },
  {
    target: "#guidedTour-step13",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step13"),
    placement: "auto"
  },
  {
    target: "#guidedTour-step1",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step14"),
    placement: "center"
  },
  {
    target: "#guidedTour-step1",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step15"),
    placement: "center"
  },
  {
    target: "#guidedTour-step1",
    disableBeacon: true,
    content: stringTranslate("smallScreenTour", "step16"),
    placement: "center"
  }
];
