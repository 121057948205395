export const customers = {
  "Customers list": "Customers list",
  "Company Name": "Company Name",
  "New Company": "New Company",
  "Modify Name": "Modify Name",
  "Modify Company": "Modify Company",
  VAT: "VAT",
  Phone: "Phone",
  Address: "Address",
  Email: "Email",
  Customer: "Customer",
  Supplier: "Supplier",
  Contacts: "Contacts",
  Pois: "Pois",
  Notes: "Notes",
  Modules: "Modules",
  "Module Name": "Name",
  "Module Created At": "Created At",
  "New Contact": "New Contact",
  "Contact Modify": "Contact Modify",
  "New Note": "New Note",
  "Note Modify": "Note Modify",
  "Contact Name": "Name",
  "Contact Phone": "Phone",
  "Contact Email": "Email",
  "Note Description": "Note Description",
  "Note Date": "Note Date",
  "New Poi": "New POI",
  "Poi Modify": "POI Modify",
  "Poi Description": "Description",
  "Poi Address": "Address",
  "Poi Latitude": "Latitude",
  "Poi Longitude": "Longitude",
  "Main Address": "Main Address",
  "Address Type": "Type",
  noModules: "No modules found",
  noCustomForms: "No custom form has been created yet",
  newModule: "New module",
  poiCategory: "POI Category",

  importFileMessage:
    "You can upload an Excel file (.xls, .xlsx, .csv) to import a list of customers. The file should contain these informations:",
  mainAddressName: "Headquarters name",
  mainAddressCoords: "Headquarters coordinates",
  downloadExampleFile: "Download the example file from this ",
  downloadExampleFile2:
    " and modify it with your data (leave the first row as it is), then upload it below and wait for it to be processed.",
  downloadExampleFile3:
    "If you want to add the headquarters of the customer and you need help to find the coordinates of a place, read this ",
  googleMapsGuide: "Google Maps guide.",
  necessaryFields:
    "Fields marked with * are mandatory, if one of these fields is empty the row of the excel file will be ignored.",
  necessaryFields2:
    "If the aren't the coordinates, the headquarters name won't be saved.",
  necessaryFields3:
    "VAT/Fiscal Code value must be univocal, if a customer with the same VAT/Fiscla Code already exists then the data will be updated.",
  loadingMessage: "Loading file, please wait...",
  importErrorMessage:
    "File import error, check that it is correctly filled in and try again.",
  importSuccessMessage: "Customers imported successfully",
  numberOfCustomers: "$1 rows out of $2 processed correctly"
};
