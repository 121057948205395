import React, { Component } from "react";
import { stringTranslate } from "languages/OMTranslate";
import { Typography } from "@material-ui/core";

class TimeTrackingChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight - 48 - 47 - 205
    };
    this.resizeTO = null;
  }

  resize = () => {
    if (this.resizeTO) clearTimeout(this.resizeTO);
    this.resizeTO = setTimeout(() => {
      this.setState({ height: window.innerHeight - 48 - 47 - 205 });
      this.drawChart();
    }, 500);
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    window.google.charts.load("current", { packages: ["timeline"] });
    window.google.charts.setOnLoadCallback(this.drawChart);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  drawChart = () => {
    const options = {
      enableInteractivity: false,
      hAxis: {
        title: "Hours",
        format: "HH:mm",
        viewWindow: { min: [0, 0, 0], max: [24, 0, 0] }
      },
      tooltip: {
        trigger: "none"
      },
      legend: "none"
    };
    var container = document.getElementById("timeTrackingChart");
    var chart = new window.google.visualization.Timeline(container);
    var dataTable = new window.google.visualization.DataTable();
    dataTable.addColumn({ type: "string", id: "Date" });
    dataTable.addColumn({ type: "string", id: "Duration" });
    dataTable.addColumn({ type: "string", id: "style", role: "style" });
    dataTable.addColumn({ type: "date", id: "Start" });
    dataTable.addColumn({ type: "date", id: "End" });
    dataTable.addRows(this.props.timeTrackingData);

    chart.draw(dataTable, options);
  };

  render() {
    const { totalTime } = this.props;

    return (
      <div>
        <div
          style={{
            paddingBottom: "8px",
            paddingRight: "10px",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end"
          }}
        >
          <Typography variant="h3">
            {stringTranslate("reports", "totalTime") + totalTime}
          </Typography>
          <div>
            <div id="legend1">
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  display: "inline-block",
                  backgroundColor: "#0091ae",
                  borderRadius: 3
                }}
              />{" "}
              <Typography variant="caption" style={{ display: "inline" }}>
                {stringTranslate("reports", "validTime")}
              </Typography>
            </div>
            <div id="legend2">
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  display: "inline-block",
                  backgroundColor: "#be3d56",
                  borderRadius: 3
                }}
              />{" "}
              <Typography variant="caption" style={{ display: "inline" }}>
                {stringTranslate("reports", "modifiedTime")}
              </Typography>
            </div>
          </div>
        </div>

        <div id="timeTrackingChart" style={{ height: this.state.height }} />
      </div>
    );
  }
}
export default TimeTrackingChart;
