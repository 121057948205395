export const general = {
  "Overmover Payoff": "Overmover Payoff italiano",
  "Please enter a valid email": "Inserire un indirzzo email valido",
  New: "Nuovo",
  New_F: "Nuova",
  Edit: "Modifica",
  Search: "Cerca",
  Add: "Aggiungi",
  admin: "Amministratore",
  staff: "Operatore",
  user: "utente",
  ENABLED: "abilitato",
  DISABLED: "non abilitato",
  WAITING_VALIDATION: "in attesa di verifica",
  Save: "Salva",
  Delete: "Cancella",
  Cancel: "Annulla",
  Select: "Seleziona",
  Close: "Chiudi",
  Send: "Invia",
  Confirm: "Conferma",
  Upload: "Carica",
  "Delete confirm title": "Conferma cancellazione",
  "Delete confirm content":
    "I dati relativi saranno persi. Confermi la cancellazione?",
  onSaving: "Salvataggio in corso...",
  onSaved: "Salvataggio effettuato con successo",
  "Not Valid Email": "Indirizzo email non valido",
  "All resources": "Tutte le risorse",
  "Visible resources": "Risorse visibili sulla mappa",
  "Show all": "Vedi tutti",
  All: "Tutti",
  Today: "Oggi",
  filter: "Filtri",
  from: "Dal",
  to: "Al",

  fsnDateTimeFormat: "dd/MM/yyyy HH:mm",
  momentDateTimeFormat: "DD/MM/YYYY HH:mm",
  momentDateFormat: "DD/MM/YYYY",
  momentTimeFormat: "HH:mm",
  fsnDateFormat: "dd/MM/yyyy",
  fsnTimeFormat: "HH:mm",
  timeCaption: "Orario",

  otherInfo: "Altre informazioni",
  "Only Desktop version available for now.":
    "Al momento e' disponibile solo in versione desktop",
  "Do you need a mobile version?": "Ti serve la versione mobile?",
  "Send us a message": "Inviaci una richiesta",
  offlineStatus:
    "Il servizio non è disponibile: connessione ad internet assente.",
  reconnectAttemptLink: "Clicca qui per ricaricare la homepage di OverMover",
  reconnectAttemptLinkShort: "Clicca qui",
  manualRedirect:
    "Se non vieni reindirizzato automaticamente al tuo nuovo account clicca qui",

  demoDataDeletionTitle: "Cancella dati dimostrativi",
  demoDataDeletionText:
    "Sono ancora presenti i dati dimostrativi. Si vuole procedere alla eliminazione di essi?",
  demoDataDeleting: "Cancellazione dati dimostrativi in corso...",

  //login
  "Do more with your workers": "Ottieni di piu' dalle tue risorse",
  "Leverage smartphones to coordinate, comunicate, schedule and allocate your workforce. Reduce waste and improve your business.":
    "Sfrutta gli smartphone per coordinare, comunicare, pianificare le risorse del tuo team. Riduci gli sprechi e migliora il tuo business.",
  //register
  "Try OverMover Free for 30 days": "Prova OverMover per 30 giorni",
  "Manage your workforce: Defines To-Do and deadlines, exchange messages and documents, schedule & allocate tasks to your workers.":
    "Gestisci la tua forza lavoro: definisci le attività, scambia messaggi e documenti, pianifica ed alloca i task.",
  "Try it free for 30 days - no credit card required.":
    "Provalo gratuitamente per 30 giorni, senza carta di credito.",

  "Loading Data...": "Caricamento dati...",
  "No elements": "Nessun elemento",
  "Thank you!": "Grazie!",
  "Your email has been verified": "La tua email e' stata verificata",
  "Login to OverMover": "Accedi a OverMover",
  "Create new password": "Crea un nuova password",
  "Modify Password": "Modifica password",
  "An email has been sent to your email address. Follow the instructions to complete the password recovery procedure":
    "E' stata inviata una mail al tuo indirizzo di posta. Segui le indicazioni per completare la procedura di recupero password",
  "Return to login": "Torna alla login",
  errorOccurred:
    "Si e' verificato un errore... ci dispiace per l'inconveniente",
  "Verify email address": "Verifica indirizzo email",
  "Your email %%address%% is not verified, check the mailbox (and the Spam folder)":
    "La tua email %%address%% non e' verificata, controlla la casella di posta (e la cartella Spam)",
  "Resend email": "Rispedisci email",
  Center: "Centra"
};
