import * as Actions from "../../actions/customersPage/index";

const initialState = {
  selectedPoi: false,
  orphanPoiId: null,
  orphanPoiAddress: null
};

const selectedPoi = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SELECTED_POI: {
      return {
        ...state,
        selectedPoi: action.poi
      };
    }
    case Actions.SET_ORPHAN_ID: {
      return {
        ...state,
        orphanPoiId: action.poiId
      };
    }
    case Actions.SET_ORPHAN_ADDRESS: {
      return {
        ...state,
        orphanPoiAddress: action.address
      };
    }
    case Actions.RESET_ACTION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default selectedPoi;
