import { authRoles } from "auth";
import ResourcesPage from "./ResourcesPage";

export const ResourcesConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.staff, //['admin','staff']
  routes: [
    {
      path: "/resources",
      component: ResourcesPage
    }
  ]
};
