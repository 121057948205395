import * as Actions from "../../actions/customersPage/index";

const initialState = {
  showContactsModal: false,
  showNoteModal: false,
  showPoiModal: false
};

const showContactModal = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SHOW_CONTACT_MODAL: {
      return {
        ...state,
        showContactsModal: !state.showContactsModal
      };
    }
    case Actions.SHOW_NOTE_MODAL: {
      return {
        ...state,
        showNoteModal: !state.showNoteModal
      };
    }
    case Actions.SHOW_POI_MODAL: {
      return {
        ...state,
        showPoiModal: !state.showPoiModal
      };
    }
    case Actions.RESET_ACTION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default showContactModal;
