import React from "react";
import { withStyles } from "@material-ui/core/styles";
import checkAccountPermission from "main/common/account/accountEditionConfig";
import { stringTranslate } from "languages/OMTranslate";
import columnConfig from "./columnConfig";
import HeaderCell from "./HeaderCell";
import ScrollbarSize from "react-scrollbar-size";

const style = theme => ({
  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "48px",
    minHeight: "48px",
    marginLeft: "5px",
    borderBottomWidth: "1px",
    borderBottomColor: "#e0e0e0",
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.dark,
    fontSize: theme.text.medium,
    fontWeight: 300
  }
});

class TableHeader extends React.Component {
  state = {
    scrollbarWidth: 0
  };

  scrollbarSizeLoad = measurements => {
    this.setState({ scrollbarWidth: measurements.scrollbarWidth });
  };

  scrollbarSizeChange = measurements => {
    this.setState({ scrollbarWidth: measurements.scrollbarWidth });
  };

  render() {
    const isWhereVisible = checkAccountPermission(
      this.props.account,
      "taskList",
      "where"
    );

    return (
      <div
        className={this.props.classes.tableRow}
        style={{ marginRight: this.state.scrollbarWidth }}
      >
        <ScrollbarSize
          onLoad={this.scrollbarSizeLoad}
          onChange={this.scrollbarSizeChange}
        />
        <HeaderCell
          {...this.props}
          column="1"
          id="executionOrder"
          label={stringTranslate("tasks", "executionOrder")}
        />
        <HeaderCell
          {...this.props}
          column="2"
          id="code"
          label={stringTranslate("tasks", "code")}
        />
        <HeaderCell
          {...this.props}
          column={isWhereVisible ? "3" : "3L"}
          id="description"
          label={stringTranslate("tasks", "description")}
        />
        <HeaderCell
          {...this.props}
          column="4"
          id="resourceName"
          label={stringTranslate("tasks", "resource")}
        />
        <HeaderCell
          {...this.props}
          column="5"
          id="beginning"
          label={stringTranslate("tasks", "when")}
        />
        {isWhereVisible ? (
          <HeaderCell
            {...this.props}
            column="6"
            id="where"
            label={stringTranslate("tasks", "where")}
          />
        ) : null}
        {isWhereVisible ? (
          <HeaderCell
            {...this.props}
            column="7"
            id="distance"
            label={stringTranslate("tasks", "distance")}
            style={{ flexDirection: "row-reverse", textAlign: "right" }}
          />
        ) : null}
        <HeaderCell
          {...this.props}
          column="8"
          id="executionStatus"
          label={stringTranslate("tasks", "status")}
        />
        <div style={{ width: columnConfig["9"] }}> </div>
      </div>
    );
  }
}
export default withStyles(style, { withTheme: true })(TableHeader);
