import { Error404PageConfig } from "./errors/404/Error404PageConfig";
import { Error500PageConfig } from "./errors/500/Error500PageConfig";
import { NotAvaiablePageConfig } from "./errors/NotAvaiable/NotAvaiablePageConfig";
import { AuthPageConfig } from "./authPage/AuthPageConfig";
import { LoadAccountPageConfig } from "./loading/LoadAccountPageConfig";

export const pagesConfigs = [
  Error404PageConfig,
  Error500PageConfig,
  NotAvaiablePageConfig,
  AuthPageConfig,
  LoadAccountPageConfig
];
