import React, { Component } from "react";
import BroadcastRoomList from "./BroadcastRoomList";
import BroadcastList from "./BroadcastList";
import BroadcastInput from "./BroadcastInput";
import firebase, { getFirestoreCollection } from "../../../../firestoreAccount";
import { CircularProgress } from "@material-ui/core";

class Broadcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      broadcast: [],
      selectedBroadcast: null,
      broadcastInput: false,
      roomList: false,
      broadcastList: false
    };
    this.unsubscribe = null;
  }

  componentDidMount() {
    this.unsubscribe = firebase
      .firestore()
      .collection(getFirestoreCollection() + "/broadcastChat")
      .where("userId", "==", this.props.user.uid)
      .onSnapshot(querySnapshot => {
        const broadcast = [];
        let empty = true;
        if (!querySnapshot.empty) {
          empty = false;
          querySnapshot.forEach(doc => {
            broadcast.push({
              id: doc.id,
              userId: doc.data().userId,
              name: doc.data().name,
              chatRooms: doc.data().chatRooms
            });
          });
        }
        if (empty) {
          this.setState({ broadcast, roomList: true });
        } else {
          this.setState({ broadcast, broadcastList: true });
        }
      });
  }

  handleToggle = roomId => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(roomId);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(roomId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({ checked: newChecked });
  };

  showInput = selectedBroadcast => {
    if (selectedBroadcast) {
      this.setState({
        broadcastInput: true,
        roomList: false,
        broadcastList: false,
        selectedBroadcast,
        checked: selectedBroadcast.chatRooms
      });
    } else {
      this.setState({
        broadcastInput: true,
        roomList: false,
        broadcastList: false,
        selectedBroadcast: null
      });
    }
  };

  showNewBroadcast = () => {
    this.setState({
      broadcastInput: false,
      roomList: true,
      broadcastList: false,
      checked: []
    });
  };

  broadcastHome = () => {
    if (this.state.broadcast && this.state.broadcast.length > 0) {
      this.setState({
        broadcastInput: false,
        roomList: false,
        broadcastList: true
      });
    } else {
      this.setState({
        broadcastInput: false,
        roomList: true,
        broadcastList: false
      });
    }
  };

  render() {
    if (
      !this.state.broadcastInput &&
      !this.state.roomList &&
      !this.state.broadcastList
    ) {
      return (
        <div
          style={{
            paddingTop: "100%",
            paddingLeft: "40%"
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      );
    }

    return (
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        {this.state.broadcastInput ? (
          <BroadcastInput
            goBack={this.broadcastHome}
            user={this.props.user}
            resources={this.props.resources}
            chatRooms={this.props.chatRooms}
            checked={this.state.checked}
            selectedBroadcast={this.state.selectedBroadcast}
          />
        ) : null}

        {this.state.roomList ? (
          <BroadcastRoomList
            goBack={this.props.goBack}
            chatRooms={this.props.chatRooms}
            user={this.props.user}
            resources={this.props.resources}
            showInput={this.showInput}
            checked={this.state.checked}
            handleToggle={this.handleToggle}
          />
        ) : null}

        {this.state.broadcastList ? (
          <BroadcastList
            add={this.showNewBroadcast}
            goBack={this.props.goBack}
            chatRooms={this.props.chatRooms}
            user={this.props.user}
            resources={this.props.resources}
            showInput={this.showInput}
            broadcast={this.state.broadcast}
          />
        ) : null}
      </div>
    );
  }
}

export default Broadcast;
