export default {
  back: "Back",
  close: "Close",
  last: "Last",
  next: "Next",
  skip: "Skip",
  step1: "Welcome to OverMover Quick Tour! The tour requires only few minutes.",
  step2: "These icons allows to show/hide various Panels of the main console.",
  step3: "The Scheduler Icon allows to show/hide the Scheduler Panel",
  step4: "The Map Icon allows to show/hide the Map Panel.",
  step5: "The Chat Icon allows to show Chat Panel...",
  step6:
    "Here you can send messages to the resources. If you want to close this panel, click the button on the left again",
  step7:
    "The Map Panel shows the position and status (free/busy) of your Mobile Workers.",
  step8: "You can click on the Worker Marker to see more details.",
  step9: "The Tasks Panel show which Tasks are assigned to which Worker",
  step10: "And shows the executions details.",
  step11: "The Scheduler Panel shows the planning of tasks across workers.",
  step12: "And allows you to organise and plan everybody’s work.",
  step13:
    "With the Chat Panel you can exchanges messages and images with your resources on the field.",
  step14:
    "By clicking on this symbol (“+”) you can quickly add a new mobile resource.",
  step15:
    "Just fill in the resource name and phone number and click on “SAVE”.",
  step16:
    "Your resource will receive an SMS invite, with instructions to install the Worker App on his/her mobile phone.",
  step17:
    "After the Worker installs the Worker App, you will be notified and can start organise work.",
  step18:
    "Your account contains some demo data to help you see the power of OverMover.",
  step19:
    "The Demo Data can be removed once you start to invite your Resources. To remove them go to Settings -> Delete demo data",
  step20: "Congratulations! You completed the Quick Tour!",
  resourceBtn:
    "Start by sending an invite to your Resources to install the Worker App on smartphones."
};
