import React from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { Typography } from "@material-ui/core";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { FuseAnimate } from "@fuse";
import BlockIcon from "@material-ui/icons/Block";
import { stringTranslate } from "languages/OMTranslate";

const styles = theme => ({
  root: {
    backgroundColor: "white"
  },
  searchWrapper: {
    width: "100%",
    height: 56,
    padding: 18,
    display: "flex",
    alignItems: "center"
  },
  search: {
    paddingLeft: 16
  },
  largeIcon: {
    fontSize: 120,
    color: theme.palette.primary.main
  },
  logoIcon: { width: 200 }
});

const OfflinePage = ({ classes }) => {
  return (
    <div
      className={classNames(
        classes.root,
        "flex flex-col flex-1 items-center justify-center p-16"
      )}
    >
      <div className="max-w-512 text-center flex flex-col items-center justify-center">
        <FuseAnimate delay={500}>
          <img
            className={classNames(classes.logoIcon)}
            src="assets/images/logos/OverMover.svg"
            alt="logo"
          />
        </FuseAnimate>
        <FuseAnimate animation="transition.expandIn" delay={100}>
          <BlockIcon color="textSecondary" className={classes.largeIcon} />
        </FuseAnimate>

        <FuseAnimate delay={500}>
          <Typography variant="h3" color="textSecondary" className="m-16">
            {stringTranslate("general", "offlineStatus")}
          </Typography>
        </FuseAnimate>

        <Link className="font-medium" to="/">
          {stringTranslate("general", "reconnectAttemptLink")}
        </Link>
      </div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(OfflinePage);
