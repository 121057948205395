import React from "react";
import { Typography } from "@material-ui/core";

const ReportTitle = ({ title, theme, icon, children }) => {
  return (
    <div
      style={{
        flex: "0 0 auto",
        display: "flex",
        flexDirection: "row",
        padding: theme.measures.panelPadding
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: "0 0 auto",
            borderBottom: theme.panels.border
          }}
        >
          {icon ? icon : null}
          <Typography variant="h3" className="px-8 py-4" color="primary">
            {title}
          </Typography>
        </div>
      </div>
      {children ? children : null}
    </div>
  );
};
export default ReportTitle;
