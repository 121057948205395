import { combineReducers } from "redux";
import selectedCustomer from "./selectedCustomer.reducer";
import selectedContact from "./selectedContact.reducer";
import selectedNote from "./selectedNote.reducer";
import selectedPoi from "./selectedPoi.reducer";
import contactsTab from "./contactsTab.reducer";

const customersPage = combineReducers({
  selectedCustomer,
  selectedContact,
  selectedNote,
  selectedPoi,
  contactsTab
});

export default customersPage;
