export function getTaskColor(task) {
  let color = "";
  switch (task.executionStatus) {
    case "TOEXECUTE":
      color = "#0091ae";
      break;
    case "COMPLETED":
      color = "#Bfbdc6";
      break;
    case "SUSPENDED":
      color = "#Bfbdc6";
      break;
    case "ABORTED":
    case "REJECTED":
      color = "#BE3D56";
      break;

    default:
      color = "#ffa000";
      break;
  }

  return color;
}

export function getTaskForegroundColor(task) {
  let color = "";
  switch (task.executionStatus) {
    case "TOEXECUTE":
    case "COMPLETED":
    case "SUSPENDED":
    case "ABORTED":
    case "REJECTED":
      color = "#FFFFFF";
      break;

    default:
      color = "#33475b";
      break;
  }

  return color;
}
