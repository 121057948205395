import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { withStyles } from "@material-ui/core/styles";
import { Button, Snackbar } from "@material-ui/core";
import { stringTranslate } from "languages/OMTranslate";

import OMDeleteConfirmationAlert from "../../common/OMDeleteConfirmationAlert";
import SelectCustomForm from "../components/SelectCustomForm";
import ModuleView from "./Module";

import { getFirestoreCollection } from "../../../../firestoreAccount";
import { FieldTypes } from "../ModulesConfig";

const styles = theme => ({
  container: {
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    flex: 1,
    height: "100%"
  },
  contentSelectCustomForm: {
    marginBottom: "20px"
  },
  contentActions: {
    display: "flex",
    paddingTop: theme.spacing.unit * 3
  }
});

const defaultValueFromType = type => {
  switch (type) {
    case FieldTypes.text:
      return "";
    case FieldTypes.area:
      return "";
    case FieldTypes.radio:
      return "";
    case FieldTypes.check:
      return [];
    case FieldTypes.dropdown:
      return "";
    case FieldTypes.date:
      return {
        seconds: 0
      };
    case FieldTypes.time:
      return {
        seconds: 0
      };
    default:
      return undefined;
  }
};

/**
 * Crea un oggetto modulo con i campi indicati dal custom form
 * con i relativi valori di default
 * @param {Object} customForm oggetto custom form
 */
const generateEmptyModuleFromCustomForm = customForm => {
  let module = {};
  customForm.fields.forEach(field => {
    module = {
      ...module,
      [field.name]: defaultValueFromType(field.type)
    };
  });
  return module;
};

/**
 * Genera un oggetto con lo stato iniziale del component
 * Viene impostato che è possibile modificare il tipo di custom form
 * solo se non è stato passato un modulo o un custom form selezionato.
 * Se viene indicato un modulo i valore del parametro selectedCustomForm
 * viene ignorato
 * @param {Object} module oggetto modulo
 * @param {Array} customForms lista di custom forms
 * @param {Object} selectedCustomForm oggetto custom form indicato come selezionato
 */
const initState = (
  customForms,
  module = undefined,
  selectedCustomForm = undefined
) => {
  const base = {
    // Oggetto le cui proprietà corrispondono al nome del campo errato
    // e il valore il messaggio di erroe
    errors: {},
    snackbar: {
      open: false,
      message: ""
    },
    dialogConfirm: {
      open: false,
      callback: undefined
    }
  };
  if (module === undefined) {
    if (selectedCustomForm !== undefined) {
      return {
        ...base,
        module: generateEmptyModuleFromCustomForm(selectedCustomForm),
        selectedCustomForm,
        disableChangeForm: true
      };
    }
    return {
      ...base,
      module: generateEmptyModuleFromCustomForm(customForms[0]),
      selectedCustomForm: customForms[0],
      disableChangeForm: false
    };
  }
  const customFormModule = customForms.find(f => f.id === module.customFormId);
  if (customFormModule === undefined) {
    return {
      ...base,
      module: undefined,
      selectedCustomForm: customForms[0],
      disableChangeForm: true
    };
  }
  return {
    ...base,
    module,
    selectedCustomForm: customFormModule,
    disableChangeForm: true
  };
};

class ModuleForm extends React.Component {
  state = initState(
    this.props.customForms,
    this.props.module,
    this.props.selectedCustomForm
  );

  componentDidUpdate(prevProps) {
    if (this.props.module === undefined && prevProps.module === undefined) {
      return;
    }
    if (
      (this.props.module !== undefined && prevProps.module === undefined) ||
      (this.props.module === undefined && prevProps.module !== undefined) ||
      this.props.module.id !== prevProps.module.id
    ) {
      this.setState(
        initState(
          this.props.customForms,
          this.props.module,
          this.props.selectedCustomForm
        )
      );
    }
  }

  showSnackbar = (message, action = undefined) => {
    this.setState({ snackbar: { open: true, message, action } });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState(state => ({
      snackbar: { ...state.snackbar, open: false }
    }));
  };

  toggleShowDialogConfirm = (callback = () => {}) => {
    this.setState(state => ({
      dialogConfirm: {
        open: !state.dialogConfirm.open,
        callback
      }
    }));
  };

  onFieldChange = field => value => {
    const { isPreview } = this.props;
    if (isPreview) {
      return;
    }
    this.setState(state => {
      const { errors } = state;
      delete errors[field.name];
      return {
        module: {
          ...state.module,
          [field.name]: value
        },
        errors
      };
    });
  };

  /**
   * Metodo richiamato quando viene modificato il tipo di custom form.
   * La modifica del tipo di custom form causa la renderizzazione dei
   * relativi campi
   */
  onCustomFormChange = customForm => {
    this.setState({
      selectedCustomForm: customForm,
      module: generateEmptyModuleFromCustomForm(customForm)
    });
  };

  /**
   * Metodo richiamato quando viene cliccato il pulsante salva
   * Vengono controllati se i valori dei campi sono corretti e
   * viene eseguito il salvataggio su firestore
   */
  onSave = () => {
    const { module, selectedCustomForm } = this.state;
    const { task, party } = this.props;
    let errors = {};
    selectedCustomForm.fields.forEach(field => {
      if (field.required) {
        if (
          module[field.name] === undefined ||
          module[field.name] === null ||
          module[field.name] === "" ||
          module[field.name].length === 0
        ) {
          errors = {
            ...errors,
            [field.name]: stringTranslate("modules", "fieldRequired")
          };
          return;
        }
        if (field.type === FieldTypes.check) {
          if (
            module[field.name].find(
              v => field.options.find(o => o.name === v) === undefined
            ) !== undefined
          ) {
            errors = {
              ...errors,
              [field.name]: stringTranslate("modules", "fieldRequired")
            };
            return;
          }
        }
        if (
          field.type === FieldTypes.dropdown ||
          field.type === FieldTypes.radio
        ) {
          if (
            field.options.find(o => o.name === module[field.name]) === undefined
          ) {
            errors = {
              ...errors,
              [field.name]: stringTranslate("modules", "fieldRequired")
            };
            return;
          }
        }
        if (field.type === FieldTypes.date || field.type === FieldTypes.time) {
          if (
            Object.prototype.toString.call(module[field.name]) !==
              "[object Date]" &&
            (module[field.name].seconds === undefined ||
              module[field.name].seconds === null ||
              module[field.name].seconds <= 0)
          ) {
            errors = {
              ...errors,
              [field.name]: stringTranslate("modules", "fieldRequired")
            };
          }
        }
      }
    });
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors
      });
      this.showSnackbar(stringTranslate("modules", "errorInvalidFields"));
      return;
    }
    this.saveModuleAsync({
      customFormId: selectedCustomForm.id,
      taskId: task ? task.id : "",
      partyId: party ? party.id : "",
      ...module
    });
  };

  onDelete = () => {
    const { onDeleted } = this.props;
    const { module } = this.state;
    this.toggleShowDialogConfirm(async () => {
      await this.deleteModuleAsync(module);
      onDeleted();
    });
  };

  /**
   * Salva in firestore il modulo su firestore
   * Al termine del salvataggio viene aggiornato lo stato
   * con l'id dell'oggetto creato
   * @param {Object} module oggetto con le informazioni del modulo
   */
  saveModuleAsync = async module => {
    const { firestore } = this.props;
    let savedModule = module;
    try {
      if (module.id !== undefined && module.id !== "") {
        await firestore
          .collection(`${getFirestoreCollection()}/modules`)
          .doc(module.id)
          .update(module);
      } else {
        savedModule = await firestore
          .collection(`${getFirestoreCollection()}/modules`)
          .add({
            ...module,
            createdAt: new Date()
          });
      }
    } catch (e) {
      console.log("error save module", e);
      this.showSnackbar(stringTranslate("modules", "errorSaveCustomFrom"));
      return;
    }
    this.setState({
      module: {
        id: savedModule.id,
        ...module
      },
      disableChangeForm: true
    });
    this.showSnackbar(stringTranslate("modules", "moduleSaved"));
  };

  /**
   * Cancella il modulo da firestore
   * @param {Object} module oggetto modulo
   */
  deleteModuleAsync = async module => {
    const { firestore } = this.props;
    try {
      await firestore
        .collection(`${getFirestoreCollection()}/modules`)
        .doc(module.id)
        .delete();
    } catch (e) {
      console.log("error delete module", e);
      this.showSnackbar(stringTranslate("modules", "errorDeleteCustomFrom"));
    }
  };

  render() {
    const { task, party, isPreview, customForms, classes } = this.props;
    const {
      module,
      selectedCustomForm,
      disableChangeForm,
      errors,
      snackbar,
      dialogConfirm
    } = this.state;
    return (
      <div className={classes.container}>
        {!disableChangeForm && (
          <div className={classes.contentSelectCustomForm}>
            <SelectCustomForm
              customForms={customForms}
              selected={selectedCustomForm}
              onChange={this.onCustomFormChange}
              // disabled={disableChangeForm}
            />
          </div>
        )}
        <ModuleView
          customForm={selectedCustomForm}
          module={module}
          task={task}
          party={party}
          errors={errors}
          onFieldChange={this.onFieldChange}
        />
        {!isPreview && (
          <div className={classes.contentActions}>
            <Button
              aria-label="Check"
              variant="contained"
              color="secondary"
              className="mr-12"
              onClick={this.onSave}
            >
              {stringTranslate("general", "Save")}
            </Button>
            {module && module.id && (
              <Button
                onClick={this.onDelete}
                aria-label="Clear"
                variant="contained"
                color="primary"
                className="mr-12"
              >
                {stringTranslate("general", "Delete")}
              </Button>
            )}
          </div>
        )}
        <OMDeleteConfirmationAlert
          isOpen={dialogConfirm.open}
          deleteHandler={() => {
            this.toggleShowDialogConfirm();
            dialogConfirm.callback();
          }}
          cancelHandler={() => this.toggleShowDialogConfirm()}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          message={snackbar.message}
          action={
            snackbar.action && [
              <Button
                key="undo"
                color="secondary"
                size="small"
                onClick={snackbar.action}
              >
                {stringTranslate("modules", "undo")}
              </Button>
            ]
          }
        />
      </div>
    );
  }
}

ModuleForm.propTypes = {
  task: PropTypes.shape({
    description: PropTypes.string.isRequired
  }),
  party: PropTypes.shape({
    name: PropTypes.string
  }),
  customForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          required: PropTypes.bool.isRequired,
          position: PropTypes.number.isRequired,
          help: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              label: PropTypes.string
            })
          )
        }).isRequired
      ).isRequired
    }).isRequired
  ).isRequired,
  module: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  // Questo valore viene ignorato se viene indicato un modulo
  selectedCustomForm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        position: PropTypes.number.isRequired,
        help: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string
          })
        )
      }).isRequired
    ).isRequired
  }),
  isPreview: PropTypes.bool,
  onDeleted: PropTypes.func, // Richiamato dopo la cancellazione del modulo
  classes: PropTypes.shape().isRequired // Oggetto creato da withStyles
};

ModuleForm.defaultProps = {
  task: undefined,
  party: undefined,
  isPreview: false,
  selectedCustomForm: undefined,
  module: undefined,
  onDeleted: () => {}
};

export default compose(
  firestoreConnect(),
  withStyles(styles)
)(ModuleForm);
