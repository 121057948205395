import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import Modal from "@material-ui/core/Modal";
import { setSelectedNote, toggleNoteModal } from "store/actions/customersPage";
import OMTabSearchAddBar from "../common/OMTabSearchAddBar";
import moment from "moment";
import NoteFormModal from "./NoteFormModal";
import NotesList from "./NotesList";

const styles = theme => ({
  root: {
    padding: theme.measures.panelPadding,
    width: "100%"
  }
});

class NotesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ""
    };
  }
  setSearchText = event => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };

  render() {
    const {
      toggleNoteModal,
      setSelectedNote,
      showNoteModal,
      selectedCustomer,
      selectedNote,
      notes
    } = this.props;
    const { searchText } = this.state;

    var filteredNotes = null;
    if (notes) {
      filteredNotes = notes.map(item => Object.assign({}, item));

      filteredNotes = filteredNotes.filter(note => {
        var thisParty = false;
        if (note.partyId === selectedCustomer.id) thisParty = true;
        else thisParty = false;
        var found = false;
        if (thisParty) {
          if (
            note.description.toLowerCase().search(searchText.toLowerCase()) > -1
          ) {
            found = true;
          }
        }
        return found;
      });
    }

    if (filteredNotes) {
      filteredNotes.forEach(function(note) {
        var tmpdate = moment.unix(note.date.seconds);
        note.dateFormatted = tmpdate.format("L");
      });

      filteredNotes.sort(function(x, y) {
        if (x.date.seconds < y.date.seconds) {
          return -1;
        }
        if (x.date.seconds > y.date.seconds) {
          return 1;
        }
        if (x.description.toLowerCase() < y.description.toLowerCase()) {
          return -1;
        }
        if (x.description.toLowerCase() > y.description.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    }

    return (
      <React.Fragment>
        <OMTabSearchAddBar
          textChangeHandler={this.setSearchText}
          textClearHandler={() => {
            this.setState({ searchText: "" });
          }}
          addClickHandler={() => {
            setSelectedNote(null);
            toggleNoteModal();
          }}
          searchText={searchText}
          withAdd={!Boolean(this.props.hideAdd)}
        />

        <NotesList filteredNotes={filteredNotes} />

        <Modal open={showNoteModal} onBackdropClick={() => toggleNoteModal()}>
          <NoteFormModal selectedNote={selectedNote} />
        </Modal>
      </React.Fragment>
    );
  }
}

NotesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  showNoteModal: state.customersPage.contactsTab.showNoteModal,
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer,
  selectedNote: state.customersPage.selectedNote.selectedNote,
  notes: state.firestore.ordered.notes
});

const mapDispatchToProps = dispatch => ({
  setSelectedNote: contact => dispatch(setSelectedNote(contact)),
  toggleNoteModal: () => dispatch(toggleNoteModal())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(NotesTab);
