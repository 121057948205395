import React from "react";
import { withStyles } from "@material-ui/core";
import AccountButtonMenu from "./AccountButtonMenu";
import AccountSubscriptionData from "./AccountSubscriptionData";

const styles = theme => ({
  root: {
    position: "relative",
    display: "flex",
    flexFlow: "row nowrap",
    height: "calc(100vh - " + theme.measures.navbarHeight + ")",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      flexFlow: "column nowrap"
    }
  }
});

const AccountSubscriptionPage = ({ classes }) => {
  return (
    <div className={classes.root} id="hContainer">
      <AccountButtonMenu />
      <AccountSubscriptionData />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(AccountSubscriptionPage);
