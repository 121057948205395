export const fuseThemesConfig = {
  beach: {
    palette: {
      type: "light",
      primary: {
        light: "#c4d8dd",
        main: "#9591a0",
        dark: "#33475b",
        darker: "#1F354B",
        darkest: "#1A2C3E",

        contrastText: "#f2f7f6",
        lightGrey: "#e9e9ec",
        mainGray: "#d4d3d9",
        background: "#f7f7f7",

        border: "#d4d3d9",
        panelBorder: "#f1f1f1",

        grey1: "#e9e9ec",
        grey2: "#d4d3d9",
        grey3: "#bfbdc6",
        white: "#ffffff"
      },
      secondary: {
        light: "#ffb281",
        main: "#ff7b59",
        dark: "#ba5228",
        contrastText: "#fff"
      },
      activity: {
        busy: "#be3d56",
        free: "#35a897",
        offline: "#8795a1"
      },
      delay: {
        strong: "rgba(230,0,0,0.7)",
        small: "rgba(255,200,0,0.7)"
      }
    },
    text: {
      small: "12px",
      medium: "14px",
      big: "18px"
    },
    measures: {
      mainPageShouldersWidth: "250px",
      chatShouldersWidth: "270px",
      standardBreakpoint: "xs",
      nextBreakpoint: "sm",
      panelPadding: "10px",
      formMaxWidth: "700px",
      reportMaxWidth: "950px",
      navbarHeight: "48px",
      panelBorderWidth: "10px"
    },
    panels: {
      backgroundColor: "#FFFFFF",
      border: "1px solid #d9d9d9",
      padding: "0px"
    },
    addButton: {
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)"
    }
  }
};

//  standardBreakpoint: "xs"
