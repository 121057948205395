import React from "react";
import { stringTranslate } from "languages/OMTranslate";
import { Typography } from "@material-ui/core";

const EmptyReport = () => {
  return (
    <Typography variant="h3">
      {stringTranslate("reports", "emptyReport")}
    </Typography>
  );
};
export default EmptyReport;
