import firebase from "firebase";
import "firebase/firestore";
import config from "./firebaseService/firebaseServiceConfig";

firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });

export default firebase;

export function getFirestoreCollection() {
  var url = "accounts/" + localStorage.getItem("accountId");
  return url;
}

export function setFirestoreCollection(accountId) {
  localStorage.setItem("accountId", accountId);
}
