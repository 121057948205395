import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "redux";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { withStyles, ListItem, Typography } from "@material-ui/core";
import moment from "moment";

const styles = theme => ({
  listContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: "10px",
    borderBottom: "1px solid " + theme.palette.divider
  },
  left: {
    display: "flex",
    flexDirection: "row",
    flex: 6,
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: 0
  },
  right: {
    display: "flex",
    flexDirection: "row",
    flex: 0.5,
    justifyContent: "flex-end",
    alignItems: "center"
  },
  chatContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  timeContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    alignSelf: "flex-end"
  },
  lastMessage: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "130px",
    paddingRight: "5px"
  }
});

class ChatRoomListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromNow: moment.unix(props.selectedRoom.lastUpdate.seconds).fromNow(true)
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.updateTime(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateTime = () => {
    this.setState({
      fromNow: moment
        .unix(this.props.selectedRoom.lastUpdate.seconds)
        .fromNow(true)
    });
  };

  render() {
    const { resourceId, lastMessage } = this.props.selectedRoom;
    const { classes } = this.props;
    const name = this.props.resources
      ? this.props.resources[resourceId].name
      : "";
    return (
      <div>
        <ListItem
          button
          className={classes.listContainer}
          disableGutters
          onClick={() => this.props.selectChatHandler(this.props.selectedRoom)}
        >
          <div className={classes.left}>
            <div className={classes.chatContainer}>
              <Typography variant="body1">{name}</Typography>
              <Typography variant="caption" className={classes.lastMessage}>
                {lastMessage}
              </Typography>
            </div>
            <div className={classes.timeContainer}>
              <Typography variant="caption">{this.state.fromNow}</Typography>
            </div>
          </div>
          <div className={classes.right}>
            <KeyboardArrowRightIcon />
          </div>
        </ListItem>
      </div>
    );
  }
}

ChatRoomListItem.propTypes = {
  resources: PropTypes.object
};

const mapStateToProps = state => ({
  resources: state.firestore.data.resources
});

export default compose(connect(mapStateToProps))(
  withStyles(styles, { withTheme: true })(ChatRoomListItem)
);
