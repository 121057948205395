import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate } from "languages/OMTranslate";
import {
  toggleContactModal,
  setSelectedContact
} from "store/actions/customersPage";
import CloseIcon from "@material-ui/icons/Close";
import LiveHelp from "@material-ui/icons/LiveHelp";
import { deviceDetect } from "react-device-detect";
import moment from "moment";
import { TextFieldFormsy } from "@fuse";
import Formsy from "formsy-react";
import { Button, IconButton, Typography, Modal, Grid } from "@material-ui/core";
import { software_version } from "software_version";
import firebase from "firestoreAccount";
import HamburgerMenuItem from "./HamburgerMenuItem";

const styles = theme => ({
  root: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    width: "80%",
    height: "80%",
    top: "calc( 10vh )",
    left: "calc( 10vw )",
    display: "flex",
    flexDirection: "column"
  },
  container: {
    flex: "1 1 auto"
  },
  textField: {
    width: "100%"
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px"
  },
  version: {
    fontSize: "10px",
    textAlign: "right",
    color: theme.palette.primary.mainGray,
    flex: "0 0 auto"
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  },
  listItemText: {
    root: {
      paddingRight: 0
    },
    primary: {
      fontSize: theme.text.medium
    }
  },
  submenu: {
    borderTop: "1px solid " + theme.palette.primary.panelBorder,
    backgroundColor: theme.palette.primary.background,
    borderBottom: "1px solid " + theme.palette.primary.panelBorder
  }
});

class MobileHelpdeskModal extends Component {
  state = {
    message: "",
    dialogOpen: false,
    canSubmit: false,
    onSaving: false,
    onSaved: false
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  handleSubmit() {
    const { firestore, user } = this.props;
    const { message } = this.state;
    if (message) {
      this.setState({
        onSaving: true
      });
      var helpdesk = firestore.collection("/helpdesk_messages").doc();
      helpdesk
        .set({
          from: "WEBAPP",
          userId: user.uid,
          userDisplayName: user.data.displayName,
          userEmail: user.data.email,
          accountId: user.accountId,
          message: message,
          status: "NEW",
          deviceInfo: deviceDetect(),
          timestamp: moment().toDate()
        })
        .then(() => {
          this.setState({
            onSaving: false,
            onSaved: true
          });
          setTimeout(
            function() {
              this.setState({
                message: "",
                dialogOpen: false,
                canSubmit: false,
                onSaving: false,
                onSaved: false
              });
            }.bind(this),
            3000
          );
        });
    }
  }

  helpdeskClick = event => {
    this.setState({ dialogOpen: !this.state.dialogOpen, helpMenu: null });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  tourClick = event => {
    if (!this.props.user.showGuidedTour) {
      let index = 0;
      if (this.props.user.stepIndex < 16) {
        index = this.props.user.stepIndex;
      }
      firebase
        .firestore()
        .collection("users")
        .doc(this.props.user.uid)
        .update({ showGuidedTour: true, stepIndex: index })
        .then(result => {
          this.setState({ helpMenu: null }, () => {
            window.location.reload();
          });
        });
    }
  };

  helpMenuClose = () => {
    this.setState({ helpMenu: null });
  };

  helpMenuClick = event => {
    this.setState({ helpMenu: event.currentTarget });
  };

  render() {
    const { classes, theme } = this.props;
    const { message, onSaving, onSaved, canSubmit } = this.state;

    return (
      <React.Fragment>
        <HamburgerMenuItem
          to="#"
          onClick={this.helpdeskClick}
          label={stringTranslate("toolbar", "help")}
        >
          <LiveHelp
            className="flex-no-shrink flex-no-grow"
            style={{ color: theme.palette.primary.dark }}
          />
        </HamburgerMenuItem>

        <Modal
          open={this.state.dialogOpen}
          onBackdropClick={this.helpdeskClick}
        >
          <div className={classes.root}>
            <Formsy
              onValidSubmit={this.onSubmit}
              onValid={this.enableButton}
              onInvalid={this.disableButton}
              ref={form => (this.form = form)}
              className={classes.container}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <Typography variant="h3">
                    {stringTranslate("toolbar", "Help request")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    {stringTranslate(
                      "toolbar",
                      "Do you need assistance? Send us a quick message and we will answer immediately."
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextFieldFormsy
                    id="message"
                    name="message"
                    label={stringTranslate("toolbar", "Message")}
                    className={classes.textField}
                    value={message}
                    multiline
                    rows="6"
                    onChange={this.handleChange("message")}
                    margin="normal"
                    required
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!onSaved ? (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="mr-12"
                        onClick={() => this.handleSubmit()}
                        disabled={onSaving || !canSubmit}
                      >
                        {stringTranslate("general", "Send")}
                      </Button>
                      {onSaving && stringTranslate("toolbar", "onSending")}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        color="primary"
                        className="mr-12"
                        onClick={this.helpdeskClick}
                      >
                        {stringTranslate("general", "Close")}
                      </Button>
                      {onSaved && stringTranslate("toolbar", "onSent")}
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
              <IconButton
                className={classes.closeButton}
                onClick={this.helpdeskClick}
              >
                <CloseIcon />
              </IconButton>
            </Formsy>
            <Typography variant="caption" className={classes.version}>
              {software_version}
            </Typography>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

MobileHelpdeskModal.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

function mapStateToProps({ auth }) {
  return {
    user: auth.user
  };
}

const mapDispatchToProps = dispatch => ({
  setSelectedContact: contact => dispatch(setSelectedContact(contact)),
  toggleContactModal: () => dispatch(toggleContactModal())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(MobileHelpdeskModal);
