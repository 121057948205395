import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate } from "languages/OMTranslate";
import { TextField, Typography } from "@material-ui/core";

const styles = theme => ({
  root: { padding: "24px" },
  header: { display: "flex", flex: "flex-row" },
  title: { flex: "1 1 auto", border: "1px none red", order: "1" },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

class UserModifyFormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.initNewUser()
    };
  }

  initNewUser = () => {
    return {
      displayName: ""
    };
  };

  handleChange = name => event => {
    const { user } = this.state;
    this.setState({ user: { ...user, [name]: event.target.value } });
  };

  onSubmit() {}

  render() {
    const { classes, displayName } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.title}>
            <Typography variant="h3">
              {stringTranslate("users", "Modify User")}
            </Typography>
          </div>
        </div>
        <div className={classes.header}>
          <form
            onSubmit={this.onSubmit}
            className={classes.container}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="displayName"
                label={stringTranslate("users", "displayName")}
                className={classes.textField}
                value={displayName}
                autoFocus={true}
                fullWidth={true}
                onChange={this.handleChange("displayName")}
                margin="normal"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

UserModifyFormPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  users: state.firestore.data.users
});

const mapDispatchToProps = dispatch => ({});

export default compose(
  firestoreConnect([
    {
      collection: "users",
      where: ["accountId", "==", localStorage.getItem("accountId")],
      storeAs: "users"
    }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(UserModifyFormPage);
