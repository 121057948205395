import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { connect } from "react-redux";
import classNames from "classnames";
import { firestoreConnect } from "react-redux-firebase";
import firebase, { getFirestoreCollection } from "../../../firestoreAccount";
import { stringTranslate } from "languages/OMTranslate";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import {
  togglePoiModal,
  setSelectedPoi,
  setOrphanPoiAddress,
  setOrphanPoiId
} from "store/actions/customersPage";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import $ from "jquery";
import { TextFieldFormsy } from "@fuse";
import Formsy from "formsy-react";
import { Button, IconButton, Typography, Tooltip } from "@material-ui/core";
import moment from "moment";
import ColorPicker from "../../common/ColorPicker";
import CreatableSelect from "react-select/lib/Creatable";
import OMReactSelect from "../common/OMReactSelect";

const styles = theme => ({
  root: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    width: "800px",
    height: "700px",
    top: "calc( 50vh - 350px )",
    left: "calc( 50vw - 400px )",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      width: "80%",
      height: "80%",
      top: "calc( 10vh )",
      left: "calc( 10vw )"
    }
  },
  textField: {
    width: "100%"
  },
  categoryTextField: {
    marginLeft: "16px",
    display: "flex",
    flex: 1
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px"
  },
  addressContainer: {
    display: "flex"
  },
  addressText: {
    flex: "1 1 auto"
  },
  addressIcon: {
    flex: "0 0 auto"
  },
  mapContainer: {
    position: "relative",
    width: "100%",
    height: "300px",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      height: "100px"
    },
    border: "1px solid " + theme.panels.border
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  }
});

class PoiFormModal extends Component {
  constructor(props) {
    super(props);

    let selectedColor = "#f44336";
    let categoryName = "";
    if (
      props.poiCategories &&
      props.poiCategories.length &&
      props.selectedPoi &&
      props.selectedPoi.categoryId
    ) {
      props.poiCategories.forEach(cat => {
        if (cat.id === props.selectedPoi.categoryId) {
          selectedColor = cat.color;
          categoryName = cat.name;
        }
      });
    }

    this.state = {
      name:
        props.selectedPoi && props.selectedPoi.id ? props.selectedPoi.name : "",
      address:
        props.selectedPoi && props.selectedPoi.id
          ? props.selectedPoi.address
          : "",
      latitude:
        props.selectedPoi && props.selectedPoi.id
          ? props.selectedPoi.lat_long.latitude
          : "",
      longitude:
        props.selectedPoi && props.selectedPoi.id
          ? props.selectedPoi.lat_long.longitude
          : "",
      categoryId:
        props.selectedPoi && props.selectedPoi.categoryId
          ? props.selectedPoi.categoryId
          : null,
      categoryName: categoryName,
      color: selectedColor,
      errorName: false,
      dialogOpen: false,
      selectedLat: false,
      selectedLng: false,
      canSubmit: false
    };
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  handleSubmit() {
    const {
      firestore,
      selectedCustomer,
      selectedPoi,
      togglePoiModal,
      setOrphanPoiId,
      setOrphanPoiAddress
    } = this.props;

    const {
      name,
      address,
      longitude,
      latitude,
      categoryId,
      color
    } = this.state;

    if (name) {
      // Per prima cosa viene aggiornata la categoria del poi se sono stati cambiati colore o simbolo
      if (categoryId) {
        firestore
          .collection(getFirestoreCollection() + "/poiCategories")
          .doc(categoryId)
          .update({ color: color })
          .then(() => {
            console.log("Aggiornamento categoria riuscito");
          })
          .catch(error => console.log(error));
      }

      var poi = null;
      if (selectedPoi && selectedPoi.id) {
        poi = firestore
          .collection(getFirestoreCollection() + "/pois")
          .doc(selectedPoi.id);
        poi.set({
          name,
          address: address,
          lat_long: new firestore.GeoPoint(latitude, longitude),
          partyId: selectedCustomer.id,
          isMainAddress:
            selectedPoi && selectedPoi.isMainAddress ? true : false,
          timestamp: moment().toDate(),
          categoryId: categoryId ? categoryId : null
        });
      } else {
        poi = firestore.collection(getFirestoreCollection() + "/pois").doc();
        if (selectedCustomer && selectedCustomer.id) {
          poi.set({
            name,
            address: address,
            lat_long: new firestore.GeoPoint(latitude, longitude),
            partyId: selectedCustomer.id,
            isMainAddress:
              selectedPoi && selectedPoi.isMainAddress ? true : false,
            timestamp: moment().toDate(),
            categoryId: categoryId ? categoryId : null
          });
        } else {
          poi.set({
            name,
            address: address,
            lat_long: new firestore.GeoPoint(latitude, longitude),
            isMainAddress:
              selectedPoi && selectedPoi.isMainAddress ? true : false,
            timestamp: moment().toDate(),
            categoryId: categoryId ? categoryId : null
          });
          setOrphanPoiId(poi.id);
          setOrphanPoiAddress(address);
        }
      }
      togglePoiModal();
    } else {
      this.setState({
        errorName: true
      });
    }
  }

  centerLatLong = () => {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        latLng: new window.google.maps.LatLng(
          this.state.latitude,
          this.state.longitude
        )
      },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            this.setAddress(
              results[0].formatted_address,
              this.state.latitude,
              this.state.longitude
            );

            this.markerRef.setVisible(false);
            this.mapRef.setCenter(
              new window.google.maps.LatLng(
                this.state.latitude,
                this.state.longitude
              )
            );
            this.mapRef.setZoom(17); // Why 17? Because it looks good.
            this.markerRef.setPosition(
              new window.google.maps.LatLng(
                this.state.latitude,
                this.state.longitude
              )
            );
            this.markerRef.setVisible(true);
          }
        }
      }
    );
  };

  handleDelete() {
    const { firestore } = this.props;
    const { selectedPoi, setSelectedPoi, togglePoiModal } = this.props;

    if (selectedPoi.id) {
      firestore
        .collection(getFirestoreCollection() + "/pois")
        .doc(selectedPoi.id)
        .delete()
        .then(() => {
          setSelectedPoi(null);
          this.setState({ dialogOpen: false });
          togglePoiModal();
        });
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  setAddress = (address, lat, long) => {
    this.setState({
      address: address,
      latitude: lat,
      selectedLat: lat,
      longitude: long,
      selectedLng: long
    });
  };

  componentDidMount() {
    const { selectedPoi } = this.props;
    var map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 10,
      mapTypeControl: false,
      center: {
        lat: 41.9,
        lng: 12.4
      }
    });

    this.mapRef = map;

    var autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("address")
    );
    autocomplete.bindTo("bounds", map);
    autocomplete.setFields(["address_components", "geometry", "icon", "name"]);

    var geocoder = new window.google.maps.Geocoder();
    window.google.maps.event.addListener(map, "click", function(event) {
      marker.setVisible(false);
      map.setCenter(event.latLng);
      map.setZoom(17); // Why 17? Because it looks good.
      marker.setPosition(event.latLng);
      marker.setVisible(true);
      geocoder.geocode(
        {
          latLng: event.latLng
        },
        function(results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              self.setAddress(
                results[0].formatted_address,
                event.latLng.lat(),
                event.latLng.lng()
              );
            }
          }
        }
      );
    });

    var marker = new window.google.maps.Marker({
      map: map,
      anchorPoint: new window.google.maps.Point(0, -29)
    });

    this.markerRef = marker;

    if (selectedPoi && selectedPoi.id) {
      marker.setPosition(
        new window.google.maps.LatLng(
          selectedPoi.lat_long.latitude,
          selectedPoi.lat_long.longitude
        )
      );
      map.setCenter(
        new window.google.maps.LatLng(
          selectedPoi.lat_long.latitude,
          selectedPoi.lat_long.longitude
        )
      );
      map.setZoom(17); // Why 17? Because it looks good.
      marker.setVisible(true);
    }
    var self = this;

    autocomplete.addListener("place_changed", function() {
      marker.setVisible(false);
      var place = autocomplete.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        //        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17); // Why 17? Because it looks good.
      }

      marker.setPosition(place.geometry.location);
      marker.setVisible(true);
      var address_components = place.address_components;
      var components = {};
      $.each(address_components, function(k, v1) {
        $.each(v1.types, function(k2, v2) {
          components[v2 + "_long_name"] = v1.long_name;
          components[v2 + "_short_name"] = v1.short_name;
        });
      });
      var address = [];
      if (components.route_long_name) address.push(components.route_long_name);
      if (components.street_number_long_name)
        address.push(components.street_number_long_name);
      if (
        components.postal_code_long_name &&
        components.locality_long_name &&
        components.administrative_area_level_2_short_name
      ) {
        address.push(
          components.postal_code_long_name +
            " " +
            components.locality_long_name +
            " " +
            components.administrative_area_level_2_short_name
        );
      } else {
        if (
          components.locality_long_name &&
          components.administrative_area_level_2_short_name
        )
          address.push(
            components.locality_long_name +
              " " +
              components.administrative_area_level_2_short_name
          );
      }
      if (components.political_long_name)
        address.push(components.political_long_name);

      self.setAddress(
        address.join(", "),
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
    });
  }

  onCategoryChange = object => {
    if (object) {
      this.setState({
        categoryId: object.value,
        categoryName: object.label,
        color: object.color
      });
    } else {
      this.setState({
        categoryId: null,
        categoryName: "",
        color: ""
      });
    }
  };

  onCategoryCreate = inputValue => {
    const { color } = this.state;
    firebase
      .firestore()
      .collection(getFirestoreCollection() + "/poiCategories")
      .add({
        name: inputValue,
        color: color
      })
      .then(docRef => {
        docRef
          .get()
          .then(docSnap => {
            this.setState({
              categoryId: docSnap.id,
              categoryName: docSnap.data().name,
              color: docSnap.data().color
            });
          })
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));
  };

  render() {
    const { classes, togglePoiModal, selectedPoi, poiCategories } = this.props;
    const {
      name,
      address,
      canSubmit,
      longitude,
      latitude,
      categoryId,
      categoryName,
      color
    } = this.state;

    const categoryOptions = poiCategories.map(c => {
      return {
        value: c.id,
        label: c.name,
        color: c.color
      };
    });

    return (
      <React.Fragment>
        <DialogTitle id="simple-dialog-title" disableTypography>
          {selectedPoi && selectedPoi.id ? (
            <Typography variant="h3">
              {stringTranslate("customers", "Poi Modify")}
              {selectedPoi.isMainAddress
                ? " - " + stringTranslate("customers", "Main Address")
                : ""}
            </Typography>
          ) : (
            <Typography variant="h3">
              {stringTranslate("customers", "New Poi")}
              {selectedPoi && selectedPoi.isMainAddress
                ? " - " + stringTranslate("customers", "Main Address")
                : ""}
            </Typography>
          )}
        </DialogTitle>
        <IconButton
          className={classes.closeButton}
          onClick={() => togglePoiModal()}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Formsy
            onValidSubmit={this.onSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
            ref={form => (this.form = form)}
            className={classes.container}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <TextFieldFormsy
                  id="name"
                  name="name"
                  label={stringTranslate("customers", "Poi Description")}
                  className={classes.textField}
                  value={name}
                  onChange={this.handleChange("name")}
                  margin="normal"
                  required
                  InputProps={{
                    className: classes.inputText
                  }}
                  InputLabelProps={{
                    className: classes.inputTextLabel
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <ColorPicker
                  color={color}
                  onChange={color => {
                    this.setState({ color: color.hex });
                  }}
                />
                <div className={classes.categoryTextField}>
                  <OMReactSelect
                    isClearable
                    onChange={this.onCategoryChange}
                    onCreateOption={this.onCategoryCreate}
                    options={categoryOptions}
                    value={
                      categoryId
                        ? { value: categoryId, label: categoryName }
                        : null
                    }
                    componentType={CreatableSelect}
                    label={stringTranslate("customers", "poiCategory")}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormsy
                  id="address"
                  name="address"
                  label={stringTranslate("customers", "Poi Address")}
                  className={classes.textField}
                  value={address}
                  onChange={this.handleChange("address")}
                  margin="normal"
                  required
                  InputProps={{
                    className: classes.inputText
                  }}
                  InputLabelProps={{
                    className: classes.inputTextLabel
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormsy
                  id="latitude"
                  name="latitude"
                  label={stringTranslate("customers", "Poi Latitude")}
                  className={classes.textField}
                  value={latitude}
                  onChange={this.handleChange("latitude")}
                  margin="normal"
                  type="number"
                  required
                  InputProps={{
                    className: classes.inputText
                  }}
                  InputLabelProps={{
                    className: classes.inputTextLabel
                  }}
                />
              </Grid>
              <Grid item xs={6} className={classes.addressContainer}>
                <TextFieldFormsy
                  id="longitude"
                  name="longitude"
                  label={stringTranslate("customers", "Poi Longitude")}
                  className={classNames(classes.textField, classes.addressText)}
                  value={longitude}
                  onChange={this.handleChange("longitude")}
                  margin="normal"
                  type="number"
                  required
                  InputProps={{
                    className: classes.inputText
                  }}
                  InputLabelProps={{
                    className: classes.inputTextLabel
                  }}
                />
                <Tooltip
                  title={stringTranslate("general", "Center")}
                  aria-label={stringTranslate("general", "Center")}
                >
                  <IconButton
                    className={classNames(
                      classes.iconButton,
                      classes.addressIcon
                    )}
                    aria-label={stringTranslate("general", "Center")}
                    onClick={() => this.centerLatLong()}
                  >
                    <GpsFixedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <div className={classes.mapContainer} id="map" />
              </Grid>
              <Grid item xs={12} className="mt-12">
                <Button
                  variant="contained"
                  color="secondary"
                  className="mr-12"
                  onClick={() => this.handleSubmit()}
                  disabled={!canSubmit}
                >
                  {stringTranslate("general", "Save")}
                </Button>
                {selectedPoi && selectedPoi.id ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className="mr-12"
                    onClick={() => this.setState({ dialogOpen: true })}
                  >
                    {stringTranslate("general", "Delete")}
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <IconButton
              className={classes.closeButton}
              onClick={() => {
                setSelectedPoi(null);
                togglePoiModal();
              }}
            >
              <CloseIcon />
            </IconButton>
            <Dialog
              open={this.state.dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {stringTranslate("general", "Delete confirm title")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {stringTranslate("general", "Delete confirm content")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ dialogOpen: false })}
                  color="primary"
                >
                  {stringTranslate("general", "Cancel")}
                </Button>
                <Button
                  onClick={() => this.handleDelete()}
                  color="secondary"
                  autoFocus
                >
                  {stringTranslate("general", "Delete")}
                </Button>
              </DialogActions>
            </Dialog>
          </Formsy>
        </DialogContent>
      </React.Fragment>
    );
  }
}

PoiFormModal.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer,
  selectedPoi: state.customersPage.selectedPoi.selectedPoi
});

const mapDispatchToProps = dispatch => ({
  setSelectedPoi: poi => dispatch(setSelectedPoi(poi)),
  setOrphanPoiId: poiId => dispatch(setOrphanPoiId(poiId)),
  setOrphanPoiAddress: address => dispatch(setOrphanPoiAddress(address)),
  togglePoiModal: () => dispatch(togglePoiModal())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(PoiFormModal);
