import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { TextField } from "@material-ui/core";

import { stringTranslate } from "languages/OMTranslate";

const styles = theme => ({
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  }
});

/**
 * Input di testo della lebel di un campo del custom form
 */
const LabelTextField = ({ classes, field, onChange, errors }) => (
  <TextField
    error={errors["label"] !== undefined}
    label={stringTranslate("modules", "fieldLabel")}
    helperText={errors["label"]}
    value={field.label}
    onChange={e => onChange(field, "label")(e.target.value)}
    InputProps={{
      className: classes.inputText
    }}
    InputLabelProps={{
      className: classes.inputTextLabel
    }}
    margin="normal"
    required
    fullWidth
  />
);

LabelTextField.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired
};

LabelTextField.defaultProps = {
  errors: {}
};

export default withStyles(styles, { withTheme: true })(LabelTextField);
