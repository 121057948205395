import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import MainActivityScheduler from "../scheduler/MainActivityScheduler";

const styles = theme => ({
  root: {
    order: 3,
    flex: "1 1 auto",
    width: "100%",
    display: "flex",
    height: "30%",
    backgroundColor: theme.panels.backgroundColor,
    borderTop:
      theme.measures.panelBorderWidth +
      " solid " +
      theme.palette.primary.panelBorder,
    borderBottom: theme.panels.border,
    padding: 0,
    overflow: "hidden"
  },
  hidden: {
    display: "none"
  }
});

class MainActivitySchedulerContainer extends Component {
  render() {
    const { classes, calendarVisibility } = this.props;

    return (
      <div
        id="guidedTour-step11"
        className={classNames(classes.root, { hidden: !calendarVisibility })}
      >
        <MainActivityScheduler tasks={this.props.tasks} />
      </div>
    );
  }
}

MainActivitySchedulerContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  calendarVisibility: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  calendarVisibility: state.mainActivityPage.panelVisibility.calendarVisibility
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(MainActivitySchedulerContainer)
);
