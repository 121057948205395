import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ScrollbarSize from "react-scrollbar-size";

const style = theme => ({
  tableRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "48px",
    minHeight: "48px",
    borderBottomWidth: "1px",
    borderBottomColor: "#e0e0e0",
    backgroundColor: "#FFFFFF",
    color: "#0000008A",
    fontSize: theme.text.medium,
    fontWeight: 500
  }
});

class TableHeader extends React.Component {
  state = {
    scrollbarWidth: 0
  };

  scrollbarSizeLoad = measurements => {
    this.setState({ scrollbarWidth: measurements.scrollbarWidth });
  };

  scrollbarSizeChange = measurements => {
    this.setState({ scrollbarWidth: measurements.scrollbarWidth });
  };

  render() {
    return (
      <div
        className={this.props.classes.tableRow}
        style={{ marginRight: this.state.scrollbarWidth }}
      >
        <ScrollbarSize
          onLoad={this.scrollbarSizeLoad}
          onChange={this.scrollbarSizeChange}
        />
        {this.props.columns.map((element, index) => {
          return (
            <div
              key={index}
              style={{
                width: element.width,
                paddingLeft: "12px",
                paddingRight: "12px"
              }}
            >
              {element.label}
            </div>
          );
        })}
      </div>
    );
  }
}
export default withStyles(style, { withTheme: true })(TableHeader);
