import React from "react";
import { stringTranslate } from "languages/OMTranslate";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const ImportCustomersInstructions = props => {
  return (
    <React.Fragment>
      <Typography variant="body1">
        {stringTranslate("customers", "importFileMessage")}
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            {stringTranslate("customers", "Company Name")}*
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {stringTranslate("customers", "VAT")}*
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {stringTranslate("customers", "Email")}
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {stringTranslate("customers", "Phone")}
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {stringTranslate("customers", "mainAddressName")}
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            {stringTranslate("customers", "mainAddressCoords")}
          </Typography>
        </li>
      </ul>
      <Typography variant="body1">
        {stringTranslate("customers", "necessaryFields")}
      </Typography>
      <Typography variant="body1">
        {stringTranslate("customers", "necessaryFields2")}
      </Typography>
      <Typography variant="body1">
        {stringTranslate("customers", "necessaryFields3")}
      </Typography>
      <Typography variant="body1">
        {stringTranslate("customers", "downloadExampleFile")}
        <Link to="/static/clienti_overmover.xlsx" target="_blank" download>
          link
        </Link>
        {stringTranslate("customers", "downloadExampleFile2")}
      </Typography>
      <Typography variant="body1">
        {stringTranslate("customers", "downloadExampleFile3")}
        <a href="https://support.google.com/maps/answer/18539" target="_blank">
          {stringTranslate("customers", "googleMapsGuide")}
        </a>
      </Typography>
    </React.Fragment>
  );
};

export default ImportCustomersInstructions;
