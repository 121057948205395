import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ListItem, Typography } from "@material-ui/core";
import moment from "moment";
import "moment/min/locales";

import { getMomementLocale } from "languages/OMTranslate";

const styles = theme => ({
  item: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: 50
  },
  container: {
    display: "flex",
    flexDirection: "row"
  },
  contentInfos: {
    display: "flex",
    flexDirection: "column"
  },
  icon: {
    fontSize: "16px",
    marginTop: "2px",
    marginRight: "16px",
    color: theme.palette.primary.dark
  },
  name: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark,
    lineHeight: "1.75"
  },
  info: {
    fontSize: theme.text.small
  }
});

const Module = ({ module, customForm, onClick, classes }) => {
  moment.locale(getMomementLocale());
  const date = module.createdAt
    ? moment.unix(module.createdAt.seconds).format("DD/MM/YYYY")
    : "";
  return (
    <ListItem onClick={onClick} dense button className={classes.item}>
      <div className={classes.container}>
        <div className={classes.contentInfos}>
          <Typography className={classes.name}>{customForm.name}</Typography>
          <Typography color="textSecondary" className={classes.info}>
            {date}
          </Typography>
        </div>
      </div>
    </ListItem>
  );
};

Module.propTypes = {
  module: PropTypes.shape({
    createdAt: PropTypes.shape({
      seconds: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  customForm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired // Fornito da HOC withStyles
};

export default withStyles(styles)(Module);
