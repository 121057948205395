import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { compose } from "redux";
import { connect } from "react-redux";
import { Badge } from "@material-ui/core";

const styles = theme => ({
  badge: {
    backgroundColor: "#ff7b59",
    color: "#fff",
    width: "20px",
    height: "20px",
    top: "-9px",
    right: "-9px"
  }
});

class NotificationsCounter extends Component {
  render() {
    const { classes, notifications } = this.props;
    if (!notifications) {
      return null;
    }

    const counter = notifications.length;
    let hideBadge = false;
    if (counter <= 0) {
      hideBadge = true;
    }

    return (
      <Badge
        classes={{ badge: classes.badge }}
        badgeContent={counter}
        invisible={hideBadge}
      >
        {this.props.children}
      </Badge>
    );
  }
}

const mapStateToProps = state => ({
  notifications: state.mainActivityPage.notifications.notifications
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(NotificationsCounter);
