export const SET_RESOURCE = "[RESOURCES] SET_RESOURCE";
export const SHOW_RESOURCE_FORM = "[RESOURCES] SHOW_FORM";

export const setResource = resourceId => {
  return {
    type: SET_RESOURCE,
    resourceId
  };
};

export const showResourceForm = flag => {
  return {
    type: SHOW_RESOURCE_FORM,
    flag
  };
};
