import { register } from "./register";
import { login } from "./login";
import { general } from "./general";
import { toolbar } from "./toolbar";
import { resources } from "./resources";
import { users } from "./users";
import { customers } from "./customers";
import { mainactivity } from "./mainactivity";
import { tasks } from "./tasks";
import { reports } from "./reports";
import { account } from "./account";
import { modules } from "./modules";
import chat from "./chat";
import tour from "./tour";
import smallScreenTour from "./smallScreenTour";
import timeTracking from "./timeTracking";

export const dictionary_it = {
  general: general,
  users: users,
  register: register,
  toolbar: toolbar,
  login: login,
  resources,
  customers: customers,
  mainactivity: mainactivity,
  reports: reports,
  tasks,
  chat,
  account,
  modules,
  tour,
  smallScreenTour,
  timeTracking
};
