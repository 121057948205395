import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate } from "languages/OMTranslate";
import { setSelectedPoi, togglePoiModal } from "store/actions/customersPage";
import OMTabSearchAddBar from "../common/OMTabSearchAddBar";
import PoisList from "./PoisList";

const styles = theme => ({
  root: {
    padding: theme.measures.panelPadding,
    width: "100%"
  }
});

class PoisTab extends Component {
  state = {
    searchText: ""
  };

  setSearchText = event => {
    this.setState({ searchText: event.target.value });
  };

  render() {
    const {
      togglePoiModal,
      setSelectedPoi,
      selectedCustomer,
      positions
    } = this.props;
    const { searchText } = this.state;

    let filteredPois = [];
    if (positions)
      filteredPois = positions.map(item => Object.assign({}, item));

    filteredPois = filteredPois.filter(poi => {
      var thisParty = false;
      if (poi.partyId === selectedCustomer.id) thisParty = true;
      else thisParty = false;
      var found = false;
      if (thisParty) {
        if (
          (poi.name &&
            poi.name.toLowerCase().search(searchText.toLowerCase()) > -1) ||
          (poi.address &&
            poi.address.toLowerCase().search(searchText.toLowerCase()) > -1)
        ) {
          found = true;
        }
      }
      return found;
    });
    if (filteredPois) {
      filteredPois.forEach(function(poi) {
        if (poi.isMainAddress) {
          poi.mainAddress = stringTranslate("customers", "Main Address");
        } else {
          poi.mainAddress = "";
        }
      });

      filteredPois.sort(function(x, y) {
        if (x.timestamp && y.timestamp) {
          if (x.timestamp > y.timestamp) return -1;
          if (x.timestamp < y.timestamp) return 1;
          return 0;
        }
        if (x.name && y.name) {
          if (x.name.toLowerCase() < y.name.toLowerCase()) {
            return -1;
          }
          if (x.name.toLowerCase() > y.name.toLowerCase()) {
            return 1;
          }
        }
        return 0;
      });
    }

    return (
      <React.Fragment>
        <OMTabSearchAddBar
          textChangeHandler={this.setSearchText}
          textClearHandler={() => {
            this.setState({ searchText: "" });
          }}
          addClickHandler={() => {
            setSelectedPoi(null);
            togglePoiModal();
          }}
          searchText={searchText}
          withAdd={!Boolean(this.props.hideAdd)}
        />
        <PoisList filteredPois={filteredPois} />
      </React.Fragment>
    );
  }
}

PoisTab.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  showPoiModal: state.customersPage.contactsTab.showPoiModal,
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer,
  selectedPoi: state.customersPage.selectedPoi.selectedPoi,
  positions: state.firestore.ordered.positions
});

const mapDispatchToProps = dispatch => ({
  setSelectedPoi: contact => dispatch(setSelectedPoi(contact)),
  togglePoiModal: () => dispatch(togglePoiModal())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(PoisTab);
