import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { getFirestoreCollection } from '../../../firestoreAccount';

import CustomFormsPanel from './CustomFormsPanel';
import ModulePanel from './ModulePanel';

import { Hidden } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    flexDirection: 'row',
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      flexFlow: 'column nowrap'
    }
  },

  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    order: '1',
    flex: '0 0 auto',
    width: theme.measures.mainPageShouldersWidth,
    border: '1px solid',
    backgroundColor: theme.palette.primary.background,
    borderColor: theme.palette.primary.border,
    height: 'calc(100vh - 48px)',
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      width: '100%'
    }
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    order: '2',
    flex: '1 1 auto',
    height: 'calc(100vh - 48px)',
    minWidth: '100px',
    backgroundColor: theme.palette.primary.white,
    overflow: 'auto'
  },
  panel: {
    order: '1',
    flex: '0 0 auto'
  },
  tabContainer: {
    order: '1',
    flex: '0 0 auto',
    borderTop: '1px solid ' + theme.palette.divider,
    height: 48,
    backgroundColor: theme.palette.primary.background
  },
  tab: {
    order: '3',
    flex: '0 0 auto'
  },
  expansionPanel: {
    margin: 'auto'
  }
});

class ModulesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idSelectedCustomForm: undefined,
      addNewCustomForm: false,
      showBuilder: false
    };
  }

  onAdd = () => {
    this.setState({
      addNewCustomForm: true,
      idSelectedCustomForm: undefined,
      showBuilder: true
    });
  };

  onBack = () => {
    this.setState({
      addNewCustomForm: false,
      idSelectedCustomForm: undefined,
      showBuilder: false
    });
  };

  onSelectCustomForm = customForm => {
    this.setState({
      addNewCustomForm: false,
      idSelectedCustomForm: customForm.id,
      showBuilder: false
    });
  };

  onOpenBuilder = () => {
    this.setState({ showBuilder: true });
  };

  onCloseBuilder = () => {
    this.setState({ showBuilder: false });
  };

  render() {
    const { classes, customForms } = this.props;
    const { idSelectedCustomForm, addNewCustomForm, showBuilder } = this.state;
    const selectedCustomForm = customForms
      ? customForms.find(form => form.id === idSelectedCustomForm)
      : undefined;
    return (
      <div
        className={classes.root}
        ref={root => {
          this.root = root;
        }}
      >
        <Hidden xsDown={Boolean(selectedCustomForm) || addNewCustomForm}>
          <div className={classes.listContainer}>
            <CustomFormsPanel
              customForms={customForms}
              selectedCustomForm={selectedCustomForm}
              onSelectCustomForm={this.onSelectCustomForm}
              onAdd={this.onAdd}
            />
          </div>
        </Hidden>
        <Hidden xsDown={!Boolean(selectedCustomForm) && !addNewCustomForm}>
          <div className={classes.formContainer}>
            <ModulePanel
              customForm={selectedCustomForm}
              onBack={this.onBack}
              className={classes.panel}
              showBuilder={showBuilder}
              onOpenBuilder={this.onOpenBuilder}
              onCloseBuilder={this.onCloseBuilder}
            />
          </div>
        </Hidden>
      </div>
    );
  }
}

ModulesPage.propTypes = {
  classes: PropTypes.object.isRequired, // Provided by withStyle
  customForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired
      }).isRequired
    })
  )
};

ModulesPage.defaultProps = {
  parties: [],
  tasks: [],
  customForms: []
};

const mapStateToProps = state => {
  const { ordered, data } = state.firestore;
  return {
    parties: ordered.parties,
    tasks: ordered.tasks,
    customForms: data.customForms
      ? Object.keys(data.customForms)
          .map(k =>
            data.customForms[k]
              ? {
                  id: k,
                  ...data.customForms[k]
                }
              : undefined
          )
          .filter(cf => cf !== undefined)
      : []
  };
};

const mapDispatchToProps = dispatch => ({});

export default compose(
  firestoreConnect(() => [
    {
      collection: `${getFirestoreCollection()}/customForms`,
      storeAs: 'customForms'
    }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(ModulesPage);
