import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

import LabelWithText from "./fields/LabelWithText";
import Text from "./fields/Text";
import Area from "./fields/Area";
import Dropdown from "./fields/Dropdown";
import Check from "./fields/Check";
import Radio from "./fields/Radio";
import DateField from "./fields/Date";
import Time from "./fields/Time";

import { FieldTypes } from "../ModulesConfig";
import { fieldCompare } from "../Common";

import { stringTranslate } from "languages/OMTranslate";

const getFieldToRender = (field, value, onChange, error = undefined) => {
  switch (field.type) {
    case FieldTypes.text:
      return (
        <Text field={field} value={value} onChange={onChange} error={error} />
      );
    case FieldTypes.area:
      return (
        <Area field={field} value={value} onChange={onChange} error={error} />
      );
    case FieldTypes.radio:
      return (
        <Radio field={field} value={value} onChange={onChange} error={error} />
      );
    case FieldTypes.check:
      return (
        <Check field={field} value={value} onChange={onChange} error={error} />
      );
    case FieldTypes.dropdown:
      return (
        <Dropdown
          field={field}
          value={value}
          onChange={onChange}
          error={error}
        />
      );
    case FieldTypes.date:
      return (
        <DateField
          field={field}
          value={value}
          onChange={onChange}
          error={error}
        />
      );
    case FieldTypes.time:
      return (
        <Time field={field} value={value} onChange={onChange} error={error} />
      );
    default:
      return undefined;
  }
};

const styles = () => ({
  container: {},
  contentField: {
    width: "100%",
    marginTop: "10px"
  }
});

/**
 * Gestisce la visualizzazione dei dati di un modello e l'anteprima
 * del custom form.
 */
const Module = ({
  classes,
  customForm,
  module,
  task,
  party,
  onFieldChange,
  errors
}) => {
  if (customForm === undefined) {
    return (
      <div className={classes.container}>
        <Grid container>
          <Grid item lg={12}>
            <Typography variant="h3">
              {stringTranslate("modules", "errorShowModule")}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <Grid container>
        {task && (
          <Grid item xs={12} md={6}>
            <LabelWithText
              label={stringTranslate("modules", "task")}
              text={task.description}
            />
          </Grid>
        )}
        {party && (
          <Grid item xs={12} md={6}>
            <LabelWithText
              label={stringTranslate("modules", "party")}
              text={party.name}
            />
          </Grid>
        )}
        <Grid item className={classes.contentField} xs={12}>
          {customForm.fields
            .slice()
            .sort(fieldCompare)
            .map(field => (
              <div key={field.name} className={classes.contentField}>
                {getFieldToRender(
                  field,
                  module && module[field.name],
                  onFieldChange,
                  errors[field.name]
                )}
              </div>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

Module.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  customForm: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        position: PropTypes.number.isRequired,
        help: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string
          })
        )
      }).isRequired
    ).isRequired
  }),
  module: PropTypes.shape({}),
  task: PropTypes.shape({
    description: PropTypes.string.isRequired
  }),
  party: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  errors: PropTypes.shape({}),
  onFieldChange: PropTypes.func
};

Module.defaultProps = {
  customForm: undefined,
  module: undefined,
  task: undefined,
  party: undefined,
  errors: {},
  onFieldChange: field => value => {}
};

export default withStyles(styles, { withTheme: true })(Module);
