import React, { Component } from "react";
import { connect } from "react-redux";
import * as userActions from "auth/store/actions";
import { bindActionCreators } from "redux";
import * as Actions from "store/actions";
import firebaseService from "firebaseService";

class Auth extends Component {
  componentDidMount() {
    firebaseService.init();
    this.firebaseCheck();
  }

  firebaseCheck = async () => {
    firebaseService.onAuthStateChanged(authUser => {
      if (authUser) {
        firebaseService.setUserAccess(authUser.uid).then(docId => {});
        // this.props.showMessage({ message: "Logging in with Firebase" });

        /**
         * Retrieve user data from Firebase
         */
        firebaseService.getUserData(authUser.uid).then(user => {
          this.props.setUserDataFirebase(user, authUser);
          // this.props.showMessage({ message: "Logged in with Firebase" });
        });
      } else {
        this.props.setUserChecked();
      }
    });
  };

  render() {
    const { children } = this.props;

    return <React.Fragment>{children}</React.Fragment>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: userActions.logoutUser,
      setUserData: userActions.setUserData,
      setUserChecked: userActions.setUserChecked,
      setUserDataAuth0: userActions.setUserDataAuth0,
      setUserDataFirebase: userActions.setUserDataFirebase,
      showMessage: Actions.showMessage,
      hideMessage: Actions.hideMessage
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(Auth);
