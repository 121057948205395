export const OPEN_FORM = "[TASK] OPEN_FORM";
export const PASS_VALUE = "[TASK] PASS_VALUE";
export const SHARE_TASK_FILTERED = "[TASK] SHARE_TASK_FILTERED";

export function toggleTaskForm(options) {
  return {
    type: OPEN_FORM,
    options
  };
}

export function passValueToForm(values) {
  return {
    type: PASS_VALUE,
    values
  };
}

export function shareTaskListFiltered(filtered) {
  return {
    type: SHARE_TASK_FILTERED,
    filtered
  };
}
