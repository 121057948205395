import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { InfoWindow, Marker } from "react-google-maps";
import { getSearchMarkerIcon } from "./getMarkerIcon";
import { Typography } from "@material-ui/core";
import { stringTranslate } from "languages/OMTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  toggleTaskForm,
  passValueToForm
} from "store/actions/mainActivityPage";

const styles = theme => ({
  infoWindowTitle: {
    borderBottom: "1px solid " + theme.palette.primary.border,
    fontWeight: "bold",
    padding: "4px"
  },
  infoWindowText: {
    fontSize: 12,
    padding: "4px"
  },
  infoWindowLinkBox: {
    fontSize: 12,
    padding: "4px",
    cursor: "pointer",
    display: "flex"
  },
  infoWindowLink: {
    flex: "1 1 auto"
  }
});

class SearchMapMarkerComponent extends React.Component {
  state = { isOpen: true };

  onToggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const props = this.props;
    const searchIcon = {
      url: getSearchMarkerIcon(props.resource),
      scaledSize: new window.google.maps.Size(30, 40)
    };

    return (
      <Marker
        key={props.key}
        position={props.position}
        icon={searchIcon}
        className="mycolor"
        onClick={this.onToggleOpen}
        labelAnchor={new window.google.maps.Point(25, 0)}
        labelClass="marker-label"
        labelOrigin={new window.google.maps.Point(75, 32)}
      >
        <React.Fragment>
          {this.state.isOpen && (
            <InfoWindow
              onCloseClick={this.onToggleOpen}
              options={{
                pixelOffset: new window.google.maps.Size(0, -5)
              }}
              position={props.position}
            >
              <React.Fragment>
                <Typography
                  variant="body1"
                  className={props.classes.infoWindowTitle}
                >
                  {props.formatted_address}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    alignItems: "center"
                  }}
                >
                  <div
                    onClick={() => {
                      this.props.passValueToForm({
                        poiId: {
                          label: props.formatted_address,
                          value: "empty",
                          coords: {
                            lat: props.position.lat(),
                            lng: props.position.lng()
                          },
                          isGooglePlace: true
                        }
                      });
                      this.props.toggleTaskForm({
                        taskFormOpen: true,
                        detailDataPanelOpen: true,
                        managedTaskId: null
                      });
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={this.props.classes.infoWindowLinkBox}
                    >
                      <FontAwesomeIcon
                        icon={["fas", "plus-circle"]}
                        className="mr-4"
                      />
                      {stringTranslate("mainactivity", "addTask")}
                    </Typography>
                  </div>
                  <div
                    onClick={this.props.deleteMarker}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "flex-end"
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={this.props.classes.infoWindowLinkBox}
                    >
                      <FontAwesomeIcon
                        icon={["fas", "trash-alt"]}
                        className="mr-4"
                      />
                      {stringTranslate("mainactivity", "Remove")}
                    </Typography>
                  </div>
                </div>
              </React.Fragment>
            </InfoWindow>
          )}
        </React.Fragment>
      </Marker>
    );
  }
}

export default compose(
  connect(
    null,
    dispatch => ({
      toggleTaskForm: options => dispatch(toggleTaskForm(options)),
      passValueToForm: values => dispatch(passValueToForm(values))
    })
  ),
  withStyles(styles, { withTheme: true })
)(SearchMapMarkerComponent);
