import React from "react";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import NotesList from "./notes/NotesList";
import { stringTranslate } from "languages/OMTranslate";
import firebase, {
  getFirestoreCollection
} from "../../../../../firestoreAccount";

const styles = theme => ({
  container: {
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    flex: 1,
    height: "100%"
  },
  dialogContent: {
    padding: 0
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "8px",
    paddingTop: "8px",
    paddingRight: "8px"
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "8px"
  },
  contentView: {
    minHeight: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  shadow: {
    height: "5px",
    boxShadow: "0px 2px 2px 0px rgba(0,0,0, 0.1)"
  },
  addButton: {
    marginLeft: theme.spacing.unit,
    boxShadow: theme.addButton.boxShadow
  },
  plusButton: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "12px"
  }
});

class TaskNotes extends React.Component {
  state = {
    loading: true,
    notes: []
  };

  componentDidMount() {
    const { taskId } = this.props;
    if (taskId === null || taskId === undefined) {
      return;
    }
    this.unsubscribe = firebase
      .firestore()
      .collection(`${getFirestoreCollection()}/tasks/${taskId}/notes`)
      .orderBy("creationDate", "desc")
      .onSnapshot(querySnapshot => {
        let notes = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            notes.push({
              id: doc.id,
              creationDate: new Date(doc.data().creationDate.seconds * 1000),
              note: doc.data().note,
              user: doc.data().user
            });
          });
        }
        this.setState({ loading: false, notes });
      });
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  add = () => {
    const { user } = this.props;
    const { notes } = this.state;
    const id = Math.round(Math.random() * 1000);
    notes.unshift({
      id,
      new: true,
      creationDate: new Date(),
      note: "",
      user: {
        type: "WEB",
        id: user.uid,
        name: user.data.displayName
      }
    });
    this.setState({ notes });
  };

  delete = note => {
    const notes = this.state.notes.filter((value, index) => {
      return value.id !== note.id;
    });
    this.setState({ notes });
  };

  contentToRender = () => {
    const { loading, notes } = this.state;
    const { classes } = this.props;
    if (loading) {
      return (
        <div className={classes.contentView}>
          <CircularProgress color="secondary" />
        </div>
      );
    }
    if (notes.length === 0) {
      return (
        <div className={classes.contentView}>
          <Typography className={classes.message}>
            {stringTranslate("tasks", "noNotes")}
          </Typography>
        </div>
      );
    }
    return (
      <NotesList
        notes={notes}
        taskId={this.props.taskId}
        user={this.props.user}
        deleteNote={this.delete}
      />
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.titleContainer}>
          <div>
            <IconButton onClick={this.props.onBack}>
              <BackIcon />
            </IconButton>
          </div>
          <div className={classes.title}>
            <Typography variant="h3">
              {stringTranslate("tasks", "notes")}
            </Typography>
          </div>
          <div className={classes.plusButton}>
            <Button
              variant="fab"
              color="secondary"
              mini
              onClick={this.add}
              className={classes.addButton}
              aria-label={stringTranslate("general", "Add")}
            >
              <AddIcon />
            </Button>
          </div>
        </div>
        <div className={classes.shadow} />
        <DialogContent className={classes.dialogContent}>
          <div className={classes.container}>{this.contentToRender()}</div>
        </DialogContent>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TaskNotes);
