import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Person,
  Forum,
  LocationOn,
  CalendarToday,
  AssignmentTurnedIn
} from "@material-ui/icons";
import List from "@material-ui/core/List";

import { firestoreConnect } from "react-redux-firebase";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import {
  userListToggle,
  mapToggle,
  calendarToggle,
  taskListToggle,
  chatToggle,
  hideAllPanels
} from "../../../store/actions/mainActivityPage";
import { stringTranslate } from "languages/OMTranslate";
import checkAccountPermission from "main/common/account/accountEditionConfig";

const styles = theme => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.dark,
    order: 1,
    flex: "0 0 auto",
    width: "48px",
    textAlign: "center",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      order: 99,
      width: "100%"
    }
  },
  list: {
    paddingTop: 0,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      display: "flex",
      paddingBottom: "0px"
    }
  },

  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    color: theme.palette.primary.white,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      flex: "1 1 auto"
    },
    child: {
      color: theme.palette.primary.white
    }
  },
  listItemSelected: {
    backgroundColor: theme.palette.primary.darkest + " !important "
  },

  userListItem: {
    paddingLeft: 0,
    paddingRight: 0,
    color: theme.palette.primary.white,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      display: "none"
    }
  },

  listItemIcon: {
    margin: "0 auto",
    color: "#9591a0 !important"
  },
  listItemIconSelected: {
    margin: "0 auto",
    color: theme.palette.primary.white
  }
});

class MainActivityButtonMenu extends Component {
  state = { i: 0 };

  togglePageElement(cssclass) {
    if (
      window.innerWidth <
      this.props.theme.breakpoints.values[
        this.props.theme.measures.nextBreakpoint
      ]
    ) {
      this.props.hideAllPanels();
      if (cssclass !== "chat") {
        this.props.userListToggle();
      }
    }
    switch (cssclass) {
      case "userList":
        this.props.userListToggle();
        break;
      case "map":
        this.props.mapToggle();
        break;
      case "calendar":
        this.props.calendarToggle();
        break;
      case "taskList":
        this.props.taskListToggle();
        break;
      case "chat":
        this.props.chatToggle();
        break;
      default:
        break;
    }
  }

  checkLimit(prevWidth, currentWidth) {
    var limitPixel = this.props.theme.breakpoints.values[
      this.props.theme.measures.nextBreakpoint
    ];
    if (prevWidth < limitPixel && currentWidth >= limitPixel) {
      if (this.props.chatVisibility) {
        this.props.userListToggle();
        if (checkAccountPermission(this.props.account, "mainActivity", "Map")) {
          this.props.mapToggle();
          this.props.taskListToggle();
        } else {
          this.props.taskListToggle();
          this.props.calendarToggle();
        }
      }
    }
    if (prevWidth >= limitPixel && currentWidth < limitPixel) {
      var found = false;
      if (this.props.mapVisibility) {
        this.props.hideAllPanels();
        this.props.userListToggle();
        this.props.mapToggle();
        found = true;
      } else {
        if (this.props.calendarVisibility) {
          this.props.hideAllPanels();
          this.props.userListToggle();
          this.props.calendarToggle();
          found = true;
        } else {
          if (this.props.taskListVisibility) {
            this.props.hideAllPanels();
            this.props.userListToggle();
            this.props.taskListToggle();
            found = true;
          } else {
            if (this.props.chatVisibility) {
              this.props.hideAllPanels();
              this.props.chatToggle();
              found = true;
            }
          }
        }
      }
      if (!found) {
        this.props.hideAllPanels();
        this.props.userListToggle();
        if (checkAccountPermission(this.props.account, "mainActivity", "Map")) {
          this.props.mapToggle();
        } else {
          this.props.taskListToggle();
        }
      }
    }
  }

  setDefaultView() {
    if (
      window.innerWidth <
      this.props.theme.breakpoints.values[
        this.props.theme.measures.nextBreakpoint
      ]
    ) {
      this.props.hideAllPanels();
      this.props.userListToggle();
      if (checkAccountPermission(this.props.account, "mainActivity", "Map")) {
        this.props.mapToggle();
      } else {
        this.props.taskListToggle();
      }
    } else {
      this.props.hideAllPanels();
      this.props.userListToggle();
      if (checkAccountPermission(this.props.account, "mainActivity", "Map")) {
        this.props.mapToggle();
        this.props.taskListToggle();
      } else {
        this.props.calendarToggle();
        this.props.taskListToggle();
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.checkLimit(prevProps.innerWidth, this.props.innerWidth);
    if (prevProps.account != this.props.account) {
      this.setDefaultView();
    }
  }

  render() {
    const {
      classes,
      userListVisibility,
      mapVisibility,
      calendarVisibility,
      taskListVisibility,
      chatVisibility,
      account
    } = this.props;

    return (
      <div className={classes.root}>
        <List className={classes.list} id="guidedTour-step2">
          <ListItem
            button
            classes={{
              root: classes.userListItem,
              selected: classes.listItemSelected
            }}
            selected={Boolean(userListVisibility)}
            onClick={() => this.togglePageElement("userList")}
          >
            <ListItemIcon
              classes={{
                root: Boolean(userListVisibility)
                  ? classes.listItemIconSelected
                  : classes.listItemIcon
              }}
            >
              <Tooltip
                title={stringTranslate("mainactivity", "Resources")}
                aria-label={stringTranslate("mainactivity", "Resources")}
              >
                <Person />
              </Tooltip>
            </ListItemIcon>
          </ListItem>

          {checkAccountPermission(account, "mainActivity", "Map") && (
            <ListItem
              id="guidedTour-step4"
              button
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected
              }}
              selected={Boolean(mapVisibility)}
              onClick={() => this.togglePageElement("map")}
            >
              <ListItemIcon
                classes={{
                  root: Boolean(mapVisibility)
                    ? classes.listItemIconSelected
                    : classes.listItemIcon
                }}
              >
                <Tooltip
                  title={stringTranslate("mainactivity", "Map")}
                  aria-label={stringTranslate("mainactivity", "Map")}
                >
                  <LocationOn />
                </Tooltip>
              </ListItemIcon>
            </ListItem>
          )}
          {checkAccountPermission(account, "mainActivity", "Scheduler") && (
            <ListItem
              id="guidedTour-step3"
              button
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected
              }}
              selected={Boolean(calendarVisibility)}
              onClick={() => this.togglePageElement("calendar")}
            >
              <ListItemIcon
                classes={{
                  root: Boolean(calendarVisibility)
                    ? classes.listItemIconSelected
                    : classes.listItemIcon
                }}
              >
                <Tooltip
                  title={stringTranslate("mainactivity", "Scheduler")}
                  aria-label={stringTranslate("mainactivity", "Scheduler")}
                >
                  <CalendarToday />
                </Tooltip>
              </ListItemIcon>
            </ListItem>
          )}
          {checkAccountPermission(account, "mainActivity", "Tasks") && (
            <ListItem
              id="guidedTour-step6"
              button
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected
              }}
              selected={Boolean(taskListVisibility)}
              onClick={() => this.togglePageElement("taskList")}
            >
              <ListItemIcon
                classes={{
                  root: Boolean(taskListVisibility)
                    ? classes.listItemIconSelected
                    : classes.listItemIcon
                }}
              >
                <Tooltip
                  title={stringTranslate("mainactivity", "Tasks")}
                  aria-label={stringTranslate("mainactivity", "Tasks")}
                >
                  <AssignmentTurnedIn />
                </Tooltip>
              </ListItemIcon>
            </ListItem>
          )}
          {checkAccountPermission(account, "mainActivity", "Chat") && (
            <ListItem
              id="guidedTour-step5"
              button
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected
              }}
              selected={Boolean(chatVisibility)}
              onClick={() => this.togglePageElement("chat")}
            >
              <ListItemIcon
                classes={{
                  root: Boolean(chatVisibility)
                    ? classes.listItemIconSelected
                    : classes.listItemIcon
                }}
              >
                <Tooltip
                  title={stringTranslate("mainactivity", "Chat")}
                  aria-label={stringTranslate("mainactivity", "Chat")}
                >
                  <Forum />
                </Tooltip>
              </ListItemIcon>
            </ListItem>
          )}
        </List>
      </div>
    );
  }
}

MainActivityButtonMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  userListVisibility: PropTypes.bool.isRequired,
  userListToggle: PropTypes.func.isRequired,
  mapVisibility: PropTypes.bool.isRequired,
  mapToggle: PropTypes.func.isRequired,
  calendarVisibility: PropTypes.bool.isRequired,
  calendarToggle: PropTypes.func.isRequired,
  taskListVisibility: PropTypes.bool.isRequired,
  taskListToggle: PropTypes.func.isRequired,
  chatVisibility: PropTypes.bool.isRequired,
  chatToggle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  userListVisibility: state.mainActivityPage.panelVisibility.userListVisibility,
  mapVisibility: state.mainActivityPage.panelVisibility.mapVisibility,
  calendarVisibility: state.mainActivityPage.panelVisibility.calendarVisibility,
  taskListVisibility: state.mainActivityPage.panelVisibility.taskListVisibility,
  chatVisibility: state.mainActivityPage.panelVisibility.chatVisibility,
  account: state.firestore.data.account,
  user: state.auth.user
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    {
      userListToggle,
      mapToggle,
      taskListToggle,
      calendarToggle,
      chatToggle,
      hideAllPanels
    }
  )
)(withStyles(styles, { withTheme: true })(MainActivityButtonMenu));
