import firebase, { getFirestoreCollection } from "firestoreAccount";

const notificationClickHandler = ({
  notificationId,
  type,
  sourceId,
  chatRooms,
  setActiveChat,
  chatVisibility,
  chatToggle,
  history
}) => {
  switch (type) {
    case "CHAT":
      if (chatRooms && chatRooms.length > 0) {
        const room = chatRooms.find(el => el.id === sourceId);
        if (room) setActiveChat(room);
        if (!chatVisibility) chatToggle();
      }
      break;
    case "TASK":
      break;
    case "PAYMENTS":
      history.push("/payments");
      break;
    default:
      return false;
  }
  firebase
    .firestore()
    .collection(getFirestoreCollection() + "/notifications")
    .doc(notificationId)
    .update({ status: "READ" });
};
export default notificationClickHandler;
