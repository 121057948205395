import firebase from "firebase/app";
import moment from "moment";

export default function errorReporting(userId, type, message) {
  var collection = "system_logs_" + type;

  firebase
    .firestore()
    .collection(collection)
    .add({
      accountId: localStorage.getItem("accountId"),
      userId: userId,
      type: type,
      message: message,
      page: window.location.href,
      timestamp: moment().toDate()
    });
}
