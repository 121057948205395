import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";

import OptionField from "./OptionField";

import { stringTranslate } from "languages/OMTranslate";

const styles = theme => ({
  content: {
    marginTop: 10
  },
  list: {
    marginTop: 10
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  },
  button: {
    marginTop: 10,
    textTransform: "none"
  }
});

/**
 * Componente che gestisce le opzioni
 */
const OptionsField = ({
  classes,
  field,
  onChange,
  onDelete,
  onAdd,
  errors
}) => (
  <div className={classes.content}>
    <div className={classes.list}>
      {field.options.map((option, i) => (
        <OptionField
          key={`${field.name}.${option.name}.${i}`}
          option={option}
          errors={errors}
          onChange={onChange(field, option.name)}
          onDelete={() => onDelete(field, option.name)}
          index={i}
        />
      ))}
    </div>
    <Button
      size="small"
      color="secondary"
      className={classes.button}
      onClick={() => onAdd(field)}
    >
      {stringTranslate("modules", "addFieldOption")}
    </Button>
  </div>
);

OptionsField.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  field: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired
};

OptionsField.defaultProps = {
  errors: {}
};

export default withStyles(styles, { withTheme: true })(OptionsField);
