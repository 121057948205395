import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import classNames from "classnames";
import { FuseScrollbars, FuseAnimateGroup } from "@fuse";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { List } from "@material-ui/core";
import {
  setSelectedCustomer,
  setSelectedPoi,
  setOrphanPoiId,
  setOrphanPoiAddress
} from "store/actions/customersPage";
import OMSearchAddBar from "main/content/common/OMSearchAddBar";
import { ListItemText, ListItem } from "@material-ui/core";

const styles = theme => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    height: "100%"
  },
  listContainer: {
    display: "flex",
    flexDirection: "column"
  },
  listElement: {
    flex: "0 0 auto",
    borderBottom: "1px solid",
    borderColor: theme.palette.primary.lightGrey,
    padding: "20px"
  },
  contentToolbar: {
    display: "flex",
    flexDirection: "row"
  },
  toolbar: {
    flex: "1 1 auto"
  },
  addiconbutton: {
    flex: "0 0 auto",
    color: theme.palette.secondary.main
  },

  textContainer: {
    flex: "1 1 auto"
  },
  customerListItem: {
    // padding: 10,
    paddingLeft: ".6rem",
    borderBottom: "1px solid " + theme.palette.divider,
    minHeight: 50,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "&.active": {
      backgroundColor: theme.palette.background.paper
    }
  },
  icon: {
    flex: "0 0 auto"
  },
  customerName: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  customerDetail: {
    fontSize: theme.text.small
  }
});

class CustomersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ""
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  setSearchText = event => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };
  clearText = () => {
    this.setState({ searchText: "" });
  };

  addNewCompany = () => {
    const {
      setSelectedCustomer,
      setOrphanPoiId,
      setOrphanPoiAddress,
      setSelectedPoi
    } = this.props;
    this.props.handleAddNewCustomer();
    setSelectedCustomer(null);
    setSelectedPoi(null);
    setOrphanPoiId(null);
    setOrphanPoiAddress(null);
  };

  render() {
    const {
      classes,
      customers,
      setSelectedCustomer,
      selectedCustomer,
      allMainAddress,
      handleChangeTab
    } = this.props;
    const { searchText } = this.state;

    const CustomerListItem = props => {
      const { customer } = props;
      const { allMainAddress } = props;
      var mainAddress = "";

      if (allMainAddress) {
        allMainAddress.forEach(poi => {
          if (poi.partyId === customer.id) {
            mainAddress = poi.address;
          }
        });
      }
      return (
        <ListItem
          button
          className={classNames(classes.customerListItem, {
            active: selectedCustomer && selectedCustomer.id === customer.id
          })}
          onClick={() => {
            setSelectedCustomer(null);
            setSelectedCustomer(customer);
            handleChangeTab(0, 0);
          }}
          disableGutters
        >
          <div className={classes.textContainer}>
            <ListItemText
              classes={{
                root: "min-w-px",
                primary: classes.customerName,
                secondary: classes.customerDetail
              }}
              primary={customer.name}
              secondary={mainAddress}
            />
          </div>
          <KeyboardArrowRightIcon className={classes.icon} />
        </ListItem>
      );
    };

    const filteredCustomers = customers
      ? customers.filter(customer => {
          var found = false;
          if (
            customer.name &&
            customer.name.toLowerCase().search(searchText.toLowerCase()) > -1
          ) {
            found = true;
          }
          var mainAddress = "";
          if (allMainAddress) {
            allMainAddress.forEach(poi => {
              if (poi.partyId === customer.id) {
                mainAddress = poi.address;
              }
            });
          }
          if (mainAddress.toLowerCase().search(searchText.toLowerCase()) > -1) {
            found = true;
          }

          return found;
        })
      : [];

    filteredCustomers.sort(function(x, y) {
      if (!x.name) return -1;
      if (!y.name) return 1;

      if (x.name.toLowerCase() < y.name.toLowerCase()) {
        return -1;
      }
      if (x.name.toLowerCase() > y.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    return (
      <div
        className={classes.root}
        ref={root => {
          this.root = root;
        }}
      >
        <OMSearchAddBar
          searchText={searchText}
          textChangeHandler={this.setSearchText}
          textClearHandler={this.clearText}
          addClickHandler={this.addNewCompany}
          withAdd
        />

        <FuseScrollbars className="overflow-y-auto flex-1">
          <List className="w-full">
            {filteredCustomers && filteredCustomers.length > 0 && (
              <React.Fragment>
                <FuseAnimateGroup
                  enter={{
                    animation: "transition.expandIn"
                  }}
                  className="flex flex-col flex-no-shrink"
                >
                  {filteredCustomers.map(customer => {
                    return (
                      <CustomerListItem
                        customer={customer}
                        allMainAddress={allMainAddress}
                        key={customer.id}
                      />
                    );
                  })}
                </FuseAnimateGroup>
              </React.Fragment>
            )}
          </List>
        </FuseScrollbars>
      </div>
    );
  }
}

CustomersList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer
});

const mapDispatchToProps = dispatch => ({
  setSelectedCustomer: customer => dispatch(setSelectedCustomer(customer)),
  setOrphanPoiId: poiId => dispatch(setOrphanPoiId(poiId)),
  setOrphanPoiAddress: address => dispatch(setOrphanPoiAddress(address)),
  setSelectedPoi: poi => dispatch(setSelectedPoi(poi))
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(CustomersList);
