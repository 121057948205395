import firebaseService from "firebaseService";
import * as UserActions from "auth/store/actions";
import * as Actions from "store/actions";
import { stringTranslate, getMomementLocale } from "languages/OMTranslate";
import moment from "moment";
import { getCookie } from "main/common/getCookie";
import { getAccountInitialBilling } from "main/common/account/accountHelper";
import * as accountConstants from "main/common/account/accountConstants";
import firebase from "firestoreAccount";
import "firebase/functions";
import errorReporting from "main/common/errorReporting";

export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_RESET = "REGISTER_RESET";

function createDemoData(userId, accountId) {
  errorReporting(
    userId,
    "INFO",
    "[register.actions] createDemoData http call start"
  );

  var demoFunction = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("createDemoData");
  demoFunction({ userId, accountId })
    .then(result => {})
    .catch(error =>
      errorReporting(
        userId,
        "ERROR",
        "[register.actions] createDemoData function error: " + error
      )
    );
}

async function createNewAccount(companyName, requestedPlan) {
  let promise = new Promise((resolve, reject) => {
    var accountCollection = firebase.firestore().collection("accounts");
    accountCollection
      .add({
        name: companyName,
        createDatetime: moment().toDate(),
        billing: getAccountInitialBilling(),
        edition: accountConstants.PROFESSIONAL_EDITION,
        requestedPlan: requestedPlan
      })
      .then(function(accountRef) {
        resolve(accountRef);
      })
      .catch(error => {
        console.log(error);
      });
  });
  let account = await promise;

  return account.id;
}

// messaggioFunction DocumentReference.set() called with invalid data. Unsupported field value: undefined (found in field data.firstName)

export function registerWithFirebase(model) {
  const { email, password, firstName, lastName } = model;
  return dispatch =>
    firebaseService.auth &&
    firebaseService.auth
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        firebase.auth().useDeviceLanguage();
        response.user
          .sendEmailVerification()
          .then(function() {
            console.log("email di conferma inviata");
          })
          .catch(function(error) {
            console.log(error);
          });
        var hubspotUrl =
          "https://api.hsforms.com/submissions/v3/integration/submit/5410082/857f5155-93a4-4103-b6ac-18e9ef936c0e";

        var data = {
          fields: [
            {
              name: "email",
              value: email
            },
            {
              name: "firstname",
              value: firstName
            },
            {
              name: "lastname",
              value: lastName
            },
            {
              name: "company",
              value: model.company
            },
            {
              name: "message",
              value: "Registration Form"
            },
            {
              name: "hs_language",
              value: getMomementLocale()
            },
            {
              name: "seniority",
              value: "EMAIL_NOT_VERIFIED"
            }
          ],
          context: {
            hutk: getCookie("hubspotutk"),
            pageName: "Overmover Register Form"
          }
        };

        var xhr = new XMLHttpRequest();
        var final_data = JSON.stringify(data);
        xhr.open("POST", hubspotUrl);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send(final_data);

        createNewAccount(model.company, model.requestedPlan).then(accountId => {
          createDemoData(response.user.uid, accountId);
          dispatch(
            UserActions.createUserSettingsFirebase({
              ...response.user,
              firstName,
              lastName,
              email,
              accountId,
              showGuidedTour: true
            })
          );
        });
        return dispatch({
          type: REGISTER_SUCCESS
        });
      })
      .catch(error => {
        /* const usernameErrorCodes = [
          "auth/operation-not-allowed",
          "auth/user-not-found",
          "auth/user-disabled"
        ]; */

        const emailErrorCodes = [
          "auth/email-already-in-use",
          "auth/invalid-email"
        ];

        const passwordErrorCodes = [
          "auth/weak-password",
          "auth/wrong-password"
        ];

        const response = {
          email: emailErrorCodes.includes(error.code) ? error.message : null,
          password: passwordErrorCodes.includes(error.code)
            ? stringTranslate("login", error.code)
            : null
        };

        if (error.code === "auth/invalid-api-key") {
          dispatch(Actions.showMessage({ message: error.message }));
        }

        return dispatch({
          type: REGISTER_ERROR,
          payload: response
        });
      });
}
