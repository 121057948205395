import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Button, IconButton } from "@material-ui/core";

const styles = () => ({
  button: {
    marginLeft: "20px"
  },
  icon: {
    fontSize: "20px",
    marginLeft: "20px"
  },
  iconDefault: {
    fontSize: "20px",
    marginLeft: "20px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  iconText: {
    fontSize: "20px",
    marginRight: "10px"
  }
});

class ResponsiveButton extends React.Component {
  state = {
    mobile: false
  };

  componentWillMount() {
    this.onUpdateDimensions();
  }
  componentDidMount() {
    window.addEventListener("resize", this.onUpdateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onUpdateDimensions);
  }

  onUpdateDimensions = () => {
    const { mobile } = this.state;
    if (window.innerWidth <= 600 && !mobile) {
      this.setState({ mobile: true });
    }
    if (window.innerWidth > 600 && mobile) {
      this.setState({ mobile: false });
    }
  };

  render() {
    const {
      classes,
      onClick,
      icon,
      text,
      color,
      variant,
      fullWithIcon
    } = this.props;
    const { mobile } = this.state;
    const Icon = icon;
    let button = (
      <Button
        className={classes.button}
        onClick={onClick}
        color={color}
        variant={variant}
      >
        {fullWithIcon && <Icon className={classes.iconText} />}
        {text}
      </Button>
    );
    if (mobile) {
      button = (
        <IconButton
          className={color === "default" ? classes.iconDefault : classes.icon}
          onClick={onClick}
          color={color}
        >
          <Icon />
        </IconButton>
      );
    }
    return button;
  }
}

ResponsiveButton.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  fullWithIcon: PropTypes.bool
};

ResponsiveButton.defaultProps = {
  selected: false,
  color: "default",
  variant: "outlined",
  fullWithIcon: true
};

export default withStyles(styles)(ResponsiveButton);
