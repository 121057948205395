import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  withStyles,
  InputLabel,
  Button,
  CircularProgress
} from "@material-ui/core";
import { Today } from "@material-ui/icons";
import { stringTranslate } from "languages/OMTranslate";
import { firestoreConnect } from "react-redux-firebase";
import Formsy from "formsy-react";
import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import OMReactSelect from "main/content/common/OMReactSelect";
import Select from "react-select";
import TimeTrackingChart from "./TimeTrackingChart";
import firebase, { getFirestoreCollection } from "../../../../firestoreAccount";
import moment from "moment";
import EmptyReport from "./EmptyReport";
import ReportTitle from "../ReportTitle";

const styles = theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "calc(100vh - 48px)",
    background: theme.palette.primary.white,
    padding: theme.measures.panelPadding
  },
  filter: {
    padding: theme.measures.panelPadding,
    maxWidth: theme.measures.formMaxWidth,
    marginBottom: theme.measures.panelPadding,
    flex: "0 0 auto"
  },
  formContainer: {
    alignItems: "flex-end"
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  },
  spinner: {
    paddingTop: "50%",
    paddingLeft: "50%"
  },
  modal: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    width: "500px",
    height: "350px",
    top: "35vh",
    left: "35vw"
  },
  addButton: {
    marginLeft: theme.spacing.unit,
    boxShadow: theme.addButton.boxShadow
  }
});

class TimeTrackingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resourceId: null,
      resourceName: null,
      period: {
        value: "sevenDays",
        label: stringTranslate("reports", "sevenDays")
      },
      showDates: false,
      startDate: null,
      endDate: null,
      selected: null,
      loading: false,
      firstLoad: true,
      totalTime: 0,
      chartData: []
    };
  }

  handleDateChange = (date, type) => {
    if (type === "start") {
      this.setState({ startDate: date });
    } else {
      this.setState({ endDate: date });
    }
  };

  setResource = object => {
    if (!object || !object.value || !object.label) {
      this.setState({ resourceId: null, resourceName: null });
    } else {
      this.setState({ resourceId: object.value, resourceName: object.label });
    }
  };

  setPeriod = object => {
    if (!object || !object.value || !object.label) {
      // Non dovrebbe mai succedere
      this.setState({ period: null });
    } else {
      const showDates = object.value === "customDays" ? true : false;

      this.setState({
        period: { value: object.value, label: object.label },
        showDates
      });
    }
  };

  addDays = (date, days) => {
    var d = new Date(date);
    d.setDate(d.getDate() + days);
    return d;
  };

  sameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  getDateRange = (start, end) => {
    var dateArray = [];
    var currentDate = start;
    while (currentDate <= end) {
      dateArray.push({
        obj: new Date(currentDate),
        string: moment(currentDate).format(
          stringTranslate("reports", "momentFormat")
        )
      });
      currentDate = this.addDays(currentDate, 1);
    }
    return dateArray;
  };

  formatTime = millisec => {
    var sec = parseInt(millisec / 1000);
    var min = parseInt(sec / 60);
    var hrs = parseInt(min / 60);
    if (hrs > 0) {
      min = min - hrs * 60;
      return hrs + "h " + min + "m";
    }
    if (min > 0) {
      return min + "min";
    }
    return "0h";
  };

  findLabel = (doc, dateRange) => {
    var label = "";
    dateRange.forEach(date => {
      if (this.sameDay(date.obj, doc.start)) {
        label = date.string;
      }
    });
    return label;
  };

  findColor = time => {
    if (time.status && time.status === "WAITING") {
      return "color: #be3d56";
    } else {
      return "color: #0091ae";
    }
  };

  formatBarLabel = (start, end) => {
    return (
      this.formatTime(end - start) +
      " - " +
      stringTranslate("reports", "From") +
      " " +
      start.getHours() +
      ":" +
      start.getMinutes() +
      " " +
      stringTranslate("reports", "To") +
      " " +
      end.getHours() +
      ":" +
      end.getMinutes()
    );
  };

  submit = () => {
    this.chart = null;
    this.dataTable = null;
    this.chartOptions = null;

    this.setState({ loading: true, firstLoad: false });
    const { resourceId } = this.state;
    let startDate = null;
    let endDate = null;

    switch (this.state.period.value) {
      case "today":
        startDate = new Date();
        startDate.setHours(0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
      case "yesterday":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        endDate.setHours(23, 59, 59);
        break;
      case "sevenDays":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
      case "thirtyDays":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
      case "customDays":
        startDate = this.state.startDate;
        startDate.setHours(0, 0, 0);
        endDate = this.state.endDate;
        endDate.setHours(23, 59, 59);
        break;
      default:
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
    }

    firebase
      .firestore()
      .collection(getFirestoreCollection() + "/time_tracking")
      .where("start", ">=", startDate)
      .where("start", "<=", endDate)
      .where("resourceId", "==", resourceId)
      .orderBy("start", "asc")
      .get()
      .then(querySnapshot => {
        var chartData = [];
        var dateRange = [];
        var totalTime = 0;

        if (!querySnapshot.empty) {
          // sampleDate serve per avere un'ora da usare per creare le righe vuote mancanti
          var sampleDate = [12, 0];
          const firstDoc = querySnapshot.docs[0];
          if (firstDoc && firstDoc.data() && firstDoc.data().start) {
            const firstDate = new Date(firstDoc.data().start.seconds * 1000);
            sampleDate = [firstDate.getHours(), firstDate.getMinutes()];
          }

          // Array con tutte le date da visualizzare
          dateRange = this.getDateRange(startDate, endDate);

          dateRange.forEach((date, index) => {
            // Somma dei tempi per ogni giorno
            var sum = 0;
            querySnapshot.docs.forEach(doc => {
              const docDateStart = new Date(doc.data().start.seconds * 1000);
              if (this.sameDay(date.obj, docDateStart)) {
                var docDateEnd = null;
                if (doc.data().end) {
                  docDateEnd = new Date(doc.data().end.seconds * 1000);
                } else {
                  docDateEnd = new Date();
                }
                sum = sum + (docDateEnd - docDateStart);
              }
            });
            totalTime += sum;

            var timeSum = "Σ " + this.formatTime(sum);
            var length = timeSum.length;
            while (length < 9) {
              timeSum = "\u00A0\u00A0" + timeSum;
              length++;
            }
            var concat = date.string;
            concat = concat + "  " + timeSum;

            dateRange[index].string = concat;

            chartData.push([
              concat,
              "",
              "opacity: 0",
              new Date(2019, 1, 1, sampleDate[0], sampleDate[1], 0),
              new Date(2019, 1, 1, sampleDate[0], sampleDate[1], 0)
            ]);
          });

          querySnapshot.docs.forEach(doc => {
            var time = doc.data();
            var startH, startM, endH, endM;

            // L'inizio deve esserci per forza
            if (time.start) {
              time.start = new Date(time.start.seconds * 1000);
              startH = time.start.getHours();
              startM = time.start.getMinutes();
            } else {
              return;
            }

            // Se c'è un turno non finito arriverà fino all'ora corrente in cui è stato lanciato il report o a mezzanotte
            if (time.end) {
              time.end = new Date(time.end.seconds * 1000);
              endH = time.end.getHours();
              endM = time.end.getMinutes();
            } else {
              var now = new Date();
              if (this.sameDay(now, time.start)) {
                endH = now.getHours();
                endM = now.getMinutes();
              } else {
                endH = 23;
                endM = 59;
              }
            }

            var startDate = new Date(2019, 1, 1, startH, startM, 0);
            var endDate = new Date(2019, 1, 1, endH, endM, 0);

            chartData = chartData.concat([
              [
                this.findLabel(time, dateRange),
                this.formatBarLabel(startDate, endDate),
                this.findColor(time),
                startDate,
                endDate
              ]
            ]);
          });
        }
        this.setState({
          loading: false,
          chartData,
          totalTime: this.formatTime(totalTime)
        });
      })
      .catch(error => {
        this.setState({ loading: false, chartData: [] });
        console.log(error);
      });
  };

  render() {
    const { classes, resources } = this.props;
    let resourcesOptions = [];
    if (resources) {
      resources.forEach(el => {
        resourcesOptions.push({
          value: el.id,
          label: el.name
        });
      });
    }

    const periodOptions = [
      {
        value: "today",
        label: stringTranslate("reports", "today")
      },
      {
        value: "yesterday",
        label: stringTranslate("reports", "yesterday")
      },
      {
        value: "sevenDays",
        label: stringTranslate("reports", "sevenDays")
      },
      {
        value: "thirtyDays",
        label: stringTranslate("reports", "thirtyDays")
      },
      {
        value: "customDays",
        label: stringTranslate("reports", "customDays")
      }
    ];

    return (
      <React.Fragment>
        <div className={classes.root}>
          <ReportTitle
            title={stringTranslate("reports", "TimeTracking")}
            theme={this.props.theme}
            icon={<Today color="primary" />}
          />

          <div className={classes.filter}>
            <Formsy
              ref={form => (this.form = form)}
              className={classes.container}
            >
              <Grid container spacing={24} className={classes.formContainer}>
                <Grid item xs={12} sm={4}>
                  <OMReactSelect
                    value={
                      this.state.resourceId
                        ? {
                            value: this.state.resourceId,
                            label: this.state.resourceName
                          }
                        : null
                    }
                    onChange={this.setResource}
                    options={resourcesOptions}
                    label={stringTranslate("reports", "Resource")}
                    placeholder={stringTranslate("general", "Select")}
                    componentType={Select}
                    isClearable
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <OMReactSelect
                    value={this.state.period ? this.state.period : null}
                    onChange={this.setPeriod}
                    options={periodOptions}
                    label={stringTranslate("reports", "period")}
                    componentType={Select}
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>
                {this.state.showDates ? (
                  <React.Fragment>
                    <Grid item xs={12} sm={2}>
                      <div className="flex flex-col overflow-hidden">
                        <InputLabel required className="form-input-label">
                          {stringTranslate("reports", "dateStart")}
                        </InputLabel>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={date =>
                            this.handleDateChange(date, "start")
                          }
                          className="date-picker-input"
                          placeholderText={stringTranslate(
                            "reports",
                            "dateStart"
                          )}
                          dateFormat={stringTranslate(
                            "general",
                            "fsnDateFormat"
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <div className="flex flex-col overflow-hidden">
                        <InputLabel required className="form-input-label">
                          {stringTranslate("reports", "dateEnd")}
                        </InputLabel>
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={date => this.handleDateChange(date, "end")}
                          className="date-picker-input"
                          placeholderText={stringTranslate(
                            "reports",
                            "dateEnd"
                          )}
                          dateFormat={stringTranslate(
                            "general",
                            "fsnDateFormat"
                          )}
                        />
                      </div>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
              <Grid container spacing={24} className={classes.formContainer}>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="mt-12"
                    onClick={this.submit}
                    disabled={
                      !this.state.resourceId ||
                      !this.state.period ||
                      (this.state.period.value === "customDays" &&
                        (!this.state.startDate || !this.state.endDate))
                    }
                  >
                    {stringTranslate("reports", "Calculate")}
                  </Button>
                </Grid>
              </Grid>
            </Formsy>
          </div>
          {this.state.loading ? (
            <div className={classes.spinner}>
              <CircularProgress color="secondary" />
            </div>
          ) : null}
          {!this.state.loading && this.state.chartData.length ? (
            <TimeTrackingChart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              timeTrackingData={this.state.chartData}
              totalTime={this.state.totalTime}
            />
          ) : !this.state.firstLoad ? (
            <EmptyReport />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  user: state.auth.user,
  resources: state.firestore.ordered.resources
});

const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => {
    return [
      {
        collection: "/resources",
        where: ["accountId", "==", localStorage.getItem("accountId")],
        storeAs: "resources",
        orderBy: "name"
      }
    ];
  }),
  withStyles(styles, { withTheme: true })
)(TimeTrackingReport);
