import React from "react";
import { FuseAnimateGroup } from "@fuse";
import List from "@material-ui/core/List";
import Note from "./Note";

const NotesList = ({ notes, taskId, user, deleteNote }) => {
  return (
    <div style={{ flex: 1, paddingLeft: "10px", paddingRight: "10px" }}>
      <List className="w-full">
        <FuseAnimateGroup
          enter={{
            animation: "transition.expandIn"
          }}
          className="flex flex-col flex-no-shrink"
        >
          {notes.map(n => (
            <Note
              key={n.id}
              note={n}
              taskId={taskId}
              user={user}
              deleteNote={deleteNote}
            />
          ))}
        </FuseAnimateGroup>
      </List>
    </div>
  );
};

export default NotesList;
