export const register = {
  "Create An Account": "Crea un account",
  "Display name": "Nome",
  Email: "Email",
  Password: "Password",
  "Confirm password": "Conferma password",
  Register: "Registra",
  "Already have an account?": "Hai gia' un account?",
  "Passwords do not match": "Le password non coincidono",
  acceptCondition: "Accetto le",
  policyLink: "Condizioni d'uso",
  acceptCondition2: "e l'",
  policyLink2: "Informativa sulla privacy",

  "First Name": "Nome",
  "Last Name": "Cognome",
  Company: "Azienda",

  acceptError: "È necessario accettare i termini di servizio",
  role: "Ruolo"
};
