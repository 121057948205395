import React, { Component } from "react";
import { withStyles, Button } from "@material-ui/core";
import { stringTranslate, getPolicyLink } from "languages/OMTranslate";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  textContainer: {
    order: 1,
    overflow: "auto",
    flex: "1 1 auto",
    marginBottom: "10px",
    marginTop: "0px"
  },
  innerTextContainer: {},
  buttonContainer: {
    order: 2,
    flex: "0 0 auto",
    textAlign: "center"
  }
});

class PolicyText extends Component {
  componentDidMount() {}
  render() {
    const { classes, onBackdropClick } = this.props;
    return (
      <div className={classes.root}>
        <iframe src={getPolicyLink()} className={classes.textContainer} />
        <div className={classes.buttonContainer}>
          <Button variant="contained" color="primary" onClick={onBackdropClick}>
            {stringTranslate("general", "Close")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PolicyText);
