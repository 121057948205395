import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { compose } from "redux";
import ChatList from "./ChatList";
import ChatHistory from "./ChatHistory";
import Broadcast from "./Broadcast";
import firebase, { getFirestoreCollection } from "../../../../firestoreAccount";
import {
  addRoom,
  emptyRoomList,
  setActiveChat
} from "../../../../store/actions/mainActivityPage";

class MainActivityChatRoomList extends Component {
  state = {
    search: "",
    querySize: 0,
    showBCList: false
  };

  constructor(props) {
    super(props);
    this.unsubscribe = null;
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  componentDidMount() {
    const query = firebase
      .firestore()
      .collection(getFirestoreCollection() + "/chatRooms");

    if (this.props.user && this.props.user.uid) {
      query.where("users", "array-contains", this.props.user.uid);
    }

    this.unsubscribe = query
      .orderBy("lastUpdate", "desc")
      .onSnapshot(querySnapshot => {
        let rooms = [];
        if (!querySnapshot.empty) {
          for (const room of querySnapshot.docs) {
            rooms.push({
              id: room.id,
              ...room.data()
            });
          }
          this.props.addRoom(rooms);
          this.setState({ querySize: rooms.length });
          if (this.props.user) {
            const userId = this.props.user.uid;
            for (const change of querySnapshot.docChanges()) {
              // La chat è stata aggiornata, ma l'utente non è l'ultimo che ci ha scritto qualcosa
              if (change.doc.data().lastSender !== userId) {
                // Vengono aggiornati tutti i messaggi da SENT a RECEIVED
                firebase
                  .firestore()
                  .collection(
                    getFirestoreCollection() +
                      `/chatRooms/${change.doc.id}/chatMessages`
                  )
                  .where("status", "==", "SENT")
                  .where("user.type", "==", "RESOURCE")
                  .get()
                  .then(queryMsg => {
                    queryMsg.forEach(msg => {
                      msg.ref.update({ status: "RECEIVED" });
                    });
                  });
              }
            }
          }
        } else {
          this.props.emptyRoomList();
        }
      });
  }

  setSearch = event => {
    this.setState({ search: event.target.value });
  };

  selectChatHandler = room => {
    this.props.setActiveChat(room);
  };

  newBroadcast = () => {
    this.setState({ showBCList: true });
  };

  closeBroadcast = () => this.setState({ showBCList: false });

  renderMainContent = () => {
    if (this.state.showBCList) {
      return (
        <Broadcast
          chatRooms={this.props.chatRooms ? this.props.chatRooms : []}
          resources={this.props.resources ? this.props.resources : []}
          user={this.props.user ? this.props.user : null}
          goBack={this.closeBroadcast}
        />
      );
    } else if (this.props.selectedChat) {
      return (
        <ChatHistory
          classes={this.props.classes}
          selectChatHandler={this.selectChatHandler}
          selectedChat={this.props.selectedChat}
        />
      );
    } else {
      return (
        <ChatList
          classes={this.props.classes}
          selectChatHandler={this.selectChatHandler}
          chatRooms={this.props.chatRooms ? this.props.chatRooms : []}
          querySize={this.state.querySize}
          newBroadcast={this.newBroadcast}
        />
      );
    }
  };

  render() {
    return <React.Fragment>{this.renderMainContent()}</React.Fragment>;
  }
}

MainActivityChatRoomList.propTypes = {
  classes: PropTypes.object.isRequired,
  chatRooms: PropTypes.array,
  resources: PropTypes.object
};

const mapStateToProps = state => ({
  chatRooms: state.mainActivityPage.chat.chatRooms,
  selectedChat: state.mainActivityPage.chat.selectedChat,
  user: state.auth.user,
  resources: state.firestore.data.resources
});

const mapDispatchToProps = dispatch => ({
  addRoom: room => dispatch(addRoom(room)),
  emptyRoomList: () => dispatch(emptyRoomList()),
  setActiveChat: room => dispatch(setActiveChat(room))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(null, { withTheme: true })(MainActivityChatRoomList));
