export const SET_AS_UPDATED = "[RESOURCE_LIST] SET_AS_UPDATED";

export function setSetResourceListAsUpdated() {
  return {
    type: SET_AS_UPDATED
  };
}

export const SHOW_RESOURCE = "[RESOURCE_LIST] SHOW_RESOURCE";

export function showResource(resourceId) {
  return {
    type: SHOW_RESOURCE,
    resourceId
  };
}

export const SET_SEARCHED_RESOURCES = "[RESOURCE_LIST] SET_SEARCHED_RESOURCES";

export function setSearchedResources(resources) {
  return {
    type: SET_SEARCHED_RESOURCES,
    resources
  };
}
