import React from "react";
import {
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  Input,
  MenuItem,
  ListItemText
} from "@material-ui/core";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250
    }
  }
};

const MultipleSelect = ({
  inputLabel,
  selected,
  onChange,
  id,
  values,
  style
}) => {
  return (
    <FormControl style={style}>
      <InputLabel htmlFor={id}>{inputLabel}</InputLabel>
      <Select
        multiple
        value={selected}
        onChange={onChange}
        input={<Input id={id} />}
        renderValue={selectedValues => {
          let names = [];
          values.forEach(el => {
            if (selectedValues.indexOf(el.id) > -1) names.push(el.name);
          });
          return names.join(", ");
        }}
        MenuProps={MenuProps}
      >
        {values.map(value => (
          <MenuItem key={value.id} value={value.id}>
            <Checkbox checked={selected.indexOf(value.id) > -1} />
            <ListItemText primary={value.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default MultipleSelect;
