export default {
  back: "Indietro",
  close: "Chiudi",
  last: "Ultima",
  next: "Prossimo",
  skip: "Salta",
  step1:
    "Benvenuto nel tour guidato di OverMover! Il tour richiede solo pochi minuti.",
  step2:
    "Queste icone permettono di mostrare/nascondere i vari pannelli della pagina principale.",
  step3:
    "L'icona del calendario permette di mostrare/nascondere il pannello del calendario",
  step4:
    "L'icona della mappa permette di mostrare/nascondere il pannello della mappa.",
  step5: "L'icona della chat permette di mostrare il pannello delle chat...",
  step6:
    "Qui puoi mandare messaggio alle risorse. Se vuoi chiudere questo pannello, clicca ancora l'icona a sinistra",
  step7:
    "Il pannello della mappa mostra la posizione e lo stato (libero/occupato) delle risorse",
  step8: "Puoi cliccare sul segnaposto di una risorsa per vedere più dettagli",
  step9:
    "Il pannello dei task mostra quali attività sono assegnate alle risorse",
  step10: "E mostra i dettagli dei task e il loro stato di esecuzione.",
  step11:
    "Il pannello del calendario mostra la pianificazione dei task nel tempo per ogni risorsa",
  step12: "E ti permette di organizzare il lavoro di tutti i dipendenti.",
  step13:
    "Nel pannello delle chat puoi scambiare messaggi e immagini con le risorse sul campo.",
  step14: "Cliccando su questo simbolo (“+”) puoi creare una nuova risorsa",
  step15:
    "Inserisci il nome della risorsa e il suo numero di telefono e clicca “SALVA”.",
  step16:
    "La risorsa riceverà un SMS di invito, con le istruzioni per installare l'app sul loro smartphone.",
  step17:
    "Dopo che la risorsa avrà installato l'applicazione, riceverai  una notifica e potrai iniziare a organizzare il lavoro.",
  step18:
    "Il tuo account contiene alcuni dati di esempio per aiutarti a vedere come funziona OverMover",
  step19:
    "I dati di esempio possono essere rimossi quando inizierai ad invitare le tue risorse. Per rimuoverli vai nel menu Impostazioni -> Cancella dati dimostrativi",
  step20: "Congratulazioni! Hai completato il tour guidato!",
  resourceBtn:
    "Inizia invitando le tue risorse ad installare la Worker App sugli smartphone."
};
