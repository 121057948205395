export const mainactivity = {
  "Search on Google Maps": "Search on Google Maps",
  Resources: "Resources",
  Customers: "Customers",
  Tasks: "Tasks",
  Busy: "Busy",
  Engaged: "Engaged",
  Free: "Free",
  Still: "Still",
  Moving: "Moving",
  Offline: "Offline",
  Privacy: "Privacy",
  "N/A": "N/A",
  Day: "Day",
  Week: "Week",
  Month: "Month",
  Scheduler: "Scheduler",
  Chat: "Chat",
  Map: "Map",
  at: "at",
  taskDescription: "Current task",
  addTask: "Add task",
  loadingAccount: "Creating your account...",
  "Show Filter": "Show Filter",
  "Show Traffic": "Show Traffic",
  "Zoom in": "Zoom in",
  Route: "Route",
  Remove: "Remove",
  selectCategories: "Select categories",
  selectCustomers: "Select customers"
};
