import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { TextField, MenuItem, Typography } from "@material-ui/core";

const styles = theme => ({
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  }
});

const Dropdown = ({ classes, field, value, onChange, error }) => (
  <TextField
    select
    label={field.label}
    className={classes.textField}
    value={value}
    onChange={e => {
      if (e.target.value !== value) {
        onChange(field)(e.target.value);
      }
    }}
    InputProps={{
      className: classes.inputText
    }}
    InputLabelProps={{
      className: classes.inputTextLabel,
      shrink: true
    }}
    error={error !== undefined && error !== ""}
    helperText={`${field.help}${
      error && error !== "" ? `${field.help ? " - " : ""}${error}` : ""
    }`}
    required={field.required}
    margin="normal"
    fullWidth
  >
    {field.options.map(option => (
      <MenuItem key={option.name} value={option.name}>
        <Typography>{option.label}</Typography>
      </MenuItem>
    ))}
  </TextField>
);

Dropdown.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    position: PropTypes.number.isRequired,
    help: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string
      })
    )
  }).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

Dropdown.defaultProps = {
  value: "",
  error: undefined
};

export default withStyles(styles, { withTheme: true })(Dropdown);
