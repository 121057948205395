import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import Button from "@material-ui/core/Button";

const CsvButton = props => {
  const data = props.data && props.data.length > 0 ? props.data : [];

  return (
    <CSVLink
      data={data}
      separator={";"}
      filename={props.filename ? props.filename + ".csv" : "report.csv"}
    >
      <Button
        variant="contained"
        color="secondary"
        style={props.style ? props.style : {}}
      >
        Download CSV
        <FontAwesomeIcon
          icon={["fas", "file-csv"]}
          style={{ color: "#FFFFFF", marginLeft: "4px", fontSize: 20 }}
        />
      </Button>
    </CSVLink>
  );
};
export default CsvButton;
