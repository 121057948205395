export default function smallScreenHandleIndexEE(
  stepIndex,
  chatToggle,
  chatVisibility,
  chatRooms,
  setActiveChat,
  mapToggle,
  mapVisibility,
  taskListToggle,
  taskListVisibility,
  calendarToggle,
  calendarVisibility,
  userListToggle,
  userListVisibility,
  hideAllPanels,
  resources
) {
  switch (stepIndex) {
    case 3:
    case 4:
      if (!mapVisibility) {
        hideAllPanels();
        if (!userListVisibility) userListToggle();
        mapToggle();
      }
      break;
    case 5:
    case 6:
    case 7:
      if (!calendarVisibility) {
        hideAllPanels();
        if (!userListVisibility) userListToggle();
        calendarToggle();
      }
      break;
    case 8:
    case 9:
      if (!taskListVisibility) {
        hideAllPanels();
        if (!userListVisibility) userListToggle();
        taskListToggle();
      }
      break;
    case 10:
    case 11:
      if (!chatVisibility) {
        hideAllPanels();
        chatToggle();
      }
      break;
    case 12:
      if (!chatVisibility) {
        hideAllPanels();
        chatToggle();
      }
      if (chatRooms.length > 0) {
        if (resources && resources.length) {
          const mb = resources.find(item => item.name === "Martin Bauer");
          if (mb) {
            const selectedChat = chatRooms.find(
              item => item.resourceId === mb.id
            );
            if (selectedChat) {
              setActiveChat(selectedChat);
            } else {
              setActiveChat(chatRooms[0]);
            }
          } else {
            setActiveChat(chatRooms[0]);
          }
        }
      }
      break;
    case 13:
    case 14:
    case 15:
      if (!mapVisibility) {
        hideAllPanels();
        if (!userListVisibility) userListToggle();
        mapToggle();
      }
      break;
    default:
      break;
  }
}
