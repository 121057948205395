import React, { Component } from "react";
import {
  withStyles,
  Button,
  Typography,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Grid
} from "@material-ui/core";
import { Person } from "@material-ui/icons";
import moment from "moment";
import Formsy from "formsy-react";
import DatePicker from "react-datepicker";
import { stringTranslate } from "languages/OMTranslate";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import Select from "react-select";
import OMReactSelect from "main/content/common/OMReactSelect";
import { Link } from "react-router-dom";
import { HorizontalBar } from "react-chartjs-2";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import classNames from "classnames";
import CsvButton from "./CsvButton";
import PdfButton from "./PdfButton";
import $ from "jquery";

const yesterday = moment()
  .subtract(1, "days")
  .startOf("day")
  .toDate();

const styles = theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    background: theme.palette.primary.white,
    padding: theme.measures.panelPadding
  },
  titleRow: {
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "row",
    padding: theme.measures.panelPadding
  },
  title: {
    display: "flex",
    flexDirection: "row",
    flex: "0 0 auto",
    borderBottom: theme.panels.border
  },
  filter: {
    padding: theme.measures.panelPadding,
    marginBottom: theme.measures.panelPadding,
    flex: "0 0 auto"
  },
  formContainer: {
    alignItems: "flex-end"
  },
  dataContainer: {
    marginTop: theme.measures.panelPadding,
    height: 500
  },
  tableContainer: {
    height: "100%"
  },
  chartContainer: {
    position: "relative",
    marginBottom: theme.measures.panelPadding,
    maxWidth: 800,
    maxHeight: 400
  },
  progress: {
    margin: "auto auto"
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputText2: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark,
    paddingTop: "3px"
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  },
  aggregationRow: {
    "& td": {
      fontWeight: "bold"
    }
  },
  numericColumn: {
    textAlign: "right"
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "stretch",
    alignItems: "center"
  }
});

class ResourceReport extends Component {
  MAX_SELECTABLE_RESOURCES = 5;

  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment()
        .subtract(8, "days")
        .startOf("day")
        .toDate(),
      toDate: yesterday,
      selectedResources: [],
      showTable: true,
      showChart: true,
      data: [],
      chartData: {},
      errorName: false,
      dialogOpen: false,
      isLoading: false,
      period: {
        value: "sevenDays",
        label: stringTranslate("reports", "sevenDays")
      },
      showDates: false
    };
  }

  handleDateChange = name => date => {
    const { fromDate, toDate } = this.state;
    let dateToSet = date;
    const stateToSet = {};
    if (date) {
      if (date.getTime() > yesterday.getTime()) {
        dateToSet = yesterday;
      }
      if (name === "fromDate" && toDate) {
        if (dateToSet.getTime() > toDate.getTime()) {
          dateToSet = toDate;
        } else if (moment(toDate).diff(moment(dateToSet), "days") > 30) {
          //se la data d'inizio è più di 30gg prima della data di fine, sposto la data di fine a 30gg
          stateToSet.toDate = moment(dateToSet)
            .add(30, "days")
            .toDate();
        }
      }
      if (name === "toDate" && fromDate) {
        if (dateToSet.getTime() < fromDate.getTime()) {
          dateToSet = fromDate;
        } else if (moment(dateToSet).diff(moment(fromDate), "days") > 30) {
          //se la data di fine è più di 30gg dopo la data di inizio, sposto la data di inizio a 30gg
          stateToSet.fromDate = moment(dateToSet)
            .subtract(30, "days")
            .toDate();
        }
      }
    }
    stateToSet[name] = dateToSet;
    this.setState(stateToSet);
  };

  handleResourceChange = selectedResources => {
    if (selectedResources.length <= this.MAX_SELECTABLE_RESOURCES) {
      this.setState({ selectedResources });
    }
  };

  handleCheckboxChange = (event, checked) => {
    this.setState({
      [event.target.value]: checked
    });
  };

  formatDuration = duration => {
    return Math.floor(duration.asHours()) + "h " + duration.minutes() + "m";
  };

  setBoldLabels = obj => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = <b>{obj[key]}</b>;
      }
    }
    return obj;
  };

  handleSubmit = () => {
    const { selectedResources } = this.state;

    let fromDate = null;
    let toDate = null;
    switch (this.state.period.value) {
      case "yesterday":
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 1);
        fromDate.setHours(0, 0, 0);

        toDate = new Date();
        toDate.setDate(toDate.getDate() - 1);
        toDate.setHours(23, 59, 59);
        break;
      case "sevenDays":
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 7);
        fromDate.setHours(0, 0, 0);

        toDate = new Date();
        toDate.setHours(23, 59, 59);
        break;
      case "thirtyDays":
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 30);
        fromDate.setHours(0, 0, 0);

        toDate = new Date();
        toDate.setHours(23, 59, 59);
        break;
      case "customDays":
        fromDate = this.state.fromDate;
        fromDate.setHours(0, 0, 0);
        toDate = this.state.toDate;
        toDate.setHours(23, 59, 59);
        break;
      default:
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 7);
        fromDate.setHours(0, 0, 0);

        toDate = new Date();
        toDate.setHours(23, 59, 59);
        break;
    }

    const { firestore } = this.props;
    this.setState({ isLoading: true });
    const data = [];
    const chartData = {
      labels: [],
      datasets: [
        {
          label: stringTranslate("reports", "completedTasks"),
          backgroundColor: "#0091ae",
          data: [],
          // yAxisID: "completed_tasks_axis"
          xAxisID: "completed_tasks_axis"
        },
        {
          label: stringTranslate("reports", "deltaPositionsHour"),
          backgroundColor: "#BE3D56",
          data: [],
          // yAxisID: "time_spent_axis"
          xAxisID: "time_spent_axis"
        }
      ]
    };

    const promises = [];
    selectedResources.forEach(resourceObj => {
      promises.push(
        firestore
          .collection(
            "accounts/" +
              localStorage.getItem("accountId") +
              "/resource_report_data"
          )
          .where("date", ">=", fromDate)
          .where("date", "<=", toDate)
          .where("resourceId", "==", resourceObj.value)
          .orderBy("date")
          .get()
      );
    });

    Promise.all(promises).then(querySnapshots => {
      let overallHandledTasks = 0;
      let overallCompletedTasks = 0;
      let overallTimeSpent = 0;
      let overallTaskTimeSpent = 0;
      querySnapshots.forEach(querySnapshot => {
        if (querySnapshot.empty) {
          return;
        }

        let resourceName;
        let handledTasksByResource = 0;
        let completedTasksByResource = 0;
        let timeSpentByResource = 0;
        let taskTimeSpentByResource = 0;
        querySnapshot.forEach(doc => {
          const documentData = doc.data();
          let duration;
          if (documentData.dayFirstPosition && documentData.dayLastPosition) {
            const hourStart = moment.unix(
              documentData.dayFirstPosition.seconds
            );
            const hourEnd = moment.unix(documentData.dayLastPosition.seconds);
            duration = moment.duration(hourEnd.diff(hourStart));
            documentData.hourStart = hourStart.format("HH:mm");
            documentData.hourEnd = hourEnd.format("HH:mm");
          } else {
            documentData.hourStart = "";
            documentData.hourEnd = "";
            duration = moment.duration(0);
          }
          const taskDuration = moment.duration(
            documentData.taskExecutionTime,
            "seconds"
          );
          documentData.resourceName = this.props.indexedResources[
            documentData.resourceId
          ].name;
          resourceName = documentData.resourceName;
          documentData.date = moment
            .unix(documentData.date.seconds)
            .format("L");
          documentData.timeSpent = this.formatDuration(duration);
          documentData.taskTimeSpent = this.formatDuration(taskDuration);
          timeSpentByResource += duration.asSeconds();
          overallTimeSpent += duration.asSeconds();
          taskTimeSpentByResource += taskDuration.asSeconds();
          overallTaskTimeSpent += taskDuration.asSeconds();
          handledTasksByResource += documentData.handledTasks;
          completedTasksByResource += documentData.completedTasks;
          overallHandledTasks += documentData.handledTasks;
          overallCompletedTasks += documentData.completedTasks;

          data.push(documentData);
        });
        const subTotalObj = {
          resourceName: stringTranslate("reports", "resourceSubtotal"),
          handledTasks: handledTasksByResource,
          completedTasks: completedTasksByResource,
          timeSpent: this.formatDuration(
            moment.duration(timeSpentByResource, "seconds")
          ),
          taskTimeSpent: this.formatDuration(
            moment.duration(taskTimeSpentByResource, "seconds")
          ),
          isAggregation: true
        };
        // this.setBoldLabels(subTotalObj);
        data.push(subTotalObj);

        chartData.labels.push(resourceName);
        chartData.datasets[0].data.push(completedTasksByResource);
        chartData.datasets[1].data.push(timeSpentByResource);
      });

      const totalObj = {
        resourceName: stringTranslate("reports", "total"),
        handledTasks: overallHandledTasks,
        completedTasks: overallCompletedTasks,
        timeSpent: this.formatDuration(
          moment.duration(overallTimeSpent, "seconds")
        ),
        taskTimeSpent: this.formatDuration(
          moment.duration(overallTaskTimeSpent, "seconds")
        ),
        isAggregation: true
      };
      // this.setBoldLabels(totalObj);
      data.push(totalObj);

      this.setState({ data, chartData, isLoading: false });
    });
  };

  setPeriod = object => {
    if (!object || !object.value || !object.label) {
      // Non dovrebbe mai succedere
      this.setState({ period: null });
    } else {
      const showDates = object.value === "customDays" ? true : false;

      this.setState({
        period: { value: object.value, label: object.label },
        showDates
      });
    }
  };

  exportData = data => {
    const csvData = [];
    csvData.push([
      stringTranslate("reports", "resource"),
      stringTranslate("reports", "Date"),
      stringTranslate("reports", "firstPositionHour"),
      stringTranslate("reports", "lastPositionHour"),
      stringTranslate("reports", "deltaPositionsHour"),
      stringTranslate("reports", "taskExecutionTime"),
      stringTranslate("reports", "handledTasks"),
      stringTranslate("reports", "completedTasks")
    ]);
    data.forEach(row => {
      csvData.push([
        row.resourceName,
        row.date ? row.date : "",
        row.hourStart ? row.hourStart : "",
        row.hourEnd ? row.hourEnd : "",
        row.timeSpent ? row.timeSpent : "",
        row.taskTimeSpent ? row.taskTimeSpent : "",
        row.handledTasks ? row.handledTasks : 0,
        row.completedTasks ? row.completedTasks : 0
      ]);
    });
    return csvData;
  };

  getPdfTitle = () => {
    var title = stringTranslate("reports", "Resource Report") + " - ";
    let fromDate = null;
    let toDate = null;
    switch (this.state.period.value) {
      case "yesterday":
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 1);
        fromDate.setHours(0, 0, 0);

        toDate = new Date();
        toDate.setDate(toDate.getDate() - 1);
        toDate.setHours(23, 59, 59);
        break;
      case "sevenDays":
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 7);
        fromDate.setHours(0, 0, 0);

        toDate = new Date();
        toDate.setHours(23, 59, 59);
        break;
      case "thirtyDays":
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 30);
        fromDate.setHours(0, 0, 0);

        toDate = new Date();
        toDate.setHours(23, 59, 59);
        break;
      case "customDays":
        fromDate = this.state.fromDate;
        fromDate.setHours(0, 0, 0);
        toDate = this.state.toDate;
        toDate.setHours(23, 59, 59);
        break;
      default:
        fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 7);
        fromDate.setHours(0, 0, 0);

        toDate = new Date();
        toDate.setHours(23, 59, 59);
        break;
    }
    title =
      title +
      stringTranslate("general", "from") +
      " " +
      moment(fromDate).format(
        stringTranslate("general", "momentDateTimeFormat")
      ) +
      " " +
      stringTranslate("general", "to") +
      " " +
      moment(toDate).format(stringTranslate("general", "momentDateTimeFormat"));

    return title;
  };

  render() {
    const { classes, resources } = this.props;
    const {
      fromDate,
      toDate,
      selectedResources,
      showTable,
      showChart,
      data,
      chartData,
      isLoading
    } = this.state;

    let resourcesOptions = [];
    if (resources) {
      resources.forEach(el => {
        resourcesOptions.push({
          value: el.id,
          label: el.name
        });
      });
    }

    const muiDataTableColumns = [
      {
        name: "resourceName",
        label: stringTranslate("reports", "resource")
      },
      {
        name: "date",
        label: stringTranslate("reports", "Date")
      },
      {
        name: "hourStart",
        label: stringTranslate("reports", "firstPositionHour")
      },
      {
        name: "hourEnd",
        label: stringTranslate("reports", "lastPositionHour")
      },
      {
        name: "timeSpent",
        label: stringTranslate("reports", "deltaPositionsHour"),
        options: {
          setCellProps: () => {
            return {
              className: classes.numericColumn
            };
          }
        }
      },
      {
        name: "taskTimeSpent",
        label: stringTranslate("reports", "taskExecutionTime"),
        options: {
          setCellProps: () => {
            return {
              className: classes.numericColumn
            };
          }
        }
      },
      {
        name: "handledTasks",
        label: stringTranslate("reports", "handledTasks"),
        options: {
          setCellProps: () => {
            return {
              className: classes.numericColumn
            };
          }
        }
      },
      {
        name: "completedTasks",
        label: stringTranslate("reports", "completedTasks"),
        options: {
          setCellProps: () => {
            return {
              className: classes.numericColumn
            };
          }
        }
      },
      {
        name: "isAggregation",
        options: {
          display: "excluded"
        }
      },
      {
        name: "resourceId",
        options: {
          display: "excluded"
        }
      },
      {
        name: "link",
        label: stringTranslate("reports", "Routes Report"),
        // filter: true,
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            if (!tableMeta.rowData) {
              return "";
            }
            //isAggregation
            if (tableMeta.rowData[8]) {
              return "";
            }

            const resourceId = tableMeta.rowData[9];
            const path = "/reports/routes/" + resourceId;
            return (
              <Link to={path}>{stringTranslate("reports", "viewReport")}</Link>
            );
          }
        }
      }
    ];

    const muiDataTableOptions = {
      pagination: false,
      selectableRows: false,
      sort: false,
      rowHover: false,
      download: false,
      print: true,
      search: false,
      filter: false,
      responsive: "scroll",
      elevation: 0,
      setRowProps: row => {
        return {
          className: classNames({
            //isAggregation
            [classes.aggregationRow]: row[8] === true
          })
        };
      },
      textLabels: {
        toolbar: {
          viewColumns: stringTranslate("reports", "showColumns")
        },
        viewColumns: {
          title: stringTranslate("reports", "showColumns"),
          titleAria: stringTranslate("reports", "showColumns")
        }
      }
    };

    const periodOptions = [
      {
        value: "yesterday",
        label: stringTranslate("reports", "yesterday")
      },
      {
        value: "sevenDays",
        label: stringTranslate("reports", "sevenDays")
      },
      {
        value: "thirtyDays",
        label: stringTranslate("reports", "thirtyDays")
      },
      {
        value: "customDays",
        label: stringTranslate("reports", "customDays")
      }
    ];

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.titleRow}>
            <div className={classes.title}>
              <Person color="primary" />
              <Typography variant="h3" className="px-8 py-4" color="primary">
                {stringTranslate("reports", "Resource Report")}
              </Typography>
            </div>
          </div>
          <div className={classes.filter}>
            <Formsy
              onValidSubmit={this.handleSubmit}
              // onValid={this.enableButton}
              // onInvalid={this.disableButton}
              ref={form => (this.form = form)}
              className={classes.container}
            >
              <Grid container spacing={24} className={classes.formContainer}>
                <Grid item xs={12} sm={4}>
                  <OMReactSelect
                    value={selectedResources}
                    isMulti
                    onChange={this.handleResourceChange}
                    options={resourcesOptions}
                    label={stringTranslate("tasks", "resource")}
                    placeholder={stringTranslate("general", "Select")}
                    componentType={Select}
                    isClearable
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <OMReactSelect
                    value={this.state.period ? this.state.period : null}
                    onChange={this.setPeriod}
                    options={periodOptions}
                    label={stringTranslate("reports", "period")}
                    componentType={Select}
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>
                {this.state.showDates ? (
                  <React.Fragment>
                    <Grid item xs={12} sm={1}>
                      <div className="flex flex-col overflow-hidden">
                        <InputLabel required className="form-input-label">
                          {stringTranslate("reports", "fromDate")}
                        </InputLabel>
                        <DatePicker
                          selected={fromDate}
                          onChange={this.handleDateChange("fromDate")}
                          className="date-picker-input"
                          placeholderText={stringTranslate(
                            "reports",
                            "fromDate"
                          )}
                          dateFormat={stringTranslate(
                            "general",
                            "fsnDateFormat"
                          )}
                          maxDate={yesterday}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <div className="flex flex-col overflow-hidden">
                        <InputLabel required className="form-input-label">
                          {stringTranslate("reports", "toDate")}
                        </InputLabel>
                        <DatePicker
                          selected={toDate}
                          onChange={this.handleDateChange("toDate")}
                          className="date-picker-input"
                          placeholderText={stringTranslate("reports", "toDate")}
                          dateFormat={stringTranslate(
                            "general",
                            "fsnDateFormat"
                          )}
                          maxDate={yesterday}
                        />
                      </div>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
              <Grid container spacing={24} className={classes.formContainer}>
                <Grid item xs={6} sm={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // className={classes.checkBox}
                        checked={showChart}
                        onChange={this.handleCheckboxChange}
                        value="showChart"
                      />
                    }
                    label={stringTranslate("reports", "showChart")}
                    // classes={{ label: classes.filterLabel }}
                  />
                </Grid>
                <Grid item xs={6} sm={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // className={classes.checkBox}
                        checked={showTable}
                        onChange={this.handleCheckboxChange}
                        value="showTable"
                      />
                    }
                    label={stringTranslate("reports", "showTable")}
                    // classes={{ label: classes.filterLabel }}
                  />
                </Grid>
                <div className={this.props.classes.buttonContainer}>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: "16px" }}
                    // onClick={() => this.handleSubmit()}
                    disabled={
                      selectedResources.length <= 0 ||
                      (this.state.period.value === "customDays" &&
                        (!fromDate || !toDate))
                    }
                    type="submit"
                  >
                    {stringTranslate("reports", "Calculate")}
                  </Button>
                  {!isLoading && data && data.length > 0 ? (
                    <CsvButton
                      data={this.exportData(data)}
                      filename={stringTranslate("reports", "Resource Report")}
                      style={{ marginRight: "16px" }}
                    />
                  ) : null}
                  {!isLoading && data && data.length > 0 ? (
                    <PdfButton
                      data={this.exportData(data)}
                      filename={stringTranslate("reports", "Resource Report")}
                      title={this.getPdfTitle()}
                    />
                  ) : null}
                </div>
              </Grid>
            </Formsy>
          </div>

          {!isLoading ? (
            data && data.length > 0 ? (
              <div className={classes.dataContainer}>
                {showChart && (
                  <div className={classes.chartContainer}>
                    {/* <Bar */}
                    <HorizontalBar
                      maintainAspectRatio={false}
                      data={chartData}
                      width={800}
                      height={400}
                      options={{
                        scales: {
                          // yAxes: [
                          yAxes: [
                            {
                              barPercentage: 0.8,
                              categoryPercentage: 0.5,
                              // barThickness: 20
                              // barThickness: 6,
                              maxBarThickness: 30,
                              minBarLength: 5
                            }
                          ],
                          xAxes: [
                            {
                              id: "completed_tasks_axis",
                              scaleLabel: {
                                labelString: stringTranslate(
                                  "reports",
                                  "completedTasks"
                                ),
                                display: true
                              },
                              offset: true,
                              type: "linear",
                              ticks: { beginAtZero: true, stepSize: 1 },
                              // position: "left"
                              position: "top"
                            },
                            {
                              id: "time_spent_axis",
                              scaleLabel: {
                                labelString: stringTranslate(
                                  "reports",
                                  "deltaPositionsHour"
                                ),
                                display: true
                              },
                              offset: true,
                              type: "linear",
                              ticks: {
                                beginAtZero: true,
                                stepSize: 3600, //secondi => tick ogni ora
                                callback: function(value, index, values) {
                                  //converto il singolo valore della scala in ore
                                  return Math.round(value / 3600) + "h";
                                }
                              },
                              // position: "right"
                              position: "bottom"
                            }
                          ]
                        },
                        tooltips: {
                          callbacks: {
                            label: function(tooltipItem, data) {
                              var label =
                                data.datasets[tooltipItem.datasetIndex].label ||
                                "";

                              if (label) {
                                label += ": ";
                              }
                              if (tooltipItem.datasetIndex === 0) {
                                // return label + tooltipItem.yLabel;
                                return label + tooltipItem.xLabel;
                              }

                              const duration = moment.duration(
                                // tooltipItem.yLabel,
                                tooltipItem.xLabel,
                                "seconds"
                              );

                              return (
                                label +
                                Math.floor(duration.asHours()) +
                                "h " +
                                duration.minutes() +
                                "m"
                              );
                            }
                          }
                        }
                      }}
                    />
                  </div>
                )}
                {showTable && (
                  <div className={classes.tableContainer}>
                    <MUIDataTable
                      columns={muiDataTableColumns}
                      data={data}
                      options={muiDataTableOptions}
                    />
                  </div>
                )}
              </div>
            ) : (
              <Typography variant="body1" className="m-12">
                {stringTranslate("general", "No elements")}
              </Typography>
            )
          ) : (
            <div className={classes.progress}>
              <CircularProgress color="secondary" />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  resources: state.firestore.ordered.resources,
  indexedResources: state.firestore.data.resources,
  reportSelectedResource: state.firestore.data.reportSelectedResource
});

export default compose(
  firestoreConnect(props => {
    var collections = [
      {
        collection: "/resources",
        where: ["accountId", "==", localStorage.getItem("accountId")],
        storeAs: "resources"
      }
    ];
    return collections;
  }),
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(ResourceReport);
