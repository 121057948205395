export const OPEN_DIALOG = "[DIALOG] OPEN";
export const CLOSE_DIALOG = "[DIALOG] CLOSE";
export const OPEN_DELETE_DEMO_DATA_DIALOG = "[DIALOG] OPEN DELETE DEMO DATA";
export const CLOSE_DELETE_DEMO_DATA_DIALOG = "[DIALOG] CLOSE DELETE DEMO DATA";

export function closeDialog() {
  return {
    type: CLOSE_DIALOG
  };
}

export function openDialog(options) {
  return {
    type: OPEN_DIALOG,
    options
  };
}

export function openDeleteDemoDataDialog() {
  return {
    type: OPEN_DELETE_DEMO_DATA_DIALOG
  };
}

export function closeDeleteDemoDataDialog() {
  return {
    type: CLOSE_DELETE_DEMO_DATA_DIALOG
  };
}
