import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import { stringTranslate } from 'languages/OMTranslate';
import { Typography, Zoom } from '@material-ui/core';

const progressSize = 50;
const progressColor = green[500];

const styles = (theme) => ({
  container: {
    position: 'relative',
  },
  contentProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  progressWrapper: {
    position: 'relative',
    display: 'inline-block',
    height: `${progressSize}px`,
    width: `${progressSize}px`,
  },
  progress: {
    color: progressColor,
  },
  contentProgressInfo: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
  },
  pregressInfo: {
    fontWeight: 700,
  },
  labelButton: {
    display: 'inline-block',
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
});

class ButtonUpload extends React.Component {
  state = {
    animateButtonIn: false,
    animateProgressIn: false,
  };

  static getDerivedStateFromProps(props) {
    if (!props.running && !props.success) {
      return {
        animateButtonIn: true,
        animateProgressIn: false,
      };
    }
    if (props.running || props.success) {
      return {
        animateButtonIn: false,
        animateProgressIn: true,
      };
    }
    return null;
  }

  contentToRender = () => {
    const {
      running,
      progress,
      success,
      children,
      onFileSelected,
      fileTypes,
      classes,
    } = this.props;
    const { animateButtonIn, animateProgressIn } = this.state;
    let innerInfo = (
      <Typography className={classes.pregressInfo}>{`${progress}%`}</Typography>
    );
    if (running && success) {
      innerInfo = <CheckIcon nativeColor={progressColor} />;
    }
    return (
      <React.Fragment>
        <div className={classes.container}>
          <input
            accept={fileTypes.join()}
            className={classes.input}
            id="button-upload-file"
            type="file"
            onChange={onFileSelected}
            onClick={(event) => {
              // Resetta informazioni file selezionato
              event.target.value = null;
            }}
          />
          <label htmlFor="button-upload-file" className={classes.labelButton}>
            <Zoom
              in={animateButtonIn}
              onExited={(node) => {
                const { style } = node;
                style.height = '0px';
                style.opacity = 0;
              }}
              onEnter={(node) => {
                const { style } = node;
                style.height = 'auto';
                style.opacity = 1;
              }}
            >
              <Button
                variant="outlined"
                component="span"
                className={classes.button}
              >
                {children}
              </Button>
            </Zoom>
          </label>
          <Zoom
            in={animateProgressIn}
            onExited={(node) => {
              const { style } = node;
              style.height = '0px';
              style.opacity = 0;
            }}
            onEnter={(node) => {
              const { style } = node;
              style.height = 'auto';
              style.opacity = 1;
            }}
          >
            <div className={classes.contentProgress}>
              <div className={classes.progressWrapper}>
                <CircularProgress
                  size={progressSize}
                  className={classes.progress}
                  variant="static"
                  value={progress}
                />
                <div className={classes.contentProgressInfo}>{innerInfo}</div>
              </div>
            </div>
          </Zoom>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { className } = this.props;
    return <div className={className}>{this.contentToRender()}</div>;
  }
}

ButtonUpload.propTypes = {
  children: PropTypes.string,
  running: PropTypes.bool,
  progress: PropTypes.number,
  success: PropTypes.bool,
  onFileSelected: PropTypes.func.isRequired,
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  classes: PropTypes.shape().isRequired, // Fornito da HOC withStyles
};

ButtonUpload.defaultProps = {
  children: stringTranslate('general', 'Upload'),
  running: false,
  progress: 0,
  success: false,
  fileTypes: ['*'],
  className: '',
};

export default withStyles(styles)(ButtonUpload);
