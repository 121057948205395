export const reports = {
  Calculate: "Calculate",
  Date: "Date",
  From: "From",
  To: "To",
  Time: "Time",
  fromDate: "From date",
  toDate: "To date",

  Resource: "Resource",

  Speed: "Speed",

  "Routes Report": "Routes Report",
  "Resource Report": "Resource Report",
  "No results title": "No results",
  "No results message": "No results found with these parameters",
  showColumns: "Show columns",
  viewReport: "View report",

  //Report risorse
  resourceSubtotal: "Total by resource",
  partySubtotal: "Total by customer",
  resource: "Resource",
  total: "Grand total",
  firstPositionHour: "Start working hour",
  lastPositionHour: "Stop working hour",
  deltaPositionsHour: "Worked hours",
  taskExecutionTime: "Task execution hours",
  handledTasks: "Handled tasks",
  completedTasks: "Completed tasks",
  showChart: "Show chart",
  showTable: "Show table",

  //Report timbrature
  validTime: "Valid",
  modifiedTime: "To verify",
  TimeTracking: "Daily Clock in/out",
  timeTrackingFormTitle: "Modify clock in/out",
  dateStart: "From",
  dateEnd: "To",
  momentFormat: "MM/DD/YYYY",
  emptyReport: "No results found",
  "Show positions": "Show positions",
  "Show route": "Show route",
  "Tasks Report": "Tasks Report",
  "From Date": "From Date",
  "To Date": "To Date",
  timeStart: "Start time",
  timeEnd: "End time",
  save: "Save",
  days: "Days",
  totalTime: "Total worked time: ",
  Departure: "Departure",
  Arrival: "Arrival",
  "Total route": "Total route",
  groupBy: "Group by",

  period: "Period",
  today: "Today",
  yesterday: "Yesterday",
  sevenDays: "Up to 7 days ago",
  thirtyDays: "Up to 30 days ago",
  customDays: "Choose dates"
};
