import * as resourceConstants from "./resourceConstants";
import { stringTranslate } from "languages/OMTranslate";
import { Typography, withStyles } from "@material-ui/core";
import React from "react";
import checkAccountPermission from "main/common/account/accountEditionConfig";
import { isResourceOffline } from "./resourceHelper";

const styles = theme => ({
  busy: {
    color: theme.palette.activity.busy
  },
  free: {
    color: theme.palette.activity.free
  },
  offline: {
    color: theme.palette.activity.offline,
    fontStyle: "italic"
  }
});

function getActivityClassName(resource) {
  if (isResourceOffline(resource)) {
    return "offline";
  }

  const activity = resource.activity;
  switch (activity) {
    case resourceConstants.ACTIVITY_FREE:
      return "free";
    case resourceConstants.ACTIVITY_ENGAGED:
      return "busy";
    default:
      return "";
  }
}

const ResourceCaption = ({ resource, account, classes }) => {
  const resourceActivityLabel = stringTranslate("resources", resource.activity);
  const resourceStatusLabel = stringTranslate("resources", resource.status);

  const activityClassName = getActivityClassName(resource);

  return (
    <div className="flex">
      {isResourceOffline(resource) ? (
        <Typography variant="caption" className={classes.offline}>
          {stringTranslate("mainactivity", "Offline")}
        </Typography>
      ) : (
        <React.Fragment>
          <Typography variant="caption" className={classes[activityClassName]}>
            {resourceActivityLabel}
          </Typography>

          {checkAccountPermission(account, "resourceList", "where") && (
            <React.Fragment>
              <Typography variant="caption">&nbsp;/&nbsp;</Typography>
              <Typography variant="caption">{resourceStatusLabel}</Typography>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ResourceCaption);
