import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Card, CardHeader, CardContent, Typography } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import TaskExecutionStatusIcon from "main/common/tasks/TaskExecutionStatusIcon";

const styles = theme => ({
  taskCard: {
    alignItems: "center",
    display: "flex",
    borderRadius: 0,
    "&.delay": {
      borderLeftWidth: 3,
      "&.strong": {
        borderLeftColor: theme.palette.delay.strong
      },
      "&.small": {
        borderLeftColor: theme.palette.delay.small
      }
    }
  },
  taskCardSubheader: {
    "&.delay": {
      "&.strong": {
        color: theme.palette.delay.strong
      },
      "&.small": {
        color: theme.palette.delay.small
      }
    }
  }
});

const TaskCard = ({
  task,
  delayClasses,
  handleClickDetail,
  when,
  showWhere,
  where,
  distance,
  classes
}) => {
  return (
    <Card
      className={classNames(classes.taskCard, delayClasses)}
      onClick={event => handleClickDetail(event, task.id)}
    >
      <div className="flex-1">
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <TaskExecutionStatusIcon task={task} />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1" className="flex-1 ml-6">
                  {task.code}
                </Typography>
                <Typography variant="body1" className="flex-1 ml-6">
                  {task.description}
                </Typography>
              </div>
            </div>
          }
          subheader={
            <Typography
              variant="caption"
              className={classNames(
                classes.taskCardSubheader,
                delayClasses,
                "mt-6"
              )}
            >
              {when}
            </Typography>
          }
          disableTypography
        />
        {showWhere ? (
          <CardContent className={classes.taskCardContent}>
            <div className="flex">
              <div className="flex-1">{where}</div>
              <div>{distance}</div>
            </div>
          </CardContent>
        ) : null}
      </div>
      <KeyboardArrowRightIcon />
    </Card>
  );
};

export default withStyles(styles)(TaskCard);
