import history from "history.js";
import { setDefaultSettings } from "store/actions/fuse";
import { FuseDefaultSettings } from "@fuse";
import _ from "@lodash";
import store from "store";
import * as Actions from "store/actions";
import firebase from "firebase/app";
import firebaseService from "firebaseService";
import moment from "moment";
import { setDateFsnGlobalLocale } from "languages/OMTranslate";
import { REGISTER_RESET } from "./register.actions";
import { deviceDetect } from "react-device-detect";

export const SET_USER_DATA = "[USER] SET DATA";
export const REMOVE_USER_DATA = "[USER] REMOVE DATA";
export const USER_LOGGED_OUT = "[USER] LOGGED OUT";
export const UPDATE_REDUX_USER = "[USER] UPDATE REDUX USER";

export const RESET_ACTION = "[RESET_ACTION] RESET_ACTION";

export const SET_USER_CHECKED = "[USER] SET_USER_CHECKED";

export function setUserChecked() {
  return {
    type: SET_USER_CHECKED
  };
}

/**
 * Set user data from Auth0 token data
 */
export function setUserDataAuth0(tokenData) {
  const user = {
    role: "admin",
    from: "auth0",
    data: {
      displayName: tokenData.username,
      photoURL: tokenData.picture,
      email: tokenData.email,
      settings:
        tokenData.user_metadata && tokenData.user_metadata.settings
          ? tokenData.user_metadata.settings
          : {},
      shortcuts:
        tokenData.user_metadata && tokenData.user_metadata.shortcuts
          ? tokenData.user_metadata.shortcuts
          : []
    }
  };

  return setUserData(user);
}

/**
 * Set user data from Firebase data
 */
export function setUserDataFirebase(user, authUser) {
  if (
    user &&
    user.data &&
    user.data.settings &&
    user.data.settings.theme &&
    user.data.settings.layout &&
    user.data.settings.layout.style
  ) {
    // Set user data but do not update
    return setUserData(user);
  } else {
    // Create missing user settings
    return createUserSettingsFirebase(authUser);
  }
}

/**
 * Create User Settings with Firebase data
 */
export function createUserSettingsFirebase(authUser) {
  const guestUser = store.getState().auth.user;
  const fuseDefaultSettings = store.getState().fuse.settings.defaults;
  const currentUser = firebase.auth().currentUser;
  const user = _.merge({}, guestUser, {
    uid: authUser.uid,
    from: "firebase",
    role: "admin",
    status: "ENABLED",
    deviceInfo: deviceDetect(),
    createDatetime: moment().toDate(),
    emailVerified: false,
    showGuidedTour: authUser.showGuidedTour ? authUser.showGuidedTour : false,
    countryCode: localStorage.getItem("countryCode"),
    countryName: localStorage.getItem("countryName"),
    data: {
      firstName: authUser.firstName,
      lastName: authUser.lastName,
      displayName: authUser.firstName + " " + authUser.lastName,
      email: authUser.email,
      settings: { ...fuseDefaultSettings }
    }
  });

  if (authUser.accountId) user.accountId = authUser.accountId;

  currentUser.updateProfile(user.data);

  updateUserData(user);

  return (dispatch, getState) => {
    return dispatch(setUserData(user));
  };
}

export function updateReduxUser(user) {
  return {
    type: UPDATE_REDUX_USER,
    user
  };
}

/**
 * Set User Data
 */
export function setUserData(user) {
  return dispatch => {
    /*
        Set User Settings
         */
    // dispatch(setDefaultSettings(user.data.settings));

    /*
        Set User Data
         */
    dispatch({
      type: SET_USER_DATA,
      payload: user
    });
  };
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
  return (dispatch, getState) => {
    const oldUser = getState().auth.user;
    const user = _.merge({}, oldUser, { data: { settings } });

    updateUserData(user);

    return dispatch(setUserData(user));
  };
}

export function updateUserLanguage(country) {
  return (dispatch, getState) => {
    const oldUser = getState().auth.user;
    oldUser.data.userLanguage = country;

    setDateFsnGlobalLocale(country);
    updateUserData(oldUser);

    return dispatch(setUserData(oldUser));
  };
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts) {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts
      }
    };

    updateUserData(newUser);

    return dispatch(setUserData(newUser));
  };
}

/**
 * Remove User Data
 */
export function removeUserData() {
  return {
    type: REMOVE_USER_DATA
  };
}

/**
 * Logout
 */
export function logoutUser() {
  return (dispatch, getState) => {
    const user = getState().auth.user;

    if (user.role === "guest") {
      return null;
    }

    history.push({
      pathname: "/"
    });

    firebaseService.signOut();

    dispatch(setDefaultSettings(FuseDefaultSettings));

    store.dispatch({ type: RESET_ACTION });
    dispatch({ type: REGISTER_RESET });

    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}

/**
 * Update User Data
 */
function updateUserData(user) {
  if (user.role === "guest") {
    return;
  }

  firebaseService
    .updateUserData(user)
    .then(() => {
      store
        .dispatch
        //Actions.showMessage({ message: "User data saved to firebase" })
        ();
    })
    .catch(error => {
      store.dispatch(
        Actions.showMessage({ message: "messaggio" + error.message })
      );
    });
}
