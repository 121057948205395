import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import MainActivityMap from "../map/MainActivityMap";

const styles = theme => ({
  root: {
    order: 2,
    flex: "1 1 auto",
    width: "100%",
    display: "flex",
    height: "40%",
    backgroundColor: theme.panels.backgroundColor,
    borderBottom: theme.panels.border,
    padding: theme.panels.padding
  },
  hidden: {
    display: "none"
  }
});

class MainActivityMapContainer extends Component {
  render() {
    const { classes, mapVisibility } = this.props;
    return (
      <div
        id="guidedTour-step7"
        className={classNames(classes.root, { hidden: !mapVisibility })}
      >
        <MainActivityMap tasks={this.props.tasks} />
      </div>
    );
  }
}

MainActivityMapContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  mapVisibility: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  mapVisibility: state.mainActivityPage.panelVisibility.mapVisibility
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(MainActivityMapContainer)
);
