import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getFirestoreCollection } from "../../../firestoreAccount";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate } from "languages/OMTranslate";
import moment from "moment";
import "moment-duration-format";
import Formsy from "formsy-react";
import classNames from "classnames";
import { withStyles, Button, Typography, InputLabel } from "@material-ui/core";
import { AssignmentTurnedIn } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import OMReactSelect from "main/content/common/OMReactSelect";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { mapEnvironmentScript } from "main/common/mapEnvironment";
import * as taskConstants from "main/common/tasks/taskConstants";
import CsvButton from "./CsvButton";
import PdfButton from "./PdfButton";

const styles = theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "calc(100vh - 48px)",
    background: theme.palette.primary.white,
    padding: theme.measures.panelPadding
  },
  titleRow: {
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "row",
    padding: theme.measures.panelPadding
  },
  title: {
    display: "flex",
    flexDirection: "row",
    flex: "0 0 auto",
    borderBottom: theme.panels.border
  },
  filter: {
    padding: theme.measures.panelPadding,
    maxWidth: theme.measures.reportMaxWidth,
    marginBottom: theme.measures.panelPadding,
    flex: "0 0 auto"
  },
  formContainer: {
    alignItems: "flex-end"
  },
  map: {
    flex: "1 1 auto"
  },
  mapContainer: {
    position: "relative",
    width: "100%"
  },
  loadingMap: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: `rgba(255, 255, 255, 0.85) !important;`,
    textAlign: "center",
    display: "flex",
    flexDirection: "column"
  },
  progress: {
    margin: "auto auto"
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputText2: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark,
    paddingTop: "3px"
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  },
  tableContainer: {
    position: "relative",
    flex: "1 1 auto"
  }
});

class RoutesReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment()
        .subtract(7, "days")
        .toDate(),
      endDate: moment().toDate(),

      resourceId: null,
      resourceName: null,
      companyId: null,
      companyName: null,
      taskStatusId: null,
      taskStatusName: null,
      groupById: "resource",
      groupByName: stringTranslate("tasks", "resource"),
      errorName: false,
      dialogOpen: false,
      isLoading: false,
      data: null,
      period: {
        value: "sevenDays",
        label: stringTranslate("reports", "sevenDays")
      },
      showDates: false
    };

    // this.status;
  }

  handleShowDirection = () => {
    this.setState({ showDirection: !this.state.showDirection });
  };

  handleStartDateChange = date => {
    this.setState({ startDate: date });
  };

  handleEndDateChange = date => {
    this.setState({ endDate: date });
  };

  handleChange = (fieldId, fieldName) => object => {
    var newState = [];
    if (object && object.value) {
      newState[fieldId] = object.value;
      newState[fieldName] = object.label;
      this.setState(newState);
    } else {
      newState[fieldId] = null;
      newState[fieldName] = null;
      this.setState(newState);
    }
  };

  setPeriod = object => {
    if (!object || !object.value || !object.label) {
      // Non dovrebbe mai succedere
      this.setState({ period: null });
    } else {
      const showDates = object.value === "customDays" ? true : false;

      this.setState({
        period: { value: object.value, label: object.label },
        showDates
      });
    }
  };

  getTasks() {
    const { firestore } = this.props;
    const { resourceId, taskStatusId, companyId } = this.state;

    let startDate = null;
    let endDate = null;

    switch (this.state.period.value) {
      case "today":
        startDate = new Date();
        startDate.setHours(0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
      case "yesterday":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        endDate.setHours(23, 59, 59);
        break;
      case "sevenDays":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
      case "thirtyDays":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
      case "customDays":
        startDate = this.state.startDate;
        startDate.setHours(0, 0, 0);
        endDate = this.state.endDate;
        endDate.setHours(23, 59, 59);
        break;
      default:
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
    }

    var accountId = localStorage.getItem("accountId");

    let collection = firestore
      .collection("/accounts/" + accountId + "/tasks/")
      .where("beginning", "<", endDate)
      .where("beginning", ">=", startDate);

    if (resourceId) {
      collection = collection.where("resourceId", "==", resourceId);
    }
    if (companyId) {
      collection = collection.where("partyId", "==", companyId);
    }
    if (taskStatusId) {
      collection = collection.where("executionStatus", "==", taskStatusId);
    }
    collection = collection.orderBy("beginning");

    const tasks = collection.get().then(querySnapshot => {
      var tasks = querySnapshot.docs.map(function(documentSnapshot) {
        return documentSnapshot.data();
      });
      return tasks;
    });

    return tasks;
  }

  companySort = (a, b) => {
    if (!this.props.indexedParties[a.partyId]) return 1;
    if (!this.props.indexedParties[b.partyId]) return -1;
    if (
      this.props.indexedParties[a.partyId].name >
      this.props.indexedParties[b.partyId].name
    )
      return 1;
    if (
      this.props.indexedParties[a.partyId].name ===
      this.props.indexedParties[b.partyId].name
    )
      return 0;
    if (
      this.props.indexedParties[a.partyId].name <
      this.props.indexedParties[b.partyId].name
    )
      return -1;
  };

  resourceSort = (a, b) => {
    if (!this.props.indexedResources[a.resourceId]) return 1;
    if (!this.props.indexedResources[b.resourceId]) return -1;
    if (
      this.props.indexedResources[a.resourceId].name >
      this.props.indexedResources[b.resourceId].name
    )
      return 1;
    if (
      this.props.indexedResources[a.resourceId].name ===
      this.props.indexedResources[b.resourceId].name
    )
      return 0;
    if (
      this.props.indexedResources[a.resourceId].name <
      this.props.indexedResources[b.resourceId].name
    )
      return -1;
  };

  handleSubmit = async () => {
    this.setState({ isLoading: true });
    var tasks = await this.getTasks();

    var data = [];
    if (tasks && tasks.length > 0) {
      tasks = tasks.filter(task => {
        if (!this.props.indexedResources[task.resourceId]) return false;
        if (!this.props.indexedParties[task.partyId]) return false;
        return true;
      });

      tasks.forEach(task => {
        var beginning = moment.unix(task.beginning.seconds);
        var ending = moment.unix(task.ending.seconds);
        var resourceName =
          this.props.indexedResources[task.resourceId] &&
          this.props.indexedResources[task.resourceId].name
            ? this.props.indexedResources[task.resourceId].name
            : "";
        var poiAddress =
          this.props.indexedPois[task.poiId] &&
          this.props.indexedPois[task.poiId].address
            ? this.props.indexedPois[task.poiId].address
            : "";
        var partyName =
          this.props.indexedParties[task.partyId] &&
          this.props.indexedParties[task.partyId].name
            ? this.props.indexedParties[task.partyId].name
            : "";
        data.push({
          beginningTimestamp: task.beginning.seconds,
          endingTimestamp: task.ending.seconds,
          resourceId: task.resourceId,
          partyId: task.partyId,
          resourceName: resourceName,
          description: task.description,
          beginning: beginning.format(
            stringTranslate("general", "momentDateTimeFormat")
          ),
          ending: ending.format(
            stringTranslate("general", "momentDateTimeFormat")
          ),
          duration: moment.utc(ending.diff(beginning)).format("HH:mm"),
          poiAddress: poiAddress,
          partyName: partyName,
          statusName: stringTranslate("tasks", task.executionStatus)
        });
      });
    }

    this.setState({
      isLoading: false,
      data: data
    });
  };

  getSubtotal(groupById, subtotal, name) {
    var res = null;
    if (groupById === "resource") {
      res = {
        duration: moment.utc(subtotal.as("milliseconds")).format("HH:mm"),
        resourceName:
          stringTranslate("reports", "resourceSubtotal") + " " + name
      };
    }
    if (groupById === "company") {
      res = {
        duration: moment.utc(subtotal.as("milliseconds")).format("HH:mm"),
        partyName: stringTranslate("reports", "partySubtotal") + " " + name
      };
    }
    return res;
  }

  addSubTotals = data => {
    const { groupById } = this.state;

    var res = [];
    var last = null;
    if (data && data.length) {
      var subtotal = moment.duration(0);
      var total = moment.duration(0);
      for (var i = 0; i < data.length; i++) {
        var beginning = moment.unix(data[i].beginningTimestamp);
        var ending = moment.unix(data[i].endingTimestamp);
        if (last) {
          if (last.resourceId !== data[i].resourceId) {
            if (groupById === "resource") {
              res.push(
                this.getSubtotal(groupById, subtotal, last.resourceName)
              );
            }
            if (groupById === "company") {
              res.push(this.getSubtotal(groupById, subtotal, last.partyName));
            }
            subtotal = moment.duration(0);
          } else {
          }
          res.push(data[i]);
          subtotal.add(moment.duration(ending.diff(beginning)));
          total.add(moment.duration(ending.diff(beginning)));
          if (i === data.length - 1) {
            if (groupById === "resource") {
              res.push(
                this.getSubtotal(groupById, subtotal, last.resourceName)
              );
            }
            if (groupById === "company") {
              res.push(this.getSubtotal(groupById, subtotal, last.partyName));
            }
          }
        } else {
          res.push(data[i]);
        }
        last = data[i];
      }
      if (groupById === "resource") {
        res.push({
          duration: total.format("HH:mm"),
          resourceName: stringTranslate("reports", "total")
        });
      }
      if (groupById === "company") {
        res.push({
          duration: moment.utc(total.as("milliseconds")).format("HH:mm"),
          partyName: stringTranslate("reports", "total")
        });
      }
    }
    return res;
  };

  exportData = data => {
    const csvData = [];
    csvData.push([
      stringTranslate("tasks", "resource"),
      stringTranslate("tasks", "task"),
      stringTranslate("tasks", "company"),
      stringTranslate("tasks", "beginning"),
      stringTranslate("tasks", "ending"),
      stringTranslate("tasks", "duration"),
      stringTranslate("tasks", "address"),
      stringTranslate("tasks", "status")
    ]);

    data.forEach(row => {
      if (this.state.groupById === "resource") {
        csvData.push([
          row.resourceName,
          row.description ? row.description : "",
          row.partyName ? row.partyName : "",
          row.beginning ? row.beginning : "",
          row.ending ? row.ending : "",
          row.duration ? row.duration : "",
          row.poiAddress ? row.poiAddress : "",
          row.statusName ? row.statusName : ""
        ]);
      } else {
        csvData.push([
          row.partyName ? row.partyName : "",
          row.description ? row.description : "",
          row.resourceName,
          row.beginning ? row.beginning : "",
          row.ending ? row.ending : "",
          row.duration ? row.duration : "",
          row.poiAddress ? row.poiAddress : "",
          row.statusName ? row.statusName : ""
        ]);
      }
    });

    return csvData;
  };

  getPdfTitle = () => {
    var title = stringTranslate("reports", "Resource Report") + " - ";
    let startDate = null;
    let endDate = null;

    switch (this.state.period.value) {
      case "today":
        startDate = new Date();
        startDate.setHours(0, 0, 0);
        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
      case "yesterday":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        endDate.setHours(23, 59, 59);
        break;
      case "sevenDays":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
      case "thirtyDays":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 30);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
      case "customDays":
        startDate = this.state.startDate;
        startDate.setHours(0, 0, 0);
        endDate = this.state.endDate;
        endDate.setHours(23, 59, 59);
        break;
      default:
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        startDate.setHours(0, 0, 0);

        endDate = new Date();
        endDate.setHours(23, 59, 59);
        break;
    }
    title =
      title +
      stringTranslate("general", "from") +
      " " +
      moment(startDate).format(
        stringTranslate("general", "momentDateTimeFormat")
      ) +
      " " +
      stringTranslate("general", "to") +
      " " +
      moment(endDate).format(
        stringTranslate("general", "momentDateTimeFormat")
      );

    return title;
  };

  render() {
    const { classes, resources, parties } = this.props;
    const {
      startDate,
      endDate,
      resourceId,
      resourceName,
      taskStatusId,
      taskStatusName,
      companyId,
      companyName,
      groupById,
      groupByName,
      data,
      isLoading
    } = this.state;

    let resourcesOptions = [];
    if (resources) {
      resources.forEach(el => {
        resourcesOptions.push({
          value: el.id,
          label: el.name
        });
      });
    }
    resourcesOptions.sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    });

    let companiesOptions = [];
    if (parties) {
      parties.forEach(el => {
        companiesOptions.push({
          value: el.id,
          label: el.name
        });
      });
    }

    companiesOptions.sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    });

    let canSubmit = true;

    let taskStatusOptions = [];

    const executionStatuses = [
      taskConstants.STATUS_OG_ENROUTE,
      taskConstants.STATUS_OG_NEARBY,
      taskConstants.STATUS_OG_ARRIVED,
      taskConstants.STATUS_OG_EXECUTING,

      taskConstants.STATUS_TOEXECUTE,
      taskConstants.STATUS_COMPLETED,
      taskConstants.STATUS_ABORTED,
      taskConstants.STATUS_REJECTED,
      taskConstants.STATUS_SUSPENDED
    ];

    executionStatuses.forEach(status => {
      if (stringTranslate("tasks", status))
        taskStatusOptions.push({
          value: status,
          label: stringTranslate("tasks", status)
        });
    });

    taskStatusOptions.sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    });

    let groupByOptions = [];
    groupByOptions.push({
      value: "resource",
      label: stringTranslate("tasks", "resource")
    });
    groupByOptions.push({
      value: "company",
      label: stringTranslate("tasks", "company")
    });

    var muiDataTableColumns = [];
    if (groupById === "resource") {
      muiDataTableColumns.push({
        name: "resourceName",
        label: stringTranslate("reports", "resource")
      });
    }
    if (groupById === "company") {
      muiDataTableColumns.push({
        name: "partyName",
        label: stringTranslate("tasks", "company")
      });
    }
    muiDataTableColumns.push({
      name: "description",
      label: stringTranslate("tasks", "task")
    });
    if (groupById === "resource") {
      muiDataTableColumns.push({
        name: "partyName",
        label: stringTranslate("tasks", "company")
      });
    }
    if (groupById === "company") {
      muiDataTableColumns.push({
        name: "resourceName",
        label: stringTranslate("reports", "resource")
      });
    }
    muiDataTableColumns.push({
      name: "beginning",
      label: stringTranslate("tasks", "beginning")
    });
    muiDataTableColumns.push({
      name: "ending",
      label: stringTranslate("tasks", "ending"),
      options: {
        display: false
      }
    });
    muiDataTableColumns.push({
      name: "duration",
      label: stringTranslate("tasks", "duration")
    });
    muiDataTableColumns.push({
      name: "poiAddress",
      label: stringTranslate("tasks", "address"),
      options: {
        display: false
      }
    });

    muiDataTableColumns.push({
      name: "statusName",
      label: stringTranslate("tasks", "status")
    });

    const muiDataTableOptions = {
      pagination: false,
      selectableRows: false,
      sort: false,
      rowHover: false,
      download: false,
      print: true,
      search: false,
      filter: false,
      responsive: "scroll",
      elevation: 0,
      setRowProps: row => {
        return {
          className: classNames({
            //isAggregation
            [classes.aggregationRow]: row[8] === true
          })
        };
      },
      textLabels: {
        body: {
          noMatch: stringTranslate("reports", "emptyReport")
        },
        toolbar: {
          viewColumns: stringTranslate("reports", "showColumns")
        },
        viewColumns: {
          title: stringTranslate("reports", "showColumns"),
          titleAria: stringTranslate("reports", "showColumns")
        }
      }
    };

    if (data && this.state.groupById === "resource")
      data.sort(this.resourceSort);
    if (data && this.state.groupById === "company") data.sort(this.companySort);
    var completeData = this.addSubTotals(data);

    const periodOptions = [
      {
        value: "today",
        label: stringTranslate("reports", "today")
      },
      {
        value: "yesterday",
        label: stringTranslate("reports", "yesterday")
      },
      {
        value: "sevenDays",
        label: stringTranslate("reports", "sevenDays")
      },
      {
        value: "thirtyDays",
        label: stringTranslate("reports", "thirtyDays")
      },
      {
        value: "customDays",
        label: stringTranslate("reports", "customDays")
      }
    ];

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.titleRow}>
            <div className={classes.title}>
              <AssignmentTurnedIn color="primary" />
              <Typography variant="h3" className="px-8 py-4" color="primary">
                {stringTranslate("reports", "Tasks Report")}
              </Typography>
            </div>
          </div>
          <div className={classes.filter}>
            <Formsy
              onValidSubmit={this.onSubmit}
              onValid={this.enableButton}
              onInvalid={this.disableButton}
              ref={form => (this.form = form)}
              className={classes.container}
            >
              <Grid container spacing={24} className={classes.formContainer}>
                <Grid item xs={12} sm={4}>
                  <OMReactSelect
                    value={
                      resourceId
                        ? {
                            value: resourceId,
                            label: resourceName
                          }
                        : null
                    }
                    onChange={this.handleChange("resourceId", "resourceName")}
                    options={resourcesOptions}
                    label={stringTranslate("tasks", "resource")}
                    placeholder={stringTranslate("general", "Select")}
                    componentType={Select}
                    isClearable
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <OMReactSelect
                    value={
                      companyId
                        ? {
                            value: companyId,
                            label: companyName
                          }
                        : null
                    }
                    onChange={this.handleChange("companyId", "companyName")}
                    options={companiesOptions}
                    label={stringTranslate("tasks", "company")}
                    placeholder={stringTranslate("general", "Select")}
                    componentType={Select}
                    isClearable
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <OMReactSelect
                    value={
                      groupById
                        ? {
                            value: groupById,
                            label: groupByName
                          }
                        : null
                    }
                    onChange={this.handleChange("groupById", "groupByName")}
                    options={groupByOptions}
                    label={stringTranslate("reports", "groupBy")}
                    componentType={Select}
                    isClearable
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <OMReactSelect
                    value={
                      taskStatusId
                        ? {
                            value: taskStatusId,
                            label: taskStatusName
                          }
                        : null
                    }
                    onChange={this.handleChange(
                      "taskStatusId",
                      "taskStatusName"
                    )}
                    options={taskStatusOptions}
                    label={stringTranslate("tasks", "status")}
                    placeholder={stringTranslate("general", "Select")}
                    componentType={Select}
                    isClearable
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OMReactSelect
                    value={this.state.period ? this.state.period : null}
                    onChange={this.setPeriod}
                    options={periodOptions}
                    label={stringTranslate("reports", "period")}
                    componentType={Select}
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>

                {this.state.showDates ? (
                  <React.Fragment>
                    <Grid item xs={6} sm={2}>
                      <div className="flex flex-col overflow-hidden">
                        <InputLabel required className="form-input-label">
                          {stringTranslate("reports", "From Date")}
                        </InputLabel>
                        <DatePicker
                          selected={startDate}
                          onChange={this.handleStartDateChange}
                          className="date-picker-input"
                          placeholderText={stringTranslate(
                            "reports",
                            "From Date"
                          )}
                          dateFormat={stringTranslate(
                            "general",
                            "fsnDateFormat"
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <div className="flex flex-col overflow-hidden">
                        <InputLabel required className="form-input-label">
                          {stringTranslate("reports", "To Date")}
                        </InputLabel>
                        <DatePicker
                          selected={endDate}
                          onChange={this.handleEndDateChange}
                          className="date-picker-input"
                          placeholderText={stringTranslate(
                            "reports",
                            "To Date"
                          )}
                          dateFormat={stringTranslate(
                            "general",
                            "fsnDateFormat"
                          )}
                        />
                      </div>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "16px"
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.handleSubmit()}
                  disabled={!canSubmit}
                  style={{ marginRight: "16px" }}
                >
                  {stringTranslate("reports", "Calculate")}
                </Button>

                {!isLoading && completeData && completeData.length > 0 ? (
                  <CsvButton
                    data={this.exportData(completeData)}
                    filename={stringTranslate("reports", "Tasks Report")}
                    style={{ marginRight: "16px" }}
                  />
                ) : null}
                {!isLoading && completeData && completeData.length > 0 ? (
                  <PdfButton
                    data={this.exportData(completeData)}
                    filename={stringTranslate("reports", "Tasks Report")}
                    title={this.getPdfTitle()}
                  />
                ) : null}
              </div>
            </Formsy>
          </div>
          {!isLoading && data && (
            <div className={classes.tableContainer}>
              <MUIDataTable
                columns={muiDataTableColumns}
                data={completeData}
                options={muiDataTableOptions}
              />
            </div>
          )}
          {isLoading && (
            <div className={classes.tableContainer}>
              <div
                className={classes.loadingMap}
                style={{
                  height: "100%",
                  width: "100%"
                }}
              >
                <div className={classes.progress}>
                  <CircularProgress color="secondary" />
                </div>
              </div>
            </div>
          )}
        </div>
        <Dialog
          open={this.state.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {stringTranslate("reports", "No results title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {stringTranslate("reports", "No results message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogOpen: false })}
              color="primary"
            >
              {stringTranslate("general", "Close")}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

RoutesReport.propTypes = {};

const mapStateToProps = state => ({
  user: state.auth.user,
  resources: state.firestore.ordered.resources,
  parties: state.firestore.ordered.parties,
  reportSelectedResource: state.firestore.data.reportSelectedResource,
  indexedResources: state.firestore.data.resources,
  indexedParties: state.firestore.data.parties,
  indexedPois: state.firestore.data.pois
});

const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => {
    var collections = [
      {
        collection: "/resources",
        where: ["accountId", "==", localStorage.getItem("accountId")],
        storeAs: "resources"
      },
      {
        collection: getFirestoreCollection() + "/pois",
        storeAs: "pois"
      },
      {
        collection: getFirestoreCollection() + "/parties",
        storeAs: "parties",
        where: [["isActive", "==", true]]
      }
    ];
    if (props.match.params.resourceId) {
      var tmp = {
        collection: "/resources",
        doc: props.match.params.resourceId,
        storeAs: "reportSelectedResource"
      };
      collections.push(tmp);
    }
    return collections;
  }),
  withStyles(styles, { withTheme: true })
)(mapEnvironmentScript(RoutesReport));
