export const login = {
  "Login to your account": "Login to your account",
  Email: "Email",
  Password: "Password",
  Login: "Login",
  "Don't have an account?": "Don't have an account?",
  "Create an account": "Create an account",
  "Min character length is 4": "Min character length is 4",
  "Min character length is 3": "Min character length is 3",

  "auth/email-already-in-use": "Email already in use",
  "auth/invalid-email": "Invalid email",
  "auth/operation-not-allowed": "Operation not allowed",
  "auth/user-not-found": "User not found",
  "auth/user-disabled": "User disabled",
  "auth/weak-password": "Password too weak",
  "auth/wrong-password": "Wrong password",
  "auth/expired-action-code": "Invalid verifing code",
  "auth/invalid-action-code": "Invalid verifing code",
  "Do you need help?": "Do you need help?",
  "Contact us": "Contact us",
  "Lost Password?": "Lost Password?",
  "Get a new one": "Get a new one",
  "Reset password": "Reset password",
  "Send request": "Send request",
  "Error in reset password request. User unknown.":
    "Error in reset password request. User unknown."
};
