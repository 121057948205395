export const toolbar = {
  "Change Language": "Change Language",
  Logout: "Logout",
  Home: "Home",
  Resources: "Resources",
  Customers: "Customers",
  Documents: "Documents",
  Reports: "Reports",
  Modules: "Modules",
  Settings: "Settings",
  Users: "Users",
  POIs: "POIs",
  Account: "Account",
  resourceRoutes: "Routes",
  "Help request": "Help request",
  Message: "Message",
  onSending: "Sending message to Overmover helpdesk...",
  onSent: "Your message is correctly registered in our helpdesk.",
  emptyNotifications: "No notifications",
  notifications: "Notifications",
  "Change language": "Change language",
  help: "Request assistance",
  startTour: "Guided tour",
  "Do you need assistance? Send us a quick message and we will answer immediately.":
    "Do you need assistance? Send us a quick message and we will answer immediately.",
  Menu: "Menu",
  clearAll: "Mark as read",
  VerifyTimeTracking: "Verify attendance",
  TimeTracking: "Attendance",
  TaskReport: "Tasks",
  customerList: "Customers list",
  importCustomers: "Import from file"
};
