export const account = {
  name: "Ragione sociale",
  phoneNumber: "Numero di telefono",
  address: "Indirizzo",
  UUID: "Partita IVA",
  email: "Email",

  subscriptionType: "Piano attuale",
  billingDate: "Prossima fattura",
  estimatedInvoice: "Stima importo fattura",

  paymentMethod: "Metodo di pagamento",
  updatingStripeData: "Aggiornamento metodo di pagamento in corso...",
  missingPaymentMethodDialogTitle: "Metodo di pagamento assente",
  missingPaymentMethodDialogBody:
    "Per poter continuare ad utilizzare OverMover occorre inserire un valido metodo di pagamento.",
  missingPaymentMethodNotificationBody1:
    "La sottoscrizione dell'abbonamento scadrà tra ",
  missingPaymentMethodNotificationBody2:
    " giorni. Si prega di inserire un metodo di pagamento valido",

  Account: "Account",
  Subscription: "Abbonamento",
  Payments: "Pagamenti"
};
