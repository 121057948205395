import * as Actions from "../../actions/mainActivityPage/index";

const initialState = {
  taskFormOpen: false,
  detailDataPanelOpen: false,
  managedTaskId: null,
  valuesForForm: {},
  taskListFiltered: []
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.OPEN_FORM:
      return {
        ...state,
        ...action.options
      };
    case Actions.PASS_VALUE:
      return {
        ...state,
        valuesForForm: action.values
      };
    case Actions.SHARE_TASK_FILTERED:
      return {
        ...state,
        taskListFiltered: action.filtered
      };
    default: {
      return state;
    }
  }
};

export default taskReducer;
