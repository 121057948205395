import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ListItemText, ListItem } from "@material-ui/core";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import classNames from "classnames";

const styles = theme => ({
  textContainer: {
    flex: "1 1 auto"
  },
  item: {
    paddingLeft: ".6rem",
    borderBottom: "1px solid " + theme.palette.divider,
    minHeight: 50,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "&.active": {
      backgroundColor: theme.palette.background.paper
    }
  },
  icon: {
    flex: "0 0 auto"
  },
  name: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  }
});

const CustomFormItem = ({ classes, customForm, selected, onClick }) => (
  <ListItem
    button
    className={classNames(classes.item, {
      active: selected
    })}
    onClick={onClick}
    disableGutters
  >
    <div className={classes.textContainer}>
      <ListItemText
        classes={{
          root: "min-w-px",
          primary: classes.name
        }}
        primary={customForm.name}
      />
    </div>
    <KeyboardArrowRightIcon className={classes.icon} />
  </ListItem>
);

CustomFormItem.propTypes = {
  customForm: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired // Fornito da HOC withStyles
};

CustomFormItem.defaultProps = {
  selected: false
};

export default withStyles(styles)(CustomFormItem);
