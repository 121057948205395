export const tasks = {
  filterToYesterday: "Until yesterday",
  filterToday: "Today",
  filterFromTomorrow: "From tomorrow",
  date: "Date",

  description: "Description",
  resource: "Resource",
  when: "When",
  where: "Where",
  distance: "Distance",
  status: "Status",
  read: "Read",
  showTaskDetail: "Show task detail",
  toAssignTask: "To assing task",
  loading: "Updating tasks, wait...",

  //executionstatus
  TOEXECUTE: "To execute",
  COMPLETED: "Completed",
  SUSPENDED: "Suspended",
  ABORTED: "Aborted",
  REJECTED: "Rejected",

  "OG-EXECUTING": "Executing",
  "OG-ENROUTE": "On his way",
  "OG-ARRIVED": "Arrived",

  //transmissionStatus
  SENT: "Sent",
  RECEIVED: "Received",
  READ: "Read",

  //form
  task: "Task",
  company: "Company",
  address: "Address",
  contact: "Contact",
  phone: "Phone number",
  duration: "Duration (min.)",
  beginning: "Beginning",
  ending: "Ending",
  savedAddresses: "Saved addresses",
  googleSearch: "Google search",
  dateSequenceError: "Beginning must precede Ending",
  notify: "Notify",
  before: "before arrive",
  code: "Code",
  warningSameTime: "There's another task at this time",
  executionOrder: "Sequence",

  // Allegati
  attachments: "Attachments",
  taskAttachments: "Task's Attachments",
  errorFetchAttachments: "An error accour while reading attachments",
  noAttachments: "This task has no attachments",
  invalidFile: "Invalid file format",
  invalidFileSize: "File's size exceeds 10MB",
  attachmentCreated: "Attachment uploaded",
  attachmentDeleted: "Attachment deleted",
  errorCreateAttachment: "An error accour while creating the attachment",
  errorUploadFile: "An error accour while uploading the attachment",
  errorDeleteAttachment: "An error accour while deleting the attachment",
  errorLoadPdf: "An error accour while loading pdf",
  errorLoadImage: "An error accour while loading image",
  scannedCode: "Scanned code: ",

  // Moduli
  modules: "Modules",
  taskModules: "Task's Modules",
  noModules: "No modules found",
  noCustomForms: "No custom form has been created yet",

  // Note
  notes: "Notes",
  noNotes: "This task has no notes"
};
