//activity
export const ACTIVITY_FREE = "FREE";
export const ACTIVITY_ENGAGED = "ENGAGED";

//status
export const STATUS_STILL = "STILL";
export const STATUS_MOVING = "MOVING";
export const STATUS_PRIVACY = "PRIVACY";

//deviceStatus
export const PHONE_STATUS_ONLINE = "ONLINE";
export const PHONE_STATUS_OFFLINE = "OFFLINE";

//registration
export const REG_INVITED = "INVITED";
export const REG_NEW = "NEW";
export const REG_TOSENDINVITE = "TOSENDINVITE";
export const REG_VERIFIED = "VERIFIED";
export const REG_DELETED = "DELETED";

//type
export const TYPE_PERSON = "PERSON";
export const TYPE_VEHICLE = "VEHICLE";

// accelerometer
/* soglia per stabilire se il dispositivo è in movimento */
export const ACCELEROMETER_THRESHOLD = 0.04;
