export default {
  chat: "Chat",
  writeMessage: "Scrivi un messaggio",
  upload: "Caricamento file",
  loadMessages: "Carica messaggi",
  image: "Immagine",
  pdf: "Pdf",
  broadcast: "Broadcast",
  write: "Scrivi",
  save: "Salva",
  broadcastName: "Nome broadcast",
  defaultBroadcastName: "Nuovo broadcast",
  broadcastModalMsg:
    "Puoi dare un nome a questo elenco di contatti nel caso volessi inviare messaggi broadcast in futuro alle stesse risorse",
  emptyList: "Nessun broadcast trovato"
};
