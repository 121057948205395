import * as Actions from "../actions";

const initialState = { success: false };

const language = function(state = initialState, action) {
  switch (action.type) {
    case Actions.LANGUAGE_CHANGE: {
      return {
        ...initialState,
        success: true
      };
    }
    default: {
      return state;
    }
  }
};

export default language;
