import { stringTranslate } from "../../../../languages/OMTranslate";
export default [
  {
    target: "#guidedTour-step1",
    content: stringTranslate("tour", "step1"),
    disableBeacon: true,
    placement: "center"
  },
  {
    target: "#guidedTour-step2",
    disableBeacon: true,
    content: stringTranslate("tour", "step2"),
    placement: "right"
  },
  {
    target: "#guidedTour-step3",
    disableBeacon: true,
    content: stringTranslate("tour", "step3"),
    placement: "right"
  },
  {
    target: "#guidedTour-step5",
    disableBeacon: true,
    content: stringTranslate("tour", "step5"),
    placement: "right"
  },
  {
    target: "#guidedTour-step13",
    disableBeacon: true,
    content: stringTranslate("tour", "step6"),
    placement: "left"
  },
  {
    target: "#guidedTour-step13",
    disableBeacon: true,
    content: stringTranslate("tour", "step13"),
    placement: "left"
  },
  {
    target: "#guidedTour-step9",
    disableBeacon: true,
    content: stringTranslate("tour", "step9"),
    placement: "top"
  },
  {
    target: "#guidedTour-step9",
    disableBeacon: true,
    content: stringTranslate("tour", "step10"),
    placement: "top"
  },
  {
    target: "#guidedTour-step11",
    disableBeacon: true,
    content: stringTranslate("tour", "step11"),
    placement: "bottom"
  },
  {
    target: "#guidedTour-step11",
    disableBeacon: true,
    content: stringTranslate("tour", "step12"),
    placement: "bottom"
  },
  {
    target: "#guidedTour-step14",
    disableBeacon: true,
    content: stringTranslate("tour", "step14"),
    placement: "right"
  },
  {
    target: "#guidedTour-step1",
    disableBeacon: true,
    content: stringTranslate("tour", "step18"),
    placement: "center"
  },
  {
    target: "#guidedTour-step1",
    disableBeacon: true,
    content: stringTranslate("tour", "step19"),
    placement: "center"
  },
  {
    target: "#guidedTour-step1",
    disableBeacon: true,
    content: stringTranslate("tour", "step20"),
    placement: "center"
  }
];
