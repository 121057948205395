import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";

import { firestoreConnect } from "react-redux-firebase";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import OMTabSearchAddBar from "../common/OMTabSearchAddBar";
import ModulesList from "./ModulesList";
import ModuleForm from "../modules/module/ModuleForm";
import { stringTranslate } from "languages/OMTranslate";

const styles = theme => ({
  root: {
    padding: theme.measures.panelPadding,
    width: "100%"
  },
  dialogCloseButton: {
    position: "absolute",
    top: theme.spacing.unit,
    right: theme.spacing.unit
  }
});

class ModulesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      moduleDetail: {
        show: false,
        module: undefined
      }
    };
  }
  setSearchText = event => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };

  handleOnAddModule = () => {
    const { customForms } = this.props;
    if (customForms && customForms.length > 0) {
      this.setState({ moduleDetail: { show: true, module: undefined } });
    }
  };

  handleOnCloseModuleDetail = () => {
    this.setState({ moduleDetail: { show: false, module: undefined } });
  };

  handleOnModuleClick = module => {
    this.setState({ moduleDetail: { show: true, module } });
  };

  /**
   * Applica il filtro di ricerca
   * @param {Array} customForms lista di custom form
   * @param {Array} modules lista di moduli
   */
  applyFilter = (customForms, modules) => {
    const { searchText } = this.state;
    if (
      customForms === undefined ||
      customForms.length === 0 ||
      modules === undefined ||
      modules.length === 0
    ) {
      return [];
    }

    let filteredForms = [...customForms];

    if (searchText !== "") {
      // Vengono filtrati i custom form secondo il testo inserito
      filteredForms = customForms.filter(
        form => form.name.toLowerCase().search(searchText.toLowerCase()) > -1
      );
    }

    let orderedModules = [...modules];
    // Vengono ordinati i moduli
    orderedModules = orderedModules.sort(function(m1, m2) {
      if (m1.createdAt.seconds < m2.createdAt.seconds) {
        return 1;
      }
      if (m1.createdAt.seconds > m2.createdAt.seconds) {
        return -1;
      }
      return 0;
    });
    // Vengono ritornati solo i moduli che sono collegati ai custom form filtrati
    return orderedModules.filter(
      m => filteredForms.find(f => f.id === m.customFormId) !== undefined
    );
  };

  render() {
    const { classes, selectedCustomer, modules, customForms } = this.props;
    const { searchText, moduleDetail } = this.state;
    return (
      <React.Fragment>
        <OMTabSearchAddBar
          textChangeHandler={this.setSearchText}
          textClearHandler={() => {
            this.setState({ searchText: "" });
          }}
          addClickHandler={this.handleOnAddModule}
          searchText={searchText}
          withAdd={customForms && customForms.length > 0}
        />
        <ModulesList
          modules={this.applyFilter(customForms, modules)}
          customForms={customForms}
          onItemClick={this.handleOnModuleClick}
        />

        <Dialog
          onClose={this.handleOnCloseModuleDetail}
          aria-labelledby="module-dialog"
          open={moduleDetail.show}
          fullWidth
          scroll="paper"
        >
          <DialogTitle id="module-dialog" disableTypography>
            <Typography variant="h3">
              {`${
                moduleDetail.module
                  ? customForms.find(
                      cf => moduleDetail.module.customFormId === cf.id
                    ).name
                  : stringTranslate("modules", "newModule")
              }`}
            </Typography>
          </DialogTitle>
          <IconButton
            className={classes.dialogCloseButton}
            onClick={this.handleOnCloseModuleDetail}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <ModuleForm
              customForms={customForms}
              module={moduleDetail.module}
              party={selectedCustomer}
              onDeleted={this.handleOnCloseModuleDetail}
            />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

ModulesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  // Proprietà ricavate dallo store
  selectedCustomer: PropTypes.shape().isRequired,
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired
      }).isRequired
    })
  ),
  customForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired
      }).isRequired
    })
  )
};

ModulesTab.defaultProps = {
  modules: [],
  customForms: []
};

const mapStateToProps = state => ({
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer,
  modules: state.firestore.ordered.modules,
  customForms: state.firestore.data.customForms
    ? Object.keys(state.firestore.data.customForms)
        .map(k =>
          state.firestore.data.customForms[k]
            ? {
                id: k,
                ...state.firestore.data.customForms[k]
              }
            : undefined
        )
        .filter(cf => cf !== undefined)
    : undefined
});

export default compose(
  firestoreConnect(),
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(ModulesTab);
