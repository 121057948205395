import * as accountConstants from "main/common/account/accountConstants";

var configMap = {};

configMap[accountConstants.STARTER_EDITION] = {
  toolbar: ["Home", "Resources", "Settings"],
  mainActivity: ["Scheduler", "Tasks", "Chat"],
  taskList: []
};

configMap[accountConstants.PROFESSIONAL_EDITION] = {
  toolbar: ["Customers", "Reports", "TaskReport"],
  mainActivity: ["Map"],
  taskList: ["where"],
  resourceList: ["where"],
  timeTracking: ["TimeTracking"]
};

configMap[accountConstants.ENTERPRISE_EDITION] = {
  toolbar: ["Modules"],
  mainActivity: [],
  taskList: [],
  resourceList: [],
  timeTracking: ["TimeTracking"]
};

function checkAccountPermission(account, context, toCheck) {
  if (!account) {
    return false;
  }

  var edition = account.edition
    ? account.edition
    : accountConstants.STARTER_EDITION;

  return checkConfigMap(edition, context, toCheck);
}

function checkConfigMap(edition, context, toCheck) {
  if (!configMap[edition][context]) return false;
  if (configMap[edition][context].indexOf(toCheck) > -1) return true;

  //verifico le edizioni minori
  if (edition === accountConstants.ENTERPRISE_EDITION)
    return checkConfigMap(
      accountConstants.PROFESSIONAL_EDITION,
      context,
      toCheck
    );
  if (edition === accountConstants.PROFESSIONAL_EDITION)
    return checkConfigMap(accountConstants.STARTER_EDITION, context, toCheck);

  //non esiste il permesso
  if (edition === accountConstants.STARTER_EDITION) return false;
}

export default checkAccountPermission;
