import * as Actions from "../../actions/mainActivityPage/index";

const initialState = {
  chatRooms: [],
  selectedChat: null,
  usedChat: false
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.ADD_ROOM:
      return {
        ...state,
        chatRooms: action.rooms
      };
    case Actions.EMPTY_ROOM_LIST:
      return {
        ...state,
        chatRooms: []
      };
    case Actions.SET_ACTIVE_CHAT:
      return {
        ...state,
        selectedChat: action.room
      };
    case Actions.SET_USED_CHAT:
      return {
        ...state,
        usedChat: action.value
      };
    default: {
      return state;
    }
  }
};

export default chatReducer;
