import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import MapComponent from "./MapComponent";
import Measure from "react-measure";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import TrafficIcon from "@material-ui/icons/Traffic";
import FilterListIcon from "@material-ui/icons/FilterList";
import { stringTranslate } from "languages/OMTranslate";
import { getMomementLocale } from "languages/OMTranslate";
import history from "history.js";
import MapFilter from "./MapFilter";

const styles = theme => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%",
    position: "relative"
  },
  mapContainer: {
    order: 2,
    flex: "1 1 auto",
    position: "relative",
    height: "100%"
  },
  map: {
    position: "absolute",
    width: "100%",
    top: "0px",
    bottom: "0px"
  },
  panelTitle: {
    order: 1,
    flex: "0 0 auto"
  },
  trafficButton: {
    position: "absolute",
    top: "10px",
    right: "60px",
    height: "30px",
    backgroundColor: theme.palette.primary.white,
    borderRadius: "3px",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 6px",
    zIndex: "1",
    display: "block"
  },
  filterActivate: {
    position: "absolute",
    height: "30px",
    paddingLeft: 20,
    paddingTop: 2,
    backgroundColor: theme.palette.primary.white,
    borderRadius: "3px",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 6px",
    zIndex: "1",
    display: "none",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      padding: 0,
      display: "block",
      right: "auto",
      top: "auto",
      left: "10px",
      bottom: "10px"
    }
  },
  filterActivateButton: {
    padding: "6px"
  }
});

class MainActivityMap extends Component {
  state = {
    dimensions: {
      width: -1,
      height: -1
    },
    showCustomers: false,
    showResources: true,
    showTasks: false,
    forceCenter: null,
    forceZoom: null,
    activeFilterButton: false,
    showFilterActivate: true,
    showTraffic: false,
    customerFilterAnchor: null,
    selectedParties: [],
    selectedCategories: []
  };

  handleChange = (name, event) => {
    this.setState({ [name]: event.target.checked, showFilterActivate: true });
  };

  showFilter = () => {
    this.setState({ showFilterActivate: !this.state.showFilterActivate });
  };

  showTraffic = () => {
    this.setState({ showTraffic: !this.state.showTraffic });
  };

  goToReport = resourceId => {
    const url = window.location.origin + "/reports/routes/" + resourceId;
    window.open(url, "Percorso risorsa", "height=700,width=1000");
  };

  toggleCustomerFilterOptions = event => {
    if (!this.state.customerFilterAnchor) {
      this.setState({ customerFilterAnchor: event.currentTarget });
    } else {
      this.setState({ customerFilterAnchor: null });
    }
  };

  handleMultipleSelect = (event, name) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes, pois, resources, tasks, parties } = this.props;
    const { showFilterActivate } = this.state;
    let googleMapURL =
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" +
      process.env.REACT_APP_MAP_KEY +
      "&language=" +
      getMomementLocale();

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.mapContainer}>
            <Measure
              bounds
              onResize={contentRect => {
                this.setState({ dimensions: contentRect.bounds });
              }}
            >
              {({ measureRef, contentRect }) => {
                return (
                  <div className={classes.map} ref={measureRef}>
                    <MapComponent
                      googleMapURL={googleMapURL}
                      resources={resources}
                      pois={pois}
                      tasks={tasks}
                      parties={parties}
                      showResources={this.state.showResources}
                      showParties={this.state.showCustomers}
                      showTasks={this.state.showTasks}
                      dimensions={this.state.dimensions}
                      forceCenter={this.state.forceCenter}
                      forceZoom={this.state.forceZoom}
                      goToReport={this.goToReport}
                      showTraffic={this.state.showTraffic}
                      selectedCategories={this.state.selectedCategories}
                      selectedParties={this.state.selectedParties}
                    />
                  </div>
                );
              }}
            </Measure>
          </div>
          <div className={classes.trafficButton}>
            <Tooltip
              aria-label={stringTranslate("mainactivity", "Show Filter")}
              title={stringTranslate("mainactivity", "Show Filter")}
            >
              <IconButton
                aria-label={stringTranslate("mainactivity", "Show Traffic")}
                className={classes.filterActivateButton}
                onClick={this.showTraffic}
              >
                <TrafficIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>

          {showFilterActivate &&
          window.innerWidth <
            this.props.theme.breakpoints.values[
              this.props.theme.measures.nextBreakpoint
            ] ? (
            <div className={classes.filterActivate}>
              <Tooltip
                aria-label={stringTranslate("mainactivity", "Show Filter")}
                title={stringTranslate("mainactivity", "Show Filter")}
              >
                <IconButton
                  aria-label={stringTranslate("mainactivity", "Show Filter")}
                  className={classes.filterActivateButton}
                  onClick={this.showFilter}
                >
                  <FilterListIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <MapFilter
              showResources={this.state.showResources}
              showCustomers={this.state.showCustomers}
              showTasks={this.state.showTasks}
              handleChange={this.handleChange}
              toggleCustomerFilterOptions={this.toggleCustomerFilterOptions}
              customerFilterAnchor={this.state.customerFilterAnchor}
              poiCategories={
                this.props.poiCategories ? this.props.poiCategories : []
              }
              selectedCategories={this.state.selectedCategories}
              parties={this.props.parties ? this.props.parties : []}
              selectedParties={this.state.selectedParties}
              handleMultipleSelect={this.handleMultipleSelect}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

MainActivityMap.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tasks: PropTypes.array,
  pois: PropTypes.array,
  resources: PropTypes.array,
  parties: PropTypes.array
};

const mapStateToProps = state => ({
  pois: state.firestore.ordered.pois,
  resources: state.firestore.ordered.resources,
  parties: state.firestore.ordered.parties,
  visibleMarkers: state.mainActivityPage.activityMap.visibleMarkers,
  user: state.auth.user,
  poiCategories: state.firestore.ordered.poiCategories
});
const mapDispatchToProps = {};
export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(styles, { withTheme: true })(MainActivityMap));
