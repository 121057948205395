export default {
  dateFormat: "DD/MM/YYYY",
  resource: "Risorsa",
  date: "Data",
  start: "Inizio",
  end: "Fine",
  title: "Modifica timbratura",
  save: "Salva",
  emptyTable: "Nessun dato trovato",
  search: "Cerca",
  selectedRows: "selezionata/e",
  approve: "Conferma selezionate"
};
