import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import MainActivityChatRoomList from "../chat/MainActivityChatRoomList";

const styles = theme => ({
  root: {
    order: 4,
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
    width: theme.measures.chatShouldersWidth,
    border: "1px solid",
    backgroundColor: theme.palette.primary.background,
    borderColor: theme.palette.primary.border,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      order: 3,
      width: "100%",
      flex: "1 1 auto"
    }
  },
  hidden: {
    display: "none"
  }
});

class MainActivityChatContainer extends Component {
  render() {
    const { classes, chatVisibility } = this.props;
    return (
      <div
        id="guidedTour-step13"
        className={classNames(classes.root, { hidden: !chatVisibility })}
      >
        <MainActivityChatRoomList />
      </div>
    );
  }
}

MainActivityChatContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  chatVisibility: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  chatVisibility: state.mainActivityPage.panelVisibility.chatVisibility
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(MainActivityChatContainer)
);
