import React from "react";
import { stringTranslate } from "languages/OMTranslate";
import firebase, { getFirestoreCollection } from "../../../firestoreAccount";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import GroupAdd from "@material-ui/icons/GroupAdd";
import AddCircle from "@material-ui/icons/AddCircle";
import { ExcelRenderer } from "react-excel-renderer";
import Dropzone from "react-dropzone";
import LoadingModal from "../../common/LoadingModal";
import ImportCustomersInstructions from "./ImportCustomersInstructions";
import ImportCustomersPreview from "./ImportCustomersPreview";
import MapPlaceholder from "../common/MapPlaceholder";

const styles = theme => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignSelf: "stretch",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: theme.panels.backgroundColor,
    maxHeight: "calc(100vh - 48px)"
  },
  titleRow: {
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "row",
    padding: theme.measures.panelPadding
  },
  title: {
    display: "flex",
    flexDirection: "row",
    flex: "0 0 auto",
    borderBottom: theme.panels.border
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    flex: 1,
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "8px"
  },
  dropzone: {
    width: "60%",
    height: "200px",
    minHeight: "200px",
    padding: "8px",
    borderRadius: "10px",
    borderWidth: "5px",
    borderColor: "#e9e9ec",
    alignSelf: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dropzoneContainer: {
    display: "flex",
    alignSelf: "stretch",
    flexDirection: "row",
    justifyContent: "center"
  },
  divider: {
    height: "50px",
    display: "flex",
    alignSelf: "stretch"
  },
  errorContainer: {
    display: "flex",
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px"
  }
});

class ImportCustomers extends React.Component {
  state = {
    loading: false,
    error: false,
    success: false,
    rows: [],
    total: 0
  };

  fileHandler = event => {
    let fileObj = event[0];

    this.setState({ loading: true });

    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        this.setState({ loading: false, error: true });
        console.log(err);
      } else {
        var total = 0;
        var rows = resp.rows
          .filter((value, index) => {
            // Salta le righe vuote
            if (value.length <= 0) return false;

            // La prima riga contiene i nomi delle colonne e va saltata
            if (index === 0 && value[0] && value[0].includes("Nome cliente")) {
              return false;
            }

            total++;

            // Controlla che i campi obbligatori ci siano
            if (!value[0] || !value[1]) return false;

            return true;
          })
          .map(value => {
            if (value[5] && (!value[6] || !value[7])) {
              return [
                value[0] + "",
                value[1] + "",
                value[2] + "",
                value[3] + "",
                value[4] + "",
                null,
                null,
                null
              ];
            } else {
              return [
                value[0] + "",
                value[1] + "",
                value[2] + "",
                value[3] + "",
                value[4] + "",
                value[5] + "",
                parseFloat(value[6]),
                parseFloat(value[7])
              ];
            }
          });

        this.setState({
          rows: rows,
          loading: false,
          total: total
        });
      }
    });
  };

  saveCustomers = async () => {
    this.setState({ loading: true });
    const rows = this.state.rows;

    for (let i = 0; i < rows.length; i++) {
      const customer = rows[i];
      const querySnapshot = await firebase
        .firestore()
        .collection(getFirestoreCollection() + "/parties")
        .where("UUID", "==", customer[1])
        .get();

      // Se il cliente esiste già viene aggiornato altrimenti viene creato
      const docRef = null;
      if (querySnapshot.empty) {
        console.log("michi", "creo nuovo utente " + customer[1]);
        docRef = await firebase
          .firestore()
          .collection(getFirestoreCollection() + "/parties")
          .add({
            UUID: customer[1],
            email: customer[3],
            isActive: true,
            isDemo: false,
            name: customer[0],
            phone: customer[4]
          });
      } else {
        const first = querySnapshot.docs[0];
        console.log("michi", "aggiorno utente " + first.data().UUID);
        docRef = first.ref;
        await firebase
          .firestore()
          .collection(getFirestoreCollection() + "/parties")
          .doc(first.id)
          .update({
            email: customer[3],
            isActive: true,
            isDemo: false,
            name: customer[0],
            phone: customer[4]
          });
      }

      if (customer[5]) {
        const foundPoi = await firebase
          .firestore()
          .collection(getFirestoreCollection() + "/pois")
          .where("partyId", "==", docRef.id)
          .where("isMainAddress", "==", true)
          .get();

        const address = await this.findAddress(customer[6], customer[7]);

        if (foundPoi.empty) {
          await firebase
            .firestore()
            .collection(getFirestoreCollection() + "/pois")
            .add({
              address: address,
              isDemo: false,
              isMainAddress: true,
              lat_long: new firebase.firestore.GeoPoint(
                customer[6],
                customer[7]
              ),
              name: customer[5],
              partyId: docRef.id
            });
        } else {
          const poi = foundPoi.docs[0];
          await firebase
            .firestore()
            .collection(getFirestoreCollection() + "/pois")
            .doc(poi.id)
            .update({
              address: address,
              isDemo: false,
              isMainAddress: true,
              lat_long: new firebase.firestore.GeoPoint(
                customer[6],
                customer[7]
              ),
              name: customer[5],
              partyId: docRef.id
            });
        }
      }
    }
    this.setState({ loading: false, rows: [], total: 0, success: true });
  };

  findAddress = (lat, long) => {
    return new Promise(function(resolve, reject) {
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        {
          latLng: new window.google.maps.LatLng(lat, long)
        },
        function(result, status) {
          if (status === window.google.maps.GeocoderStatus.OK && result[0]) {
            resolve(result[0].formatted_address);
          } else {
            reject("");
          }
        }
      );
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <LoadingModal
          text={stringTranslate("customers", "loadingMessage")}
          isOpen={this.state.loading}
        />
        <div style={{ height: "0px", maxHeight: "0px" }}>
          <MapPlaceholder />
        </div>
        <div className={classes.titleRow}>
          <div className={classes.title}>
            <GroupAdd color="primary" />
            <Typography variant="h3" className="px-8 py-4" color="primary">
              {stringTranslate("toolbar", "importCustomers")}
            </Typography>
          </div>
        </div>

        {this.state.rows.length === 0 ? (
          <div className={classes.container}>
            <ImportCustomersInstructions />
            <div className={classes.divider} />
            <Dropzone onDrop={this.fileHandler}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div className={classes.dropzoneContainer}>
                    <div {...getRootProps()} className={classes.dropzone}>
                      <input {...getInputProps()} />
                      <AddCircle style={{ color: "#e9e9ec", fontSize: 70 }} />
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
            {this.state.error ? (
              <div className={classes.errorContainer}>
                <Typography variant="h3" style={{ color: "rgba(230,0,0,0.7)" }}>
                  {stringTranslate("customers", "importErrorMessage")}
                </Typography>
              </div>
            ) : null}
            {this.state.success ? (
              <div className={classes.errorContainer}>
                <Typography variant="h3" style={{ color: "#0091ae" }}>
                  {stringTranslate("customers", "importSuccessMessage")}
                </Typography>
              </div>
            ) : null}
          </div>
        ) : (
          <ImportCustomersPreview
            rows={this.state.rows}
            theme={this.props.theme}
            goBack={() => this.setState({ rows: [], total: 0 })}
            save={this.saveCustomers}
            total={this.state.total}
          />
        )}
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(ImportCustomers);
