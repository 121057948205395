import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IndeterminateCheckBoxOutlined from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
  formControlLabel: {
    marginLeft: "0px",
    marginRight: "0px"
  },
  checkbox: {
    padding: "3px",
    fontSize: "20px"
  },
  label: {
    fontSize: theme.text.small,
    fontWeight: "300"
  },
  checkboxWithOptions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: "3px"
  }
});

const CheckboxWithOptions = ({
  indeterminate,
  checked,
  handleChange,
  openOptions,
  label,
  anchor,
  children,
  classes
}) => {
  const randomId = Math.floor(Math.random() * (100 - 1 + 1)) + 1;

  return (
    <div className={classes.checkboxWithOptions}>
      <FormControlLabel
        control={
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
            checkedIcon={
              indeterminate ? (
                <IndeterminateCheckBoxOutlined style={{ fontSize: 20 }} />
              ) : (
                <CheckBoxIcon style={{ fontSize: 20 }} />
              )
            }
            checked={checked}
            onChange={handleChange}
            value="showTasks"
            color="primary"
            classes={{ root: classes.checkbox }}
          />
        }
        classes={{ root: classes.formControlLabel, label: classes.label }}
        label={""}
      />
      <div onClick={openOptions} style={{ cursor: "pointer" }}>
        <span style={{ fontSize: "12px", fontWeight: 300, marginRight: "5px" }}>
          {label}
        </span>
        <FontAwesomeIcon
          icon={anchor ? ["fas", "caret-up"] : ["fas", "caret-down"]}
          className="mr-4"
        />
      </div>
      <Popper
        id={Boolean(anchor) ? "anchorElement-" + randomId : null}
        open={Boolean(anchor)}
        anchorEl={anchor}
        style={{ zIndex: 10 }}
      >
        <Paper>{children}</Paper>
      </Popper>
    </div>
  );
};
export default withStyles(styles, { withTheme: true })(CheckboxWithOptions);
