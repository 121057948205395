export const general = {
  "Overmover Payoff": "Overmover Payoff inglese",
  "Please enter a valid email": "Please enter a valid email",
  New: "New",
  New_F: "New",
  Edit: "Edit",
  Search: "Search",
  Add: "Add",
  admin: "Admin",
  staff: "Staff",
  user: "user",
  ENABLED: "enabled",
  DISABLED: "disabled",
  WAITING_VALIDATION: "waiting validation",
  Save: "Save",
  Delete: "Delete",
  Cancel: "Cancel",
  Select: "Select",
  Close: "Close",
  Send: "Send",
  Confirm: "Confirm",
  Upload: "Upload",
  "Delete confirm title": "Delete confirm",
  "Delete confirm content": "All data will be lost. Do you confirm delete?",
  onSaving: "Saving data...",
  onSaved: "Data saved.",
  "Not Valid Email": "This is not a valid email address",
  "All resources": "All resources",
  "Visible resources": "Visible resources on map",
  "Show all": "Show all",
  All: "All",
  Today: "Today",
  filter: "Filter",
  from: "From",
  to: "To",

  fsnDateTimeFormat: "MM/dd/yyyy HH:mm",
  momentDateTimeFormat: "MM/DD/YYYY HH:mm",
  momentDateFormat: "MM/DD/YYYY",
  momentTimeFormat: "h:mm A",
  fsnDateFormat: "MM/dd/yyyy",
  fsnTimeFormat: "h:mm aa",
  timeCaption: "Time",

  otherInfo: "Other information",
  "Only Desktop version available for now.":
    "Only Desktop version available for now.",
  "Do you need a mobile version?": "Do you need a mobile version?",
  "Send us a message": "Send us a message",
  offlineStatus: "Service unavailable: there is no internet connection.",
  reconnectAttemptLink: "Click here to retry loading OverMover homepage",
  reconnectAttemptLinkShort: "Click here",
  manualRedirect:
    "Click here if you aren't automatically redirected to your new account",

  demoDataDeletionTitle: "Delete demo data",
  demoDataDeletionText:
    "Demo data are still present. Do you want to proceed deleting them?",
  demoDataDeleting: "Deleting demo data...",

  //login
  "Do more with your workers": "Do more with your workers",
  "Leverage smartphones to coordinate, comunicate, schedule and allocate your workforce. Reduce waste and improve your business.":
    "Leverage smartphones to coordinate, comunicate, schedule and allocate your workforce. Reduce waste and improve your business.",
  //register
  "Try OverMover Free for 30 days": "Try OverMover Free for 30 days",
  "Manage your workforce: Defines To-Do and deadlines, exchange messages and documents, schedule & allocate tasks to your workers.":
    "Manage your workforce: Defines To-Do and deadlines, exchange messages and documents, schedule & allocate tasks to your workers.",
  "Try it free for 30 days - no credit card required.":
    "Try it free for 30 days - no credit card required.",

  "Loading Data...": "Loading Data...",
  "No elements": "No elements",
  "Thank you!": "Thank you!",
  "Your email has been verified": "Your email has been verified",
  "Login to OverMover": "Login to OverMover",
  "Create new password": "Create new password",
  "Modify Password": "Modify Password",
  "An email has been sent to your email address. Follow the instructions to complete the password recovery procedure":
    "An email has been sent to your email address. Follow the instructions to complete the password recovery procedure",
  "Return to login": "Return to login",
  errorOccurred: "Oops! Something went wrong... sorry for inconvenience",
  "Verify email address": "Verify email address",
  "Your email %%address%% is not verified, check the mailbox (and the Spam folder)":
    "Your email %%address%% is not verified, check the mailbox (and the Spam folder)",
  "Resend email": "Resend email",
  Center: "Center"
};
