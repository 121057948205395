import { withStyles } from "@material-ui/core/styles";
import { InputBase } from "@material-ui/core";

/**
 * Componente utilizzato per la selezione dei tipi di campi
 */

export default withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3
    }
  },
  input: {
    width: "auto",
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#F5F5F5",
    fontSize: "1.4rem",
    padding: "10px 26px 10px 12px",
    outline: "none",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff"
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.87)"
    }
  }
}))(InputBase);
