export default {
  step1: "Welcome to OverMover Quick Tour! The tour requires only few minutes.",
  step2: "These icons allows to change Panel of the main console.",
  step3: "The Map Icon allows to show the Map Panel.",
  step4:
    "The Map Panel shows the position and status (free/busy) of your Mobile Workers.",
  step5: "You can click on the Worker Marker to see more details.",
  step6: "The Scheduler Icon allows to show the Scheduler Panel",
  step7: "The Scheduler Panel shows the planning of tasks across workers.",
  step8: "And allows you to organise and plan everybody’s work.",
  step9: "The Tasks Panel show which Tasks are assigned to which Worker",
  step10: "And shows the executions details.",
  step11: "The Chat Icon allows to show Chat Panel...",
  step12: "Here you can send messages to the resources.",
  step13:
    "With the Chat Panel you can exchanges messages and images with your resources on the field.",
  step14:
    "Your account contains some demo data to help you see the power of OverMover.",
  step15:
    "The Demo Data can be removed once you start to invite your Resources. To remove them go to Settings -> Delete demo data",
  step16: "Congratulations! You completed the Quick Tour!"
};
