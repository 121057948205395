import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { InfoWindow } from "react-google-maps";
import { getTaskMarkerIcon } from "./getMarkerIcon";
import {
  getTaskColor,
  getTaskForegroundColor
} from "../scheduler/getTaskColor";
import { Typography } from "@material-ui/core";
import { stringTranslate } from "languages/OMTranslate";

const {
  MarkerWithLabel
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const styles = theme => ({
  infoWindowTitle: {
    borderBottom: "1px solid " + theme.palette.primary.border,
    fontWeight: "bold",
    padding: "4px"
  },
  infoWindowText: {
    fontSize: 12,
    padding: "4px"
  }
});

class TaskMapMarkerComponent extends React.Component {
  state = {
    isOpen: false
  };

  onToggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const props = this.props;
    const poiId = props.task.poiId;

    if (
      !props.allPois ||
      !props.allPois[poiId] ||
      !props.allPois[poiId].lat_long
    ) {
      return <React.Fragment />;
    }

    let customerName = "";
    if (
      props.allCustomer &&
      props.task.partyId &&
      props.allCustomer[props.task.partyId]
    ) {
      customerName = props.allCustomer[props.task.partyId].name;
    }

    const resourceIcon = {
      url: getTaskMarkerIcon(),
      scaledSize: new window.google.maps.Size(15, 20)
    };

    return (
      <React.Fragment>
        <MarkerWithLabel
          position={{
            lat: props.allPois[poiId].lat_long.latitude,
            lng: props.allPois[poiId].lat_long.longitude
          }}
          icon={resourceIcon}
          className="mycolor"
          onClick={this.onToggleOpen}
          labelAnchor={new window.google.maps.Point(25, 0)}
          labelClass="marker-label"
          labelOrigin={new window.google.maps.Point(75, 32)}
          labelVisible={Boolean(props.task.code)}
        >
          <React.Fragment>
            <div>{props.task.code}</div>
            {this.state.isOpen && (
              <InfoWindow
                onCloseClick={this.onToggleOpen}
                options={{ pixelOffset: new window.google.maps.Size(0, -22) }}
                position={{
                  lat: props.allPois[poiId].lat_long.latitude,
                  lng: props.allPois[poiId].lat_long.longitude
                }}
              >
                <React.Fragment>
                  {props.task.code ? (
                    <Typography
                      variant="body1"
                      style={{ fontWeight: "bold", padding: "4px" }}
                    >
                      {stringTranslate("tasks", "code") +
                        `: ${props.task.code}`}
                    </Typography>
                  ) : null}
                  <Typography
                    variant="body1"
                    className={props.classes.infoWindowTitle}
                  >
                    {props.task.description}
                  </Typography>
                  {customerName ? (
                    <Typography
                      variant="body1"
                      className={this.props.classes.infoWindowText}
                    >
                      {customerName}
                    </Typography>
                  ) : null}
                  <Typography
                    variant="body1"
                    className={this.props.classes.infoWindowText}
                  >
                    {props.allPois[poiId].address}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={this.props.classes.infoWindowText}
                    style={{
                      display: "inline",
                      backgroundColor: getTaskColor(props.task),
                      color: getTaskForegroundColor(props.task)
                    }}
                  >
                    {stringTranslate("tasks", props.task.executionStatus)}
                  </Typography>
                </React.Fragment>
              </InfoWindow>
            )}
          </React.Fragment>
        </MarkerWithLabel>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TaskMapMarkerComponent);
