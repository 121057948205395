import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { getFirestoreCollection } from "../../../firestoreAccount";
import { stringTranslate } from "languages/OMTranslate";
import {
  toggleContactModal,
  setSelectedContact
} from "store/actions/customersPage";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextFieldFormsy } from "@fuse";
import Formsy from "formsy-react";
import { Button, IconButton, Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    width: "600px",
    height: "400px",
    top: "calc( 50vh - 200px )",
    left: "calc( 50vw - 300px )",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      width: "80%",
      height: "80%",
      top: "calc( 10vh )",
      left: "calc( 10vw )"
    }
  },
  textField: {
    width: "100%"
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px"
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  }
});

class ContactFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name:
        props.selectedContact && props.selectedContact.id
          ? props.selectedContact.name
          : "",
      phone:
        props.selectedContact && props.selectedContact.id
          ? props.selectedContact.phone
          : "",
      email:
        props.selectedContact && props.selectedContact.id
          ? props.selectedContact.email
          : "",
      errorName: false,
      dialogOpen: false,
      canSubmit: false
    };
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  handleSubmit() {
    const { firestore } = this.props;
    const {
      selectedCustomer,
      selectedContact,
      toggleContactModal
    } = this.props;
    const { name, phone, email } = this.state;
    if (name) {
      var contact = null;
      if (selectedContact && selectedContact.id) {
        contact = firestore
          .collection(getFirestoreCollection() + "/contacts")
          .doc(selectedContact.id);
      } else {
        contact = firestore
          .collection(getFirestoreCollection() + "/contacts")
          .doc();
      }
      if (contact) {
        contact.set({
          name: name,
          phone: phone,
          email: email,
          partyId: selectedCustomer.id
        });
      }
      toggleContactModal();
    } else {
      this.setState({
        errorName: true
      });
    }
  }

  handleDelete() {
    const { firestore } = this.props;
    const {
      selectedContact,
      setSelectedContact,
      toggleContactModal
    } = this.props;

    if (selectedContact.id) {
      firestore
        .collection(getFirestoreCollection() + "/contacts")
        .doc(selectedContact.id)
        .delete()
        .then(() => {
          setSelectedContact(null);
          this.setState({ dialogOpen: false });
          toggleContactModal();
        });
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { classes, toggleContactModal, selectedContact } = this.props;
    const { name, phone, email, canSubmit } = this.state;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Formsy
            onValidSubmit={this.onSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
            ref={form => (this.form = form)}
            className={classes.container}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                {selectedContact && selectedContact.id ? (
                  <Typography variant="h3">
                    {stringTranslate("customers", "Contact Modify")}
                  </Typography>
                ) : (
                  <Typography variant="h3">
                    {stringTranslate("customers", "New Contact")}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <TextFieldFormsy
                  id="contactsName"
                  name="contactsName"
                  label={stringTranslate("customers", "Contact Name")}
                  className={classes.textField}
                  value={name}
                  onChange={this.handleChange("name")}
                  margin="normal"
                  required
                  InputProps={{
                    className: classes.inputText
                  }}
                  InputLabelProps={{
                    className: classes.inputTextLabel
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormsy
                  id="contactsPhone"
                  name="contactsPhone"
                  label={stringTranslate("customers", "Contact Phone")}
                  className={classes.textField}
                  value={phone}
                  onChange={this.handleChange("phone")}
                  margin="normal"
                  InputProps={{
                    className: classes.inputText
                  }}
                  InputLabelProps={{
                    className: classes.inputTextLabel
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormsy
                  id="contactsEmail"
                  name="contactsEmail"
                  label={stringTranslate("customers", "Contact Email")}
                  className={classes.textField}
                  value={email}
                  onChange={this.handleChange("email")}
                  margin="normal"
                  validations="isEmail"
                  validationError={stringTranslate(
                    "general",
                    "Not Valid Email"
                  )}
                  InputProps={{
                    className: classes.inputText
                  }}
                  InputLabelProps={{
                    className: classes.inputTextLabel
                  }}
                />
              </Grid>
              <Grid item xs={12} className="mt-12">
                <Button
                  variant="contained"
                  color="secondary"
                  className="mr-12"
                  onClick={() => this.handleSubmit()}
                  disabled={!canSubmit}
                >
                  {stringTranslate("general", "Save")}
                </Button>
                {selectedContact && selectedContact.id ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className="mr-12"
                    onClick={() => this.setState({ dialogOpen: true })}
                  >
                    {stringTranslate("general", "Delete")}
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <IconButton
              className={classes.closeButton}
              onClick={() => {
                setSelectedContact(null);
                toggleContactModal();
              }}
            >
              <CloseIcon />
            </IconButton>
            <Dialog
              open={this.state.dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {stringTranslate("general", "Delete confirm title")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {stringTranslate("general", "Delete confirm content")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ dialogOpen: false })}
                  color="primary"
                >
                  {stringTranslate("general", "Cancel")}
                </Button>
                <Button
                  onClick={() => this.handleDelete()}
                  color="secondary"
                  autoFocus
                >
                  {stringTranslate("general", "Delete")}
                </Button>
              </DialogActions>
            </Dialog>
          </Formsy>
        </div>
      </React.Fragment>
    );
  }
}

ContactFormModal.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer
});

const mapDispatchToProps = dispatch => ({
  setSelectedContact: contact => dispatch(setSelectedContact(contact)),
  toggleContactModal: () => dispatch(toggleContactModal())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(ContactFormModal);
