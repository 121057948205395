export default function handleIndexEE(
  stepIndex,
  chatToggle,
  chatVisibility,
  chatRooms,
  setActiveChat,
  mapToggle,
  mapVisibility,
  taskListToggle,
  taskListVisibility,
  calendarToggle,
  calendarVisibility,
  userListToggle,
  userListVisibility,
  resources
) {
  switch (stepIndex) {
    case 4:
    case 5:
      if (!chatVisibility) {
        chatToggle();
      }
      break;
    case 6:
      if (!chatVisibility) {
        chatToggle();
      }
      if (chatRooms.length > 0) {
        if (resources && resources.length) {
          const mb = resources.find(item => item.name === "Martin Bauer");
          if (mb) {
            const selectedChat = chatRooms.find(
              item => item.resourceId === mb.id
            );
            if (selectedChat) {
              setActiveChat(selectedChat);
            } else {
              setActiveChat(chatRooms[0]);
            }
          } else {
            setActiveChat(chatRooms[0]);
          }
        }
      }
      if (!mapVisibility) {
        mapToggle();
      }
      break;
    case 7:
      if (!chatVisibility) {
        chatToggle();
      }
      if (!mapVisibility) {
        mapToggle();
      }
      break;
    case 8:
      if (!mapVisibility) {
        mapToggle();
      }
      if (!taskListVisibility) {
        taskListToggle();
      }
      break;
    case 9:
      if (!mapVisibility) {
        mapToggle();
      }
      if (calendarVisibility) {
        calendarToggle();
      }
      if (!taskListVisibility) {
        taskListToggle();
      }
      break;
    case 10:
      if (!taskListVisibility) {
        taskListToggle();
      }
      if (!calendarVisibility) {
        calendarToggle();
      }
      if (mapVisibility) {
        mapToggle();
      }
      break;
    case 11:
      if (!calendarVisibility) {
        calendarToggle();
      }
      if (mapVisibility) {
        mapToggle();
      }
      if (!taskListVisibility) {
        taskListToggle();
      }
      break;
    case 12:
      if (mapVisibility) {
        mapToggle();
      }
      if (!calendarVisibility) {
        calendarToggle();
      }
      if (!userListVisibility) {
        userListToggle();
      }
      break;
    case 13:
      if (mapVisibility) {
        mapToggle();
      }
      if (!calendarVisibility) {
        calendarToggle();
      }
      if (!userListVisibility) {
        userListToggle();
      }
      break;
    case 14:
      if (!userListVisibility) {
        userListToggle();
      }
      break;
    default:
      break;
  }
}
