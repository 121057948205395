export const register = {
  "Create An Account": "Create An Account",
  "Display name": "Display name",
  Email: "Email",
  Password: "Password",
  "Confirm password": "Confirm password",
  Register: "Register",
  "Already have an account?": "Already have an account?",
  "Passwords do not match": "Passwords do not match",

  acceptCondition: "I hereby accept the",
  policyLink: "Terms of Use",
  acceptCondition2: "and the",
  policyLink2: "Privacy Policy",

  acceptError: "The terms of service must be accepted",
  role: "Ruolo",
  Company: "Company",
  "First Name": "First Name",
  "Last Name": "Last Name"
};
