import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate } from "languages/OMTranslate";
import { FuseAnimate } from "@fuse";
import UserCard from "./UserCard";
import UserForm from "./UserForm";
import OMSearchAddBar from "main/content/common/OMSearchAddBar";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    padding: "24px",
    backgroundColor: theme.palette.primary.background,
    minHeight: "calc(100vh - 48px)"
  },
  header: { display: "flex", flex: "flex-row" },
  title: { flex: "1 1 auto", border: "1px none red", order: "1" },
  actions: {
    flex: "0 0 auto",
    border: "1px none red",
    order: "2",
    width: "300px"
  },
  addbutton: {
    fontSize: "44px",
    color: theme.palette.secondary.main
  },
  content: {
    display: "flex",
    marginTop: "12px",
    flexWrap: "wrap"
  },
  icon: {
    color: theme.palette.primary.main
  },
  textSmall: {
    fontSize: theme.text.small
  }
});

class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modifingUserUid: null,
      addNewUser: false,
      searchText: ""
    };
  }

  setSearchText = event => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };
  clearText = () => {
    this.setState({ searchText: "" });
  };

  nullModifingUser() {
    this.setState({
      modifingUserUid: null
    });
  }

  addNewUser() {
    this.setState({
      addNewUser: true
    });
  }

  closeNewUser() {
    this.setState({
      addNewUser: false
    });
  }

  setModifingUser(user) {
    this.setState({
      modifingUserUid: user.uid
    });
  }

  render() {
    const { classes, users } = this.props;
    const { modifingUserUid, addNewUser, searchText } = this.state;

    const filteredUsers = users
      ? users.filter(user => {
          var found = false;
          if (
            user.data.displayName
              .toLowerCase()
              .search(searchText.toLowerCase()) > -1
          ) {
            found = true;
          }
          if (
            user.data.email.toLowerCase().search(searchText.toLowerCase()) > -1
          ) {
            found = true;
          }

          return found;
        })
      : [];
    if (filteredUsers) {
      filteredUsers.sort(function(x, y) {
        if (
          x.data.displayName.toLowerCase() < y.data.displayName.toLowerCase()
        ) {
          return -1;
        }
        if (
          x.data.displayName.toLowerCase() > y.data.displayName.toLowerCase()
        ) {
          return 1;
        }
        return 0;
      });
    }

    return (
      <div
        className={classes.root}
        ref={root => {
          this.root = root;
        }}
      >
        <div className={classes.header}>
          <div className={classes.title}>
            <Typography variant="h3" classes={{ root: "mt-12" }}>
              {stringTranslate("users", "Users list")}
            </Typography>
          </div>
          <div className={classes.actions}>
            <OMSearchAddBar
              searchText={searchText}
              textChangeHandler={this.setSearchText}
              textClearHandler={this.clearText}
              addClickHandler={() => this.addNewUser()}
              withAdd
            />
          </div>
        </div>
        <div className={classes.content}>
          {addNewUser ? (
            <FuseAnimate
              animation="transition.fadeIn"
              duration={400}
              delay={400}
            >
              <UserForm
                user={{}}
                setModifingUser={() => this.nullModifingUser()}
                closeNewUser={() => this.closeNewUser()}
              />
            </FuseAnimate>
          ) : (
            ""
          )}
          {filteredUsers &&
            filteredUsers.map((user, index) => {
              return user.id === modifingUserUid ? (
                <UserForm
                  key={index}
                  user={user}
                  setModifingUser={() => this.nullModifingUser()}
                  closeNewUser={() => this.closeNewUser()}
                />
              ) : (
                <UserCard
                  key={index}
                  user={user}
                  setModifingUser={() => this.setModifingUser(user)}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

UsersPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  users: PropTypes.array
};

const mapStateToProps = state => ({
  users: state.firestore.ordered.users
});

const mapDispatchToProps = dispatch => ({});

export default compose(
  firestoreConnect(props => {
    return [
      {
        collection: "users",
        where: ["accountId", "==", localStorage.getItem("accountId")],
        storeAs: "users"
      }
    ];
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(UsersPage);
