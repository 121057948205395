import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import classNames from "classnames";
import {
  Icon,
  Button,
  Drawer,
  Tooltip,
  Typography,
  Modal,
  IconButton
} from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  stringTranslate,
  getLanguageIcon,
  getLanguageLabel
} from "languages/OMTranslate";
import { Menu as MenuIcon } from "@material-ui/icons";
import { ChevronRight as ChevronRightIcon } from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import HamburgerMenuItem from "./HamburgerMenuItem";
import MobileHelpdeskModal from "./MobileHelpdeskModal";
import NotificationsCounter from "./NotificationsCounter";
import NotificationsList from "./NotificationsList";
import Close from "@material-ui/icons/Close";
import {
  setActiveChat,
  chatToggle,
  hideAllPanels,
  userListToggle
} from "store/actions/mainActivityPage";
import { openDeleteDemoDataDialog } from "store/actions/fuse";
import notificationClickHandler from "./notificationClickHandler";
import firebase, { getFirestoreCollection } from "firestoreAccount";
import history from "history.js";
import checkAccountPermission from "main/common/account/accountEditionConfig";
import { withRouter } from "react-router-dom";

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    flexShrink: 0
  },
  drawerContent: {
    width: "200px"
  },
  submenu: {
    borderTop: "1px solid " + theme.palette.primary.panelBorder,
    backgroundColor: theme.palette.primary.background,
    borderBottom: "1px solid " + theme.palette.primary.panelBorder,
    paddingLeft: "10px"
  },
  listItemText: {
    paddingRight: "5px"
  },
  notificationsModal: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    width: "80vw",
    height: "80vh",
    top: "10vh",
    left: "10vw"
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px"
  },
  languageFlag: {
    width: "24px"
  }
});

class HamburgerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      reportMenu: false,
      settingsMenu: false,
      helpdeskMenu: false,
      languageMenu: false,
      openNotificationsModal: false
    };
    this.menuRef = React.createRef();
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      reportMenu: false,
      settingsMenu: false,
      languageMenu: false
    });
  };

  toggleReportMenu = () => {
    this.setState({ reportMenu: !this.state.reportMenu });
  };
  toggleLanguageMenu = () => {
    this.setState({ languageMenu: !this.state.languageMenu });
  };
  toggleHelpdeskMenu = () => {
    this.setState({ helpdeskMenu: !this.state.helpdeskMenu });
  };
  toggleSettingstMenu = () => {
    this.setState({ settingsMenu: !this.state.settingsMenu });
  };

  toggleNotifications = () => {
    this.setState({
      openNotificationsModal: !this.state.openNotificationsModal
    });
  };

  handleNotificationClick = event => {
    if (this.props.userListVisibility) {
      this.props.userListToggle();
    }
    this.props.hideAllPanels();
    notificationClickHandler({
      notificationId: event.id,
      type: event.type,
      sourceId: event.sourceId,
      ...this.props
    });
    this.toggleNotifications();
    this.handleClose();
  };

  clearAllNotification = () => {
    const collection = getFirestoreCollection() + "/notifications";
    firebase
      .firestore()
      .collection(collection)
      .where("status", "==", "NEW")
      .get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          doc.ref.update({ status: "READ" });
        });
        this.toggleNotifications();
      })
      .catch(error => console.log(error));
  };

  handleGuidedTour = event => {
    if (!this.props.user.showGuidedTour) {
      firebase
        .firestore()
        .collection("users")
        .doc(this.props.user.uid)
        .update({ showGuidedTour: true, stepIndex: this.props.user.stepIndex })
        .then(result => {
          this.setState({ helpMenu: null }, () => {
            history.replace({ pathname: "/" });
            window.location.reload();
          });
        });
    }
  };

  render() {
    const { anchorEl, reportMenu, settingsMenu, languageMenu } = this.state;
    const { classes, theme, role, account } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Tooltip title={stringTranslate("toolbar", "Menu")}>
            <Button className="h-48" onClick={this.handleClick}>
              <NotificationsCounter>
                <MenuIcon ref={this.menuRef} />
              </NotificationsCounter>
            </Button>
          </Tooltip>
          <Drawer
            variant="persistent"
            anchor="right"
            open={Boolean(anchorEl)}
            className={classes.drawer}
          >
            <div className={classes.drawerContent}>
              <div className="text-right">
                <Button className="h-48" onClick={this.handleClose}>
                  <ChevronRightIcon />
                </Button>
              </div>
              <Divider />
              {checkAccountPermission(account, "toolbar", "Home") && (
                <HamburgerMenuItem
                  to="/mainActivity"
                  onClick={this.handleClose}
                  label={stringTranslate("toolbar", "Home")}
                />
              )}
              {checkAccountPermission(account, "toolbar", "Resources") && (
                <HamburgerMenuItem
                  to="/resources"
                  onClick={this.handleClose}
                  label={stringTranslate("toolbar", "Resources")}
                />
              )}
              {checkAccountPermission(account, "toolbar", "Customers") && (
                <HamburgerMenuItem
                  to="/customers"
                  onClick={this.handleClose}
                  label={stringTranslate("toolbar", "Customers")}
                />
              )}
              {checkAccountPermission(account, "toolbar", "Reports") && (
                <HamburgerMenuItem
                  to="#"
                  onClick={this.toggleReportMenu}
                  label={stringTranslate("toolbar", "Reports")}
                >
                  <Icon style={{ color: theme.palette.primary.dark }}>
                    keyboard_arrow_down
                  </Icon>
                </HamburgerMenuItem>
              )}

              {checkAccountPermission(account, "toolbar", "Reports") &&
                reportMenu && (
                  <div className={classes.submenu}>
                    <HamburgerMenuItem
                      to="/reports/routes"
                      onClick={this.handleClose}
                      label={stringTranslate("toolbar", "resourceRoutes")}
                    />
                    {checkAccountPermission(
                      account,
                      "timeTracking",
                      "TimeTracking"
                    ) ? (
                      <HamburgerMenuItem
                        to="/reports/timeTracking"
                        onClick={this.handleClose}
                        label={stringTranslate("toolbar", "TimeTracking")}
                      />
                    ) : null}
                    <HamburgerMenuItem
                      to="/reports/resources"
                      onClick={this.handleClose}
                      label={stringTranslate("toolbar", "Resources")}
                    />
                    {checkAccountPermission(
                      account,
                      "toolbar",
                      "TaskReport"
                    ) && (
                      <HamburgerMenuItem
                        to="/reports/tasks"
                        onClick={this.handleClose}
                        label={stringTranslate("toolbar", "TaskReport")}
                      />
                    )}
                  </div>
                )}
              {checkAccountPermission(account, "toolbar", "Modules") && (
                <HamburgerMenuItem
                  to="/modules"
                  onClick={this.handleClose}
                  label={stringTranslate("toolbar", "Modules")}
                />
              )}
              {checkAccountPermission(account, "toolbar", "Settings") &&
                role === "admin" && (
                  <HamburgerMenuItem
                    to="#"
                    onClick={this.toggleSettingstMenu}
                    label={stringTranslate("toolbar", "Settings")}
                  >
                    <Icon style={{ color: theme.palette.primary.dark }}>
                      keyboard_arrow_down
                    </Icon>
                  </HamburgerMenuItem>
                )}
              {checkAccountPermission(account, "toolbar", "Settings") &&
                role === "admin" &&
                settingsMenu && (
                  <div className={classes.submenu}>
                    <HamburgerMenuItem
                      to="/users"
                      onClick={this.handleClose}
                      label={stringTranslate("toolbar", "Users")}
                    />
                    <HamburgerMenuItem
                      to="/account"
                      onClick={this.handleClose}
                      label={stringTranslate("toolbar", "Account")}
                    />
                    {checkAccountPermission(
                      account,
                      "timeTracking",
                      "TimeTracking"
                    ) ? (
                      <HamburgerMenuItem
                        to="/timeTracking"
                        onClick={this.handleClose}
                        label={stringTranslate("toolbar", "VerifyTimeTracking")}
                      />
                    ) : null}
                    {account && !account.demoDataDeleted && (
                      <HamburgerMenuItem
                        to="#"
                        onClick={() => this.props.openDeleteDemoDataPopup()}
                        label={stringTranslate(
                          "general",
                          "demoDataDeletionTitle"
                        )}
                      />
                    )}
                  </div>
                )}
              <HamburgerMenuItem to="#" onClick={this.toggleNotifications}>
                <NotificationsCounter>
                  <Typography
                    variant="body1"
                    className={classNames(classes.listItemText)}
                  >
                    {stringTranslate("toolbar", "notifications")}
                  </Typography>
                </NotificationsCounter>
              </HamburgerMenuItem>
              <MobileHelpdeskModal />
              <HamburgerMenuItem to="#" onClick={this.handleGuidedTour}>
                <Typography
                  variant="body1"
                  className={classNames(classes.listItemText)}
                >
                  {stringTranslate("toolbar", "startTour")}
                </Typography>
              </HamburgerMenuItem>
              <HamburgerMenuItem
                to="#"
                onClick={this.toggleLanguageMenu}
                label={stringTranslate("toolbar", "Change Language")}
              >
                <Icon style={{ color: theme.palette.primary.dark }}>
                  keyboard_arrow_down
                </Icon>
              </HamburgerMenuItem>

              {languageMenu ? (
                <div className={classes.submenu}>
                  <HamburgerMenuItem
                    to="#"
                    onClick={() => {
                      this.props.changeUserLanguage("en-US");
                      this.handleClose();
                    }}
                    label={getLanguageLabel("en-US")}
                  >
                    <img
                      alt=""
                      className={classNames(classes.languageFlag)}
                      src={getLanguageIcon("en-US")}
                    />
                  </HamburgerMenuItem>

                  <HamburgerMenuItem
                    to="#"
                    onClick={() => {
                      this.props.changeUserLanguage("it-IT");
                      this.handleClose();
                    }}
                    label={getLanguageLabel("it-IT")}
                  >
                    <img
                      alt=""
                      className={classNames(classes.languageFlag)}
                      src={getLanguageIcon("it-IT")}
                    />
                  </HamburgerMenuItem>
                </div>
              ) : (
                ""
              )}
              <HamburgerMenuItem
                to="#"
                onClick={this.props.logout}
                label={stringTranslate("toolbar", "Logout")}
              >
                <Icon style={{ color: theme.palette.primary.dark }}>
                  exit_to_app
                </Icon>
              </HamburgerMenuItem>
            </div>
          </Drawer>
        </div>
        <Modal
          open={this.state.openNotificationsModal}
          onClose={this.toggleNotifications}
        >
          <div className={classNames(classes.notificationsModal)}>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: "10px"
                }}
              >
                <div style={{ alignSelf: "flex-start" }}>
                  <Typography variant="h3">
                    {stringTranslate("toolbar", "notifications")}
                  </Typography>
                </div>
              </div>
              <div
                style={{ display: "flex", flex: 1, flexDirection: "column" }}
              >
                <div style={{ alignSelf: "flex-end" }}>
                  <IconButton onClick={this.toggleNotifications}>
                    <Close />
                  </IconButton>
                </div>
              </div>
            </div>
            <NotificationsList
              notifications={this.props.notifications}
              language={this.props.language}
              onClick={this.handleNotificationClick}
              clearAll={this.clearAllNotification}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  language: state.auth.user.data.userLanguage,
  role: state.auth.user.role,
  notifications: state.mainActivityPage.notifications.notifications,
  chatRooms: state.mainActivityPage.chat.chatRooms,
  chatVisibility: state.mainActivityPage.panelVisibility.chatVisibility,
  userListVisibility: state.mainActivityPage.panelVisibility.userListVisibility,
  account: state.firestore.data.account,
  user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  setActiveChat: room => dispatch(setActiveChat(room)),
  chatToggle: () => dispatch(chatToggle()),
  hideAllPanels: () => dispatch(hideAllPanels()),
  userListToggle: () => dispatch(userListToggle()),
  openDeleteDemoDataPopup: () => dispatch(openDeleteDemoDataDialog())
});

export default withRouter(
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(withStyles(styles, { withTheme: true })(HamburgerMenu))
);
