import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { showResource, showAllResources } from "store/actions/mainActivityPage";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { stringTranslate } from "languages/OMTranslate";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

const styles = theme => ({
  root: {
    order: 1,
    flex: "0 0 auto",
    width: "100%",
    display: "flex",
    height: 48,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 10,
    backgroundColor: theme.panels.backgroundColor,
    borderBottom: theme.panels.border,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      display: "none"
    }
  },
  hidden: {},
  button: {
    marginLeft: 2 * theme.spacing.unit,
    marginTop: "-8px"
  }
});

class MainActivityTitleContainer extends Component {
  emptySelectedResource = () => {
    this.props.showResource(null);
    this.props.showAllResources(true);
  };

  render() {
    const {
      classes,
      selectedResource,
      indexedResources,
      searchedResources,
      resourcesArray,
      mapVisibileResources,
      mapVisibility,
      calendarVisibility,
      taskListVisibility
    } = this.props;

    if (!mapVisibility && !calendarVisibility && !taskListVisibility) {
      return null;
    }
    if (selectedResource) {
      const resourceName = indexedResources[selectedResource]
        ? indexedResources[selectedResource].name
        : "";

      return (
        <div className={classNames(classes.root)}>
          <Typography variant="h3">{resourceName}</Typography>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            size="small"
            onClick={this.emptySelectedResource}
          >
            {stringTranslate("general", "Show all")}
          </Button>
        </div>
      );
    } else {
      if (
        (searchedResources &&
          resourcesArray &&
          searchedResources.length !== resourcesArray.length) ||
        (mapVisibileResources &&
          resourcesArray &&
          mapVisibileResources.length !== resourcesArray.length)
      ) {
        return (
          <div className={classNames(classes.root)}>
            <Typography variant="h3">
              {stringTranslate("general", "Visible resources")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              size="small"
              onClick={this.emptySelectedResource}
            >
              {stringTranslate("general", "Show all")}
            </Button>
          </div>
        );
      } else {
        return (
          <div className={classNames(classes.root)}>
            <Typography variant="h3">
              {stringTranslate("general", "All resources")}
            </Typography>
          </div>
        );
      }
    }
  }
}

MainActivityTitleContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  indexedResources: state.firestore.data.resources,
  resourcesArray: state.firestore.ordered.resources,
  selectedResource: state.mainActivityPage.resourceList.selectedResource,
  searchedResources: state.mainActivityPage.resourceList.searchedResources,
  mapVisibileResources: state.mainActivityPage.activityMap.visibleResources,
  mapVisibility: state.mainActivityPage.panelVisibility.mapVisibility,
  taskListVisibility: state.mainActivityPage.panelVisibility.taskListVisibility,
  calendarVisibility: state.mainActivityPage.panelVisibility.calendarVisibility
});

const mapDispatchToProps = dispatch => ({
  showResource: resourceId => dispatch(showResource(resourceId)),
  showAllResources: isToShow => dispatch(showAllResources(isToShow))
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(styles, { withTheme: true })(MainActivityTitleContainer));
