import React from "react";
import { getMomementLocale } from "languages/OMTranslate";
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap } from "react-google-maps";

const mapEnvironment = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" +
      process.env.REACT_APP_MAP_KEY +
      "&language=" +
      getMomementLocale(),
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div id="mapContainer" style={{ height: `100%`, width: `100%` }} />
    ),
    mapElement: <div id="mapElement" style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
);

export default mapEnvironment;

export const mapEnvironmentScript = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" +
      process.env.REACT_APP_MAP_KEY +
      "&language=" +
      getMomementLocale(),
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div id="mapContainer" style={{ height: `100%`, width: `100%` }} />
    ),
    mapElement: <div id="mapElement" style={{ height: `0px` }} />
  }),
  withScriptjs,
  withGoogleMap
);
