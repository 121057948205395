import React from "react";
import {
  AppBar,
  Button,
  Input,
  Paper,
  Toolbar,
  Tooltip,
  withStyles
} from "@material-ui/core";
import PropTypes from "prop-types";
import { stringTranslate } from "languages/OMTranslate";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import classNames from "classnames";

const styles = theme => ({
  addButton: {
    marginLeft: theme.spacing.unit,
    boxShadow: theme.addButton.boxShadow
  },
  appbar: {
    backgroundColor: theme.palette.primary.background
  },
  toolbar: {}
});

const OMSearchAddBar = ({
  id,
  classes,
  disableSearchCondition,
  searchText,
  textChangeHandler,
  textClearHandler,
  addClickHandler,
  withAdd
}) => (
  <AppBar position="static" elevation={0} className={classes.appbar}>
    <Toolbar
      className={classNames("px-6", "py-6", classes.toolbar)}
      disableGutters
      variant="dense"
    >
      <Paper
        className="flex flex-1 p-4 items-center w-full px-8 py-4 rounded-4"
        elevation={1}
      >
        <SearchIcon
          className="mr-8"
          color={disableSearchCondition ? "disabled" : "action"}
        />

        <Input
          placeholder={stringTranslate("general", "Search") + ".."}
          className="flex flex-1"
          disableUnderline
          fullWidth
          disabled={disableSearchCondition}
          value={searchText}
          inputProps={{
            "aria-label": stringTranslate("general", "Search") + ".."
          }}
          onChange={textChangeHandler}
        />

        {searchText && (
          <ClearIcon
            className="ml-8"
            color={disableSearchCondition ? "disabled" : "action"}
            onClick={textClearHandler}
          />
        )}
      </Paper>
      {withAdd && (
        <Tooltip title={stringTranslate("general", "Add")}>
          <Button
            id={id ? id : undefined}
            variant="fab"
            color="secondary"
            mini
            onClick={addClickHandler}
            className={classes.addButton}
            aria-label={stringTranslate("general", "Add")}
          >
            <AddIcon />
          </Button>
        </Tooltip>
      )}
    </Toolbar>
  </AppBar>
);

OMSearchAddBar.propTypes = {
  classes: PropTypes.object.isRequired,
  searchText: PropTypes.string.isRequired,
  textChangeHandler: PropTypes.func.isRequired,
  textClearHandler: PropTypes.func.isRequired,
  disableSearchCondition: PropTypes.bool,
  withAdd: PropTypes.bool,
  addClickHandler: PropTypes.func
};

OMSearchAddBar.defaultProps = {
  withAdd: false,
  disableSearchCondition: false
};

export default withStyles(styles, { withTheme: true })(OMSearchAddBar);
