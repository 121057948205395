import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Avatar from "@material-ui/core/Avatar";

class ResourceView extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    schedulerData: PropTypes.object.isRequired,
    contentScrollbarHeight: PropTypes.number.isRequired,
    slotClickedFunc: PropTypes.func,
    slotItemTemplateResolver: PropTypes.func
  };

  render() {
    const {
      schedulerData,
      contentScrollbarHeight,
      slotClickedFunc,
      slotItemTemplateResolver,
      schedulerResources,
      showAvatar
    } = this.props;
    const { renderData } = schedulerData;

    let width = schedulerData.getResourceTableWidth() - 2;
    let paddingBottom = contentScrollbarHeight;
    let resourceList = renderData.map(item => {
      let avatarPicture = null;
      let avatarString = null;
      schedulerResources.forEach(element => {
        if (element.id == item.slotId && element.avatarString) {
          avatarPicture = element.avatarPicture;
          avatarString = element.avatarString;
        }
      });

      let a =
        slotClickedFunc != undefined ? (
          <a
            onClick={() => {
              slotClickedFunc(schedulerData, item);
            }}
          >
            <span className="expander-space" />
            {item.slotName}
          </a>
        ) : avatarString && showAvatar ? (
          <Avatar
            className="my-4 mx-auto"
            src={avatarPicture}
            alt={item.slotName}
          >
            {avatarString}
          </Avatar>
        ) : (
          <span>
            <span className="expander-space" />
            <span>{item.slotName}</span>
          </span>
        );
      let slotItem = (
        <div
          title={item.slotName}
          className="header2-text"
          style={{ textAlign: "left" }}
        >
          {a}
        </div>
      );
      if (!!slotItemTemplateResolver) {
        let temp = slotItemTemplateResolver(
          schedulerData,
          item,
          slotClickedFunc,
          width,
          "header2-text"
        );
        if (!!temp) slotItem = temp;
      }

      return (
        <tr key={item.slotId}>
          <td data-resource-id={item.slotId} style={{ height: item.rowHeight }}>
            {slotItem}
          </td>
        </tr>
      );
    });

    return (
      <div style={{ paddingBottom: paddingBottom }}>
        <table className="resource-table">
          <tbody>{resourceList}</tbody>
        </table>
      </div>
    );
  }
}

export default ResourceView;
