import PropTypes from "prop-types";
import React from "react";
import { View, Keyboard, ViewPropTypes } from "react-native";
import { Composer, Send, Actions } from "react-web-gifted-chat";
import { fuseThemesConfig } from "../../../../fuse-configs/fuseThemesConfig";
import { withStyles, Snackbar, CircularProgress } from "@material-ui/core";
import { stringTranslate } from "languages/OMTranslate";

class InputChat extends React.Component {
  constructor(props) {
    super(props);

    this.keyboardWillShow = this.keyboardWillShow.bind(this);
    this.keyboardWillHide = this.keyboardWillHide.bind(this);

    this.state = {
      position: "static"
    };
  }

  componentWillMount() {
    this.keyboardWillShowListener = Keyboard.addListener(
      "keyboardWillShow",
      this.keyboardWillShow
    );
    this.keyboardWillHideListener = Keyboard.addListener(
      "keyboardWillHide",
      this.keyboardWillHide
    );
  }

  componentWillUnmount() {
    this.keyboardWillShowListener.remove();
    this.keyboardWillHideListener.remove();
  }

  keyboardWillShow() {
    if (this.state.position !== "relative") {
      this.setState({
        position: "relative"
      });
    }
  }

  keyboardWillHide() {
    if (this.state.position !== "absolute") {
      this.setState({
        position: "absolute"
      });
    }
  }

  renderActions() {
    if (this.props.renderActions) {
      return this.props.renderActions(this.props);
    } else if (this.props.onPressActionButton) {
      return <Actions {...this.props} />;
    }
    return null;
  }

  renderSend() {
    if (this.props.renderSend) {
      return this.props.renderSend(this.props);
    }
    return <Send {...this.props} />;
  }

  renderComposer() {
    if (this.props.renderComposer) {
      return this.props.renderComposer(this.props);
    }

    return <Composer {...this.props} />;
  }

  renderAccessory() {
    if (this.props.renderAccessory) {
      return (
        <View style={[styles.accessory, this.props.accessoryStyle]}>
          {this.props.renderAccessory(this.props)}
        </View>
      );
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            classes={{ root: classes.mysnackbar }}
            ContentProps={{ classes: { root: classes.snackbarContent } }}
            open={this.props.uploading}
            message={
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <CircularProgress color="secondary" size={20} />
                <span style={{ marginLeft: "10px" }} id="message-id">
                  {stringTranslate("chat", "upload")}
                </span>
              </div>
            }
          />
        </div>
        <div className={classes.container}>
          {this.renderActions()}
          {this.renderComposer()}
          {this.renderSend()}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    height: 50,
    marginLeft: 6,
    marginRight: 6,
    marginBottom: 6,
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 4,
    paddingLeft: 4,
    borderRadius: 8,
    backgroundColor: fuseThemesConfig.beach.palette.primary.white,
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
  },
  mysnackbar: {
    right: "10px",
    bottom: "80px",
    width: "234px"
  },
  snackbarContent: {
    maxWidth: "224px",
    minWidth: "224px",
    backgroundColor: "#283848"
  }
});

InputChat.defaultProps = {
  renderAccessory: null,
  renderActions: null,
  renderSend: null,
  renderComposer: null,
  containerStyle: {},
  primaryStyle: {},
  accessoryStyle: {},
  onPressActionButton: () => {}
};

InputChat.propTypes = {
  renderAccessory: PropTypes.func,
  renderActions: PropTypes.func,
  renderSend: PropTypes.func,
  renderComposer: PropTypes.func,
  onPressActionButton: PropTypes.func,
  containerStyle: ViewPropTypes.style,
  primaryStyle: ViewPropTypes.style,
  accessoryStyle: ViewPropTypes.style
};

export default withStyles(styles, { withTheme: true })(InputChat);
