import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import LabelTextField from "./shared/LabelTextField";
import HelpTextField from "./shared/HelpTextField";
import RequireCheckBox from "./shared/RequireCheckBox";
import OptionsField from "./shared/OptionsField";

const styles = theme => ({
  content: {
    display: "flex",
    flexDirection: "column",
    padding: 0
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  }
});

const DropdownsForm = ({
  classes,
  field,
  onChange,
  onDeleteOption,
  onChangeOption,
  onAddOption,
  errors
}) => (
  <div className={classes.content}>
    <LabelTextField field={field} errors={errors} onChange={onChange} />
    <HelpTextField field={field} errors={errors} onChange={onChange} />
    <OptionsField
      field={field}
      errors={errors}
      onChange={onChangeOption}
      onDelete={onDeleteOption}
      onAdd={onAddOption}
    />
    <RequireCheckBox field={field} onChange={onChange} />
  </div>
);

DropdownsForm.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    help: PropTypes.string
  }).isRequired,
  errors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  onDeleteOption: PropTypes.func.isRequired,
  onChangeOption: PropTypes.func.isRequired,
  onAddOption: PropTypes.func.isRequired
};

DropdownsForm.defaultProps = {
  errors: {}
};

export default withStyles(styles, { withTheme: true })(DropdownsForm);
