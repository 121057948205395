import React, { Component } from "react";
import { AccountCircle } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import MainActivitySectionHeader from "../mainActivity/MainActivitySectionHeader";
import { connect } from "react-redux";
import { compose } from "recompose";
import Formsy from "formsy-react";
import { Grid, Button } from "@material-ui/core";
import { stringTranslate } from "languages/OMTranslate";
import { firestoreConnect } from "react-redux-firebase";
import { TextFieldFormsy } from "@fuse";
import { mapEnvironmentScript } from "main/common/mapEnvironment";
import $ from "jquery";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    order: "2",
    flex: "1 1 auto",
    height: "calc(100vh - 48px)",
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      width: "100%",
      height: "auto"
    }
  },
  formContainer: {
    padding: theme.spacing.unit * 3,
    width: "100%",
    maxWidth: theme.measures.formMaxWidth,
    order: 1,
    flex: "0 0 auto"
  },
  form: {
    marginTop: theme.spacing.unit * 3
  },
  textField: {
    width: "100%"
  }
});

class AccountDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      dialogOpen: false,
      canSubmit: false,
      onSaving: false,
      onSaved: false,
      account: props.account,
      billingAddressToSave: {}
    };
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  ucaseUuid = event => {
    const { account } = this.props;
    this.setState({
      account: { ...account, UUID: event.target.value.toUpperCase() }
    });
  };

  forceSetAddress = event => {
    const { account } = this.props;
    this.setState({ account: { ...account, address: event.target.value } });
  };

  setAddress = (address, componentsObj) => {
    let routeAddress = "";
    if (componentsObj.route) {
      routeAddress = componentsObj.route;
      if (componentsObj.street_number) {
        routeAddress += ", " + componentsObj.street_number;
      }
    }

    this.setState({
      account: {
        ...this.state.account,
        address
      },
      billingAddressToSave: {
        address: routeAddress,
        postalCode: componentsObj.postal_code ? componentsObj.postal_code : "",
        city: componentsObj.locality ? componentsObj.locality : "",
        province: componentsObj.administrative_area_level_2
          ? componentsObj.administrative_area_level_2
          : "",
        country: componentsObj.country ? componentsObj.country : "",
        countryCode: componentsObj.countryCode ? componentsObj.countryCode : ""
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      $("#hContainer").height(window.innerHeight - 48);
    }
    $("#hContainer").height(window.innerHeight - 48);

    if (!prevProps.account && this.props.account) {
      this.setState({ account: this.props.account });
    }
  }

  resize = () => this.forceUpdate();

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    var autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById("address")
    );
    autocomplete.setFields(["address_components", "geometry"]);

    var self = this;

    autocomplete.addListener("place_changed", function() {
      var place = autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      const address_components = place.address_components;
      const componentsObj = {};
      const addressObj = {};
      const address = [];
      for (const component of address_components) {
        componentsObj[component.types[0]] = {
          long_name: component.long_name,
          short_name: component.short_name
        };
      }

      const componentsToSave = [
        "route",
        "street_number",
        "postal_code",
        "locality",
        "administrative_area_level_3", //comune
        "administrative_area_level_2", //provincia
        "country"
      ];

      componentsToSave.forEach(component => {
        const nameType =
          component === "administrative_area_level_2" //per la provincia basta la sigla
            ? "short_name"
            : "long_name";

        if (componentsObj[component]) {
          address.push(componentsObj[component][nameType]);
          addressObj[component] = componentsObj[component][nameType];
        }
      });

      if (componentsObj.country) {
        addressObj.countryCode = componentsObj.country.short_name;
      }

      self.setAddress(address.join(", "), addressObj);
    });
  }

  onSubmit = model => {
    const { firestore } = this.props;
    const accountToSave = model;
    accountToSave.billingAddress = this.state.billingAddressToSave;
    accountToSave.UUID = accountToSave.UUID.toUpperCase();

    this.setState({ onSaving: true }, () => {
      firestore
        .update(
          {
            collection: "accounts",
            doc: localStorage.getItem("accountId")
          },
          accountToSave
        )
        .then(docRef => {
          this.setState(
            {
              onSaving: false,
              onSaved: true
            },
            () => {
              setTimeout(() => {
                this.setState({ onSaved: false });
              }, 3 * 1000);
            }
          );
        });
    });
  };

  render() {
    const { classes } = this.props;
    const { canSubmit, account, onSaving, onSaved } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.formContainer}>
          <MainActivitySectionHeader text="Account" icon={AccountCircle} />
          <Formsy
            className={classes.form}
            onValidSubmit={this.onSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
            ref={form => (this.form = form)}
          >
            <Grid container spacing={24}>
              <Grid item sm={6} xs={12}>
                <TextFieldFormsy
                  id="name"
                  name="name"
                  label={stringTranslate("account", "name")}
                  className={classes.textField}
                  value={account ? account.name : null}
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <TextFieldFormsy
                  id="phoneNumber"
                  name="phoneNumber"
                  label={stringTranslate("account", "phoneNumber")}
                  className={classes.textField}
                  value={account ? account.phoneNumber : null}
                  margin="normal"
                  validations="isNumeric"
                  validationError={stringTranslate(
                    "general",
                    "Not Valid Email"
                  )}
                  required
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <TextFieldFormsy
                  id="UUID"
                  name="UUID"
                  label={stringTranslate("account", "UUID")}
                  // onChange={this.ucaseUuid}
                  className={classes.textField}
                  value={account ? account.UUID : null}
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextFieldFormsy
                  id="email"
                  name="email"
                  label={stringTranslate("account", "email")}
                  className={classes.textField}
                  value={account ? account.email : null}
                  margin="normal"
                  validations="isEmail"
                  validationError={stringTranslate(
                    "general",
                    "Not Valid Email"
                  )}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormsy
                  id="address"
                  name="address"
                  label={stringTranslate("account", "address")}
                  className={classes.textField}
                  onChange={this.forceSetAddress}
                  value={account ? account.address : null}
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  className="mr-12"
                  variant="contained"
                  color="secondary"
                  aria-label={stringTranslate("general", "Save")}
                  disabled={!canSubmit}
                >
                  {stringTranslate("general", "Save")}
                </Button>
                {onSaving && stringTranslate("general", "onSaving")}
                {onSaved && stringTranslate("general", "onSaved")}
              </Grid>
            </Grid>
          </Formsy>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  account: state.firestore.data.account
});

export default compose(
  firestoreConnect(),
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(mapEnvironmentScript(AccountDataForm));
