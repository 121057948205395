import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import Collapse from "@material-ui/core/Collapse";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Checkbox from "@material-ui/core/Checkbox";
import AddIcon from "@material-ui/icons/Add";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import FilterList from "@material-ui/icons/FilterList";
import "moment/min/locales";
import "react-datepicker/dist/react-datepicker.css";
import { stringTranslate } from "languages/OMTranslate";
import DatePicker from "react-datepicker";
import MultipleSelect from "../../common/MultipleSelect";
import * as taskConstants from "../../../common/tasks/taskConstants";

const styles = theme => ({
  root: {
    minHeight: "50px",
    flexFlow: "row wrap",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      flex: "0 0 auto"
    }
  },
  filterLabel: {
    fontSize: theme.text.small,
    fontWeight: 300
  },
  filterContainer: {
    display: "flex",
    flex: "1 0 auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 .6rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "flex-start",
      flexWrap: "wrap"
    }
  },
  checkBox: {
    paddingLeft: 8,
    paddingRight: 4
  },
  fabButton: {
    flex: "0 0 auto",
    boxShadow: theme.addButton.boxShadow
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: ".6rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center"
    }
  },
  dateInput: {
    width: "120px"
  },
  fullWidth: {
    width: "100%"
  }
});

const executionStatuses = [
  {
    id: taskConstants.STATUS_TOEXECUTE,
    name: stringTranslate("tasks", taskConstants.STATUS_TOEXECUTE)
  },
  {
    id: taskConstants.STATUS_OG_ENROUTE,
    name: stringTranslate("tasks", taskConstants.STATUS_OG_ENROUTE)
  },
  {
    id: taskConstants.STATUS_OG_ARRIVED,
    name: stringTranslate("tasks", taskConstants.STATUS_OG_ARRIVED)
  },
  {
    id: taskConstants.STATUS_OG_EXECUTING,
    name: stringTranslate("tasks", taskConstants.STATUS_OG_EXECUTING)
  },
  {
    id: taskConstants.STATUS_COMPLETED,
    name: stringTranslate("tasks", taskConstants.STATUS_COMPLETED)
  },
  {
    id: taskConstants.STATUS_ABORTED,
    name: stringTranslate("tasks", taskConstants.STATUS_ABORTED)
  },
  {
    id: taskConstants.STATUS_REJECTED,
    name: stringTranslate("tasks", taskConstants.STATUS_REJECTED)
  },
  {
    id: taskConstants.STATUS_SUSPENDED,
    name: stringTranslate("tasks", taskConstants.STATUS_SUSPENDED)
  }
];

const EnhancedTableToolbar = ({
  classes,
  filterToYesterday,
  filterToday,
  filterFromTomorrow,
  filterExecutionStatus,
  statusChangeHandler,
  taskFormOpen,
  taskId,
  detailDataPanelOpen,
  addClickHandler,
  checkboxChangeHandler,
  closeTaskFormHandler,
  detailDataPanelToggler,
  freeSearch,
  handleFreeSearch,
  filterDate,
  handleFilterDate,
  toggleFilter,
  showFilters,
  allTasks
}) => {
  const customIcons = {
    icon: <CheckBoxOutlineBlankIcon fontSize="small" />,
    checkedIcon: <CheckBoxIcon fontSize="small" />,
    indeterminateIcon: <IndeterminateCheckBoxIcon fontSize="small" />
  };

  return (
    <Toolbar className={classNames(classes.root, "px-6")}>
      <div className={classes.title}>
        <div className="flex flex-1">
          <AssignmentTurnedIn color="primary" />
          <Typography
            className="px-8 py-4"
            variant="h3"
            id="tableTitle"
            color="primary"
          >
            {stringTranslate("mainactivity", "Tasks")}
          </Typography>
        </div>
        <Hidden smUp>
          <Tooltip title={stringTranslate("general", "filter")}>
            <IconButton
              color="primary"
              onClick={toggleFilter}
              aria-label={stringTranslate("general", "filter")}
              style={{ marginRight: "8px" }}
            >
              <FilterList />
            </IconButton>
          </Tooltip>
          <Tooltip title={stringTranslate("general", "Add")}>
            <Button
              variant="fab"
              color="secondary"
              mini
              onClick={addClickHandler}
              aria-label={stringTranslate("general", "Add")}
              className={classes.fabButton}
            >
              <AddIcon />
            </Button>
          </Tooltip>
        </Hidden>
      </div>
      <div className={classes.filterContainer}>
        <Collapse in={showFilters} classes={{ container: classes.fullWidth }}>
          <div className={classes.filterContainer}>
            <TextField
              id="freeSearch"
              label={stringTranslate("general", "Search")}
              value={freeSearch}
              onChange={handleFreeSearch}
              style={{
                width: "200px",
                marginRight: "16px",
                paddingBottom: "15px"
              }}
            />

            <MultipleSelect
              inputLabel={stringTranslate("tasks", "status")}
              selected={filterExecutionStatus}
              onChange={statusChangeHandler}
              id="status-label-placeholder"
              values={executionStatuses}
              style={{
                marginRight: "16px",
                paddingBottom: "15px",
                width: "200px"
              }}
            />

            <div
              style={{
                marginRight: "20px"
              }}
            >
              <DatePicker
                selected={filterDate}
                onChange={handleFilterDate}
                className={classNames("date-picker-input", classes.dateInput)}
                placeholderText={stringTranslate("tasks", "date")}
                dateFormat={stringTranslate("general", "fsnDateFormat")}
                isClearable={true}
                popperPlacement="top-start"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkBox}
                    checked={filterToYesterday}
                    onChange={(event, checked) => {
                      checkboxChangeHandler(event.target.value, checked);
                    }}
                    value="filterToYesterday"
                    disabled={filterDate !== null}
                    {...customIcons}
                  />
                }
                label={stringTranslate("tasks", "filterToYesterday")}
                classes={{ label: classes.filterLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkBox}
                    checked={filterToday}
                    onChange={(event, checked) => {
                      checkboxChangeHandler(event.target.value, checked);
                    }}
                    value="filterToday"
                    disabled={filterDate !== null}
                    {...customIcons}
                  />
                }
                label={stringTranslate("tasks", "filterToday")}
                classes={{ label: classes.filterLabel }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkBox}
                    checked={filterFromTomorrow}
                    onChange={(event, checked) => {
                      checkboxChangeHandler(event.target.value, checked);
                    }}
                    value="filterFromTomorrow"
                    disabled={filterDate !== null}
                    {...customIcons}
                  />
                }
                label={stringTranslate("tasks", "filterFromTomorrow")}
                classes={{ label: classes.filterLabel }}
              />
            </div>
            <Hidden xsDown>
              <Tooltip title={stringTranslate("general", "Add")}>
                <Button
                  variant="fab"
                  color="secondary"
                  mini
                  onClick={addClickHandler}
                  aria-label={stringTranslate("general", "Add")}
                  className={classes.fabButton}
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            </Hidden>
          </div>
        </Collapse>
      </div>
    </Toolbar>
  );
};

export default withWidth()(
  withStyles(styles, { withTheme: true })(EnhancedTableToolbar)
);
