import React, { Component } from "react";
import $ from "jquery";
import { withStyles, Button } from "@material-ui/core";
import { stringTranslate, getMomementLocale } from "languages/OMTranslate";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  textContainer: {
    order: 1,
    overflow: "auto",
    flex: "1 1 auto",
    marginBottom: "10px",
    marginTop: "0px"
  },
  innerTextContainer: {},
  buttonContainer: {
    order: 2,
    flex: "0 0 auto",
    textAlign: "center"
  }
});

class PrivacyText extends Component {
  componentDidMount() {
    var loadIubendaBadge = function() {
      var s = document.createElement("script"),
        tag = document.getElementsByTagName("script")[0];
      s.src = "https://cdn.iubenda.com/iubenda.js";
      tag.parentNode.insertBefore(s, tag);
    };

    var aLink = $(
      '<a href="https://www.iubenda.com/privacy-policy/52025894" class="iubenda-white no-brand iubenda-embed iub-body-embed" title="Privacy Policy ">Privacy Policy</a>'
    );
    if (getMomementLocale() === "it") {
      aLink = $(
        '<a href="https://www.iubenda.com/privacy-policy/61923992" class="iubenda-white no-brand iubenda-embed iub-body-embed" title="Privacy Policy ">Privacy Policy</a>'
      );
    }

    $("#element-id-containing-badge").append(aLink);
    loadIubendaBadge();

    // var aLink =
    //   '<a href="https://www.iubenda.com/privacy-policy/52025894" class="iubenda-black iubenda-embed" title="Privacy Policy ">Privacy Policy</a>' +
    //   '<script type="text/javascript" src="https://cdn.iubenda.com/iubenda.js"></script>';

    // $("#element-id-containing-badge").html(aLink);
  }
  render() {
    const { classes, onBackdropClick } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.textContainer}>
          <div
            id="element-id-containing-badge"
            className={classes.innerTextContainer}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button variant="contained" color="primary" onClick={onBackdropClick}>
            {stringTranslate("general", "Close")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PrivacyText);
