export const TOGGLE_USERLIST = "[PANEL_VISIBILITY] TOGGLE_USERLIST";
export const TOGGLE_MAP = "[PANEL_VISIBILITY] TOGGLE_MAP";
export const TOGGLE_CALENDAR = "[PANEL_VISIBILITY] TOGGLE_CALENDAR";
export const TOGGLE_TASKLIST = "[PANEL_VISIBILITY] TOGGLE_TASKLIST";
export const TOGGLE_TASKFORM = "[PANEL_VISIBILITY] TOGGLE_TASKFORM";
export const TOGGLE_CHAT = "[PANEL_VISIBILITY] TOGGLE_CHAT";
export const HIDE_ALL = "[PANEL_VISIBILITY] HIDE_ALL";

export function hideAllPanels() {
  return {
    type: HIDE_ALL
  };
}

export function userListToggle() {
  return {
    type: TOGGLE_USERLIST
  };
}

export function mapToggle() {
  return {
    type: TOGGLE_MAP
  };
}

export function calendarToggle() {
  return {
    type: TOGGLE_CALENDAR
  };
}

export function taskListToggle() {
  return {
    type: TOGGLE_TASKLIST
  };
}

export function taskFormToggle() {
  return {
    type: TOGGLE_TASKFORM
  };
}

export function chatToggle() {
  return {
    type: TOGGLE_CHAT
  };
}
