import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
import { AccountCircle, AttachMoney, Ballot } from "@material-ui/icons";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import {
  userListToggle,
  mapToggle,
  calendarToggle,
  taskListToggle,
  chatToggle
} from "../../../store/actions/mainActivityPage";
import { stringTranslate } from "languages/OMTranslate";

const styles = theme => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.dark,
    flex: "0 0 auto",
    width: "48px",
    textAlign: "center",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      order: 99,
      display: "flex",
      width: "100%"
    }
  },
  list: {
    paddingTop: 0,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      display: "flex",
      width: "100%",
      padding: 0
    }
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      padding: "11px 0px"
    }
  },
  listItemUnselected: {
    backgroundColor: theme.palette.primary.darker
  },
  listItemIconSelected: {
    margin: "0 auto",
    color: theme.palette.primary.white
  },
  listItemIcon: {
    color: "#9591a0 !important",
    margin: "0 auto"
  },
  listItemSelected: {
    backgroundColor: theme.palette.primary.darkest + " !important "
  },
  listItemIconUnselected: {
    color: theme.palette.primary.white
  },
  disabled: {
    color: theme.palette.primary.contrastTextDisabled
  }
});

const AccountButtonMenu = ({ classes, location }) => {
  const accountMenuItems = [
    {
      label: "Account",
      link: "/account",
      icon: AccountCircle,
      visible: true
    },
    {
      label: "Subscription",
      link: "/subscription",
      icon: Ballot,
      visible: true
    },
    {
      label: "paymentMethod",
      link: "/payments",
      icon: AttachMoney,
      visible: true
    }
  ];

  return (
    <div className={classes.root}>
      <List className={classes.list}>
        {accountMenuItems.map(item => {
          const ItemIcon = item.icon;
          return (
            item.visible && (
              <ListItem
                key={item.label}
                button
                component={Link}
                to={item.link}
                classes={{
                  root: classes.listItem,
                  selected: classes.listItemSelected
                }}
                selected={location.pathname === item.link}
              >
                <ListItemIcon
                  classes={{
                    root:
                      location.pathname === item.link
                        ? classes.listItemIconSelected
                        : classes.listItemIcon
                  }}
                >
                  <Tooltip
                    title={stringTranslate("account", item.label)}
                    aria-label={stringTranslate("account", item.label)}
                  >
                    <ItemIcon />
                  </Tooltip>
                </ListItemIcon>
              </ListItem>
            )
          );
        })}
      </List>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default withRouter(
  connect(
    mapStateToProps,
    { userListToggle, mapToggle, taskListToggle, calendarToggle, chatToggle }
  )(withStyles(styles, { withTheme: true })(AccountButtonMenu))
);
