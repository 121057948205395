import React from "react";
import PartiesMapMarkerComponent from "./PartiesMapMarkerComponent";
import { colorNames } from "../../../common/ColorPicker";

const PartiesMapMarkerGroup = ({
  allAddress,
  party,
  allPois,
  poiCategories,
  selectedCategories
}) => {
  if (!allAddress) return <React.Fragment />;

  let mainAddress = allAddress.filter(poi => {
    if (poi.partyId !== party.id) return false;

    if (selectedCategories && selectedCategories.length > 0) {
      if (
        !poi.categoryId ||
        !(selectedCategories.indexOf(poi.categoryId) > -1)
      ) {
        return false;
      }
      return true;
    }
    return true;
  });

  if (!mainAddress) return <React.Fragment />;
  if (!mainAddress[0]) return <React.Fragment />;

  return (
    <React.Fragment>
      {mainAddress.map(p => {
        const poiId = p.id;
        if (allPois.hasOwnProperty(poiId)) {
          const poi = allPois[poiId];

          let colorName = "";
          if (
            poi.hasOwnProperty("categoryId") &&
            poi.categoryId !== null &&
            poiCategories.hasOwnProperty(poi.categoryId)
          ) {
            // Determina il colore della bandierina in base alla categoria
            const color = poiCategories[poi.categoryId].color;
            colorName = colorNames.hasOwnProperty(color)
              ? colorNames[color]
              : "";
          }

          return (
            <PartiesMapMarkerComponent
              key={poiId}
              position={{
                lat: poi.lat_long.latitude,
                lng: poi.lat_long.longitude
              }}
              party={party}
              poiName={poi.name}
              poiAddress={poi.address}
              color={colorName}
            />
          );
        } else {
          return <React.Fragment />;
        }
      })}
    </React.Fragment>
  );
};

export default PartiesMapMarkerGroup;
