import React, { Component } from "react";
import { FuseScrollbars } from "@fuse";
import {
  withStyles,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton
} from "@material-ui/core";
import { stringTranslate } from "languages/OMTranslate";
import OMSearchAddBar from "../../common/OMSearchAddBar";
import { ArrowBack } from "@material-ui/icons";

const styles = theme => ({
  scrollbar: {
    flex: 1
  },
  title: {
    height: "47px",
    minHeight: "47px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.primary.grey3
  }
});

class BroadcastList extends Component {
  state = { search: "" };

  setSearch = event => this.setState({ search: event.target.value });

  clearSearch = () => this.setState({ search: "" });

  getContactList = broadcast => {
    const { chatRooms, resources } = this.props;
    let description = "";

    chatRooms.forEach(element => {
      if (broadcast.chatRooms.indexOf(element.id) >= 0) {
        if (element.resourceId in resources) {
          const res = resources[element.resourceId];
          description += res.name + ", ";
        }
      }
    });
    return description.substring(0, description.length - 2);
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.title}>
          <IconButton
            style={{ marginTop: 4, marginBottom: 4, marginLeft: 4 }}
            onClick={this.props.goBack}
          >
            <ArrowBack color="primary" />
          </IconButton>
          <Typography variant="h3" className="px-8 py-4" color="primary">
            {stringTranslate("chat", "broadcast")}
          </Typography>
        </div>
        <OMSearchAddBar
          withAdd
          addClickHandler={this.props.add}
          searchText={this.state.search}
          textChangeHandler={this.setSearch}
          textClearHandler={this.clearSearch}
        />
        {this.props.broadcast.length > 0 ? (
          <FuseScrollbars className={classes.scrollbar}>
            <List>
              <React.Fragment>
                {this.props.broadcast.forEach(bc => {
                  if (
                    bc.name
                      .toLowerCase()
                      .includes(this.state.search.toLowerCase())
                  ) {
                    return (
                      <ListItem
                        key={bc.id}
                        role={undefined}
                        dense
                        button
                        divider
                        onClick={() => this.props.showInput(bc)}
                      >
                        <div>
                          <ListItemText primary={bc.name} />
                          <Typography variant="caption">
                            {this.getContactList(bc)}
                          </Typography>
                        </div>
                      </ListItem>
                    );
                  }
                })}
              </React.Fragment>
            </List>
          </FuseScrollbars>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(BroadcastList);
