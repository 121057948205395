import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { compose } from "recompose";
import { connect } from "react-redux";
import Measure from "react-measure";
import { firestoreConnect } from "react-redux-firebase";
import firebase from "firestoreAccount";
import { stringTranslate } from "languages/OMTranslate";
import moment from "moment";
import Formsy from "formsy-react";
import { withStyles, Button, Typography, InputLabel } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import errorReporting from "main/common/errorReporting";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import OMReactSelect from "main/content/common/OMReactSelect";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getDirectionMarkerIcon } from "main/content/mainActivity/map/getMarkerIcon";
import { mapEnvironmentScript } from "main/common/mapEnvironment";
import axios from "axios";

const styles = theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "calc(100vh - 48px)",
    background: theme.palette.primary.white,
    padding: theme.measures.panelPadding
  },
  titleRow: {
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "row",
    padding: theme.measures.panelPadding
  },
  title: {
    display: "flex",
    flexDirection: "row",
    flex: "0 0 auto",
    borderBottom: theme.panels.border
  },
  filter: {
    padding: theme.measures.panelPadding,
    maxWidth: theme.measures.reportMaxWidth,
    marginBottom: theme.measures.panelPadding,
    flex: "0 0 auto"
  },
  formContainer: {
    alignItems: "flex-end"
  },
  map: {
    flex: "1 1 auto"
  },
  mapContainer: {
    position: "relative",
    width: "100%"
  },
  loadingMap: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: `rgba(255, 255, 255, 0.85) !important;`,
    textAlign: "center",
    display: "flex",
    flexDirection: "column"
  },
  progress: {
    margin: "auto auto"
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputText2: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark,
    paddingTop: "3px"
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  },
  route: {
    flex: "0 0 auto",
    minHeight: "80px",
    display: "flex",
    width: "100%",
    maxWidth: theme.measures.reportMaxWidth
  },
  positionContainer: {
    display: "flex",
    height: "100%"
  },
  positionLogo: {
    flex: "0 0 auto",
    margin: "auto 10px"
  },
  positionDesc: {
    flex: "1 1 auto",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    marginRight: "20px"
  },
  positionDescTitle: {
    flex: "1 1 auto",
    width: "100%",
    margin: "auto"
  },
  positionDescContent: {
    flex: "1 1 auto",
    width: "100%",
    margin: "auto"
  },
  positionMarker: {
    height: "40px",
    fontSize: theme.text.big
  }
});

class RoutesReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        width: -1,
        height: -1
      },
      selectedDate: moment().toDate(),
      selectedStartTime: moment()
        .startOf("day")
        .toDate(),
      selectedEndTime: moment()
        .endOf("day")
        .toDate(),
      resourceId: null,
      resourceName: null,
      errorName: false,
      dialogOpen: false,
      positions: [],
      isLoading: false,
      showDirection: false,
      firstPosition: null,
      lastPosition: null,
      firstPositionAddress: null,
      lastPositionAddress: null,
      totalRoute: null
    };
  }

  handleShowDirection = () => {
    this.setState({ showDirection: !this.state.showDirection });
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleStartTimeChange = time => {
    this.setState({ selectedStartTime: time });
  };

  handleEndTimeChange = time => {
    this.setState({ selectedEndTime: time });
  };

  handleChange = name => object => {
    if (object && object.value) {
      this.setState({
        resourceId: object.value,
        resourceName: object.label
      });
    }
  };

  async getPositions(selectedResourceId = null) {
    const { firestore } = this.props;
    const {
      selectedDate,
      selectedStartTime,
      selectedEndTime,
      resourceId
    } = this.state;

    var accountId = localStorage.getItem("accountId");
    var collection =
      "/accounts/" +
      accountId +
      "/positions/resource_" +
      (selectedResourceId ? selectedResourceId : resourceId) +
      "/valid_positions/";

    var startDate = moment(selectedDate);
    var startTime = moment(selectedStartTime);

    startDate.set({
      hour: startTime.hour(),
      minute: startTime.minute(),
      second: 0
    });

    var endDate = moment(selectedDate);
    var endTime = moment(selectedEndTime);
    endDate.set({
      hour: endTime.hour(),
      minute: endTime.minute(),
      second: 59
    });
    const positions = await firestore
      .collection(collection)
      .where("timestamp", ">=", startDate.toDate())
      .where("timestamp", "<", endDate.toDate())
      .orderBy("timestamp")
      .get()
      .then(querySnapshot => {
        var positions = [];
        querySnapshot.forEach(function(doc) {
          if (!doc.get("lat_long")) {
            return;
          }
          positions.push(doc.data());
        });
        if (positions.length > 0) {
          this.setState({
            firstPosition: positions[0],
            lastPosition: positions[positions.length - 1]
          });

          new window.google.maps.Geocoder().geocode(
            {
              location: {
                lat: positions[0].lat_long.latitude,
                lng: positions[0].lat_long.longitude
              }
            },
            (results, status) => {
              if (status === "OK") {
                this.setState({
                  firstPositionAddress: results[0]["formatted_address"]
                });
              } else {
                this.setState({ firstPositionAddress: null });
              }
            }
          );
          new window.google.maps.Geocoder().geocode(
            {
              location: {
                lat: positions[positions.length - 1].lat_long.latitude,
                lng: positions[positions.length - 1].lat_long.longitude
              }
            },
            (results, status) => {
              if (status === "OK") {
                this.setState({
                  lastPositionAddress: results[0]["formatted_address"]
                });
              } else {
                this.setState({ lastPositionAddress: null });
              }
            }
          );
        } else {
          this.setState({
            firstPosition: null,
            lastPosition: null,
            firstPositionAddress: null,
            lastPositionAddress: null
          });
        }
        return positions;
      })
      .catch(error => {
        console.log(error);
      });

    return positions;
  }

  async checkForSavedData(positions) {
    const { firestore } = this.props;
    const { selectedDate, selectedStartTime, selectedEndTime } = this.state;

    const reportCollection = this.getReportCollection();

    var startDate = moment(selectedDate).set({
      hour: moment(selectedStartTime).hour(),
      minute: moment(selectedStartTime).minute(),
      second: 0
    });

    var endDate = moment(selectedDate).set({
      hour: moment(selectedEndTime).hour(),
      minute: moment(selectedEndTime).minute(),
      second: 59
    });

    var savedData = await firestore
      .collection(reportCollection)
      .where("startDate", "==", startDate.toDate())
      .where("endDate", "==", endDate.toDate())
      .get()
      .then(querySnapshot => {
        var savedData = null;
        querySnapshot.forEach(doc => {
          savedData = doc.data();
        });
        return savedData;
      })
      .catch(error => {
        console.log(error);
      });

    if (savedData && savedData.positionCount !== positions.length) {
      savedData = null;
    }

    return savedData;
  }

  getReportCollection() {
    var accountId = localStorage.getItem("accountId");
    const { resourceId } = this.state;
    const reportName = "routeReport";
    const reportCollection =
      "/accounts/" +
      accountId +
      "/reports/resource_" +
      resourceId +
      "/" +
      reportName;
    return reportCollection;
  }

  getFilterDate() {
    const { selectedDate } = this.state;
    return {
      startDate: moment(selectedDate)
        .startOf("day")
        .toDate(),
      endDate: moment(selectedDate)
        .endOf("day")
        .toDate()
    };
  }

  boundMapToPositions(positions) {
    var bounds = new window.google.maps.LatLngBounds();
    for (var i = 0; i < positions.length; i++) {
      if (!positions[i].lat_long) continue;
      if (!positions[i].heading && positions[i].heading !== 0) continue;
      var myLatLng = {
        lat: positions[i].lat_long.latitude,
        lng: positions[i].lat_long.longitude
      };
      bounds.extend(myLatLng);
      this.map.fitBounds(bounds);
    }
  }

  saveDataToStorage(filecontent, positionCount) {
    const { firestore } = this.props;
    const {
      selectedDate,
      selectedStartTime,
      selectedEndTime,
      resourceId
    } = this.state;
    var accountId = localStorage.getItem("accountId");
    var jsonContent = JSON.stringify(filecontent);

    var startDate = moment(selectedDate).set({
      hour: moment(selectedStartTime).hour(),
      minute: moment(selectedStartTime).minute(),
      second: 0
    });

    var endDate = moment(selectedDate).set({
      hour: moment(selectedEndTime).hour(),
      minute: moment(selectedEndTime).minute(),
      second: 59
    });

    var saveData = firestore.collection(this.getReportCollection()).doc();
    const filename =
      "account_" +
      accountId +
      "/resource_" +
      resourceId +
      "/routeReport_" +
      saveData.id +
      ".json";

    saveData
      .set({
        reportDataFile: filename,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        calculationDate: moment().toDate(),
        positionCount: positionCount
      })
      .then(docRef => {
        var blob = new Blob([jsonContent], { type: "application/json" });
        firebase
          .storage()
          .ref("reportsData")
          .child(filename)
          .put(blob);
      });
  }

  displayDirection = direction => {
    if (!this.directionsDisplay)
      this.directionsDisplay = new window.google.maps.DirectionsRenderer();

    this.directionsDisplay.setMap(this.map);
    this.directionsDisplay.setDirections(direction);
  };

  reduceArray(positions, boundWaypoint) {
    // se sono piu' di cento ne prendo boundWaypoint ben distribuite
    if (positions.length > boundWaypoint) {
      const factor = positions.length / (boundWaypoint - 1);
      var newArray = [];
      for (var i = 0; i < boundWaypoint - 1; i++) {
        let position = Math.round(i * factor);
        newArray.push(positions[position]);
      }
      newArray.push(positions[positions.length - 1]);
      positions = newArray;
    }
    return positions;
  }

  addDirectionMarkers = (start_location, end_location) => {
    const sizeX = 30;
    const sizeY = 35;
    const startmarker = new window.google.maps.Marker({
      position: start_location,
      icon: {
        url: "/assets/mapIcons/report/map-marker-green-a.svg",
        scaledSize: new window.google.maps.Size(sizeX, sizeY)
      },
      map: this.map
    });
    this.markers.push(startmarker);

    const endmarker = new window.google.maps.Marker({
      position: end_location,
      icon: {
        url: "/assets/mapIcons/report/map-marker-red-b.svg",
        scaledSize: new window.google.maps.Size(sizeX, sizeY)
      },
      map: this.map
    });
    this.markers.push(endmarker);
  };

  getPolyline = () => {
    var polyline = new window.google.maps.Polyline({
      strokeColor: "#4a80f5",
      strokeOpacity: 0.7,
      strokeWeight: 5
    });
    return polyline;
  };

  handleSubmit(resourceId = null) {
    this.setState({ isLoading: true });
    this.performReport(resourceId);
  }

  drawPolyline = (positions, positionCount) => {
    var directionsService = new window.google.maps.DirectionsService();
    var positionForPolyline = this.reduceArray(positions, 25);

    var waypointsArray = [];
    for (var i = 1; i < positionForPolyline.length - 1; i++) {
      waypointsArray.push({
        location: positionForPolyline[i],
        stopover: false
      });
    }
    var directionsParams = {
      origin: positionForPolyline[0],
      destination: positionForPolyline[positionForPolyline.length - 1],
      waypoints: waypointsArray,
      travelMode: "DRIVING"
    };

    directionsService.route(directionsParams, (response, status) => {
      if (status === "OK") {
        if (!this.directionsDisplay) {
          this.directionsDisplay = new window.google.maps.DirectionsRenderer({
            polylineOptions: this.getPolyline(),
            suppressMarkers: true
          });
        }

        response.routes.forEach(route => {
          this.addDirectionMarkers(
            route.legs[0].start_location,
            route.legs[route.legs.length - 1].end_location
          );

          var totalRoute = 0;
          for (var i = 0; i < route.legs.length; i++) {
            totalRoute = totalRoute + route.legs[i].distance.value;
          }

          var encodedPolyline = this.getPolyline();
          encodedPolyline.setPath(
            window.google.maps.geometry.encoding.decodePath(
              route.overview_polyline
            )
          );
          encodedPolyline.setMap(this.map);
          this.polylines.push(encodedPolyline);
          this.setState({ totalRoute: totalRoute });
        });
        // this.directionsDisplay.setMap(this.map);
        // this.directionsDisplay.setDirections(response);
        this.saveDataToStorage(response, positionCount);
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });
        errorReporting(
          this.props.user.uid,
          "ERROR",
          "Directions request failed due to " + status
        );
      }
    });
  };

  getSnapToRoad = async positions => {
    let res = await axios.get("https://roads.googleapis.com/v1/snapToRoads", {
      params: {
        interpolate: true,
        key: process.env.REACT_APP_MAP_KEY,
        path: positions.join("|")
      }
    });
    let { data } = await res;
    var snappedCoordinates = [];
    for (var i = 0; i < data.snappedPoints.length; i++) {
      var latlng = new window.google.maps.LatLng({
        lat: data.snappedPoints[i].location.latitude,
        lng: data.snappedPoints[i].location.longitude
      });
      snappedCoordinates.push(latlng);
    }
    return snappedCoordinates;
  };

  performReport = async (resourceId = null) => {
    const { resourceName, showDirection } = this.state;
    this.setState({ totalRoute: null });

    if (!this.markers) this.markers = [];
    if (this.markers.length > 0) {
      for (var i = 0; i < this.markers.length; i++) {
        if (this.markers[i]) this.markers[i].setMap(null);
      }
      this.markers = [];
    }

    if (!this.polylines) this.polylines = [];
    if (this.polylines.length > 0) {
      for (var j = 0; j < this.polylines.length; j++) {
        if (this.polylines[j]) this.polylines[j].setMap(null);
      }
      this.polylines = [];
    }

    if (this.directionsDisplay) this.directionsDisplay.setMap(null);
    var directionsService = new window.google.maps.DirectionsService();

    if (showDirection) {
      //mostra percorso
      this.getPositions().then(positions => {
        if (!positions || positions.length === 0) {
          this.setState({ isLoading: false, dialogOpen: true });
          return;
        } else {
          this.boundMapToPositions(positions);
          this.checkForSavedData(positions).then(async savedData => {
            if (savedData) {
              //ho trovato i dati salvati
              firebase
                .storage()
                .ref("reportsData")
                .child(savedData.reportDataFile)
                .getDownloadURL()
                .then(url => {
                  var xhr = new XMLHttpRequest();
                  xhr.responseType = "blob";
                  xhr.onload = event => {
                    var blob = xhr.response;
                    var reader = new FileReader();
                    reader.onload = event => {
                      const direction = JSON.parse(event.target.result);
                      var totalRoute = 0;

                      for (var i = 0; i < direction.routes.length; i++) {
                        this.addDirectionMarkers(
                          direction.routes[i].legs[0].start_location,
                          direction.routes[i].legs[
                            direction.routes[i].legs.length - 1
                          ].end_location
                        );

                        var encodedPolyline = this.getPolyline();
                        encodedPolyline.setPath(
                          window.google.maps.geometry.encoding.decodePath(
                            direction.routes[i].overview_polyline
                          )
                        );
                        encodedPolyline.setMap(this.map);
                        this.polylines.push(encodedPolyline);

                        if (direction.routes[i].legs) {
                          for (
                            var j = 0;
                            j < direction.routes[i].legs.length;
                            j++
                          ) {
                            if (
                              direction.routes[i].legs[j] &&
                              direction.routes[i].legs[j].distance &&
                              direction.routes[i].legs[j].distance.value
                            ) {
                              totalRoute =
                                totalRoute +
                                direction.routes[i].legs[j].distance.value;
                            }
                          }
                        }
                      }
                      this.setState({
                        isLoading: false,
                        totalRoute: totalRoute
                      });
                    };
                    reader.readAsText(blob);
                  };
                  xhr.open("GET", url);
                  xhr.send();
                })
                .catch(function(error) {
                  console.log(error);
                });
            } else {
              var positionForSnaptoroad = positions;

              var snappedCoordinates = [];
              for (
                var i = 0;
                i < Math.ceil(positionForSnaptoroad.length / 100);
                i++
              ) {
                var pathValues = [];
                for (var j = 0 + i * 100; j < 100 + i * 100; j++) {
                  if (positionForSnaptoroad[j]) {
                    var latlong = new window.google.maps.LatLng({
                      lat: positionForSnaptoroad[j].lat_long.latitude,
                      lng: positionForSnaptoroad[j].lat_long.longitude
                    });

                    pathValues.push(latlong.toUrlValue());
                  }
                }
                snappedCoordinates = snappedCoordinates.concat(
                  await this.getSnapToRoad(pathValues)
                );
              }
              this.drawPolyline(snappedCoordinates, positions.length);
            }
          });
        }
      });
    } else {
      //mostra punti
      this.getPositions(resourceId).then(positions => {
        if (!positions || positions.length === 0) {
          this.setState({ isLoading: false, dialogOpen: true });
          return;
        } else {
          this.boundMapToPositions(positions);

          for (var i = 0; i < positions.length; i++) {
            if (!positions[i].lat_long) continue;
            if (!positions[i].heading && positions[i].heading !== 0) continue;

            if (!this.state.showDirection) {
              const marker = new window.google.maps.Marker({
                position: {
                  lat: positions[i].lat_long.latitude,
                  lng: positions[i].lat_long.longitude
                },
                icon: {
                  url: getDirectionMarkerIcon(positions[i]),
                  scaledSize: new window.google.maps.Size(20, 20),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(10, 10)
                },
                map: this.map
              });

              this.addInfoWindow(marker, positions[i], resourceName);
              this.markers.push(marker);
            }
          }
          this.addDirectionMarkers(
            {
              lat: positions[0].lat_long.latitude,
              lng: positions[0].lat_long.longitude
            },
            {
              lat: positions[positions.length - 1].lat_long.latitude,
              lng: positions[positions.length - 1].lat_long.longitude
            }
          );
        }
        this.setState({ isLoading: false });
      });
    }

    return;
  };

  componentDidUpdate(prevProps) {
    if (this.props.reportSelectedResource) {
      if (
        this.state.resourceId === null &&
        this.state.resourceId !== this.props.match.params.resourceId
      ) {
        this.setState({
          resourceId: this.props.match.params.resourceId,
          resourceName: this.props.reportSelectedResource.name
        });
        this.handleSubmit(this.props.match.params.resourceId);
      }
    }
  }

  addInfoWindow(marker, position, resourceName) {
    var infowindow = new window.google.maps.InfoWindow();
    infowindow.setContent(
      '<div class="reportInfoWindow">' +
        '<div class="header">' +
        resourceName +
        "</div>" +
        '<div class="content">' +
        moment.unix(position.timestamp.seconds).format("DD/MM/YYYY HH:mm") +
        "<br/>" +
        stringTranslate("reports", "Speed") +
        ": " +
        Math.round(position.speed) +
        " Km/h<br/>" +
        "</div>" +
        "</div>"
    );

    window.google.maps.event.addListener(marker, "click", function() {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: marker.getPosition() }, function(
        results,
        status
      ) {
        if (status === "OK") {
          if (results[0]) {
            infowindow.setContent(
              '<div class="reportInfoWindow">' +
                '<div class="header">' +
                resourceName +
                "</div>" +
                '<div class="content">' +
                results[0].formatted_address +
                "<br/>" +
                moment
                  .unix(position.timestamp.seconds)
                  .format("DD/MM/YYYY HH:mm") +
                "<br/>" +
                stringTranslate("reports", "Speed") +
                ": " +
                Math.round(position.speed) +
                " Km/h<br/>" +
                "</div>" +
                "</div>"
            );
          }
        }
      });

      infowindow.open(this.map, marker);
    });
  }

  componentDidMount() {
    this.map = new window.google.maps.Map(document.getElementById("map"), {
      mapTypeControl: false
    });

    var countryName = "Italy";
    if (this.props.user.countryName) countryName = this.props.user.countryName;

    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: countryName }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        this.map.setCenter(results[0].geometry.location);
        this.map.fitBounds(results[0].geometry.bounds);
      } else {
        console.log(
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  }

  render() {
    const { classes, resources } = this.props;
    const {
      selectedDate,
      selectedStartTime,
      selectedEndTime,
      dimensions,
      resourceId,
      resourceName,
      isLoading
    } = this.state;

    let resourcesOptions = [];
    if (resources) {
      resources.forEach(el => {
        resourcesOptions.push({
          value: el.id,
          label: el.name
        });
      });
    }

    let canSubmit = false;
    if (resourceId) canSubmit = true;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.titleRow}>
            <div className={classes.title}>
              <Room color="primary" />
              <Typography variant="h3" className="px-8 py-4" color="primary">
                {stringTranslate("reports", "Routes Report")}
              </Typography>
            </div>
          </div>
          <div className={classes.filter}>
            <Formsy
              onValidSubmit={this.onSubmit}
              onValid={this.enableButton}
              onInvalid={this.disableButton}
              ref={form => (this.form = form)}
              className={classes.container}
            >
              <Grid container spacing={24} className={classes.formContainer}>
                <Grid item xs={12} sm={2}>
                  <div className="flex flex-col overflow-hidden">
                    <InputLabel required className="form-input-label">
                      {stringTranslate("reports", "Date")}
                    </InputLabel>
                    <DatePicker
                      selected={selectedDate}
                      onChange={this.handleDateChange}
                      className="date-picker-input"
                      placeholderText={stringTranslate("reports", "Date")}
                      dateFormat={stringTranslate("general", "fsnDateFormat")}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={1}>
                  <div className="flex flex-col overflow-hidden">
                    <InputLabel required className="form-input-label">
                      {stringTranslate("reports", "From")}
                    </InputLabel>
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      selected={selectedStartTime}
                      onChange={this.handleStartTimeChange}
                      className="date-picker-input"
                      placeholderText={stringTranslate("reports", "From")}
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      timeCaption="Time"
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sm={1}>
                  <div className="flex flex-col overflow-hidden">
                    <InputLabel required className="form-input-label">
                      {stringTranslate("reports", "To")}
                    </InputLabel>
                    <DatePicker
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      selected={selectedEndTime}
                      onChange={this.handleEndTimeChange}
                      className="date-picker-input"
                      placeholderText={stringTranslate("reports", "To")}
                      timeFormat="HH:mm"
                      dateFormat="HH:mm"
                      timeCaption="Time"
                      injectTimes={[
                        moment()
                          .endOf("day")
                          .toDate()
                      ]}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <OMReactSelect
                    value={
                      resourceId
                        ? {
                            value: resourceId,
                            label: resourceName
                          }
                        : null
                    }
                    onChange={this.handleChange("resourceId")}
                    options={resourcesOptions}
                    label={stringTranslate("tasks", "resource")}
                    placeholder={stringTranslate("general", "Select")}
                    componentType={Select}
                    isClearable
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.showDirection}
                        onChange={this.handleShowDirection}
                        value="1"
                        color="secondary"
                      />
                    }
                    label={stringTranslate("reports", "Show route")}
                    classes={{ label: classes.inputText }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="mt-12"
                    onClick={() => this.handleSubmit()}
                    disabled={!canSubmit}
                  >
                    {stringTranslate("reports", "Calculate")}
                  </Button>
                </Grid>
              </Grid>
            </Formsy>
          </div>
          <div className={classes.route}>
            {this.state.firstPosition && (
              <div className={classes.positionContainer}>
                <div className={classes.positionLogo}>
                  <img
                    alt="firstPosition"
                    className={classes.positionMarker}
                    src={"assets/mapIcons/report/map-marker-green-a.svg"}
                  />
                </div>
                <div className={classes.positionDesc}>
                  <div className={classes.positionDescTitle}>
                    <Typography variant="caption">
                      {stringTranslate("reports", "Departure")}
                    </Typography>
                  </div>
                  <div className={classes.positionDescContent}>
                    <Typography variant="body1">
                      {moment
                        .unix(this.state.firstPosition.timestamp.seconds)
                        .format("DD/MM/YYYY HH:mm:ss")}
                      <br />
                      {this.state.firstPositionAddress}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
            {this.state.lastPosition && (
              <div className={classes.positionContainer}>
                <div className={classes.positionLogo}>
                  <img
                    alt="lastPosition"
                    className={classes.positionMarker}
                    src={"assets/mapIcons/report/map-marker-red-b.svg"}
                  />
                </div>
                <div className={classes.positionDesc}>
                  <div className={classes.positionDescTitle}>
                    <Typography variant="caption">
                      {stringTranslate("reports", "Arrival")}
                    </Typography>
                  </div>
                  <div className={classes.positionDescContent}>
                    <Typography variant="body1">
                      {moment
                        .unix(this.state.lastPosition.timestamp.seconds)
                        .format("DD/MM/YYYY HH:mm:ss")}
                      <br />
                      {this.state.lastPositionAddress}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
            {this.state.totalRoute && (
              <div className={classes.positionContainer}>
                <div className={classes.positionLogo}>
                  <FontAwesomeIcon
                    className={classes.positionMarker}
                    icon={["fas", "route"]}
                  />
                </div>
                <div className={classes.positionDesc}>
                  <div className={classes.positionDescTitle}>
                    <Typography variant="caption">
                      {stringTranslate("reports", "Total route")}
                    </Typography>
                  </div>
                  <div className={classes.positionDescContent}>
                    <Typography variant="body1">
                      {Math.round(this.state.totalRoute / 10) / 100 + " Km"}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </div>

          <Measure
            bounds
            onResize={contentRect => {
              this.setState({ dimensions: contentRect.bounds });
            }}
          >
            {({ measureRef, contentRect }) => {
              return (
                <div className={classes.map} ref={measureRef}>
                  <Grid item xs={12}>
                    <div
                      className={classes.mapContainer}
                      style={{
                        height: dimensions.height - 10,
                        width: dimensions.width
                      }}
                    >
                      <div
                        id="map"
                        style={{
                          height: dimensions.height - 10,
                          width: dimensions.width
                        }}
                      />
                      {isLoading ? (
                        <div
                          className={classes.loadingMap}
                          style={{
                            height: dimensions.height - 10,
                            width: dimensions.width
                          }}
                        >
                          <div className={classes.progress}>
                            <CircularProgress color="secondary" />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Grid>
                </div>
              );
            }}
          </Measure>
        </div>
        <Dialog
          open={this.state.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {stringTranslate("reports", "No results title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {stringTranslate("reports", "No results message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogOpen: false })}
              color="primary"
            >
              {stringTranslate("general", "Close")}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

RoutesReport.propTypes = {};

const mapStateToProps = state => ({
  user: state.auth.user,
  resources: state.firestore.ordered.resources,
  reportSelectedResource: state.firestore.data.reportSelectedResource
});

const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => {
    var collections = [
      {
        collection: "/resources",
        where: ["accountId", "==", localStorage.getItem("accountId")],
        storeAs: "resources"
      }
    ];
    if (props.match.params.resourceId) {
      var tmp = {
        collection: "/resources",
        doc: props.match.params.resourceId,
        storeAs: "reportSelectedResource"
      };
      collections.push(tmp);
    }
    return collections;
  }),
  withStyles(styles, { withTheme: true })
)(mapEnvironmentScript(RoutesReport));
