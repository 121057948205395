import * as Actions from "../../actions/customersPage/index";

const initialState = {
  selectedNote: false
};

const selectedNote = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SELECTED_NOTE: {
      return {
        ...state,
        selectedNote: action.note
      };
    }
    case Actions.RESET_ACTION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default selectedNote;
