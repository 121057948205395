import React, { Component } from "react";
import {
  withStyles,
  IconButton,
  Typography,
  Button,
  Popover,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import { GiftedChat, Bubble, Send } from "../../../../gifted-chat/GiftedChat";
import firebase, { getFirestoreCollection } from "../../../../firestoreAccount";
import SendIcon from "@material-ui/icons/Send";
import ArrowBack from "@material-ui/icons/ArrowBack";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import AttachIcon from "@material-ui/icons/AttachFile";
import FileUploader from "react-firebase-file-uploader";
import MD5 from "md5";
import InputChat from "./InputChat";
import { stringTranslate, getMomementLocale } from "languages/OMTranslate";
import AttachmentViewer from "../taskList/detail/attachments/AttachmentViewer";

const styles = theme => ({
  spinner: {
    paddingTop: "100%",
    paddingLeft: "40%"
  },
  sendIcon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.secondary.main,
    height: 40,
    width: 40,
    borderRadius: 20,
    marginRight: 5,
    marginBottom: 3
  },
  attachmentIcon: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    height: "47px",
    minHeight: "47px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.primary.grey3,
    paddingRight: "10px"
  },
  buttonRoot: {
    paddingLeft: "0px"
  },
  buttonText: {
    textTransform: "none",
    maxWidth: "195px",
    flexDirection: "column"
  }
});

class BroadcastInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      progress: 0,
      msg: [],
      viewAttachment: false,
      titleButton: null
    };
  }

  onSend = (msg, attachment = false, url = "") => {
    const { checked } = this.props;
    const messages = this.state.msg;
    const isImage = attachment === "image";
    const isPdf = attachment === "pdf";

    const message = {
      createdAt: msg[0].createdAt,
      image: isImage ? url : "",
      pdf: isPdf ? url : "",
      status: "SENT",
      text: msg[0].text
    };

    messages.unshift({
      id: Date.now(),
      user: {
        id: this.props.user.uid,
        avatar: "",
        name: this.props.user.data.displayName,
        type: "WEB"
      },
      ...message
    });
    this.setState({ msg: messages });

    checked.forEach(roomId => {
      firebase
        .firestore()
        .collection(
          getFirestoreCollection() + `/chatRooms/${roomId}/chatMessages`
        )
        .add({
          ...message,
          user: {
            _id: this.props.user.uid,
            avatar: "",
            name: this.props.user.data.displayName,
            type: "WEB"
          }
        })
        .then(docRef => {
          let lastMessage = isImage
            ? stringTranslate("chat", "image")
            : isPdf
            ? stringTranslate("chat", "pdf")
            : msg[0].text;

          firebase
            .firestore()
            .collection(getFirestoreCollection() + `/chatRooms`)
            .doc(roomId)
            .update({
              lastUpdate: new Date(),
              lastMessage,
              lastSender: this.props.user.uid
            });
        });
    });
  };

  renderBubble = props => {
    const timeTextStyle = {
      left: {
        color: "#ffffff",
        fontSize: "12px"
      },
      right: {
        color: "#33475b",
        fontSize: "12px"
      }
    };
    const wrapperStyle = {
      left: {
        backgroundColor: "#0091ae",
        boxShadow:
          "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
      },
      right: {
        backgroundColor: "#ffffff",
        boxShadow:
          "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
      }
    };

    return (
      <Bubble
        {...props}
        customTextStyle={{ fontSize: "14px" }}
        timeTextStyle={timeTextStyle}
        wrapperStyle={wrapperStyle}
      />
    );
  };

  renderSend = props => {
    const { classes } = this.props;
    return (
      <Send
        {...props}
        children={
          <div className={classes.sendIcon}>
            <SendIcon />
          </div>
        }
      />
    );
  };

  handleUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 });
  };

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = async filename => {
    const fileRef = firebase
      .storage()
      .ref("chatAttachments")
      .child(filename);

    const metadata = await fileRef.getMetadata();
    let fileType = "image";
    if (metadata.contentType === "application/pdf") {
      fileType = "pdf";
    }

    this.setState({ progress: 0, isUploading: false });
    fileRef.getDownloadURL().then(url => {
      this.onSend([{ createdAt: new Date(), text: "" }], fileType, url);
    });
  };

  getSafeFilename = file => {
    // Per uniformare il nome del file a quello caricato da mobile e rendere i file anonimi -> più privacy
    let crypted = MD5(file.name);
    const now = new Date();
    crypted =
      "attach-" +
      crypted.substring(0, 8) +
      "-" +
      crypted.substring(8, 12) +
      "-" +
      crypted.substring(12, 16) +
      "-" +
      crypted.substring(16, 20) +
      "-" +
      crypted.substring(20) +
      "_" +
      now.getTime();
    return crypted;
  };

  renderActions = props => {
    const { classes } = this.props;
    return (
      <label className={classes.attachmentIcon}>
        <AttachIcon />
        <FileUploader
          hidden
          accept="image/*,.pdf"
          name="attachment"
          filename={this.getSafeFilename}
          storageRef={firebase.storage().ref("chatAttachments")}
          onUploadStart={this.handleUploadStart}
          onUploadError={this.handleUploadError}
          onUploadSuccess={this.handleUploadSuccess}
        />
      </label>
    );
  };

  renderInputToolbar = props => {
    return <InputChat {...props} uploading={this.state.isUploading} />;
  };

  renderCustomView = props => {
    if (props.currentMessage.pdf) {
      return (
        <IconButton
          onClick={() =>
            this.setState({ viewAttachment: props.currentMessage.pdf })
          }
        >
          <InsertDriveFile style={{ fontSize: 65 }} />
        </IconButton>
      );
    }
    return null;
  };

  getContactList = () => {
    const { chatRooms, resources, checked } = this.props;
    let description = "";

    chatRooms.forEach(element => {
      if (checked.indexOf(element.id) >= 0) {
        if (resources.hasOwnProperty(element.resourceId)) {
          const res = resources[element.resourceId];
          description += res.name + ", ";
        }
      }
    });
    return description.substring(0, description.length - 2);
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.title}>
          <IconButton
            style={{ marginTop: 4, marginBottom: 4, marginLeft: 4 }}
            onClick={this.props.goBack}
          >
            <ArrowBack color="primary" />
          </IconButton>
          <Button
            classes={{ root: classes.buttonRoot }}
            onClick={event =>
              this.setState({ titleButton: event.currentTarget })
            }
          >
            <div className={classes.buttonText}>
              <Typography variant="h3" color="primary" noWrap align="left">
                {this.props.selectedBroadcast
                  ? this.props.selectedBroadcast.name
                  : stringTranslate("chat", "defaultBroadcastName")}
              </Typography>
              <Typography variant="caption" color="primary" noWrap>
                {this.getContactList()}
              </Typography>
            </div>
          </Button>
          <Popover
            open={Boolean(this.state.titleButton)}
            anchorEl={this.state.titleButton}
            onClose={() => this.setState({ titleButton: null })}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            classes={{
              paper: "py-8"
            }}
          >
            <List>
              <React.Fragment>
                {this.props.chatRooms.forEach(room => {
                  if (this.props.checked.indexOf(room.id) >= 0) {
                    if (this.props.resources.hasOwnProperty(room.resourceId)) {
                      const res = this.props.resources[room.resourceId];

                      return (
                        <ListItem key={room.id} role={undefined} dense>
                          <ListItemText primary={res.name} />
                        </ListItem>
                      );
                    }
                  }
                })}
              </React.Fragment>
            </List>
          </Popover>
        </div>
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <GiftedChat
            messages={this.state.msg}
            user={{
              id: this.props.user.uid
            }}
            onSend={messages => this.onSend(messages)}
            locale={getMomementLocale()}
            placeholder={stringTranslate("chat", "writeMessage")}
            renderAvatar={null}
            renderDay={() => null}
            renderBubble={this.renderBubble}
            renderSend={this.renderSend}
            renderActions={this.renderActions}
            renderInputToolbar={this.renderInputToolbar}
            renderTicks={() => null}
            renderCustomView={this.renderCustomView}
          />
          {this.state.viewAttachment ? (
            <AttachmentViewer
              attachment={{ type: "PDF", url: this.state.viewAttachment }}
              onClose={() => this.setState({ viewAttachment: false })}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(BroadcastInput);
