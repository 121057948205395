import React from "react";
import { Typography, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import columnConfig from "./columnConfig";

const style = theme => ({});

const TextCell = props => {
  return (
    <div
      style={{
        width: columnConfig[props.column],
        paddingLeft: "12px",
        paddingRight: "12px",
        ...props.style
      }}
    >
      {props.tooltip ? (
        <Tooltip title={props.tooltip} placement="top-start" enterDelay={300}>
          <React.Fragment>
            <Typography
              variant="caption"
              style={props.textColor ? { color: props.textColor } : {}}
            >
              {props.content}
            </Typography>
            {props.secondary ? (
              <Typography
                variant="caption"
                style={
                  props.secondaryColor ? { color: props.secondaryColor } : {}
                }
              >
                {props.secondary}
              </Typography>
            ) : null}
          </React.Fragment>
        </Tooltip>
      ) : (
        <React.Fragment>
          <Typography
            variant="caption"
            style={props.textColor ? { color: props.textColor } : {}}
          >
            {props.content}
          </Typography>
          {props.secondary ? (
            <Typography
              variant="caption"
              style={
                props.secondaryColor ? { color: props.secondaryColor } : {}
              }
            >
              {props.secondary}
            </Typography>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};
export default withStyles(style, { withTheme: true })(TextCell);
