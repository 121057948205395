import React from "react";
import { dragDropItems } from "../../../common/dragDropItems";
import { DropTarget } from "react-dnd";
import ResourceListItem from "../../../common/resources/ResourceListItem";

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

const contactTarget = {
  drop(props, monitor) {
    const taskId = monitor.getItem().id;
    props.assignTaskHandler(taskId, props.contact.id);
  }
};

function MainActivityResourceListItem(props) {
  return props.connectDropTarget(
    <div>
      <ResourceListItem {...props} />
    </div>
  );
}

export default DropTarget(dragDropItems.TO_ASSIGN_TASK, contactTarget, collect)(
  MainActivityResourceListItem
);
