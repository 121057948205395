import React, { Component } from "react";
import { FuseScrollbars } from "@fuse";
import {
  withStyles,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  Button,
  Modal,
  TextField,
  Typography,
  IconButton
} from "@material-ui/core";
import { stringTranslate } from "languages/OMTranslate";
import OMSearchAddBar from "../../common/OMSearchAddBar";
import firebase, { getFirestoreCollection } from "../../../../firestoreAccount";
import { ArrowBack } from "@material-ui/icons";

const styles = theme => ({
  scrollbar: {
    flex: 1
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingTop: "24px",
    paddingBottom: "24px",
    borderTopWidth: 1,
    borderTopColor: theme.palette.primary.grey3
  },
  modal: {
    backgroundColor: theme.panels.backgroundColor,
    width: "400px",
    height: "200px",
    position: "absolute",
    left: "50%",
    top: "50%",
    marginLeft: "-100px",
    marginTop: "-200px",
    borderRadius: "4px",
    paddingTop: "24px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "24px"
  },
  modalForm: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title: {
    height: "47px",
    minHeight: "47px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.primary.grey3
  }
});

class BroadcastRoomList extends Component {
  state = {
    search: "",
    showSaveModal: false,
    name: stringTranslate("chat", "defaultBroadcastName")
  };

  setSearch = event => this.setState({ search: event.target.value });

  clearSearch = () => this.setState({ search: "" });

  showModal = () => this.setState({ showSaveModal: true });

  createBroadcast = () => {
    firebase
      .firestore()
      .collection(getFirestoreCollection() + "/broadcastChat")
      .add({
        name: this.state.name,
        chatRooms: this.props.checked,
        userId: this.props.user.uid
      })
      .then(result => {
        result.get().then(doc => {
          this.setState({ showSaveModal: false });
          this.props.showInput({
            id: doc.id,
            userId: doc.data().userId,
            name: doc.data().name,
            chatRooms: doc.data().chatRooms
          });
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ showSaveModal: false });
      });
  };

  render() {
    const { classes, chatRooms, user, resources, checked } = this.props;
    return (
      <React.Fragment>
        <div className={classes.title}>
          <IconButton
            style={{ marginTop: 4, marginBottom: 4, marginLeft: 4 }}
            onClick={this.props.goBack}
          >
            <ArrowBack color="primary" />
          </IconButton>
          <Typography variant="h3" className="px-8 py-4" color="primary">
            {stringTranslate("chat", "broadcast")}
          </Typography>
        </div>
        <OMSearchAddBar
          searchText={this.state.search}
          textChangeHandler={this.setSearch}
          textClearHandler={this.clearSearch}
        />
        <FuseScrollbars className={classes.scrollbar}>
          <List>
            <React.Fragment>
              {chatRooms.map(room => {
                const resource = resources ? resources[room.resourceId] : null;
                if (
                  user &&
                  room.users.indexOf(user.uid) >= 0 &&
                  resource &&
                  resource.name
                    .toLowerCase()
                    .includes(this.state.search.toLowerCase())
                ) {
                  return (
                    <ListItem
                      key={room.id}
                      role={undefined}
                      dense
                      button
                      onClick={() => this.props.handleToggle(room.id)}
                    >
                      <Checkbox
                        checked={checked.indexOf(room.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                      <ListItemText primary={resource.name} />
                    </ListItem>
                  );
                } else {
                  return null;
                }
              })}
            </React.Fragment>
          </List>
        </FuseScrollbars>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.props.showInput(null)}
            disabled={checked.length <= 0}
          >
            {stringTranslate("chat", "write")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.showModal}
            disabled={checked.length <= 0}
          >
            {stringTranslate("chat", "save")}
          </Button>
        </div>
        <Modal
          open={this.state.showSaveModal}
          onClose={() => this.setState({ showSaveModal: false })}
        >
          <div className={classes.modal}>
            <Typography>
              {stringTranslate("chat", "broadcastModalMsg")}
            </Typography>
            <br />
            <div className={classes.modalForm}>
              <TextField
                id="name"
                label={stringTranslate("chat", "broadcastName")}
                defaultValue={this.state.name}
                InputLabelProps={{ shrink: true }}
                onChange={event => this.setState({ name: event.target.value })}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={this.createBroadcast}
                disabled={this.state.name.length <= 0}
              >
                {stringTranslate("chat", "save")}
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(BroadcastRoomList);
