export default {
  chat: "Chat",
  writeMessage: "Write a message",
  upload: "Uploading file",
  loadMessages: "Load messages",
  image: "Image",
  pdf: "Pdf",
  broadcast: "Broadcast",
  write: "Write",
  save: "Save",
  broadcastName: "Broadcast name",
  defaultBroadcastName: "New broadcast",
  broadcastModalMsg:
    "You can name this list of contacts if you want to send broadcast messages to the same resources in the future",
  emptyList: "No broadcast found"
};
