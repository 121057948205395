import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const styles = () => ({
  handle: {
    position: "relative",
    display: "flex",
    overflow: "hidden",
    boxSizing: "border-box",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    padding: "0.5rem"
  },
  icon: {
    margin: "auto",
    fontSize: "2em",
    color: "#33475B",
    transition: "color 200ms ease-in",
    padding: 0
  }
});

const Handle = ({ classes, ...props }) => (
  <div {...props} className={classes.handle}>
    <DragIndicatorIcon className={classes.icon} />
  </div>
);

Handle.propTypes = {
  classes: PropTypes.shape().isRequired // Provided by withStyles
};

export default withStyles(styles)(Handle);
