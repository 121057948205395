import * as Actions from "../../actions/fuse/index";

const initialState = {
  state: false,
  options: {
    children: "Hi"
  },
  openDeleteDemoDataDialog: false
};

const dialog = function(state = initialState, action) {
  switch (action.type) {
    case Actions.OPEN_DIALOG: {
      return {
        ...state,
        state: true,
        options: {
          ...state.options,
          ...action.options
        }
      };
    }
    case Actions.CLOSE_DIALOG: {
      return {
        ...state,
        state: false
      };
    }
    case Actions.OPEN_DELETE_DEMO_DATA_DIALOG: {
      return {
        ...state,
        openDeleteDemoDataDialog: true
      };
    }
    case Actions.CLOSE_DELETE_DEMO_DATA_DIALOG: {
      return {
        ...state,
        openDeleteDemoDataDialog: false
      };
    }
    default: {
      return state;
    }
  }
};

export default dialog;
