import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import BackIcon from "@material-ui/icons/ArrowBack";
import { stringTranslate } from "languages/OMTranslate";

import ModuleForm from "../../../../modules/module/ModuleForm";

const styles = theme => ({
  container: {
    position: "relative",
    display: "flex",
    flexFlow: "column nowrap",
    flex: 1,
    height: "100%"
  },
  backButton: {
    position: "absolute",
    top: theme.spacing.unit,
    left: theme.spacing.unit
  },
  dialogTitle: {
    minHeight: "70px",
    paddingBottom: "10px"
  },
  dialogContent: {},
  title: {
    paddingLeft: "50px"
  }
});

const ModuleDetail = ({ module, customForms, task, onBack, classes }) => (
  <React.Fragment>
    <IconButton className={classes.backButton} onClick={onBack}>
      <BackIcon />
    </IconButton>
    <DialogTitle
      id="simple-dialog-title"
      disableTypography
      className={classes.dialogTitle}
    >
      <Typography variant="h3" className={classes.title}>
        {`${
          module
            ? customForms.find(cf => module.customFormId === cf.id).name
            : stringTranslate("modules", "newModule")
        }`}
      </Typography>
    </DialogTitle>
    <DialogContent className={classes.dialogContent}>
      <div className={classes.container}>
        <ModuleForm
          customForms={customForms}
          module={module}
          task={task}
          onDeleted={onBack}
        />
      </div>
    </DialogContent>
  </React.Fragment>
);

ModuleDetail.propTypes = {
  task: PropTypes.shape({
    description: PropTypes.string.isRequired
  }).isRequired,
  customForms: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          required: PropTypes.bool.isRequired,
          position: PropTypes.number.isRequired,
          help: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              label: PropTypes.string
            })
          )
        }).isRequired
      ).isRequired
    }).isRequired
  ).isRequired,
  module: PropTypes.shape({}),
  onBack: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired // Oggetto creato da withStyles
};

ModuleDetail.defaultProps = {};

export default withStyles(styles)(ModuleDetail);
