import React from "react";
import { Icon } from "@material-ui/core";
import * as taskConstants from "./taskConstants";
import { withStyles } from "@material-ui/core/styles";
import { getTaskColor } from "main/content/mainActivity/scheduler/getTaskColor";

const statusConf = {
  [taskConstants.STATUS_TOEXECUTE]: "new_releases",
  [taskConstants.STATUS_OG_ENROUTE]: "local_shipping",
  [taskConstants.STATUS_OG_NEARBY]: "beenhere",
  [taskConstants.STATUS_OG_EXECUTING]: "play_circle_filled",
  [taskConstants.STATUS_COMPLETED]: "assignment_turned_in",
  [taskConstants.STATUS_ABORTED]: "cancel"
};

const styles = {};

const TaskExecutionStatusIcon = ({ task }) => {
  return (
    <Icon
      className="block text-24 bg-white rounded-full"
      style={{
        color: getTaskColor(task)
      }}
    >
      {statusConf[task.executionStatus]}
    </Icon>
  );
};

export default withStyles(styles, { withTheme: true })(TaskExecutionStatusIcon);
