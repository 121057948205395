import { combineReducers } from "redux";
import fuse from "./fuse";
import auth from "auth/store/reducers";
import mainActivityPage from "./mainActivityPage";
import resources from "./resources";
import customersPage from "./customersPage";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const createReducer = asyncReducers =>
  combineReducers({
    auth,
    fuse,
    mainActivityPage,
    resources,
    customersPage,
    firebase: firebaseReducer,
    firestore: firestoreReducer,

    ...asyncReducers
  });

export default createReducer;
