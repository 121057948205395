import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { InfoWindow } from "react-google-maps";
import { getPartiesMarkerIcon } from "./getMarkerIcon";
import { Typography } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stringTranslate } from "languages/OMTranslate";
import {
  toggleTaskForm,
  passValueToForm
} from "store/actions/mainActivityPage";

const {
  MarkerWithLabel
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const styles = theme => ({
  infoWindowTitle: {
    borderBottom: "1px solid " + theme.palette.primary.border,
    fontWeight: "bold",
    padding: "4px"
  },
  infoWindowText: {
    fontSize: 12,
    padding: "4px"
  },
  infoWindowLinkBox: {
    fontSize: 12,
    padding: "4px",
    cursor: "pointer",
    display: "flex"
  }
});

class PartiesMapMarkerComponent extends React.Component {
  state = { isOpen: false };

  onToggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const icon = {
      url: getPartiesMarkerIcon(this.props.color),
      scaledSize: new window.google.maps.Size(15, 20)
    };

    let label = this.props.poiName;
    if (label && label.length > 10) {
      label = label.substring(0, 10) + "...";
    }

    return (
      <MarkerWithLabel
        position={this.props.position}
        icon={icon}
        className="mycolor"
        onClick={this.onToggleOpen}
        labelAnchor={new window.google.maps.Point(25, 0)}
        labelClass="marker-label"
        labelOrigin={new window.google.maps.Point(75, 32)}
        labelVisible={Boolean(label)}
      >
        <React.Fragment>
          <div>{label}</div>
          {this.state.isOpen && (
            <InfoWindow
              onCloseClick={this.onToggleOpen}
              options={{
                pixelOffset: new window.google.maps.Size(0, -22)
              }}
              position={this.props.position}
            >
              <React.Fragment>
                <Typography
                  variant="body1"
                  className={this.props.classes.infoWindowTitle}
                >
                  {this.props.party.name}
                </Typography>
                <Typography
                  variant="body1"
                  className={this.props.classes.infoWindowText}
                >
                  {this.props.poiName}
                </Typography>
                <Typography
                  variant="body1"
                  className={this.props.classes.infoWindowText}
                >
                  {this.props.poiAddress}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    alignItems: "center"
                  }}
                >
                  <div
                    onClick={() => {
                      this.props.passValueToForm({
                        partyId: {
                          label: this.props.party.name,
                          value: this.props.party.id
                        }
                      });
                      this.props.toggleTaskForm({
                        taskFormOpen: true,
                        detailDataPanelOpen: true,
                        managedTaskId: null
                      });
                    }}
                  >
                    <Typography
                      variant="body1"
                      className={this.props.classes.infoWindowLinkBox}
                    >
                      <FontAwesomeIcon
                        icon={["fas", "plus-circle"]}
                        className="mr-4"
                      />
                      {stringTranslate("mainactivity", "addTask")}
                    </Typography>
                  </div>
                </div>
              </React.Fragment>
            </InfoWindow>
          )}
        </React.Fragment>
      </MarkerWithLabel>
    );
  }
}

export default compose(
  connect(
    null,
    dispatch => ({
      toggleTaskForm: options => dispatch(toggleTaskForm(options)),
      passValueToForm: values => dispatch(passValueToForm(values))
    })
  ),
  withStyles(styles, { withTheme: true })
)(PartiesMapMarkerComponent);
