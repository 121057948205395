import { authRoles } from "auth";
import RoutesReport from "./RoutesReport";
import TaskReport from "./TaskReport";
import ResourceReport from "./ResourceReport";
import FilterTest from "./FilterTest";
import TimeTrackingReport from "./timeTracking/TimeTrackingReport";

export const ReportsConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.staff, //['admin','staff']
  routes: [
    {
      path: "/reports/routes/:resourceId",
      component: RoutesReport
    },
    {
      path: "/reports/routes/",
      component: RoutesReport
    },
    {
      path: "/reports/filterTest/:resourceId",
      component: FilterTest
    },
    {
      path: "/reports/filterTest/",
      component: FilterTest
    },
    {
      path: "/reports/resources/",
      component: ResourceReport
    },
    {
      path: "/reports/timeTracking/",
      component: TimeTrackingReport
    },
    {
      path: "/reports/tasks/",
      component: TaskReport
    }
  ]
};
