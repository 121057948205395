import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles/index";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate, getMomementLocale } from "languages/OMTranslate";
import firebase from "firebase/app";
import errorReporting from "main/common/errorReporting";
import { compose } from "redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";
import moment from "moment";

const styles = theme => ({
  root: {}
});

class OMAuthVerify extends React.Component {
  state = { dialogOpen: false };

  componentDidUpdate(prevProps) {
    if (prevProps.user.role === "guest" && this.props.user !== "guest") {
      this.checkEmailVerification();
    }
  }

  componentDidMount() {
    this.checkEmailVerification();
  }

  checkEmailVerification = () => {
    if (firebase.auth().currentUser) {
      const isEmailVerified = firebase.auth().currentUser.emailVerified;
      if (!this.props.user.emailVerified && isEmailVerified) {
        firebase
          .firestore()
          .collection("users")
          .doc(this.props.user.uid)
          .update({ emailVerified: true });

        var hubspotUrl =
          "https://api.hsforms.com/submissions/v3/integration/submit/5410082/857f5155-93a4-4103-b6ac-18e9ef936c0e";

        var data = {
          fields: [
            {
              name: "email",
              value: this.props.user.data.email
            },
            {
              name: "firstname",
              value: this.props.user.data.firstName
            },
            {
              name: "lastname",
              value: this.props.user.data.lastName
            },
            {
              name: "company",
              value: this.props.account.name
            },
            {
              name: "message",
              value: "Registration Form"
            },
            {
              name: "hs_language",
              value: getMomementLocale()
            },
            {
              name: "seniority",
              value: "EMAIL_VERIFIED"
            }
          ]
        };

        var xhr = new XMLHttpRequest();
        var final_data = JSON.stringify(data);
        xhr.open("POST", hubspotUrl);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.send(final_data);
      }
      if (!isEmailVerified && !this.props.user.emailVerified)
        this.setState({
          dialogOpen: true
        });
    }
  };

  handleResend = () => {
    firebase.auth().useDeviceLanguage();
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(() => {
        this.setState({ dialogOpen: false });
      })
      .catch(error => {
        errorReporting(
          this.props.user.uid,
          "error",
          "Error in resend google confirmation email"
        );
        this.setState({ dialogOpen: false });
      });
  };

  render() {
    const { user, account } = this.props;
    const { dialogOpen } = this.state;

    var content = stringTranslate(
      "general",
      "Your email %%address%% is not verified, check the mailbox (and the Spam folder)"
    );
    content = content.replace("%%address%%", user.data.email);

    var creationDate =
      user && user.createDatetime
        ? moment.unix(user.createDatetime.seconds).startOf("day")
        : moment().startOf("day");
    var today = moment().startOf("day");

    var isOpen = false;
    if (
      account &&
      account.loaded &&
      user &&
      user.createDatetime &&
      creationDate < today &&
      dialogOpen
    )
      isOpen = true;

    return (
      <React.Fragment>
        {this.props.children}
        {firebase.auth().currentUser && (
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onBackdropClick={() => this.setState({ dialogOpen: false })}
          >
            <DialogTitle id="alert-dialog-title">
              {stringTranslate("general", "Verify email address")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {content}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ dialogOpen: false })}
                color="primary"
              >
                {stringTranslate("general", "Close")}
              </Button>
              <Button
                onClick={() => this.handleResend()}
                color="secondary"
                autoFocus
              >
                {stringTranslate("general", "Resend email")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  account: state.firestore.data.account
});

export default compose(
  firestoreConnect(),
  connect(mapStateToProps),
  withStyles(styles, { withTheme: true })
)(OMAuthVerify);
