import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  IconButton,
  withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { stringTranslate } from "languages/OMTranslate";

const styles = {
  dialogTitle: {
    display: "flex",
    alignItems: "center"
  },
  dialogActions: {
    padding: "24px"
  }
};

const OMConfirmationPopup = ({
  isOpen,
  closeHandler,
  confirmHandler,
  title,
  text,
  confirmActionText,
  cancelActionText,
  showCancelButton,
  classes
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        className={classes.dialogTitle}
        id="alert-dialog-title"
        disableTypography
      >
        <Typography className="flex-1" variant="h3">
          {title}
        </Typography>
        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {showCancelButton && (
          <Button variant="contained" color="primary" onClick={closeHandler}>
            {cancelActionText
              ? cancelActionText
              : stringTranslate("general", "Cancel")}
          </Button>
        )}
        <Button variant="contained" color="secondary" onClick={confirmHandler}>
          {confirmActionText
            ? confirmActionText
            : stringTranslate("general", "Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OMConfirmationPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirmHandler: PropTypes.func.isRequired,
  closeHandler: PropTypes.func,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  confirmActionText: PropTypes.string.isRequired,
  cancelActionText: PropTypes.string,
  showCancelButton: PropTypes.bool
};

OMConfirmationPopup.defaultProps = {
  isOpen: false,
  showCancelButton: true,
  closeHandler: () => {}
};

export default withStyles(styles, { withTheme: true })(OMConfirmationPopup);
