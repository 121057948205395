//execution status
export const STATUS_TOEXECUTE = "TOEXECUTE";
export const STATUS_COMPLETED = "COMPLETED";
export const STATUS_SUSPENDED = "SUSPENDED";
export const STATUS_ABORTED = "ABORTED";
export const STATUS_REJECTED = "REJECTED";
export const STATUS_OG_ENROUTE = "OG-ENROUTE";
export const STATUS_OG_NEARBY = "OG-NEARBY";
export const STATUS_OG_ARRIVED = "OG-ARRIVED";
export const STATUS_OG_EXECUTING = "OG-EXECUTING";

//transmission status
export const TRANSMISSION_SENT = "SENT";
export const TRANSMISSION_RECEIVED = "RECEIVED";
export const TRANSMISSION_READ = "READ";
