import React from "react";
import columnConfig from "./columnConfig";
import { TableSortLabel } from "@material-ui/core";

const HeaderCell = props => {
  return (
    <div
      style={{
        width: columnConfig[props.column],
        paddingLeft: "12px",
        paddingRight: "12px",
        ...props.style
      }}
    >
      <TableSortLabel
        active={props.orderBy === props.id}
        direction={props.order}
        onClick={event => props.createSortHandler(event, props.id)}
      >
        {props.label}
      </TableSortLabel>
    </div>
  );
};
export default HeaderCell;
