import * as Actions from "../../actions/resources/index";

const initialState = {
  selectedResource: null,
  isToShowResourceForm: false
};

const resourceList = function(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_RESOURCE:
      return { ...state, selectedResource: action.resourceId };
    case Actions.SHOW_RESOURCE_FORM:
      return { ...state, isToShowResourceForm: action.flag };
    default:
      return state;
  }
};

export default resourceList;
