import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MainActivityButtonMenu from "./MainActivityButtonMenu";
import MainActivityResourceListContainer from "./containers/MainActivityResourceListContainer";
import MainActivityMapContainer from "./containers/MainActivityMapContainer";
import MainActivitySchedulerContainer from "./containers/MainActivitySchedulerContainer";
import MainActivityTaskListContainer from "./containers/MainActivityTaskListContainer";
import MainActivityChatContainer from "./containers/MainActivityChatContainer";
import MainActivityTitleContainer from "./containers/MainActivityTitleContainer";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import firebase, { getFirestoreCollection } from "../../../firestoreAccount";
import Joyride, { ACTIONS, EVENTS, STATUS, LIFECYCLE } from "react-joyride";
import { bigScreenSteps, smallScreenSteps } from "./guidedTourSteps";
import {
  userListToggle,
  mapToggle,
  calendarToggle,
  taskListToggle,
  chatToggle,
  setActiveChat,
  saveNotifications,
  hideAllPanels
} from "store/actions/mainActivityPage";
import { stringTranslate } from "languages/OMTranslate";
import { withRouter } from "react-router-dom";
import { updateReduxUser } from "auth/store/actions";
import { showAllResources } from "store/actions/mainActivityPage";
import { handleIndex, smallScreenHandleIndex } from "./handleTour";
import { withSnackbar } from "notistack";
import Close from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import notificationClickHandler from "main/common/toolbar/notificationClickHandler";
import Hidden from "@material-ui/core/Hidden";
import $ from "jquery";
import moment from "moment";

const styles = theme => ({
  hContainer: {
    position: "relative",
    display: "flex",
    flexFlow: "row nowrap",
    // height: "calc(100vh - 122px)",
    height: "calc(100vh - 48px)",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      flexFlow: "column",
      width: "100%"
    }
  },
  vContainer: {
    backgroundColor: theme.palette.primary.lightGrey,
    order: 3,
    flex: "1 1 auto",
    width: "50%",
    display: "flex",
    flexFlow: "column nowrap",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      order: 2,
      width: "100%"
    }
  },
  snackbarContent: {
    backgroundColor: "#283848"
  }
});

class MainActivityPage extends Component {
  constructor(props) {
    super(props);
    this.unsubscribe = null;
    this.state = {
      runTour: false,
      showResourceHint: false,
      stepIndex: 0,
      initTour: false,
      allTasks: [],
      filteredTasks: []
    };

    if ("Notification" in window && Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }

  resize = () => this.forceUpdate();

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);

    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  filterTasks = () => {
    if (!this.state.allTasks) return;

    const filteredTasks = this.state.allTasks.filter(task => {
      if (task.executionStatus != "COMPLETED") return true;
      var filterDate = moment().subtract(3, "days");
      var taskDate = moment();
      if (task.ending) taskDate = moment.unix(task.ending.seconds);
      if (taskDate.isBefore(filterDate)) return false;
      return true;
    });
    this.setState({ filteredTasks });
  };

  componentDidMount() {
    firebase
      .firestore()
      .collection(getFirestoreCollection() + "/tasks")
      .onSnapshot(querySnapshot => {
        let allTasks = [];
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            allTasks.push({
              id: doc.id,
              ...doc.data()
            });
          });
        }
        this.setState({ allTasks }, () => this.filterTasks());
      });

    window.addEventListener("resize", this.resize);
    this.props.setShowAllResources(true);

    const collection = getFirestoreCollection() + "/notifications";
    this.unsubscribe = firebase
      .firestore()
      .collection(collection)
      .where("status", "==", "NEW")
      .orderBy("date", "desc")
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(change => {
          if (change.type === "added") {
            if (
              change.doc.data().type !== "CHAT" ||
              !this.props.chatVisibility ||
              this.props.selectedChat === null ||
              this.props.selectedChat.id !== change.doc.data().sourceId
            ) {
              if (document.hasFocus()) {
                this.showInAppNotification(change.doc.id, change.doc.data());
              } else {
                this.showBrowserNotification(change.doc.id, change.doc.data());
              }
            }
          }
        });

        let notifications = [];
        snapshot.forEach(doc => {
          notifications.push({
            id: doc.id,
            title: doc.data().title,
            body: doc.data().body,
            sourceId: doc.data().sourceId,
            type: doc.data().type,
            status: doc.data().status,
            date: doc.data().date
          });
        });
        this.props.saveNotifications(notifications);
      });

    if (this.props.account && !this.state.initTour) {
      this.setState({ initTour: true }, () => {
        this.initTour();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      $("#hContainer").height(window.innerHeight - 48);
    }
    $("#hContainer").height(window.innerHeight - 48);
    if (!prevProps.account && this.props.account && !this.state.initTour) {
      this.setState({ initTour: true }, () => {
        this.initTour();
      });
    }
  }

  initTour = () => {
    if (this.props.user && this.props.user.showGuidedTour) {
      let stepIndex = 0;
      if (
        this.props.user.stepIndex !== null &&
        this.props.user.stepIndex !== undefined &&
        this.props.user.stepIndex !== 999
      ) {
        stepIndex = this.props.user.stepIndex;
      }

      if (
        window.innerWidth <
        this.props.theme.breakpoints.values[
          this.props.theme.measures.nextBreakpoint
        ]
      ) {
        smallScreenHandleIndex({
          stepIndex,
          edition: this.props.account.edition
            ? this.props.account.edition
            : null,
          ...this.props
        });
      } else {
        handleIndex({
          stepIndex,
          edition: this.props.account.edition
            ? this.props.account.edition
            : null,
          ...this.props
        });
      }
      this.setState({ runTour: true, stepIndex });
    }
  };

  showInAppNotification = (id, notification) => {
    this.props.enqueueSnackbar(this.getNotificationTitle(notification), {
      variant: "default",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      },
      ContentProps: {
        classes: {
          root: this.props.classes.snackbarContent
        }
      },
      autoHideDuration: 3000,
      action: (
        <IconButton>
          <Close fontSize="small" style={{ color: "#ffffff" }} />
        </IconButton>
      ),
      message: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            color: "#fff"
          }}
        >
          <div style={{ minHeight: "14px" }}>
            {this.getNotificationTitle(notification)}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px",
              minHeight: "14px"
            }}
          >
            {this.getNotificationBody(notification)}
          </div>
        </div>
      )
    });
  };

  getNotificationTitle = notification => {
    if (notification.type === "PAYMENTS") {
      return stringTranslate("account", "missingPaymentMethodDialogTitle");
    }
    return notification.title;
  };

  getNotificationBody = notification => {
    if (notification.type === "PAYMENTS") {
      return (
        stringTranslate("account", "missingPaymentMethodNotificationBody1") +
        notification.body +
        stringTranslate("account", "missingPaymentMethodNotificationBody2")
      );
    }
    return notification.body;
  };

  showBrowserNotification = (id, notification) => {
    if (Notification.permission === "granted") {
      const options = {
        body: this.getNotificationBody(notification),
        tag: notification.type,
        data: {
          sourceId: notification.sourceId,
          notificationId: notification.id
        },
        renotify: true
      };
      let n = new Notification(
        this.getNotificationTitle(notification),
        options
      );
      n.onclick = event => {
        notificationClickHandler({
          notificationId: id,
          type: event.target.tag,
          sourceId: event.target.data.sourceId,
          ...this.props
        });
      };
    }
  };

  tooltipCallback = data => {
    const { action, index, status, type, lifecycle } = data;
    let maxStepIndex =
      this.props.user.maxStepIndex === null ||
      this.props.user.maxStepIndex === undefined
        ? 0
        : this.props.user.maxStepIndex;

    if (maxStepIndex < index) {
      maxStepIndex = index;
    }

    if (
      action === ACTIONS.CLOSE &&
      lifecycle === LIFECYCLE.COMPLETE &&
      type === EVENTS.STEP_AFTER
    ) {
      // L'utente ha chiuso le slide del tour con il tasto chiudi in alto a destra
      this.setState({ runTour: false, showResourceHint: true });
      this.props.updateReduxUser({
        stepIndex: index,
        maxStepIndex,
        showGuidedTour: false
      });
      firebase
        .firestore()
        .collection("users")
        .doc(this.props.user.uid)
        .update({ showGuidedTour: false, stepIndex: index, maxStepIndex });
    } else if (EVENTS.STEP_AFTER === type) {
      // Dopo la 14esima slide bisogna cambiare pagina
      if (
        index === 13 &&
        action === ACTIONS.NEXT &&
        (this.props.account.edition === "EE" ||
          this.props.account.edition === "PE")
      ) {
        this.props.updateReduxUser({
          stepIndex: index + 1,
          maxStepIndex: maxStepIndex + 1
        });
        this.setState({ stepIndex: index + 1 }, () => {
          firebase
            .firestore()
            .collection("users")
            .doc(this.props.user.uid)
            .update({ stepIndex: index + 1, maxStepIndex: maxStepIndex + 1 })
            .then(result => {
              this.props.history.push({ pathname: "/resources" });
            });
        });
      } else if (
        index === 10 &&
        action === ACTIONS.NEXT &&
        (!this.props.account.edition || this.props.account.edition === "SE")
      ) {
        // Dopo la decima slide bisogna cambiare pagina se l'account è STARTER
        this.props.updateReduxUser({
          stepIndex: index + 1,
          maxStepIndex: maxStepIndex + 1
        });
        this.setState({ stepIndex: index + 1 }, () => {
          firebase
            .firestore()
            .collection("users")
            .doc(this.props.user.uid)
            .update({ stepIndex: index + 1, maxStepIndex: maxStepIndex + 1 })
            .then(result => {
              this.props.history.push({ pathname: "/resources" });
            });
        });
      } else {
        // Mostra la prossima slide
        this.setState({
          stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
        });
      }
    } else if (EVENTS.STEP_BEFORE === type) {
      handleIndex({
        stepIndex: index,
        edition: this.props.account.edition,
        ...this.props
      });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ runTour: false, showResourceHint: true });
      this.props.updateReduxUser({
        stepIndex: 999,
        maxStepIndex,
        showGuidedTour: false
      });
      firebase
        .firestore()
        .collection("users")
        .doc(this.props.user.uid)
        .update({ showGuidedTour: false, stepIndex: 999, maxStepIndex });
    }
  };

  smallScreenTooltipCallback = data => {
    const { action, index, status, type, lifecycle } = data;

    if (
      action === ACTIONS.CLOSE &&
      lifecycle === LIFECYCLE.COMPLETE &&
      type === EVENTS.STEP_AFTER
    ) {
      // L'utente ha chiuso le slide del tour con il tasto chiudi in alto a destra
      this.setState({ runTour: false, showResourceHint: true });
      this.props.updateReduxUser({ stepIndex: index, showGuidedTour: false });
      firebase
        .firestore()
        .collection("users")
        .doc(this.props.user.uid)
        .update({ showGuidedTour: false, stepIndex: index });
    } else if (EVENTS.STEP_AFTER === type) {
      // Mostra la prossima slide
      this.setState({
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
      });
    } else if (EVENTS.STEP_BEFORE === type) {
      smallScreenHandleIndex({
        stepIndex: index,
        edition: this.props.account.edition,
        ...this.props
      });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ runTour: false, showResourceHint: true });
      this.props.updateReduxUser({ stepIndex: 999, showGuidedTour: false });
      firebase
        .firestore()
        .collection("users")
        .doc(this.props.user.uid)
        .update({ showGuidedTour: false, stepIndex: 999 });
    }
  };

  render() {
    const { classes } = this.props;
    const tourProps = {
      showProgress: true,
      run: this.state.runTour,
      stepIndex: this.state.stepIndex,
      continuous: true,
      disableCloseOnEsc: true,
      disableOverlayClose: true,
      styles: {
        options: {
          primaryColor: "#ff7b59"
        }
      },
      locale: {
        back: stringTranslate("tour", "back"),
        close: stringTranslate("tour", "close"),
        last: stringTranslate("tour", "last"),
        next: stringTranslate("tour", "next"),
        skip: stringTranslate("tour", "skip")
      }
    };

    const edition =
      this.props.account && this.props.account.edition
        ? this.props.account.edition
        : "SE";

    return (
      <React.Fragment>
        <div className="main-container" id="guidedTour-step1">
          <div className={classes.hContainer} id="hContainer">
            <Hidden smUp>
              <Joyride
                steps={smallScreenSteps[edition]}
                callback={this.smallScreenTooltipCallback}
                hideBackButton={true}
                {...tourProps}
              />
            </Hidden>
            <Hidden xsDown>
              <Joyride
                steps={bigScreenSteps[edition]}
                callback={this.tooltipCallback}
                {...tourProps}
              />
            </Hidden>
            {this.props.userListVisibility ? (
              <Joyride
                steps={[
                  {
                    target: "#guidedTour-step14",
                    content: stringTranslate("tour", "resourceBtn"),
                    placement: "right",
                    placementBeacon: "top"
                  }
                ]}
                run={this.state.showResourceHint}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                locale={{
                  back: stringTranslate("tour", "back"),
                  close: stringTranslate("tour", "close"),
                  last: stringTranslate("tour", "last"),
                  next: stringTranslate("tour", "next"),
                  skip: stringTranslate("tour", "skip")
                }}
              />
            ) : null}
            <MainActivityButtonMenu innerWidth={window.innerWidth} />
            <MainActivityResourceListContainer />
            {window.innerWidth <
            this.props.theme.breakpoints.values[
              this.props.theme.measures.nextBreakpoint
            ] ? (
              <React.Fragment>
                <div className={classes.vContainer}>
                  <MainActivityTitleContainer />
                  <MainActivityMapContainer tasks={this.state.filteredTasks} />
                  <MainActivitySchedulerContainer
                    tasks={this.state.filteredTasks}
                  />
                  <MainActivityTaskListContainer />
                  <MainActivityChatContainer />
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={classes.vContainer}>
                  <MainActivityTitleContainer />
                  <MainActivityMapContainer tasks={this.state.filteredTasks} />
                  <MainActivitySchedulerContainer
                    tasks={this.state.filteredTasks}
                  />
                  <MainActivityTaskListContainer />
                </div>
                <MainActivityChatContainer />
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

MainActivityPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  account: state.firestore.data.account,
  user: state.auth.user,
  chatRooms: state.mainActivityPage.chat.chatRooms,
  chatVisibility: state.mainActivityPage.panelVisibility.chatVisibility,
  mapVisibility: state.mainActivityPage.panelVisibility.mapVisibility,
  calendarVisibility: state.mainActivityPage.panelVisibility.calendarVisibility,
  taskListVisibility: state.mainActivityPage.panelVisibility.taskListVisibility,
  userListVisibility: state.mainActivityPage.panelVisibility.userListVisibility,
  showAllResources: state.mainActivityPage.activityMap.showAllResources,
  resources: state.firestore.ordered.resources,
  selectedChat: state.mainActivityPage.chat.selectedChat
});

const mapDispatchToProps = dispatch => ({
  userListToggle: () => dispatch(userListToggle()),
  mapToggle: () => dispatch(mapToggle()),
  taskListToggle: () => dispatch(taskListToggle()),
  calendarToggle: () => dispatch(calendarToggle()),
  chatToggle: () => dispatch(chatToggle()),
  setActiveChat: room => dispatch(setActiveChat(room)),
  updateReduxUser: user => dispatch(updateReduxUser(user)),
  setShowAllResources: isToShow => dispatch(showAllResources(isToShow)),
  saveNotifications: notifications =>
    dispatch(saveNotifications(notifications)),
  hideAllPanels: () => dispatch(hideAllPanels())
});

export default withRouter(
  compose(
    firestoreConnect(props => {
      let collection = [
        {
          collection: getFirestoreCollection() + "/pois",
          orderBy: "address",
          storeAs: "pois"
        },
        {
          collection: "/resources",
          where: ["accountId", "==", localStorage.getItem("accountId")],
          orderBy: "name",
          storeAs: "resources"
        },
        {
          collection: getFirestoreCollection() + "/parties",
          where: ["isActive", "==", true],
          orderBy: "name",
          storeAs: "parties"
        },
        {
          collection: getFirestoreCollection() + "/contacts",
          orderBy: "name",
          storeAs: "contacts"
        },
        {
          collection: getFirestoreCollection() + "/pois",
          where: ["partyId", "==", ""],
          orderBy: "address",
          storeAs: "freePois"
        },
        {
          collection: getFirestoreCollection() + "/contacts",
          where: ["partyId", "==", ""],
          orderBy: "name",
          storeAs: "freeContacts"
        },
        // {
        //   collection: getFirestoreCollection() + "/tasks",
        //   storeAs: "tasks",
        //   orderBy: "beginning"
        // },
        {
          collection: getFirestoreCollection() + "/tasks",
          where: ["resourceId", "==", ""],
          storeAs: "toAssignTasks"
        },
        {
          collection: getFirestoreCollection() + "/poiCategories",
          orderBy: "name",
          storeAs: "poiCategories"
        }
      ];
      return collection;
    }),
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withStyles(styles, { withTheme: true })
  )(withSnackbar(MainActivityPage))
);
