import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withStyles } from "@material-ui/core/styles";
import {
  setResource,
  showResourceForm
} from "../../../../store/actions/resources";
import { openDeleteDemoDataDialog } from "store/actions/fuse";
import ChipInput from "material-ui-chip-input";
import { stringTranslate } from "languages/OMTranslate";
import {
  Button,
  Avatar,
  Typography,
  Hidden,
  IconButton,
  Grid
} from "@material-ui/core";
import { TextFieldFormsy, OMPhoneInputFormsy } from "@fuse";
import PersonIcon from "@material-ui/icons/Person";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import OMDeleteConfirmationAlert from "../../common/OMDeleteConfirmationAlert";
import OMConfirmationPopup from "../../common/OMConfirmationPopup";
import Formsy from "formsy-react";
import * as resourceConstants from "../../../common/resources/resourceConstants";
import { getFirestoreCollection } from "../../../../firestoreAccount";
import firebase from "firestoreAccount";
import "firebase/functions";
import errorReporting from "main/common/errorReporting";

const styles = theme => ({
  formContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    marginTop: "1.6rem"
  },
  avatar: {
    margin: 10,
    width: 80,
    height: 80,
    [theme.breakpoints.down("xs")]: {
      width: 120,
      height: 120
    }
  },
  title: {
    display: "flex",
    alignItems: "center"
  },
  leftArrow: {
    marginRight: "1.6rem"
  },
  phoneInputElement: {
    borderBottom: "2px solid " + theme.palette.primary.dark,
    "&:hover": {
      borderBottom: "2px solid black"
    },
    "&:focus": {
      borderBottom: "2px solid black !important"
    }
  }
});

class ResourceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      resource: this.getEmptyResource(),
      deleteConfirmationOpen: false,
      sendInviteConfirmationOpen: false,
      onSaving: false,
      onSaved: false,
      onInvited: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedResourceId !== this.props.selectedResourceId) {
      if (this.props.selectedResourceId) {
        this.setResourceData();
      } else {
        this.resetForm();
      }
    }
  }

  componentDidMount() {
    if (this.props.selectedResourceId) {
      this.setResourceData();
    }
  }

  setResourceData = () => {
    const resourceData = this.props.indexedResources[
      this.props.selectedResourceId
    ];
    this.setState({
      resource: {
        name: resourceData.name,
        phone: resourceData.phoneNumber,
        chips: resourceData.tags ? resourceData.tags : []
      }
    });
  };

  getEmptyResource = () => ({
    name: null,
    phone: null,
    chips: []
  });

  getNewResourceModel = () => ({
    name: null,
    phoneNumber: null,
    accountId: localStorage.getItem("accountId"),
    type: resourceConstants.TYPE_PERSON,
    registration: resourceConstants.REG_NEW,
    activity: resourceConstants.ACTIVITY_FREE,
    status: resourceConstants.STATUS_STILL,
    isDemo: false,
    lastPosition: {
      // lat_long:
    },
    config: {
      accelerometer: {
        threshold: resourceConstants.ACCELEROMETER_THRESHOLD
      }
    }
  });

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  closeDeleteConfirmAlert = () => {
    this.setState({ deleteConfirmationOpen: false });
  };

  openDeleteConfirmAlert = () => {
    this.setState({ deleteConfirmationOpen: true });
  };

  onSubmit = model => {
    const { firestore, selectedResourceId, user } = this.props;
    if (selectedResourceId) {
      const resource = this.props.indexedResources[selectedResourceId];
      const fieldsToUpdate = {
        name: model.name,
        phoneNumber: model.phone,
        tags: this.state.resource.chips,
        config:
          resource && resource.config
            ? resource.config
            : this.getNewResourceModel().config
      };

      this.setState({ onSaving: true }, () => {
        firestore
          .update(
            {
              collection: "/resources",
              doc: selectedResourceId
            },
            fieldsToUpdate
          )
          .then(docRef => {
            this.setState(
              {
                onSaving: false,
                onSaved: true
              },
              () => {
                setTimeout(() => {
                  this.setState({ onSaved: false });
                }, 3 * 1000);
              }
            );
          });
      });
    } else {
      const resource = this.getNewResourceModel();
      resource.name = model.name;
      resource.phoneNumber = model.phone;
      resource.tags = this.state.resource.chips;
      resource.language = user.data.userLanguage.substring(0, 2);
      resource.creationDatetime = new Date();

      this.setState({ onSaving: true }, () => {
        firestore.add({ collection: "/resources" }, resource).then(docRef => {
          // this.resetForm();
          this.props.setResource(docRef.id);
          this.createChatRoom(docRef.id);
          this.setState(
            {
              onSaving: false,
              onSaved: true,
              sendInviteConfirmationOpen: true
            },
            () => {
              setTimeout(() => {
                this.setState({ onSaved: false });
              }, 3 * 1000);
            }
          );
        });
      });
    }
  };

  resetForm = () => {
    this.form.reset();
    this.setState({ resource: this.getEmptyResource() });
  };

  sendInvitationRequest = resourceId => {
    var inviteFunction = firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("inviteResource");
    inviteFunction({ resourceId })
      .then(result => {
        console.log("inviteResource_result", result);
      })
      .catch(error =>
        errorReporting(
          this.props.user.uid,
          "ERROR",
          "[ResourceForm] Errore nella chiamata alla function di invito risorsa: " +
            error
        )
      );
  };

  inviteResource = e => {
    e.preventDefault();
    const { firestore, selectedResourceId, user, account } = this.props;
    if (selectedResourceId) {
      const fieldsToUpdate = {
        registration: resourceConstants.REG_TOSENDINVITE,
        invitingWebUser: user.data.displayName
      };

      this.setState({ sendInviteConfirmationOpen: false }, () => {
        if (account && !account.demoDataDeleted) {
          this.props.openDeleteDemoDataPopup();
        }

        firestore
          .update(
            {
              collection: "/resources",
              doc: selectedResourceId
            },
            fieldsToUpdate
          )
          .then(docRef => {
            this.sendInvitationRequest(selectedResourceId);
            this.setState({ onInvited: true }, () => {
              setTimeout(() => {
                this.setState({ onInvited: false });
              }, 3 * 1000);
            });
          });
      });
    }
  };

  createChatRoom = resourceId => {
    const { firestore } = this.props;

    const users = [];
    firestore
      .get({
        collection: "users",
        where: ["accountId", "==", localStorage.getItem("accountId")]
      })
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          users.push(doc.id);
        });

        const chatRoom = {
          resourceId,
          users,
          lastMessage: "",
          lastSender: "",
          lastUpdate: new Date()
        };
        firestore.add(
          { collection: getFirestoreCollection() + "/chatRooms" },
          chatRoom
        );
      });
  };

  deleteResource = e => {
    e.preventDefault();
    const { firestore, selectedResourceId } = this.props;
    if (selectedResourceId) {
      firestore
        .delete({
          collection: "/resources",
          doc: selectedResourceId
        })
        .then(() => {
          this.props.setResource(null);
          this.closeDeleteConfirmAlert();
        });
    }
  };

  handleAddChip = chip => {
    const resource = this.state.resource;
    this.setState({
      resource: {
        ...resource,
        chips: resource.chips.concat(chip)
      }
    });
  };

  onKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  handleDeleteChip = (chip, index) => {
    const resource = this.state.resource;

    this.setState({
      resource: {
        ...resource,
        chips: resource.chips.filter((el, i) => index !== i)
      }
    });
  };

  render() {
    const {
      canSubmit,
      deleteConfirmationOpen,
      sendInviteConfirmationOpen,
      onSaving,
      onSaved,
      onInvited
    } = this.state;
    const { chips, name, phone } = this.state.resource;
    const { classes, selectedResourceId, user } = this.props;

    let canRegister;
    let resource;
    let picture;

    if (selectedResourceId) {
      resource = this.props.indexedResources[selectedResourceId];
      if (resource) {
        canRegister = [
          resourceConstants.REG_NEW,
          resourceConstants.REG_TOSENDINVITE,
          resourceConstants.REG_INVITED
        ].includes(resource.registration);
        picture = resource.picture;
      }
    }

    let userCountry = user.countryCode;
    if (!userCountry) {
      userCountry = "IT"; //fallback
    }

    const headerLabel =
      (resource
        ? stringTranslate("general", "Edit")
        : stringTranslate("general", "New_F")) +
      " " +
      stringTranslate("resources", "Resource").toLowerCase();

    return (
      <React.Fragment>
        <div className={classes.title}>
          <Hidden smUp>
            <IconButton
              className={classes.leftArrow}
              onClick={() => {
                this.props.setResource(null);
                this.props.showResourceForm(false);
              }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h3">{headerLabel}</Typography>
        </div>
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalid={this.disableButton}
          className={classes.formContainer}
          ref={c => (this.form = c)}
          onKeyPress={this.onKeyPress}
        >
          <Grid container spacing={24} alignItems="flex-end">
            <Grid item xs={12} sm={2} container justify="center">
              <Avatar
                src={picture}
                onClick={() => {}}
                className={classes.avatar}
                alt={name ? name[0] : ""}
              >
                <PersonIcon />
              </Avatar>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldFormsy
                fullWidth
                type="text"
                name="name"
                label={stringTranslate("resources", "Name")}
                value={name}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <OMPhoneInputFormsy
                fullWidth
                name="phone"
                label={stringTranslate("resources", "Phone")}
                validations="isNumeric"
                validationError={stringTranslate(
                  "resources",
                  "Phone_validation_error"
                )}
                value={phone}
                country={userCountry}
                required
              />
            </Grid>
            <Grid item xs={12} sm={resource ? 8 : 12}>
              <ChipInput
                fullWidth
                onAdd={chip => this.handleAddChip(chip)}
                onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                label={stringTranslate("resources", "Categories")}
                value={chips}
              />
            </Grid>
            {resource && (
              <Grid item xs={12} sm={4}>
                <TextFieldFormsy
                  id="guidedTour-step17"
                  fullWidth
                  type="text"
                  name="registration"
                  label={stringTranslate("resources", "Registration")}
                  value={stringTranslate("resources", resource.registration)}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            )}
            <Grid item xs>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className="mr-12"
                aria-label={stringTranslate("general", "Save")}
                disabled={!canSubmit}
              >
                {stringTranslate("general", "Save")}
              </Button>
              {canRegister && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.setState({ sendInviteConfirmationOpen: true })
                  }
                  className="mr-12"
                  aria-label={stringTranslate("resources", "Send invitation")}
                  disabled={!canSubmit}
                >
                  {stringTranslate("resources", "Send invitation")}
                </Button>
              )}
              {selectedResourceId && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={this.openDeleteConfirmAlert}
                  className="mr-12"
                  aria-label={stringTranslate("general", "Delete")}
                  disabled={!canSubmit}
                >
                  {stringTranslate("general", "Delete")}
                </Button>
              )}
              {onSaving && stringTranslate("general", "onSaving")}
              {onSaved && stringTranslate("general", "onSaved")}
              {onInvited && stringTranslate("resources", "InviteSuccess")}
            </Grid>
          </Grid>
        </Formsy>
        <OMDeleteConfirmationAlert
          isOpen={deleteConfirmationOpen}
          deleteHandler={this.deleteResource}
          cancelHandler={this.closeDeleteConfirmAlert}
        />
        <OMConfirmationPopup
          isOpen={sendInviteConfirmationOpen}
          confirmHandler={this.inviteResource}
          closeHandler={() =>
            this.setState({ sendInviteConfirmationOpen: false })
          }
          title="Worker App"
          text={stringTranslate("resources", "InviteMsg")}
          confirmActionText={stringTranslate("resources", "Invite")}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  resources: state.firestore.ordered.resources,
  indexedResources: state.firestore.data.resources,
  selectedResourceId: state.resources.resourceList.selectedResource,
  user: state.auth.user,
  account: state.firestore.data.account
});
const mapDispatchToProps = dispatch => ({
  setResource: resourceId => dispatch(setResource(resourceId)),
  showResourceForm: bool => dispatch(showResourceForm(bool)),
  openDeleteDemoDataPopup: () => dispatch(openDeleteDemoDataDialog())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(ResourceForm);
