import config from "./firebaseServiceConfig";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { setFirestoreCollection } from "firestoreAccount";
import store from "store";
import { deviceDetect } from "react-device-detect";

class firebaseService {
  init() {
    if (firebase.apps.length) {
      this.db = firebase.database();
      this.auth = firebase.auth();
      return;
    }
    firebase.initializeApp(config);
    this.db = firebase.database();
    this.auth = firebase.auth();
  }

  getUserData = userId => {
    if (!firebase.apps.length) {
      return;
    }

    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users")
        .doc(userId)
        .get()
        .then(function(doc) {
          var user = doc.data();
          if (user && user.accountId) setFirestoreCollection(user.accountId);
          resolve(user);
        });
    });
  };

  updateUserData = user => {
    if (!firebase.apps.length) {
      return;
    }
    return new Promise((resolve, reject) => {
      setFirestoreCollection(user.accountId);
      firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .set(user);

      // .then(resolve(user));
    });
  };

  onAuthStateChanged = callback => {
    if (!this.auth) {
      return;
    }
    this.auth.onAuthStateChanged(callback);
  };

  signOut = () => {
    if (!this.auth) {
      return;
    }
    this.auth.signOut();
    setFirestoreCollection(null);
    firebase.logout().then(() => {
      store.dispatch({ type: "@@reduxFirestore/CLEAR_DATA" });
    });
  };

  setUserAccess = userId => {
    if (!firebase.apps.length) {
      return;
    }

    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("users/" + userId + "/accessHistory")
        .add({
          datetime: new Date(),
          deviceInfo: deviceDetect()
        })
        .then(function(docRef) {
          resolve(docRef.id);
        });
    });
  };
}

const instance = new firebaseService();

export default instance;
