import { authRoles } from "auth";
import ModulesPage from "./ModulesPage";

export const ModulesConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.staff, //['admin','staff']
  routes: [
    {
      path: "/modules",
      component: ModulesPage
    }
  ]
};

/**
 * Tipi di campi che possono essere utilizzati nei
 * custom forms
 */
export const FieldTypes = {
  text: "TEXT",
  area: "TEXT_AREA",
  radio: "RADIO",
  dropdown: "DROPDOWN",
  check: "CHECK",
  date: "DATE",
  time: "TIME"
};

/**
 * Tipi di campi di default NON selezionabili dall'utente
 * Utilizzati per visualizzare valori nella tabella
 */
export const DefaulFieldTypes = {
  task: "task",
  party: "party",
  createdAt: "createdAt"
};

/**
 * Campi predefiniti. Utilizzati per la creazione
 * della tabella
 */
export const DefaultFieds = [
  {
    name: "createdAt",
    label: "",
    type: DefaulFieldTypes.createdAt,
    required: true,
    position: 0,
    placeholder: "",
    options: []
  }
  // {
  //   name: "taskId",
  //   label: "",
  //   type: DefaulFieldTypes.task,
  //   required: true,
  //   position: 0,
  //   placeholder: "",
  //   options: []
  // },
  // {
  //   name: "partyId",
  //   label: "",
  //   type: DefaulFieldTypes.party,
  //   required: true,
  //   position: 0,
  //   placeholder: "",
  //   options: []
  // }
];

export const generateBaseOption = () => ({
  label: "",
  name: Date.now().toString()
});

export const generateDefaultField = type => {
  const base = {
    label: "",
    // In creazione valore di name è necessario solo per oggetto draggable
    name: Date.now().toString(),
    help: "",
    position: 0,
    required: false,
    type
  };
  switch (type) {
    case FieldTypes.text:
      return {
        ...base
      };
    case FieldTypes.area:
      return {
        ...base
      };
    case FieldTypes.radio:
      return {
        ...base,
        options: [generateBaseOption()]
      };
    case FieldTypes.check:
      return {
        ...base,
        options: [generateBaseOption()]
      };
    case FieldTypes.dropdown:
      return {
        ...base,
        options: [generateBaseOption()]
      };
    case FieldTypes.date:
      return {
        ...base
      };
    case FieldTypes.time:
      return {
        ...base
      };
    default:
      return {
        ...base
      };
  }
};
