import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const styles = theme => ({
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  }
});

const Area = ({ classes, field, value, onChange, error }) => {
  return (
    <TextField
      label={field.label}
      error={error !== undefined && error !== ""}
      helperText={error}
      value={value}
      multiline
      rows="4"
      onChange={e => onChange(field)(e.target.value)}
      placeholder={field.help}
      InputProps={{
        className: classes.inputText
      }}
      InputLabelProps={{
        className: classes.inputTextLabel,
        shrink: true
      }}
      margin="normal"
      required={field.required}
      fullWidth
    />
  );
};

Area.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    position: PropTypes.number.isRequired,
    help: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string
      })
    )
  }).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
};

Area.defaultProps = {
  value: "",
  error: undefined
};

export default withStyles(styles, { withTheme: true })(Area);
