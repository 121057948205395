import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles, CircularProgress, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { compose } from "redux";
import { GiftedChat, Bubble, Send } from "../../../../gifted-chat/GiftedChat";
import firebase, { getFirestoreCollection } from "../../../../firestoreAccount";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import SendIcon from "@material-ui/icons/Send";
import Done from "@material-ui/icons/Done";
import DoneAll from "@material-ui/icons/DoneAll";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import AttachIcon from "@material-ui/icons/AttachFile";
import FileUploader from "react-firebase-file-uploader";
import MD5 from "md5";
import InputChat from "./InputChat";
import { fuseThemesConfig } from "../../../../fuse-configs/fuseThemesConfig";
import { stringTranslate, getMomementLocale } from "languages/OMTranslate";
import { usedChat } from "../../../../store/actions";
import AttachmentViewer from "../taskList/detail/attachments/AttachmentViewer";

const styles = theme => ({
  spinner: {
    paddingTop: "100%",
    paddingLeft: "40%"
  },
  chatTitle: {
    height: "47px",
    minHeight: "47px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.primary.grey3
  },
  rightBubbleNameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: 2,
    paddingRight: 3,
    transform: "scaleY(-1)"
  },
  leftBubbleNameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: 2,
    paddingLeft: 3,
    transform: "scaleY(-1)"
  },
  bubbleName: {
    fontSize: 12,
    color: theme.palette.primary.grey3
  },
  sendIcon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.secondary.main,
    height: 40,
    width: 40,
    borderRadius: 20,
    marginRight: 5,
    marginBottom: 3
  },
  attachmentIcon: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tickView: {
    flexDirection: "row",
    backgroundColor: "transparent",
    marginRight: 10
  }
});

class ChatHistory extends Component {
  constructor(props) {
    super(props);
    this.unsubscribe = null;
    this.state = {
      isUploading: false,
      progress: 0,
      msg: [],
      limit: 50,
      loading: false,
      loadingEarlier: false,
      viewAttachment: false
    };
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  componentDidMount() {
    this.retrieveMessages(this.props.selectedChat);
  }

  retrieveMessages = selectedChat => {
    const collection =
      getFirestoreCollection() +
      "/chatRooms/" +
      selectedChat.id +
      "/chatMessages";

    this.unsubscribe = firebase
      .firestore()
      .collection(collection)
      .orderBy("createdAt", "desc")
      .limit(this.state.limit)
      .onSnapshot(snapshot => {
        const messages = [];
        snapshot.docs.forEach(doc => {
          if (
            doc.data().user._id === selectedChat.resourceId &&
            doc.data().status !== "READ"
          ) {
            doc.ref.update({ status: "READ" });
          }

          messages.push({
            id: doc.id,
            text: doc.data().text,
            createdAt: new Date(doc.data().createdAt.seconds * 1000),
            image: doc.data().image ? doc.data().image : "",
            pdf: doc.data().pdf ? doc.data().pdf : "",
            user: {
              id: doc.data().user._id,
              name: doc.data().user.name,
              avatar: doc.data().user.avatar
            },
            sent:
              doc.data().status === "SENT" ||
              doc.data().status === "RECEIVED" ||
              doc.data().status === "READ",
            received:
              doc.data().status === "RECEIVED" || doc.data().status === "READ",
            read: doc.data().status === "READ"
          });
        });
        this.setState({ msg: messages, loading: false, loadingEarlier: false });
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.selectedChat.id !== this.props.selectedChat.id) {
      if (this.unsubscribe) {
        this.unsubscribe();
        this.setState({ msg: [], loading: true });
        this.retrieveMessages(nextProps.selectedChat);
      }
    }
    return true;
  }

  onSend = (msg, attachment = false, url = "") => {
    const roomId = this.props.selectedChat.id;
    const isImage = attachment === "image";
    const isPdf = attachment === "pdf";

    this.props.usedChat(true);

    firebase
      .firestore()
      .collection(
        getFirestoreCollection() + `/chatRooms/${roomId}/chatMessages`
      )
      .add({
        createdAt: msg[0].createdAt,
        image: isImage ? url : "",
        pdf: isPdf ? url : "",
        status: "SENT",
        text: msg[0].text,
        user: {
          _id: this.props.user.uid,
          avatar: "",
          name: this.props.user.data.displayName,
          type: "WEB"
        }
      })
      .then(docRef => {
        let lastMessage = msg[0].text;
        if (isImage) lastMessage = stringTranslate("chat", "image");
        if (isPdf) lastMessage = stringTranslate("chat", "pdf");

        firebase
          .firestore()
          .collection(getFirestoreCollection() + `/chatRooms`)
          .doc(roomId)
          .update({
            lastUpdate: new Date(),
            lastMessage,
            lastSender: this.props.user.uid
          });
      });
  };

  renderTicks = currentMessage => {
    if (currentMessage.user.id !== this.props.user.uid) {
      return null;
    }
    const { classes } = this.props;
    let icon = null;
    let color = fuseThemesConfig.beach.palette.primary.dark;
    const iconStyle = { fontSize: 16 };
    if (currentMessage.read) {
      icon = <DoneAll style={iconStyle} />;
      color = fuseThemesConfig.beach.palette.secondary.main;
    } else if (currentMessage.received) {
      icon = <DoneAll style={iconStyle} />;
    } else if (currentMessage.sent) {
      icon = <Done style={iconStyle} />;
    }
    if (icon) {
      return (
        <div className={classes.tickView} style={{ color }}>
          {icon}
        </div>
      );
    } else {
      return null;
    }
  };

  renderBubble = props => {
    const { classes } = this.props;
    const timeTextStyle = {
      left: {
        color: "#ffffff",
        fontSize: "12px"
      },
      right: {
        color: "#33475b",
        fontSize: "12px"
      }
    };
    const wrapperStyle = {
      left: {
        backgroundColor: "#0091ae",
        boxShadow:
          "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
      },
      right: {
        backgroundColor: "#ffffff",
        boxShadow:
          "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)"
      }
    };

    if (
      props.isSameUser(props.currentMessage, props.previousMessage) &&
      props.isSameDay(props.currentMessage, props.previousMessage)
    ) {
      return (
        <Bubble
          {...props}
          customTextStyle={{ fontSize: "14px" }}
          timeTextStyle={timeTextStyle}
          wrapperStyle={wrapperStyle}
        />
      );
    }
    return (
      <div>
        <Bubble
          {...props}
          customTextStyle={{ fontSize: "14px" }}
          timeTextStyle={timeTextStyle}
          wrapperStyle={wrapperStyle}
        />
        <div
          className={
            props.currentMessage.user.id === this.props.user.uid
              ? classes.rightBubbleNameContainer
              : classes.leftBubbleNameContainer
          }
        >
          <p className={classes.bubbleName}>{props.currentMessage.user.name}</p>
        </div>
      </div>
    );
  };

  renderSend = props => {
    const { classes } = this.props;
    return (
      <Send
        {...props}
        children={
          <div className={classes.sendIcon}>
            <SendIcon />
          </div>
        }
      />
    );
  };

  handleUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 });
  };

  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = async filename => {
    const fileRef = firebase
      .storage()
      .ref("chatAttachments")
      .child(filename);

    const metadata = await fileRef.getMetadata();
    let fileType = "image";
    if (metadata.contentType === "application/pdf") {
      fileType = "pdf";
    }

    this.setState({ progress: 0, isUploading: false });
    fileRef.getDownloadURL().then(url => {
      this.onSend([{ createdAt: new Date(), text: "" }], fileType, url);
    });
  };

  getSafeFilename = file => {
    // Per uniformare il nome del file a quello caricato da mobile e rendere i file anonimi -> più privacy
    let crypted = MD5(file.name);
    const now = new Date();
    crypted =
      "attach-" +
      crypted.substring(0, 8) +
      "-" +
      crypted.substring(8, 12) +
      "-" +
      crypted.substring(12, 16) +
      "-" +
      crypted.substring(16, 20) +
      "-" +
      crypted.substring(20) +
      "_" +
      now.getTime();
    return crypted;
  };

  renderActions = props => {
    const { classes } = this.props;
    return (
      <label className={classes.attachmentIcon}>
        <AttachIcon />
        <FileUploader
          hidden
          accept="image/*,.pdf"
          name="attachment"
          filename={this.getSafeFilename}
          storageRef={firebase.storage().ref("chatAttachments")}
          onUploadStart={this.handleUploadStart}
          onUploadError={this.handleUploadError}
          onUploadSuccess={this.handleUploadSuccess}
        />
      </label>
    );
  };

  renderInputToolbar = props => {
    return <InputChat {...props} uploading={this.state.isUploading} />;
  };

  onLoadEarlier = () => {
    this.setState(
      { loadingEarlier: true, limit: this.state.limit + 10 },
      () => {
        if (this.unsubscribe) {
          this.unsubscribe();
          this.retrieveMessages(this.props.selectedChat);
        }
      }
    );
  };

  renderCustomView = props => {
    if (props.currentMessage.pdf) {
      return (
        <IconButton
          onClick={() =>
            this.setState({ viewAttachment: props.currentMessage.pdf })
          }
        >
          <InsertDriveFile style={{ fontSize: 65 }} />
        </IconButton>
      );
    }
    return null;
  };

  render() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <div className={classes.spinner}>
          <CircularProgress color="secondary" />
        </div>
      );
    }

    let resourceName = "";
    if (this.props.resources) {
      const resourceId = this.props.selectedChat.resourceId;
      if (this.props.resources[resourceId])
        resourceName = this.props.resources[resourceId].name;
    }

    return (
      <React.Fragment>
        <div className={classes.chatTitle}>
          <IconButton
            style={{ marginTop: 4, marginBottom: 4, marginLeft: 4 }}
            onClick={() => {
              this.props.usedChat(false);
              this.props.selectChatHandler(null);
            }}
          >
            <ArrowBack color="primary" />
          </IconButton>
          <Typography variant="h3" className="px-8 py-4" color="primary">
            {resourceName}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minHeight: 0
          }}
        >
          <GiftedChat
            messages={this.state.msg}
            user={{
              id: this.props.user.uid
            }}
            locale={getMomementLocale()}
            placeholder={stringTranslate("chat", "writeMessage")}
            onSend={messages => this.onSend(messages)}
            renderAvatar={null}
            renderBubble={this.renderBubble}
            renderSend={this.renderSend}
            renderActions={this.renderActions}
            renderInputToolbar={this.renderInputToolbar}
            renderTicks={this.renderTicks}
            renderCustomView={this.renderCustomView}
            loadEarlier={true}
            isLoadingEarlier={this.state.loadingEarlier}
            onLoadEarlier={this.onLoadEarlier}
            loadEarlierProps={{
              label: stringTranslate("chat", "loadMessages")
            }}
          />
        </div>
        {this.state.viewAttachment ? (
          <AttachmentViewer
            attachment={{ type: "PDF", url: this.state.viewAttachment }}
            onClose={() => this.setState({ viewAttachment: false })}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

ChatHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.auth.user,
  resources: state.firestore.data.resources
});

const mapDispatchToProps = dispatch => ({
  usedChat: value => dispatch(usedChat(value))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(styles, { withTheme: true })(ChatHistory));
