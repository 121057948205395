import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { FuseScrollbars, FuseAnimateGroup } from "@fuse";
import { List } from "@material-ui/core";
import CustomFormItem from "./CustomFormItem";

const CustomFormList = ({
  customForms,
  selectedCustomForm,
  onSelectCustomForm
}) => (
  <FuseScrollbars className="overflow-y-auto flex-1">
    <List className="w-full">
      {customForms && customForms.length > 0 && (
        <React.Fragment>
          <FuseAnimateGroup
            enter={{
              animation: "transition.expandIn"
            }}
            className="flex flex-col flex-no-shrink"
          >
            {customForms.map(customForm => {
              return (
                <CustomFormItem
                  key={customForm.id}
                  customForm={customForm}
                  selected={
                    selectedCustomForm &&
                    selectedCustomForm.id === customForm.id
                  }
                  onClick={() => onSelectCustomForm(customForm)}
                />
              );
            })}
          </FuseAnimateGroup>
        </React.Fragment>
      )}
    </List>
  </FuseScrollbars>
);

CustomFormList.propTypes = {
  customForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired
      }).isRequired
    })
  ),
  selectedCustomForm: PropTypes.shape(),
  onSelectCustomForm: PropTypes.func.isRequired
};

CustomFormList.defaultProps = {
  customForms: [],
  selectedCustomForm: undefined
};

export default withStyles({}, { withTheme: true })(CustomFormList);
