import { stringTranslate } from "../../../languages/OMTranslate";
export default [
  {
    target: "#guidedTour-step15",
    disableBeacon: true,
    content: stringTranslate("tour", "step15"),
    placement: "right"
  },
  {
    target: "#guidedTour-step15",
    disableBeacon: true,
    content: stringTranslate("tour", "step16"),
    placement: "right"
  },
  {
    target: "#guidedTour-step17",
    disableBeacon: true,
    content: stringTranslate("tour", "step17"),
    placement: "right"
  }
];
