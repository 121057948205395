import React, { Component } from "react";
import { withStyles, TextField } from "@material-ui/core";
import MainActivitySectionHeader from "../mainActivity/MainActivitySectionHeader";
import { connect } from "react-redux";
import { Ballot } from "@material-ui/icons";
import { stringTranslate } from "languages/OMTranslate";
import { formatDate } from "main/common/dateHelpers";
import * as accountConstants from "main/common/account/accountConstants";
import firebase from "firestoreAccount";
import $ from "jquery";

const styles = theme => ({
  root: {
    order: 2,
    display: "flex",
    flexFlow: "column nowrap",
    padding: theme.spacing.unit * 3,
    maxWidth: theme.measures.formMaxWidth,
    flex: "1 1 auto",
    width: "100%",
    height: "calc(100vh - 48px)",
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      width: "100%",
      height: "auto"
    }
  }
});

class AccountSubscriptionData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extInvoice: 0
    };
  }
  resize = () => this.forceUpdate();

  componentDidUpdate(prevProps, prevState) {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      $("#hContainer").height(window.innerHeight - 48);
    }
    $("#hContainer").height(window.innerHeight - 48);

    if (!prevProps.account && this.props.account) {
      this.calculateEstimateBilling();
    }
  }

  calculateEstimateBilling = () => {
    firebase
      .firestore()
      .collection("listings")
      .where("type", "==", "RESOURCE_UNITARY")
      .where("edition", "==", this.props.account.edition)
      .limit(1)
      .get()
      .then(resourceListingRef => {
        const resourceUnitaryPrice = !resourceListingRef.empty
          ? resourceListingRef.docs[0].get("price")
          : accountConstants.RESOURCE_UNIT_COST;

        firebase
          .firestore()
          .collection("resources")
          .where("accountId", "==", localStorage.getItem("accountId"))
          .get()
          .then(resourceSnapshot => {
            const resourceNumber = resourceSnapshot.size;
            this.setState({
              extInvoice: resourceNumber * resourceUnitaryPrice
            });
          });
      });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    if (this.props.account) {
      this.calculateEstimateBilling();
    }
  }

  render() {
    const { classes, account } = this.props;
    return (
      <div className={classes.root}>
        <MainActivitySectionHeader
          text={stringTranslate("account", "Subscription")}
          icon={Ballot}
        />
        <div>
          <TextField
            id="subscriptionType"
            name="subscriptionType"
            label={stringTranslate("account", "subscriptionType")}
            fullWidth
            value={
              account && account.billing ? account.billing.subscriptionType : ""
            }
            margin="normal"
            variant="outlined"
            InputProps={{ readOnly: true }}
          />
        </div>
        <div>
          <TextField
            id="billingDate"
            name="billingDate"
            label={stringTranslate("account", "billingDate")}
            fullWidth
            value={
              account && account.billing
                ? formatDate(account.billing.billingDate)
                : ""
            }
            margin="normal"
            variant="outlined"
            InputProps={{ readOnly: true }}
          />
        </div>
        <div>
          <TextField
            id="estimatedInvoice"
            name="estimatedInvoice"
            label={stringTranslate("account", "estimatedInvoice")}
            fullWidth
            value={this.state.extInvoice.toFixed(2) + " €"}
            margin="normal"
            variant="outlined"
            InputProps={{ readOnly: true }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  account: state.firestore.data.account,
  user: state.auth.user
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(AccountSubscriptionData)
);
