import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { IconButton } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

const styles = () => ({
  leftArrow: {
    marginRight: "1.0rem"
  }
});

const BackButton = ({ classes, onClick }) => (
  <IconButton className={classes.leftArrow} onClick={onClick}>
    <KeyboardArrowLeftIcon />
  </IconButton>
);

BackButton.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  onClick: PropTypes.func.isRequired
};

BackButton.defaultProps = {
  selected: false
};

export default withStyles(styles)(BackButton);
