import React, { Component } from "react";
import { TextField, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/CheckCircle";
import { stringTranslate } from "languages/OMTranslate";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import firebase, {
  getFirestoreCollection
} from "../../../../../../firestoreAccount";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  inputLabel: {
    fontSize: "18px"
  }
});

class Note extends Component {
  constructor(props) {
    super(props);
    this.state = { text: props.note.note, saving: false, deleting: false };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.note.note !== this.props.note.note) {
      this.setState({ text: this.props.note.note });
    }
  }

  handleChange = event => {
    this.setState({ text: event.target.value });
  };

  onDelete = () => {
    this.setState({ deleting: true });
    const { taskId, note } = this.props;
    if (note.new) {
      this.props.deleteNote(note);
      this.setState({ deleting: false });
    } else {
      firebase
        .firestore()
        .collection(`${getFirestoreCollection()}/tasks/${taskId}/notes`)
        .doc(note.id)
        .delete()
        .then(() => this.setState({ deleting: false }))
        .catch(error => {
          console.log(error);
          this.setState({ deleting: false });
        });
    }
  };

  onSave = () => {
    this.setState({ saving: true }, () => {
      const { taskId, note } = this.props;
      if (note.new) {
        firebase
          .firestore()
          .collection(`${getFirestoreCollection()}/tasks/${taskId}/notes`)
          .add({
            creationDate: firebase.firestore.Timestamp.fromDate(
              note.creationDate
            ),
            note: this.state.text,
            user: note.user
          })
          .then(result => this.setState({ saving: false }))
          .catch(error => {
            console.log(error);
            this.setState({ saving: false });
          });
      } else {
        firebase
          .firestore()
          .collection(`${getFirestoreCollection()}/tasks/${taskId}/notes`)
          .doc(note.id)
          .update({ note: this.state.text })
          .then(result => this.setState({ saving: false }))
          .catch(error => {
            console.log(error);
            this.setState({ saving: false });
          });
      }
    });
  };

  render() {
    const { classes, note, user } = this.props;
    return (
      <div className={classes.container}>
        <TextField
          key={note.id}
          id={"note-" + note.id}
          label={note.user.name}
          multiline
          rows="4"
          rowsMax="4"
          value={this.state.text}
          fullWidth
          onChange={this.handleChange}
          margin="normal"
          variant="outlined"
          helperText={moment(note.creationDate).format(
            stringTranslate("general", "momentDateTimeFormat")
          )}
          InputLabelProps={{
            shrink: true,
            classes: { root: classes.inputLabel }
          }}
        />
        <div className={classes.iconContainer}>
          {this.state.deleting || this.state.saving ? (
            <div style={{ width: "48px" }}>
              <CircularProgress size={30} color="secondary" />
            </div>
          ) : (
            <React.Fragment>
              <IconButton
                aria-label={stringTranslate("general", "Delete")}
                onClick={this.onDelete}
                disabled={this.state.saving || user.uid !== note.user.id}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                aria-label={stringTranslate("general", "Save")}
                onClick={this.onSave}
                disabled={
                  this.state.deleting ||
                  !this.state.text ||
                  this.state.text === note.note ||
                  user.uid !== note.user.id
                }
              >
                <SaveIcon />
              </IconButton>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Note);
