export const customers = {
  "Customers list": "Lista clienti",
  "Company Name": "Nome azienda",
  "New Company": "Nuova azienda",
  "Modify Name": "Modifica nome",
  "Modify Company": "Modifica azienda",
  VAT: "Partita IVA/Codice fiscale",
  Phone: "Telefono",
  Address: "Indirizzo",
  Email: "Email",
  Customer: "Cliente",
  Supplier: "Fornitore",
  Contacts: "Contatti",
  Pois: "Poi",
  Notes: "Note",
  Modules: "Moduli",
  "Module Name": "Nome",
  "Module Created At": "Creato il",
  "New Contact": "Nuovo contatto",
  "Contact Modify": "Modifica contatto",
  "New Note": "Nuova nota",
  "Note Modify": "Modifica nota",
  "Contact Name": "Nome",
  "Contact Phone": "Telefono",
  "Contact Email": "Email",
  "Note Description": "Descrizione",
  "Note Date": "Data",
  "New Poi": "Nuovo POI",
  "Poi Modify": "Modifica POI",
  "Poi Description": "Descrizione",
  "Poi Address": "Indirizzo",
  "Poi Latitude": "Latitudine",
  "Poi Longitude": "Longitudine",
  "Main Address": "Sede principale",
  "Address Type": "Tipo",
  noModules: "Nessun modulo trovato",
  noCustomForms: "Non è stato ancora creato un form",
  newModule: "Nuovo modulo",
  poiCategory: "Categoria POI",
  importFileMessage:
    "È possibile caricare un file Excel (.xls, .xlsx, .csv) per importare un insieme di clienti. Il file deve contenere le seguenti informazioni:",
  mainAddressName: "Nome sede principale",
  mainAddressCoords: "Coordinate sede",
  downloadExampleFile: "Scarica il file di esempio da questo ",
  downloadExampleFile2:
    " e modificalo con i tuoi dati (lascia la prima riga con i nomi delle colonne così com'è), quindi caricalo qui sotto e attendi che venga processato.",
  downloadExampleFile3:
    "Se vuoi inserire la sede principale del cliente e hai bisogno di aiuto per trovare le coordinate, ti consigliamo di leggere questa ",
  googleMapsGuide: "guida di Google Maps.",
  necessaryFields:
    "I campi contrassegnati con * sono obbligatori, se i campi obbligatori non sono presenti allora i dati del cliente non verranno salvati.",
  necessaryFields2:
    "Il nome della sede principale viene scartato se non sono presenti anche le sue coordinate.",
  necessaryFields3:
    "Il valore di Partita IVA/Codice Fiscale deve essere univoco, se esiste già un cliente con la/o stessa/o Partita IVA/Codice Fiscale allora verranno aggiornati i suoi dati.",
  loadingMessage: "Caricamento, attendere...",
  importErrorMessage:
    "Errore importazione file, controlla che sia compilato correttamente e riprova.",
  importSuccessMessage: "Clienti importati con successo",
  numberOfCustomers: "$1 righe su $2 processate correttamente"
};
