import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { getFirestoreCollection } from "../../../firestoreAccount";
import { stringTranslate } from "languages/OMTranslate";
import { toggleNoteModal, setSelectedNote } from "store/actions/customersPage";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { Button, IconButton, InputLabel, Typography } from "@material-ui/core";
import { TextFieldFormsy } from "@fuse";
import Formsy from "formsy-react";

const styles = theme => ({
  root: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    width: "600px",
    height: "400px",
    top: "calc( 50vh - 200px )",
    left: "calc( 50vw - 300px )",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      width: "80%",
      height: "80%",
      top: "calc( 10vh )",
      left: "calc( 10vw )"
    }
  },
  textField: {
    width: "100%"
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px"
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  }
});

class NoteFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description:
        props.selectedNote && props.selectedNote.id
          ? props.selectedNote.description
          : "",
      selectedDate:
        props.selectedNote && props.selectedNote.id
          ? moment.unix(props.selectedNote.date.seconds).toDate()
          : new Date(),
      errorName: false,
      dialogOpen: false,
      canSubmit: false
    };
  }

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };
  handleSubmit() {
    const { firestore } = this.props;
    const { selectedCustomer, selectedNote, toggleNoteModal } = this.props;
    const { description, selectedDate } = this.state;
    var testDate = moment(selectedDate);
    if (description) {
      var note = null;
      if (selectedNote && selectedNote.id) {
        note = firestore
          .collection(getFirestoreCollection() + "/notes")
          .doc(selectedNote.id);
      } else {
        note = firestore.collection(getFirestoreCollection() + "/notes").doc();
      }
      if (note) {
        note.set({
          description: description,
          date: testDate.startOf("day").toDate(),
          partyId: selectedCustomer.id
        });
      }
      toggleNoteModal();
    } else {
      this.setState({
        errorName: true
      });
    }
  }

  handleDelete() {
    const { firestore } = this.props;
    const { selectedNote, setSelectedNote, toggleNoteModal } = this.props;

    if (selectedNote.id) {
      firestore
        .collection(getFirestoreCollection() + "/notes")
        .doc(selectedNote.id)
        .delete()
        .then(() => {
          setSelectedNote(null);
          this.setState({ dialogOpen: false });
          toggleNoteModal();
        });
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { classes, toggleNoteModal, selectedNote } = this.props;
    const { description } = this.state;
    const { selectedDate, canSubmit } = this.state;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <Formsy
            onValidSubmit={this.onSubmit}
            onValid={this.enableButton}
            onInvalid={this.disableButton}
            ref={form => (this.form = form)}
            className={classes.container}
          >
            <Grid container spacing={24}>
              <Grid item xs={12}>
                {selectedNote && selectedNote.id ? (
                  <Typography variant="h3">
                    {stringTranslate("customers", "Note Modify")}
                  </Typography>
                ) : (
                  <Typography variant="h3">
                    {stringTranslate("customers", "New Note")}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} className="mt-12">
                <div className="flex flex-col">
                  <InputLabel required className="form-input-label">
                    {stringTranslate("customers", "Note Date")}
                  </InputLabel>
                  <DatePicker
                    selected={selectedDate}
                    onChange={this.handleDateChange}
                    className="date-picker-input"
                    placeholderText={stringTranslate("customers", "Note Date")}
                    dateFormat={stringTranslate("general", "fsnDateFormat")}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormsy
                  id="description"
                  name="description"
                  label={stringTranslate("customers", "Note Description")}
                  className={classes.textField}
                  value={description}
                  multiline
                  rows="4"
                  onChange={this.handleChange("description")}
                  margin="normal"
                  required
                  InputProps={{
                    className: classes.inputText
                  }}
                  InputLabelProps={{
                    className: classes.inputTextLabel
                  }}
                />
              </Grid>

              <Grid item xs={12} className="mt-12">
                <Button
                  variant="contained"
                  color="secondary"
                  className="mr-12"
                  onClick={() => this.handleSubmit()}
                  disabled={!canSubmit}
                >
                  {stringTranslate("general", "Save")}
                </Button>
                {selectedNote && selectedNote.id ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className="mr-12"
                    onClick={() => this.setState({ dialogOpen: true })}
                  >
                    {stringTranslate("general", "Delete")}
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <IconButton
              className={classes.closeButton}
              onClick={() => {
                setSelectedNote(null);
                toggleNoteModal();
              }}
            >
              <CloseIcon />
            </IconButton>
            <Dialog
              open={this.state.dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {stringTranslate("general", "Delete confirm title")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {stringTranslate("general", "Delete confirm content")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ dialogOpen: false })}
                  color="primary"
                >
                  {stringTranslate("general", "Cancel")}
                </Button>
                <Button
                  onClick={() => this.handleDelete()}
                  color="secondary"
                  autoFocus
                >
                  {stringTranslate("general", "Delete")}
                </Button>
              </DialogActions>
            </Dialog>
          </Formsy>
        </div>
      </React.Fragment>
    );
  }
}

NoteFormModal.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer
});

const mapDispatchToProps = dispatch => ({
  setSelectedNote: note => dispatch(setSelectedNote(note)),
  toggleNoteModal: () => dispatch(toggleNoteModal())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(NoteFormModal);
