import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { getFirestoreCollection } from "../../../firestoreAccount";
import { stringTranslate } from "languages/OMTranslate";
import CustomersList from "./CustomersList";
import CustomerForm from "./CustomerForm";
import NotesTab from "./NotesTab";
import PoisTab from "./PoisTab";
import ContactsTab from "./ContactsTab";
import ModulesTab from "./ModulesTab";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { mapEnvironmentScript } from "main/common/mapEnvironment";
import checkAccountPermission from "main/common/account/accountEditionConfig";
import { Hidden } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const styles = theme => ({
  root: {
    display: "flex",
    height: "calc(100vh - 64px)",
    flexDirection: "row",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      flexFlow: "column nowrap"
    }
  },

  listContainer: {
    display: "flex",
    flexDirection: "column",
    order: "1",
    flex: "0 0 auto",
    width: theme.measures.mainPageShouldersWidth,
    border: "1px solid",
    backgroundColor: theme.palette.primary.background,
    borderColor: theme.palette.primary.border,
    height: "calc(100vh - 48px)",
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      width: "100%"
    }
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    order: "2",
    flex: "1 1 auto",
    height: "calc(100vh - 48px)",
    backgroundColor: theme.palette.primary.white
  },
  form: {
    order: "1",
    flex: "0 0 auto"
  },
  tabContainer: {
    order: "1",
    flex: "0 0 auto",
    borderTop: "1px solid " + theme.palette.divider,
    height: 48,
    backgroundColor: theme.palette.primary.background
  },
  tab: {
    order: "3",
    flex: "0 0 auto"
  },
  expansionPanel: {
    margin: "auto"
  }
});

const ExpansionPanel = withStyles({
  root: {
    borderTop: "1px solid #d4d3d9",
    borderBottom: "1px solid #d4d3d9",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    }
  },
  expanded: {
    margin: "auto"
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 48,
    "&$expanded": {
      backgroundColor: "#f7f7f7",
      minHeight: 48
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(props => <MuiExpansionPanelSummary {...props} />);
ExpansionPanelSummary.muiName = "ExpansionPanelSummary";

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails);

class CustomersPage extends Component {
  state = {
    currentTab: 0,
    customerContacts: null,
    addNewCustomer: false
  };

  handleChangeTab = (event, value) => {
    this.setState({ currentTab: value });
  };

  handleAddNewCustomer = () => {
    this.setState({ addNewCustomer: true });
  };

  handleNullNewCustomer = () => {
    this.setState({ addNewCustomer: false });
  };

  render() {
    const {
      selectedCustomer,
      classes,
      parties,
      allMainAddress,
      mainAddress,
      account
    } = this.props;
    const { currentTab, addNewCustomer } = this.state;

    return (
      <div
        className={classes.root}
        ref={root => {
          this.root = root;
        }}
      >
        <Hidden xsDown={Boolean(selectedCustomer) || addNewCustomer}>
          <div className={classes.listContainer}>
            <CustomersList
              customers={parties}
              allMainAddress={allMainAddress}
              handleChangeTab={this.handleChangeTab}
              handleAddNewCustomer={this.handleAddNewCustomer}
            />
          </div>
        </Hidden>
        <Hidden xsDown={!Boolean(selectedCustomer) && !addNewCustomer}>
          <div className={classes.formContainer}>
            <CustomerForm
              customers={parties}
              className={classes.form}
              setPoiTab={this.handleChangeTab}
              mainAddress={mainAddress ? mainAddress[0] : null}
              handleNullNewCustomer={this.handleNullNewCustomer}
            />
            {selectedCustomer && selectedCustomer.id ? (
              <React.Fragment>
                <Hidden xsDown>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      order: 2,
                      flex: "1 1 auto"
                    }}
                  >
                    <Tabs
                      value={currentTab}
                      onChange={this.handleChangeTab}
                      className={classes.tabContainer}
                    >
                      <Tab label={stringTranslate("customers", "Contacts")} />
                      <Tab label={stringTranslate("customers", "Pois")} />
                      <Tab label={stringTranslate("customers", "Notes")} />
                      {checkAccountPermission(
                        account,
                        "toolbar",
                        "Modules"
                      ) && (
                        <Tab label={stringTranslate("customers", "Modules")} />
                      )}
                    </Tabs>
                    {currentTab === 0 && (
                      <ContactsTab className={classes.tab} />
                    )}
                    {currentTab === 1 && (
                      <PoisTab className={classes.tab} style={{ order: 2 }} />
                    )}
                    {currentTab === 2 && <NotesTab className={classes.tab} />}
                    {checkAccountPermission(account, "toolbar", "Modules") &&
                      currentTab === 3 && (
                        <ModulesTab className={classes.tab} />
                      )}
                  </div>
                </Hidden>
                <Hidden smUp>
                  <div
                    style={{
                      order: 2,
                      flex: "1 1 auto"
                    }}
                  >
                    <ExpansionPanel
                      classes={{ expanded: classes.expansionPanel }}
                    >
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>
                          {stringTranslate("customers", "Contacts")}
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%"
                          }}
                        >
                          <ContactsTab className={classes.tab} hideAdd={true} />
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      classes={{ expanded: classes.expansionPanel }}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        classes={{ expanded: classes.expansionPanelSummary }}
                      >
                        <Typography className={classes.heading}>
                          {stringTranslate("customers", "Pois")}
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%"
                          }}
                        >
                          <PoisTab
                            className={classes.tab}
                            style={{ order: 2 }}
                            hideAdd={true}
                          />
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      classes={{ expanded: classes.expansionPanel }}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        classes={{ expanded: classes.expansionPanelSummary }}
                      >
                        <Typography className={classes.heading}>
                          {stringTranslate("customers", "Notes")}
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%"
                          }}
                        >
                          <NotesTab className={classes.tab} hideAdd={true} />
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    {checkAccountPermission(account, "toolbar", "Modules") && (
                      <ExpansionPanel
                        classes={{ expanded: classes.expansionPanel }}
                      >
                        <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          classes={{ expanded: classes.expansionPanelSummary }}
                        >
                          <Typography className={classes.heading}>
                            {stringTranslate("customers", "Modules")}
                          </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%"
                            }}
                          >
                            <ModulesTab className={classes.tab} />
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    )}
                  </div>
                </Hidden>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </Hidden>
      </div>
    );
  }
}

CustomersPage.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  parties: PropTypes.array
};

const mapStateToProps = state => ({
  account: state.firestore.data.account,
  parties: state.firestore.ordered.parties,
  allMainAddress: state.firestore.ordered.allMainAddress,
  mainAddress: state.firestore.ordered.mainAddress,
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer
});

const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => {
    var collections = [
      {
        collection: getFirestoreCollection() + "/parties",
        storeAs: "parties",
        where: [["isActive", "==", true]]
      }
    ];
    collections.push({
      collection: getFirestoreCollection() + "/pois",
      storeAs: "allMainAddress",
      where: [["isMainAddress", "==", true]]
    });
    const partyId =
      props.selectedCustomer && props.selectedCustomer.id
        ? props.selectedCustomer.id
        : 0;

    if (true) {
      collections.push({
        collection: getFirestoreCollection() + "/pois",
        storeAs: "positions",
        where: [["partyId", "==", partyId]]
      });
      collections.push({
        collection: getFirestoreCollection() + "/pois",
        storeAs: "mainAddress",
        where: [["partyId", "==", partyId], ["isMainAddress", "==", true]]
      });
      collections.push({
        collection: getFirestoreCollection() + "/notes",
        storeAs: "notes",
        where: [["partyId", "==", partyId]]
      });
      collections.push({
        collection: getFirestoreCollection() + "/contacts",
        storeAs: "contacts",
        where: [["partyId", "==", partyId]]
      });
    }
    collections.push(
      {
        collection: `${getFirestoreCollection()}/modules`,
        storeAs: "modules",
        where: [["partyId", "==", partyId]]
      },
      {
        collection: `${getFirestoreCollection()}/customForms`,
        storeAs: "customForms"
      }
    );
    return collections;
  }),
  withStyles(styles, { withTheme: true })
)(mapEnvironmentScript(CustomersPage));
