import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles/index";
import { stringTranslate } from "languages/OMTranslate";
import { compose } from "redux";
import errorReporting from "main/common/errorReporting";

const styles = theme => ({
  root: {
    display: "block",
    position: "absolute",
    top: "0",
    left: "0",
    right: 0,
    bottom: 0,
    backgroundColor: "#e9e9ec"
  },
  center: {
    display: "block",
    width: "100%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)"
  },
  logo: {
    width: "100%",
    margin: "0 auto",
    textAlign: "center"
  },
  logoImg: {
    width: "300px",
    margin: "0 auto",
    filter: "drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2))"
  },
  messageBox: {
    display: "block",
    width: "100%",
    position: "relative",
    minHeight: "100px",
    height: "100px",
    textAlign: "center",
    padding: "20px"
  },
  message: {
    fontSize: "18px",
    textDecoration: "none"
  }
});

class OMErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service

    errorReporting(
      this.props.user.uid,
      "error",
      error + " " + info.componentStack
    );
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      // if (true) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <div className={classes.root}>
            <div className={classes.center}>
              <div className={classes.logo}>
                <a href="/" className={classes.message}>
                  <img
                    className={classes.logoImg}
                    src="assets/images/logos/OverMover.svg"
                    alt="logo"
                  />
                </a>
              </div>
              <div className={classes.messageBox}>
                <a href="/" className={classes.message}>
                  {stringTranslate("general", "reconnectAttemptLinkShort")}
                </a>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(OMErrorBoundary);
