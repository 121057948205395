import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import MainActivityTaskList from "../taskList/MainActivityTaskList";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";

const styles = theme => ({
  root: {
    backgroundColor: theme.panels.backgroundColor,
    borderBottom: theme.panels.border,
    borderTop:
      theme.measures.panelBorderWidth +
      " solid " +
      theme.palette.primary.panelBorder,
    padding: theme.panels.padding,
    order: 4,
    flex: "1 1 auto",
    flexFlow: "column",
    width: "100%",
    display: "flex",
    height: "30%",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      maxHeight: "100%"
    }
  },
  hidden: {
    display: "none"
  }
});

class MainActivityTaskListContainer extends Component {
  render() {
    const { classes, taskListVisibility } = this.props;
    return (
      <div
        id="guidedTour-step9"
        className={classNames(classes.root, { hidden: !taskListVisibility })}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MainActivityTaskList />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

MainActivityTaskListContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  taskListVisibility: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  taskListVisibility: state.mainActivityPage.panelVisibility.taskListVisibility
});

export default connect(mapStateToProps)(
  withStyles(styles, { withTheme: true })(MainActivityTaskListContainer)
);
