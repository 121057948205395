import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

const style = theme => ({
  table: {
    display: "flex",
    flexDirection: "column",
    flex: "0 1 auto",
    padding: "8px"
  },
  rows: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "scroll"
  }
});

const Table = props => {
  return (
    <div className={props.classes.table}>
      <TableHeader columns={props.columns} />
      <div className={props.classes.rows}>
        {props.data.map((row, index) => {
          return <TableRow key={index} columns={props.columns} data={row} />;
        })}
      </div>
    </div>
  );
};
export default withStyles(style, { withTheme: true })(Table);
