import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import { matchRoutes } from "react-router-config";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import _ from "@lodash";

let redirect = false;

const styles = theme => ({
  root: {
    display: "block",
    position: "absolute",
    top: "0",
    left: "0",
    right: 0,
    bottom: 0,
    backgroundColor: "#e9e9ec"
  },
  center: {
    display: "block",
    width: "100%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)"
  },
  logo: {
    width: "100%",
    margin: "0 auto",
    textAlign: "center"
  },
  logoImg: {
    width: "300px",
    margin: "0 auto",
    filter: "drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2))"
  },
  messageBox: {
    display: "block",
    width: "100%",
    position: "relative",
    minHeight: "100px",
    height: "100px",
    textAlign: "center",
    padding: "20px"
  },
  message: {
    fontSize: "14px"
  }
});

class FuseAuthorization extends Component {
  constructor(props) {
    super(props);
    this.checkAuth();
  }

  componentDidMount() {
    if (this.props.account && this.props.account.missingPaymentMethod) {
      this.props.history.push("/payments");
    }
  }

  componentDidUpdate(prevProps) {
    /**
     * If route is changed
     * Update auths
     */
    this.checkAuth();

    if (!prevProps.authRegisterSuccess && this.props.authRegisterSuccess) {
      this.props.history.push({
        pathname: "/pages/accountLoading"
      });
    }
  }

  checkAuth() {
    const matched = matchRoutes(
      this.props.routes,
      this.props.location.pathname
    )[0];
    if (
      this.props.user &&
      this.props.user.userChecked &&
      matched &&
      matched.route.auth &&
      matched.route.auth.length > 0
    ) {
      if (!matched.route.auth.includes(this.props.user.role)) {
        if (this.props.user.role === "guest") {
          this.props.history.push({
            pathname: "/login",
            state: { redirectUrl: this.props.location.pathname }
          });
        } else if (
          this.props.account &&
          this.props.account.missingPaymentMethod
        ) {
          this.props.history.push("/payments");
        } else {
          this.props.history.push({
            pathname: "/"
          });
        }
      } else if (
        this.props.account &&
        this.props.account.missingPaymentMethod &&
        this.props.location.pathname !== "/payments"
      ) {
        this.props.history.push("/payments");
      }
    }
  }

  render() {
    const { children, classes } = this.props;
    if (this.props.user && this.props.user.userChecked) {
      return <React.Fragment>{children}</React.Fragment>;
    } else {
      return (
        <React.Fragment>
          <div className={classes.root}>
            <div className={classes.center}>
              <div className={classes.logo}>
                <img
                  className={classes.logoImg}
                  src="assets/images/logos/OverMover.svg"
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ firestore, auth }) {
  return {
    user: auth.user,
    userChecked: auth.user.userChecked,
    authRegisterSuccess: auth.register.success,
    account: firestore.data.account
  };
}

export default withRouter(
  compose(
    firestoreConnect(props => [
      {
        collection: "accounts",
        doc: localStorage.getItem("accountId"),
        storeAs: "account"
      }
    ]),
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withStyles(styles, { withTheme: true })
  )(FuseAuthorization)
);
