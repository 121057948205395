export const modules = {
  // Table
  edit: "Edit Form",
  task: "Task",
  party: "Customer",
  createdAt: "Created at",
  // Builder
  newModule: "New module",
  modifyModule: "Modify module",
  customFormName: "Form name",
  fieldLabel: "Field name",
  fieldHelp: "Help text (Optional)",
  fieldRequired: "Required",
  fieldOption: "Option",
  addFieldOption: "Add option",
  add: "Add",
  save: "Save",
  copy: "Copy",
  preview: "Preview",
  delete: "Delete",
  undo: "Undo",
  fieldLabelRequired: "Insert field's name",
  fieldOptionLabelRequired: "Insert option's name",
  // Labels tabella
  tableTextLabels: {
    body: {
      noMatch: "No matching modules found",
      toolTip: "Sort"
    },
    toolbar: {
      search: "Search",
      viewColumns: "View Columns",
      filterTable: "Filter Table"
    },
    filter: {
      all: "All",
      title: "FILTERS",
      reset: "RESET"
    },
    viewColumns: {
      title: "Show Columns",
      titleAria: "Show/Hide Table Columns"
    }
  },
  fieldTypes: {
    text: "Text",
    area: "Text area",
    radio: "Single choice",
    check: "Multi choice",
    dropdown: "Single choice - List",
    date: "Date",
    time: "Time"
  },
  customFormSaved: "Form saved",
  customFormCopied: "Form copied",
  moduleSaved: "Module saved",
  errorSaveCustomFrom: "An error accour while saving the form",
  errorDeleteCustomFrom: "An error accour while deleting the form",
  errorSaveModule: "An error accour while saving the module",
  errorDeleteModule: "An error accour while deleting the module",
  errorInvalidFields: "There are one or more invalid fields",
  errorCustomFormNameRequired: "Insert the form's name",
  errorCannotChangeTypeFieldInUse: "Cannot change the type of a field in use",
  errorCannotDeleteCustomFormInUse: "Cannot delete form in use",
  // Module
  errorShowModule: "Uneable to show this module",
  errorCopyCustomFrom: "An error accour while coping the form",
  copyOf: "Copy of",
  fieldRemoved: "Field removed"
};
