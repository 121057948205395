import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withStyles } from "@material-ui/core/styles";
import {
  ListItem,
  ListItemSecondaryAction,
  Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import "moment/min/locales";

import { stringTranslate, getMomementLocale } from "languages/OMTranslate";

const styles = theme => ({
  item: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: 50
  },
  container: {
    display: "flex",
    flexDirection: "row"
  },
  contentInfos: {
    display: "flex",
    flexDirection: "column"
  },
  icon: {
    fontSize: "18px",
    marginTop: "2px",
    marginRight: "16px",
    color: theme.palette.primary.dark
  },
  name: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark,
    lineHeight: "1.75"
  },
  info: {
    fontSize: theme.text.small
  }
});

const Barcode = ({ attachment, onClick, onDelete, classes }) => {
  moment.locale(getMomementLocale());
  const date = moment
    .unix(attachment.createdAt.seconds)
    .format(stringTranslate("general", "momentDateFormat"));
  return (
    <ListItem onClick={onClick} dense button className={classes.item}>
      <div className={classes.container}>
        <FontAwesomeIcon className={classes.icon} icon="qrcode" />
        <div className={classes.contentInfos}>
          <Typography className={classes.name}>
            {stringTranslate("tasks", "scannedCode") + attachment.barcode.data}
          </Typography>
          <Typography color="textSecondary" className={classes.info}>
            {date}
          </Typography>
        </div>
      </div>
      <ListItemSecondaryAction>
        <Tooltip
          title={stringTranslate("general", "Delete")}
          aria-label="Delete"
        >
          <IconButton aria-label="Delete" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

Barcode.propTypes = {
  attachment: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    createdAt: PropTypes.shape({
      seconds: PropTypes.number.isRequired
    }).isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired // Fornito da HOC withStyles
};

export default withStyles(styles)(Barcode);
