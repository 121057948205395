export const SET_SELECTED_CUSTOMER = "[CUSTOMERS] SET_SELECTED_CUSTOMER";
export const SHOW_CONTACT_MODAL = "[CUSTOMERS] SHOW_CONTACT_MODAL";
export const SHOW_NOTE_MODAL = "[CUSTOMERS] SHOW_NOTE_MODAL";
export const SHOW_POI_MODAL = "[CUSTOMERS] SHOW_POI_MODAL";
export const SET_SELECTED_CONTACT = "[CUSTOMERS] SET_SELECTED_CONTACT";
export const SET_SELECTED_NOTE = "[CUSTOMERS] SET_SELECTED_NOTE";
export const SET_SELECTED_POI = "[CUSTOMERS] SET_SELECTED_POI";
export const RESET_ACTION = "[RESET_ACTION] RESET_ACTION";

export const SET_ORPHAN_ID = "[SET_ORPHAN_ID] SET_ORPHAN_ID";
export const SET_ORPHAN_ADDRESS = "[SET_ORPHAN_ID] SET_ORPHAN_ADDRESS";

export function setSelectedCustomer(customer) {
  return {
    type: SET_SELECTED_CUSTOMER,
    customer: customer
  };
}

export function setSelectedContact(contact) {
  return {
    type: SET_SELECTED_CONTACT,
    contact: contact
  };
}

export function setSelectedNote(note) {
  return {
    type: SET_SELECTED_NOTE,
    note: note
  };
}

export function setSelectedPoi(poi) {
  return {
    type: SET_SELECTED_POI,
    poi: poi
  };
}

export function setOrphanPoiId(poiId) {
  return {
    type: SET_ORPHAN_ID,
    poiId: poiId
  };
}

export function setOrphanPoiAddress(address) {
  return {
    type: SET_ORPHAN_ADDRESS,
    address: address
  };
}

export function toggleContactModal() {
  return {
    type: SHOW_CONTACT_MODAL
  };
}

export function toggleNoteModal() {
  return {
    type: SHOW_NOTE_MODAL
  };
}

export function togglePoiModal() {
  return {
    type: SHOW_POI_MODAL
  };
}
