import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { stringTranslate, getMomementLocale } from 'languages/OMTranslate';
import { WrappedVirtualizedTable } from '../common/WrappedVirtualizedTable';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import 'moment/min/locales';

const styles = theme => ({
  root: {
    height: '100%',
    width: '100%',
    order: 3,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      minHeight: '200px'
    }
  },
  contentView: {
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  message: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark,
    lineHeight: '1.75'
  }
});

const ModulesList = ({ modules, customForms, onItemClick, classes }) => {
  moment.locale(getMomementLocale());
  const getMessageView = message => {
    return (
      <div className={classes.contentView}>
        <Typography className={classes.message}>{message}</Typography>
      </div>
    );
  };
  let contentToRender = (
    <WrappedVirtualizedTable
      rowCount={modules.length}
      rowGetter={({ index }) => ({
        name: customForms.find(f => f.id === modules[index].customFormId).name,
        createdAt: modules[index].createdAt
          ? moment
              .unix(modules[index].createdAt.seconds)
              .format(stringTranslate('general', 'momentDateFormat'))
          : ''
      })}
      onRowClick={({ index }) => {
        onItemClick(modules[index]);
      }}
      columns={[
        {
          width: 200,
          flexGrow: 1.1,
          label: stringTranslate('customers', 'Module Name'),
          dataKey: 'name'
        },
        {
          width: 200,
          flexGrow: 1.1,
          label: stringTranslate('customers', 'Module Created At'),
          dataKey: 'createdAt'
        }
      ]}
    />
  );
  if (customForms.length === 0) {
    contentToRender = getMessageView(
      stringTranslate('customers', 'noCustomForms')
    );
  }
  if (customForms.length === 0) {
    contentToRender = getMessageView(stringTranslate('customers', 'noModules'));
  }
  return <Paper className={classes.root}>{contentToRender}</Paper>;
};

ModulesList.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape()),
  customForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

ModulesList.defaultProps = {
  modules: []
};

export default withStyles(styles, { withTheme: true })(ModulesList);
