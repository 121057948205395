import React from "react";
import PropTypes from "prop-types";
import { FuseAnimateGroup } from "@fuse";
import { withStyles } from "@material-ui/core/styles";
import { List } from "@material-ui/core";
import Barcode from "./Barcode";
import Attachment from "./Attachment";

const styles = () => ({
  container: {
    flex: 1
  }
});

const Attachments = ({ attachments, onItemClick, onItemDelete, classes }) => {
  return (
    <div className={classes.container}>
      <List className="w-full">
        <FuseAnimateGroup
          enter={{
            animation: "transition.expandIn"
          }}
          className="flex flex-col flex-no-shrink"
        >
          {attachments.map(attachment => {
            if (attachment.data().type === "BARCODE") {
              return (
                <Barcode
                  key={attachment.id}
                  attachment={{ ...attachment.data() }}
                  onClick={() => onItemClick(attachment)}
                  onDelete={() => onItemDelete(attachment)}
                />
              );
            } else {
              return (
                <Attachment
                  key={attachment.id}
                  attachment={{ ...attachment.data() }}
                  onClick={() => onItemClick(attachment)}
                  onDelete={() => onItemDelete(attachment)}
                />
              );
            }
          })}
        </FuseAnimateGroup>
      </List>
    </div>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.func.isRequired
    })
  ),
  onItemClick: PropTypes.func.isRequired,
  onItemDelete: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired // Fornito da HOC withStyles
};

Attachments.defaultProps = {
  attachments: []
};

export default withStyles(styles)(Attachments);
