import React from "react";
import PropTypes from "prop-types";
import { FuseAnimateGroup } from "@fuse";
import { withStyles } from "@material-ui/core/styles";
import { List } from "@material-ui/core";

import Module from "./Module";

const styles = () => ({
  container: {
    flex: 1
  }
});

const Modules = ({ modules, customForms, onItemClick, classes }) => {
  return (
    <div className={classes.container}>
      <List className="w-full">
        <FuseAnimateGroup
          enter={{
            animation: "transition.expandIn"
          }}
          className="flex flex-col flex-no-shrink"
        >
          {modules.map(module => {
            const customForm = customForms.find(
              cf => cf.id === module.customFormId
            );
            return (
              <Module
                key={module.id}
                module={module}
                customForm={customForm}
                onClick={() => onItemClick(module, customForm)}
              />
            );
          })}
        </FuseAnimateGroup>
      </List>
    </div>
  );
};

Modules.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape()),
  customForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired
      }).isRequired
    }).isRequired
  ).isRequired,
  onItemClick: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired // Fornito da HOC withStyles
};

Modules.defaultProps = {
  modules: []
};

export default withStyles(styles)(Modules);
