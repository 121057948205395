import React from "react";
import Select from "react-select";
import { withStyles, Button, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import { stringTranslate } from "languages/OMTranslate";

const styles = theme => ({
  addButton: {
    marginLeft: theme.spacing.unit,
    boxShadow: theme.addButton.boxShadow
  }
});

const MainActivityResourceListDropdown = ({
  classes,
  theme,
  autocompleteSelectHandler,
  addClickHandler,
  resourceOptions,
  resourceObjSelected
}) => {
  const selectStyles = {
    menu: base => ({
      ...base,
      zIndex: 5
    }),
    singleValue: base => ({
      fontSize: theme.typography.fontSize,
      color: theme.palette.primary.darker
    }),
    placeholder: base => ({
      fontSize: theme.typography.fontSize,
      color: theme.palette.primary.main,
      position: "absolute",
      left: 10
    }),
    option: (base, state) => ({
      ...base,
      fontSize: theme.typography.fontSize,
      backgroundColor: state.isFocused ? theme.palette.primary.grey1 : "white",
      padding: "5px",
      color: state.isSelected ? theme.palette.primary.darker : "inherit"
    }),
    control: (base, state) => ({
      ...base,
      "&:hover": {
        borderColor: theme.palette.primary.main
      },
      borderRadius: ".4rem",
      boxShadow: state.isFocused
        ? "0 0 0 1px " + theme.palette.primary.main
        : null,
      border: theme.panels.border
    })
  };

  return (
    <div className="flex px-6 items-center" style={{ paddingTop: "10px" }}>
      <Select
        value={resourceObjSelected}
        onChange={autocompleteSelectHandler}
        options={resourceOptions}
        placeholder={stringTranslate("general", "All resources")}
        styles={selectStyles}
        className="flex-1"
        // isClearable
      />
      <Tooltip title={stringTranslate("general", "Add")}>
        <Button
          id="guidedTour-step14"
          variant="fab"
          color="secondary"
          mini
          onClick={addClickHandler}
          className={classes.addButton}
          aria-label={stringTranslate("general", "Add")}
        >
          <AddIcon />
        </Button>
      </Tooltip>
    </div>
  );
};

MainActivityResourceListDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  resourceObjSelected: PropTypes.object,
  resourceOptions: PropTypes.array.isRequired,
  autocompleteSelectHandler: PropTypes.func.isRequired,
  addClickHandler: PropTypes.func.isRequired
};

export default withStyles(styles, { withTheme: true })(
  MainActivityResourceListDropdown
);
