import { authRoles } from "auth";
import TimeTrackingPage from "./TimeTrackingPage";

const TimeTrackingConfig = {
  settings: {
    layout: {
      config: {}
    }
  },
  auth: authRoles.staff, //['admin','staff']
  routes: [
    {
      path: "/timeTracking",
      component: TimeTrackingPage
    }
  ]
};
export default TimeTrackingConfig;
