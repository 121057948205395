import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import ResourceList from "./list/ResourceList";

const styles = theme => ({
  root: {
    order: 2,
    height: "100%",
    flex: "0 1 auto",
    width: theme.measures.mainPageShouldersWidth,
    border: "1px solid",
    backgroundColor: theme.palette.primary.background,
    borderColor: theme.palette.primary.border,
    padding: 0,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      width: "100%"
    }
  },
  hidden: {
    display: "none"
  }
});

class ResourceListContainer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {" "}
        <ResourceList />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ResourceListContainer);
