import firebase from "../../../../../../firestoreAccount";

/**
 * Verifica se il mime type è relativo a una immagine
 * @param {String} mimeType tipo mime del file es: image/jpeg
 * @return {Bool} true se mime di una immagine
 */
const isMimeTypeImage = mimeType =>
  mimeType.split("/").length > 0 && mimeType.split("/")[0] === "image";

/**
 * Verifica se il mime type è relativo a un file pdf
 * @param {String} mimeType tipo mime del file es: application/pdf
 * @return {Bool} true se mime di un pdf
 */
const isMimeTypePdf = mimeType => mimeType === "application/pdf";

/**
 * Verifica se il mime type è relativo a un file valido supportato
 * @param {String} mimeType tipo mime del file es: application/pdf
 * @return {Bool} true se mime di un file supportato
 */
export const validFileType = mimeType =>
  isMimeTypeImage(mimeType) || isMimeTypePdf(mimeType);

/**
 * Verifica che un la dimensione del file insia inferiore
 * alla dimensione massima consentita. Max: 10MB
 * @param {Number} size dimensione del file
 * @returns {Bool} true se dimensione inferiore alla dimensione massima consentita
 */
export const validSize = size => size < 1024 * 1024 * 10;

/**
 * Esegue il caricamento di un file nello storageg firestore
 * @param {File} file oggetto file
 * @param {String} bucket nome del bucket in cui salvare il file
 * @return {Function} funzione nella quale è possibile indicare le funzioni di callback;
 * questa ritorna un oggetto task con il quale è possibile interrompere l'operazione di
 * upload
 */
export const uploadFile = (file, bucket) => (
  onSnapshotChange = snapshot => {},
  onError = error => {},
  onSuccess = downloadUrl => {}
) => {
  const { name } = file;
  const millis = new Date().getTime();
  const ext = name.substring(name.lastIndexOf("."));
  const withoutExt = name.substring(0, name.lastIndexOf("."));
  const fileName = `${withoutExt}_${millis}${ext}`;
  const fileRef = firebase
    .storage()
    .ref(bucket)
    .child(fileName);
  return fileRef
    .put(file)
    .on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      onSnapshotChange,
      onError,
      async () => {
        const downloadUrl = await fileRef.getDownloadURL();
        onSuccess(downloadUrl);
      }
    );
};

/**
 * Esegue la cancellazione di un file
 * @param {String} fileUrl url del file da cancellare
 */
export const deleteFileAsync = async fileUrl =>
  firebase
    .storage()
    .refFromURL(fileUrl)
    .delete();

/**
 * Elabora la dimensione del file calcolando valore e unità di misura
 * @param {Number} size dimensione del file in byte
 * @return {{ size: Number, unit: String }} oggetto con le informazioni sulla dimensione
 */
export const processFileSize = size => {
  // File maggiore di un mega
  const byte = 1024;
  const mega = byte * byte;
  if (size > mega) {
    return { size: (size / mega).toFixed(2), unit: "MB" };
  }
  if (size > byte) {
    return { size: (size / byte).toFixed(2), unit: "KB" };
  }
  return { size, unit: "Byte" };
};

/**
 * Dato il mime de file ritorna il relativo tipo di immagine
 * @see Globals.const
 * @param {String} mimeType tipo mime del file es: image/jpeg
 * @returns {String} tipd di file
 */
export const fileType = mimeType => {
  if (isMimeTypePdf(mimeType)) {
    return "PDF";
  }
  if (isMimeTypeImage(mimeType)) {
    return "IMAGE";
  }
  return "";
};
