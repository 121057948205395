import React, { Component } from "react";
import ResourceForm from "./form/ResourceForm";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    order: 1,
    flex: "0 0 auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    // height: "50px",
    backgroundColor: theme.panels.backgroundColor,
    padding: theme.spacing.unit * 3,
    maxWidth: theme.measures.formMaxWidth
  }
});

class ResourceFormContainer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} id="guidedTour-step15">
        <ResourceForm />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ResourceFormContainer);
