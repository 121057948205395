import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { dictionary_it } from "./it-IT";
import { dictionary_en } from "./en-US";
import { registerLocale, setDefaultLocale } from "react-datepicker/lib";
import store from "store";

export function getLanguageLabel(country) {
  var label = null;
  switch (country) {
    case "en-US":
    case "en":
      label = "English";
      break;
    case "it-IT":
    case "it":
      label = "Italiano";
      break;
    default:
      label = "English";
      break;
  }
  return label;
}

export function getLanguageIcon(country) {
  var icon = null;
  switch (country) {
    case "en-US":
    case "en":
      icon = "assets/flags/gb.svg";
      break;
    case "it-IT":
    case "it":
      icon = "assets/flags/it.svg";
      break;
    default:
      icon = "assets/flags/gb.svg";
      break;
  }
  return icon;
}

export function getCurrentLanguageIcon() {
  var icon = null;
  if (store.getState().auth && store.getState().auth.user.data.userLanguage) {
    icon = getLanguageIcon(store.getState().auth.user.data.userLanguage);
  }
  return icon;
}

export function getMomementLocale() {
  var dictionary = "en";

  if (store.getState().auth && store.getState().auth.user.data.userLanguage) {
    switch (store.getState().auth.user.data.userLanguage) {
      case "it-IT":
        dictionary = "it";
        break;
      case "en-US":
      default:
        dictionary = "en";
        break;
    }
  }
  return dictionary;
}

export function setDateFsnGlobalLocale(userLanguage) {
  let localeLabel;
  let dateFsnLocale;
  switch (userLanguage) {
    case "it-IT":
      localeLabel = "it";
      dateFsnLocale = require("date-fns/locale/it");
      break;
    case "en-US":
    default:
      localeLabel = "en-US";
      dateFsnLocale = require("date-fns/locale/en-US");
      break;
  }
  registerLocale(localeLabel, dateFsnLocale);
  setDefaultLocale(localeLabel);
}

export function getContactLink() {
  var url = "";
  switch (getMomementLocale()) {
    case "it":
      url = "https://www.overmover.com/it/contatti/";
      break;
    default:
      url = "https://www.overmover.com/contacts";
      break;
  }
  return url;
}

export function getPolicyLink() {
  var url = "";
  switch (getMomementLocale()) {
    case "it":
      url = "https://www.overmover.com/it/terms-and-conditions-it/";
      break;
    default:
      url = "https://www.overmover.com/en/terms-and-conditions/";
      break;
  }
  return url;
}

export function stringTranslate(context, string) {
  var dictionary = null;

  if (store.getState().auth && store.getState().auth.user.data.userLanguage) {
    switch (store.getState().auth.user.data.userLanguage) {
      case "en-US":
        dictionary = dictionary_en;
        break;
      case "it-IT":
        dictionary = dictionary_it;
        break;
      default:
        dictionary = dictionary_en;
        break;
    }
  }
  var baseDictionary = dictionary_en;
  if (dictionary[context][string]) return dictionary[context][string];
  else return baseDictionary[context][string];
}

class OMTranslate extends Component {
  render() {
    const { context, string } = this.props;
    return <React.Fragment>{stringTranslate(context, string)}</React.Fragment>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ auth }) {
  return {
    user: auth.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OMTranslate);
