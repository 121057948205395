import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Select, MenuItem, Typography } from "@material-ui/core";

import InputSelect from "./InputSelect";

const styles = () => ({
  select: {
    width: "100%"
  },
  name: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1.4rem",
    fontWeight: "400",
    lineHeight: "1.5"
  }
});

const SelectCustomForm = ({
  classes,
  selected,
  customForms,
  onChange,
  disabled
}) =>
  disabled ? (
    <Typography className={classes.name}>{selected.name}</Typography>
  ) : (
    <Select
      value={selected.id}
      onChange={e => {
        const { value } = e.target;
        if (value !== selected) {
          onChange(customForms.find(f => f.id === value));
        }
      }}
      className={classes.select}
      input={<InputSelect />}
    >
      {customForms.map(f => {
        return (
          <MenuItem key={f.id} value={f.id}>
            <Typography>{f.name}</Typography>
          </MenuItem>
        );
      })}
    </Select>
  );

SelectCustomForm.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  customForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
          required: PropTypes.bool.isRequired,
          position: PropTypes.number.isRequired,
          help: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              label: PropTypes.string
            })
          )
        }).isRequired
      ).isRequired
    }).isRequired
  ).isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        position: PropTypes.number.isRequired,
        help: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string
          })
        )
      }).isRequired
    ).isRequired
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

SelectCustomForm.defaultProps = {
  selected: undefined,
  disabled: false
};

export default withStyles(styles)(SelectCustomForm);
