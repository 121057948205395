import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { compose } from "redux";
import Measure from "react-measure";
import { timestampToDbDateTime } from "../../../common/dateHelpers";
import { dragDropItems } from "../../../common/dragDropItems";
import ToAssignTaskChipSimple from "../taskList/ToAssignTaskChipSimple";
import {
  assignTaskToResource,
  setSchedulerDndConfig
} from "../../../../store/actions/mainActivityPage";
import { sortResources } from "../../../common/resources/resourceHelper";
import { taskFormToggle } from "store/actions/mainActivityPage";

import Scheduler, {
  SchedulerData,
  ViewTypes,
  DnDSource
  // } from "react-big-scheduler";
} from "../../../../big_sched/src";
import "react-big-scheduler/lib/css/style.css";
import moment from "moment";
import { getTaskColor } from "./getTaskColor";
import "moment/locale/it";
import { firestoreConnect } from "react-redux-firebase";
import { getFirestoreCollection } from "../../../../firestoreAccount";

import { stringTranslate } from "languages/OMTranslate";
import { CalendarToday } from "@material-ui/icons";
import TaskDetail from "../taskList/detail/TaskDetail";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";

import Radio from "antd/lib/radio";
const RadioButton = Radio.Button;

const styles = theme => ({
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    width: "100%"
  },
  schedulerContainer: {
    order: 1,
    flex: "1 1 auto"
  }
});

class MainActivityScheduler extends React.Component {
  state = {
    dimensions: {
      width: -1,
      height: -1
    },
    schedulerResources: [],
    schedulerTask: [],
    taskToModifyId: null,
    showTaskForm: false
  };
  constructor(props) {
    super(props);
    let schedulerData = new SchedulerData();
    schedulerData.setViewType(ViewTypes.Day);
    schedulerData.config.nonAgendaDayCellHeaderFormat = "HH:mm";
    schedulerData.config.creatable = false;
    // moment.locale("it");
    // schedulerData.setLocaleMoment(moment);

    this.state = {
      ...this.state,
      viewModel: schedulerData,
      taskDndSource: new DnDSource(
        props => props.task,
        ToAssignTaskChipSimple,
        dragDropItems.TO_ASSIGN_TASK
      )
    };
  }

  resize = () => this.forceUpdate();

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.props.setSchedulerDndConfig({
      taskDndSource: this.state.taskDndSource,
      newEvent: this.newEvent,
      schedulerData: this.state.viewModel
    });
  }

  buildEventFromTask(task) {
    return {
      id: task.id,
      resourceId: task.resourceId,
      start: timestampToDbDateTime(task.beginning),
      end: timestampToDbDateTime(task.ending),
      title: task.description,
      bgColor: getTaskColor(task)
    };
  }

  render() {
    const {
      resources,
      visibleResources,
      searchedResources,
      selectedResource,
      tasks,
      theme
    } = this.props;
    const { viewModel, taskDndSource, dimensions } = this.state;

    viewModel.setSchedulerMaxHeight(dimensions.height - 48); //46px di header
    if (dimensions.width > 0 && dimensions.width < 600)
      viewModel.setSchedulerMaxHeight(dimensions.height - 90); //78px di header di due right
    viewModel.config.views = [
      {
        viewName: stringTranslate("mainactivity", "Day"),
        viewType: ViewTypes.Day,
        showAgenda: false,
        isEventPerspective: false
      },
      {
        viewName: stringTranslate("mainactivity", "Week"),
        viewType: ViewTypes.Week,
        showAgenda: false,
        isEventPerspective: false
      },
      {
        viewName: stringTranslate("mainactivity", "Month"),
        viewType: ViewTypes.Month,
        showAgenda: false,
        isEventPerspective: false
      }
    ];
    viewModel.config.resourceName = stringTranslate(
      "mainactivity",
      "Resources"
    );
    viewModel.config.nonWorkingTimeBodyBgColor =
      theme.palette.primary.background;
    viewModel.config.nonWorkingTimeHeadBgColor =
      theme.palette.primary.background;
    viewModel.config.calendarPopoverEnabled = false;

    if (resources && tasks) {
      let tmp = resources.filter(resource => {
        if (selectedResource) {
          if (resource.id === selectedResource) return true;
          else return false;
        } else {
          if (visibleResources.length === 0 || !searchedResources) return true;
          var found = false;
          var found2 = false;
          visibleResources.forEach(r1 => {
            if (r1.id === resource.id) found = true;
          });

          searchedResources.forEach(r1 => {
            if (r1.id === resource.id) found2 = true;
          });
          return found && found2;
        }
      });
      var schedulerResources = [];
      tmp.forEach(resource => {
        var res = resource.name.split(" ");
        var avatarString = "";
        res.forEach(element => {
          avatarString = avatarString + element.charAt(0);
        });

        var tmp = {
          id: resource.id,
          name: resource.name,
          avatarPicture: resource.picture,
          avatarString: avatarString
        };
        schedulerResources.push(tmp);
      });

      let tmp2 = tasks.filter(task => {
        if (!task.resourceId) return false;
        if (visibleResources.length === 0) return true;
        var found = false;
        visibleResources.forEach(r1 => {
          if (r1.id === task.resourceId) found = true;
        });
        return found;
      });
      var schedulerTasks = [];

      tmp2.forEach(task => {
        if (task.beginning && task.ending) {
          const tmp = this.buildEventFromTask(task);
          schedulerTasks.push(tmp);
        }
      });
    }

    if (schedulerResources && schedulerResources.length > 0) {
      viewModel.setResources(sortResources(schedulerResources));
      if (schedulerTasks && schedulerTasks.length > 0) {
        viewModel.setEvents(schedulerTasks);
      }
    }
    viewModel.config.dayResourceTableWidth = 200;
    viewModel.config.weekResourceTableWidth = 200;
    viewModel.config.monthResourceTableWidth = 200;

    if (dimensions.width > 0) {
      viewModel.config.weekCellWidth = Math.round(
        (dimensions.width - 200 - 16) / 7
      );
      viewModel.config.dayCellWidth = Math.round(
        (dimensions.width - 200 - 16) / 28
      );
    }

    let dndSources = [taskDndSource];

    const { classes } = this.props;

    let leftCustomHeader = (
      <div className="px-6 py-6" style={{ display: "flex", flex: "0 0 auto" }}>
        <CalendarToday color="primary" />
        <Typography variant="h3" className="px-8 py-4" color="primary">
          {stringTranslate("mainactivity", "Scheduler")}
        </Typography>
      </div>
    );

    let todayButton = (
      <RadioButton onClick={() => this.goToToday(viewModel)}>
        <span style={{ margin: "0px 8px", fontWeight: 300 }}>
          {stringTranslate("general", "Today")}
        </span>
      </RadioButton>
    );

    var showAvatar = false;
    var width = window.innerWidth;

    if (
      window.innerWidth <
      this.props.theme.breakpoints.values[
        this.props.theme.measures.nextBreakpoint
      ]
    ) {
      showAvatar = true;
      viewModel.config.dayResourceTableWidth = 60;
      viewModel.config.weekResourceTableWidth = 60;
      viewModel.config.monthResourceTableWidth = 60;
      width = window.innerWidth - 16;
      viewModel.config.nonAgendaSlotMinHeight = 49;
    } else {
      viewModel.config.nonAgendaSlotMinHeight = 0;
      if (this.props.userListVisibility)
        width =
          width -
          parseInt(
            this.props.theme.measures.mainPageShouldersWidth.replace("px", "")
          );
      if (this.props.chatVisibility)
        width =
          width -
          parseInt(
            this.props.theme.measures.chatShouldersWidth.replace("px", "")
          );
      width = width - 48 - 16;
    }
    viewModel.config.schedulerWidth = width;
    viewModel.config.weekCellWidth = Math.max(
      Math.round((width - viewModel.config.weekResourceTableWidth) / 7),
      50
    );
    viewModel.config.dayCellWidth = Math.max(
      Math.round((width - viewModel.config.dayResourceTableWidth) / 28),
      25
    );

    return (
      <Measure
        bounds
        onResize={contentRect => {
          this.setState({ dimensions: contentRect.bounds });
        }}
      >
        {({ measureRef, contentRect }) => {
          return (
            <React.Fragment>
              <div className={classes.root} ref={measureRef}>
                <div className={classes.schedulerContainer}>
                  <Scheduler
                    schedulerResources={schedulerResources}
                    schedulerData={viewModel}
                    prevClick={this.prevClick}
                    nextClick={this.nextClick}
                    onSelectDate={this.onSelectDate}
                    onViewChange={this.onViewChange}
                    eventItemClick={this.eventClicked}
                    updateEventStart={this.updateEventStart}
                    updateEventEnd={this.updateEventEnd}
                    moveEvent={this.moveEvent}
                    newEvent={this.newEvent}
                    dndSources={dndSources}
                    leftCustomHeader={leftCustomHeader}
                    todayButton={todayButton}
                    showAvatar={showAvatar}
                  />
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <TaskDetail
                      debug={true}
                      open={this.state.showTaskForm}
                      onClose={this.handleCloseTaskForm}
                      taskId={this.state.taskToModifyId}
                      detailDataPanelOpen={true}
                      detailDataPanelToggler={this.toggleDetailPanel}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </Measure>
    );
  }

  slotClickedFunc = (schedulerData, slot) => {
    alert(
      `You just clicked a ${
        schedulerData.isEventPerspective ? "task" : "resource"
      }.{id: ${slot.slotId}, name: ${slot.slotName}}`
    );
  };

  handleCloseTaskForm = () => {
    this.setState({
      taskToModifyId: null,
      showTaskForm: false
    });
  };

  toggleDetailPanel = () => {};

  prevClick = schedulerData => {
    schedulerData.prev();
    this.setState({
      viewModel: schedulerData
    });
  };

  nextClick = schedulerData => {
    schedulerData.next();
    this.setState({
      viewModel: schedulerData
    });
  };

  updateEventStart = (schedulerData, event, newStart) => {
    const { firestore } = this.props;
    const updTask = {
      beginning: moment(newStart).toDate()
    };

    firestore.update(
      { collection: getFirestoreCollection() + "/tasks", doc: event.id },
      updTask
    );
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    const { firestore } = this.props;
    const updTask = {
      ending: moment(newEnd).toDate()
    };

    firestore.update(
      { collection: getFirestoreCollection() + "/tasks", doc: event.id },
      updTask
    );
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    this.setState({
      viewModel: schedulerData
    });
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    //fallback nel caso in cui il drop venga eseguito su un altro target (es. Lista risorse)
    if (slotId === undefined) {
      return;
    }

    const { firestore } = this.props;

    //aggiorno scheduler (superfluo poichè si aggiorano i dati su firestore)
    // let newEvent = this.buildEventFromTask(item);
    // newEvent.start = start;
    // newEvent.end = end;
    // newEvent.resourceId = slotId;
    // schedulerData.addEvent(newEvent);
    // this.setState({
    //   viewModel: schedulerData
    // });

    //aggiorno firestore
    const updTask = {
      beginning: moment(start).toDate(),
      ending: moment(end).toDate(),
      resourceId: slotId
    };
    firestore.update(
      { collection: getFirestoreCollection() + "/tasks", doc: item.id },
      updTask
    );
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    const { firestore } = this.props;

    // schedulerData.moveEvent(event, slotId, slotName, start, end);
    // this.setState({
    //   viewModel: schedulerData
    // });

    const updTask = {
      beginning: moment(start).toDate(),
      ending: moment(end).toDate(),
      resourceId: slotId
    };
    firestore.update(
      { collection: getFirestoreCollection() + "/tasks", doc: event.id },
      updTask
    );
  };

  goToToday = schedulerData => {
    schedulerData.setDate();
    this.setState({
      viewModel: schedulerData
    });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);
    this.setState({
      viewModel: schedulerData
    });
  };
  eventClicked = (schedulerData, event) => {
    this.setState({
      showTaskForm: true,
      taskToModifyId: event.id
    });
  };
}

MainActivityScheduler.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  pois: PropTypes.array,
  resources: PropTypes.array,
  parties: PropTypes.array,
  visibleResources: PropTypes.array
};

const mapStateToProps = state => ({
  pois: state.firestore.ordered.pois,
  resources: state.firestore.ordered.resources,
  parties: state.firestore.ordered.parties,
  visibleResources: state.mainActivityPage.activityMap.visibleResources,
  searchedResources: state.mainActivityPage.resourceList.searchedResources,
  selectedResource: state.mainActivityPage.resourceList.selectedResource,
  taskFormVisibility: state.mainActivityPage.panelVisibility.taskFormVisibility,
  userListVisibility: state.mainActivityPage.panelVisibility.userListVisibility,
  chatVisibility: state.mainActivityPage.panelVisibility.chatVisibility,
  user: state.auth.user
});
const mapDispatchToProps = dispatch => ({
  setSchedulerDndConfig: config => dispatch(setSchedulerDndConfig(config)),
  taskFormToggle: () => dispatch(taskFormToggle()),
  assignTaskToResource: (taskId, resourceId) =>
    dispatch(assignTaskToResource(taskId, resourceId))
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(MainActivityScheduler);
