import { withFormsy } from "formsy-react";
import React, { Component } from "react";
import OMPhoneInput from "main/content/common/OMPhoneInput";

class OMPhoneInputFormsy extends Component {
  changeValue = phone => {
    this.props.setValue(phone);
    if (this.props.onChange) {
      this.props.onChange(phone);
    }
  };

  render() {
    const errorMessage = this.props.getErrorMessage();
    const value = this.props.getValue() || "";

    return (
      <OMPhoneInput
        {...this.props}
        changeValueHandler={this.changeValue}
        value={value}
        errorMessage={errorMessage}
      />
    );
  }
}

export default withFormsy(OMPhoneInputFormsy);
