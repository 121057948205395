import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PdfButton = props => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => {
        var firstRow = props.data[0];
        var widths = [];
        firstRow.forEach(element => {
          widths.push("auto");
        });

        var docDefinition = {
          pageSize: "A4",
          pageOrientation: "landscape",
          content: [
            { text: props.title, fontSize: 15 },
            {
              table: {
                headerRows: 1,
                widths: widths,
                body: props.data
              }
            }
          ]
        };

        pdfMake
          .createPdf(docDefinition)
          .download(props.filename ? props.filename : "report.pdf");
      }}
      style={props.style ? props.style : {}}
    >
      Download PDF
      <FontAwesomeIcon
        icon={["fas", "file-pdf"]}
        style={{ color: "#FFFFFF", marginLeft: "4px", fontSize: 20 }}
      />
    </Button>
  );
};
export default PdfButton;
