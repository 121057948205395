import * as Actions from "../../actions/mainActivityPage/index";

const initialState = {
  userListVisibility: true,
  mapVisibility: false,
  calendarVisibility: false,
  taskListVisibility: false,
  taskFormVisibility: false,
  chatVisibility: false
};

const panelVisibility = function(state = initialState, action) {
  switch (action.type) {
    case Actions.HIDE_ALL: {
      return {
        ...state,
        userListVisibility: false,
        mapVisibility: false,
        calendarVisibility: false,
        taskListVisibility: false,
        taskFormVisibility: false,
        chatVisibility: false
      };
    }
    case Actions.TOGGLE_USERLIST: {
      return {
        ...state,
        userListVisibility: !state.userListVisibility
      };
    }
    case Actions.TOGGLE_MAP: {
      return {
        ...state,
        mapVisibility: !state.mapVisibility
      };
    }
    case Actions.TOGGLE_CALENDAR: {
      return {
        ...state,
        calendarVisibility: !state.calendarVisibility
      };
    }
    case Actions.TOGGLE_TASKLIST: {
      return {
        ...state,
        taskListVisibility: !state.taskListVisibility
      };
    }
    case Actions.TOGGLE_TASKFORM: {
      return {
        ...state,
        taskFormVisibility: !state.taskFormVisibility
      };
    }
    case Actions.TOGGLE_CHAT: {
      return {
        ...state,
        chatVisibility: !state.chatVisibility
      };
    }
    case Actions.RESET_ACTION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default panelVisibility;
