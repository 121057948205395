import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";

import { firestoreConnect } from "react-redux-firebase";
import Paper from "@material-ui/core/Paper";
import { stringTranslate } from "languages/OMTranslate";
import { WrappedVirtualizedTable } from "../common/WrappedVirtualizedTable";
import { setSelectedPoi, togglePoiModal } from "store/actions/customersPage";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    height: "100%",
    width: "100%",
    order: 3,
    [theme.breakpoints.down(theme.measures.standardBreakpoint)]: {
      minHeight: "200px"
    }
  }
});

class PoisList extends Component {
  render() {
    const {
      filteredPois,
      setSelectedPoi,
      togglePoiModal,
      classes
    } = this.props;

    return (
      <Paper className={classes.root}>
        {filteredPois && filteredPois.length > 0 ? (
          <WrappedVirtualizedTable
            rowCount={filteredPois.length}
            rowGetter={({ index }) => {
              return filteredPois[index];
            }}
            onRowClick={event => {
              setSelectedPoi(event.rowData);
              togglePoiModal();
            }}
            columns={[
              {
                width: 140,
                flexGrow: 0.0,
                label: stringTranslate("customers", "Address Type"),
                dataKey: "mainAddress"
              },
              {
                width: 100,
                flexGrow: 1.1,
                label: stringTranslate("customers", "Poi name"),
                dataKey: "name"
              },
              {
                width: 300,
                flexGrow: 1.1,
                label: stringTranslate("customers", "Poi Address"),
                dataKey: "address"
              }
            ]}
          />
        ) : (
          <Typography variant="body1" className="m-12">
            {stringTranslate("general", "No elements")}
          </Typography>
        )}
      </Paper>
    );
  }
}

PoisList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  selectedCustomer: state.customersPage.selectedCustomer.selectedCustomer,
  positions: state.firestore.ordered.positions
});

const mapDispatchToProps = dispatch => ({
  setSelectedPoi: poi => dispatch(setSelectedPoi(poi)),
  togglePoiModal: () => dispatch(togglePoiModal())
});

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(PoisList);
