import React from "react";
import { Icon } from "@material-ui/core";
import * as resourceConstants from "./resourceConstants";

const onlineIcon = (
  <Icon className="block text-16 text-green bg-white rounded-full">
    check_circle
  </Icon>
);
const offlineIcon = (
  <Icon className="block text-16 text-grey-dark bg-white rounded-full">
    not_interested
  </Icon>
);
const privacyIcon = (
  <Icon className="block text-16 text-red bg-white rounded-full">
    remove_circle
  </Icon>
);

const StatusIcon = ({ resource }) => {
  if (resource && resource.device && resource.device.deviceStatus) {
    if (
      resource.device.deviceStatus.connection ===
      resourceConstants.PHONE_STATUS_OFFLINE
    ) {
      return offlineIcon;
    } else if (resource.status === resourceConstants.STATUS_PRIVACY) {
      return privacyIcon;
    } else {
      return onlineIcon;
    }
  }

  //fallback
  return offlineIcon;
};

export default StatusIcon;
