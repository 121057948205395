import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const LoadingModal = props => {
  const { isOpen, text } = props;
  return (
    <Dialog open={isOpen}>
      <DialogTitle id="alert-dialog-title">
        <Typography>{text}</Typography>
      </DialogTitle>
      <DialogContent style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
