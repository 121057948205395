import React from "react";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import columnConfig from "./columnConfig";

const style = theme => ({
  iconCell: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
});

const IconCell = props => {
  const { classes } = props;

  return (
    <div
      {...props.dragHandleProps}
      style={{
        width: columnConfig[props.column],
        paddingLeft: "12px",
        paddingRight: "12px",
        ...props.style
      }}
      className={classes.iconCell}
    >
      {props.tooltip ? (
        <Tooltip title={props.tooltip} placement="top-start" enterDelay={300}>
          {props.children}
        </Tooltip>
      ) : (
        props.children
      )}
    </div>
  );
};
export default withStyles(style, { withTheme: true })(IconCell);
