import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  showResource,
  setSearchedResources,
  assignTaskToResource,
  setActiveChat,
  chatToggle,
  setSetResourceListAsUpdated
} from "store/actions/mainActivityPage";
import { showResourceForm } from "store/actions/resources";
import { Person } from "@material-ui/icons";
import { stringTranslate } from "languages/OMTranslate";
import { withRouter } from "react-router-dom";
import {
  splitResourcesByLocalization,
  filterResourcesByString
} from "../../../common/resources/resourceHelper";
import { withStyles, List, Typography, Hidden } from "@material-ui/core";
import { FuseScrollbars, FuseAnimateGroup } from "@fuse";
import MainActivityResourceListItem from "./MainActivityResourceListItem";
import MainActivitySectionHeader from "../MainActivitySectionHeader";
import MainActivityResourceListDropdown from "./MainActivityResourceListDropdown";
import OMSearchAddBar from "../../common/OMSearchAddBar";
import checkAccountPermission from "main/common/account/accountEditionConfig";
import { compose } from "redux";

const styles = theme => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    height: "100%",
    position: "relative"
  },
  fab: {
    position: "absolute",
    right: "5px",
    bottom: "5px",
    zIndex: 50
  }
});

class MainActivityResourceList extends Component {
  state = { searchText: "" };

  handleContactClick = contactId => {
    this.setState({ searchText: "" });
    this.props.showResource(contactId);
    if (!this.props.selectedChat || !this.props.usedChat) {
      const room = this.props.chatRooms.find(el => el.resourceId === contactId);
      if (room) this.props.setActiveChat(room);
      if (!this.props.chatVisibility) this.props.chatToggle();
    }
  };

  handleAutocompleteSelect = objOption => {
    this.setState({ searchText: "" });
    this.props.showResource(objOption.value);
  };

  handleAssignTask = (taskId, resourceId) => {
    this.props.assignTaskToResource(taskId, resourceId);
  };

  openResourceForm = () => {
    this.props.showResourceForm(true);
    this.props.history.push("/resources");
  };

  getFilteredArray = (arr, searchText) => {
    if (searchText.length === 0) {
      return arr;
    }
    return filterResourcesByString(arr, searchText);
  };

  setSearchText = event => {
    const searchText = event.target.value;
    this.setState({ searchText });
    const { visibleResources } = this.props;
    this.props.setSearchedResources(
      this.getFilteredArray(visibleResources, searchText)
    );
  };

  clearText = () => {
    this.setState({ searchText: "" });
    const { visibleResources } = this.props;
    this.props.setSearchedResources(
      this.getFilteredArray(visibleResources, "")
    );
  };

  componentDidUpdate() {
    if (this.props.updateList) {
      this.clearText();
    }
    this.props.setSetResourceListAsUpdated();
  }

  render() {
    const {
      classes,
      visibleResources,
      indexedResources,
      searchedResources
    } = this.props;

    const { selectedContactId } = this.props;
    const { searchText } = this.state;

    let filteredResources = [];
    if (selectedContactId) {
      filteredResources.push({
        ...indexedResources[selectedContactId],
        id: selectedContactId
      });
    } else {
      if (searchText) {
        filteredResources = searchedResources;
      } else {
        filteredResources = visibleResources;
      }
    }

    const sortedResources = filteredResources;
    const sortedResourcesOptions = filteredResources.map(resource => ({
      value: resource.id,
      label: resource.name
    }));
    let selectedResourceAutocompleteObj = null;
    if (selectedContactId) {
      selectedResourceAutocompleteObj = {
        value: selectedContactId,
        label: indexedResources[selectedContactId].name
      };
    }
    const resourcesSplitted = splitResourcesByLocalization(sortedResources);

    return (
      <div className={classes.root}>
        <Hidden xsDown>
          <MainActivitySectionHeader
            icon={Person}
            text={stringTranslate("toolbar", "Resources")}
          />
        </Hidden>
        <Hidden smUp>
          {this.props.userListVisibility ? (
            <MainActivityResourceListDropdown
              autocompleteSelectHandler={this.handleAutocompleteSelect}
              resourceObjSelected={selectedResourceAutocompleteObj}
              resourceOptions={sortedResourcesOptions}
              addClickHandler={this.openResourceForm}
            />
          ) : null}
        </Hidden>
        <Hidden xsDown>
          <OMSearchAddBar
            id="guidedTour-step14"
            disableSearchCondition={Boolean(selectedContactId)}
            searchText={searchText}
            textChangeHandler={this.setSearchText}
            textClearHandler={this.clearText}
            addClickHandler={this.openResourceForm}
            withAdd
          />
          <FuseScrollbars className="overflow-y-auto flex-1">
            <List className="w-full">
              {sortedResources.length > 0 && (
                <React.Fragment>
                  <FuseAnimateGroup
                    enter={{
                      animation: "transition.expandIn"
                    }}
                    className="flex flex-col flex-no-shrink"
                  >
                    {resourcesSplitted.withPosition.map(contact => (
                      <MainActivityResourceListItem
                        key={contact.id}
                        contact={contact}
                        selectedResourceId={selectedContactId}
                        contactClickHandler={this.handleContactClick}
                        assignTaskHandler={this.handleAssignTask}
                        showTags={selectedContactId !== null}
                        account={this.props.account}
                      />
                    ))}
                    {checkAccountPermission(
                      this.props.account,
                      "resourceList",
                      "where"
                    ) &&
                      resourcesSplitted.withoutPosition.length > 0 && (
                        <Typography
                          className="py-4 pl-4"
                          variant="subtitle2"
                          color="primary"
                        >
                          {stringTranslate("resources", "Not_localized")}
                        </Typography>
                      )}
                    {resourcesSplitted.withoutPosition.map(contact => (
                      <MainActivityResourceListItem
                        key={contact.id}
                        contact={contact}
                        selectedResourceId={selectedContactId}
                        contactClickHandler={this.handleContactClick}
                        assignTaskHandler={this.handleAssignTask}
                        showTags={selectedContactId !== null}
                        account={this.props.account}
                      />
                    ))}
                  </FuseAnimateGroup>
                </React.Fragment>
              )}
            </List>
          </FuseScrollbars>
        </Hidden>
      </div>
    );
  }
}

MainActivityResourceList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  selectedContactId: state.mainActivityPage.resourceList.selectedResource,
  visibleResources: state.mainActivityPage.activityMap.visibleResources,
  searchedResources: state.mainActivityPage.resourceList.searchedResources,
  indexedResources: state.firestore.data.resources,
  chatRooms: state.mainActivityPage.chat.chatRooms,
  selectedChat: state.mainActivityPage.chat.selectedChat,
  usedChat: state.mainActivityPage.chat.usedChat,
  chatVisibility: state.mainActivityPage.panelVisibility.chatVisibility,
  updateList: state.mainActivityPage.resourceList.updateList,
  user: state.auth.user,
  account: state.firestore.data.account,
  userListVisibility: state.mainActivityPage.panelVisibility.userListVisibility
});

const mapDispatchToProps = dispatch => ({
  showResource: resourceId => dispatch(showResource(resourceId)),
  showResourceForm: bool => dispatch(showResourceForm(bool)),
  setSearchedResources: resources => dispatch(setSearchedResources(resources)),
  assignTaskToResource: (taskId, resourceId) =>
    dispatch(assignTaskToResource(taskId, resourceId)),
  setActiveChat: room => dispatch(setActiveChat(room)),
  chatToggle: () => dispatch(chatToggle()),
  setSetResourceListAsUpdated: () => dispatch(setSetResourceListAsUpdated())
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(styles, { withTheme: true })(MainActivityResourceList));
