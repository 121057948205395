export const resources = {
  Resource: "Resource",
  Name: "Name",
  Phone: "Phone",
  Phone_validation_error: "Please insert a valid phone number",
  Categories: "Categories",
  "Send invitation": "Send invitation",
  Invite: "Invite",
  Registration: "Registration",
  Not_localized: "Resources without position",
  InviteMsg:
    "New resource added. Send an invite to join OverMover and install the WorkerApp on their smartphone?",
  InviteSuccess: "Resource invited successfully",

  INVITED: "Invited",
  NEW: "To invite",
  VERIFIED: "Verified",
  TOSENDINVITE: "Inviting",

  //attività
  FREE: "Free",
  ENGAGED: "Busy",

  //stato
  STILL: "Still",
  MOVING: "Moving",
  PRIVACY: "Privacy",
  OFFLINE: "Offline"
};
