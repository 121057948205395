import React from "react";
import classNames from "classnames";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import {
  withStyles,
  Avatar,
  Chip,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import StatusIcon from "./StatusIcon";
import PersonIcon from "@material-ui/icons/Person";
import ResourceCaption from "./ResourceCaption";

const styles = theme => ({
  textContainer: {
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  avatarNameContainer: {
    display: "flex",
    alignItems: "center"
  },
  resourceDataContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto"
  },
  resourceListItem: {
    borderBottom: "1px solid " + theme.palette.divider,
    // minHeight: 90,
    "&.active": {
      backgroundColor: theme.palette.background.paper
    },
    "&.target": {
      border: theme.panels.border
    }
  },
  chip: {
    height: "20px",
    margin: theme.spacing.unit / 4
  },
  rightArrow: {
    flex: "0 0 auto"
  }
});

function ResourceListItem({
  contact,
  isOver,
  classes,
  selectedResourceId,
  contactClickHandler,
  showTags,
  account
}) {
  return (
    <ListItem
      button
      disableGutters
      className={classNames(classes.resourceListItem, "pl-6", {
        active: selectedResourceId === contact.id || isOver,
        target: isOver
      })}
      onClick={() => contactClickHandler(contact.id)}
    >
      <div className={classes.resourceDataContainer}>
        <div className={classes.avatarNameContainer}>
          <div className="relative">
            <div className="absolute pin-r pin-b -m-4 z-10">
              <StatusIcon resource={contact} />
            </div>

            <Avatar src={contact.picture} alt={contact.name}>
              <PersonIcon />
            </Avatar>
          </div>
          <div className={classes.textContainer}>
            <ListItemText
              classes={{
                root: "min-w-px pr-0",
                secondary: "truncate"
              }}
              disableTypography
              primary={<Typography>{contact.name}</Typography>}
              secondary={
                <ResourceCaption resource={contact} account={account} />
              }
            />
          </div>
        </div>
        {showTags && (
          <div className="mt-8">
            {contact.tags &&
              contact.tags.map(label => (
                <Chip key={label} label={label} className={classes.chip} />
              ))}
          </div>
        )}
      </div>
      <KeyboardArrowRightIcon className={classes.rightArrow} />
    </ListItem>
  );
}

export default withStyles(styles, { withTheme: true })(ResourceListItem);
