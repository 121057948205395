import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { withStyles } from "@material-ui/core/styles";
import Field from "./Field";

const styles = theme => ({
  container: {
    padding: theme.spacing.unit * 3
  }
});

class Fields extends React.Component {
  onDragEnd = result => {
    const { onMove } = this.props;
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    onMove(source.index, destination.index, draggableId);
  };

  render() {
    const {
      classes,
      fields,
      onAdd,
      onDelete,
      onFieldTypeChange,
      onFieldPropChange,
      onFieldPropOptionDelete,
      onFieldPropOptionChange,
      onFieldPropOptionAdd
    } = this.props;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="tasks">
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={classes.container}
            >
              {fields.map(({ field, errors }, i) => (
                <Field
                  key={field.name}
                  index={i}
                  field={field}
                  errors={errors}
                  onAdd={onAdd}
                  onDelete={onDelete}
                  onFieldTypeChange={onFieldTypeChange}
                  onFieldPropChange={onFieldPropChange}
                  onFieldPropOptionDelete={onFieldPropOptionDelete}
                  onFieldPropOptionChange={onFieldPropOptionChange}
                  onFieldPropOptionAdd={onFieldPropOptionAdd}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

Fields.propTypes = {
  classes: PropTypes.shape().isRequired, // Provided by withStyles
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        position: PropTypes.number.isRequired,
        help: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string
          })
        )
      }).isRequired,
      errors: PropTypes.shape()
    }).isRequired
  ).isRequired,
  onFieldTypeChange: PropTypes.func.isRequired,
  onFieldPropChange: PropTypes.func.isRequired,
  onFieldPropOptionDelete: PropTypes.func.isRequired,
  onFieldPropOptionChange: PropTypes.func.isRequired,
  onFieldPropOptionAdd: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired
};

Fields.defaultProps = {};

export default withStyles(styles, { withTheme: true })(Fields);
