import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from "@material-ui/core";
import { stringTranslate } from "languages/OMTranslate";

const OMDeleteConfirmationAlert = props => {
  return (
    <Dialog
      open={props.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {stringTranslate("general", "Delete confirm title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {stringTranslate("general", "Delete confirm content")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.cancelHandler} color="primary">
          {stringTranslate("general", "Cancel")}
        </Button>
        <Button onClick={props.deleteHandler} color="secondary" autoFocus>
          {stringTranslate("general", "Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OMDeleteConfirmationAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired
};

OMDeleteConfirmationAlert.defaultProps = {
  isOpen: true
};

export default OMDeleteConfirmationAlert;
