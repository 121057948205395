import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { stringTranslate } from "languages/OMTranslate";
import UserCardContent from "./UserCardContent";
import { Create } from "@material-ui/icons";
import { IconButton, Typography } from "@material-ui/core";
import { Card, CardHeader } from "@material-ui/core";

const styles = theme => ({
  root: {},
  actionContainer: {
    display: "flex",
    flexDirection: "column"
  }
});

const UserCard = props => {
  const { classes, user, setModifingUser } = props;
  return (
    <Card className="w-256 mb-16 sm:mb-12 mr-16 sm:mr-12 max-h-full flex flex-col min-w-200 ">
      <CardHeader
        className="pl-12 pr-12"
        title={<Typography variant="body1">{user.data.displayName}</Typography>}
        classes={{
          action: "-mx-12 "
        }}
        subheader={
          <Typography variant="caption">
            {stringTranslate("general", user.role)}
          </Typography>
        }
        action={
          <React.Fragment>
            <div className={classes.actionContainer}>
              <IconButton
                aria-label={stringTranslate("general", "Modify User")}
                component={Link}
                to="#"
                onClick={setModifingUser}
              >
                <Create classes={{ root: "text-18" }} />
              </IconButton>
            </div>
          </React.Fragment>
        }
        subheaderTypographyProps={{
          style: { textTransform: "capitalize" }
        }}
      />
      <UserCardContent user={user} />
    </Card>
  );
};

export default withStyles(styles, { withTheme: true })(UserCard);
