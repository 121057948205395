import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setResource, showResourceForm } from "store/actions/resources";
import OMSearchAddBar from "main/content/common/OMSearchAddBar";
import { withStyles, List } from "@material-ui/core";
import { FuseScrollbars, FuseAnimateGroup } from "@fuse";
import ResourceListItem from "main/common/resources/ResourceListItem";
import { compose } from "redux";
import { filterResourcesByString } from "../../../common/resources/resourceHelper";

const styles = theme => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    height: "100%"
  }
});

class ResourceList extends Component {
  state = {
    searchText: "",
    setSearchedResources: ""
  };

  addNewResource = () => {
    this.props.setResource(null);
    this.props.showResourceForm(true);
  };

  handleContactClick = contactId => {
    this.setState({ searchText: "" });
    this.props.setResource(contactId);
    this.props.showResourceForm(true);
  };

  getFilteredArray = (arr, searchText) => {
    if (searchText.length === 0) {
      return arr;
    }
    return filterResourcesByString(arr, searchText);
  };

  setSearchText = event => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };

  clearText = () => {
    this.setState({ searchText: "" });
  };

  render() {
    const { classes, resources, selectedResourceId } = this.props;
    const { searchText } = this.state;

    let filteredResources = [];
    if (resources) {
      filteredResources = resources;
      if (searchText) {
        filteredResources = this.getFilteredArray(resources, searchText);
      }
    }
    const sortedResources = filteredResources;

    return (
      <div className={classes.root}>
        <OMSearchAddBar
          searchText={searchText}
          textChangeHandler={this.setSearchText}
          textClearHandler={this.clearText}
          addClickHandler={this.addNewResource}
          withAdd
        />
        <FuseScrollbars className="overflow-y-auto flex-1">
          <List className="w-full">
            {sortedResources.length > 0 && (
              <React.Fragment>
                <FuseAnimateGroup
                  enter={{
                    animation: "transition.expandIn"
                  }}
                  className="flex flex-col flex-no-shrink"
                >
                  {sortedResources.map(contact => (
                    <ResourceListItem
                      key={contact.id}
                      contact={contact}
                      selectedResourceId={selectedResourceId}
                      contactClickHandler={this.handleContactClick}
                      showTags
                      account={this.props.account}
                    />
                  ))}
                </FuseAnimateGroup>
              </React.Fragment>
            )}
          </List>
        </FuseScrollbars>
      </div>
    );
  }
}

ResourceList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  resources: state.firestore.ordered.resources,
  selectedResourceId: state.resources.resourceList.selectedResource,
  user: state.auth.user,
  account: state.firestore.data.account
});
const mapDispatchToProps = dispatch => ({
  setResource: resourceId => dispatch(setResource(resourceId)),
  showResourceForm: bool => dispatch(showResourceForm(bool))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withStyles(styles, { withTheme: true })(ResourceList));
