import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { InfoWindow } from "react-google-maps";
import { getResourceMarkerIcon } from "./getMarkerIcon";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { timestampToDateTime } from "../../../common/dateHelpers";
import { stringTranslate } from "languages/OMTranslate";
import { isResourceOffline } from "main/common/resources/resourceHelper";
import $ from "jquery";

const {
  MarkerWithLabel
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const styles = theme => ({
  infoWindowTitle: {
    borderBottom: "1px solid " + theme.palette.primary.border,
    fontWeight: "bold",
    padding: "4px"
  },
  infoWindowText: {
    fontSize: 12,
    padding: "4px"
  },
  infoWindowLinkBox: {
    fontSize: 12,
    padding: "4px",
    cursor: "pointer"
  },
  infoWindowLink: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "row",
    alignItems: "center"
  },
  busy: {
    color: "#be3d56"
  },
  free: {
    color: "#35a897"
  }
});

class ResourceMapMarkerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      openInfoWindow: props.isSelectedResource
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.openInfoWindow) {
      new window.google.maps.Geocoder().geocode(
        {
          location: {
            lat: prevProps.resource.lastPosition.lat_long.latitude,
            lng: prevProps.resource.lastPosition.lat_long.longitude
          }
        },
        (results, status) => {
          if (status === "OK") {
            this.setState({ address: results[0]["formatted_address"] });
          } else {
          }
        }
      );
    }

    if (prevProps.isSelectedResource !== this.props.isSelectedResource) {
      this.setState({ openInfoWindow: this.props.isSelectedResource });
    }

    $("#resource_" + this.props.resource.id)
      .parent()
      .parent()
      .css({ maxHeight: "200px  !important", height: "auto" });
    $("#resource_" + this.props.resource.id)
      .parent()
      .parent()
      .parent()
      .css({ maxHeight: "200px !important", height: "auto" });
  }

  getBatteryIcon(perc) {
    var icon = "battery-empty";
    perc = Math.round(perc * 100);
    if (perc > 10) icon = "battery-quarter";
    if (perc > 35) icon = "battery-half";
    if (perc > 60) icon = "battery-three-quarters";
    if (perc > 80) icon = "battery-full";
    return icon;
  }

  getActivity(activity) {
    var label = "";
    switch (activity) {
      case "ENGAGED":
        label = stringTranslate("mainactivity", "Engaged");
        break;
      default:
        label = stringTranslate("mainactivity", "Free");
        break;
    }
    return label;
  }
  getStatus(status) {
    var label = "";
    switch (status) {
      case "STILL":
        label = stringTranslate("mainactivity", "Still");
        break;
      case "MOVING":
        label = stringTranslate("mainactivity", "Moving");
        break;
      case "OFFLINE":
        label = stringTranslate("mainactivity", "Offline");
        break;
      case "PRIVACY":
        label = stringTranslate("mainactivity", "Privacy");
        break;
      default:
        label = stringTranslate("mainactivity", "N/A");
        break;
    }
    return label;
  }

  onToggleOpen = () => {
    this.setState({ openInfoWindow: !this.state.openInfoWindow });
  };

  zoomTo = () => {
    this.props.changeCenterAndZoom(
      {
        lat: this.props.resource.lastPosition.lat_long.latitude,
        lng: this.props.resource.lastPosition.lat_long.longitude
      },
      18
    );
  };

  goToReport = () => {
    this.props.goToReport(this.props.resource.id);
  };

  render() {
    const resourceIcon = {
      url: getResourceMarkerIcon(this.props.resource),
      scaledSize: new window.google.maps.Size(30, 35)
    };

    var taskDescription = "";
    if (this.props.resource.activity === "ENGAGED") {
      if (this.props.allTasks) {
        const filterTasks = this.props.allTasks.filter(task => {
          if (
            (task.executionStatus === "OG-ENROUTE" ||
              task.executionStatus === "OG-ARRIVED" ||
              task.executionStatus === "OG-EXECUTING") &&
            task.resourceId === this.props.resource.id
          ) {
            return true;
          } else {
            return false;
          }
        });
        if (filterTasks.length > 0) {
          taskDescription =
            stringTranslate("mainactivity", "taskDescription") +
            ": " +
            filterTasks[0].description;
        }
      }
    }

    return (
      <React.Fragment>
        <MarkerWithLabel
          position={{
            lat: this.props.resource.lastPosition.lat_long.latitude,
            lng: this.props.resource.lastPosition.lat_long.longitude
          }}
          icon={resourceIcon}
          className="mycolor"
          onClick={this.onToggleOpen}
          labelAnchor={new window.google.maps.Point(25, 0)}
          labelClass="marker-label"
          labelOrigin={new window.google.maps.Point(75, 32)}
        >
          <React.Fragment>
            <div>{this.props.resource.name}</div>
            {this.state.openInfoWindow && (
              <InfoWindow
                onCloseClick={this.onToggleOpen}
                options={{ pixelOffset: new window.google.maps.Size(0, -36) }}
                position={{
                  lat: this.props.resource.lastPosition.lat_long.latitude,
                  lng: this.props.resource.lastPosition.lat_long.longitude
                }}
              >
                <div
                  className="resourcesInfoWindow"
                  id={"resource_" + this.props.resource.id}
                >
                  <span className="iwstyle">
                    <FontAwesomeIcon
                      icon={[
                        "fas",
                        this.getBatteryIcon(
                          this.props.resource.device.deviceStatus.batteryLevel
                        )
                      ]}
                      className="float-right my-4"
                    />
                    <Typography
                      variant="body1"
                      className={this.props.classes.infoWindowTitle}
                    >
                      {this.props.resource.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={this.props.classes.infoWindowText}
                    >
                      {timestampToDateTime(
                        this.props.resource.lastPosition.gpsTime
                      )}
                      <br />
                      {this.state.address}
                      <br />
                      {isResourceOffline(this.props.resource) ? (
                        <span>{this.getStatus("OFFLINE")}</span>
                      ) : this.props.resource.status === "PRIVACY" ? (
                        <span>
                          {this.getStatus(this.props.resource.status)}
                        </span>
                      ) : this.props.resource.activity === "ENGAGED" ? (
                        <span className={this.props.classes.busy}>
                          {this.getActivity(this.props.resource.activity)}
                          &nbsp;
                          {this.getStatus(this.props.resource.status)}
                          &nbsp;
                          {this.props.resource.status === "MOVING" &&
                          this.props.resource.lastPosition.speed
                            ? stringTranslate("mainactivity", "at") +
                              " " +
                              Math.round(
                                this.props.resource.lastPosition.speed
                              ) +
                              " Km/h"
                            : ""}
                        </span>
                      ) : (
                        <span className={this.props.classes.free}>
                          {this.getActivity(this.props.resource.activity)}
                          &nbsp;
                          {this.getStatus(this.props.resource.status)}
                          &nbsp;
                          {this.props.resource.status === "MOVING" &&
                          this.props.resource.lastPosition.speed
                            ? stringTranslate("mainactivity", "at") +
                              " " +
                              Math.round(
                                this.props.resource.lastPosition.speed
                              ) +
                              " Km/h"
                            : ""}
                        </span>
                      )}
                      <br />
                      {taskDescription}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "flex-end"
                      }}
                    >
                      {this.props.currentZoom === 18 ? null : (
                        <div
                          className={this.props.classes.infoWindowLink}
                          onClick={this.zoomTo}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "search-plus"]}
                            className="mr-4"
                          />
                          <Typography
                            variant="body1"
                            className={this.props.classes.infoWindowLinkBox}
                          >
                            {stringTranslate("mainactivity", "Zoom in")}
                          </Typography>
                        </div>
                      )}
                      <div
                        className={this.props.classes.infoWindowLink}
                        style={{ justifyContent: "flex-end" }}
                        onClick={this.goToReport}
                      >
                        <FontAwesomeIcon
                          icon={["fas", "map-marker-alt"]}
                          className="mr-4"
                        />
                        <Typography
                          variant="body1"
                          className={this.props.classes.infoWindowLinkBox}
                        >
                          {stringTranslate("mainactivity", "Route")}
                        </Typography>
                      </div>
                    </div>
                  </span>
                </div>
              </InfoWindow>
            )}
          </React.Fragment>
        </MarkerWithLabel>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  ResourceMapMarkerComponent
);
