import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Typography, Hidden } from "@material-ui/core";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";

import ActionBar from "../components/ActionBar";
import BackButton from "../components/BackButton";
import ResponsiveButton from "../components/ResponsiveButton";
import ModulesTable from "./ModulesTable";

import { DefaultFieds } from "../ModulesConfig";

import { stringTranslate } from "languages/OMTranslate";
import { fieldCompare } from "../Common";

const styles = () => ({
  title: {
    display: "flex",
    flex: 1,
    alignItems: "center"
  }
});

const Modules = ({
  classes,
  modules,
  customForm,
  onBack,
  onEditForm,
  onCopy,
  onModuleClick
}) => {
  const translatedDefaultFields = DefaultFieds.map(field => ({
    ...field,
    label: stringTranslate("modules", field.type)
  }));
  let orderedFields = undefined;
  if (customForm.fields !== undefined && customForm.fields.length > 0) {
    orderedFields = [
      ...customForm.fields.slice().sort(fieldCompare),
      ...translatedDefaultFields
    ];
  }

  return (
    <>
      <ActionBar>
        <Hidden smUp>
          <BackButton onClick={onBack} />
        </Hidden>
        <Typography className={classes.title} variant="h3">
          {customForm.name}
        </Typography>
        <ResponsiveButton
          onClick={() => onCopy(customForm)}
          icon={FileCopyIcon}
          text={stringTranslate("modules", "copy")}
        />
        <ResponsiveButton
          onClick={() => onEditForm(customForm.fields)}
          icon={EditIcon}
          text={stringTranslate("modules", "edit")}
        />
      </ActionBar>
      {orderedFields !== undefined && orderedFields.length > 0 && (
        <ModulesTable
          fields={orderedFields}
          modules={modules}
          onModuleClick={module => onModuleClick(customForm, module)}
        />
      )}
    </>
  );
};

Modules.propTypes = {
  customForm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.shape({
      seconds: PropTypes.number.isRequired
    }).isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        required: PropTypes.bool.isRequired,
        position: PropTypes.number.isRequired,
        help: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string
          })
        )
      })
    )
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onEditForm: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onModuleClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired, // Provided by withStyle
  modules: PropTypes.arrayOf(PropTypes.shape())
};

Modules.defaultProps = {
  modules: [],
  parties: [],
  tasks: []
};

export default withStyles(styles, { withTheme: true })(Modules);
