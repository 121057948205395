import React, { Component } from "react";
import { connect } from "react-redux";
import * as Actions from "auth/store/actions/index";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles/index";
import { Card, CardContent, Typography } from "@material-ui/core";
import classNames from "classnames";
import { FuseAnimate, FuseScrollbars } from "@fuse";
import FirebaseRegisterTab from "./tabs/FirebaseRegisterTab";
import OMTranslate from "languages/OMTranslate";
import {
  stringTranslate,
  getMomementLocale,
  getContactLink
} from "languages/OMTranslate";
import axios from "axios";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Redirect } from "react-router-dom";
import { getCookie } from "main/common/getCookie";

const styles = theme => ({
  root: {
    backgroundColor: `rgba(238, 238, 238, 0.81) !important;`
  },
  intro: {
    backgroundImage: `url("assets/images/backgrounds/coordinate-workers-team.svg")`,
    backgroundBlendMode: "color",
    backgroundPosition: "bottom 20px right 20px ",
    backgroundRepeat: "no-repeat",
    backgroundSize: "90%",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `none`
    }
  },
  card: {
    width: "100%",
    maxWidth: 500
  },
  payoff: {
    color: theme.palette.primary.dark,
    marginTop: 48,
    fontSize: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginTop: 24
    }
  },
  secondPayoff: {
    color: theme.palette.primary.dark,
    marginTop: 32,
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
      fontSize: "14px"
    }
  },
  thirdPayoff: {
    color: theme.palette.primary.dark,
    marginTop: 8,
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
      fontSize: "14px"
    }
  },
  logoIcon: {
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: 150
    }
  },
  textContainer: {
    backgroundColor: `rgba(255, 255, 255, 0.92) !important;`,
    borderRadius: 3,
    padding: 20,
    maxWidth: 600,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: `transparent  !important;`,
      padding: 0
    }
  }
});

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      showLogin: false,
      hasToRedirect: false,
      titleContent: "",
      messageContent: "",
      secondMessageContent: ""
    };
  }

  componentDidMount() {
    const { firestore } = this.props;

    firestore
      .collection("webapp_settings")
      .where("page", "==", "Register")
      .where("language", "==", getMomementLocale())
      .orderBy("timestamp", "desc")
      .get()
      .then(querySnapshot => {
        if (querySnapshot.docs.length > 0) {
          this.setState({
            titleContent: querySnapshot.docs[0].get("titleContent"),
            messageContent: querySnapshot.docs[0].get("messageContent"),
            secondMessageContent: querySnapshot.docs[0].get(
              "secondMessageContent"
            )
          });
        } else {
          this.setState({
            titleContent: stringTranslate(
              "general",
              "Try OverMover Free for 30 days"
            ),
            messageContent: stringTranslate(
              "general",
              "Manage your workforce: Defines To-Do and deadlines, exchange messages and documents, schedule & allocate tasks to your workers."
            ),
            secondMessageContent: stringTranslate(
              "general",
              "Try it free for 30 days - no credit card required."
            )
          });
        }
      });

    axios.get("https://pro.ip-api.com/json?key=X1rNtpFh8zPg8Rq").then(res => {
      const ipCheck = res.data;
      localStorage.setItem("countryCode", ipCheck.countryCode);
      localStorage.setItem("countryName", ipCheck.country);

      firestore
        .collection("ip_whitelist")
        .where("IP", "==", ipCheck.query)
        .get()
        .then(querySnapshot => {
          if (querySnapshot.docs.length > 0) {
            this.setState({ showLogin: true });
          } else {
            firestore
              .collection("ip_whitelist")
              .get()
              .then(snap => {
                if (snap.size === 0) {
                  this.setState({ showLogin: true });
                } else {
                  firestore
                    .collection("countries_blacklist")
                    .where("country_code", "==", ipCheck.countryCode)
                    .get()
                    .then(querySnapshot => {
                      if (querySnapshot.docs.length > 0) {
                        this.setState({ hasToRedirect: true });
                      } else {
                        this.setState({ showLogin: true });
                      }
                    })
                    .catch(error => {
                      this.setState({ showLogin: true });
                    });
                }
              })
              .catch(error => {
                this.setState({ hasToRedirect: true });
              });
          }
        })
        .catch(error => {
          this.setState({ hasToRedirect: true });
        });
    });
  }
  handleTabChange = (event, value) => {
    this.setState({ tabValue: value });
  };

  form = React.createRef();

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.login.error &&
      (this.props.login.error.firstName ||
        this.props.login.error.secondName ||
        this.props.login.error.password ||
        this.props.login.error.email)
    ) {
      this.form.updateInputsWithError({
        ...this.props.login.error
      });

      this.props.login.error = null;
      this.disableButton();
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    const query = new URLSearchParams(this.props.location.search);

    return (
      <div
        className={classNames(
          classes.root,
          "flex flex-col flex-1 flex-no-shrink p-24 md:flex-row md:p-0"
        )}
      >
        <div
          className={classNames(
            classes.intro,
            "md:flex md:flex-col mx-auto flex-no-grow items-center p-16 text-center md:p-128 md:items-start md:flex-no-shrink md:flex-1 md:text-left"
          )}
        >
          <div className={classes.textContainer}>
            <FuseAnimate animation="transition.expandIn">
              <a href="https://www.overmover.com">
                <img
                  className={classNames(classes.logoIcon)}
                  src="assets/images/logos/OverMover.svg"
                  alt="logo"
                />
              </a>
            </FuseAnimate>

            <FuseAnimate animation="transition.slideUpIn" delay={300}>
              <Typography
                variant="h3"
                className={classNames(classes.payoff, "max-w-512")}
                dangerouslySetInnerHTML={{ __html: this.state.titleContent }}
              />
            </FuseAnimate>

            <FuseAnimate delay={400}>
              <Typography
                variant="h3"
                className={classNames(classes.secondPayoff, "max-w-512")}
                dangerouslySetInnerHTML={{ __html: this.state.messageContent }}
              />
            </FuseAnimate>
            <FuseAnimate delay={400}>
              <Typography
                variant="h3"
                className={classNames(classes.thirdPayoff, "max-w-512")}
                dangerouslySetInnerHTML={{
                  __html: this.state.secondMessageContent
                }}
              />
            </FuseAnimate>
          </div>
        </div>

        {this.state.showLogin ? (
          <FuseAnimate animation={{ translateX: [0, "100%"] }}>
            <Card className={classNames(classes.card, "mx-auto m-16 md:m-0 ")}>
              <FuseScrollbars>
                <CardContent className="flex flex-col items-center justify-center p-32 md:p-68 md:pt-64 ">
                  <Typography variant="h3" className="md:w-full mb-24">
                    <OMTranslate
                      context="register"
                      string="Create An Account"
                    />
                  </Typography>

                  <FirebaseRegisterTab fixedEmail={query.get("email")} />

                  <div className="pt-32">
                    {stringTranslate("register", "Already have an account?") +
                      " "}
                    <Link className="" to="/login">
                      {stringTranslate("login", "Login")}
                    </Link>
                  </div>
                  <div className="pt-8">
                    {stringTranslate("login", "Do you need help?") + " "}
                    <a className="" href={getContactLink()}>
                      {stringTranslate("login", "Contact us")}
                    </a>
                  </div>

                  <div className="flex flex-col items-center" />
                </CardContent>
              </FuseScrollbars>{" "}
            </Card>
          </FuseAnimate>
        ) : this.state.hasToRedirect ? (
          <Redirect to="/pages/errors/not-avaiable" />
        ) : (
          <FuseAnimate animation={{ translateX: [0, "100%"] }}>
            <Card className={classNames(classes.card, "mx-auto m-16 md:m-0")}>
              <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              </CardContent>
            </Card>
          </FuseAnimate>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerWithFirebase: Actions.registerWithFirebase
    },
    dispatch
  );
}

function mapStateToProps({ auth }) {
  return {
    login: auth.login,
    user: auth.user
  };
}

export default compose(
  firestoreConnect(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(Register);
