import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { withFormsy } from "formsy-react";
import React, { Component } from "react";
import { withStyles, InputLabel } from "@material-ui/core";
import _ from "@lodash";
import { getCountryCallingCode } from "libphonenumber-js";

const styles = theme => ({
  input: {
    height: "33px !important",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
    "&:hover": {
      borderBottom: "2px solid " + theme.palette.primary.darker
    },
    "&:focus": {
      borderBottom: "2px solid " + theme.palette.primary.darker + " !important"
    },
    flexBasis: "auto !important"
  },
  label: {
    fontSize: theme.text.small,
    color: theme.palette.primary.dark
  }
});

class PhoneInputFormsy extends Component {
  changeValue = phone => {
    this.props.setValue(phone);
    if (this.props.onChange) {
      this.props.onChange(phone);
    }
  };

  render() {
    const importedProps = _.pick(this.props, [
      "autoComplete",
      "disabled",
      "displayInitialValueAsLocalNumber",
      "country",
      "countries",
      "labels",
      "flagsPath",
      "flags",
      "flagComponent",
      "international",
      "internationalIcon",
      "showCountrySelect",
      "countrySelectTabIndex",
      "countryOptions",
      "style",
      "className",
      "inputClassName",
      "countrySelectComponent",
      "inputComponent",
      "ext",
      "error",
      "locale",
      "metadata",
      "onCountryChange",
      "disablePhoneInput"
    ]);

    const errorMessage = this.props.getErrorMessage();
    let value = this.props.getValue() || "";
    if (!value) {
      value = "+" + getCountryCallingCode(this.props.country);
    }

    return (
      <div className="flex flex-col">
        <InputLabel
          className={this.props.classes.label}
          required={this.props.required}
        >
          {this.props.label}
        </InputLabel>
        <PhoneInput
          {...importedProps}
          placeholder={this.props.placeholder}
          inputClassName={this.props.classes.input}
          onChange={phone => this.changeValue(phone)}
          value={value}
          error={errorMessage}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withFormsy(PhoneInputFormsy)
);
