import * as accountConstants from "./accountConstants";
import firebase from "firestoreAccount";

export function getAccountInitialBilling() {
  const creationTimestamp = Date.now();
  const durationInDays = accountConstants.FREE_PLAN_DURATION_DAYS;

  return {
    subscriptionDate: new Date(creationTimestamp),
    duration: durationInDays,
    billingDate: new Date(
      creationTimestamp + durationInDays * 24 * 60 * 60 * 1000
    ),
    subscriptionType: accountConstants.FREE_PLAN,
    unitPrice: accountConstants.RESOURCE_UNIT_COST
  };
}

export function deleteDemoData(callback) {
  const accountId = localStorage.getItem("accountId");
  const deleteDemoFunction = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("cleanDemoData");
  deleteDemoFunction({ accountId })
    .then(result => {
      firebase
        .firestore()
        .collection("accounts")
        .doc(accountId)
        .update({ demoDataDeleted: true })
        .then(docSnapshot => {
          callback();
        });
    })
    .catch(error => {
      console.log("cleanDemoData_error", error);
      callback();
    });
}
