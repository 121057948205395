import React, { Component } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import Measure from "react-measure";
import { TextFieldFormsy } from "@fuse";
import { firestoreConnect } from "react-redux-firebase";
import { stringTranslate } from "languages/OMTranslate";
import moment from "moment";
import Formsy from "formsy-react";
import { withStyles, Button, Typography, InputLabel } from "@material-ui/core";
import { Room } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import OMReactSelect from "main/content/common/OMReactSelect";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getDirectionMarkerIcon } from "main/content/mainActivity/map/getMarkerIcon";
import { mapEnvironmentScript } from "main/common/mapEnvironment";
import { getDistanceFromLatLonInMeters } from "main/common/positionFilter";
import { WrappedVirtualizedTable } from "../common/WrappedVirtualizedTable";

const styles = theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "calc(100vh - 48px)",
    background: theme.palette.primary.white,
    padding: theme.measures.panelPadding
  },
  titleRow: {
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "row",
    padding: theme.measures.panelPadding
  },
  title: {
    display: "flex",
    flexDirection: "row",
    flex: "0 0 auto",
    borderBottom: theme.panels.border
  },
  filter: {
    padding: theme.measures.panelPadding,
    maxWidth: "1000px",
    marginBottom: theme.measures.panelPadding,
    flex: "0 0 auto"
  },
  formContainer: {
    alignItems: "flex-end"
  },
  debugWindow: {
    flex: "1 1 auto",
    width: "70%",
    height: "100%"
  },
  map: {
    flex: "1 1 auto"
  },
  mapContainer: {
    position: "relative",
    width: "30%",
    height: "100%"
  },
  loadingMap: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: `rgba(255, 255, 255, 0.85) !important;`,
    textAlign: "center",
    display: "flex",
    flexDirection: "column"
  },
  progress: {
    margin: "auto auto"
  },
  inputText: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark
  },
  inputText2: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark,
    paddingTop: "3px"
  },
  inputTextLabel: {
    fontSize: theme.text.medium
  }
});

class FilterTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {
        width: -1,
        height: -1
      },
      selectedDate: new Date(),
      forceResourceId: null,
      forceAccountId: null,
      resourceId: null,
      resourceName: null,
      sourceId: null,
      sourceName: null,
      errorName: false,
      dialogOpen: false,
      positions: [],
      isLoading: false,
      loadedPositions: null
    };
  }

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleChange = name => object => {
    if (object && object.value) {
      this.setState({
        resourceId: object.value,
        resourceName: object.label
      });
    }
  };

  handleChangeSource = name => object => {
    if (object && object.value) {
      this.setState({
        sourceId: object.value,
        sourceName: object.label
      });
    }
  };

  getDistanceFromLatLonInMeters(lat1, lon1, lat2, lon2) {
    var R = 6371005; // Radius of the earth in km
    var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in Meters
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  filterPosition(current_posistion, last_valid_position) {
    if (!current_posistion) return true;
    // if (!current_posistion.speed) return false;

    console.log(current_posistion);
    console.log(last_valid_position);
    const debugLog = true;

    const MIN_DISTANCE = 10; // distanza minima in m
    const MIN_SPEED = 3; // velocita' minima in km/h
    const MIN_PREV_SPEED = 3; // velocita' minima in km/h
    const MIN_TIME_DIFFERENCE = 1; //diffenrenza minima in secondi
    const MAX_SPEED = 350; // velocita' massima in km/h
    const MAX_ACCURACY = 20; //massimo valore di accuracy tollerato

    var distance = getDistanceFromLatLonInMeters(
      current_posistion.lat_long.latitude,
      current_posistion.lat_long.longitude,
      last_valid_position.lat_long.latitude,
      last_valid_position.lat_long.longitude
    );

    console.log(current_posistion.id);
    console.log(last_valid_position.id);

    if (
      current_posistion.accuracy > MAX_ACCURACY ||
      current_posistion.speed < 0
    ) {
      if (debugLog)
        console.log(
          "scartato per accuracy troppo alta o speed negativa " +
            current_posistion.accuracy +
            " - " +
            current_posistion.speed
        );
      return false;
    }

    if (distance < MIN_DISTANCE) {
      if (debugLog)
        console.log("scartato per distanza minima non rispettata: " + distance);
      return false;
    }

    if (
      !current_posistion.accelerometer.moving &&
      !last_valid_position.accelerometer.moving
    ) {
      if (debugLog)
        console.log(
          "scartato per valore moving false: " + current_posistion.speed
        );
      return false;
    }

    console.log(
      "current_posistion.timestamp.seconds " +
        current_posistion.timestamp.seconds
    );
    console.log(
      "last_valid_position.timestamp.seconds " +
        last_valid_position.timestamp.seconds
    );
    var seconds =
      current_posistion.timestamp.seconds -
      last_valid_position.timestamp.seconds;
    if (seconds === 0) return true;
    if (seconds < MIN_TIME_DIFFERENCE) {
      if (debugLog)
        console.log("scartato differenza timestamp in secondi: " + seconds);
      return false;
    }

    var velocity = (distance / seconds) * 3.6;
    console.log("velocita' " + velocity);
    console.log("distance " + distance);
    console.log("seconds " + seconds);

    if (velocity > MAX_SPEED || current_posistion.speed > MAX_SPEED) {
      if (debugLog)
        console.log(
          "scartato velocita' massima non rispettata: " +
            velocity +
            " - " +
            current_posistion.speed
        );
      return false;
    }
    return true;
  }

  onSubmit = model => {
    const { firestore } = this.props;
    const { selectedDate, resourceName } = this.state;
    this.setState({ isLoading: true });

    var testDate = moment(selectedDate);
    console.log(model.forceAccountId);

    var accountId = localStorage.getItem("accountId");
    if (model.forceAccountId) accountId = model.forceAccountId;

    var resourceId = this.state.resourceId;
    if (model.forceResourceId) resourceId = model.forceResourceId;

    var collection = "/positions";
    if (this.state.sourceId === 2)
      collection =
        "/accounts/" +
        accountId +
        "/positions/resource_" +
        resourceId +
        "/debug_positions/";
    if (this.state.sourceId === 3)
      collection =
        "/accounts/" +
        accountId +
        "/positions/resource_" +
        resourceId +
        "/valid_positions/";
    console.log("collection" + collection);

    var tempfirestore = firestore.collection(collection);
    if (this.state.sourceId === 1) {
      tempfirestore
        .where("accountId", "==", accountId)
        .where("resourceId", "==", resourceId);
    }
    tempfirestore
      .where("timestamp", ">=", testDate.startOf("day").toDate())
      .where(
        "timestamp",
        "<",
        testDate
          .add(1, "d")
          .startOf("day")
          .toDate()
      )
      .orderBy("timestamp")
      .get()
      .then(querySnapshot => {
        var positions = [];
        var lastLat = null;
        var lastLong = null;
        var lastPos = null;

        querySnapshot.forEach((doc, index) => {
          if (!lastPos) {
            lastPos = doc.data();
            lastLat = doc.data().lat_long.latitude;
            lastLong = doc.data().lat_long.longitude;
          }
          positions.push({
            ...doc.data(),
            uid: doc.id,
            latitude: doc.data().lat_long.latitude,
            longitude: doc.data().lat_long.longitude,
            timestamp: doc.data().timestamp.seconds,
            datetime: moment
              .unix(doc.data().timestamp.seconds)
              .format("DD/MM/YYYY HH:mm"),
            distance: this.getDistanceFromLatLonInMeters(
              doc.data().lat_long.latitude,
              doc.data().lat_long.longitude,
              lastLat,
              lastLong
            ),
            filterPos: this.filterPosition(doc.data(), lastPos) ? "SI" : "NO"
          });
          if (this.filterPosition(doc.data(), lastPos)) {
            lastPos = doc.data();
            lastLat = doc.data().lat_long.latitude;
            lastLong = doc.data().lat_long.longitude;
          }
        });

        // if (positions.length == 0) this.setState({ dialogOpen: true });

        if (!this.markers) this.markers = [];
        if (this.markers.length > 0) {
          for (var i = 0; i < this.markers.length; i++) {
            if (this.markers[i]) this.markers[i].setMap(null);
          }
          this.markers = [];
        }
        this.setState({ loadedPositions: null });
        if (positions.length > 0) {
          var bounds = new window.google.maps.LatLngBounds();

          for (var j = 0; j < positions.length; j++) {
            if (!positions[j].lat_long) continue;
            if (!positions[j].heading && positions[j].heading !== 0) continue;
            var myLatLng = {
              lat: positions[j].lat_long.latitude,
              lng: positions[j].lat_long.longitude
            };

            var icon = {
              url: getDirectionMarkerIcon(positions[j]),
              scaledSize: new window.google.maps.Size(20, 20)
            };
            const marker = new window.google.maps.Marker({
              position: myLatLng,
              icon: icon,
              map: this.map
            });

            this.addInfoWindow(marker, positions[j], resourceName);

            this.markers.push(marker);

            bounds.extend(myLatLng);
            this.map.fitBounds(bounds);
          }
          this.setState({ loadedPositions: positions });
        }
        this.setState({ isLoading: false });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.log("Error getting documents: ", error);
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.reportSelectedResource) {
      if (
        this.state.resourceId === null &&
        this.state.resourceId !== this.props.match.params.resourceId
      ) {
        this.setState({
          resourceId: this.props.match.params.resourceId,
          resourceName: this.props.reportSelectedResource.name
        });
        this.handleSubmit();
      }
    }
  }

  addInfoWindow(marker, position, resourceName) {
    var infowindow = new window.google.maps.InfoWindow();
    infowindow.setContent(
      '<div class="reportInfoWindow">' +
        '<div class="header">' +
        resourceName +
        "</div>" +
        "ID: " +
        position.uid +
        "<br/>" +
        '<div class="content">' +
        "Data e ora: " +
        moment.unix(position.timestamp).format("DD/MM/YYYY HH:mm") +
        "<br/>" +
        "Timestamp: " +
        position.timestamp +
        "<br/>" +
        stringTranslate("reports", "Speed") +
        ": " +
        Math.round(position.speed) +
        " Km/h<br/>" +
        "</div>" +
        "</div>"
    );

    window.google.maps.event.addListener(marker, "click", function() {
      infowindow.open(this.map, marker);
    });
  }

  componentDidMount() {
    this.map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 10,
      mapTypeControl: false,
      center: {
        lat: 41.9,
        lng: 12.4
      }
    });
  }

  render() {
    const { classes, resources } = this.props;
    const {
      selectedDate,
      dimensions,
      resourceId,
      resourceName,
      sourceId,
      sourceName,
      isLoading,
      loadedPositions
    } = this.state;

    let resourcesOptions = [];
    if (resources) {
      resources.forEach(el => {
        resourcesOptions.push({
          value: el.id,
          label: el.name
        });
      });
    }

    let sourceOptions = [
      {
        value: "1",
        label: "MIXED POSITION"
      },
      {
        value: "2",
        label: "DEBUG POSITION"
      },
      {
        value: "3",
        label: "VALID POSITION"
      }
    ];

    let canSubmit = false;
    if (resourceId) canSubmit = true;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.titleRow}>
            <div className={classes.title}>
              <Room color="primary" />
              <Typography variant="h3" className="px-8 py-4" color="primary">
                {stringTranslate("reports", "Routes Report")}
              </Typography>
            </div>
          </div>
          <div className={classes.filter}>
            <Formsy
              onValidSubmit={this.onSubmit}
              onValid={this.enableButton}
              onInvalid={this.disableButton}
              ref={form => (this.form = form)}
              className={classes.container}
            >
              <Grid container spacing={24} className={classes.formContainer}>
                <Grid item xs={12} sm={3}>
                  <div className="flex flex-col">
                    <InputLabel required className="form-input-label">
                      {stringTranslate("reports", "Date")}
                    </InputLabel>
                    <DatePicker
                      selected={selectedDate}
                      onChange={this.handleDateChange}
                      className="date-picker-input"
                      placeholderText={stringTranslate("reports", "Date")}
                      dateFormat={stringTranslate("general", "fsnDateFormat")}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <OMReactSelect
                    value={
                      resourceId
                        ? {
                            value: resourceId,
                            label: resourceName
                          }
                        : null
                    }
                    onChange={this.handleChange("resourceId")}
                    options={resourcesOptions}
                    label={stringTranslate("tasks", "resource")}
                    placeholder={stringTranslate("general", "Select")}
                    componentType={Select}
                    isClearable
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <OMReactSelect
                    value={
                      sourceId
                        ? {
                            value: sourceId,
                            label: sourceName
                          }
                        : null
                    }
                    onChange={this.handleChangeSource("sourceId")}
                    options={sourceOptions}
                    label="Sorgente"
                    placeholder={stringTranslate("general", "Select")}
                    componentType={Select}
                    isClearable
                    InputProps={{
                      className: classes.inputText
                    }}
                    InputLabelProps={{
                      className: classes.inputTextLabel
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="mt-12"
                    type="submit"
                    // onClick={() => this.handleSubmit()}
                    disabled={!canSubmit}
                  >
                    {stringTranslate("reports", "Calculate")}
                  </Button>
                </Grid>
                <Grid item sm={6} xs={6}>
                  <TextFieldFormsy
                    id="forceAccountId"
                    name="forceAccountId"
                    label="ID ACCOUNT"
                    className={classes.textField}
                    value={null}
                    margin="normal"
                  />
                </Grid>
                <Grid item sm={6} xs={6}>
                  <TextFieldFormsy
                    id="forceResourceId"
                    name="forceResourceId"
                    label="ID RISORSA"
                    className={classes.textField}
                    value={null}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </Formsy>
          </div>
          <div style={{ display: "flex", height: "100%" }}>
            <Measure
              bounds
              onResize={contentRect => {
                this.setState({ dimensions: contentRect.bounds });
              }}
            >
              {({ measureRef, contentRect }) => {
                return (
                  <div className={classes.map} ref={measureRef}>
                    <Grid item xs={12}>
                      <div
                        className={classes.mapContainer}
                        style={{
                          height: dimensions.height - 10,
                          width: dimensions.width
                        }}
                      >
                        <div
                          id="map"
                          style={{
                            height: dimensions.height - 10,
                            width: dimensions.width
                          }}
                        />
                        {isLoading && (
                          <div
                            className={classes.loadingMap}
                            style={{
                              height: dimensions.height - 10,
                              width: dimensions.width
                            }}
                          >
                            <div className={classes.progress}>
                              <CircularProgress color="secondary" />
                            </div>
                          </div>
                        )}
                      </div>
                    </Grid>
                  </div>
                );
              }}
            </Measure>
            <div className={classes.debugWindow}>
              {loadedPositions && loadedPositions.length > 0 ? (
                <WrappedVirtualizedTable
                  rowCount={loadedPositions.length}
                  rowGetter={({ index }) => {
                    return loadedPositions[index];
                  }}
                  onRowClick={event => {}}
                  columns={[
                    {
                      width: 150,
                      flexGrow: 1.1,
                      label: "Id",
                      dataKey: "uid"
                    },
                    {
                      width: 100,
                      flexGrow: 1.1,
                      label: "latitude",
                      dataKey: "latitude"
                    },
                    {
                      width: 100,
                      flexGrow: 1.1,
                      label: "longitude",
                      dataKey: "longitude"
                    },
                    {
                      width: 100,
                      flexGrow: 1.1,
                      label: "heading",
                      dataKey: "heading"
                    },
                    {
                      width: 100,
                      flexGrow: 1.1,
                      label: "accuracy",
                      dataKey: "accuracy"
                    },
                    {
                      width: 100,
                      flexGrow: 1.1,
                      label: "datetime",
                      dataKey: "datetime"
                    },
                    {
                      width: 100,
                      flexGrow: 1.1,
                      label: "distance",
                      dataKey: "distance"
                    },
                    {
                      width: 100,
                      flexGrow: 1.1,
                      label: "speed",
                      dataKey: "speed"
                    },
                    {
                      width: 100,
                      flexGrow: 1.1,
                      label: "filterPos",
                      dataKey: "filterPos"
                    }
                  ]}
                />
              ) : (
                <Typography variant="body1" className="m-12">
                  {stringTranslate("general", "No elements")}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {stringTranslate("reports", "No results title")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {stringTranslate("reports", "No results message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogOpen: false })}
              color="primary"
            >
              {stringTranslate("general", "Close")}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

FilterTest.propTypes = {};

const mapStateToProps = state => ({
  resources: state.firestore.ordered.resources,
  reportSelectedResource: state.firestore.data.reportSelectedResource
});

const mapDispatchToProps = dispatch => ({});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => {
    var collections = [
      {
        collection: "/resources",
        where: ["accountId", "==", localStorage.getItem("accountId")],
        storeAs: "resources"
      }
    ];
    if (props.match.params.resourceId) {
      var tmp = {
        collection: "/resources",
        doc: props.match.params.resourceId,
        storeAs: "reportSelectedResource"
      };
      collections.push(tmp);
    }
    return collections;
  }),
  withStyles(styles, { withTheme: true })
)(mapEnvironmentScript(FilterTest));
