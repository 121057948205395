import React from 'react';
import PropTypes from 'prop-types';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import BackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import { stringTranslate } from 'languages/OMTranslate';
import { getFirestoreCollection } from '../../../../../firestoreAccount';

import Modules from './modules/Modules';
import ModuleDetail from './modules/ModuleDetail';

const styles = theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    height: '100%'
  },
  backButton: {
    position: 'absolute',
    top: theme.spacing.unit,
    left: theme.spacing.unit
  },
  dialogTitle: {
    paddingBottom: '10px'
  },
  dialogContent: {
    padding: 0
  },
  title: {
    paddingLeft: '50px',
    marginBottom: '10px'
  },
  subTitle: {
    paddingLeft: '50px',
    color: theme.palette.primary.normal,
    fontWeight: 700,
    fontSize: '14px'
  },
  contentView: {
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  message: {
    fontSize: theme.text.medium,
    color: theme.palette.primary.dark,
    lineHeight: '1.75'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '5px 20px 20px'
  },
  shadow: {
    boxShadow: '0px 2px 2px 0px rgba(0,0,0, 0.1)'
  }
});

class TaskModules extends React.Component {
  state = {
    snackbar: {
      open: false,
      message: ''
    },
    moduleDetail: {
      show: false,
      module: undefined,
      customForm: undefined
    }
  };

  handleShowSnackbar = message => {
    this.setState({ snackbar: { open: true, message } });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState(state => ({
      snackbar: { ...state.snackbar, open: false }
    }));
  };

  /**
   * Metodo richiamato al click del puldante aggiungi
   */
  handleOnCreateClick = () => {
    this.setState({
      moduleDetail: {
        show: true,
        module: undefined,
        customForm: undefined
      }
    });
  };

  /**
   * Metodo richiamato quando viene cliccato su un modello
   * Viene modificato il componente che viene renderizzato
   * per visualizzare il dettaglio del modulo
   * @param {Object} module oggetto modello
   * @param {Object} customForm oggetto custom form
   */
  handleOnModuleClick = (module, customForm) => {
    this.setState({
      moduleDetail: {
        show: true,
        module,
        customForm
      }
    });
  };

  /**
   * Gestisce il click del pulsante indietro del component
   * che visializza il dettaglio del modulo
   */
  handleModuleDetailBack = () => {
    this.setState(state => ({
      moduleDetail: {
        ...state.moduleDetail,
        show: false
      }
    }));
  };

  /**
   * Ritorna una component per visualizzare un messaggio
   * @param {String} message messaggio
   */
  getMessageView = message => {
    const { classes } = this.props;
    return (
      <div className={classes.contentView}>
        <Typography className={classes.message}>{message}</Typography>
      </div>
    );
  };

  contentToRender = () => {
    const { task, modules, customForms, classes } = this.props;
    if (!isLoaded(modules) || !isLoaded(customForms)) {
      return (
        <div className={classes.contentView}>
          <CircularProgress color="secondary" />
        </div>
      );
    }
    if (customForms.length === 0) {
      return this.getMessageView(stringTranslate('tasks', 'noCustomForms'));
    }
    if (modules.length === 0) {
      return this.getMessageView(stringTranslate('tasks', 'noModules'));
    }
    // Workaround per evitare di visualizzare i dati di una possibile
    // query precedente
    if (modules[0].taskId !== task.id) {
      return (
        <div className={classes.contentView}>
          <CircularProgress color="secondary" />
        </div>
      );
    }
    return (
      <Modules
        modules={modules.slice().sort(function(m1, m2) {
          if (m1.createdAt.seconds < m2.createdAt.seconds) {
            return 1;
          }
          if (m1.createdAt.seconds > m2.createdAt.seconds) {
            return -1;
          }
          return 0;
        })}
        customForms={customForms}
        onItemClick={this.handleOnModuleClick}
      />
    );
  };

  render() {
    const { task, customForms, onBack, classes } = this.props;
    const { snackbar, moduleDetail } = this.state;
    if (moduleDetail.show) {
      return (
        <ModuleDetail
          task={task}
          module={moduleDetail.module}
          customForms={customForms}
          onBack={this.handleModuleDetailBack}
        />
      );
    }
    return (
      <React.Fragment>
        <IconButton className={classes.backButton} onClick={onBack}>
          <BackIcon />
        </IconButton>
        <DialogTitle
          id="simple-dialog-title"
          disableTypography
          className={classes.dialogTitle}
        >
          <div>
            <Typography variant="h3" className={classes.title}>
              {stringTranslate('tasks', 'taskModules')}
            </Typography>
            <Typography variant="h4" className={classes.subTitle}>
              {task.description}
            </Typography>
          </div>
        </DialogTitle>
        <div className={`${classes.toolbar} ${classes.shadow}`}>
          {customForms && customForms.length > 0 && (
            <Button
              variant="fab"
              color="secondary"
              mini
              onClick={this.handleOnCreateClick}
              className={classes.addButton}
              aria-label={stringTranslate('general', 'Add')}
            >
              <AddIcon />
            </Button>
          )}
        </div>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.container}>{this.contentToRender()}</div>
        </DialogContent>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={this.handleCloseSnackbar}
          message={snackbar.message}
        />
      </React.Fragment>
    );
  }
}

TaskModules.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  // Proprietà popolate da firestore
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired
      }).isRequired
    })
  ),
  customForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      createdAt: PropTypes.shape({
        seconds: PropTypes.number.isRequired
      }).isRequired
    })
  ),
  // Oggetto creato da withStyles
  classes: PropTypes.shape().isRequired
};

TaskModules.defaultProps = {
  modules: [],
  customForms: []
};

const mapStateToProps = state => ({
  modules: state.firestore.ordered.modules,
  customForms: state.firestore.data.customForms
    ? Object.keys(state.firestore.data.customForms)
        .map(k =>
          state.firestore.data.customForms[k]
            ? {
                id: k,
                ...state.firestore.data.customForms[k]
              }
            : undefined
        )
        .filter(cf => cf !== undefined)
    : []
});

export default compose(
  firestoreConnect(props => [
    {
      collection: `${getFirestoreCollection()}/modules`,
      storeAs: 'modules',
      where: [['taskId', '==', props.task.id]]
    },
    {
      collection: `${getFirestoreCollection()}/customForms`,
      storeAs: 'customForms'
    }
  ]),
  connect(mapStateToProps),
  withStyles(styles)
)(TaskModules);
