import React from "react";
import { stringTranslate } from "languages/OMTranslate";
import { withStyles } from "@material-ui/core/styles";
import Table from "../common/table/Table";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const columns = [
  {
    width: "16%",
    label: stringTranslate("customers", "Company Name")
  },
  {
    width: "12%",
    label: stringTranslate("customers", "VAT")
  },
  {
    width: "10%",
    label:
      stringTranslate("customers", "Customer") +
      "/" +
      stringTranslate("customers", "Supplier")
  },
  {
    width: "16%",
    label: stringTranslate("customers", "Email")
  },
  {
    width: "12%",
    label: stringTranslate("customers", "Phone")
  },
  {
    width: "12%",
    label: stringTranslate("customers", "mainAddressName")
  },
  {
    width: "10%",
    label: stringTranslate("customers", "Poi Latitude")
  },
  {
    width: "10%",
    label: stringTranslate("customers", "Poi Longitude")
  }
];

const style = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
    flex: 1,
    height: "calc(100% - 47px)",
    maxHeight: "calc(100% - 47px)",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingBottom: "16px"
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "16px"
  },
  esit: {
    paddingLeft: "16px"
  }
});

const ImportCustomersPreview = props => {
  return (
    <div className={props.classes.main}>
      <div className={props.classes.esit}>
        <Typography variant="body1">
          {stringTranslate("customers", "numberOfCustomers")
            .replace("$1", props.rows.length)
            .replace("$2", props.total)}
        </Typography>
      </div>
      <Table columns={columns} data={props.rows} />
      <div className={props.classes.buttonContainer}>
        <Button
          variant="contained"
          color="secondary"
          className="mr-12"
          onClick={props.save}
        >
          {stringTranslate("general", "Save")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="mr-12"
          onClick={props.goBack}
        >
          {stringTranslate("general", "Cancel")}
        </Button>
      </div>
    </div>
  );
};
export default withStyles(style, { withTheme: true })(ImportCustomersPreview);
